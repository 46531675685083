import { dismiss } from '@ionic/core/dist/types/utils/overlays';
import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-politica-de-privacidad',
  templateUrl: './politica-de-privacidad.component.html',
  styleUrls: ['./politica-de-privacidad.component.scss'],
})
export class PoliticaDePrivacidadComponent implements OnInit {

  constructor( private Modal:ModalController) { }

  ngOnInit() {}

  Retorno(){
    this.Modal.dismiss()
  }
}
