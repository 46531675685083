import { Component, OnInit } from '@angular/core';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import capacidadLand from 'src/assets/plantillas/capacidad-land.json'

@Component({
  selector: 'app-landservice6',
  templateUrl: './landservice6.component.html',
  styleUrls: ['./landservice6.component.scss'],
})
export class Landservice6Component implements OnInit {

  public capacidadLand: Array<string> = Object.keys(capacidadLand.Capacidad)

  constructor(private formservice: FormServiceService) { }

  ngOnInit() {
  }

  selectCapacidad(event) {
    
    this.formservice.landpublication.capacidad = event.target.value
  }

}
