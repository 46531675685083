// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
    ApiKeyGoogleMaps: 'AIzaSyAl3AIZpRq8PdMTYLJVCv6ihPMZuF6skvA',
    apikeystripe:"pk_live_51KGxgHCVJMP5YqiYzADXUzozYAEmRFPrTkPzRacQ3EELXgN05NVG1GvklRwKtAif5ijbqavUn0Qw4d7ygGQEf7E600LtFRhx6k",
    firebaseConfig: {
      apiKey: 'BH9RVmXdjWlt97EklhA4GG8O7ZSBeMN-YGRfOyEdoiri6r-tPji1TmwH4_U4F3XlAuUknbTjfQ0mCsdgGehGg', // Replace with your API key
      authDomain: 'YOUR_PROJECT_ID.firebaseapp.com',
      projectId: 'YOUR_PROJECT_ID',
      storageBucket: 'YOUR_PROJECT_ID.appspot.com',
      messagingSenderId: 'YOUR_MESSAGING_SENDER_ID',
      appId: 'YOUR_APP_ID',
      measurementId: 'YOUR_MEASUREMENT_ID'
    },
  aws: {
    region: 'us-east-1', // e.g. 'us-east-1'
    accessKeyId: 'your-access-key-id',
    secretAccessKey: 'your-secret-access-key',
    bucket: 'your-bucket-name',
  },

  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
