import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ModalController, NavController, PopoverController } from '@ionic/angular';

@Component({
	selector: 'app-return-bar',
	templateUrl: './return-bar.component.html',
	styleUrls: ['./return-bar.component.scss'],
})
export class ReturnBarComponent implements OnInit {
	@Input() title: string = "Regresar";
	@Input() pageToReturn: string = "home";
	@Input() type: string = "page";

	@Output() resendMessageEvent = new EventEmitter<any>();
	constructor(
		private modalController: ModalController,
		private navCtrl: NavController,
		private popOvercontroller: PopoverController,
	) { }

	ngOnInit() { }

	closeModal() {
		this.modalController.dismiss();
	}

	return_click() {
		this.navCtrl.navigateBack(this.pageToReturn);
	}

	pop_page() {
		this.navCtrl.back();
	}

	closePopOver(){
		this.popOvercontroller.dismiss();
	}

	resendMessage(){
		this.resendMessageEvent.emit(true);
	}
}
