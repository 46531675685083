import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { dismiss } from '@ionic/core/dist/types/utils/overlays';
import { ModalController, IonSlides, ToastController } from '@ionic/angular';
import { ActualizacionServiceService } from './../../../services/actualizacion/Actualizacion-service.service';
import { PublicationSaveTemplateComponent } from './../../../components/publication/publication-save-template/publication-save-template.component';
import Swal2 from "sweetalert2";
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import { LkfilmViewBySlidesComponent } from 'src/app/components/lkfilm-view-by-slides/lkfilm-view-by-slides.component';

@Component({
  selector: 'app-publication-save',
  templateUrl: './publication-save.page.html',
  styleUrls: ['./publication-save.page.scss'],
})
export class PublicationSavePage implements OnInit {
  slideOpts = {
    direction: 'vertical',
    initialSlide: 0,
    speed: 420,
  };
  public publications: any[] = [];
  public ChunkedPublications: any[] = [];
  @Input() profile_id: any;
  @Input() initial_index = 0;
  @ViewChild('slides') slides: IonSlides;
  constructor(private modalctrl:ModalController, private actualizacionservice:ActualizacionServiceService, private toastController: ToastController, private formService:FormServiceService,) { }

  ngOnInit() {
    this.getpublicationsave(true)
console.log('user_id guardado', this.profile_id);
  }
  

  retorno(){
    this.modalctrl.dismiss()
  }
  get_class2(index) {
    // console.log('se lllama get_class2');

    if(this.formService.lkpublication.index_close===index){
      return 'shadow'
    }


  }
  getpublicationsave2(){

    this.actualizacionservice.getPublicationsSave(this.profile_id, null ).then((result:[])=>{



     result['data'].forEach(element => {


        console.log("elementos",element);
        this.ChunkedPublications.push(element)


      });
      console.log("publicaciones Guardadas",this.ChunkedPublications);
      this.chunkPublications();
    })
  }

  
  
  

  ngAfterViewInit() {
    this.slides.slideTo(this.initial_index);
    this.slides.ionSlideDidChange.subscribe(async () => {
      await this.handleSlideChange();
    });
     let me = this;
     me.slides.slideTo(this.initial_index);
  }
  private chunkPublications() {
    const chunkSize = 4;
    const chunkedPublications = [];
    let index = 0;
  
    while (index < this.ChunkedPublications.length) {
      const chunk = this.ChunkedPublications.slice(index, index + chunkSize);
      if (chunk.length > 0) {
        chunkedPublications.push(chunk);
      }
      index += chunkSize; // Update the index by the chunkSize, not the chunk length
    }
  
    this.ChunkedPublications = chunkedPublications;
  }
  
  async handleSlideChange() {
    const currentIndex = await this.slides.getActiveIndex();
  
    // Check if the current slide is the last slide
    if (currentIndex === (await this.slides.length() - 1)) {
      // Fetch more publications when reaching the last slide
     
  
       await this.new_page();
    }
  }
  async new_page() {
    try {
      this.showToast();
      this.actualizacionservice.current_page++;
      const fetchedPublications = await this.actualizacionservice.getPublicationsSave(null, false, null, false, null);
    
      if (!fetchedPublications || fetchedPublications.length === 0) {
        Swal2.fire({
          title: 'La velocidad del movimiento de tu dedo excede el límite',
          icon: 'warning',
          confirmButtonText: 'Entendido',
          color: '#ffffff',
          background: '#010f4e',
        });
        return;
      }
    
      const chunkedPublications = this.chunkNewPublications(fetchedPublications);
      this.ChunkedPublications.push(...chunkedPublications);
    } catch (error) {
      console.error('Error fetching publications:', error);
    }
    
  }
  async showToast() {
    const toast = await this.toastController.create({
      message: `
        <div class="toast-content">
        <img src="https://laniakea-multimedia.s3.amazonaws.com/loading_chunk.gif" alt="Image" style="width: 100px; height: 30px;">
          <span>⚡️⚡️⚡️</span>
        </div>
      `,
      duration: 6399,
      position: 'bottom',
      cssClass: 'custom-toast',
      buttons: [
        {
          text: '...',
          role: 'cancel'
        }
      ]
    });
  
    toast.present();
  }
  
  chunkNewPublications(publications) {
    const chunkSize = 4; // Define your desired chunk size here
    const chunkedArray = [];
    
    for (let i = 0; i < publications.length; i += chunkSize) {
      const chunk = publications.slice(i, i + chunkSize);
      chunkedArray.push(chunk);
    }
    
    return chunkedArray;
  }
  modalsave(index){
    this.modalctrl.create({
      component:PublicationSaveTemplateComponent,
      cssClass: 'detailsInModal',
			swipeToClose: true,
      componentProps: {
        publications:this.ChunkedPublications,
        index: index
      }
    }).then(modal=>{
      modal.present()
    })
  }

  // getpublicationsave(){
  //   this.actualService.getPublications(null,true).then((result:[])=>{


  //     this.publications=result
  //     console.log("todas las publicaciones",this.publications);

  //   })

  // }

  async getpublicationsave(isrefresh: boolean){
    const result = await this.actualizacionservice.getPublicationsSave(null, isrefresh, this.profile_id, null, null) ;
    const fetchedPublications = result || [];

    
    this.filterPublications(fetchedPublications);
    
  }
  async filterPublications(publications) {
  
    if (publications.length > 0) {
      this.publications = publications;
      this.ChunkedPublications = publications;
      this.chunkPublications();
    }
    if (publications.length < 1  && this.actualizacionservice.current_page <3) {
      console.log('pub < x');
      this.actualizacionservice.current_page++;
      await this.getpublicationsave(false);
    }
  
    console.log('siguiendo length of pub', this.ChunkedPublications.length);
  
  
  }
  get_class(index) {
    // console.log('se lllama get_class');


    if((index % 4 === 0)) {
      return 'reminder0 ';
    }
    else if(index % 4 === 1){
      return 'reminder1 ';
    }
    else if(index % 4 === 2){
      return 'reminder2 ';
    }else{
      return 'reminder3 ';
    }
  }
  openComponentVideoTry(index, chunk){//
    console.log('chunkk',chunk);
    console.log('indexxx',index);
    const indexxx = chunk * 4 + index;
      this.modalctrl.create({
          component: LkfilmViewBySlidesComponent,
          cssClass: 'detailsInModal',
          swipeToClose: true,
          componentProps: {
            publications: this.publications,
            index: indexxx,
            valueSelect: 'Guardado'
          }
      }).then((modal)=>{
          modal.present();
      });
  }


  openComponentVideo(index: number, chunk: number) {
    this.retorno();
    try {
      this.formService.mostrar = false;
      console.log('pubs', this.publications);
      this.formService.publications = this.publications;
      const indexxx = chunk * 4 + index;
      //this.initial_index = indexxx;
      this.formService.index = indexxx;
      this.formService.valueSelect = 'Guardado';
      this.formService.mediaTemplate = true;
  
      // Log some information to check the values before opening the component
      console.log('Index:', index);
      console.log('Chunk:', chunk);
      console.log('Indexxx:', indexxx);
    } catch (error) {
      console.error('Error in openComponentVideo:', error);
    }
  }
  
}
