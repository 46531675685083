import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ComponentButtonColorSelectComponent } from '../color/component-button-color-select/component-button-color-select.component';
import { ComponentButtonSemiroundComponent } from '../semiround/component-button-semiround/component-button-semiround.component';
import { ComponentButtonSquareComponent } from '../square/component-button-square/component-button-square.component';
import { ComponentButtonSwitchComponent } from '../switch/component-button-switch/component-button-switch.component';
import { ComponentRoundButtonComponent } from '../round/component-round-button/component-round-button.component';
import { ComponentButtonSemiroundToPostComponent } from '../semiround/component-button-semiround-to-post/component-button-semiround-to-post.component';
import { NewPublicationButtonComponent } from '../new-publication-button/new-publication-button.component';
import { IconButtonComponent } from '../icon-button/icon-button.component';
import { ImageButtonComponent } from '../image-button/image-button.component';
import { UploadImagesComponent } from '../upload-images/upload-images.component';





@NgModule({
	declarations: [
		ComponentButtonColorSelectComponent,
		ComponentRoundButtonComponent,
		ComponentButtonSemiroundComponent,
		ComponentButtonSemiroundToPostComponent,
		ComponentButtonSquareComponent,
		ComponentButtonSwitchComponent,
		IconButtonComponent,
		ImageButtonComponent,
		NewPublicationButtonComponent,
		UploadImagesComponent

		
	],
	exports: [
		ComponentButtonColorSelectComponent,
		ComponentRoundButtonComponent,
		ComponentButtonSemiroundComponent,
		ComponentButtonSemiroundToPostComponent,
		ComponentButtonSquareComponent,
		ComponentButtonSwitchComponent,
		IconButtonComponent,
		ImageButtonComponent,
		NewPublicationButtonComponent,
		UploadImagesComponent
	],
	entryComponents: [
		ComponentButtonColorSelectComponent,
		ComponentRoundButtonComponent,
		ComponentButtonSemiroundComponent,
		ComponentButtonSemiroundToPostComponent,
		ComponentButtonSquareComponent,
		ComponentButtonSwitchComponent,
		IconButtonComponent,
		ImageButtonComponent,
		NewPublicationButtonComponent,
		UploadImagesComponent
	],
	imports: [
		CommonModule,
		IonicModule
	],
	schemas: []
})
export class ButtonsModule { }
