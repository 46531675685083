import { Calendar } from '@ionic-native/calendar/ngx';
import { Injectable } from '@angular/core';
import { Earth } from 'src/app/Models/Classes/Publications/Earth/earth';
import { Epoch } from 'src/app/Models/Classes/Publications/Epoch/epoch';
import { Eventos } from 'src/app/Models/Classes/Publications/Eventos/eventos';
import { Feex } from 'src/app/Models/Classes/Publications/Feex/feex';
import { Land } from 'src/app/Models/Classes/Publications/Land/land';
import { LkfilmPublication } from 'src/app/Models/Classes/Publications/Lkfilm/lkfilm-publication';
import { newnormal } from 'src/app/Models/Classes/Publications/New-Normal/newnormal';
import { PublicationClass } from 'src/app/Models/Classes/Publications/publication-class';
import { MediaItem } from 'src/app/Models/Classes/Media/media-item';
import { RequestService } from '../request/request.service';
import { Router } from '@angular/router';
import Swal2 from 'sweetalert2';
import {StatsComponent} from 'src/app/stats/stats.component';
import {   ModalController, } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class FormServiceService {
  showSearchInwebView;
  face_id_active:boolean=false;
  selectedLiaLanguage;
  heyLia: boolean=false;
  voiceCommandListernActive:boolean=false;
  actual_playlist;
  PermitSlidechange:boolean=true;
  drive_data;
  Capacitor_convertFileSrc_video;
  Capacitor_convertFileSrc;
  public recording_icon='radio-button-on';
  POSTING:boolean=false;
  actual_publication;
  publication_template_index=0;
  AI: boolean=false;
  changeMediaPeriodically:boolean=true;
  TikTok;
  Entrelazamiento:boolean=false;
  valueInput;
  itemsArray: string[] = []; // Array to store item IDs
  myTP_POSTS:boolean=false;
  tp_ChunkedPublications;
  tp_publications;
  public main_available_sections: Array<string> = ["EN LA APP", "TERCEROS"];
	public main_icons_for_publication_sections: Array<string> = [ "aperture-sharp", "git-network-sharp"  ];
  public popup_section: string = "EN LA APP";
  public unseenCalls:any=0;
  public AI_message:any;
  public IPAddress:any;
  public deviceid:any;
  public language:any;
  public device:any;
  public network:any;
  public BBVA_auth:boolean=false;
  public address:any;
  public googleMapOpened: boolean=false;
  public selectedVehicle: any;
  public showMoreHidden: boolean=true;
  public webViewLive: boolean=true;
  public profile: any;
  public fromCreatePublication: boolean= false;
  public scanActive: boolean=false;
  public premiumMedia: boolean=false;
  public earth3: boolean= false;
  public earth51: boolean= false;
  public earth5: boolean= false;
  public Availability: any;
  public EarthCalendar: boolean = false;
  public payCalendar: boolean= false;
  public cupos: number= 0;
public dias: number= 0;
public horas: number= 0;
  public ChunkedPublications: any[] = [];
  public publications: any[] = [];
  public mediaTemplate: boolean=false;
  public valueSelect:string;
  public index:any;
  public mostrar: boolean= false;
  public isKYCValid:boolean=false;
  public sectionVer:string=""
  public titleHeader: string="";
  public title: string="";
  public description: string="";
  public publication_type: string="";
  public quienpuedever:any=["Para todos"];
  public price:number=0;
  public divisa:string="";
  public ubicacion: string="";
  public is_feed:boolean=false;
  public multimedia: Array<MediaItem> = new Array<MediaItem>()
  public multimedia2: Array<MediaItem> = new Array<MediaItem>()
  public multimediaPerfil: Array<MediaItem> = new Array<MediaItem>()

  public lkpublication:LkfilmPublication=new LkfilmPublication()
  public normalpublication:newnormal= new newnormal();
  public earthpublication: Earth= new Earth();
  public eventospublication: Eventos= new Eventos();
  public feexpublication: Feex= new Feex();
  public epochpublication: Epoch= new Epoch();
  public landpublication: Land= new Land();

  history:Array<any>=[]










  constructor(private request: RequestService,
    private router:Router,
    private modalctrl:ModalController,
  ) {
   }

   public postSaveReserva(fecha: object) {
		return new Promise((resolve, reject) => {
			let params = { "fecha": fecha };
			this.request.createRequestPost('Horario', {}, params, 'save').then((data) => {
				resolve(data);
			}).catch((error) => {
				reject(error);
			});

		});
	}
  sendMessage_to_ai(){
    Swal2.fire({
			title: 'Procesando...',
			text: 'El mensaje se está procesando.',
			icon: 'success',
			timer: 45000, // Show the alert for 45 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-end',
			showConfirmButton: false
		  });
    this.router.navigate(['/home/messages-page']);
    if(!this.AI_message){
			Swal2.fire({
				title: 'ERROR',
				text: 'No hay ningún estimulante',
				icon: 'error',
				confirmButtonText: 'Entendido',
				color: '#ffffff',
				background: '#010f4e',
			  });
        return;
		 }
    this.AI=true;
    setTimeout(() => {
    const button = document.getElementById('mySend_AI_Prompt_button');
    button.click();
  }, 333);
  }
  async open_stats_option(item){
    const modal = await this.modalctrl.create({
			component: StatsComponent,
			swipeToClose: true,
			componentProps: {
        option:item
			  //valueSelect: 'MENU',
			  // Pass the publication ID or other necessary data to the component
			}
		  });
		  await modal.present();
  }
  muteAllMedia() {
    // Query all video and audio elements
    const mediaElements = document.querySelectorAll('video, audio');
    mediaElements.forEach((media) => {
      // Typecast Element to HTMLMediaElement, which includes video and audio elements
      const mediaElement = media as HTMLMediaElement;
      // Mute each media element
      mediaElement.muted = true;
      // Pause the media if it's playing
      if (!mediaElement.paused) {
        mediaElement.pause();
      }
    });
  }
   /*
  Contacting_with_ai(data, type){
    this.chatService.conversationsList.forEach(conversation => {
      // Assuming getUsers() returns an array and the first user has a property `username`
      const username = conversation.getUsers()[0].username;
  
      // Check if `data` includes the username or has a similar pattern
      if (data.includes(username)) {
          // Perform actions when the username matches or is found in the data
          console.log(`Match found for user: ${username}`);
          if(type==='llamada'){
>>>>>>> Stashed changes

          }else if(type==='mensaje'){

          }
          
          // Add your logic here to handle the matching conversation
      }
  });
  

  }
  
  startVideoCall(conversation){
		if (Capacitor.getPlatform() === 'web') {
			this.webViewLive=false;// to make sure the web view doesnt overlap or interfere with chime contact component
    	setTimeout(() => {
		this.modalctrl.create({
			//component: ModulesLockComponent,
			component: ChimeContactComponent,
			swipeToClose: true,
			componentProps: {
			videoCall: true,
			username: this.profile.username,
			receipient_user_id:conversation.getUsers()[0].id,
			conversationID:conversation.getID(),
				},
		}).then((modal)=>{
			modal.present();
		
		});
	}, 900); 
}else{
	let url= `https://supercumulo.laniakea.tv/loader?meeting=0&username=${this.profile.username}&receipient_user_id=${conversation.getUsers()[0].id}&conversationID=${conversation.getID()}`;
this.openBrowser(url);
}
	}
  async openBrowser(url: string) {
		//await Browser.open({ url });
		const modal = await this.modalctrl.create({
			cssClass: 'detailsInModal',
			swipeToClose: true,
			component: LaniakeaBrowserComponent,
			componentProps: {
				url: url,
			
			},
		  });
		  modal.onDidDismiss().then((data)=>{
			
		  });
		  return await modal.present();
		window.open(url, '_blank'); // Open the URL in a new tab or window
	  }
*/
	}

