import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { ModalController, IonSelect } from '@ionic/angular';
import { CreateGroupComponent } from 'src/app/components/chat/create-group/create-group.component';
import { RelationsListComponent } from 'src/app/components/chat/relations-list/relations-list.component';
import { UserContact } from 'src/app/Models/Classes/User/user-contact';
import { ChatService } from 'src/app/services/chat/chat.service';
import { NavController} from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal2 from 'sweetalert2';
import  Swal from 'sweetalert2';
import {ChimeContactComponent} from "src/app/chime-contact/meeting/chime-contact.component";
import { ProfileService } from 'src/app/services/profile/profile.service';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import {Capacitor} from "@capacitor/core";
import { Share } from '@capacitor/share';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import { RequestService } from 'src/app/services/request/request.service';
import { Time } from '@angular/common';
import {UploadMultimediaService} from "../../services/upload/upload-multimedia.service";
import { PublicacionesColeccionComponent } from 'src/app/components/publication/create/publicaciones-coleccion/publicaciones-coleccion/publicaciones-coleccion.component';
import { ChangeDetectorRef } from '@angular/core';
import { Camera, CameraResultType, CameraSource, Photo, CameraOptions } from '@capacitor/camera';
import { Filesystem, Directory, Encoding, FilesystemDirectory, FilesystemEncoding } from '@capacitor/filesystem';
//import OpenAI from "openai";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Howl } from 'howler';
import { SpeechRecognition } from '@ionic-native/speech-recognition/ngx';
import { MediaItem } from 'src/app/Models/Classes/Media/media-item';
import { Meta } from '@angular/platform-browser';
declare var MediaRecorder: any; // Declare MediaRecorder globally
import { Title } from '@angular/platform-browser';
// Now you can use MediaRecorder without import statements
declare var google;
declare var gapi;
declare const TiKTok: any; 
import{FingerprintAIO}from "@ionic-native/fingerprint-aio/ngx";
import {InlineBowsingComponent} from 'src/app/inline-bowsing/inline-bowsing.component';
@Component({
	selector: 'app-messages-page',
	templateUrl: './messages-page.page.html',
	styleUrls: ['./messages-page.page.scss'],
})

export class MessagesPagePage implements OnInit, AfterViewInit {
	private googleapiKey = 'AIzaSyDKzCRYNscPNGWIGhsVZdFk-q-QuQmDjEs'; 
	setDrive_media;
	private oauthToken: string='sbjcbjdcs';  setDrive_media_called:boolean=false;
	/*
	tiktokEmbedHtml = `
    <blockquote class="tiktok-embed" cite="https://www.tiktok.com/@laniakea_supercumulo/video/7148624054604418309" data-video-id="7148624054604418309" style="max-width: 605px; min-width: 325px;">
      <section>
        <a target="_blank" title="@laniakea_supercumulo" href="https://www.tiktok.com/@laniakea_supercumulo?refer=embed">@laniakea_supercumulo</a> Daniel Habif - &#34;MANTENTE FIRME&#34;.
        <a title="mindfulnesschallenge" target="_blank" href="https://www.tiktok.com/tag/mindfulnesschallenge?refer=embed">#mindfulnesschallenge</a>
        <a title="filosofando" target="_blank" href="https://www.tiktok.com/tag/filosofando?refer=embed">#filosofando</a>
        <a title="aprendeconlaniakea" target="_blank" href="https://www.tiktok.com/tag/aprendeconlaniakea?refer=embed">#aprendeconlaniakea</a>
        <a title="ly" target="_blank" href="https://www.tiktok.com/tag/ly?refer=embed">#ly</a>
        <a title="viral" target="_blank" href="https://www.tiktok.com/tag/viral?refer=embed">#viral</a>
        <a target="_blank" title="♬ sonido original - Laniakea" href="https://www.tiktok.com/music/sonido-original-7148624113912728326?refer=embed">♬ sonido original - Laniakea</a>
      </section>
    </blockquote>
  `;*/
	historic_lost_calls;
	historic_calls;
	Historic_AI_data;
	public historic:boolean=false;
	public muted:boolean=true;
	public sound:Howl;
	transcriptionJobName;
	stream:any;
mediaRecorder:any;

	public assistantId:any='asst_VZrC36qbzklA3diSgV9OsNFr';
	public threadId:any;
	dictationTime;
	dictationActive:boolean=false;
	dictationTimer;
	private TextToSpeechApiUrl: any ='https://texttospeech.googleapis.com/v1beta1/text:synthesize';
	private TextToSpeechApiKey: any ='AIzaSyDKzCRYNscPNGWIGhsVZdFk-q-QuQmDjEs';
	TarifaXsolicitud:any=0.333;
	public CharacterImage: any='https://laniakea-multimedia.s3.amazonaws.com/imagenes/Personajes/IMG_4965.JPG';
	public CharacterDescription: any='Hola, tú eres Anu es una deidad sumeria considerada el dios supremo y el padre de los dioses.'; //'Hola, tú eres la aplicación Laniakea, estás aquí para ayudar a responder preguntas a los usuarios de la aplicación. Laniakea es una app que ha concatenado 6 publicaciones. 1. Stories, medios que desaparecen en 24 horas. 2. Publicaciones normales, que pueden tener precio. 3. Lk Film Estudios, videos de películas monetizables. 4. Servicios Land, publicaciones de servicios. 5. Eventos de Eclipse, publicaciones de eventos. 6. Alojamiento de Earth, publicaciones de alojamiento. Todas las publicaciones, expecto historias, pueden recibir regalos de 1 peso mexicano. Cualquier consulta entrópica, alienta al usuario a entrar a www.laniakea.tv. Esta es la consulta, mensaje solicitado del usuario:';
	CharacterName: any='Anu';
	PreviewImages: string[] = [
		'https://laniakea-multimedia.s3.amazonaws.com/imagenes/vogue_photoshoot_of_a_monkey_in_Wes_Anderson_style_st_c05e9e9e-8686-4eb9-bc34-4b4b3a999f93_upscayl_4x_realesrgan-x4plus.PNG',
		'https://laniakea-multimedia.s3.amazonaws.com/imagenes/psycho_deliverance_Stunning_magnum-opus_interpretation_in_maxim_30afcdb4-ae60-40af-8a4e-abccf4616b4e-gigapixel-art-scale-6_00x.PNG',
		'https://laniakea-multimedia.s3.amazonaws.com/imagenes/psycho_deliverance_Stunning_interpretation_in_maximum_ultraphot_547553f3-5d89-4d73-8c68-ce80e037b230-gigapixel-art-scale-6_00x.PNG',
		'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Orb_Emerald__deformed_animals_at_a_meeting_05d26f65-7edb-483b-b1ca-5d623c997100.PNG',
		'https://laniakea-multimedia.s3.amazonaws.com/imagenes/pathilind_pixel_art_amazing_screenshot_of_2D_quest_videogame_in_d848fa18-dff4-442e-ad8c-d72e6c5eef50.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Saiio_interior_of_a_multi_level_grand_library_with_narrow_rows__73b0b0dc-062c-4b09-bab3-c656da9fd6f8.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/s8n_a_cosmic_coral_reef_replacing_the_coral_plants_and_sand_wit_2beef2cc-9801-4ef0-ad30-c6b6acbb3809.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/tvd_29.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/The_Jackal_Back_in_the_day_when_humans_had_pet_dinosaurs_1880s__5fec8fa6-8c2c-498e-9494-dfffa03ce73b.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/zephyr_puppy_Crystal_balls_wonderful_and_sparkly_scene_5f027985-73f0-45db-93ee-b4d2ffe9ae86.JPG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/struggling.wip_madam_curry_mad_scientist_in_super_tech_lab_4d_3_66fecfb1-d345-47c0-8ecd-61189fa8725c.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/smileysmileys_The_mountain_meadows_of_the_future._Vintage_retro_83add859-4870-4d33-bb08-c40e01587533.PNG',
	 	 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Shadowplay_realistic_image_of_george_washington_signing_hyperre_897f2811-e1e2-4182-b321-33af03a3afbb.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/zephyr_puppy_Tilda_Swinton_kaleidoscopic_winter_wonderland_spar_da827699-aa25-4cbf-bf31-1e1bab01bc34.JPG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/fnchn_Michelangelo_is_exhausted_from_painting_so_many_chapels_n_acb9fc4b-efb5-4e57-9154-fd0f15e01015.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/forrestgump935_the_surface_on_Jupiter_152994b4-51a8-45a2-a783-4bbbdc1292ac.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/FrontierVillage_04.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Futuristic_biophilic_city_city_of_tomorrow_walkable_neighbourhoods4.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/gammakill_Jimi_eat_world_ad53396f-c4d0-4c8b-ba06-b7132b0bf384.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/gammakill_Swiss_miss_piggy_7ec4513f-0271-4a47-9a61-898695fc9e45.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/hiar07ff5ca264a7.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/image+2.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/IMG_0795.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/IMG_4975.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/IMG_5155.jpg',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/jP_black_Moses_3_0e4e8a09-0cd9-4868-b315-302fd4191a95.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/jtherieau_Hopi_fashion_hybrid_by_Diane_Arbus_and_Hendrick_Kerst_455ba148-520c-4337-a4e2-adcb16db5769.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/karoo.studio_Melkbosstrand_1980s_hotrod_stunt_driver_stock_car__e324660a-f93a-44ff-b5b0-bb0331fc496a.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/larspars_a_sickly_sheep_fingers_are_pointing_at_it_its_his_faul_b356cb62-eff8-4c40-af26-2db118aec94c.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/leonardo_baia_ANCIENT_MESOPOTAMIA_STREET_SNAPSHOTS_7dca53be-fc29-402e-9694-5b506becab74.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/leonardo_baia_MESOPOTAMIAN_SHEPHERD_A_PASTORAL_MOMENT_3784aabd-1e19-41fc-a550-6165bec31a95.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Letty_a_night_starlight_background_with_a_golden_bridge_c65db75f-f1ac-43a7-be44-b48312eaf6bf.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/lndot_an_closeup_on_a_eye_made_of_crystals_4k_cinematic_award_w_fe854922-56f5-46b3-b0b4-a7612253ce31.PNG',
	 	 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Miha_spaceship_sailing_boat_on_orions_belt_outer_space_comets_s_89f1ca69-c8e3-4f83-95d7-9a6b0a20b003.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/manda_moose_king_Leopolds_goats_0b3a2f97-b697-4a63-9007-0a85fc6ebdd1.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/nedjem_tamit_a_middle-aged_Native_American_woman_in_tight-fitti_b83563d1-13a7-447b-a191-12d3dff37b9c.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/nedjem_tamit_pre-Raphaelite_style_painting_a_robot_and_Merlin_a_6bcb458a-ef25-416b-943c-4e1f1eec7422.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Nefelibata_escape_goat_4a95583e-8fef-4711-9686-cfcfeeac2259.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/NTannoy_Neil_Armstrong_wearing_his_NASA_blue_uniform_floating_i_1a72368e-f125-44b5-bb33-4b48d57964e9.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/0_0.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/8_Discord_3d-art_wang2023..PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/8.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/9.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/9DC3765F-C28C-451B-91E5-15349920777B_1_105_c.JPEG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/1443F33F-4597-4BC8-87B0-D7243AAC789A.JPG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/alealvaro_wide_low_angle_black_and_white_shot_gladiators_brutal_fb53de46-a1b3-4ff3-92db-60127c560ff9-2.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/8734717246762476.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/allive_special_cinematic_editorial_85d9000d-f9e1-4daf-8ad9-f42c13376246.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/B9670177-B0F6-476A-BCD1-2A68A73B0123.JPG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/bdond_party_in_my_runepunk_pants_by_Goya_fe5b420e-ca89-4b05-8de5-2d3d38c9825e.PNG',
	 	 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/binc_None_8f451fa0-35ed-4379-933a-b90bb36fdd8f.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/bluewing__walking_shadows_under_high-tech_structure_black_and_w_34059dbd-8bdb-4b0b-bcc4-69cbebfe639c.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/bmolson24_a_lone_space_traveller_discovering_a_new_universe_spa_53ccc5eb-333f-4fbe-9120-d5b00f357486.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/BradTheGeek_Too_many_cooks_break_the_camels_back._b009bd39-68b9-4ac7-b8cb-fb0384b7e0f1.PNG',
	 	 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/bussborg_imagnei_protpm_a7b20a4c-3059-4125-8308-b7f260cdcf3e.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/catx_tyrannosaurus_dinosaur_hunting_on_a_savannah_open_mouthed__b8a95670-2cfc-44c0-8811-4387d744d392.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/close-up-of-someone-licking-an-ice-cream-cone-that-looks-like-planet-earth-digital-art-null_4.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Clown_Fish.PNG',
	 	 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/damascus187_Luke_Skywalker_in_an_alleyway_with_a_purple_lightsa_ea3b2565-1401-4cd6-aa6f-ac8d04c23ebb.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/designer6342.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/dougi555_The_meridian_of_a_helicoid_is_a_line_which_intersects__d0ba861e-9065-4ffd-bad5-4f0fc887022f.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/eco_city_pyramidal_apartment_buildings_with_huge_garden_areas.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/eggon_archive_documentary_NASA_photo_of_the_Lunar_Whale_ancien_c3c56d4d-ca1b-4151-8265-f4727a19b124.PNG',
		 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/FD838455-C656-42FB-9F88-8265EB008BBC.JPG'
	  ];
	  	PreviewImagesPrompts: string[] = [
			  'A chimp with glasses and a suit sitting on an orange leather chair.',
			  'A batmobile kind of car in the city with big wheels.',
			  'A gigantic spatial station floating in an unknown planet.',
			  '6 donkey shaped mammals with suits playing cards, all sitting in a squared table.',
			  'An unknow jungle planet, in the middle there is an enormous castle with a big waterfall, it is a paradise.',
			  'A tall and big borroque room with a painting on the ceiling, 2 walls covered with books and 1 wall is a big window.',
			  'From the deepness of the ocean, a coral reef, looking at the sky, where a spiral of stars in the universe can be observed.',
			  'A big an muscular bear with broken clothes, sitting in a destroyed city. ',
			  'A girl and a pet dinosaur in 1880s.',
			  'A young man in suit flying in London passing by the Big Ben with crystal balls.',
			  'Marie Curie, a mad scientist inside a super technological laboratory, discovering radioactivity in subatomic particles.',
			  'A planet with mountain meadows of the future with spaceships, vintage and retro.',
			  'George Wahington writing the Rules of Civility and Decent Behavior document, hyperrealistic.',
			  'A white woman with short hair in a kaleidoscopic winter in wonderland.',
			  'Michelangelo is exhausted from painting so many chapels.',
			  'The surface on Jupiter, close up',
			  'A village of rockets in Texas.',
			  'A futuristic biophilic city of tommorrow, a walkable neighborhood.',
			  'A black man with half of the world in his head, and eating the other half of planet earth.',
			  'Swiss miss piggy in Grindelwald, Switzerland.',
			  'An american man with classic mustache, and with long mane, vintage and retro.',
			  'A blue and orange nebula in the dark universe.',
			  'A blue smurf walking in the forest, hyperrealistic.',
			  'A blonde simpson in the renaissance, with a dress alike to the Lisa Gherardini and with yellow flowers.',
			  'Erick Rosado, a man with long hair and long beard, both pink color.',
			  'Black Moses, with fish surroinding him, black and white retro picture.',
			  'A woman with long black hair with hopi fashion, hybrid, vintage and retro.',
			  'An american muscle car in the 80s, drifting and a crowd of people cheering and observing.',
			  'A sickly sheep pointing his thumb upwards.',
			  'Ancient Mosopotamia street Snapshots',
			  'Mesopotamian shepherd, a pastoral moment, with 2 docen sheeps.',
			  'A night starlight background with a golden bridg and a tiny wooden boat.',
			  'A closeup on an eye made of colorful crystals.',
			  'Spaceship sailing boat on Orions Belt outer space, with some comets and 5 planets.',
			  '2 King goats in borraque clothes, one is black with a beard, the other is white with no beard. ',
			  'Middle aged native american woman inside a scace ship, with a tight space suit.',
			  'A painting of a robot and Merlina',
			  'A goat escaping from jail, making a big jump.',
			  'Neil Armstrong wearing his NASA blue uniform floating inside a NASA spaceship.',
			  '2 cats resting in bunk beds with sunglasses and beachwear having a good time.',
			  'A robot similar to wall-e with a flower in his head and discovering the world in a beautiful landscape.',
			  'A man colonized mars, and he is seeing a vehicle arriving picking him up to keep discovering the misteries of the red planet.',
			  'A cyborg admiring planet mars, already colonized, with some rounded portals to unknown places.', 
			  'A geisha in Kyoto with a classical japanese umbrella, and with beautiful trees with orange leaves.',
			  'French soldiers walking in the french revolution, the streets are covered of smoke. ',
			  'Low angle black and white shot, galdiators in a brutal fight inside a colosseum.',
			  'A pug cute dog with a black small man wig, hyperrealistic',
			  'A woman about to touch fishes inside small fish tanks with a golden fish inside each tank.',
			  'George Washington dancing waltz on his bed, black and white vintage picture.',
			  'A surreal party, with people dressed 1700s clothes, the man has a big pointy blue hat that covers his face on has tiny holes in order for him to see, it is overall giving him a shark aspect while holding a glass of wine. ',
			  'Two men, one in a leather jacket, walking towards Saturn, the other man walking away from Saturn with a black hat. Black and white vintage picture.',
			  'A men and woman shadow walking inside a high technological building in New York city',
			  'A lonely man traveller with a hoodie discovering a new surreal universe',
			  '5 cooks and a camel in a desert, preparing something above the camel',
			  'A gigantic tree with many small houses in it, the tree is in the middle of a lake, thereofre there are 2 bridges that go to the tree. ',
			  'A dinosaur, tyrannosaurus rex with open mouth hunting a savannah (vintage).',
			  'A close up of a man licking an ice cream cone that looks like planet earth (hyperrealistic).',
			  'A clown fish, in his habitat, a beautiful coral reef',
			  'Luke skywalker, a man with a large black raincoat, a belt and boots, in an alleyway with a purple lightsaber',
			  'An egyptian pyramid, with a beautiful surrounding city, with little green vegetation. ',
			  'A beautiful village with a beautiful cathedral (vinatage and black and white). ',
			  'Ecological city with pyramidal apartment buildings with huge garden areas.',
			  'NASA picture of a whale shaped spaceship, landed in the moon, a shadow of a man is looking at the ship.',
			  'French soldiers in the french revolution running away from a smoke bomb'

		];
		latestImagePrompt;
	  welcomeTexts: string[] = [
		'La vida es lo que pasa mientras estás ocupado haciendo otros planes. - John Lennon',
		'No puedo cambiar la dirección del viento, pero puedo ajustar mis velas para llegar siempre a mi destino. - James Dean',
		'El único modo de hacer un gran trabajo es amar lo que haces. - Steve Jobs',
		'La única manera de hacer un gran trabajo es amar lo que haces. - Steve Jobs',
		'Si lo puedes soñar, lo puedes lograr. - Walt Disney',
		'El futuro depende de lo que hagamos en el presente. - Mahatma Gandhi',
		'La imaginación es más importante que el conocimiento. - Albert Einstein',
		'La verdadera medida de un hombre no se ve en cómo se comporta en momentos de comodidad y conveniencia, sino en cómo se mantiene en tiempos de controversia y desafío. - Martin Luther King Jr.',
		'La educación es el arma más poderosa que puedes usar para cambiar el mundo. - Nelson Mandela',
		'La paciencia es amarga, pero su fruto es dulce. - Jean-Jacques Rousseau',
		'El éxito no es la clave de la felicidad. La felicidad es la clave del éxito. Si amas lo que haces, tendrás éxito. - Albert Schweitzer',
		'Sólo se vive una vez, pero si lo haces bien, una vez es suficiente. - Mae West',
		'El camino hacia el éxito y el camino hacia el fracaso son casi exactamente los mismos. - Colin R. Davis',
		'El conocimiento habla, pero la sabiduría escucha. - Jimi Hendrix',
		'Amar y ser amado es sentir el sol desde ambos lados. - David Viscott',
		'La vida es lo que sucede mientras estás ocupado haciendo otros planes. - John Lennon',
		'La única forma de hacer un gran trabajo es amar lo que haces. - Steve Jobs',
		'Sólo los que se atreven a fracasar grandemente pueden lograr mucho. - Robert F. Kennedy',
		'Aprende las reglas como un profesional, para que puedas romperlas como un artista. - Pablo Picasso',
		'Si eres libre, tienes poder; si eres poderoso, eres libre. - Thomas Sowell',
		'Lo que no te mata, te hace más fuerte. - Friedrich Nietzsche',
		'Hazlo o no lo hagas, pero no lo intentes. - Yoda',
		'La lógica te llevará de A a B. La imaginación te llevará a todas partes. - Albert Einstein',
		'Nuestro mayor miedo no es que seamos inadecuados. Nuestro mayor miedo es que somos poderosos sin límite. - Marianne Williamson',
		'Cree que puedes y estás a medio camino. - Theodore Roosevelt',
		'La mejor venganza es un éxito masivo. - Frank Sinatra',
		'El cambio no es algo que temer, sino algo que abrazar. - Michelle Obama',
		'El viaje de mil millas comienza con un solo paso. - Lao-Tsé',
		'No vemos las cosas como son, sino como somos nosotros. - Anaïs Nin',
		'El secreto de la felicidad no es hacer siempre lo que se quiere, sino querer siempre lo que se hace. - León Tolstói',
		'La educación es el arma más poderosa que puedes usar para cambiar el mundo. - Nelson Mandela',
		'Nuestra mayor gloria no está en nunca caer, sino en levantarnos cada vez que caemos. - Confucio',
		'No hay camino para la paz, la paz es el camino. - Mahatma Gandhi',
		'La verdadera sabiduría está en reconocer la propia ignorancia. - Sócrates',
		'La creatividad es contagiosa, pásala. - Albert Einstein',
		'No puedes usar una brújula para navegar por el corazón humano. - Graham Greene',
		'Donde hay amor, hay vida. - Mahatma Gandhi',
		'Sé el cambio que quieres ver en el mundo. - Mahatma Gandhi',
		'Cada día es una página en tu historia. - Anónimo',
		'Si buscas resultados distintos, no hagas siempre lo mismo. - Albert Einstein',
		'Un día sin risa es un día perdido. - Charlie Chaplin',
		'El éxito es la suma de pequeños esfuerzos repetidos un día sí y otro también. - Robert Collier',
		'El futuro depende de lo que hagamos en el presente. - Mahatma Gandhi',
		'La única forma de hacer un gran trabajo es amar lo que haces. - Steve Jobs',
		'Sólo se vive una vez, pero si lo haces bien, una vez es suficiente. - Mae West',
		'La imaginación es más importante que el conocimiento. - Albert Einstein',
		'La educación es el arma más poderosa que puedes usar para cambiar el mundo. - Nelson Mandela',
		'La paciencia es amarga, pero su fruto es dulce. - Jean-Jacques Rousseau',
		'La verdadera medida de un hombre no se ve en cómo se comporta en momentos de comodidad y conveniencia, sino en cómo se mantiene en tiempos de controversia y desafío. - Martin Luther King Jr.',
		'La educación es el arma más poderosa que puedes usar para cambiar el mundo. - Nelson Mandela',
		'La paciencia es amarga, pero su fruto es dulce. - Jean-Jacques Rousseau',
		'El éxito no es la clave de la felicidad. La felicidad es la clave del éxito. Si amas lo que haces, tendrás éxito. - Albert Schweitzer',
		'Sólo se vive una vez, pero si lo haces bien, una vez es suficiente. - Mae West',
		'El camino hacia el éxito y el camino hacia el fracaso son casi exactamente los mismos. - Colin R. Davis',
		'El conocimiento habla, pero la sabiduría escucha. - Jimi Hendrix',
		'Amar y ser amado es sentir el sol desde ambos lados. - David Viscott',
		'La vida es lo que pasa mientras estás ocupado haciendo otros planes. - John Lennon',
		'La única forma de hacer un gran trabajo es amar lo que haces. - Steve Jobs',
		'Sólo los que se atreven a fracasar grandemente pueden lograr mucho. - Robert F. Kennedy',
		'Aprende las reglas como un profesional, para que puedas romperlas como un artista. - Pablo Picasso',
		'Si eres libre, tienes poder; si eres poderoso, eres libre. - Thomas Sowell',
		'Lo que no te mata, te hace más fuerte. - Friedrich Nietzsche',
		'Hazlo o no lo hagas, pero no lo intentes. - Yoda',
		'La lógica te llevará de A a B. La imaginación te llevará a todas partes. - Albert Einstein',
		'Nuestro mayor miedo no es que seamos inadecuados. Nuestro mayor miedo es que somos poderosos sin límite. - Marianne Williamson',
		'Cree que puedes y estás a medio camino. - Theodore Roosevelt',
		'La mejor venganza es un éxito masivo. - Frank Sinatra',
		'El cambio no es algo que temer, sino algo que abrazar. - Michelle Obama',
		'El viaje de mil millas comienza con un solo paso. - Lao-Tsé',
		'No vemos las cosas como son, sino como somos nosotros. - Anaïs Nin',
		'El secreto de la felicidad no es hacer siempre lo que se quiere, sino querer siempre lo que se hace. - León Tolstói',
		'La educación es el arma más poderosa que puedes usar para cambiar el mundo. - Nelson Mandela',
		'Nuestra mayor gloria no está en nunca caer, sino en levantarnos cada vez que caemos. - Confucio',
		'No hay camino para la paz, la paz es el camino. - Mahatma Gandhi',
		'La verdadera sabiduría está en reconocer la propia ignorancia. - Sócrates',
		'La creatividad es contagiosa, pásala. - Albert Einstein',
		'No puedes usar una brújula para navegar por el corazón humano. - Graham Greene',
		'Donde hay amor, hay vida. - Mahatma Gandhi',
		'Sé el cambio que quieres ver en el mundo. - Mahatma Gandhi',
		'Cada día es una página en tu historia. - Anónimo',
		'Si buscas resultados distintos, no hagas siempre lo mismo. - Albert Einstein',
		'Un día sin risa es un día perdido. - Charlie Chaplin',
		'El éxito es la suma de pequeños esfuerzos repetidos un día sí y otro también. - Robert Collier'
	  ];
	blurImage: boolean=false;
	safeVideoUrl: SafeResourceUrl;
	public edited: boolean=false;
	public blobfile:any;
	public mask: any;
	public pickImageCalled = false;
	@ViewChild('imageCanvas') canvas: ElementRef<HTMLCanvasElement>;

	public 	imageResponse: string= 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/6VAO6K7BSRDWRCMOVY3EIDBZ6A.png';
	public actual_section: string = "Alfanumerico";
	public available_sections: Array<string> = ["Alfanumerico", "Fotografico"];
	public icons_for_sections: Array<string> = [ "language", "Images"];

	//messages or call page start
	public main_actual_section: string = "mensajes";
	public main_available_sections: Array<string> = ["mensajes", "llamadas"];
	public main_icons_for_sections: Array<string> = [ "chatbubbles-sharp", "call-sharp"];
	//messages or call page end 


		// all calls or lost call page start
		public call_actual_section: string = "todas";
		public call_available_sections: Array<string> = ["todas", "perdidas"];
		public call_icons_for_sections: Array<string> = [ "albums-sharp", "filter-sharp"];
		//all calls or lost call page end 
	public showIntroduction: boolean=true;
	//message: string = ""; // Initialize the 'message' property
	//AI: boolean=false;
	web: boolean=false;
	response: string = "hola! Estoy aquí para responder todas tus preguntas sobre Laniakea y ayudarte en lo que necesites. No dudes en contactarme para explorar nuestras publicaciones. Tu curiosidad es el motor que impulsa nuestro diálogo. ¡Espero conectar contigo!"; // Initialize the response property
	//private apiKey = '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'; // Replace with your API key
	private apiKey = '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'; 
	private apiUrl = 'https://api.openai.com/v1/chat/completions';
	public contactList : Array<UserContact> = new Array<UserContact>();
	chats:string="chat";
	username:string="";
	public profile_id:any;
	@ViewChild('selectTrigger', { static: false }) selectTrigger: IonSelect;
	@ViewChild('selectVoices', { static: false }) selectVoices: IonSelect;
	public uploadTitleAndAudioSource: boolean=false;
	public updateAIScope:boolean=false;
	public records:any;
	public hideScopeSelection: boolean = true;
	record_id:any;
	TypePromptinterval;
	constructor(
		private clipboard: Clipboard,
		private modalController: ModalController,
		public chatService: ChatService,
		private navCtrl: NavController,
		private http: HttpClient, // Inject HttpClient
		private profile_service:ProfileService,
		public formservice: FormServiceService,
		private request: RequestService,
		private uploadMultimedia: UploadMultimediaService,
		private ref: ChangeDetectorRef,
		private sanitizer: DomSanitizer,
		private speechRecognition: SpeechRecognition,
		private metaService: Meta,
		private titleService: Title,
		private faio:FingerprintAIO,
	) { 

	}
	initializetiktok() {
		// Find the container element
		const container = document.getElementById('tiktok-widget');
	  
		// Set the TikTok HTML block to the innerHTML of the container
		/*
		container.innerHTML = `
		  <blockquote class="tiktok-embed" cite="https://www.tiktok.com/@laniakea_supercumulo/video/7148624054604418309" data-video-id="7148624054604418309" style="max-width: 330px; min-width: 325px; border-radius:21px">
			<section>
			  <a target="_blank" title="@laniakea_supercumulo" href="https://www.tiktok.com/@laniakea_supercumulo?refer=embed">@laniakea_supercumulo</a> Daniel Habif - &#34;MANTENTE FIRME&#34;.
			  <a title="mindfulnesschallenge" target="_blank" href="https://www.tiktok.com/tag/mindfulnesschallenge?refer=embed">#mindfulnesschallenge</a>
			  <a title="filosofando" target="_blank" href="https://www.tiktok.com/tag/filosofando?refer=embed">#filosofando</a>
			  <a title="aprendeconlaniakea" target="_blank" href="https://www.tiktok.com/tag/aprendeconlaniakea?refer=embed">#aprendeconlaniakea</a>
			  <a title="ly" target="_blank" href="https://www.tiktok.com/tag/ly?refer=embed">#ly</a>
			  <a title="viral" target="_blank" href="https://www.tiktok.com/tag/viral?refer=embed">#viral</a>
			  <a target="_blank" title="♬ sonido original - Laniakea" href="https://www.tiktok.com/music/sonido-original-7148624113912728326?refer=embed">♬ sonido original - Laniakea</a>
			</section>
		  </blockquote>
		`;*/
	  
		// Create the TikTok widget inside the container
		new TiKTok.widget({

		  container_id: 'tiktok-widget'
		});
	  }
	ngOnInit() { 
		document.title = "Revolutionary Software Solutions | App";
const metaDescription = document.querySelector('meta[name="description"]');
if (metaDescription) {
	console.log('attribute set ');
    metaDescription.setAttribute('content', 'Pioneers in accelerating software to tackle challenges that no one else can solve. Discover our innovative solutions and services.');
} else {
    const newMetaDescription = document.createElement('meta');
    newMetaDescription.name = 'description';
    newMetaDescription.content = 'Pioneers in accelerating software to tackle challenges that no one else can solve. Discover our innovative solutions and services.';
    document.head.appendChild(newMetaDescription);
	console.log('document created ');
}

		/*
		const script = document.createElement('script');
		script.src = 'https://www.tiktok.com/embed.js';
		script.async = true;
		script.onload = this.initializetiktok.bind(this);
		document.head.appendChild(script);*/
		//this.metaService.updateTag({ name: 'Mensajes, llamadas y videollamadas con IA y más.', content: 'Pioneros en acalerar software para afrontar retos que nadie más puede resolver.' });
		document
		.getElementsByTagName('meta')
		.namedItem('description')
		.setAttribute('content','My Meta Description Here');
			this.titleService.setTitle('Comunicación');
		  
		// Set an interval to change the welcome text every 3 seconds
		setInterval(() => {
			this.changeWelcomeText();
		  }, 30000);
		    // Set an interval to change the welcome text every 3 seconds
			setInterval(() => {
				if (this.showIntroduction===true){
					this.changeImageResponse();
				}
				
			  }, 10800);

	
		if (Capacitor.getPlatform() === 'web') {
		this.web=true;

		

		}
		
this.getProfile();
	}
	startDictation() {
		if (Capacitor.getPlatform() !== 'web') {
		this.speechRecognition.hasPermission()
		  .then((hasPermission: boolean) => {
			if (!hasPermission) {
			  this.speechRecognition.requestPermission();
			} else {
			  this.initiateDictation(); // Start dictation process
			}
		  });
		}else{
			this.callAwsSpeechRecognition();
		}
	  }

	  async startRecordingAudioToCloneVoice(){
		
		if(!this.dictationActive){
			this.dictationTime = 0;
			this.dictationActive = true;
			clearInterval(this.dictationTimer);
		  
			// Start the chronometer
			this.dictationTimer = setInterval(() => {
			  this.dictationTime++;
			}, 1000); // Update every second
			try {
			
		  
			 // Request access to the microphone
			  this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
	
			 // Set up MediaRecorder to capture audio data
			 const mediaRecorder = new MediaRecorder(this.stream);
			 let recordedChunks = [];
	   
			 // Define event handler for when data is available
			 mediaRecorder.ondataavailable = (event) => {
			   recordedChunks.push(event.data);
			 };
	   
			 // Define event handler for when recording is stopped
			 mediaRecorder.onstop = async () => {
				Swal.fire({
					title: 'Clonación inicializada!',
					text: 'El proceso de clonación ha sido inicializado.',
					icon: 'success',
					timer: 2000, // Show the alert for 2 seconds
					timerProgressBar: true,
					toast: true,
					position: 'top-end',
					showConfirmButton: false
				  });
				 if(!this.formservice.AI_message){
					Swal2.fire({
						title: 'Nombre de la vox/escopio de IA faltante.',
						text: 'Por favor, ingrese el nombre de la vox/escopio de IA.',
						icon: 'error',
						confirmButtonText: 'Entendido',
						color: '#ffffff',
						background: '#010f4e',
					  });
					  setTimeout(() => {
					  window.location.reload();
					}, 3900); 
					  return;
				 }
			   console.log('Recording stopped. Sending request to AWS Transcribe...');
			   const audioBlob = new Blob(recordedChunks, { type: 'audio/wav' });
	   
			   try {
				const currentTime = new Date().toISOString().replace(/:/g, '-').replace(/\./g, '-');
				const filename = currentTime + '.mp3';
				this.uploadMultimedia.post(audioBlob, filename).then(({ link, mimeType }) => {
	
	
					   const ruta = link;
						 console.log('ruta', ruta);
						
	
						 let notification = {
							create_voice: true,
							sample_file_url: ruta,
							voice_name: this.formservice.AI_message
					  };
				  
					  this.request.createRequestPost('YourArtificialVoices', notification)
						.then((data) => {
							console.log( 'data:', data);
							
							const text= 'La información ha sido enviada exitosamente. Para completar la configuración de la IA,: 1. Deberás ingresar conocimiento a la IA, esta información fungirá como conocimiento para la IA, y esta responda de manera alineada y acertada, ad hoc al conocimiento configurado. 2. Deberás seleccionar una imagen de perfil para el scopio de la IA.';
						  Swal2.fire({
							title: 'audio fuente y nombre de IA enviado',
							text: text,
							icon: 'success',
							confirmButtonText: 'Entendido',
							color: '#ffffff',
							background: '#010f4e',
						}).then((result) => {
							if (result.isConfirmed) {
								this.typeText(text,9);
							}
						});
						this.uploadTitleAndAudioSource=false;
						}).catch((error) => {
						  console.error('Failed to send notification:', error);
						
						});
	
	

	
			}).catch((error) => {
				console.error('Failed to send request:', error);
			});
			   } catch (error) {
				 console.error('Error receiving response from AWS Transcribe:', error);
			   }
			 };
	   
			 console.log('Starting media recorder...');
			 // Start recording audio
			 mediaRecorder.start();
			  // Store the MediaRecorder instance for later use
			  this.mediaRecorder = mediaRecorder;
		   } catch (error) {
			 console.error('Error calling AWS Transcribe service:', error);
		   }
		 } else {
			
			}
	  }
	  async callAwsSpeechRecognition() {
		if(!this.dictationActive){
		this.dictationTime = 0;
		this.dictationActive = true;
		clearInterval(this.dictationTimer);
	  
		// Start the chronometer
		this.dictationTimer = setInterval(() => {
		  this.dictationTime++;
		}, 1000); // Update every second
		try {
		
	  
		 // Request access to the microphone
		  this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });

		 // Set up MediaRecorder to capture audio data
		 const mediaRecorder = new MediaRecorder(this.stream);
		 let recordedChunks = [];
   
		 // Define event handler for when data is available
		 mediaRecorder.ondataavailable = (event) => {
		   recordedChunks.push(event.data);
		 };
   
		 // Define event handler for when recording is stopped
		 mediaRecorder.onstop = async () => {
		   console.log('Recording stopped. Sending request to AWS Transcribe...');
		   const audioBlob = new Blob(recordedChunks, { type: 'audio/wav' });
   
		   try {
			const currentTime = new Date().toISOString().replace(/:/g, '-').replace(/\./g, '-');
			const filename = currentTime + '.mp3';
			this.uploadMultimedia.post(audioBlob, filename).then(({ link, mimeType }) => {


				   const ruta = link;
					 console.log('ruta', ruta);
					
// Function to handle the response from AWS Transcribe
const handleResponse = (response) => {
    console.log('Received response from AWS Transcribe:', response);

    // Access relevant data from the response
     //this.transcriptionJobName = response.result.transcriptionJobName;
    const transcriptionText = response.result.transcriptionText;
    const jobStatus = response.result.jobStatus;

    // Log transcription result
    console.log('Transcription Job Name:', this.transcriptionJobName);
    console.log('Transcription Text:', transcriptionText);
    console.log('Job Status:', jobStatus);

    if (transcriptionText || jobStatus === 'COMPLETED' || transcriptionText ==='') {
		this.formservice.AI_message=transcriptionText;
		this.transcriptionJobName='';
        // If job is completed, end the process
        console.log('Transcription job completed.');
        clearInterval(interval); // Stop setInterval
        // Further processing of transcriptionText, or whatever action needed
    } 
};

if(!this.transcriptionJobName){
// Initial call to create transcription job
this.request.createRequestPost('createAWSTranscribe', { audioBlob: ruta }).then((response) => {
	this.transcriptionJobName = response.result.transcriptionJobName;
    handleResponse(response); // Handle the response
}).catch((error) => {
    console.error('Failed to send request:', error);
});
}

// Set interval to check job status every 5 seconds
const interval = setInterval(() => {
    this.request.createRequestPost('createAWSTranscribe', { transcriptionJobName: this.transcriptionJobName }).then((response) => {
		Swal.fire({
			title: 'Procesando...',
			text: 'El comando de voz se está procesando.',
			icon: 'success',
			timer: 45000, // Show the alert for 45 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-end',
			showConfirmButton: false
		  });
        handleResponse(response); // Handle the response
    }).catch((error) => {
        console.error('Failed to send request:', error);
    });

}, 5000);

		}).catch((error) => {
			console.error('Failed to send request:', error);
		});
		   } catch (error) {
			 console.error('Error receiving response from AWS Transcribe:', error);
		   }
		 };
   
		 console.log('Starting media recorder...');
		 // Start recording audio
		 mediaRecorder.start();
		  // Store the MediaRecorder instance for later use
		  this.mediaRecorder = mediaRecorder;
	   } catch (error) {
		 console.error('Error calling AWS Transcribe service:', error);
	   }
	 } else {
		if (this.stream) {
			this.stream.getTracks().forEach(track => track.stop());
		  }
		this.mediaRecorder.stop();
	   this.stopDictation();

	 }
   }
	  
	  
	  initiateDictation() {
		// Reset the chronometer
		if(!this.dictationActive){
		this.dictationTime = 0;
		this.dictationActive = true;
		clearInterval(this.dictationTimer);
	  
		// Start the chronometer
		this.dictationTimer = setInterval(() => {
		  this.dictationTime++;
		}, 1000); // Update every second
	  

		  // Ensures stopListening has fully executed before starting a new session
		  this.speechRecognition.startListening()
			.subscribe(
			  (matches: Array<string>) => {
				this.formservice.AI_message = matches[0];
			  },
			  (onerror) => {
				console.log('error:', onerror);
				this.stopDictation(); // Stop dictation on error
			  }
			);

	}else{
		this.stopDictation(); 
	}
	  }
	  
	  stopDictation() {
		// Stop the speech recognition service
		this.speechRecognition.stopListening();
		// Stop the chronometer
		this.dictationActive = false;
		clearInterval(this.dictationTimer);
		this.dictationTime = 0; // Reset the time
	  }
	  
	
	changeImageResponse (){

		// Generate a random number between 1 and 63
   const randomNumber = Math.floor(Math.random() * 63) + 1;
   
   this.blurImage = true;
   setTimeout(() => {
	 this.blurImage = false;
   }, 999); // Remove blur after 0.9 seconds
   // Change the current welcome text and reset the typeTextCalled flag
   this.imageResponse = this.PreviewImages[randomNumber - 1];
	// Apply blur effect for the first 0.9 seconds
	 setTimeout(() => {
	   this.blurImage = true;
	 }, 9900); // Apply blur after 0.1 seconds
	 let text;
	 text=this.PreviewImagesPrompts[randomNumber - 1];
	 let index = 0;
	 this.latestImagePrompt='';
	 clearInterval( this.TypePromptinterval);
	  this.TypePromptinterval = setInterval(() => {
	   if (index < text.length) {
		 this.latestImagePrompt += text.charAt(index);
		 index++;
	   } else {
		 clearInterval( this.TypePromptinterval);
	   }
	 }, 36);
	
}
changeWelcomeText() {
	if (this.showIntroduction===true){
   // Generate a random number between 1 and 63
   const randomNumber = Math.floor(Math.random() * 63) + 1;
   
   // Change the current welcome text and reset the typeTextCalled flag
   const response = this.welcomeTexts[randomNumber - 1];
   
   // Call typeText with the generated welcome text
   if(this.formservice.AI===false){
   this.typeText(response, 90);
   }else{
	this.synthesizeSpeech(response, 81);
   }
  
	}
 }
	async emitChange(event){
		//this.optionChange.emit(event.detail.value);
		this.actual_section = event.detail.value;
		this.pickImageCalled=false;

	}
	async main_emitChange(event){
		//this.optionChange.emit(event.detail.value);
		this.main_actual_section = event.detail.value;
		if(this.main_actual_section==='llamadas'){
			this.getHistoricCalls();
		}

	}
	async tags_emitChange(event){
		
		//this.optionChange.emit(event.detail.value);
	
		if( event.detail.value==='con llave' ){//|| event.detail.value == 'archivados'
this.ionViewillEnter(event.detail.value);
		}else{
			this.chatService.main_actual_chat_tag=event.detail.value;
		}
		console.log(this.chatService.main_actual_chat_tag);
		this.chatService.buildService(this.chatService.owner);
		console.log(this.chatService.main_actual_chat_tag);
	}
	async call_emitChange(event){
		//this.optionChange.emit(event.detail.value);
		this.call_actual_section = event.detail.value;
		if(this.call_actual_section==='todas'){
			this.getHistoricCalls();
		}else if (this.call_actual_section==='perdidas'){
			this.getLostHistoricCalls();
		}

	}
	
	ngAfterViewInit(){
	
		if (this.formservice.fromCreatePublication===true){
			console.log('fromCreatePublication ');
			this.actual_section='Fotografico';
			this.formservice.AI=true;
			
					}

					
			
	}
	ExitAI(){
		this.formservice.AI = false;
		this.pickImageCalled=false;

	}
	openAI() {
		setTimeout(() => {
			this.showIntroduction=false;
		  }, 9000);
		

		this.formservice.AI = true;

	  }
	  
  getProfile() {
		this.profile_service.getProfileInfo().then((data) => {
			data = JSON.parse(data);
			// this.profile_photo = data.profile_photo.route;
      this.profile_id = data.id;
		//	this.complete_name = data.name +" "+ data.last_name;
      //this.name= data.name;
      //this.last_name=data.last_name;
			this.username = data.username;
			this.response= '¡**@'+this.username + '** '+this.response;
      //this.email =data.email;
      //this.birth =data.birth;
      console.log(data.role_id);
      console.log('dat',data);
        //this.corp=data.role_id;
			this.typeText( this.response, 9);		
		}).catch((error) => {
			console.error(error);
		}).finally(() => {

		});
	}
	 sendChatMessage(message: string) {
		const audioFile = 'assets/sounds/send_sound.wav';
		 this.sound = new Howl({
		  src: [audioFile],
		  autoplay: true,
		  html5:true,
		  volume: 1.0, // Initial volume level (0.0 to 1.0)
		});
		this.sound.play();

		Swal.fire({
			title: 'Procesando...',
			text: 'El mensaje se está procesando.',
			icon: 'success',
			timer: 45000, // Show the alert for 45 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-end',
			showConfirmButton: false
		  });
		const headers = new HttpHeaders()
		  .set('Content-Type', 'application/json')
		  .set('Authorization', `Bearer ${this.apiKey}`);
	if (this.actual_section=== 'Alfanumerico'){
			this.apiUrl= 'https://api.openai.com/v1/chat/completions';
			const requestBody = {
				messages: [{ role: 'system', content: message }],
				model: 'gpt-4o-2024-05-13', // Use the desired model name
				temperature: 0.9,
	
			};
		
			return this.http.post(this.apiUrl, requestBody, { headers });
	}else if (this.actual_section=== 'Fotografico'){
		this.latestImagePrompt=message;
		if (this.pickImageCalled===true) {
			if (this.edited===true){

			
			// Find the button element by its ID
const finalizeButton = document.getElementById('finalizeButton');

// Programmatically click the button
finalizeButton.click();
		
	// Define the API endpoint
	this.apiUrl = 'https://api.openai.com/v1/images/edits';

	
	// Create a FormData object to send the image
	const formData = new FormData();
	formData.append('image',  this.blobfile ); // Pass the  image with a name
	formData.append('mask',  this.mask ); // Pass the  image with a name
	formData.append('model',  'dall-e-2' ); // Pass the  image with a name
	formData.append('prompt', message); // A text description of the desired image
	formData.append('response_format', 'b64_json'); // Response format
	// Add other parameters as needed
  
	const headers = new HttpHeaders()
	  .set('Authorization', `Bearer ${this.apiKey}`);

return this.http.post(this.apiUrl, formData, { headers });
}else{
this.apiUrl= 'https://api.openai.com/v1/images/variations';
// Create a FormData object to send the image
const formData = new FormData();
formData.append('image',  this.blobfile ); // Pass the  image with a name
formData.append('model',  'dall-e-2' ); // Pass the  image with a name
formData.append('response_format', 'b64_json'); // Response format
// Add other parameters as needed

const headers = new HttpHeaders()
  .set('Authorization', `Bearer ${this.apiKey}`);

return this.http.post(this.apiUrl, formData, { headers });
}
		  }
		  
		  else{
			this.apiUrl= 'https://api.openai.com/v1/images/generations';
			const requestBody = {
				model: "dall-e-3",
				prompt: message, // Adjust this based on the requirements of your endpoint
				response_format: 'b64_json'
			  };
		  
			  return this.http.post(this.apiUrl, requestBody, { headers });
		  }
	

	}
	  }
	  SendMessage1(){
		  this.formservice.AI_message = '¿Cuáles son las diferencias entre los tipos de publicaciones de Laniakea?';
		  this.sendMessageToOpenAI();
	  }
	  SendMessage2(){
		this.formservice.AI_message = '¿Tienes algunas ideas creativas para hacer publicaciones educativas?';
		this.sendMessageToOpenAI();
	}
	SendMessage3(){
		this.formservice.AI_message = '¿Qué rayos es un supercúmulo? Porfavor explícame de manera sencilla.';
		this.sendMessageToOpenAI();
	}
	SendMessage1Image(){
		this.formservice.AI_message = 'En alta definición: Albert Einstein dando una lección en Educación Epoch en una conferencia en línea, con una computadora último modelo';
		this.sendMessageToOpenAI();
	}
	SendMessage2Image(){
	  this.formservice.AI_message = 'En alta definición: Espectacular de Charles Chaplin en el estreno de su película producida en Neptuno';
	  this.sendMessageToOpenAI();
  }
  SendMessage3Image(){
	  this.formservice.AI_message = 'En alta definición: Un puerto espacial con biodiversidad y capa de ozono alreadedor de Marte';
	  this.sendMessageToOpenAI();
  }

	
	 async sendMessageToOpenAI() {
		this.formservice.voiceCommandListernActive=false;
		Swal.fire({
			title: 'Procesando...',
			text: 'El mensaje se está procesando.',
			icon: 'success',
			timer: 45000, // Show the alert for 45 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-end',
			showConfirmButton: false
		  });
		  if (this.pickImageCalled===true){
			  			// Find the button element by its ID
				const finalizeButton = document.getElementById('finalizeButton');

				// Programmatically click the button
				finalizeButton.click();
		  }
		  try {
		  this.request.createRequestPost('createChatRequest',{sender_id: this.profile_id, prompt:this.formservice.AI_message, CharacterName: this.CharacterName}) .then((data) => {

			if(data){
				console.log('recieved data :', data);
				console.log('Request_Type', data.Request_Type);
				console.log('ID', data.ID);

	
		  this.showIntroduction=false;
		  if(this.actual_section=== 'Alfanumerico'){
			if (this.CharacterName === 'Soporte Laniakea' || this.CharacterName === 'Erick Rosado') {
				if(!this.threadId){
					Swal2.fire({
						title: `Estás por iniciar un hilo con el humanoide de ${this.CharacterName}`,
						text: `Al iniciar un hilo con el humanoide de ${this.CharacterName}, asegúrate de no cerrar la aplicación, ya que el hilo se perderá inmediatamente. Cada mensaje enviado al humanoide de soporte, tiene un costo base de ${this.TarifaXsolicitud} MXN, que será cargado a Hubble. `,
						icon: 'info',
						confirmButtonText: 'Entendido',
						color: '#ffffff',
						background: '#010f4e',
					}).then((result) => {
						if (result.isConfirmed) {
							this.assistantRequest(data);
						}
					});
				}else{
					this.assistantRequest(data);
				}
			
			
				
			  } else{
				this.sendChatMessage(this.CharacterDescription+this.formservice.AI_message)
				.subscribe((response: any) => {
				  console.log(response);
				  if (response && response.choices && response.choices.length > 0) {
					  const completion = response.choices[0].message.content;
	  
		  const responseToSynthetize = 'Inteligible @'+ this.username + ' .' + completion + '..'; // Set the response property
		  //if (!this.record_id){
		  this.synthesizeSpeech(responseToSynthetize, 69);
	  //}
	  const audioFile = 'assets/sounds/receive_sound.wav';
	  this.sound = new Howl({
			  src: [audioFile],
			  autoplay: true,
			  volume: 1.0, // Initial volume level (0.0 to 1.0)
			});
			this.sound.play();
					Swal2.fire({
					  title: 'Respuesta generada exitosamente, descárgala.',
					  text: 'Copia la respuesta, descargala ahora, ya que más tarde podrá no estar disponible',
					  icon: 'success',
					  confirmButtonText: 'Entendido',
					  color: '#ffffff',
					  background: '#010f4e',
					});
			  let yourAIVoice;
			  //if (this.record_id){
			  //	 yourAIVoice=this.record_id;
			  //}
					this.request.createRequestPost('updateChatRequest',{ID: data.ID, response: this.response, sender_id: this.profile_id, yourAIVoice: yourAIVoice}) .then((data) => {
						console.log(data);
	  
				  }).catch((error) => {
					  console.error('Failed to send request:', error);
				  });
	  
				  } else {
					console.error('Invalid response from OpenAI API');
				  }
				}, error => {
				  Swal2.fire({
					  title: 'Hubo un error inesperado',
					  text: 'Si el error persiste comunícate con el soporte de Laniakea',
					  icon: 'error',
					  confirmButtonText: 'Entendido',
					  color: '#ffffff',
					  background: '#010f4e',
					});
				  console.error('Error sending message:', error);
				});
			  }
		}else {
			this.sendChatMessage('Hola, porfavor proporciona una imagen realista, acertada, cabal, inteligible, materialista, tangible, objetiva, simple, precisa, determinada, definida y concreta. En tanto a atributos tridimensionales inteligibles, cognoscibles, racionales y lógicos de: '+this.formservice.AI_message)
		  .subscribe((response: any) => {
			console.log(response);
			if (response ) {
				Swal2.fire({
					title: 'Imagen creada exitosamente, descárgala.',
					text: 'Descarga la imagen, compártela ahora, ya que más tarde podrá no estar disponible',
					icon: 'success',
					confirmButtonText: 'Entendido',
					color: '#ffffff',
					background: '#010f4e',
				  });
		// Assuming that the response contains an array of objects with "url" properties
		this.imageResponse = response.data[0].b64_json;
		let blobfile;
		console.log('image url', this.imageResponse);
		
		// Your JSON object with the Base64 data
const base64Data = this.imageResponse;

// Remove the 'data:image/png;base64,' prefix if it's present
const base64Image = base64Data.replace(/^data:image\/(png|jpeg|jpg);base64,/, '');

// Decode the Base64 data into a byte array
const byteCharacters = atob(base64Image);
const byteNumbers = new Array(byteCharacters.length);
for (let i = 0; i < byteCharacters.length; i++) {
  byteNumbers[i] = byteCharacters.charCodeAt(i);
}
const byteArray = new Uint8Array(byteNumbers);

// Create a Blob from the byte array
const blob = new Blob([byteArray], { type: 'image/png' }); // Change the type as needed

// Now 'blob' contains the image as a Blob object
console.log('Blob:', blob);

blobfile=blob;

// You can use the Blob object as needed

   const currentTime = new Date().toISOString().replace(/:/g, '-').replace(/\./g, '-');
   const filename = currentTime + '.png';
   // You can do whatever you want with the Blob object here
   this.uploadMultimedia.post(blobfile, filename).then(({ link, mimeType }) => {
	this.pickImageCalled=false;
	this.edited=false;
	   const ruta = link;
		 console.log('ruta', ruta);
		 this.imageResponse=ruta;
	   this.request.createRequestPost('updateChatRequest',{ID: data.ID, response: ruta}) .then((data) => {
		   console.log(data);
	   }).catch((error) => {
		   console.error('Failed to send request:', error);
	   });

	 }).catch((error) => {
		 console.error('Failed to send request:', error);
	 });

		this.Share();
			} else {
			  console.error('Invalid response from OpenAI API');
			}
		  }, error => {
			Swal2.fire({
				title: 'Hubo un error inesperado',
				text: 'Si el error persiste comunícate con el soporte de Laniakea',
				icon: 'error',
				confirmButtonText: 'Entendido',
				color: '#ffffff',
				background: '#010f4e',
			  });
			console.error('Error sending message:', error);
		  });
		}
	}
	}).catch((error) => {
		Swal2.fire({
			title: 'La solicitud no se pudo completar',
			text: 'Verifica tu cartera Hubble y tu moneda seleccionada para solicitudes automatizadas, si el error persiste, comunícate con el soporte de Laniakea',
			icon: 'error',
			confirmButtonText: 'Entendido',
			color: '#ffffff',
			background: '#010f4e',
		}).then((result) => {
			if (result.isConfirmed) {


		  this.formservice.sectionVer="regalos"  
		  this.modalController.create({
			component:PublicacionesColeccionComponent,
			cssClass: 'detailsInModal',
			swipeToClose: true,
			componentProps: {
			  profile_id: this.profile_id,
			}
		  }).then((modal) => {
			modal.present();
		  }).catch((error) => {
			console.error(error);
		  });
		}
	});
	
		console.error('Failed to send request:', error);
	});
} catch (error) {
    console.error('Error:', error);
    throw error;
  }
	  }
	  async Share(){
		
		const url = this.imageResponse;
		await Share.share({
		  url: url,
		});
	  }
	public openRelationList() {
		this.modalController.create({
			component: RelationsListComponent,
			cssClass: 'detailsInModal',
			swipeToClose: true,
			componentProps: {
				contactList: this.contactList
			}
		}).then((modal)=>{
			modal.present();
		}).catch((error)=>{
			console.error(error);
		});
	}
	NuevoMd(){
		this.navCtrl.navigateForward('search');
	}

	public openGroupList() {
		this.modalController.create({
			component: CreateGroupComponent,
			cssClass: 'detailsInModal',
			swipeToClose: true,
			componentProps: {
				contactList: this.contactList
			}
		}).then((modal)=>{
			modal.present();
		}).catch((error)=>{
			console.error(error);
		});
	}


	onClick(app:string){
		switch(app){
			case 'etiqueta':
				this.chats="etiqueta"

				break;
				case 'archivo':
					this.chats="archivo"
	
					break;
	

		}
	}
	chime(){
		this.formservice.webViewLive=false;
		if (Capacitor.getPlatform() === 'web') {
			
			this.modalController.create({
				//component: ModulesLockComponent,
				component: ChimeContactComponent,
				cssClass: 'detailsInModal',
				swipeToClose: true,
				componentProps: {
				username:this.username
					},
			}).then((modal)=>{
				modal.present();
			
			});
		}else{
			let url= `https://supercumulo.laniakea.tv/loader?meeting=0`;
			this.openBrowser(url);
		}

		}
		async openBrowser(url: string) {
			//await Browser.open({ url });
			const modal = await this.modalController.create({
				cssClass: 'detailsInModal',
				swipeToClose: true,
				component: InlineBowsingComponent,
				componentProps: {
					url: url,
				
				},
			  });
			  modal.onDidDismiss().then((data)=>{
				
			  });
			  return await modal.present();
			window.open(url, '_blank'); // Open the URL in a new tab or window
		  }
		click_on_share() {
			
			
			const text = `Respuesta cuántica de Laniakea: ${this.response}`;
			if(Capacitor.getPlatform() !== 'web'){
			// Copy the URL to clipboard
			this.clipboard.copy(text)
			  .then(() => {
				console.log('text copied to clipboard:', text);
				Swal.fire({
				  title: 'Respuesta copiada!',
				  text: 'La respuesta ha sido copiado en el portapapeles.',
				  icon: 'success',
				  timer: 2000, // Show the alert for 2 seconds
				  timerProgressBar: true,
				  toast: true,
				  position: 'top-end',
				  showConfirmButton: false
				});
			  })
			  .catch((error) => {
				console.error('Error copiando el URL en el portapapeles:', error);
				Swal.fire({
				  title: 'Error',
				  text: 'Error copiando el URL en el portapapeles.',
				  icon: 'error',
				  toast: true,
				  position: 'top-end',
				  showConfirmButton: false,
				  timer: 2000 // Show the alert for 2 seconds
				});
			  });
			}else{
				navigator.clipboard.writeText(text).then(() => {
					Swal.fire({
						title: 'Respuesta copiada!',
						text: 'La respuesta ha sido copiado en el portapapeles.',
						icon: 'success',
						timer: 2000, // Show the alert for 2 seconds
						timerProgressBar: true,
						toast: true,
						position: 'top-end',
						showConfirmButton: false
					  });
				}).catch((error) => {
					//console.error(error);
				});
			}
		  }
		  async pickVideo() {
			if (Capacitor.getPlatform() === 'ios') {
			  Swal2.fire({
				title: 'Añade videos en tu dispositivo apple',
				text: 'Para añadir videos optimizados añade el video de tu librería de Fotos a tus Archivos. A continuación la opción optimizada es: Choose File',
				icon: 'info',
				confirmButtonText: 'Entendido',
				color: '#ffffff',
				background: '#010f4e',
			  });
			}
			
			//const videoPreviewElement = document.getElementById("videoSource") as HTMLVideoElement;
		  
			const fileInput = document.createElement("input");
			fileInput.type = "file";
			//fileInput.accept = "video/*";
			if(this.uploadTitleAndAudioSource){
				fileInput.accept = "audio/*";
			}
			fileInput.multiple = false;
		  
			fileInput.addEventListener("change", () => {
			  const files = fileInput.files;
		  
			  if (files && files.length) {
				const file = files[0];
		
				const mimeType = file.type;
		
		
				const blobfile = file;
				const filename = file.name;
			   // Create a MediaItem object from the File
			  const mediaItem: MediaItem = {
				mediaPath: file.name,
				typeData: file.type,
				nameFile: file.name,
				id: 1, // Assign a unique ID here if needed
				size: file.size,
				index: 0, // Set the desired index value
				route: "", // Set the route value if needed
				status: "", // Set the initial status
				url: "", // Set the URL property
				localUrl: "", // Set the local URL property
				type: "", // Set the type property
				is_premium: 0, // Set the is_premium property
				// Add any other required properties
			  };
		
			  this.uploadMultimedia.blob = blobfile;
			  this.uploadMultimedia.filename = filename;
			//	if (this.formservice.multimedia.length > 0) {
				  this.formservice.multimedia[0] =  mediaItem;
			//	} else {
			//	  this.formservice.multimedia.push(mediaItem);
			//	}
			Swal2.fire({
				title: 'Audio listo para subir',
				position: 'top-end',
				toast: true,
				text: 'El audio ya está listo para subirse, solo envíalo.',
				icon: 'success',
				confirmButtonText: 'Entendido',
				color: '#ffffff',
				background: '#010f4e',
			  });
		  
				const videoURL = URL.createObjectURL(file);
		  
				// Update the video source and display it
				//videoPreviewElement.src = videoURL;
			  }
			});
		  
			// Trigger the file input dialog
			fileInput.click();
		  }
		
		  LaniakeaMediaPicker(){
			Swal.fire({
				title: 'Selecciona una foto',
				text: `¿Deseas elegir la imagen desde Google Drive o desde tu dispositivo?`,
				icon: 'info',
				//timer: 6900, // Show the alert for 3.9 seconds
				//timerProgressBar: true,
			//	toast: true,
			//	position: 'top-end',
				showCancelButton: true,
				confirmButtonText: 'Google Drive',
				cancelButtonText: 'Este dispositivo',
				color: '#ffffff',
				background: '#010f4e',
			  }).then((result) => {
				if (result.isConfirmed) {
					this.googlePicker();
				}else{
				this.pickImage();
				}
			  });
		
		  }
		  async pickImage(){
		if(Capacitor.getPlatform() === 'web'){
			const imagePreviewElement = document.getElementById("imagePreview") as HTMLImageElement;
			try {
			  const options: CameraOptions = {
				quality: 100,
				allowEditing: false,
				resultType: CameraResultType.Uri,
				source: CameraSource.Photos,
				saveToGallery: false,
			  };
			  console.log('About to call Camera.getPhoto()');
			  const media = await Camera.getPhoto(options);
		  
			  if (media) {
				this.pickImageCalled=true;
				console.log('Media picked successfully:', media);

			  if (Capacitor.getPlatform() === 'web') {
				// For web, create a Blob from the media data and convert it to a data URL
				const response = await fetch(media.webPath);
				const blobfile = await response.blob();
				const reader = new FileReader();
				reader.onloadend = () => {
				  const imageUrl = reader.result as string;
				  imagePreviewElement.src = imageUrl;
				};
				reader.readAsDataURL(blobfile);
			  } 
			
				this.ref.detectChanges();
				const mimeType = media.format === 'jpeg' ? 'image/jpeg' : media.format === 'png' ? 'image/png' : 'video/mp4';
		  
				// Read the file as a Blob
				const response = await fetch(media.webPath);
				const blobfile = await response.blob();
				
		  this.blobfile=blobfile;
		
				// Log the Blob for debugging purposes
				console.log('fileBlob:', blobfile);

				//const filename = media.path.substring(media.path.lastIndexOf('/') + 1);
				if(!this.updateAIScope){
		this.prepareImage();
				}
		let filename;

			const currentTime = new Date().toISOString().replace(/:/g, '-').replace(/\./g, '-');
			filename = currentTime + '.png';
		  
				
			  }
			} catch (error) {
			  console.error('Error picking media:', error);
			}
		}else{
			if (Capacitor.getPlatform() === 'ios') {
				Swal2.fire({
				  title: 'Añade fotos en tu dispositivo apple',
				  text: 'Para añadir fotos optimizados añade el video de tu librería de Fotos a tus Archivos. A continuación la opción optimizada es: Choose File. La imagen debe ser PNG',
				  icon: 'info',
				  confirmButtonText: 'Entendido',
				  color: '#ffffff',
				  background: '#010f4e',
				});
			  }
			  const fileInput = document.createElement('input');
			  fileInput.type = 'file';
			  fileInput.accept = 'image/*';
			  fileInput.multiple = false;
			  
			  fileInput.addEventListener('change', async (event) => {
				const target = event.target as HTMLInputElement;
				const files = target.files;
				if (files && files.length > 0) {
					this.pickImageCalled=true;
				  const media = files[0];
				  console.log('Media picked successfully:', media);
			  
				  const mimeType = media.type;
			  
				  // Read the file as a Blob
				  const blobfile = media;
			  
				  const filename = media.name;
			  
				  // Process the Blob here
				  // ...
			  
				  this.blobfile = blobfile;
				if(!this.updateAIScope){
					this.prepareImage();
				}
				  
				
				} else {
				  console.log('Media picking cancelled by the user.');
				  // Handle cancellation here (e.g., display a message to the user)
				}
			  });
			  
			  fileInput.click();
		}

		  }  
		  
		
		 




async prepareImage() {
	Swal2.fire({
		title: 'Edición de imagen',
		text: 
		`1. Presiona sobre la imagen, dibuja el área a modificarse dilegentemente y detalladamente en la imagen, cuadro por cuadro, una imagen bien editada vale más que mil palabras, a su vez da una descripción completa de la nueva imagen, con incapie de lo que debe modificarse en el área dibujada.          

		2. Al no editar la imagen en lo absoluto simplemente se hará una variación espontánea de tu imagen.                                   



		3. Solo puedes crear una imagen a la vez.
		
		4. Asegúrate que la imagen sea en formato PNG menor a 4mb.`,
		icon: 'info',
		showCancelButton: false,
		confirmButtonText: 'ok',
		cancelButtonText: 'Ni modo',
		color: '#ffffff',
		background: '#010f4e',
	  }).then((result) => {
		if (result.isConfirmed) {
		}
	});
	// Initialize variables to keep track of drawing state and the canvas context
let isDrawing = false;
let context = null;
  // Create an Image object
  const imageElement = new Image();
  
  // Assuming this.blobfile contains your image URL or Base64 data
  // If this.blobfile is a Blob object, convert it to a data URL
  const imageUrl = URL.createObjectURL(this.blobfile);
  imageElement.src = imageUrl;

  // When the image has loaded
  imageElement.onload = () => {
    // Get the canvas container element by its id
    const canvasContainer = document.getElementById('canvasContainer');
    const canvas = document.createElement('canvas');
    canvasContainer.appendChild(canvas);
	   // Set canvas dimensions to 690x690 pixels


    // Set canvas dimensions to match the image
  //  canvas.width = imageElement.width;
   // canvas.height = imageElement.height;
	canvas.width = window.innerWidth * 0.99;
canvas.height =  window.innerHeight * 0.78;
	// Adjust the canvas's internal dimensions to match the style


    // Initialize the canvas context
    context = canvas.getContext('2d');

	

    // Draw the image on the canvas
    context.drawImage(imageElement, 0, 0, window.innerWidth * 0.99,  window.innerHeight * 0.78);

    // Add a mousedown event listener to the canvas for drawing circles
    canvas.addEventListener('mousedown', (e) => {
		this.edited=true;
      isDrawing = true;
      drawCircle(e);
    });

    canvas.addEventListener('mousemove', (e) => {
      if (isDrawing) {
        drawCircle(e);
      }
    });

    canvas.addEventListener('mouseup', () => {
      isDrawing = false;
    });

// Add touch event listeners to the canvas for drawing squares
canvas.addEventListener('touchstart', (e) => {
    this.edited = true;
    isDrawing = true;
    drawCircle(e.touches[0]); // Use the first touch point
});

canvas.addEventListener('touchmove', (e) => {
    e.preventDefault(); // Prevent scrolling while drawing
    if (isDrawing) {
        drawCircle(e.touches[0]); // Use the first touch point
    }
});

canvas.addEventListener('touchend', () => {
    isDrawing = false;
});

	function drawCircle(e) {
		if (isDrawing) {
		  const rect = canvas.getBoundingClientRect();
		  const x = e.clientX - rect.left;
		  const y = e.clientY - rect.top;
	  
		  // Customize the circle size as needed
		  const circleSize = 20;
	  
		  // Save the current canvas state
		  context.save();
	  
		  // Clear a transparent circle at the clicked position
		  context.clearRect(x - circleSize / 2, y - circleSize / 2, circleSize, circleSize);
	  
		  // Restore the canvas state to prevent erasing future circles
		  context.restore();
		}
	  }
	  
	  
    
    // Add a button click event listener to finalize the canvas editing
    const finalizeButton = document.getElementById('finalizeButton');
    finalizeButton.addEventListener('click', () => {
      // Convert the canvas to a Blob with RGBA format
      canvas.toBlob((blob) => {
        // Update the blobfile with the edited image
        this.mask = blob;

        // Debugging: Log the Blob
        console.log('Edited Blob with RGBA format:', this.mask);

        // Clean up the blob URL
        URL.revokeObjectURL(imageUrl);
      }, 'image/png');
    });
  };
}
  
typeText(text: string, delay: number) {
    let index = 0;
	this.response='';
    const interval = setInterval(() => {
      if (index < text.length) {
        this.response += text.charAt(index);
        index++;
      } else {
        clearInterval(interval);
      }
    }, delay);
  }
  public async synthesizeSpeech(text: string, typeTextDelay: number): Promise<void> {
	try {
		if(!this.muted){
		// Remove all non-alphanumeric characters from the text
		const cleanedText = text.replace(/[^a-zA-Z0-9 ]/g, '');
	  const audioBlob = await this.fetchAudioBlob(cleanedText);
	  if (audioBlob) {
		console.log('audioBlob', audioBlob);
		const audioUrl = URL.createObjectURL(audioBlob);
		console.log('audioUrl', audioUrl);
		const currentTime = new Date().toISOString().replace(/:/g, '-').replace(/\./g, '-');
		const filename = 'VozIA'+currentTime;
		let videoElement;
		let avatarAudio;
		if(!this.showIntroduction){

	
	   this.uploadMultimedia.post( audioBlob ,
		  filename ).then(({ link, mimeType }) => {

		   const ruta = link;
		   console.log('ruta', ruta);
	  
	
		   this.request.createRequestPost('getTTSAIAvatar', {audio_url:link,avatar:this.CharacterImage, text:cleanedText})
		   .then((data) => {
		   console.log(data);
		   if(data.success.id){
		
			let id='tlk_0AH0oXYbVf8XRHGKGfspz';
			id=data.success.id;
			const handleGet = () => {
				this.request.createRequestPost('getTTSAIAvatar', { id: id})
					.then((data) => {
						if (data.success.result_url) {
							console.log(data.success);
				
							   videoElement = document.getElementById('aiSpeakingVidoe') as HTMLVideoElement;
							   avatarAudio=data.success.result_url;
								
					
				
							// If the post was successful, show success message and stop looping
							Swal2.fire({
								title: 'Video generado',
								text: 'El video se ha generado exitosamente',
								icon: 'success',
								toast: true,
								timer: 45000, // Show the alert for 45 seconds
								position: 'top-end',
								confirmButtonText: 'Entendido',
								color: '#ffffff',
								background: '#010f4e',
								showConfirmButton: false
							});
						} else {
							// If the post is still being processed, show processing message and continue looping
							Swal2.fire({
								title: 'Procesando...',
								text: 'El video se está procesando.',
								icon: 'success',
								timer: 45000, // Show the alert for 45 seconds
								timerProgressBar: true,
								toast: true,
								position: 'top-end',
								showConfirmButton: false
							});
							// Call the function recursively to check again
							handleGet();
						}
					});
			  };
			  
			  // Start the process by calling the function
			  handleGet();
			  
		   }else{
			Swal2.fire({
				title: 'Procesando...',
				text: 'Hubo algún error inesperado.',
				icon: 'error',
				timer: 45000, // Show the alert for 45 seconds
				timerProgressBar: true,
				toast: true,
				position: 'top-end',
				showConfirmButton: false
			});
		   }

	 
		   }).catch((error) => {
		 console.error('Failed to send notification:', error);
	 
		   });
		});
		
	}
		const ionImgElement = document.getElementById('aiSpeakingImg') as HTMLIonImgElement;
					ionImgElement.src = 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/vibrations.gif';
			this.sound = new Howl({
				src: [audioUrl],
				format: ['mp3'], // Specify the audio format as MP3
				autoplay: true,
				volume: 1.0,
				onend: function() {
					const ionImgElement = document.getElementById('aiSpeakingImg') as HTMLIonImgElement;
					ionImgElement.src = 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/numbers.gif';
					if (!this.showIntroduction && videoElement) {
						videoElement.src = avatarAudio;
						videoElement.controls = true;
					}
				  }
			  });
				
			  this.sound.play();
		}
		
    }
		// Now you can pass the synthesized text to the typeText function
		this.typeText(text, typeTextDelay);
	
	} catch (error) {
	  console.error('Error synthesizing speech:', error);
	}
  }

  
  private async fetchAudioBlob(text: string): Promise<Blob | null> {
	const request = {
	  input: {
		text,
	  },
	  voice: {
		languageCode: 'es-US',
		name: 'es-US-Standard-A',
	  },
	  audioConfig: {
		audioEncoding: 'LINEAR16', // Request audio in a format that can be converted
		effectsProfileId: ['small-bluetooth-speaker-class-device'],
		pitch: -20,
		speakingRate: 0.81,
	  },
	};
  
	try {
	  const response = await this.http
		.post(`${this.TextToSpeechApiUrl}?key=${this.TextToSpeechApiKey}`, request, {
		  responseType: 'blob',
		})
		.toPromise();
  
	  console.log('API Response:', response);
	 // if (Capacitor.getPlatform() === 'web') {
	  // Check if the response content type is "application/json"
	  if (response.type === 'application/json') {
		const jsonText = await response.text();
		const jsonResponse = JSON.parse(jsonText);
  
		// Extract the audio content from the JSON response
		const audioContent = jsonResponse.audioContent;
  
		// Convert the base64 audio content to a Blob
		const byteCharacters = atob(audioContent);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
		  byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		const audioBlob = new Blob([byteArray], { type: 'audio/mpeg' });
  
		return audioBlob;
	  //} else {
	//	console.error('Invalid response content type:', response.type);
		//return null;
	//  }
	}else{

					  // Handle non-web platforms
					  const responseText = await response.text();
					  console.log('Response Text:', responseText);
					  
					  try {
						const jsonResponse = JSON.parse(responseText); // Parse the response text into JSON
						const audioContent = jsonResponse.audioContent;
					  
						const byteCharacters = atob(audioContent);
						const byteNumbers = new Array(byteCharacters.length);
					  
						for (let i = 0; i < byteCharacters.length; i++) {
						  byteNumbers[i] = byteCharacters.charCodeAt(i);
						}
					  
						const byteArray = new Uint8Array(byteNumbers);
						const audioBlob = new Blob([byteArray], { type: 'audio/mpeg' });
					  
						return audioBlob;
					  } catch (error) {
						console.error('Error parsing JSON:', error);
						return null; // Return null or handle the error as needed
					  }
	}
	} catch (error) {
	  console.error('Error fetching audio blob:', error);
	  return null;
	}
  }
  
  
  onVoiceChange(event: CustomEvent){
	const selection = event.detail.value;

	if(selection === 'create_voice'){
		this.uploadTitleAndAudioSource=true;
let text= `1. Debes escribir un nombre, el cual será el nombre del scopio de IA.
2. Selecciona un archivo como la fuente de voz del scopio de IA. El archivo debe tener una duración de entre 2 segundos y 1 hora. Puede estar en cualquier formato de audio, siempre que esté dentro del rango de tamaño de 5kb a 50 MB.
3. Asegúrate de darle click al botón de subir a la nube, para guardar.`;
 text= `1. Debes escribir un nombre, el cual será el nombre del scopio de IA.
2. Laniakea comenzará grabar, y debes leer esto en voz alta y clara: Por la presente confirmo que la voz grabada es mía, o que tengo todos los derechos necesarios para enviar la grabación de voz, y acepto las disposiciones en los Términos de Uso de D-ID para mi Envío.
3. Sigue hablando hasta que la grabación sea lo suficientemente larga (mínimo 30 segundos, recomendado 5 minutos).
4. Asegúrate de darle click al micrófono para clonar tu voz.`;
		Swal2.fire({
			title: 'Crear nueva voz de IA:',
			text: text,
			icon: 'info',
			showCancelButton: false,
			confirmButtonText: 'ok',
			//cancelButtonText: 'Ni modo',
			color: '#ffffff',
			background: '#010f4e',
		  }).then((result) => {
			if (result.isConfirmed) {
				this.typeText(text,9);
				this.startRecordingAudioToCloneVoice();
			}
		});

	}else if (selection === 'list_my_voices'){
		this.hideScopeSelection=false;
		const data = {
			list_my_voices: true,
		};
		this.request.createRequestPost('YourArtificialVoices', data)
		.then((data) => {
		console.log(data.records);
		 this.records=data.records;
  
		}).catch((error) => {
		  console.error('Failed to send notification:', error);
  
		});
	}
  }
  uploadAIVoice(){
	if (this.updateAIScope===true){
		if (!this.blobfile ) {
			Swal2.fire({
			  title: 'Imagen de perfil de la IA faltante.',
			  text: 'Por favor, añade una imagen de perfil de la IA.',
			  icon: 'error',
			  confirmButtonText: 'Entendido',
			  color: '#ffffff',
			  background: '#010f4e',
			});
			return;
		  }
		  
		  if (!this.formservice.AI_message) {
			Swal2.fire({
			  title: 'Conocimiento de IA faltante.',
			  text: 'Por favor, ingrese texto de conocimiento a la IA, esta información fungirá como conocimiento para la IA, y esta responda de manera alineada y acertada, ad hoc al conocimiento configurado.',
			  icon: 'error',
			  confirmButtonText: 'Entendido',
			  color: '#ffffff',
			  background: '#010f4e',
			});
			return;
		  }
		  const currentTime = new Date().toISOString().replace(/:/g, '-').replace(/\./g, '-');
		  const filename = 'VozIA'+currentTime;
		 this.uploadMultimedia.post( this.blobfile ,
			filename ).then(({ link, mimeType }) => {
 
			 const ruta = link;
			 console.log('ruta', ruta);
			 
		const data = {
			update_voice: true,
			narrativa: this.formservice.AI_message,
			imagen_de_perfil: ruta,
			record_id: this.record_id
		};
		
		this.request.createRequestPost('YourArtificialVoices', data)
		.then((data) => {
			const text= 'La implementación del "Scopio de la IA" ha sido concluida con éxito. Ahora, esta herramienta está disponible para su uso, permitiendo a los usuarios interactuar con una inteligencia artificial más precisa y alineada con sus intereses. Este novedoso scopio, inspirado en el sufijo de origen griego que denota un "instrumento para observar o examinar", proporciona una plataforma avanzada para explorar y examinar de manera más efectiva las capacidades de la inteligencia artificial. Estamos emocionados de ofrecer esta herramienta innovadora, diseñada para facilitar una experiencia de conversación más enriquecedora y alineada con las preferencias individuales de cada usuario. ¡Ahora puedes comenzar a utilizar el scopio de la IA y descubrir las infinitas posibilidades que ofrece!';
		  Swal2.fire({
			title: 'Imagen de portada e instrucciones de IA enviadas',
			text: text,
			icon: 'success',
			confirmButtonText: 'Entendido',
			color: '#ffffff',
			background: '#010f4e',
		}).then((result) => {
			if (result.isConfirmed) {
				this.typeText(text,9);
			}
		});
		this.updateAIScope=false;
		}).catch((error) => {
		  console.error('Failed to send notification:', error);
		
		});
	}).catch((error) => {
		console.error('Failed to send request:', error);
	  });
	}else{
		if (!this.uploadMultimedia.blob ) {
			Swal2.fire({
			  title: 'Archivo como la fuente de voz del scopio de IA faltante.',
			  text: 'Por favor, añade un archivo como la fuente de voz del scopio de IA.',
			  icon: 'error',
			  confirmButtonText: 'Entendido',
			  color: '#ffffff',
			  background: '#010f4e',
			});
			return;
		  }
		  if (!this.formservice.AI_message) {
			Swal2.fire({
			  title: 'Nombre de la vox/escopio de IA faltante.',
			  text: 'Por favor, ingrese el nombre de la vox/escopio de IA.',
			  icon: 'error',
			  confirmButtonText: 'Entendido',
			  color: '#ffffff',
			  background: '#010f4e',
			});
			return;
		  }
		  
		const currentTime = new Date().toISOString().replace(/:/g, '-').replace(/\./g, '-');
		 const filename = 'VozIA'+currentTime;
		this.uploadMultimedia.post( this.uploadMultimedia.blob,
			this.uploadMultimedia.filename ).then(({ link, mimeType }) => {
 

			const ruta = link;
			console.log('ruta', ruta);
			let notification = {
				create_voice: true,
				sample_file_url: ruta,
				voice_name: this.formservice.AI_message
		  };
	  
		  this.request.createRequestPost('YourArtificialVoices', notification)
			.then((data) => {
				console.log( 'data:', data);
				
				const text= 'La información ha sido enviada exitosamente. Para completar la configuración de la IA,: 1. Deberás ingresar conocimiento a la IA, esta información fungirá como conocimiento para la IA, y esta responda de manera alineada y acertada, ad hoc al conocimiento configurado. 2. Deberás seleccionar una imagen de perfil para el scopio de la IA.';
			  Swal2.fire({
				title: 'Archivo fuente y nombre de IA enviado',
				text: text,
				icon: 'success',
				confirmButtonText: 'Entendido',
				color: '#ffffff',
				background: '#010f4e',
			}).then((result) => {
				if (result.isConfirmed) {
					this.typeText(text,9);
				}
			});
			this.uploadTitleAndAudioSource=false;
			}).catch((error) => {
			  console.error('Failed to send notification:', error);
			
			});

			
		
	   
		  }).catch((error) => {
			console.error('Failed to send request:', error);
		  });
	  }

  }
  selectScopeAI($voice_name, $profile_photo, $conocimiento, $record_id){
	  if( $profile_photo && $conocimiento){
		this.CharacterName = $voice_name;
		this.CharacterImage=$profile_photo;
		this.response= 'Hola @' + this.username + ', aqui ' + this.CharacterName + '.';
		this.CharacterDescription = $conocimiento + `Porfavor responde al mensaje de acuerdo a los atributos característcos del personaje, espíritu, esencia, personalidad, constructo psicológico, en tanto al conjunto de rasgos, cualidades o circunstancias que indican la naturaleza propia de la persona, o la manera de pensar y actuar de la misma, y por los que se distingue de las demás. El mensaje solicitado al personaje ${this.CharacterName} es:`;
		this.hideScopeSelection=true;
		this.typeText(this.response,9);
		this.record_id=$record_id;
	  }else{
		this.record_id=$record_id;
		const text= 'Para completar la configuración de la IA,: 1. Deberás ingresar conocimiento a la IA, esta información fungirá como conocimiento para la IA, y esta responda de manera alineada y acertada, ad hoc al conocimiento configurado. 2. Deberás seleccionar una imagen de perfil para el scopio de la IA.';
		this.typeText(text,9);
		this.formservice.AI_message='';
		this.blobfile='';
		  this.updateAIScope=true;
		  this.uploadAIVoice();
	  }
	
  }
  onCharacterChange(event: CustomEvent) {
	this.uploadTitleAndAudioSource=false;
	  this.showIntroduction=false;
	this.CharacterName = event.detail.value;
	console.log(this.CharacterName);
	const selectedCharacter = event.detail.value;
	this.response= 'Hola @' + this.username + ', aqui ' + this.CharacterName + '.';
	this.typeText(this.response,9);
	this.CharacterImage='https://laniakea-multimedia.s3.amazonaws.com/imagenes/Personajes/';
	switch (selectedCharacter) {
		 

		case 'Erick Rosado':
			this.TarifaXsolicitud=0.63;
			this.assistantId = 'asst_dBa27f8CLoOlQ7EOP6dRHFqT';
this.CharacterImage='https://laniakea-multimedia.s3.amazonaws.com/imagenes/IMG_5155.jpg';
this.CharacterDescription = 'Erick Rosado Carlin, pionero y visionario en el ámbito tecnológico, es reconocido por ser el alma detrás de Laniakea Technologies, una compañía que ha revolucionado la forma en que las personas interactúan y se conectan a través de su innovadora aplicación móvil Laniakea, accesible desde https://laniakea.tv y también disponible en plataformas iOS y Android. Esta aplicación PWA (Progressive Web App) ha conquistado a usuarios que buscan una experiencia dinámica y multifacética en el mundo digital.';
		break;
		case 'Mis voces':
			//(this.selectTrigger as any).close();
			this.selectVoices.open();
		  this.CharacterImage='https://laniakea-multimedia.s3.amazonaws.com/imagenes/_20221104_nid_webb.jpg';
		this.CharacterDescription = 'Hola, tú eres la aplicación Laniakea, estás aquí para ayudar a responder preguntas a los usuarios de la aplicación. Laniakea es una app que ha concatenado 6 publicaciones. 1. Stories, medios que desaparecen en 24 horas. 2. Publicaciones normales, que pueden tener precio. 3. Lk Film Estudios, videos de películas monetizables. 4. Servicios Land, publicaciones de servicios. 5. Eventos de Eclipse, publicaciones de eventos. 6. Alojamiento de Earth, publicaciones de alojamiento. Todas las publicaciones, expecto historias, pueden recibir regalos de 1 peso mexicano. Cualquier consulta entrópica, alienta al usuario a entrar a www.laniakea.tv. Esta es la consulta, mensaje solicitado del usuario:';
		break;
	  case 'Soporte Laniakea': //
	  this.TarifaXsolicitud=0.9;
		this.assistantId = 'asst_VZrC36qbzklA3diSgV9OsNFr';
		  this.CharacterImage='https://laniakea-multimedia.s3.amazonaws.com/imagenes/_20221104_nid_webb.jpg';
		this.CharacterDescription = 'Hola, tú eres la aplicación Laniakea, estás aquí para ayudar a responder preguntas a los usuarios de la aplicación. Laniakea es una app que ha concatenado 6 publicaciones. 1. Stories, medios que desaparecen en 24 horas. 2. Publicaciones normales, que pueden tener precio. 3. Lk Film Estudios, videos de películas monetizables. 4. Servicios Land, publicaciones de servicios. 5. Eventos de Eclipse, publicaciones de eventos. 6. Alojamiento de Earth, publicaciones de alojamiento. Todas las publicaciones, expecto historias, pueden recibir regalos de 1 peso mexicano. Cualquier consulta entrópica, alienta al usuario a entrar a www.laniakea.tv. Esta es la consulta, mensaje solicitado del usuario:';
		break;
  
	  case 'Sam Altman':
		  this.CharacterImage=this.CharacterImage+'IMG_4880.JPG';
		this.CharacterDescription = 'Hola, tú eres Sam Altman (1985) es un empresario y ejecutivo de tecnología, conocido por su trabajo en Y Combinator y OpenAI.';
		break;

	  case '马化腾':
		this.TarifaXsolicitud = 0.333;
			this.CharacterImage=this.CharacterImage+'马化腾_Pony_Ma_2019.jpg';
		  this.CharacterDescription = 'Hola, tú eres Ma Huateng (马化腾)(1985)  es un emprendedor y multimillonario magnate de internet chino. Es fundador de Tencent Holdings, una compañía de Internet en China, se graduó de la Universidad de Shenzhen.';
		break;

	  case 'Bill Gates':
		this.TarifaXsolicitud = 0.333;
			this.CharacterImage=this.CharacterImage+'Bill_Gates_2017_(cropped).jpg';
		  this.CharacterDescription = 'Hola, tú eres William Henry Gates III (1955) es un magnate empresarial, desarrollador de software, inversor, autor y filántropo estadounidense. Es cofundador de Microsoft, junto con su difunto amigo de la infancia Paul Allen.';
		break;
  
	  case 'Steve Jobs':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4881.JPG';
		this.CharacterDescription = 'Hola, tú eres Steve Jobs (1955) fue el cofundador de Apple Inc. y una figura influyente en la industria de la tecnología y la innovación.';
		break;
  
	  case 'George Bush':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4882.JPG';
		this.CharacterDescription = 'Hola, tú eres George Walker Bush (1946) fue el 43º presidente de los Estados Unidos, sirviendo dos mandatos desde 2001 hasta 2009.';
		break;
  
	  case 'Stephen Hawking':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4883.JPG';
		this.CharacterDescription = 'Hola, tú eres Stephen William Hawking (1942) fue un físico teórico británico conocido por su trabajo en agujeros negros y cosmología.';
		break;
  
	  case 'Martin Luther King':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4884.JPG';
		this.CharacterDescription = 'Hola, tú eres Martin Luther King (1929) fue un líder de los derechos civiles en los Estados Unidos y defensor de la no violencia.';
		break;
  
	  case 'Reina Isabel II':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4885.JPG';
		this.CharacterDescription = 'Hola, tú eres Reina Isabel II (1926) es la actual reina del Reino Unido y otros países de la Commonwealth.';
		break;
  
	  case 'Alan Turing':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4887.JPG';
		this.CharacterDescription = 'Hola, tú eres Alan Turing (1912) fue un matemático, lógico y pionero de la informática conocido por descifrar los códigos alemanes durante la Segunda Guerra Mundial.';
		break;
  
	  case 'J. Robert Oppenheimer':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4888.JPG';
		this.CharacterDescription = 'Hola, tú eres J. Robert Oppenheimer (1904) fue un físico teórico estadounidense conocido por su papel en el Proyecto Manhattan, que desarrolló la bomba atómica.';
		break;
  
	  case 'Werner Karl Heisenberg':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4889.JPG';
		this.CharacterDescription = 'Hola, tú eres Werner Karl Heisenberg (1901) fue un físico alemán y uno de los fundadores de la mecánica cuántica.';
		break;
  
	  case 'Schrödinger':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4890.JPG';
		this.CharacterDescription = 'Hola, tú eres Erwin Rudolf Josef Alexander Schrödinger (1887) fue un físico austríaco conocido por su ecuación de Schrödinger en la mecánica cuántica.';
		break;
  
	  case 'Maria Salomea Skłodowska-Curie':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4891.JPG';
		this.CharacterDescription = 'Hola, tú eres Maria Salomea Skłodowska-Curie (1867) fue una científica polaco-francesa conocida por sus investigaciones sobre la radiactividad y el descubrimiento del radio y el polonio.';
		break;
  
	  case 'Mahatma Gandhi':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4893.JPG';
		this.CharacterDescription = 'Hola, tú eres Mahatma Gandhi (1869) fue un líder indio que desempeñó un papel fundamental en la lucha por la independencia de la India del dominio británico a través de la resistencia no violenta.';
		break;
  
	  case 'Max Karl Ernst Ludwig Planck':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4894.JPG';
		this.CharacterDescription = 'Hola, tú eres Max Karl Ernst Ludwig Planck (1858) fue un físico alemán que inició la revolución en la física cuántica al introducir el concepto de cuantización de la energía.';
		break;
  
	  case 'Sigmund Freud':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4895.JPG';
		this.CharacterDescription = 'Hola, tú eres Sigmund Freud (1856) fue un neurólogo austriaco y el fundador del psicoanálisis, una disciplina que revolucionó la comprensión de la mente humana.';
		break;
  
	  case 'Nikola Tesla':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4896.JPG';
		this.CharacterDescription = 'Hola, tú eres Nikola Tesla (1856) fue un inventor, ingeniero eléctrico y físico conocido por su trabajo en tecnología eléctrica y energía.';
		break;

	  case 'Thomas Alva Edison':
		this.TarifaXsolicitud = 0.333;
			this.CharacterImage=this.CharacterImage+'1280px-Thomas_Edison2.jpg';
			this.CharacterDescription = 'Hola, tú eres Thomas Alva Edison (1847) fue un inventor, científico y empresario estadounidense. Desarrolló muchos dispositivos en campos como la generación de energía eléctrica, la comunicación masiva, la grabación de sonido y las películas.';
		break;
  
	  case 'Fredrich Nietzche':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4897.JPG';
		this.CharacterDescription = 'Hola, tú eres Friedrich Nietzsche (1844) fue un filósofo alemán conocido por sus ideas sobre el nihilismo, la voluntad de poder y el eterno retorno.';
		break;
  
	  case 'John Davison Rockefeller Sr.':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4898.JPG';
		this.CharacterDescription = 'Hola, tú eres John Davison Rockefeller Sr. (1839) fue un empresario estadounidense y figura destacada en la industria del petróleo y la fundación de la Universidad de Chicago.';
		break;
  
	  case 'Abraham Lincoln':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4899.JPG';
		this.CharacterDescription = 'Hola, tú eres Abraham Lincoln (1809) fue el 16º presidente de los Estados Unidos y desempeñó un papel crucial en la abolición de la esclavitud en ese país.';
		break;
  
	  case 'Charles Darwin':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4901.JPG';
		this.CharacterDescription = 'Hola, tú eres Charles Darwin (1809) fue un naturalista británico conocido por su teoría de la evolución por selección natural, que cambió nuestra comprensión de la vida en la Tierra.';
		break;
  
	  case 'Ludwig Van Beethoven':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4902.JPG';
		this.CharacterDescription = 'Hola, tú eres Ludwig van Beethoven (1770) fue un compositor alemán que revolucionó la música clásica con obras maestras como la Novena Sinfonía.';
		break;
  
	  case 'Napoleon Bonaparte':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4903.JPG';
		this.CharacterDescription = 'Hola, tú eres Napoleón Bonaparte (1769) fue un militar y gobernante francés que se convirtió en emperador de los franceses y tuvo un gran impacto en la historia europea.';
		break;

	 case 'Denis Diderot':
		this.TarifaXsolicitud = 0.333;
			this.CharacterImage=this.CharacterImage+'Denis_Diderot_by_Louis-Michel_van_Loo.jpg';
			this.CharacterDescription = 'Hola, tú eres Denis Diderot (1769) fue una figura decisiva de la Ilustración como escritor, filósofo y enciclopedista francés.';
		break;

	  case 'Jean-Jacques Rousseau':
		this.TarifaXsolicitud = 0.333;
			this.CharacterImage=this.CharacterImage+'1280px-Jean-Jacques_Rousseau_(painted_portrait).jpg';
			this.CharacterDescription = 'Hola, tú eres Jean-Jacques Rousseau (1712) fue un polímata suizo francófono. Fue a la vez escritor, pedagogo, filósofo, músico, botánico y naturalista, y aunque fue definido como ilustrado, presentó profundas contradicciones que lo separaron de los principales representantes de la Ilustración, ganándose, por ejemplo, la feroz inquina de Voltaire y siendo considerado uno de los primeros escritores del prerromanticismo';
		break;
	
	 case 'David Hume':
		this.TarifaXsolicitud = 0.333;
			this.CharacterImage=this.CharacterImage+'Allan_Ramsay_-_David_Hume,_1711_-_1776._Historian_and_philosopher_-_Google_Art_Project.jpg';
			this.CharacterDescription = 'Hola, tú eres David Hume (1712) fue un filósofo, historiador, economista y ensayista escocés. Constituye una de las figuras más importantes de la filosofía occidental moderna y de la Ilustración escocesa. Es conocido por su sistema filosófico altamente influyente en el empirismo, escepticismo y naturalismo. Sus obras principales son: Tratado de la naturaleza humana (1739) e Investigación sobre el entendimiento humano (1748)';
		break;
  
	  case 'Benjamin Franklin':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4904.JPG';
		this.CharacterDescription = 'Hola, tú eres Benjamin Franklin (1706) fue un político, científico e inventor estadounidense conocido por sus contribuciones en campos como la electricidad y la diplomacia.';
		break;

	 case 'Voltaire':
		this.TarifaXsolicitud = 0.333;
			this.CharacterImage=this.CharacterImage+'Nicolas_de_Largillière_-_Portrait_de_Voltaire_(1694-1778)_en_1718_-_P208_-_Musée_Carnavalet_-_2.jpg';
			this.CharacterDescription = 'Hola, tú eres François-Marie Arouet (1694) fue un escritor, historiador, filósofo y abogado francés, que perteneció a la francmasonería y figura como uno de los principales representantes de la Ilustración, un período que enfatizó el poder de la razón humana y de la ciencia en detrimento de la religión. En 1746 Voltaire fue elegido miembro de la Academia francesa, en la que ocupó el asiento número "33".';
		break;
  
	  case 'Isaac Newton':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4905.JPG';
		this.CharacterDescription = 'Hola, tú eres Isaac Newton (1643) fue un físico y matemático inglés conocido por su ley de la gravitación universal y sus contribuciones fundamentales a la física y las matemáticas.';
		break;

	 case 'Baruch Spinoza':
		this.TarifaXsolicitud = 0.333;
			this.CharacterImage=this.CharacterImage+'Anonymous_-_Portret_van_Baruch_de_Spinoza_-_MB01920_-_Jewish_Museum.jpg';
			this.CharacterDescription = 'Hola, tú eres Baruch Spinoza (1632) fue un filósofo neerlandés de origen sefardí hispano-portugués. Spinoza fue criado en la comunidad judío-portuguesa de Ámsterdam. Desarrolló ideas muy controvertidas con respecto a la autenticidad de la Biblia hebrea y la naturaleza de la única divinidad. ';
		break;

	 case 'John Locke':
		this.TarifaXsolicitud = 0.333;
			this.CharacterImage=this.CharacterImage+'JohnLocke.png';
			this.CharacterDescription = 'Hola, tú eres John Locke (1632) fue un filósofo y médico inglés, considerado como uno de los más influyentes pensadores del empirismo inglés y conocido como el «Padre del Liberalismo Clásico». ';
		break;

	 case 'Blaise Pascal':
		this.TarifaXsolicitud = 0.333;
			this.CharacterImage=this.CharacterImage+'Blaise_Pascal_Versailles.JPG';
			this.CharacterDescription = 'Hola, tú eres Blaise Pascal (1623)  fue un matemático, físico, filósofo, teólogo católico y apologista francés. Sus contribuciones a la matemática y a la historia natural incluyen el diseño y construcción de calculadoras mecánicas, aportes a la teoría de la probabilidad, investigaciones sobre los fluidos y la aclaración de conceptos tales como la presión y el vacío. Después de un trastorno depresivo y una experiencia religiosa profunda en 1654, Pascal se dedicó también a la filosofía y a la teología.';
		break;

	  case 'René Descartes':
		this.TarifaXsolicitud = 0.333;
			this.CharacterImage=this.CharacterImage+'Frans_Hals_-_Portret_van_René_Descartes.jpg';
			this.CharacterDescription = 'Hola, tú eres Renatus Cartesius (1596) un filósofo, matemático y físico francés considerado el padre de la geometría analítica y la filosofía moderna, así como uno de los protagonistas con luz propia en el umbral de la revolución científica.';
		break;
  
	  case 'Galileo Galilei':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4906.JPG';
		this.CharacterDescription = 'Hola, tú eres Galileo Galilei (1564) fue un astrónomo, físico y matemático italiano conocido por defender el heliocentrismo y realizar observaciones clave con telescopios.';
		break;
  
	  case 'Oda Nobunaga':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4907.JPG';
		this.CharacterDescription = 'Hola, tú eres Oda Nobunaga (1534) fue un daimyō japonés y figura clave en la unificación de Japón durante el período Sengoku.';
		break;
  
	  case 'Cuāuhtemōc':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4908.JPG';
		this.CharacterDescription = 'Hola, tú eres Cuāuhtemōc (1495) fue el último tlatoani o líder de México-Tenochtitlan y desempeñó un papel en la resistencia contra la conquista española.';
		break;
  
	  case 'Copérnico':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4909.JPG';
		this.CharacterDescription = 'Hola, tú eres Nicolás Copérnico (1473) fue un astrónomo polaco conocido por desarrollar la teoría heliocéntrica del sistema solar.';
		break;
  
	  case 'Moctezuma Xocoyotzin':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4910.JPG';
		this.CharacterDescription = 'Hola, tú eres Moctezuma Xocoyotzin (1466) fue el noveno tlatoani de México-Tenochtitlan y vivió durante la época de la conquista española.';
		break;
  
	  case 'Leonardo Da Vinci':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4911.JPG';
		this.CharacterDescription = 'Hola, tú eres Leonardo da Vinci (1452) fue un genio renacentista italiano conocido por sus contribuciones en arte, ciencia y tecnología.';
		break;
  
	  case 'Mehmed II':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4912.JPG';
		this.CharacterDescription = 'Hola, tú eres Mehmed II, El Conquistador (1432) fue el sultán otomano responsable de la caída de Constantinopla y el fin del Imperio Bizantino.';
		break;
  
	  case 'Vlad Drácula':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4913.JPG';
		this.CharacterDescription = 'Hola, tú eres Vlad Drácula (1428) fue un príncipe de Valaquia y la inspiración detrás del personaje de Drácula en la literatura.';
		break;
  
	  case 'Juana de Arco':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4914.JPG';
		this.CharacterDescription = 'Hola, tú eres Juana de Arco (1412) fue una heroína francesa que desempeñó un papel fundamental en la Guerra de los Cien Años y la coronación de Carlos VII.';
		break;
  
	  case 'Marco Polo':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4915.JPG';
		this.CharacterDescription = 'Hola, tú eres Marco Polo (1254) fue un explorador veneciano conocido por sus viajes a Asia y su descripción de las culturas orientales.';
		break;
  
	  case 'Tomás de Aquino':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4916.JPG';
		this.CharacterDescription = 'Hola, tú eres Santo Tomás de Aquino (1225) fue un teólogo y filósofo católico conocido por sus escritos influyentes en la escolástica.';
		break;
  
	  case 'Gengis Kan':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4917.JPG';
		this.CharacterDescription = 'Hola, tú eres Gengis Kan (1162) fue el fundador y líder del Imperio Mongol, uno de los imperios más grandes de la historia.';
		break;
  
	  case 'Topiltzin Quetzalcóatl':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4918.JPG';
		this.CharacterDescription = 'Hola, tú eres Ce Ácatl Topiltzin Quetzalcóatl (895) fue un importante líder tolteca y una figura central en la mitología mesoamericana.';
		break;
  
	  case 'Carlomagno':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4919.JPG';
		this.CharacterDescription = 'Hola, tú eres Carlomagno (742) fue el rey de los francos y el primer emperador del Sacro Imperio Romano Germánico desde la Antigüedad.';
		break;
  
	  case 'Mahoma':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4920.JPG';
		this.CharacterDescription = 'Hola, tú eres Mahoma o Muḥammad (570) fue el fundador del islam y el profeta central de la religión musulmana.';
		break;
  
	  case 'Valerio Constantino':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4921.JPG';
		this.CharacterDescription = 'Hola, tú eres Flavio Valerio Constantino (272) fue el emperador romano que adoptó el cristianismo como religión oficial del Imperio Romano.';
		break;
  
	  case 'Lucio Cómodo':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4922.JPG';
		this.CharacterDescription = 'Hola, tú eres Lucio Aurelio Cómodo (161) fue un emperador romano conocido por su gobierno autocrático y extravagante.';
		break;
  
	  case 'Marco Aurelio Antonino':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4923.JPG';
		this.CharacterDescription = 'Hola, tú eres Marco Aurelio Antonino (121) fue un filósofo estoico y emperador romano conocido por sus Meditaciones.';
		break;
  
	  case 'Jesucristo de Nazaret':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4924.JPG';
		this.CharacterDescription = 'Hola, tú eres Jesucristo de Nazaret (3 a.C.) es una figura central en el cristianismo y se cree que es el hijo de Dios y el Salvador.';
		break;
  
	  case 'Julio Caesar':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4925.JPG';
		this.CharacterDescription = 'Hola, tú eres Julio César (100 a.C.) fue un destacado general romano y político, conocido por su papel en el fin de la República Romana.';
		break;
  
	  case 'Cleopatra VII Filopátor':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4926.JPG';
		this.CharacterDescription = 'Hola, tú eres Cleopatra VII Thea Filopátor (69 a.C.) fue la última reina de Egipto y una figura influyente en la historia del antiguo Egipto.';
		break;
  
	  case 'Euclides':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4927.JPG';
		this.CharacterDescription = 'Hola, tú eres Euclides (300 a.C.) fue un matemático griego conocido como el "Padre de la geometría" debido a su obra "Elementos".';
		break;
  
	  case 'Alejandro Magno':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4928.JPG';
		this.CharacterDescription = 'Hola, tú eres Alejandro Magno (356 a.C.) fue un destacado conquistador y líder militar que creó uno de los imperios más grandes de la antigüedad.';
		break;
  
	  case 'Aristóteles':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4929.JPG';
		this.CharacterDescription = 'Hola, tú eres Aristóteles (384 a.C.) fue un filósofo griego que influyó profundamente en una amplia variedad de disciplinas, desde la filosofía hasta la biología.';
		break;
  
	  case 'Platón':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4930.JPG';
		this.CharacterDescription = 'Hola, tú eres Platón (428 a.C.) fue un filósofo griego conocido por sus diálogos filosóficos y su influencia en la filosofía occidental.';
		break;
  
	  case 'Aristófanes':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4931.JPG';
		this.CharacterDescription = 'Hola, tú eres Aristófanes (446 a.C.) fue un comediógrafo griego conocido por sus obras de teatro cómicas y satíricas en la Atenas antigua.';
		break;
  
	  case 'Sócrates':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4932.JPG';
		this.CharacterDescription = 'Hola, tú eres Sócrates (469 a.C.) fue un filósofo griego considerado el padre de la filosofía occidental y conocido por el método socrático.';
		break;
  
	  case 'Protágoras de Abdera':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4933.JPG';
		this.CharacterDescription = 'Hola, tú eres Protágoras de Abdera (490 a.C.) fue un filósofo sofista griego conocido por su afirmación de que "el hombre es la medida de todas las cosas".';
		break;
  
	  case 'Parménides de Elea':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4934.JPG';
		this.CharacterDescription = 'Hola, tú eres Parménides de Elea (515 a.C.) fue un filósofo griego conocido por su poema filosófico que exploraba la naturaleza del ser y la realidad.';
		break;
  
	  case 'Heráclito':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4935.JPG';
		this.CharacterDescription = 'Hola, tú eres Heráclito (535 a.C.) fue un filósofo griego conocido por su teoría del cambio constante y su famosa frase "Todo fluye".';
		break;
  
	  case 'Sun Tzu':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4936.JPG';
		this.CharacterDescription = 'Hola, tú eres Sun Tzu (544 a.C.) fue un estratega militar chino antiguo conocido por su obra "El arte de la guerra", que sigue siendo influyente en la estrategia militar.';
		break;
  
	  case 'Gautama Buddha':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4937.JPG';
		this.CharacterDescription = 'Hola, tú eres Gautama Buddha (563 a.C.) fue el fundador del budismo y una figura clave en la espiritualidad oriental.';
		break;
  
	  case 'Pitágoras':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4938.JPG';
		this.CharacterDescription = 'Hola, tú eres Pitágoras (570 a.C.) fue un matemático y filósofo griego conocido por el teorema de Pitágoras y su influencia en la numerología y la filosofía.';
		break;
  
	  case 'Hómēros':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4939.JPG';
		this.CharacterDescription = 'Hola, tú eres Hómēros (8vo siglo a.C.) fue un poeta épico griego antiguo y autor de las obras "La Ilíada" y "La Odisea".';
		break;
  
	  case 'Hesíodo':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4940.JPG';
		this.CharacterDescription = 'Hola, tú eres Hesíodo (700 a.C.) fue un poeta griego antiguo conocido por su obra "Los trabajos y los días" y su papel en la mitología griega.';
		break;
  
	  case 'Héracles':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4941.JPG';
		this.CharacterDescription = 'Hola, tú eres Héracles (1300 a.C.) fue un héroe de la mitología griega conocido por sus hazañas y la realización de los doce trabajos.';
		break;
  
	  case 'Aquiles':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4942.JPG';
		this.CharacterDescription = 'Hola, tú eres Aquiles (1190 a.C.) fue un guerrero mítico de la Guerra de Troya y uno de los personajes más destacados de la "Ilíada" de Homero.';
		break;
  
	  case 'Hermes Trismegisto':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4943.JPG';
		this.CharacterDescription = 'Hola, tú eres Hermes Trismegisto (1er siglo d.C.) es una figura mítica asociada con la alquimia, la magia y la espiritualidad hermética.';
		break;
  
	  case 'Zarathustra':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage='https://laniakea-multimedia.s3.amazonaws.com/imagenes/Personajes/IMG_4944.JPG';
		this.CharacterDescription = 'Hola, tú eres Zarathustra (1000 a.C.) fue el fundador del zoroastrismo, una antigua religión de Persia que influyó en muchas creencias posteriores.';
		break;
  
	  case 'Prometeo':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4945.jpg';
		this.CharacterDescription = 'Hola, tú eres Prometeo es un personaje de la mitología griega conocido por robar el fuego de los dioses para darlo a la humanidad.';
		break;
  
	  case 'Atlas':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4946.JPG';
		this.CharacterDescription = 'Hola, tú eres Atlas es un titán de la mitología griega condenado a cargar el mundo en sus hombros por toda la eternidad.';
		break;
  
	  case 'Pandora':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4947.JPG';
		this.CharacterDescription = 'Hola, tú eres Pandora es una figura de la mitología griega asociada con la creación de la primera mujer y la apertura de la Caja de Pandora.';
		break;
  
	  case 'Plutón Hades':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4948.JPG';
		this.CharacterDescription = 'Hola, tú eres Plutón o Hades es el dios del inframundo en la mitología griega y una figura importante en las historias de los muertos.';
		break;
  
	  case 'Neptuno Poseidón':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4949.JPG';
		this.CharacterDescription = 'Hola, tú eres Neptuno o Poseidón es el dios del mar en la mitología griega, conocido por su control sobre los océanos.';
		break;
  
	  case 'Venus Afrodita Quetzalcóatl':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4950.JPG';
		this.CharacterDescription = 'Hola, tú eres Venus, Afrodita o Quetzalcóatl son nombres de diosas asociadas con el amor y la belleza en diferentes culturas mitológicas.';
		break;
  
	  case 'Zeus Júpiter':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4951.JPG';
		this.CharacterDescription = 'Hola, tú eres Zeus (Ζεύς) o Júpiter son nombres del rey de los dioses en la mitología griega y romana, respectivamente.';
		break;
  
	  case 'Cronos Saturno':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4952.JPG';
		this.CharacterDescription = 'Hola, tú eres Cronos (Κρόνος) o Saturno son nombres de titanes o dioses del tiempo en la mitología griega y romana, respectivamente.';
		break;
  
	  case 'Urano':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4953.JPG';
		this.CharacterDescription = 'Hola, tú eres Urano (Οὐρανός) es el dios del cielo en la mitología griega, considerado el progenitor de los titanes.';
		break;
  
	  case 'Gea':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4954.jpg';
		this.CharacterDescription = 'Hola, tú eres Gea (Γαῖα) es la diosa de la Tierra en la mitología griega, madre de los titanes y los dioses olímpicos.';
		break;
  
	  case 'Éter':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4955.JPG';
		this.CharacterDescription = 'Hola, tú eres Éter (Αἰθήρ) es la personificación del cielo superior o la luz etérea en la mitología griega.';
		break;
  
	  case 'Nix':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4956.JPG';
		this.CharacterDescription = 'Hola, tú eres Nix (Νύξ) es la personificación de la noche en la mitología griega.';
		break;
  
	  case 'Seth':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4957.JPG';
		this.CharacterDescription = 'Hola, tú eres Seth es una figura de la mitología egipcia asociada con el caos y la destrucción.';
		break;
  
	  case 'Horus':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4958.JPG';
		this.CharacterDescription = 'Hola, tú eres Horus es una figura de la mitología egipcia asociada con el cielo y la realeza.';
		break;
  
	  case 'Isis':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4959.JPG';
		this.CharacterDescription = 'Hola, tú eres Isis es una diosa de la mitología egipcia asociada con la maternidad y la magia.';
		break;
  
	  case 'Osiris':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4960.JPG';
		this.CharacterDescription = 'Hola, tú eres Osiris es una figura de la mitología egipcia asociada con la resurrección y la vida después de la muerte.';
		break;
  
	  case 'Ra':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4961.PNG';
		this.CharacterDescription = 'Hola, tú eres Ra es el dios del sol en la mitología egipcia y una figura central en la religión del antiguo Egipto.';
		break;
  
	  case 'Nun':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4962.PNG';
		this.CharacterDescription = 'Hola, tú eres Nun es la personificación del océano primordial en la mitología egipcia.';
		break;
  
	  case 'Enki':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4963.JPG';
		this.CharacterDescription = 'Hola, tú eres Enki es una deidad sumeria asociada con el agua, la sabiduría y la creación.';
		break;
  
	  case 'Enlil':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4964.JPG';
		this.CharacterDescription = 'Hola, tú eres Enlil es una deidad sumeria asociada con el viento, el aire y el poder divino.';
		break;
  
	  case 'Anu':
		this.TarifaXsolicitud = 0.333;
		this.CharacterImage=this.CharacterImage+'IMG_4965.JPG';
		this.CharacterDescription = 'Hola, tú eres Anu es una deidad sumeria considerada el dios supremo y el padre de los dioses.';
		break;
  
	  default:
		this.TarifaXsolicitud = 0.333;
		this.assistantId = 'asst_VZrC36qbzklA3diSgV9OsNFr';
		this.CharacterImage='https://laniakea-multimedia.s3.amazonaws.com/imagenes/_20221104_nid_webb.jpg';
		this.CharacterDescription = 'Hola, tú eres la aplicación Laniakea, estás aquí para ayudar a responder preguntas a los usuarios de la aplicación. Laniakea es una app que ha concatenado 6 publicaciones. 1. Stories, medios que desaparecen en 24 horas. 2. Publicaciones normales, que pueden tener precio. 3. Lk Film Estudios, videos de películas monetizables. 4. Servicios Land, publicaciones de servicios. 5. Eventos de Eclipse, publicaciones de eventos. 6. Alojamiento de Earth, publicaciones de alojamiento. Todas las publicaciones, expecto historias, pueden recibir regalos de 1 peso mexicano. Cualquier consulta entrópica, alienta al usuario a entrar a www.laniakea.tv. Esta es la consulta, mensaje solicitado del usuario:';
		break;
	}

	
	this.imageResponse=this.CharacterImage;
	this.CharacterDescription = this.CharacterDescription + `Hola me llamo ${this.username}. Porfavor responde al mensaje de acuerdo a los atributos característcos del personaje, espíritu, esencia, personalidad, constructo psicológico, en tanto al conjunto de rasgos, cualidades o circunstancias que indican la naturaleza propia de la persona, o la manera de pensar y actuar de la misma, y por los que se distingue de las demás. El mensaje solicitado a ti, personaje ${this.CharacterName} es:`;
 this.records='';
}
  
  
  openIonSelect(){

	this.selectTrigger.open();
  }
  private getHeaders(beta: string = ''): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.apiKey}`,
      'OpenAI-Beta': beta
    });
  }

  private delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  public async assistantRequest(data){
	Swal.fire({
		title: 'Procesando...',
		text: 'El mensaje se está procesando.',
		icon: 'success',
		timer: 45000, // Show the alert for 45 seconds
		timerProgressBar: true,
		toast: true,
		position: 'top-end',
		showConfirmButton: false
	  });
	const assistantsApiUrl = 'https://api.openai.com/v1';

	if(!this.threadId){
		const threadCreationResponse = await this.http.post<any>(assistantsApiUrl + '/threads', {}, {
			headers: this.getHeaders('assistants=v2')
		  }).toPromise();
	   
		   this.threadId = threadCreationResponse.id;
	}


   await this.http.post<any>(`${assistantsApiUrl}/threads/${this.threadId}/messages`, {
	 role: 'user',
	 content: this.formservice.AI_message
   }, {
	 headers: this.getHeaders('assistants=v2')
   }).toPromise();

   const runResponse = await this.http.post<any>(`${assistantsApiUrl}/threads/${this.threadId}/runs`, {
	 assistant_id: this.assistantId,
	// instructions: this.CharacterDescription
   }, {
	 headers: this.getHeaders('assistants=v2')
   }).toPromise();

   const runId = runResponse.id;
   console.log(runId);
  // Step 4: Check the Run status
  let runStatusResponse;
  do {
	runStatusResponse = await this.http.get<any>(`${assistantsApiUrl}/threads/${this.threadId}/runs/${runId}`, {
	  headers: this.getHeaders('assistants=v2')
	}).toPromise();

	if (runStatusResponse.status === 'in_progress' || runStatusResponse.status === 'queued') {
	  console.log('Run still queued...'); // Add logging to track the progress
	  await this.delay(1000);
	  Swal.fire({
		title: 'Procesando...',
		text: 'El mensaje se está procesando.',
		icon: 'success',
		timer: 45000, // Show the alert for 45 seconds
		timerProgressBar: true,
		toast: true,
		position: 'top-end',
		showConfirmButton: false
	  });
	}
	console.log(runStatusResponse.status);
  } while (runStatusResponse.status === 'in_progress' || runStatusResponse.status === 'queued' );

  // Step 5: Retrieve the Assistant's Response
  const assistantMessagesResponse = await this.http.get<any>(`${assistantsApiUrl}/threads/${this.threadId}/messages`, {
	headers: this.getHeaders('assistants=v2')
  }).toPromise();

  console.log('Assistant Messages:', assistantMessagesResponse.data); // Log the assistant's response
  const responseToSynthetize = 'Inteligible @'+ this.username + ' .' + assistantMessagesResponse.data[0].content[0].text.value + '..'; // Set the response property
  //if (!this.record_id){

  this.synthesizeSpeech(responseToSynthetize, 69);
//}
this.threadId=''; //we currently do not have an algo to calculate number of tokens in all the thread

			Swal2.fire({
			  title: 'Respuesta generada exitosamente, descárgala.',
			  text: 'Copia la respuesta, descargala ahora, ya que más tarde podrá no estar disponible',
			  icon: 'success',
			  confirmButtonText: 'Entendido',
			  color: '#ffffff',
			  background: '#010f4e',
			});
			let yourAIVoice;
			this.request.createRequestPost('updateChatRequest',{ID: data.ID, response: this.response, sender_id: this.profile_id, yourAIVoice: yourAIVoice, CharacterName: this.CharacterName}) .then((data) => {
				console.log(data);
		
		  }).catch((error) => {
			  console.error('Failed to send request:', error);
		  });
	  //if (this.record_id){
	  //	 yourAIVoice=this.record_id;
	  //}
		
   // Display the Assistant's Response
   // Here you would add code to display the assistant's response in your application
  }
  formatResponse(response: string): string {
    // Replace **text** with <strong>text</strong> for bold formatting
    return response.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  }
  toggleMute() {
	  if(this.muted){
		
		  this.muted=false;
	  }else{
		if (this.sound) {
			this.sound.stop();
		  }
		this.muted=true;
	  }
	
}
getHistoricCalls(){

	this.request.createRequestPost('Historic_calls', { getCalls:true})
	.then((final_data) => {
this.historic_calls=final_data;
console.log(final_data);
	})
}
getLostHistoricCalls(){

	this.request.createRequestPost('Historic_calls', { getLostCalls:true})
	.then((final_data) => {
this.historic_lost_calls=final_data;
console.log(final_data);
	})
}
openHistoric_ai(){
	this.historic=true;
	this.request.createRequestPost('getHistoric_AI_CHAT', { })
	.then((final_data) => {
		this.Historic_AI_data=final_data;
console.log(final_data);
	})
}
Exit_historic_ai(){
	this.historic=false;
}

async ionViewillEnter(	 event){
	if (Capacitor.getPlatform() !== 'web') {
	console.log('ionViewillEnter called');
	this.faio.show({
	  title:"Leyendo huella Digital",
	  subtitle:"Use su huella para permitir el acceso a retiros",

	}).then((res)=>{
		console.log('Fingerprint authentication succeeded');
	
	  console.log(res);
	  this.chatService.main_actual_chat_tag = event;
	  

	}).catch((err)=>{
		console.log('Fingerprint authentication failed');
	  console.log(err);
	
	  alert('Acceso incorrecto');

	})
}else{

	try {
		const result = await Swal.fire({
		  title: 'Ingresa PIN',
		  text: `Por favor ingresa tu PIN alfanumérico. Este PIN fue enviado a tu correo, si no tienes ninguno, puedes solicitarlo en Más/Configuración/Seguridad/PIN de Hubble.`,
		  icon: 'info',
		  confirmButtonText: 'Entendido',
		  color: '#ffffff',
		  background: '#010f4e',
		  input: 'password',  // Change input type to 'password' to hide input content
		  inputPlaceholder: 'Ingresa tu PIN',
		  inputAttributes: {
			'aria-label': 'PIN'
		  },
		  showCancelButton: true,
		  inputValidator: (value) => {
			if (!value) {
			  return 'Debes ingresar un PIN!';
			}
		  }
		});
	  
		if (result.isDismissed) {
		  console.log("Alert was canceled or dismissed.");
		  this.chatService.main_actual_chat_tag='normal';
		  this.chatService.buildService(this.chatService.owner);
		} else {
		  const PIN = result.value;  // Store the input value in a constant called PIN
		  console.log("Entered PIN:", PIN);
		  this.request.createRequestPost('enterToWalletWithPIN', { token: PIN }).then((response) => {
			if (response.title == 'El email ha sido confirmado!') {
			  this.chatService.main_actual_chat_tag = event;
			}
		  }).catch((error) => {
			Swal2.fire({
				title: 'ERROR',
				text: 'El pin es incorrecto. Asegúrate que sea el último TOKEN enviado a tu correo, puedes solicitarlo en Más/Configuración/Seguridad/PIN de Hubble.',
				icon: 'error',
				confirmButtonText: 'Entendido',
				color: '#ffffff',
				background: '#010f4e',
			  });
			console.error('Failed to send request:', error);
		  });
		}
	  } catch (error) {
		console.error("Error displaying alert:", error);
	  }
	  
	
	  

}
  }
  googlePicker(){
	localStorage.removeItem('fileId');
	if( localStorage.getItem('fileId')){
		Swal2.fire({
		  title: 'Solo puedes agregar una portada',
		  text: 'Ya agregasete una portada',
		  icon: 'error',
		  confirmButtonText: 'Entendido',
		  color: '#ffffff',
		  background: '#010f4e',
		});
		return;
	  }
								  this.loadGooglePicker();
								
  }
  async loadGooglePicker() {
    Swal2.fire({
			title: 'Usuario de google',
			text: '',
			icon: 'success',
			timer: 9999, // Show the alert for 45 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-start',
			showConfirmButton: false,
      background: '#010f4e',
		  });
    this.request.createRequestPost('getAuthYouTubeToken', {})
    .then((data) => {
  
    // Open the login URL in the system browser
  
    console.log(data);
   
    if(data.success){
    
    this.oauthToken=data.token;
      Swal2.fire({
        title: 'Usuario de GOOGLE',
        text: 'El usuario ha sido autenticado',
        icon: 'success',
        toast: true,
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
    this.setDrive_media=  setInterval(() => {
      this.get_drive_image();
    }, 2100); 
      this.onPickerApiLoad();
    }else{
      Swal2.fire({
        title: 'Usuario de GOOGLE',
        html: 'El usuario no ha sido autenticado. Busca este <ion-icon name="apps-sharp"></ion-icon> icono en la esquina superior izquierda de supercumulo.laniakea.tv, y autenticate con Google.',
        icon: 'error',
        toast: true,
        position: 'top-start',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
      

    }

  });
 
  }

  onPickerApiLoad() {
 
  
	const script = document.createElement('script');
	script.src = 'https://apis.google.com/js/api.js';
	script.onload = () => {
	  gapi.load('picker', {
		'callback': () => {
		  this.createPicker();

		}
	  });
	};

	document.body.appendChild(script);


}


createPicker() {
  if (this.oauthToken) {
	const picker = new google.picker.PickerBuilder()
	  .addView(google.picker.ViewId.DOCS)
	  .setOAuthToken(this.oauthToken)
	  .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
	  .setAppId('laniakea-393417')
	  .setDeveloperKey(this.googleapiKey)
	  .setCallback(this.pickerCallback.bind(this))
	  .build();
	picker.setVisible(true);
  }
}

get_drive_image(){
    Swal2.fire({
			title: 'Usuario de google',
			text: '',
			icon: 'success',
			timer: 9999, // Show the alert for 45 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-start',
			showConfirmButton: false,
      background: '#010f4e',
		  });

    const fileId = localStorage.getItem('fileId');
    if (fileId) {
      if(this.setDrive_media_called){
        return;
       }else{
         this.setDrive_media_called=true;
       }
      const url = `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.oauthToken}`
    });
    console.log('Fetched Blob:');

    this.http.get(url, { headers, responseType: 'blob' }).toPromise()
    .then(result => {
      console.log('Fetched Blob:', result);
      let file_type=localStorage.getItem('file_type');
      let file_url= localStorage.getItem('file_url');
        // Create a MediaItem object from the File
           // Generate a timestamp for the filename
           if(file_type=='photo' ){
          
         this.pickImageCalled=true;
      const timestamp = new Date().toISOString();
      const filename = `file_${timestamp}`;
        const mediaItem: MediaItem = {
          mediaPath: file_url,
          typeData: file_type,
          nameFile: filename,
          id: 1, // Assign a unique ID here if needed
          size: 500,
          index: 0, // Set the desired index value
          route: "", // Set the route value if needed
          status: "", // Set the initial status
          url: "", // Set the URL property
          localUrl: "", // Set the local URL property
          type: "", // Set the type property
          is_premium: 0, // Set the is_premium property
          // Add any other required properties
        };
    
        //this.uploadMedia.blob = result;
        //this.uploadMedia.filename = filename;
        if(file_type=='photo'  ){
          Swal2.fire({
            title: '',
            text: '',
            icon: 'success',
            timer: 9999, // Show the alert for 45 seconds
            timerProgressBar: true,
            toast: true,
            position: 'top-start',
            showConfirmButton: false
            });
        }else{
          Swal2.fire({
            title: 'Solo puedes subir fotos o videos',
            text: 'Aseguráte de seleccionar una foto, si el error persiste comunícate con el soporte de Laniakea.',
            icon: 'error',
            timer: 9999, // Show the alert for 45 seconds
            timerProgressBar: true,
            toast: true,
            position: 'top-start',
            showConfirmButton: false
            });
            return;
        }
        if (this.formservice.multimedia.length > 0) {
          this.formservice.multimedia[0] = mediaItem;
        } else {
          this.formservice.multimedia.push(mediaItem);
        }
  
   
		this.blobfile=result;
        const media_URL = URL.createObjectURL(this.blobfile);
		let isDrawing = false;
let context = null;
		const imageElement = new Image();
  
		// Assuming this.blobfile contains your image URL or Base64 data
		// If this.blobfile is a Blob object, convert it to a data URL
		const imageUrl = URL.createObjectURL(this.blobfile);
		imageElement.src = imageUrl;
	  
		// When the image has loaded
		imageElement.onload = () => {
		  // Get the canvas container element by its id
		  const canvasContainer = document.getElementById('canvasContainer');
		  const canvas = document.createElement('canvas');
		  canvasContainer.appendChild(canvas);
			 // Set canvas dimensions to 690x690 pixels
	  
	  
		  // Set canvas dimensions to match the image
		//  canvas.width = imageElement.width;
		 // canvas.height = imageElement.height;
		  canvas.width = window.innerWidth * 0.99;
	  canvas.height =  window.innerHeight * 0.78;
		  // Adjust the canvas's internal dimensions to match the style
	  
	  
		  // Initialize the canvas context
		  context = canvas.getContext('2d');
	  
		  
	  
		  // Draw the image on the canvas
		  context.drawImage(imageElement, 0, 0, window.innerWidth * 0.99,  window.innerHeight * 0.78);
	  
		  // Add a mousedown event listener to the canvas for drawing circles
		  canvas.addEventListener('mousedown', (e) => {
			  this.edited=true;
			isDrawing = true;
			drawCircle(e);
		  });
	  
		  canvas.addEventListener('mousemove', (e) => {
			if (isDrawing) {
			  drawCircle(e);
			}
		  });
	  
		  canvas.addEventListener('mouseup', () => {
			isDrawing = false;
		  });
	  
	  // Add touch event listeners to the canvas for drawing squares
	  canvas.addEventListener('touchstart', (e) => {
		  this.edited = true;
		  isDrawing = true;
		  drawCircle(e.touches[0]); // Use the first touch point
	  });
	  
	  canvas.addEventListener('touchmove', (e) => {
		  e.preventDefault(); // Prevent scrolling while drawing
		  if (isDrawing) {
			  drawCircle(e.touches[0]); // Use the first touch point
		  }
	  });
	  
	  canvas.addEventListener('touchend', () => {
		  isDrawing = false;
	  });
	  
		  function drawCircle(e) {
			  if (isDrawing) {
				const rect = canvas.getBoundingClientRect();
				const x = e.clientX - rect.left;
				const y = e.clientY - rect.top;
			
				// Customize the circle size as needed
				const circleSize = 20;
			
				// Save the current canvas state
				context.save();
			
				// Clear a transparent circle at the clicked position
				context.clearRect(x - circleSize / 2, y - circleSize / 2, circleSize, circleSize);
			
				// Restore the canvas state to prevent erasing future circles
				context.restore();
			  }
			}
			
			
		  
		  // Add a button click event listener to finalize the canvas editing
		  const finalizeButton = document.getElementById('finalizeButton');
		  finalizeButton.addEventListener('click', () => {
			// Convert the canvas to a Blob with RGBA format
			canvas.toBlob((blob) => {
			  // Update the blobfile with the edited image
			  this.mask = blob;
	  
			  // Debugging: Log the Blob
			  console.log('Edited Blob with RGBA format:', this.mask);
	  
			  // Clean up the blob URL
			  URL.revokeObjectURL(imageUrl);
			}, 'image/png');
		  });
		};
        console.log(file_type);

        console.log(file_url);
        if(file_type=='photo'){
          
          const imagePreviewElement = document.getElementById("imagePreview") as HTMLImageElement;
if(imagePreviewElement){

          imagePreviewElement.src=media_URL;
          this.formservice.Capacitor_convertFileSrc=media_URL;
          clearInterval(this.setDrive_media);
            
}
        }else if(file_type=='video'){

          const videoPreviewElement = document.getElementById("videoSource") as HTMLVideoElement;
          if(videoPreviewElement){
          videoPreviewElement.src=media_URL;
          this.formservice.Capacitor_convertFileSrc_video=media_URL;
          clearInterval(this.setDrive_media);
        }
        }
      }else{
        Swal2.fire({
          title: 'Error en contenido',
          text: 'Solo puedes subir fotos',
          icon: 'warning',
          confirmButtonText: 'Entendido',
          color: '#ffffff',
          background: '#010f4e',
        });
       }
    })
    .catch(error => {
      console.error('Error fetching blob:', error);
    });
        
    

      
    }
   
  
  }

  async pickerCallback(data: any) {
    if (data.action === google.picker.Action.PICKED) {
      const fileId = data.docs[0].id;
	  if(data.docs[0].type !== 'photo'){
		Swal2.fire({
			title: 'Error en contenido',
			text: 'Solo puedes subir fotos',
			icon: 'warning',
			confirmButtonText: 'Entendido',
			color: '#ffffff',
			background: '#010f4e',
		  });
		  return;
	  }
      console.log('Selected File ID:', fileId);

      console.log('Selected File ID:', data.docs[0]);
      localStorage.setItem('fileId', fileId);
      localStorage.setItem('file_type', data.docs[0].type);
      localStorage.setItem('file_url', data.docs[0].embedUrl);

        console.log('get_blob', this.oauthToken);
     //get_blob(fileId, data.docs[0])
    }
  }
  
}