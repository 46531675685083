import { FormServiceService } from './../../../../services/publications/form-service.service';
import { MediaItem } from "../../Media/media-item";
import { ReactionContainerClass } from "../../Reactions/ReactionContainer/reaction-container-class";
import { SellerProfile } from "../../User/Store/seller-profile";
import { CommentClass } from "../Comments/comment-class";
import { PublicationClass } from "../publication-class";
import {OnChanges, SimpleChanges} from "@angular/core";

export class LkfilmPublication extends PublicationClass implements  OnChanges{
     //Multimedia
     doesItHaveMultimedia: boolean;
     multimediaCounter: number;
     multimediaContainer: Array<MediaItem>;
     public multimedia: Array<MediaItem> = new Array<MediaItem>()
     //LkFilm Publication Data
     ubicacion: string;
     title: string;
     description: string;
     views: number;
    regalos: number;
    Verified: boolean;
    SaveCounter: any;
    ShareCounter: any;
     quienpuedever:Array<string>=["Para todos"];
     publication_type:string;
     category:string;
     price: number = 0;
     is_feed:boolean;
     walletID:string;
     index_close: number;
     page_posicion: number;
     constructor(publication?: any, userProfile?: SellerProfile, reactionContainer?: Array<ReactionContainerClass>, ownReaction?: any) {
         super(publication, userProfile, reactionContainer, ownReaction);
         if (publication && publication.container) {
             //Multimedia
             this.doesItHaveMultimedia = (publication.multimediaCounter == 0) ? false : true;
             this.multimediaCounter = (publication.container.multimediaCounter) ? parseInt(publication.container.multimediaCounter) : 0;
             this.transformMediaToMediaItem(publication.container.multimediaContainer).then((multimedia) => {
                 this.multimediaContainer = multimedia;
             });
             //LkFilm Publication Data
             this.title = (publication.container.title) ? publication.container.title : null;
             this.ubicacion = (publication.container.ubicacion) ? publication.container.ubicacion : null;
             this.views = (publication.container.views) ? publication.container.views : null;
            this.regalos = (publication.container.regalos) ? publication.container.regalos : null;
            this.Verified = (publication.container.Verified) ? publication.container.Verified : null;
            this.SaveCounter = (publication.container.SaveCounter) ? publication.container.SaveCounter : null;
            this.ShareCounter = (publication.container.ShareCounter) ? publication.container.ShareCounter : null;
             this.description = (publication.container.description) ? publication.container.description : null;
             this.price = (publication.container.price) ? publication.container.price : null;
             this.quienpuedever = (publication.container.quienpuedever) ? publication.container.quienpuedever : null;
             this.category=(publication.container.category)? publication.container.category : null;
             this.is_feed=(publication.container.is_feed)? publication.container.is_feed : null;
             this.walletID=(publication.container.walletID)? publication.container.walletID : null;
         } else {
             //Multimedia
             this.doesItHaveMultimedia = false;
             this.multimediaCounter = 0;
             this.multimediaContainer = new Array<MediaItem>();
             //LkFilm Publication Data
             this.title = undefined;
             this.ubicacion = undefined;
             this.views = undefined;
            this.Verified = undefined;
            this.ShareCounter = undefined;
            this.SaveCounter = undefined;
            this.regalos = undefined;
             this.description = undefined;
             this.price = 0;
             this.quienpuedever = undefined;
             this.category = undefined;
             this.is_feed=undefined;
             this.walletID="";
         }
     }

     public updatePublication(publication: any, userProfile: SellerProfile, reactionContainer: Array<ReactionContainerClass>, ownReaction: any) {
         //Multimedia
         this.doesItHaveMultimedia = (publication.multimediaCounter == 0) ? false : true;
         this.multimediaCounter = (publication.multimediaCounter) ? parseInt(publication.multimediaCounter) : this.multimediaCounter;
         this.transformMediaToMediaItem(publication.container.multimediaContainer).then((multimedia) => {
             this.multimediaContainer = multimedia;
         });
         //Publication
         this.id = (publication.id) ? parseInt(publication.id) : this.id;
         this.publication_type = (publication.publication_type_string) ? publication.publication_type_string : this.publication_type;
         this.status_string = (publication.status_string) ? publication.status_string : this.status_string;
         //Interaction
         this.reactionCounter = (publication.reactionCounter) ? parseInt(publication.reactionCounter) : this.reactionCounter;
         this.reactionContainer = reactionContainer;
         this.ownReaction = ownReaction;
         this.commentCounter = (publication.commentCounter) ? parseInt(publication.commentCounter) : this.commentCounter;
         //User
         this.userProfile = userProfile;
         this.corp = (publication.corp) ? publication.corp : this.corp;
         this.is_saved = (publication.is_saved) ? publication.is_saved : this.is_saved;
         this.owner = (publication.owner) ? publication.owner : this.owner;
     }


     setMultimedia(mediaItem) {
         if (this.publication_type == "event") {
             if (this.doesItHaveMultimedia) {
                 this.deleteMultimedia(0);
             }
         }
         this.multimediaContainer.push(mediaItem);
         this.doesItHaveMultimedia = true;
     }

     deleteMultimedia(index: number) {
         this.multimediaContainer.splice(index, 1);
         if (this.multimediaContainer.length == 0) {
             this.doesItHaveMultimedia = false;
         }
     }

     getPublicationsDataToSend() {
         let data = {
             doesItHaveMultimedia: this.doesItHaveMultimedia,
             publication_type: this.publication_type,
             data: {
                 title: this.title,
                 description: this.description,
                 quienpuedever:this.quienpuedever.join(","),
                 category:this.category,
                 price:this.price,
                 is_feed:this.is_feed,
                 ubicacion: this.ubicacion,
                 walletID:this.walletID
             }
         }

         return data;
     }

     transformMediaToMediaItem(objectMedia) {
         return new Promise<Array<MediaItem>>((resolve) => {
             let MediaContainer = new Array<MediaItem>();
             if (objectMedia) {
                 objectMedia.forEach(media => {
                     MediaContainer.push(new MediaItem(media));
                 });
                 resolve(MediaContainer);
             }
             else {
                 resolve(MediaContainer);
             }

         });
     }

  ngOnChanges(changes: SimpleChanges): void {
       console.log('lkfilm publications changes detected at ', changes);
  }
}
