import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, OnDestroy} from '@angular/core'
import { ModalController, NavController, IonInfiniteScroll, IonSlides } from '@ionic/angular'
import { PublicationService } from 'src/app/services/publications/publication.service'
import { ProfileService } from 'src/app/services/profile/profile.service';
import { PopzoomComponent } from 'src/app/components/galery/popzoom/popzoom.component';
import { PublicationClass } from 'src/app/Models/Classes/Publications/publication-class';
import { PublicacionesGuardadasComponent } from 'src/app/components/publication/create/publicaciones-guardadas/publicaciones-guardadas/publicaciones-guardadas.component';
import { PublicacionesResumenComponent } from 'src/app/components/publication/create/publicaciones-resumen/publicaciones-resumen/publicaciones-resumen.component';
import { PublicacionesColeccionComponent } from 'src/app/components/publication/create/publicaciones-coleccion/publicaciones-coleccion/publicaciones-coleccion.component';
// import {VideoProcessingService} from "../../services/videoprocessingservice/video-processing-service.service";
import {MessagesListComponent} from "../../components/chat/messages-list/messages-list.component";
import {LkfilmViewBySlidesComponent} from "../../components/lkfilm-view-by-slides/lkfilm-view-by-slides.component";
import { ActualizacionServiceService } from './../../services/actualizacion/Actualizacion-service.service';
import {FormServiceService} from "../../services/publications/form-service.service";
import Swal2 from "sweetalert2";
import { FilterBarComponent } from '../../components/general-design/Filter-Bar/Filter-Bar.component';
import { ToastController } from '@ionic/angular';
@Component({
  selector: 'app-publication-feed',
  templateUrl: './publication-feed.page.html',
  styleUrls: ['./publication-feed.page.scss'],
})

export class PublicationFeedPage implements OnInit, OnChanges, OnDestroy {
  slideOpts = {
    direction: 'vertical',
    initialSlide: 0,
    speed: 420,
  };
  public Profile: any;
  //public publicaciones = [];
  //data1;

  public ChunkedPublications: any[] = [];
  //public publicationsLoaded = false;
  public publications: any[] = [];
  @Input() app: any;
  @Input() pr: any;
  @Input() initial_index = 0;
  @ViewChild('slides') slides: IonSlides;
  //@ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  constructor(
    private actualizacionservice: ActualizacionServiceService,
    public modalController: ModalController,
    public publicationService: PublicationService,
    private profileService: ProfileService,
    public navCtrl: NavController,
    public formService:FormServiceService,
    private toastController: ToastController,
  ) { }

  ngOnInit() {

    //this.getPublications();
    //this.getProfile();

this.fetchPublications(true);
    //console.log("Publicaciones",this.publicaciones);

   // this.publicaciones.forEach(element => {
     // this.data1=Object.values(this.publicaciones);
    //});

  }
  ngOnDestroy(){
    this.ChunkedPublications = [];
    this.publications = [];
  }
  public return_name_of_class(length: number) {
    if (length === 1) {
      return "image-grid-col-2 image-grid-row-1";
    }else {
      return "";
    }
  }
  
  private async fetchPublications(isrefresh: boolean) {
    let result ;

    if(this.app==='lkfilm'){
      result = await this.actualizacionservice.getAIPublications(null, false, null, false, 'lkfilm');
    }else if (this.app==='earth'){
      result = await this.actualizacionservice.getAIPublications(null, false, null, false, 'earth');
    }else if (this.app==='epoch'){
      result = await this.actualizacionservice.getAIPublications(null, false, null, false, 'epoch');
    }else if (this.app==='eclipse'){
      result = await this.actualizacionservice.getAIPublications(null, false, null, false, 'eclipse');
    }else if (this.app==='land'){
      result = await this.actualizacionservice.getAIPublications(null, false, null, false, 'land');
    }
    const fetchedPublications = result || [];
    
    this.filterPublications(fetchedPublications);
  }
  async filterPublications(publications) {
  
    if (publications.length > 0) {
      this.publications = publications;
      this.ChunkedPublications = publications;
      this.chunkPublications();
    }
    if (publications.length < 1 && this.actualizacionservice.current_page<3) {
      console.log('pub < x');
      this.actualizacionservice.current_page++;
      await this.fetchPublications(false);
    }
  
    console.log('siguiendo length of pub', this.ChunkedPublications.length);
  
  
  }
  private chunkPublications() {
    const chunkSize = 4;
    const chunkedPublications = [];
    let index = 0;
  
    while (index < this.ChunkedPublications.length) {
      const chunk = this.ChunkedPublications.slice(index, index + chunkSize);
      if (chunk.length > 0) {
        chunkedPublications.push(chunk);
      }
      index += chunkSize; // Update the index by the chunkSize, not the chunk length
    }
  
    this.ChunkedPublications = chunkedPublications;
  }

  async new_pageOLD() {
    this.actualizacionservice.current_page++;
    console.log('pagina en actualizacion service', this.actualizacionservice.current_page);
    let publications_from_server;
    //publications_from_server= await this.fetchPublications();
    console.log('publications from server', publications_from_server);
    if (publications_from_server.length == null || publications_from_server.length === 0 ) {
      Swal2.fire({
        title: 'Tranquilo, no te desplazes tan rápido',
        icon: 'warning',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
      return;
    }
    let i = 0;
    for (const pub of publications_from_server) {
      if (pub['publication_type'] == 'lkfilm'){
        i++;
       // this.publicaciones.push(pub);
      }
    }
    //no hubo publicaciones de seguidores en la pagina X avanzar a la siguiente
    console.log('number of publications with lkfilm', i);
    if (i==0 && this.actualizacionservice.current_page < 4){
      await this.new_page();
    }else if(i==0 && this.actualizacionservice.current_page >= 4) {
      Swal2.fire({
        title: 'Tranquilo, no te desplazes tan rápido',
        icon: 'warning',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
      return;
    }
  }

  async onIonInfinite(ev) {
    await this.new_page().then(()=>{
      ev.target.complete();
    });
  }

  //public getPublications() {

 //   this.actualizacionservice.getPublications(null,null).then((data=>{

   //   data.forEach(element => {
//            console.log("elementos del data",element);
  //          //pór ahora solo append de lkfilm
    //        if(element.publication_type == 'lkfilm'){
   //          // this.publicaciones.push(element);
   //         }
   //       });


    //}))

    //console.log("arregloPublicaciones",this.publicaciones);


    // this.publicationService.getAllPublications(null, null, 'lkfilm').then((data: any) => {
    //   data.data.forEach(element => {
    //     console.log("elementos del data",element);
    //     //pór ahora solo append de lkfilm
    //     if(element.publication_type == 'lkfilm'){
    //       this.publicaciones.push(element);
    //     }
    //   });
    //   this.paginatorIndex = 2;
    // }).catch(error => {
    //   console.error("Error Get Publications", error);
    // });
  //}

  public getProfile() {
    this.profileService.getProfileInfo().then((data) => {
      console.log("Data profile",data);

      this.Profile = JSON.parse(data);
    });
  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  pop(publication) {
    this.modalController
      .create({
        component: PopzoomComponent,
        cssClass: 'detailsInModal',
        swipeToClose: true,
        componentProps: {
          publication

        },
      })
      .then((modal) => modal.present())
  }

  guardadas(publication) {
    this.modalController
      .create({
        component: PublicacionesGuardadasComponent,
        cssClass: 'detailsInModal',
        swipeToClose: true,
        componentProps: {
          publication
        },
      })
      .then((modal) => modal.present())
  }

  resumen() {
    this.modalController
      .create({
        component: PublicacionesResumenComponent,
        cssClass: 'detailsInModal',
        swipeToClose: true,
      })
      .then((modal) => modal.present())
  }

  coleccion(index) {
    this.formService.sectionVer="lkfilme"
    console.log("section select",this.formService.sectionVer);

    this.modalController
      .create({

        component: PublicacionesColeccionComponent,
        cssClass: 'detailsInModal',
        swipeToClose: true,
        componentProps: {
         // publications:this.publicaciones,
          index: index
        }
      },

      )
      .then((modal) => modal.present())
  }

  openComponentVideoOLD(index){
    this.modalController.create({
        component: LkfilmViewBySlidesComponent,
        cssClass: 'detailsInModal',
        swipeToClose: true,
        componentProps: {
         // publications:this.publicaciones,
          index: index
        }
    }).then((modal)=>{
        modal.present();
        modal.onDidDismiss().then((data)=>{
          let index_close = this.formService.lkpublication.index_close;
          console.log('index after close', index_close);
        //  this.publicaciones[index_close]['shadow'] = true;
         // console.log(this.publicaciones[index_close]);
        });
    });
  }

  get_class(index) {
    // console.log('se lllama get_class');


    if((index % 4 === 0)) {
      return 'reminder0 ';
    }
    else if(index % 4 === 1){
      return 'reminder1 ';
    }
    else if(index % 4 === 2){
      return 'reminder2 ';
    }else{
      return 'reminder3 ';
    }

  

  }
  get_class2(index) {
    // console.log('se lllama get_class2');

    if(this.formService.lkpublication.index_close===index){
      return 'shadow'
    }




  }

  filter(){
    this.modalController.create({
      component:FilterBarComponent,
      cssClass: 'detailsInModal',
			swipeToClose: true,
    }).then((modal=>{
      modal.present()
    }))
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('Publication feed page Changes detected at ', changes);
  }
  async handleSlideChange() {
    const currentIndex = await this.slides.getActiveIndex();
  
    // Check if the current slide is the last slide
    if (currentIndex === (await this.slides.length() - 1)) {
      // Fetch more publications when reaching the last slide
     
  
       await this.new_page();
    }
  }
  
  
  
  
  

  ngAfterViewInit() {
    
    this.slides.slideTo(this.initial_index);
    this.slides.ionSlideDidChange.subscribe(async () => {
      await this.handleSlideChange();
    });
     let me = this;
     me.slides.slideTo(this.initial_index);
  }
  
  
  
  
  
  async new_page() {
    try {
      this.showToast();
      this.actualizacionservice.current_page++;
      let fetchedPublications;
      if(this.app==='lkfilm'){
        fetchedPublications = await this.actualizacionservice.getAIPublications(null, false, null, false, 'lkfilm');
      }else if (this.app==='earth'){
        fetchedPublications = await this.actualizacionservice.getAIPublications(null, false, null, false, 'earth');
      }else if (this.app==='epoch'){
        fetchedPublications = await this.actualizacionservice.getAIPublications(null, false, null, false, 'epoch');
      }else if (this.app==='eclipse'){
        fetchedPublications = await this.actualizacionservice.getAIPublications(null, false, null, false, 'eclipse');
      }else if (this.app==='land'){
        fetchedPublications = await this.actualizacionservice.getAIPublications(null, false, null, false, 'land');
      }
     
    
      if (!fetchedPublications || fetchedPublications.length === 0) {
        Swal2.fire({
          title: 'La velocidad del movimiento de tu dedo excede el límite',
          icon: 'warning',
          confirmButtonText: 'Entendido',
          color: '#ffffff',
          background: '#010f4e',
        });
        return;
      }
    
      const chunkedPublications = this.chunkNewPublications(fetchedPublications);
      this.ChunkedPublications.push(...chunkedPublications);
    } catch (error) {
      console.error('Error fetching publications:', error);
    }
    
  }
  async showToast() {
    const toast = await this.toastController.create({
      message: `
        <div class="toast-content">
        <img src="https://laniakea-multimedia.s3.amazonaws.com/loading_chunk.gif" alt="Image" style="width: 30px; height: 30px;">
          <span>⚡️⚡️⚡️</span>
        </div>
      `,
      duration: 6399,
      position: 'bottom',
      cssClass: 'custom-toast',
      /*buttons: [
        {
          text: '...',
          role: 'cancel'
        }
      ]*/
    });
  
    toast.present();
  }
  
  
  
  
  chunkNewPublications(publications) {
    const chunkSize = 4; // Define your desired chunk size here
    const chunkedArray = [];
    
    for (let i = 0; i < publications.length; i += chunkSize) {
      const chunk = publications.slice(i, i + chunkSize);
      chunkedArray.push(chunk);
    }
    
    return chunkedArray;
  }
  
  /*openComponentVideo(index, chunk){//
  console.log('chunkk',chunk);
  console.log('indexxx',index);
  const indexxx = chunk * 4 + index;
    this.modalController.create({
        component: LkfilmViewBySlidesComponent,
        swipeToClose: true,
        componentProps: {
          publications: this.publications,
          index: indexxx, 
          valueSelect: 'lkfilm'
        }
    }).then((modal)=>{
        modal.present();
    });
}

*/
openComponentVideo(index: number, chunk: number) {
  this.dismiss();
  try {
    this.formService.mostrar = false;
    console.log('pubs', this.publications);
    this.formService.publications = this.publications;
    const indexxx = chunk * 4 + index;
    //this.initial_index = indexxx;
    this.formService.index = indexxx;
    if(this.app==='lkfilm'){
      this.formService.valueSelect = 'lkfilm';
    }else if (this.app==='earth'){
      this.formService.valueSelect = 'earth';
    }else if (this.app==='epoch'){
      this.formService.valueSelect = 'epoch';
    }else if (this.app==='eclipse'){
      this.formService.valueSelect = 'eclipse';
    }else if (this.app==='land'){
      this.formService.valueSelect = 'land';
    }
  
    this.formService.mediaTemplate = true;

    // Log some information to check the values before opening the component
    console.log('Index:', index);
    console.log('Chunk:', chunk);
    console.log('Indexxx:', indexxx);
  } catch (error) {
    console.error('Error in openComponentVideo:', error);
  }
}

}
