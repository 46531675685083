import { Component, Input, OnInit,EventEmitter, Output } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { CommentsModalComponent } from '../../../comments/comments-modal/comments-modal.component';
import { PublicationService } from 'src/app/services/publications/publication.service';
import { ReactionssService } from 'src/app/services/reactions/reactions.service';
import { PublicationClass } from 'src/app/Models/Classes/Publications/publication-class';
import { ModalTransactionComponent } from '../../../single-elements/modal-transaction/modal-transaction.component';
import { PagoTarjetaComponent } from 'src/app/components/pago-verificacion/Pago-Tarjeta/Pago-Tarjeta.component';
import { MetodoDePagoComponent } from './../../../create/finalizar-compra/metodo-de-pago/metodo-de-pago.component';
import { VerDetallesComponent } from './../../../create/Ver-detalles/Ver-detalles.component';
import { PagosPorPublicacionComponent } from '../../../create/Pagos/Pagos-por-publicacion/Pagos-por-publicacion.component';
import { runInThisContext } from 'vm';
import { NormalPublication } from 'src/app/Models/Classes/Publications/Normal/normal-publication';
import {FormServiceService} from "src/app/services/publications/form-service.service";
@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss'],
})

export class InformationComponent implements OnInit {
  interval;
  originalDescription;
  originalTitle;
  @Input() publication: any;
  @Input() corp: boolean = false;
  @Output() savePublication = new EventEmitter();
  @Output() success = new EventEmitter();
	@Input() profilePhoto: string;
  activador:boolean=false
  public showMore = true;

  constructor(private modalController: ModalController,private popOvercontroller: PopoverController,   private formService: FormServiceService,
    ) { }

    ngOnInit() {
      console.log('Publication in InformationComponent:', this.publication);
      if (this.publication) {
        console.log('Publication title in InformationComponent:', this.publication.title);
        console.log('Publication views in InformationComponent:', this.publication.views);

      }
    }
    toggleVisibility() {
    console.log('toggleVisibility');
      this.formService.showMoreHidden= ! this.formService.showMoreHidden;
      if(!this.formService.showMoreHidden){
        this.typeText(this.publication.description, 0.09);
        this.typeTitleText(this.publication.title, 0.09);
      }

  }
  typeText(text: string, delay: number, charLimit: number = 270) {
    let index = 0;
  
    this.publication.description = '';
    clearInterval(this.interval);
     this.interval = setInterval(() => {
      if (index < text.length && this.publication.description.length < charLimit) {
        this.publication.description += text.charAt(index);
        index++;
      } else {
        clearInterval(this.interval);
      }
    }, delay);
  }
  
  typeTitleText(text: string, delay: number, charLimit: number = 270) {
    let index = 0;
  
    this.publication.title = '';
    const interval = setInterval(() => {
      if (index < text.length && this.publication.title.length < charLimit) {
        this.publication.title += text.charAt(index);
        index++;
      } else {
        clearInterval(interval);
      }
    }, delay);
  }
  
    

  activar(){
    if(this.activador==false){
      this.activador=true
      return "Mostrar Menos"





    }else if(this.activador==true){
      this.activador=false
      return "Mostrar Menos"



    }



  }


	openCompleteInformation(){}
  
  PagarPublicacion() {

		this.modalController.create({
      component: PagosPorPublicacionComponent,
      cssClass: 'detailsInModal',
			swipeToClose: true,
      componentProps: {
        public:this.publication
      }
    }).then((modal) => {
      modal.present();
    }).catch((error) => {
      console.error(error);
    });
  }

  OpenDetallesComponent() {
    this.modalController.create({
      component: VerDetallesComponent,
      cssClass: 'detailsInModal',
			swipeToClose: true,
      componentProps: {
        public:this.publication
      }

    }).then((modal) => {
      modal.present();
    }).catch((error) => {
      console.error(error);
    });
  }


}


