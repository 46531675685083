import { MediaItem } from "../../Media/media-item";
import { ReactionContainerClass } from "../../Reactions/ReactionContainer/reaction-container-class";
import { SellerProfile } from "../../User/Store/seller-profile";
import { PublicationClass } from "../publication-class";

export class UrlPublication extends PublicationClass {
    //Multimedia
    doesItHaveMultimedia: boolean;
    multimediaCounter: number;
    multimediaContainer: Array<MediaItem>;
    //Publication Url Data
    title: string;
    description: string;
    constructor(publication?: any, userProfile?: SellerProfile, reactionContainer?: Array<ReactionContainerClass>, ownReaction?: any) {
        super(publication, userProfile, reactionContainer, ownReaction);
        if (publication && publication.container) {
            //Multimedia
            this.doesItHaveMultimedia = (publication.multimediaCounter == 0) ? false : true;
            this.multimediaCounter = (publication.multimediaCounter) ? parseInt(publication.multimediaCounter) : 0;
            this.transformMediaToMediaItem(publication.multimediaContainer).then((multimedia)=>{
                this.multimediaContainer = multimedia;
            });
            //Publication Url Data
            this.title = (publication.container.title)? publication.container.title: null;
            this.description = (publication.container.description)? publication.container.description: null;
        } else {
            //Multimedia
            this.doesItHaveMultimedia = false;
            this.multimediaCounter = 0;
            this.multimediaContainer = new Array<MediaItem>();
            //Publication Url Data
            this.title = undefined;
            this.description = undefined;
        }
    }
    public updatePublication(publication: any, userProfile: SellerProfile, reactionContainer: Array<ReactionContainerClass>, ownReaction: any) {
        //Multimedia
        this.doesItHaveMultimedia = (publication.multimediaCounter == 0) ? false : true;
        this.multimediaCounter = (publication.multimediaCounter) ? parseInt(publication.multimediaCounter) : this.multimediaCounter;
        this.transformMediaToMediaItem(publication.multimediaContainer).then((multimedia)=>{
            this.multimediaContainer = multimedia;
        });
        //Publication
        this.id = (publication.id) ? parseInt(publication.id) : this.id;
        this.publication_type = (publication.publication_type_string) ? publication.publication_type_string : this.publication_type;
        this.status_string = (publication.status_string) ? publication.status_string : this.status_string;
        //Interaction
        this.reactionCounter = (publication.reactionCounter) ? parseInt(publication.reactionCounter) : this.reactionCounter;
        this.reactionContainer = reactionContainer;
        this.ownReaction = ownReaction;
        this.commentCounter = (publication.commentCounter) ? parseInt(publication.commentCounter) : this.commentCounter;
        //User
        this.userProfile = userProfile;
        this.corp = (publication.corp) ? publication.corp : this.corp;
        this.is_saved = (publication.is_saved) ? publication.is_saved : this.is_saved;
        this.owner = (publication.owner) ? publication.owner : this.owner;
    }

    public setMultimedia( mediaItem ){
        if(this.publication_type == "event"){
            if(this.doesItHaveMultimedia){
                this.deleteMultimedia(0);
            }
        }
        this.multimediaContainer.push(mediaItem);
        this.doesItHaveMultimedia = true;
    }

    public deleteMultimedia(index:number){
        this.multimediaContainer.splice(index,1);
        if(this.multimediaContainer.length == 0){
            this.doesItHaveMultimedia = false;
        }
    }

    public getPublicationsDataToSend(){
        let data = {
            doesItHaveMultimedia: this.doesItHaveMultimedia,
            publication_type: this.publication_type,
        }
        return data;
    }

    private transformMediaToMediaItem(objectMedia){
        return new Promise<Array<MediaItem>>((resolve) => {
            let MediaContainer = new Array<MediaItem>();
            if(objectMedia){
                objectMedia.forEach(media => {
                    MediaContainer.push( new MediaItem(media) );
                });
                resolve(MediaContainer);
            }
            else{
                resolve(MediaContainer);
            }
            
        });  
    }
}


