import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-component-button-semiround',
  templateUrl: './component-button-semiround.component.html',
  styleUrls: ['./component-button-semiround.component.scss'],
})
export class ComponentButtonSemiroundComponent implements OnInit {

  @Input() text: string;
  @Input() iconName: string;
  constructor() { }

  ngOnInit() {}

}
