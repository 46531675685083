import { MediaItem } from "../Media/media-item";
import { UserContact } from "../User/user-contact";
import * as models from 'src/app/Models/Classes/graphql/models/models-graphql';
import { MultimediaInChatService } from "src/app/services/chat/multimedia-in-chat.service";
import { IMultimediable } from "../../Interfaces/imultimediable";

export class Message implements IMultimediable {
    private id: string
    public body: string;
    private createdAt: string;
    private multimedia: Array<MediaItem>;
    private user: models.GetUserQuery;
    private multimediaID: string;
    private isSystemNotification: boolean;
    private media: any;
    private users: models.ListUsersQuery;
    private isSeen: boolean;
    private isDelete: boolean;
    private messageResponse: Message;
    constructor(messageResponse?: models.GetMessageQuery) {

        if (messageResponse) {
            this.id = (messageResponse.id) ? messageResponse.id : "";
            this.user = (messageResponse.user) ? messageResponse.user : null;
            this.body = (messageResponse.body) ? messageResponse.body : "";
            this.isSeen = (messageResponse.isSeen) ? messageResponse.isSeen : false;
            this.createdAt = (messageResponse.createdAt) ? messageResponse.createdAt : new Date().toLocaleString();
            this.users = (messageResponse.users) ? messageResponse.users : null;
            this.multimedia = new Array<MediaItem>();
            this.multimediaID = (messageResponse.multimediaID) ? messageResponse.multimediaID : null;
            this.isSystemNotification = messageResponse.isSystemNotification;
            this.isDelete = (messageResponse.isDelete)? messageResponse.isDelete : false
            if (messageResponse.message) {
                this.messageResponse = new Message(messageResponse.message);
            }
            if (this.multimediaID) {
                this.setMedia(this.multimediaID)
            }
        } else {
            this.id = "";
            this.body = "";
            this.createdAt = "";
            this.isSeen = false;
            this.multimedia = new Array<MediaItem>();
        }
    }

    public setMedia(mediaID: string) {
        MultimediaInChatService.instance.getChatElement(parseInt(mediaID)).then((mediaResponse: MediaItem) => {
            this.media = mediaResponse;

        }).catch((error) => {
            console.error(error);
        });
    }

    public getDelete(): boolean{
        return this.isDelete;
    }

    public setDelete(isDelete:boolean){
        this.isDelete = isDelete;
    }
    public getMessageResponse(){
        return this.messageResponse;
    }

    public clearUsers(usersList: models.ListUsersQuery) {
        this.users = usersList;
    }

    public getMedia(): MediaItem {
        return this.media;
    }

    public getId(): string {
        return this.id;
    }

    public getText(): string {
        return this.body;
    }

    public getDate(): string {
        return this.createdAt;
    }

    public getMultimedia(): Array<MediaItem> {
        return this.multimedia;
    }

    public getUser(): models.GetUserQuery {
        return this.user;
    }

    public getSystemNotification(): boolean {
        return this.isSystemNotification;
    }

    public getMultimediaID(): string {
        return this.multimediaID;
    }
    public setText(text: string) {
        this.body = text;
    }

    public getHaveMultimedia(): boolean {
        if (this.multimedia.length > 0 && this.multimedia != null) {
            return true;
        }
        return false;
    }

    public getIsMine(userID: string): boolean {
        if (this.getUser().id == userID) {
            return true;
        }
        return false;
    }

    public setSeen(usersList: Array<UserContact>) { // Hay que corregir este metodo para que compare exactamente los usuarios que lo han visto con los que existen en la conversacion
        if (this.users && (this.users.items.length + 1 >= usersList.length + 1)) {
            this.isSeen = true;
        }
    }

    public getSeen(): boolean {
        return this.isSeen;
    }

    public getTimeSince() {
        let options: Intl.DateTimeFormatOptions = {
            timeZone: 'America/Mexico_City',
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
        }
        return new Date(this.createdAt).toLocaleTimeString("en-US", options);
    }

    public getDateSince() {
        try {
            let date = new Date(this.createdAt);
            return new Intl.DateTimeFormat(['ban', 'id']).format(date);
        } catch (e) {
            console.error(e);
        }
    }

    public deleteMultimedia(index: number) {
        if (this.getHaveMultimedia()) {
            this.multimedia.splice(index, 1);
        }
    }

    public setMultimedia(mediaItem: MediaItem) {
        if (this.getHaveMultimedia()) {
            this.deleteMultimedia(0);
        }
        this.multimedia.push(mediaItem);
    }
}
