import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PublicationSavePageRoutingModule } from './publication-save-routing.module';

import { PublicationSavePage } from './publication-save.page';
import {ColeccionPremium} from 'src/app/components/publication/create/publicaciones-coleccion/publicaciones-coleccion/ColeccionLaniakea/Coleccion-premium/Coleccion-premium';
import {ColeccionRegalos} from 'src/app/components/publication/create/publicaciones-coleccion/publicaciones-coleccion/ColeccionLaniakea/Coleccion-regalos/Coleccion-regalos';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PublicationSavePageRoutingModule
  ],
  declarations: [PublicationSavePage,
    ColeccionPremium,
    ColeccionRegalos
  ]
})
export class PublicationSavePageModule {}
