import { Injectable } from '@angular/core';
import { Utils } from 'src/app/Models/Classes/utils';
import { Wallet } from 'src/app/Models/Classes/Wallet/wallet';
import { ProfileService } from '../profile/profile.service';
import { RequestService } from '../request/request.service';
import { Capacitor } from '@capacitor/core';
@Injectable({
	providedIn: 'root'
})
export class WalletServiceService {

	public MyWalletsList = new Array<Wallet>();
  balance=120000
  currency:string=""
  amount:number=0

  public SubscriptionExpired: boolean;
	constructor(
		private request: RequestService,
		private utils: Utils,
		private profile_service: ProfileService,
	) {
		if (Capacitor.getPlatform() !== 'web') {
		this.profile_service.getProfileInfo().then((profile)=>{
			this.getWallets(JSON.parse(profile).id).then((wallets: any)=>{
				wallets.forEach(wallet => {
					console.log(wallet);
					this.MyWalletsList.push(new Wallet(wallet));
				});
			}).catch((error)=>{
				console.error(error);
			});
		}).catch((error)=>{
			console.error(error);
		});
	}
	}

	public getCurrencyValue(object): Promise<any>{
		return new Promise((resolve, reject)=>{
			this.request.createRequestGet('getCurrencyValue', object).then((data)=>{
				resolve(JSON.parse(data));
        console.log("currencys",data);

			}).catch((error)=>{
				reject(error);
			})
		});
	}

	public createUserWallet(wallet_object: any): Promise<any> {
		return new Promise((resolve, reject) => {
			var params = { "id": "0" };

			this.request.createRequestPost('user', wallet_object, params, 'wallet').then((data) => {

				let dataParsed = JSON.parse(data);

				let found = this.MyWalletsList.find(element => element.id == dataParsed.wallet.id);
				if(found == null){
					this.MyWalletsList.push( new Wallet(dataParsed.wallet) );
				}
				else{
					found.balance = parseFloat(dataParsed.wallet.balance);
				}

				resolve(data);
			}).catch((error) => {
				reject(error);
			});
		});
	}

	public getWallets(user_id){
		return new Promise((resolve, reject) => {
			var params = { "id": user_id.toString() };
			this.request.createRequestGet('user', {}, params, 'wallet').then((data)=>{
				resolve(JSON.parse(data));
			}).catch((error) => {
				reject(error);
			});
		});
	}

	public getTransactions(){//si en api
		return new Promise((resolve, reject)=> {
			this.request.createRequestGet('transactions', null, null, null).then((data)=>{
				resolve(JSON.parse(data));
			}).catch((error) => {
				reject(error);
			});
		});
	}

	public getApprovalRechargeLink(deposit:any){
		return new Promise((resolve, reject)=>{
			this.request.createRequestPost('payment', deposit, null, null, null)
			.then((data)=>{
				resolve(data);
			})
			.catch((error)=>{
				reject(error);
			});
		});
	}

	public newWithDrawal(deposit: any){
		return new Promise((resolve, reject)=>{
			this.request.createRequestPost('payment', deposit, null, null, null)
			.then((data)=>{
				resolve(data);
			})
			.catch((error)=>{
				reject(error);
			});
		});
	}

	public newPayment(deposit:any){
		return new Promise((resolve, reject)=>{
			this.request.createRequestPost('payment', deposit, null, null, null)
			.then((data)=>{
				resolve(data);
			})
			.catch((error)=>{
				reject(error);
			});
		});
	}

	public refreshWallets(){
		return new Promise((resolve, reject) => {
			this.utils.getItem('ProfileData').then((profileData: string) => {
				//console.log(JSON.parse(profileData));
				this.getWallets(JSON.parse(profileData).id).then((data: any)=>{
					console.log(data);
					data.forEach(wallet => {

						let found = this.MyWalletsList.find(element => element.id == wallet.id);
						if(found == null){
							this.MyWalletsList.push( new Wallet(wallet) );
						}
						else{
							found.balance = parseFloat(wallet.balance);
						}

					});
					resolve(this.MyWalletsList);

				}).catch((error)=>{
					console.error(error);
					reject(error);
				});
			});
		});

	}

	public getWallet():Wallet{
		let found = this.MyWalletsList.find(element => element.currency == 'MXN');
		return found;
	}

	public KYC_validation(){
		if (Capacitor.getPlatform() !== 'web') {
		return new Promise((resolve, reject) => {
			this.profile_service.getProfileInfo().then((data)=>{
	
				data = JSON.parse(data);

				if(data.kyc == 'Validado'){
					resolve({'permit': true, 'kyc': data.kyc });
				}
				else{
					resolve({'permit': false, 'kyc': data.kyc });
				}
				console.log(data);
				/*this.profile_photo = data.profile_photo.url;
				this.complete_name = data.name + data.last_name;
				this.username = data.username;*/
				resolve(true);
			}).catch((error)=>{
				reject(error);
			});
		});
	}else{
		return new Promise((resolve, reject) => {
			this.profile_service.getProfileInfo().then((data)=>{
	
			

				try {
					// Ensure the data is in the correct format for web
				   // Ensure the data is in the correct format for web
				   if (typeof data === 'string') {
					data = JSON.parse(data);
					   console.log('json result publication service.ts', data);
					 
					 }
					   }catch(e){
						   reject(e)
					   }

				if(data['kyc'] === 'Validado'){
					resolve({'permit': true, 'kyc': data['kyc'] });
				}
				else{
					resolve({'permit': false, 'kyc': data['kyc'] });
				}
				console.log(data);
				/*this.profile_photo = data.profile_photo.url;
				this.complete_name = data.name + data.last_name;
				this.username = data.username;*/
				resolve(true);
			}).catch((error)=>{
				reject(error);
			});
		});

	}

	}
	public KYC_validation3(user_id?: number){
		return new Promise((resolve, reject) => {
			this.profile_service.getProfileInfo(user_id).then((data)=>{
				data = JSON.parse(data);

				if(data.kyc == 'Validado'){
					resolve({'permit': true, 'kyc': data.kyc });
				}
				else{
					resolve({'permit': false, 'kyc': data.kyc });
				}
				console.log(data);
				/*this.profile_photo = data.profile_photo.url;
				this.complete_name = data.name + data.last_name;
				this.username = data.username;*/
				resolve(true);
			}).catch((error)=>{
				reject(error);
			});
		});

	}

	public fetchBalance(userId: number): Promise<any> {
		return new Promise((resolve, reject) => {
		  this.request.createRequestGet('getBalance', { userId }).then((data) => {
			resolve(JSON.parse(data));
		  }).catch((error) => {
			reject(error);
		  });
		});
	  }
	  public getExchangeValue(object): Promise<any>{
		return new Promise((resolve, reject)=>{
			this.request.createRequestGet('getExchangeValue', object).then((data)=>{
				resolve(JSON.parse(data));
        console.log("currencys",data);

			}).catch((error)=>{
				reject(error);
			})
		});
	}

}
