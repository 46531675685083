import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { TerminosCondicionesViewPageRoutingModule } from './terminos-condiciones-view-routing.module';

import { TerminosCondicionesViewPage } from './terminos-condiciones-view.page';
import { TerminosCondicionesModuleModule } from 'src/app/components/Terminos-Condiciones/terminos-condiciones-module/terminos-condiciones-module.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TerminosCondicionesViewPageRoutingModule,
    TerminosCondicionesModuleModule
  ],
  declarations: [TerminosCondicionesViewPage]
})
export class TerminosCondicionesViewPageModule {}
