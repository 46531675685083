import { Component, OnInit } from '@angular/core';
import { FormServiceService } from 'src/app/services/publications/form-service.service';

import  Moment  from 'moment';



@Component({
  selector: 'app-eclipse-eventos2',
  templateUrl: './eclipse-eventos2.component.html',
  styleUrls: ['./eclipse-eventos2.component.scss'],
})
export class EclipseEventos2Component implements OnInit {
  customYearValues = [2020, 2016, 2008, 2004, 2000, 1996];
  customDayShortNames = ['s\u00f8n', 'man', 'tir', 'ons', 'tor', 'fre', 'l\u00f8r'];
  customPickerOptions: any;
 

  constructor(public formservice:FormServiceService) { 
    this.customPickerOptions = {
      buttons: [{
        text: 'Save',
        handler: () => console.log('Clicked Save!')
      }, {
        text: 'Log',
        handler: () => {
          console.log('Clicked Log. Do not Dismiss.');
          return false;
        }
      }]
    }
  }

  ngOnInit(): void { 
    
  }
ObtenerDateIni(event){
  this.formservice.eventospublication.fecha_inicial=event.detail.value
}
ObtenerDateEnd(event){
  this.formservice.eventospublication.fecha_final=event.detail.value
}

ObtenerHrIni(event){
  this.formservice.eventospublication.hora_inicial=event.detail.value
}

ObtenerHrEnd(event){
  this.formservice.eventospublication.hora_final=event.detail.value
}


 

  
}
