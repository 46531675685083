import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MediaItem } from 'src/app/Models/Classes/Media/media-item';
import { PreviewComponent } from '../preview/preview.component';

@Component({
	selector: 'app-slider',
	templateUrl: './slider.component.html',
	styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit {

	@Input() IMultimediable: any;
	@Output() deleteMedia = new EventEmitter<any>();

	slideOpts = {
		slidesPerView: 3,
		spaceBetween: 5,
	};

	constructor(
		private modalController: ModalController
	) { }

	ngOnInit() {
		
	}

	public getSrcFromUri(path:string){
		let src = (<any>window).Ionic.WebView.convertFileSrc(path);
		return src;
	}

	openPreView(media: any, index: number) {
		this.modalController.create({
			component: PreviewComponent,
			cssClass: 'detailsInModal',
			swipeToClose: true,
			componentProps: {
				media: media,
				index: index,
				IMultimediable: this.IMultimediable,
				canBeDeleted: (this.IMultimediable['id'])? false: true,
				deleteMedia: this.deleteMedia,
			}
		}).then((modal)=>{
			modal.present();
		}).catch((error)=>{
			console.error(error);
		});
	}
}
