import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagarCComponent } from 'src/app/components/pagar-c/pagar-c.component';
import { PrincipalPublicationComponent } from '../principal-publication/principal-publication.component';
import { IonicModule } from '@ionic/angular';
import { DescriptionModuleModule } from '../../description-module/description-module.module';
import { CumuloSubscriptionComponent } from '../../cumulos-suscription/cumulos/cumulo-subscription/cumulo-subscription.component';
import { CumuloSubscription2Component } from '../../cumulos-suscription/cumulos/cumulo-subscription2/cumulo-subscription2.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    /* componente principal normal */
    PrincipalPublicationComponent,
    /*Sub Component normal */
    PagarCComponent,
    CumuloSubscriptionComponent,
    CumuloSubscription2Component
  ],
  exports: [
    /* componente principal normal */
    PrincipalPublicationComponent,
    /*Sub Component normal */
    PagarCComponent,
    CumuloSubscriptionComponent,
    CumuloSubscription2Component
  ], entryComponents: [
    /* componente principal normal */
    PrincipalPublicationComponent,
    /*Sub Component normal */
    PagarCComponent,
    CumuloSubscriptionComponent,
    CumuloSubscription2Component
  ],
  imports: [
CommonModule,
    FormsModule,
    IonicModule,
    DescriptionModuleModule
  ]
})
export class NormalPublicationModule { }
