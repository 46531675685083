import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import {Plugins} from '@capacitor/core';
const { BarcodeScanner } = Plugins;
import { AlertController, ModalController } from '@ionic/angular';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import { RequestService } from 'src/app/services/request/request.service';
import Swal2 from 'sweetalert2';
import { Capacitor } from '@capacitor/core';
import { Html5QrcodeScanner, Html5Qrcode } from "html5-qrcode";
@Component({
  selector: 'app-qr-code-generator',
  templateUrl: './qr-code-generator.component.html',
  styleUrls: ['./qr-code-generator.component.scss'],
})
export class QrCodeGeneratorComponent implements OnInit, OnDestroy {
  html5QrCodeScanner: Html5QrcodeScanner | null = null;
  result=null;
  scanActive= false;
  public walletHubble:boolean=true;
  //@Input() qrData: string = '';
  web:boolean=false;

  constructor(
    public alertController: AlertController,
    private formservice:FormServiceService,
    private request:RequestService,
    private modalcoontroller: ModalController
  ) { }

  ngOnInit() {
   // 
   if (Capacitor.getPlatform() === 'web') {
    this.web=true;
    this.initWebScanner();

   }else{
    BarcodeScanner.prepare();
    document.body.classList.toggle('dark');
    this.startScanner();
    this.formservice.mostrar = false;
   }
  
  

  }
  initWebScanner() {
    Swal2.fire({
      title: 'Web Scanner',
      text: 'Using web QR code scanner.',
      icon: 'info',
      confirmButtonText: 'Start Scanning',
      background: '#010f4e',
    }).then(() => {
      document.body.classList.toggle('dark');
      this.formservice.mostrar = false;
      this.startWebScanner();
    });
  }
  async startWebScanner() {
    if (!Html5Qrcode.getCameras()) {
      Swal2.fire({
        title: 'No Camera',
        text: 'No camera found or camera permissions denied.',
        icon: 'error',
        confirmButtonText: 'Ok',
        background: '#010f4e',
      });
      return;
    }

    this.html5QrCodeScanner = new Html5QrcodeScanner(
      "reader", // The ID of the HTML element where the scanner will render
      { fps: 10, qrbox: 250 }, // Configuration options
      false // Verbose output (set to true for detailed logging)
    );
    

    this.html5QrCodeScanner.render(
      this.onScanSuccess.bind(this),
      this.onScanFailure.bind(this)
    );
  }

  stopWebScanner() {
    if (this.html5QrCodeScanner) {
      this.html5QrCodeScanner.clear();
    }
  }
  onScanSuccess(decodedText: string, decodedResult: any) {
    this.result = this.removeHttps(decodedText);
    console.log(`Scan result: ${decodedText}`, decodedResult);
    this.serverScan();
    if (this.html5QrCodeScanner) {
      this.html5QrCodeScanner.clear();
    }
  }

  onScanFailure(error: any) {
    console.warn(`QR Code Scan Error: ${error}`);
  }

  ngOnDestroy(): void {
    this.formservice.mostrar = true;
   
    this.formservice.scanActive=false;
    document.body.classList.toggle('dark');
    if(!this.web){
      BarcodeScanner.stopScan();
    }else{
      this.stopWebScanner();
    }
   
  }




  async startScanner() {
    const allowed = await this.checkPermission();
    if (allowed) {
   
     
      BarcodeScanner.hideBackground();
      this.scanActive = true;
      const result = await BarcodeScanner.startScan();
      if (result.hasContent) {
        this.result = this.removeHttps(result.content); // Remove "https://"
        //this.result = result.content;
        console.log( this.result);
        this.scanActive = false;
        this.serverScan();
      }
      //document.querySelector('body').classList.remove('scanner-active'); // Remove the class
    }
  }
  

  async checkPermission(){
    return new Promise (async (resolve, reject) => {
      const status = await BarcodeScanner.checkPermission({force:true});
      if (status.granted){
      resolve(true);
      }else if (status.denied){
        const alert = await this.alertController.create({
          header: 'No hay permiso',
          message: 'Porfavor autoriza el acceso a la camara en ajustes',
          buttons: [{
            text: 'No',
            role: 'Cancel'
          },
          {
            text: 'Open settings',
            handler: () => {
              BarcodeScanner.openAppSettings();
              resolve(false);
            }
          }

          ]
        });
        await alert.present();

      }else{
        resolve(false);
      }
    });

    }

    stopScanner(){
      BarcodeScanner.stopScan();
      this.scanActive=false;
    }

    serverScan() {
      if (this.walletHubble===false){
        this.request.createRequestPost('scanReservations', { id: this.result })
        .then((final_data) => {
         
            Swal2.fire({
              title: 'Ticket Escaneado',
              text: 'El ticket ha sido escaneado exitosamente.',
              icon: 'success',
              confirmButtonText: 'Aceptar',
              background: '#010f4e',
            });
       
        })
        .catch((error) => {
          console.error('An error occurred:', error);
          Swal2.fire({
            title: 'Error',
            text: 'Ocurrió un error inesperado. Si el problema persiste, comunícate con el soporte de Laniakea. Es probable que el ticket ya ha sido escaneado previamente',
            icon: 'error',
            confirmButtonText: 'Entendido',
            background: '#010f4e',
          });
        });
      }else{
        this.request.createRequestPost('HubbleToHubbleTransfer', { id: this.result })
        .then((final_data) => {
         
            Swal2.fire({
              title: 'Transferencia exitosa',
              text: 'La transferencia ha sido enviada exitosamente.',
              icon: 'success',
              confirmButtonText: 'Aceptar',
              background: '#010f4e',
            });
            this.modalcoontroller.dismiss();
       
        })
        .catch((error) => {
          console.error('An error occurred:', error);
          Swal2.fire({
            title: 'Error',
            text: 'Ocurrió un error inesperado. Si el problema persiste, comunícate con el soporte de Laniakea. Es probable que el ticket ya ha sido escaneado previamente',
            icon: 'error',
            confirmButtonText: 'Entendido',
            background: '#010f4e',
          });
        });
      }
     
    }
    
    removeHttps(content: string): string {
      return content.replace(/^https:\/\//i, ''); // Replace "https://" with an empty string
    }   
    dismiss(){
      this.modalcoontroller.dismiss();
    }
  }

  

