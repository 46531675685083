import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';
import { CommentsService } from 'src/app/services/comments/comments.service';
import { Utils } from '../../../../Models/Classes/utils';
import { CameraServiceService } from 'src/app/services/multimedia/camera-service.service';
import { PublicationClass } from 'src/app/Models/Classes/Publications/publication-class';
import { PublicationService } from 'src/app/services/publications/publication.service';
import { CommentClass } from 'src/app/Models/Classes/Publications/Comments/comment-class';
import { UploadMultimediaService } from 'src/app/services/upload/upload-multimedia.service';
import { element } from 'protractor';
import { File } from '@ionic-native/file/ngx';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import { RequestService } from 'src/app/services/request/request.service';
import Swal2 from 'sweetalert2';
import { AlertController } from '@ionic/angular';
import { Howl } from 'howler';
import {Capacitor} from "@capacitor/core";
@Component({
	selector: 'app-comments-modal',
	templateUrl: './comments-modal.component.html',
	styleUrls: ['./comments-modal.component.scss'],
})
export class CommentsModalComponent implements OnInit, OnDestroy {
public noLikes: boolean= false;
	public newComment: CommentClass;
	private paginatorIndex: number = 1;
	@Input() reviews:boolean=false;
	@Input() Valoraciones: any;
	@Input() publicationId: number;
	@Input() publication: PublicationClass;
	@Input() CommentatorUsername: any;
	@Input() user_id: number;
	@Output() deleteMediaEmit = new EventEmitter<any>();

	@ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

	constructor(
		private request:RequestService,
		private cameraService: CameraServiceService,
		private commentService: CommentsService,
		private utils: Utils,
		private publicationService: PublicationService,
		private modalCtrl: ModalController,
		public  uploadmedia: UploadMultimediaService,
		public formservice: FormServiceService,
		private alertController: AlertController
	) { }

	ngOnInit() {
		this.newComment = new CommentClass(this.publicationId);
		if (this.reviews ===false){
			this.getComments();
		}else{
			this.noLikes=true;
			this.getReviews();
		}
	
	}
ngOnDestroy(): void {
	
}
	back() {
		this.modalCtrl.dismiss({
		  'dismissed': true
		});
	  }

	clickOnSend() {
		if (this.commentService.blobsMultimediaComment.length>0 || !this.IsEmptyText(this.newComment.getText())) {
			this.sendNotificationComment();
			const audioFile = 'assets/sounds/send_sound.wav';
		const sound = new Howl({
		  src: [audioFile],
		  autoplay: true,
		  volume: 1.0, // Initial volume level (0.0 to 1.0)
		});
		sound.play();
			if(this.reviews===false){
				this.postComment();
			}else{
				this.postReview();
			}
			
		}
	}

	IsEmptyText(textInput: string) {
		textInput = textInput.replace(/[\r\n\s]+/gm, "");
		if (textInput.length > 0) {
			return false;
		}
		return true;
	}

	setComment(comment: string) {
		this.newComment.setText(comment);
	}

	takePhoto() {
		this.cameraService.takePhoto().then((mediaItem) => {
			this.newComment.setMultimedia(mediaItem);
		}).catch((error) => {
			console.error(error);
		});
	}

	pickMedia() {
		this.cameraService.pickMedia().then((mediaItem) => {
			this.newComment.setMultimedia(mediaItem);
		}).catch((error) => {
			console.error(error);
		});
	}

	takeVideo() {
		this.cameraService.takeVideo().then((mediaItem) => {
			this.newComment.setMultimedia(mediaItem);
		}).catch((error) => {
			console.error(error);
		});
	}

	getComments() {


		this.commentService.getComments(this.publicationId, 1).then((data: any) => {

			Object.keys(data.data).forEach(key => {
				let value = data.data[key];
			
				this.commentService.updateCommentList(value, this.publication).then((comment) => {

				}).catch((error) => {
					console.error(error);
				});
			});

			this.paginatorIndex = 2;


		}).catch(error => {
			console.log(error);
			if (error.error) {
				error = JSON.parse(error.error);
				if (error.error == 'the publication no longer exist') {
					this.modalCtrl.dismiss();
					this.publicationService.DeleteLocalPublicationByID(this.publication.id);
				}
			}
		});
	}
	getReviews() {


		this.commentService.getReviews(this.publicationId, 1).then((data: any) => {

			Object.keys(data.data).forEach(key => {
				let value = data.data[key];
	
				this.commentService.updateReviewList(value, this.publication).then((comment) => {

				}).catch((error) => {
					console.error(error);
				});
			
			});

			this.paginatorIndex = 2;


		}).catch(error => {
			console.log(error);
			if (error.error) {
				error = JSON.parse(error.error);
				if (error.error == 'the publication no longer exist') {
					this.modalCtrl.dismiss();
					this.publicationService.DeleteLocalPublicationByID(this.publication.id);
				}
			}
		});
	}

	moreData(event?) {
		this.commentService.getComments(this.publicationId, this.paginatorIndex).then((data: any) => {

			if (this.reviews===false){
			Object.keys(data.data).forEach(key => {
				let value = data.data[key];

				this.commentService.updateCommentList(value, this.publication).then((comment) => {

				}).catch((error) => {
					console.error(error);
				});
			});
		}else{
			Object.keys(data.data).forEach(key => {
				let value = data.data[key];

				this.commentService.updateReviewList(value, this.publication).then((comment) => {

				}).catch((error) => {
					console.error(error);
				});
			});
		}


			this.paginatorIndex++;
			event.target.complete();

			if (data.last_page < this.paginatorIndex) {
				event.target.disabled = true;
			}
		}).catch(error => {
			console.error("Error Get Comments", error);
		});
	}

	public postComment() {
		let temporalComment =  this.newComment;
		this.newComment = new CommentClass(temporalComment.publication_id);
		this.utils.loadingPresent().then(() => {
			this.commentService.postComment(temporalComment.getDataToSend(), this.publicationId).then((data: any) => {
				if (Capacitor.getPlatform() !== 'web') {
				data = JSON.parse(data);
				}
				//console.log(data);
				let commentId = data.comment_id;
        console.log('blobs multimedia', this.commentService.blobsMultimediaComment);
				if (this.commentService.blobsMultimediaComment.length>0) {
					this.commentService.blobsMultimediaComment.forEach(element=>{
						this.uploadmedia.post(element.blob, element.filename).then((r)=>{
							const ruta = r[0];
							const mimeType = r[1];
							let pub_type = '';
							if(mimeType.includes("image")) {
							  pub_type= 'imagen';
							}else if(mimeType.includes("video")){
							  pub_type= 'video';
							}

							const objmedia = {
							  route: ruta,
							  size: 800,
							  type:pub_type,
                comment_id: data.comment_id

							};
							this.request.createRequestPost('comments.multimedia',objmedia).then(() => {
                this.utils.loadingDismiss();
                this.commentService.blobsMultimediaComment = [];
                this.commentService.multimediaComment = [];
                this.uploadmedia.image = '';
                 	this.updateStatusComment(this.publicationId, commentId).finally(() => {
                    this.utils.loadingDismiss().finally(() => {

                      this.commentService.getCommentFromServer(this.publication.id, commentId).then((final_data) => {
                        this.commentService.updateCommentList(final_data, this.publication);
                      }).catch((error) => {
                        console.error(error);
                      });
                    });
                  });
							}).catch((error) => {
							  this.utils.loadingDismiss();
							  Swal2.fire({
								title: 'Error al subir multimedia!',
								text: error,
								icon: 'warning',
								confirmButtonText: 'Entendido',
								color: '#ffffff',
								background: '#010f4e',
							  });

							});

						  });

					});
				}else {
					this.utils.loadingDismiss().finally(() => {
							this.commentService.updateCommentList(data.comment, this.publication);
            this.commentService.blobsMultimediaComment = [];
            this.newComment.deleteMultimedia(0);
					});
				}
			}).catch((error) => {
				console.error(error);
				this.utils.loadingDismiss();
				if(error.error){
					error = JSON.parse(error.error);
					if (error.error == 'the publication no longer exist') {
						this.modalCtrl.dismiss();
						this.publicationService.DeleteLocalPublicationByID(this.publication.id);
					}
				}
			}).finally(() => {
				//this.newComment = new CommentClass(this.publicationId);
			});
		});

	}
	public postReview() {
		let temporalComment =  this.newComment;
		this.newComment = new CommentClass(temporalComment.publication_id);
		this.utils.loadingPresent().then(() => {
			this.commentService.postReview(temporalComment.getDataToSend(), this.publicationId).then((data: any) => {
				data = JSON.parse(data);
				//console.log(data);
				let commentId = data.comment_id;
        console.log('blobs multimedia', this.commentService.blobsMultimediaComment);
				if (this.commentService.blobsMultimediaComment.length>0) {
					this.commentService.blobsMultimediaComment.forEach(element=>{
						this.uploadmedia.post(element.blob, element.filename).then((r)=>{
							const ruta = r[0];
							const mimeType = r[1];
							let pub_type = '';
							if(mimeType.includes("image")) {
							  pub_type= 'imagen';
							}else if(mimeType.includes("video")){
							  pub_type= 'video';
							}

							const objmedia = {
							  route: ruta,
							  size: 800,
							  type:pub_type,
                comment_id: data.comment_id

							};
							this.request.createRequestPost('comments.multimedia',objmedia).then(() => {
                this.utils.loadingDismiss();
                this.commentService.blobsMultimediaComment = [];
                this.commentService.multimediaComment = [];
                this.uploadmedia.image = '';
                 	this.updateStatusComment(this.publicationId, commentId).finally(() => {
                    this.utils.loadingDismiss().finally(() => {

                      this.commentService.getCommentFromServer(this.publication.id, commentId).then((final_data) => {
                        this.commentService.updateCommentList(final_data, this.publication);
                      }).catch((error) => {
                        console.error(error);
                      });
                    });
                  });
							}).catch((error) => {
							  this.utils.loadingDismiss();
							  Swal2.fire({
								title: 'Error al subir multimedia!',
								text: error,
								icon: 'warning',
								confirmButtonText: 'Entendido',
								color: '#ffffff',
								background: '#010f4e',
							  });

							});

						  });

					});
				}else {
					this.utils.loadingDismiss().finally(() => {
				
							this.commentService.updateReviewList(data.comment, this.publication);
						
						
            this.commentService.blobsMultimediaComment = [];
            this.newComment.deleteMultimedia(0);
					});
				}
			}).catch((error) => {
				console.error(error);
				this.utils.loadingDismiss();
				if(error.error){
					error = JSON.parse(error.error);
					if (error.error == 'the publication no longer exist') {
						this.modalCtrl.dismiss();
						this.publicationService.DeleteLocalPublicationByID(this.publication.id);
					}
				}
			}).finally(() => {
				//this.newComment = new CommentClass(this.publicationId);
			});
		});

	}

	public updateStatusComment(id_publication: number, id_comment: number) {
		return new Promise((resolve, reject) => {
			let status = { status: "Activa" }
			this.commentService.putComment(status, id_publication, id_comment).then((data) => {
				resolve(data);
			}).catch((error) => {
				reject(error);
			});
		});
	}

	//public postComment(){}

	public deleteMedia(event){}
	sendNotificationComment() {
   
		return new Promise((resolve, reject) => {
		  const notification = {
			title: 'Nuevo comentario en tu publicación',
			body: `¡Has recibido un comentario de @${this.CommentatorUsername} en tu publicación!`,
			user_id: this.user_id,
	
		  };
	  
		  this.request.createRequestPost('GreatAtractorNotifications', notification)
			.then((data) => {
			
			  resolve(data);
			}).catch((error) => {
			  console.error('Failed to send notification:', error);
			  reject(error);
			});
		});
	  }
	   // Function to generate an array of icon names based on value
	 getStarIcons(value: number): string[] {
		const icons: string[] = [];
		const fullStars = Math.floor(value);
		const halfStar = value - fullStars >= 0.5;
	
		for (let i = 0; i < fullStars; i++) {
		  icons.push('star');
		}
	
		if (halfStar) {
		  icons.push('star-half');
		}
	
		while (icons.length < 9) {
		  icons.push('star-outline');
		}
	
		return icons;
	  }
	  async presentStarsSelectionAlert() {
		const alert = await this.alertController.create({
		  header: 'Selecciona tu valoración',
		  inputs: [
			{
			  type: 'radio',
			  label: '1 estrella',
			  value: 1,
			},
			{
			  type: 'radio',
			  label: '2 estrellas',
			  value: 2,
			},
			{
			  type: 'radio',
			  label: '3 estrellas',
			  value: 3,
			},
			{
			  type: 'radio',
			  label: '4 estrellas',
			  value: 4,
			},
			{
			  type: 'radio',
			  label: '5 estrellas',
			  value: 5,
			},
			{
				type: 'radio',
				label: '6 estrellas',
				value: 6,
			  },
			  {
				type: 'radio',
				label: '7 estrellas',
				value: 7,
			  },
			  {
				type: 'radio',
				label: '8 estrellas',
				value: 8,
			  },
			  {
				type: 'radio',
				label: '9 estrellas',
				value: 9,
			  },
		  ],
		  buttons: [
			{
			  text: 'Cancelar',
			  role: 'cancel',
			  cssClass: 'secondary',
			  handler: () => {
				console.log('Cancelar clicked');
			  },
			},
			{
			  text: 'Aceptar',
			  handler: (selectedValue) => {
				console.log('Selected value:', selectedValue);
				this.enviarValoracion(selectedValue);
				// You can update the selected value to your component property here
				// For example: this.valoracion = selectedValue;
			  },
			},
		  ],
		});
	  
		await alert.present();
	  }


	  enviarValoracion(value){
	
   
			return new Promise((resolve, reject) => {
			  const notification = {
			value: value,
			publication_id: this.publicationId,
		
			  };
		  
			  this.request.createRequestPost('ValorarPublicaciones', notification)
				.then((data) => {
					Swal2.fire({
						title: 'Valoración enviada exitosamente',
				
						icon: 'success',
						confirmButtonText: 'OK',
						color: '#ffffff',
						background: '#010f4e',
					  });
				  resolve(data);
				}).catch((error) => {
					Swal2.fire({
						title: 'No se pudo procesar tu valoración',
				
						icon: 'error',
						confirmButtonText: 'OK',
						color: '#ffffff',
						background: '#010f4e',
					  });
				  console.error('Failed to send notification:', error);
				  reject(error);
				});
			});
		  
	  }
}
