import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  NgZone,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { RequestService } from 'src/app/services/request/request.service';
import Swal2 from 'sweetalert2';
import { ModalController } from '@ionic/angular';
import { dismiss } from '@ionic/core/dist/types/utils/overlays';
import { PagoVerificacionComponent } from './../pago-verificacion.component';
import { create } from 'domain';
import { Message } from './../../../Models/Classes/Chat/message';
import {isNumeric} from "rxjs/internal-compatibility";
import { HttpClient } from '@angular/common/http';
import { transaction,createTransactionDTO } from './../../../Models/Interfaces/transaction_stripe.model';
import {input} from "aws-amplify";
import { Wallet } from 'src/app/Models/Classes/Wallet/wallet';
import { WalletServiceService } from 'src/app/services/wallet/wallet-service.service';
// import { environment } from './../../../../environments/environment.prod';
//import { InAppPurchase2 } from '@ionic-native/in-app-purchase-2/ngx';

import { environment } from 'src/environments/environment';
import { Stripe } from '@awesome-cordova-plugins/stripe/ngx';
import { Key } from 'protractor';

declare function validateCreditCard(): any;

// src/openpay.d.ts
declare var OpenPay: any;

const KEY = environment.apikeystripe;
@Component({
  selector: 'app-Pago-Tarjeta',
  templateUrl: './Pago-Tarjeta.component.html',
  styleUrls: ['./Pago-Tarjeta.component.scss'],
})
export class PagoTarjetaComponent implements OnInit, OnChanges {
  cardNum: string;
  titular: string;
  month: number;
  year: number;
  cvc: string;
  image_brand: string = '';
  amount=99;
  currency:string="mxn"
  @Input() profile: any;
  @Input() openpay_subscription_payment: any;
  deviceDataId;
  object;
  openpay_token;
  formData = {
    city: 'Querétaro',
    line3: 'Queretaro',
    postal_code: '',
    line1: 'Av 5 de Febrero',
    line2: 'Roble 207',
    state: 'Queretaro',
    country_code: 'MX',
  };
  deviceSessionId;
  constructor(
    private NgZone: NgZone,
    private router: Router,
    private request: RequestService,
    private modalcontroller: ModalController,
    private profileService: ProfileService,
    private walletService:WalletServiceService,
    private stripe:Stripe,
    //private iap2: InAppPurchase2,
  ) {
    this.stripe.setPublishableKey(KEY)

  }
  onSuccess(response){
    var token_id = response.data.id;
 
console.log('onSuccess',token_id);
this.openpay_token=token_id;
this.pagar_suscripcion();

  }
  onError(){
    console.log('onError');
    Swal2.fire({
      title: 'Pago Rechazado',
      text: 'Por favor, intentelo de nuevo o de lo contrario utilize otra tarjeta',
      icon: 'error',
      confirmButtonText: 'Ok',
      color: '#ffffff',
      background: '#010f4e',
    })
  }
  loadScripts() {
    // Load jQuery
    this.loadScript('https://ajax.googleapis.com/ajax/libs/jquery/1.11.0/jquery.min.js')
      .then(() => {
        // Load OpenPay scripts after jQuery is loaded
        return this.loadScript('https://js.openpay.mx/openpay.v1.min.js');
      })
      .then(() => {
        return this.loadScript('https://js.openpay.mx/openpay-data.v1.min.js');
      })
      .then(() => {
        // Initialize OpenPay after all scripts are loaded
        this.initializeOpenPay();
      })
      .catch((error) => {
        console.error('Error loading scripts', error);
      });
  }

  loadScript(src: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.type = 'text/javascript';
      script.onload = () => resolve();
      script.onerror = (error) => reject(error);
      document.head.appendChild(script);
    });
  }

  initializeOpenPay(): void {

      OpenPay.setId('mzdtln0bmtms6o3kck8f');
      OpenPay.setApiKey('pk_f0660ad5a39f4912872e24a7a660370c');
       this.deviceSessionId = OpenPay.deviceData.setup();
      console.log('Device Session ID:', this.deviceSessionId);

  }
  ngOnInit() {
    this.loadScripts();
    console.log(this.walletService.amount);
    // If you are testing your application, set Sandbox Mode first

OpenPay.setId('moljimj06bosrtpbhj7r');
OpenPay.setApiKey('pk_0b4026fe78b64640a32664d0034b6292');
OpenPay.setSandboxMode(true);
//this.pagar_verificacion();
console.log('getSandboxMode',OpenPay.getSandboxMode());
console.log('OpenPay',OpenPay);
/*
OpenPay.token.create({
  card_number:"4111111111111111",
  holder_name:"Juan Perez Ramirez",
  expiration_year:27,
  expiration_month:12,
  cvv2:"110",
  address:{
     city:"Querétaro",
     line3:"Queretaro",
     postal_code:"76900",
     line1:"Av 5 de Febrero",
     line2:"Roble 207",
     state:"Queretaro",
     country_code:"MX"
  }
}, this.onSuccess, this.onError);
*/



  }

  regresar(){
    this.modalcontroller.dismiss()
  }
  get_card_type()
  {
    console.log('refactoring card');
    this.image_brand = "";

    //Remove all spaces and dashes from the passed string
    const CardNo = this.cardNum.replace("-", "").replace(" ", "");

    //Check that the minimum length of the string isn't less
    //than fourteen characters and -is- numeric
    if (CardNo.length < 14 || !isNumeric(CardNo)) {
      this.image_brand = ''
    }

    //Check the first two digits first
    if(parseInt(this.cardNum.substring(0,2)) === 34 || parseInt(this.cardNum.substring(0,2)) === 37) {
      this.image_brand = 'assets/icon/pagos/amex.png';

    }else if(parseInt(this.cardNum.substring(0,2)) >= 51 && parseInt(this.cardNum.substring(0,2)) <= 55){
      this.image_brand = 'assets/icon/pagos/master_card.png';
    }else if(parseInt(this.cardNum.substring(0,1)) === 4) {
      this.image_brand = 'assets/icon/pagos/visa.png';
    }








    console.log(this.image_brand);

    this.cardNum = this.cardNum.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();

  }

  pagar_suscripcion(){
  this.request.createRequestPost('VerificationWithOpenPay', {token:this.openpay_token, device_session_id:this.deviceSessionId, holder_name:this.titular})
  .then((data) => {
    console.log('data',data);
    if(data.success){
      Swal2.fire({
        title: 'Pago de inscripción procesado con éxito!',
        text: 'Continua con los siguientes pasos para regocijarte como usuario Verificado.',
        icon: 'success',
        confirmButtonText: 'Ok',
        color: '#ffffff',
        background: '#010f4e',
      }).then((r) =>{

        this.profileService.getProfileInfo().then((profile) => {
          console.log("promesa del perfile en verificación", profile);
          this.modalcontroller.dismiss(JSON.parse(profile));
        })

}).catch((error) => {
console.log("Error de stripe",error);
});

    }else{
      Swal2.fire({
        title: 'Pago Rechazado',
        text: 'Por favor, intentelo de nuevo o de lo contrario utilize otra tarjeta',
        icon: 'error',
        confirmButtonText: 'Ok',
        color: '#ffffff',
        background: '#010f4e',
      })
    }

}).catch((error) => {
  console.log("Error de openpay",error);
  Swal2.fire({
    title: 'Pago Rechazado',
    text: 'Por favor, intentelo de nuevo o de lo contrario utilize otra tarjeta',
    icon: 'error',
    confirmButtonText: 'Ok',
    color: '#ffffff',
    background: '#010f4e',
  })
});
}

  pagar(){
    if(this.openpay_subscription_payment){
      if(!this.formData.postal_code){
        Swal2.fire({
          title: 'Pago Rechazado',
          text: 'Por favor, añade un código postal.',
          icon: 'error',
          confirmButtonText: 'Ok',
          color: '#ffffff',
          background: '#010f4e',
        })
        return;
      }
      OpenPay.token.create({
        card_number:this.cardNum,
        holder_name:this.titular,
        expiration_year:this.year,
        expiration_month:this.month,
        cvv2:this.cvc,
        address:{
           city:"Querétaro",
           line3:"Queretaro",
           postal_code:"76900",
           line1:"Av 5 de Febrero",
           line2:"Roble 207",
           state:"Queretaro",
           country_code:"MX"
        }
      }, this.onSuccess, this.onError);

    }else{


    
    let card = {
      number: this.cardNum.replace(' ', ''),
      expMonth: this.month,
      expYear: this.year,
      cvc: this.cvc
     }
     
      
     this.stripe.createCardToken(card)
     .then((token) =>{
      const token_id=token.id
      console.log("Token obtenido",token_id);

      let object={
          amount:this.amount,
          currency:this.currency,
          description:"Validacion",
          tokenid:token_id,
          profile_id: this.profile.id  // Pass the profile id
        }
        this.request.createRequestPost('stripe-pay',object).then((result)=>{
          const parsedResult = JSON.parse(result);  // Parse the result string into a JSON object
          console.log("resultado de respuesta back stripe",result);
          console.log(JSON.stringify(result));

          if (parsedResult.code == 200) {
this.pagar_suscripcion();
              Swal2.fire({
                title: 'Pago de inscripción procesado con éxito!',
                text: 'Continua con los siguientes pasos para regocijarte como usuario Verificado.',
                icon: 'success',
                confirmButtonText: 'Ok',
                color: '#ffffff',
                background: '#010f4e',
              }).then((r) =>{

                this.profileService.getProfileInfo().then((profile) => {
                  console.log("promesa del perfile en verificación", profile);
                  this.modalcontroller.dismiss(JSON.parse(profile));
                })
    

                //return this.subscribeToProduct('1618');
  //}).then(() => {
    //return this.profileService.getProfileInfo();
  //}).then((profile) => {
    //console.log("promesa del profile en verificacion",profile);
   // this.modalcontroller.dismiss(JSON.parse(profile));
  }).catch((error) => {
    console.log("Error de stripe",error);
  });

         
            
            
          } else {
            Swal2.fire({
              title: 'Pago Rechazado',
              text: 'Por favor, intentelo de nuevo o de lo contrario utilize otra tarjeta',
              icon: 'error',
              confirmButtonText: 'Ok',
              color: '#ffffff',
              background: '#010f4e',
            })

            
          }

          
         
          
        })
          
        .catch(error => console.error(error));
     })

    
  
  
     
        // const tokenid=token.id

        // let object={
        //   amount:this.amount,
        //   currency:this.currency,
        //   description:"Validacion",


        //   tokenid:tokenid
        // }

      //   this.request.createRequestPost('stripe-pay',object).then((result) =>{
      //     console.log("Respuesta back", result);
          
        //   Swal2.fire({
        //     title: 'Pago Exitoso',
        //     text: 'Su pago fue procesado con exito',
        //     icon: 'success',
        //     confirmButtonText: 'Ok',
        //     color: '#ffffff',
        //     background: '#010f4e',
        //   }).then((r) =>{
        //     this.profileService.getProfileInfo().then((profile) => {
        //       this.modalcontroller.dismiss(JSON.parse(profile));
        //     });
          
        // }).catch((error)=>{
        //   console.log("Error de stripe",error);
        // });

      // })

    
      // amount: this.walletService.amount,
      // type: "validacion"
      

          
    }
  }


 
  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
  }
  
  
  



}
