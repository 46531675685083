import { Component, Input, OnInit } from '@angular/core';
import { Vibration } from '@ionic-native/vibration/ngx';
import { Platform, PopoverController } from '@ionic/angular';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
@Component({
	selector: 'app-pop-options-list',
	templateUrl: './pop-options-list.component.html',
	styleUrls: ['./pop-options-list.component.scss'],
})
export class PopOptionsListComponent implements OnInit {
	@Input() title;
	@Input() optionList: Array<any>;
	
	public main_available_sections: Array<string> = ["EN LA APP", "TERCEROS"];
	public main_icons_for_sections: Array<string> = [ "cloud-upload-outline", "cloud-done-outline"];
	constructor(
		private popoverController: PopoverController,
		private vibrationCtrl: Vibration,
		private platform: Platform,
		public formservice:FormServiceService
	) { }
	call_emitChange(event){
	
		this.formservice.popup_section=event.detail.value;
		if(this.formservice.popup_section==='EN LA APP'){
			
		}
	}
	ngOnInit() {
		this.optionList.push({text: "Cancelar", icon: "close-circle-sharp"});
		if (this.platform.is('android') || this.platform.is('ios')) {
			this.vibrationCtrl.vibrate(400);
		}

    console.log("option list",this.optionList);


	}

	selectOption(option) {
		if(option.text != "Cancelar"){
			this.popoverController.dismiss(option);
			
		}
		else{
			this.popoverController.dismiss();
		}

	}

}
