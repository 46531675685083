import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input, OnChanges,
  OnInit,
  Output,
  QueryList, SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {
  ActionSheetController,
  GestureController,
  IonSlides,
  ModalController,
  NavController,
  Platform,
  ToastController
} from '@ionic/angular';
import { PublicationClass } from 'src/app/Models/Classes/Publications/publication-class';
import { Utils } from 'src/app/Models/Classes/utils';
import { PublicationService } from 'src/app/services/publications/publication.service';
import { InputAlertControllerService } from 'src/app/services/utils/input-alert-controller.service';
import {CommentsModalComponent} from '../comments/comments-modal/comments-modal.component';
import {ReactionssService} from '../../../services/reactions/reactions.service';
import {ActualizacionServiceService} from "../../../services/actualizacion/Actualizacion-service.service";
import Swal2 from "sweetalert2";
import  Swal from 'sweetalert2';
import { LkfilmViewBySlidesComponent } from './../../lkfilm-view-by-slides/lkfilm-view-by-slides.component';
import { RegaloModalComponent } from './../../general-design/Regalo-Modal/Regalo-Modal.component';
import {RequestService} from "../../../services/request/request.service";
import {FormServiceService} from "../../../services/publications/form-service.service";
import { PositionOptions } from '@capacitor/geolocation';
import { Message } from 'src/app/Models/Classes/Chat/message';
import { duration } from 'moment';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { WalletServiceService } from 'src/app/services/wallet/wallet-service.service';
import { SliderInformationComponent } from './../../slider-information/slider-information.component';
import { PagoVerificacionComponent } from './../../pago-verificacion/pago-verificacion.component';
import { ProfileService } from 'src/app/services/profile/profile.service';
//import {ChangeDetectorRef} from '@angular/core';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { PublicacionesColeccionComponent } from 'src/app/components/publication/create/publicaciones-coleccion/publicaciones-coleccion/publicaciones-coleccion.component';
import { InAppPurchase2,IAPProduct } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
//import { AppComponent } from 'src/app/app.component';
import { Share } from '@capacitor/share';
import {Capacitor} from "@capacitor/core";
import { CommentClass } from 'src/app/Models/Classes/Publications/Comments/comment-class';
import { CommentsService } from 'src/app/services/comments/comments.service';
import { Howl } from 'howler';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { PopoverController } from '@ionic/angular';
import {PlaylistsComponent} from 'src/app/playlists/playlists.component';
//import {ContentMediaComponent} from 'src/app/components/publication/single-elements/content-media/content-media.component';
import { SeguridadComponent } from 'src/app/components/general-design/seguridad/seguridad.component';
@Component({
	selector: 'app-publication-media-template',
	templateUrl: './publication-media-template.component.html',
	styleUrls: ['./publication-media-template.component.scss'],
})
export class PublicationMediaTemplateComponent implements AfterViewInit, OnChanges {
  timeoutId;
  SlideInteraction:boolean=true;
  @ViewChild('slides', { static: true }) slides: IonSlides;
	public newComment: CommentClass;
  public HubbleExpired: boolean = false;
  pr:any;
  public msg:string="Leyendo.."
  productId2: string = '1618';
  @Input() publications: any;
  @Input() valueSelect: string;
  @Input() actual_publication_section: string;
  @Input() profile_id?: number;
  NextSlide: any;
	@Input() discardInteractions: boolean = false;
	@Output() publicationDeleted = new EventEmitter<boolean>();
  @Input() initial_index = 0;
  public publication_position = 0;
  slideOpts = {
    direction: 'vertical',
    initialSlide: 0,
    speed: 400,
  };
  lanzamiento
  numero=10
  mostrar:boolean=false
  activeSlideIndex: number;
	constructor(
		//public actionSheetController: ActionSheetController,
		private publicationService: PublicationService,
		private utils: Utils,
		private inputAlertController: InputAlertControllerService,
		private reactionService: ReactionssService,
    private modalController: ModalController,
    private actualizacionservice: ActualizacionServiceService,
    private formService: FormServiceService,
    private request: RequestService,
    //private toastController: ToastController,
    private faio:FingerprintAIO,
    private walletService: WalletServiceService,
    private profile:ProfileService,
    //private changeDetectorRef: ChangeDetectorRef,
    private clipboard: Clipboard,
    private store: InAppPurchase2, 
   // public app :  AppComponent,
    private ActionSheetController: ActionSheetController,
    private toastController: ToastController,
    private commentService: CommentsService,
    private metaService: Meta, 
    private titleService: Title,
    private popoverCtrl:PopoverController

   // private web: WebviewComponent 
    ) { 
	}

  ngOnChanges(changes: SimpleChanges): void {
    
    console.log('Publication media template Changes detected at ', changes);
    if (changes !== undefined) {
      
      if('publications' in changes) {
        this.publications = changes.publications.currentValue;

      }
    }


  }

  click_on_share(publication: any) {
    const publicationId = publication.id;
    const url ='App web: '+ `https://supercumulo.laniakea.tv/home/go-ecosystem-page?p=${publicationId}` +
    'App ios/android: ' + `laniakea://supercumulo.laniakea.tv/home/go-ecosystem-page?p=${publicationId}`;
  
    if(Capacitor.getPlatform() !== 'web'){
    // Copy the URL to clipboard
    this.clipboard.copy(url)
      .then(() => {
        console.log('URL copied to clipboard:', url);
        Swal.fire({
          title: '¡URL copiado!',
          text: 'El URL ha sido copiado en el portapapeles.',
          icon: 'success',
          timer: 2000, // Show the alert for 2 seconds
          timerProgressBar: true,
          toast: true,
          position: 'top-end',
          showConfirmButton: false
        });
      })
      .catch((error) => {
        console.error('Error copiando el URL en el portapapeles:', error);
        Swal.fire({
          title: 'Error',
          text: 'Error copiando el URL en el portapapeles.',
          icon: 'error',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000 // Show the alert for 2 seconds
        });
      });
    }else{
      navigator.clipboard.writeText(url).then(() => {
        Swal.fire({
          title: 'Respuesta copiada!',
          text: 'La respuesta ha sido copiado en el portapapeles.',
          icon: 'success',
          timer: 2000, // Show the alert for 2 seconds
          timerProgressBar: true,
          toast: true,
          position: 'top-end',
          showConfirmButton: false
          });
      }).catch((error) => {
        //console.error(error);
      });
    }
  }

  async fetchcounters() {
    //await this.fetchGiftCount();
    await this.incrementViews();
    //await this.showViews(); // Call showViews after incrementing views
  }
  

  ngOnInit() {
    
   
    this.publication_position = 0;
    this.formService.lkpublication.index_close = 0;
    this.profile.getProfileInfo().then((result)=>{
      this.pr = JSON.parse(result);
      console.log("profile", this.pr);
      });
      //this.slides.ionSlidesDidLoad.subscribe(() => {
        //this.autoplayActiveSlide();
      //});
     // this.titleService.setTitle(this.publications[0]['userProfile']['username']);
    //  this.metaService.updateTag({ name: 'description', content: this.formatCounter(this.publications[0].views ?? '0') + ' vistas. '+ this.publications[0]['description']});

	}


/*
  autoplayActiveSlide() {
    this.slides.getActiveIndex().then((index: number) => {
      this.activeSlideIndex = index;
      console.log('Template Active slide index:', this.activeSlideIndex);
    });
  }
  */
  
  
  async nextPub() {
    if(this.SlideInteraction){
this.SlideInteraction=false;
this.timeoutId = setTimeout(() => {
  this.SlideInteraction=true;
}, 3000);
console.log('next');
const currentIndex = await this.slides.getActiveIndex();
const totalSlides = await this.slides.length();

if (currentIndex < totalSlides - 1) {
  this.slides.slideNext();
} else {
  console.log('No more pub slides available');
}
    }
   
  }
  backPub(){
    this.slides.slidePrev(); // Method to move to the next slide
  }
  
  
  
  async incrementViews() {
    console.log(this.publications);
    try {
      
      for (const publication of this.publications) {
        const id = {
          id: publication.id,
          // Prepare your data here
        };
  
        // Request to increment the views
        console.log('Incrementing views for publication:', publication.id);
        const incrementResponse = await this.request.createRequestPost('incrementViews', id);
        console.log('Increment response:', incrementResponse);
  
        // Check if the increment was successful
        if (incrementResponse && incrementResponse.message === 'Views incremented successfully.') {
          console.log('Views incremented successfully.');
  
          // Update the views count in the publication object
         // publication.views = incrementResponse.views;
        } else {
          // Ignore the error output and continue the loop
          continue;
        }
      }

    } catch (error) {
      console.error('Error incrementing publication views:', error);
    }
  }
  
  
  


  
  
  async ngAfterViewInit() {
    let me = this;
    me.slides.slideTo(this.initial_index);
    this.formService.publication_template_index=this.initial_index;
  }

  ionViewDidEnter() {
  }

  //regalosconteo(){
    //this.modalController.create({
      //component:RegaloModalComponent
    //}).then((modal)=>{
     // modal.present()
    //})
  //}

  conteo(){

    this.lanzamiento = setInterval(() => {
      console.log(`lanzamiento en ${this.numero}`);
      this.numero--
      if (this.numero == 0) {
        clearInterval(this.lanzamiento)
      }
    }, 1200);

    console.log(`time ${this.numero}`);
  }



  async envioRegalo(pub) {
    
    console.log('sub', this.walletService.SubscriptionExpired);
    console.log('hub', this.HubbleExpired);
   
    //if (!this.HubbleExpired){
      this.walletService.SubscriptionExpired = false;
     // this.HubbleExpired = true;
    console.log('public', pub.id);
    console.log('pr', this.pr.id);
    const data = {
      publication_id: pub.id,
      sender_id: this.pr.id,
    };
  
    try {
      const result = await this.request.createRequestPost('regalos', data);
      pub.regalos++;
      // If the server returned success, show a success Swal alert
      console.log('Gift sent successfully');
      Swal.fire({
        title: 'Regalo enviado a publicación con éxito',
        icon: 'success',
        confirmButtonText: 'Entendido',
        customClass: {
          confirmButton: 'swal-button'
        }
      });

      // Set HubbleExpired to false after 3 seconds
    //setTimeout(() => {
 
  this.sendNotificationRegalo(pub);
      // Fetch the updated gift count
      // ...
  
    } catch (error) {
      console.error('Error sending gift:', error);
      //if (error.message === 'Sender currency not found.') {
        Swal.fire({
          title: 'Error al enviar el regalo',
          text: 'Revisa tu moneda configurada para envió de regalos',
          icon: 'error',
          confirmButtonText: 'Entendido',
          customClass: {
            confirmButton: 'swal-button',
          },
        }).then(() => {
          // Open the ConfiguracionComponent after the user clicks "OK" in the Swal alert
          this.formService.sectionVer="regalos"  
        this.modalController.create({
          component:PublicacionesColeccionComponent,
          cssClass: 'detailsInModal',
          swipeToClose: true,
          componentProps: {
            profile_id: this.profile_id,
          }
        }).then((modal) => {
          modal.present();
        }).catch((error) => {
          console.error(error);
        });
        });
     // } else {
        // Handle other errors here
      //}
    }
 // }

  }
  sendNotificationRegalo(pub) {
   
		return new Promise((resolve, reject) => {
		  const notification = {
			title: 'Nuevo regalo recibido',
			body: `¡Has recibido un regalo de @${this.pr.username} en tu publicación!`,
			user_id: pub.userProfile.user_id,
	
		  };
	  
		  this.request.createRequestPost('GreatAtractorNotifications', notification)
			.then((data) => {
			  resolve(data);
			}).catch((error) => {
			  console.error('Failed to send notification:', error);
			  reject(error);
			});
		});
	  }
  
  
  
  
  
  
  private openKYCApplicationModal(pub) {
		console.log('openKYCApplicationModal called');
		this.walletService.KYC_validation().then((result_kyc) => {
			console.log('KYC_validation result:', result_kyc);
		  if (result_kyc['permit']) {
        if (Capacitor.getPlatform() !== 'web') {
        if (this.walletService.SubscriptionExpired !== true){
			this.envioRegalo(pub);
    }else if (this.walletService.SubscriptionExpired === true){
      this.RenewSuscription(pub);
    }
  }else{
    this.envioRegalo(pub);
  }

		  } else if (this.pr.kyc === "Sin Validar") {
			this.showSliderInformationModal();
		  } else{
			this.showPagoVerificacionModal();
		  }
		}).catch((error) => {
			console.error('KYC_validation error:', error);
		  console.error(error);
		});
	  }
    private showSliderInformationModal() {
      console.log('showSliderInformationModal called');
      this.modalController.create({
        component: SliderInformationComponent,
        cssClass: 'detailsInModal',
        swipeToClose: true,
        componentProps: {
        pr: this.pr,
        },
      }).then((modal) => {
        modal.present();
      }).catch((error) => {
        console.error(error);
      });
      }
      private showPagoVerificacionModal() {
        console.log('showPagoVerificacionModal called');
        console.log("entro al else if ");
        
        this.modalController.create({
          component: PagoVerificacionComponent,
          cssClass: 'detailsInModal',
          swipeToClose: true,
          componentProps: {
          pr: this.pr
          }
        }).then((modal) => {
          modal.present();
        });
        }


  async presentToast(pub) {
    const audioFile = 'assets/sounds/send_sound.wav';
		const sound = new Howl({
		  src: [audioFile],
		  autoplay: true,
		  volume: 1.0, // Initial volume level (0.0 to 1.0)
		});
		sound.play();
    if (Capacitor.getPlatform() !== 'web') {//ios
      if(this.formService.face_id_active){
        if(this.formService.face_id_active){
          this.modalController.create({
            component: SeguridadComponent,
            cssClass: 'detailsInModal',
            swipeToClose: true,
            componentProps: {
              face_id: true,
            }
            }).then((modal) => {
            modal.onDidDismiss().then((data) => {
              console.log('Modal dismissed:', data);
              if (data.data.role == 'success' ) {
              console.log('Output data:', data.role);
              // Handle the output data from the modal here
               // Call envioRegalo() here
               this.openKYCApplicationModal(pub);
              }
            });
            modal.present();
             // Listen to the output when the modal is dismissed
          
            }).catch((error) => {
            console.error(error);
            });
                              
          }else{
            this.enterPIN_to_enter_Hubble(pub);
          }
      }else{
        this.ionViewillEnter(pub);
      }
   
    }else{
      if(this.formService.face_id_active){
        this.modalController.create({
          component: SeguridadComponent,
          cssClass: 'detailsInModal',
          swipeToClose: true,
          componentProps: {
            face_id: true,
          }
          }).then((modal) => {
          modal.onDidDismiss().then((data) => {
            console.log('Modal dismissed:', data);
            if (data.data.role == 'success' ) {
            console.log('Output data:', data.role);
            // Handle the output data from the modal here
             // Call envioRegalo() here
             this.openKYCApplicationModal(pub);
            }
          });
          modal.present();
           // Listen to the output when the modal is dismissed
        
          }).catch((error) => {
          console.error(error);
          });
      }else{
        this.enterPIN_to_enter_Hubble(pub);
      }
     
    }

  }
  async enterPIN_to_enter_Hubble(pub){
    try {
      const { value: pin } = await Swal.fire({
        title: 'Facial ID desactivado. Ingresa PIN',
        text: `Por favor ingresa tu PIN alfanumรฉrico. Este PIN fue enviado a tu correo, si no tienes ninguno, puedes solicitarlo en Mรกs/Configuraciรณn/Seguridad/PIN de Hubble.`,
        icon: 'info',
        confirmButtonText: 'Validar',
        color: '#ffffff',
        background: '#010f4e',
        input: 'password',  // Change input type to 'password' to hide input content
        inputPlaceholder: 'Ingresa tu PIN',
        inputAttributes: {
        'aria-label': 'PIN'
        },
        showCancelButton: true,
        inputValidator: (value) => {
        if (!value) {
          return 'Debes ingresar un PIN!';
        }
        }
      });
    
      if (pin) {
        Swal.fire({
          title: 'Procesando...',
          text: 'Hubble estรก procesando.',
          icon: 'success',
          timer: 3900, // Show the alert for 3.9 seconds
          timerProgressBar: true,
          toast: true,
          position: 'top-end',
          showConfirmButton: false
          });
        const PIN = pin;  // Store the input value in a constant called PIN
        console.log("Entered PIN:", PIN);
        this.request.createRequestPost('enterToWalletWithPIN', { token: PIN }).then((response) => {
          console.log("response:", response);
        if (response.title =='El email ha sido confirmado!'){
          Swal.fire({
            title: 'Procesando...',
            text: 'Hubble estรก procesando.',
            icon: 'success',
            timer: 3900, // Show the alert for 3.9 seconds
            timerProgressBar: true,
            toast: true,
            position: 'top-end',
            showConfirmButton: false
            });
            this.openKYCApplicationModal(pub);
        }else{
          Swal.fire({
            title: 'Procesando...',
            text: response.title,
            icon: 'error',
            timer: 3900, // Show the alert for 3.9 seconds
            timerProgressBar: true,
            toast: true,
            position: 'top-end',
            showConfirmButton: false
            });
        }
      }).catch((error) => {
        console.error('Failed to send request:', error);
      });
      }
    } catch (error) {
      console.error("Error displaying alert:", error);
      }
  }

  cancelarRegalo(){
    const audioFile = 'assets/sounds/send_sound.wav';
		const sound = new Howl({
		  src: [audioFile],
		  autoplay: true,
		  volume: 1.0, // Initial volume level (0.0 to 1.0)
		});
		sound.play();
     if(this.lanzamiento){
      clearInterval(this.lanzamiento)
      console.log("Cancelo Envio de Regalo");
      setTimeout(() => {
        this.mostrar=false
        this.numero=10

      }, 3000);

     }
  }



  async fetchPublications(user_id?: number){

    const result = await this.actualizacionservice.getPublications(null, false, (user_id) ? user_id: undefined, null, null);
    console.log("result en publication media template",result);
    return result;
  }

  validate_is_last_page(){
    let me = this;

    me.slides.getActiveIndex().then((slidesIndex) => {

      console.log('index chnaged slides', slidesIndex);
      this.formService.lkpublication.index_close = slidesIndex;
    });
    me.slides.isEnd().then(async (istrue) => {
      if (istrue) {
        me.NextSlide = 'Finish';

       
        await this.new_page();
      } else {

        me.NextSlide = 'Next';

      }
    });
  }
  async handleSlideChange() {
    this.formService.actual_publication=null;
    this.fetchcounters();
    this.formService.changeMediaPeriodically=true;
    const currentIndex = await this.slides.getActiveIndex();
    this.formService.publication_template_index=currentIndex;
    // Check if the current slide is the last slide
    if (currentIndex === (await this.slides.length() - 1)) {
      // Fetch more publications when reaching the last slide
     
      setTimeout(() => {
        this.new_page();
      }, 999);
    }
  }
  ionSlideWillChange(){
    // Query all video and audio elements
this.formService.muteAllMedia();
  }

  async new_page() {
    try {
      this.showToast();
    this.actualizacionservice.current_page++;
    let publications_from_server;
    if (this.valueSelect == 'Seguidores') {
      //publications_from_server= await this.fetchPublications();
      publications_from_server = await this.actualizacionservice.getAIPublications(null, false, null, false, 'Parati');
    }else if(this.valueSelect == 'profile'){
      //publications_from_server= await this.fetchPublications(this.profile_id);
      publications_from_server=await this.actualizacionservice.getProfilePublications(null, false, this.profile_id, false, null, this.actual_publication_section) ;
    }else if(this.valueSelect === 'Para todos') {
      //publications_from_server= await this.fetchPublications();
      publications_from_server = await this.actualizacionservice.getAIPublications(null, false, null, false, 'paraTodos');
  }else if(this.formService.valueSelect === 'ColeccionRegalos') {
    //publications_from_server= await this.fetchPublications();
    publications_from_server = await this.actualizacionservice.getPublicationsGifted(null, false, null, false, null);
}else if(this.formService.valueSelect === 'ColeccionCompras') {
  //publications_from_server= await this.fetchPublications();
  publications_from_server =  await this.actualizacionservice.getPublicationsPaid(null, false, null, false, null);
}else if(this.formService.valueSelect === 'Guardado') {
  //publications_from_server= await this.fetchPublications();
  publications_from_server =  await this.actualizacionservice.getPublicationsSave(null, false, null, false, null);
}else if(this.formService.valueSelect === 'MENU') {
  //publications_from_server= await this.fetchPublications();
  publications_from_server =  await this.actualizacionservice.getAIPublications(null, false, null, false, null);
}else if (this.formService.valueSelect==='earth'){
  publications_from_server = await this.actualizacionservice.getAIPublications(null, false, null, false, 'earth');
}else if (this.formService.valueSelect==='epoch'){
  publications_from_server = await this.actualizacionservice.getAIPublications(null, false, null, false, 'epoch');
}else if (this.formService.valueSelect==='eclipse'){
  publications_from_server = await this.actualizacionservice.getAIPublications(null, false, null, false, 'eclipse');
}else if (this.formService.valueSelect==='land'){
  publications_from_server = await this.actualizacionservice.getAIPublications(null, false, null, false, 'land');
}else if(this.formService.valueSelect === 'lkfilm') {
  //publications_from_server= await this.fetchPublications();
  publications_from_server = await this.actualizacionservice.getAIPublications(null, false, null, false, 'LKFE');
}else if(this.formService.valueSelect === 'playlist') {
  //publications_from_server= await this.fetchPublications();
  publications_from_server = await this.actualizacionservice.getPlaylistPublications(null, false, null, false, this.formService.actual_playlist);
}

    console.log('publications from server', publications_from_server);
    if (!publications_from_server || publications_from_server.length === 0 ) {
      setTimeout(() => {
      Swal2.fire({
        title: 'La velocidad del movimiento de tu dedo excede el límite',
        icon: 'warning',
        toast: true,
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
    }, 999);
      return;
    }
    publications_from_server.forEach((publication) => {
      if ('quienpuedever' in publication) {
        if (this.valueSelect == 'Para todos') {
          if (publication['quienpuedever'].includes('Para todos')) {
           this.publications.push(publication);
          }
        } else if (this.valueSelect == 'Seguidores') {
         if (publication['quienpuedever'].includes('Seguidores')) {
           this.publications.push(publication);
         }
       } else {
         this.publications.push(publication);
        }
      }
    });
    //const UnchunkedPublications = this.RedundancyNewPublications(publications_from_server);
     // this.publications.push(...UnchunkedPublications);
    } catch (error) {
      console.error('Error fetching publications:', error);
    }
  }

  RedundancyNewPublications(publications) {
    const chunkSize = 1; // Define your desired chunk size here
    const chunkedArray = [];
    
    for (let i = 0; i < publications.length; i += chunkSize) {
      const chunk = publications.slice(i, i + chunkSize);
      chunkedArray.push(chunk);
    }
    
    return chunkedArray;
  }
  async showToast() {
  /*  const toast = await this.toastController.create({
      message: `
        <div class="toast-content" style="background-color:black; border-radius:9px; border: solid #010f4e;">
        <img src="https://laniakea-multimedia.s3.amazonaws.com/loading_chunk.gif" alt="Image" style="width: 48px; height: 48px; border-radius:9px; border: solid white">
        
        </div>
      `,
      duration: 6399,
      position: 'bottom',
      cssClass: 'custom-toast detailsInModal',
     /*
      buttons: [
        {
          text: '...',
          role: 'cancel'
        }
      ]

    });
  
    toast.present();*/
    Swal.fire({
      title: 'Fetching posts',
      text: ``,
      icon: 'info',
      timer: 6900, // Show the alert for 6.9 seconds
      timerProgressBar: true,
      toast: true,
      position: 'bottom-start',
    //  showCancelButton: true,
    //showConfirmButton:true,
     // cancelButtonText: '<ion-icon name="reload"></ion-icon>No',
      //confirmButtonText: '<ion-icon name="play-skip-forward-sharp"></ion-icon>Si',
      color: 'black',
      background: 'white',
      }).then((result) => {
      });
  }
    
    //let i = 0;
   // for (const pub of publications_from_server) {
     // if (this.valueSelect == 'Seguidores') {
     //   if (pub['quienpuedever'] === 'Seguidores') {
     //    // i++;
     //     this.publications.push(pub);
    //    } 
   //   }else if(this.valueSelect == 'Para todos') {
   //       if (pub['quienpuedever'] === 'Para todos') {
   //         //i++;
   //         this.publications.push(pub);
   //       }
   //     }else{
          //i++;
   //         this.publications.push(pub);
   //     }
   //   }
    
    //  }else if(this.valueSelect == 'profile'){
        //if (pub['owner'] === true) {
      //    i++;
      //    this.publications.push(pub);
        //}
     // }else if(this.valueSelect == 'lkfilm') {
       // if (pub['publication_type'] == 'lkfilm'){
       //   i++;
      //    this.publications.push(pub);
     //   }
     
     
    //no hubo publicaciones de seguidores en la pagina X avanzar a la siguiente
   // console.log('number of publications with seguidores', i);
   // if (i==0 && this.actualizacionservice.current_page < 4 && this.valueSelect !== 'lkfilm'){
      
   //   await this.new_page();
   // }else if(i==0 && this.actualizacionservice.current_page >= 4 && this.valueSelect === 'lkfilm') {
    //  Swal2.fire({
  //      title: 'La velocidad del movimiento de tus dedos, excede el limite, no te desplazes tan rápido',
  //      icon: 'warning',
  //      confirmButtonText: 'Entendido',
  //      color: '#ffffff',
  //      background: '#010f4e',
  //    });
  //    return;
   // }


  public Replace(url: string) {
    return url.split('?')[0]
  }

  sharePublication(pub) {
    this.shareActionSheet(pub);
  }

  editPublication(pub) {

    let title = "";

    switch (pub.getType()) {
      case 'normal': {
        title = "Editar Publicación";
        break;
      }
      case 'event': {
        title = "Editar Evento";
        break;
      }
      default: {
        title = "Editar Publicación";
        break;
      }
    }

    this.publicationService.getPublicationByID(pub).then((publication) => {
      /*
      this.modalController.create({
        component: CreatePublicationComponent,
        swipeToClose: true,
        componentProps: {
          title: title,
          publication: publication,
          publicationToShare: (publication)? publication : undefined,
          disablePost: true,
          isPost: false,
          idPublicationToEdit: this.publication['id']
        }
      }).then((modal) => {
        modal.present();
      }).catch((error) => {
        console.error(error);
      });
      */
    }).catch((error) => {
      console.error(error);
    });

  }

  deletePublication(pub) {
    this.utils.loadingPresent().then(() => {
      this.publicationService.deletePublications(pub['id']).then((data) => {
        this.utils.loadingDismiss().then(() => {
          Swal2.fire({
            title: 'Publicación eliminada con éxito',
            icon: 'success',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
          });
          //this.utils.alertPresent('Exito', 'Publicación eliminada', 'Se ha eliminado la publicación');
          //this.publicationDeleted.emit(true);

        });
      }).catch(error => {
        this.utils.loadingDismiss().then(() => {
          console.error(error);
          if (error.error) {
            error = JSON.parse(error.error);
            if (error.error == 'the publication no longer exist') {
              this.publicationService.DeleteLocalPublicationByID(pub.id);
            }
          }
        });
      });
    });
  }

  deleteAlertConfirm(pub) {

    this.inputAlertController.BinarySelectorAlert(
      'Eliminar publicación',				//Header
      '¿Estás seguro?',					//Message
      'Si, eliminar',						//Ok Button
      'No, conservar'						//Cancel Button
    ).then((result) => {
      if (result['action']) {
        this.deletePublication(pub);
      }
    });
  }

  saveEditPublication(pub) {
    if (pub['is_saved']) {
      this.publicationService.deleteSavePublication(pub['id']).then((data) => {
        //console.error(data);
        pub['is_saved'] = false;
      }).catch((error) => {
        console.error(error);
        if (error.error) {
          error = JSON.parse(error.error);
          if (error.error == 'the publication no longer exist') {
            this.publicationService.DeleteLocalPublicationByID(pub.id);
          }
        }
      });
    } else {
      this.publicationService.postSavePublication(pub['id']).then((data) => {
        pub['is_saved'] = true;
        //console.error(data);
      }).catch((error) => {
        console.error(error);
        if (error.error) {
          error = JSON.parse(error.error);
          if (error.error == 'the publication no longer exist') {
            this.publicationService.DeleteLocalPublicationByID(pub.id);
          }
        }
      });
    }
  }

  shareActionSheet(pub) {
    let buttons = new Array<any>();
    buttons.push({text: 'Compartir', icon: 'share'});

    this.inputAlertController.SelectorActionSheet(buttons, "Selecciona la opción de compartir adecuada").then((result) => {
      if (result['action']) {
        switch (result['button']['text']) {
          case 'Compartir': {
            this.launchPublicationToShare(pub);
            break;
          }
          default:
            break;
        }
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  launchPublicationToShare(pub) {
    let title = "Compartir Publicación";

    this.publicationService.getPublicationByID(pub['id']).then((publicationToShare) => {
      let publication = new PublicationClass();
      publication.publication_type = "Publication";
      /*
      this.modalController.create({
        component: CreatePublicationComponent,
        swipeToClose: true,
        componentProps: {
          title: title,
          publication: publication,
          publicationToShare: publicationToShare,
          disablePost: false,
          isPost: true,
          idPublicationToEdit: undefined
        }
      }).then((modal) => {
        modal.present();
      }).catch((error) => {
        console.error(error);
      });
      */
    }).catch((error) => {
      console.error(error);
    });
  }

  compra_confirmada(pub) {
    this.publicationService.getPublications(pub.id).then((data: any) => {

      pub = data;
    }).catch((error) => {
      console.error(error);
    });
  }

  click_on_comments(pub) {
    this.modalController.create({
      component: CommentsModalComponent,
      cssClass: 'detailsInModal',
			swipeToClose: true,
      componentProps: {
        publication: pub,
        publicationId: pub['id'],
        CommentatorUsername: this.pr.username,
        user_id: pub.userProfile.user_id,
      }
    }).then((modal)=>{
      modal.present();
    });
  }
  click_on_reviews(pub) {
    this.modalController.create({
      component: CommentsModalComponent,
      cssClass: 'detailsInModal',
			swipeToClose: true,
      componentProps: {
        publication: pub,
        publicationId: pub['id'],
        CommentatorUsername: this.pr.username,
        user_id: pub.userProfile.user_id,
        reviews: true,
        Valoraciones: pub.Valoraciones
      }
    }).then((modal)=>{
      modal.present();
    });
  }


  click_on_like(pub) {
    const audioFile = 'assets/sounds/send_sound.wav';
		const sound = new Howl({
		  src: [audioFile],
		  autoplay: true,
		  volume: 1.0, // Initial volume level (0.0 to 1.0)
		});
		sound.play();
    let reaction = { type: "Me gusta" };

    if (( pub.ownReaction)? true: false) {
      //Delete
      if (Capacitor.getPlatform() !== 'web'){
      this.reactionService.deleteReaction(pub['id'], 'publications').then((data: any) => {

        pub['ownReaction'] = undefined;
        pub['reactionCounter']--;

      }).catch((error) => {
        console.error(error);
        error = JSON.parse(error.error);
        if(error.error == "You have no reaction in this publication"){
          pub['ownReaction'] = undefined;
          pub['reactionCounter'] --;
        }
        if(error.error == 'the publication no longer exist'){
          this.publicationService.DeleteLocalPublicationByID(pub.id);
        }
      });
    }else{
      pub['ownReaction'] = undefined;
      pub['reactionCounter'] --;
    }
    }
    else {
      this.reactionService.postReaction(reaction, pub['id'], "publications").then((data: any) => {
        if (Capacitor.getPlatform() !== 'web') {
        data = JSON.parse(data);
        }else{
          if (typeof data === 'string') {
            data = JSON.parse(data);
               console.log('json result publication service.ts', data);
               data = data; 
             }else {
              data= data; 
             }
        }


        let objectReaction = {
          ownReaction: {
            reaction_group_id: data.reaction_group_id,
            reaction_icon: null,
            reaction_id: data.reaction_id,
            reaction_name: data.reaction_apllied
          }
        };

        this.publicationService.getOwnReaction_Publications(objectReaction).then((ownReaction)=>{
          pub.ownReaction = ownReaction;
          pub.reactionCounter++;
          this.sendNotificationLike(pub);
        });
       

      }).catch((error) => {
        console.log(error);
        error = JSON.parse(error.error);
        console.log(error);
        if(error.error == "you can only reaction once per publication"){
          this.publicationService.getPublicationByID(pub.id, true).then((publication)=>{
            pub = publication;
          }).catch((error)=>{
            console.error(error);
          });
        }
        if(error.error == 'the publication no longer exist'){
          pub.DeleteLocalPublicationByID(pub.id);
        }
      });
    }
  }

  sendNotificationLike(pub) {
   
		return new Promise((resolve, reject) => {
		  const notification = {
			title: 'Nuevo me gusta en tu publicación',
			body: `¡Has recibido un me gusta de @${this.pr.username} en tu publicación!`,
			user_id: pub.userProfile.user_id,
	
		  };
	  
		  this.request.createRequestPost('GreatAtractorNotifications', notification)
			.then((data) => {
			
			  resolve(data);
			}).catch((error) => {
			  console.error('Failed to send notification:', error);
			  reject(error);
			});
		});
	  }
  click_on_save(pub) {
    const audioFile = 'assets/sounds/send_sound.wav';
		const sound = new Howl({
		  src: [audioFile],
		  autoplay: true,
		  volume: 1.0, // Initial volume level (0.0 to 1.0)
		});
		sound.play();
    //delete the saved to the db
    if (pub.is_saved === true){
      this.request.createRequestPost('delete_save_publication',{id:pub.id}).then((result)=>{
        Swal2.fire({
          title: 'Publicación eliminada de marcadores',
          icon: 'success',
          confirmButtonText: 'Entendido',
          color: '#ffffff',
          background: '#010f4e',
        });
        pub.is_saved = false;
      }).catch((error) => {

      });
    }else{
      this.request.createRequestPost('save_publication',{idPublication:pub.id}).then((result)=>{
        this.sendNotificationSaved(pub);
        this.AugmentSaveCounter(pub);
        pub.SaveCounter++;
        Swal2.fire({
          title: 'Publicación guardada con exito ',
          icon: 'success',
          confirmButtonText: 'Entendido',
          color: '#ffffff',
          background: '#010f4e',
        });
        pub.is_saved = true;
      }).catch((error) => {

      });
    }

  }
  sendNotificationSaved(pub) {
   
		return new Promise((resolve, reject) => {
		  const notification = {
			title: 'Han guardado tu publicación',
			body: `¡@${this.pr.username} ha guardado tu publicación!`,
			user_id: pub.userProfile.user_id,
	
		  };
	  
		  this.request.createRequestPost('GreatAtractorNotifications', notification)
			.then((data) => {
			
			  resolve(data);
			}).catch((error) => {
			  console.error('Failed to send notification:', error);
			  reject(error);
			});
		});
	  }
  Golkfilm(){
    this.modalController.create({
      component:LkfilmViewBySlidesComponent,
      cssClass: 'detailsInModal',
			swipeToClose: true,

    })

  }

  ionViewillEnter(pub) {
    this.faio.show({
      title: "Leyendo huella Digital",
      subtitle: "Use su huella para permitir el acceso a retiros",
    })
      .then((res) => {
        this.lanzamiento = setInterval(() => {
          this.mostrar = true
          console.log(`lanzamiento en ${this.numero}`);
          this.numero--
      
          if (this.numero === 0) {
            clearInterval(this.lanzamiento);
            console.log("Se terminó el tiempo con Exito!!");
      
            setTimeout(() => {
              this.mostrar = false;
              this.numero = 10;
            }, 4000);
            // Call envioRegalo() here
            this.openKYCApplicationModal(pub);
          }
        }, 1200);
        console.log(res);
        this.msg = 'Se valido el acceso con tu huella';
    
      })
      .catch((err) => {
        console.log(err);
        this.msg = 'Acceso incorrecto';
        alert(err);
      });
  }
  
  
  async RenewSuscription(pub): Promise<void> {
    Swal.fire({
      title: 'Sucripción de usuario...',
      text: 'Tu suscripción de usuario verificado ha expirado. Espera...',
      icon: 'success',
      timer: 4500, // Show the alert for 4.5 seconds
      timerProgressBar: true,
      toast: true,
      position: 'top-end',
      showConfirmButton: false
      });
    return new Promise<void>(async (resolve, reject) => {
      this.store.verbosity = this.store.DEBUG;
    
      // Register the product with the given ID
      this.store.register({
      id: this.productId2,
      alias: this.productId2,
      type: this.store.PAID_SUBSCRIPTION, // Change the type to PAID_SUBSCRIPTION
      });
    
      // Fetch the product information
      const product = await this.store.get(this.productId2);
    
      // Updated
      this.store.when(this.productId2).updated((product: IAPProduct) => {
     
        console.log('Updated' + JSON.stringify(product));
        });
    
      // User closed the native purchase dialog
      this.store.when(this.productId2).cancelled((product) => {
      console.error('Purchase was Cancelled');
      });
    
      // Run some code only when the store is ready to be used
      this.store.ready(() => {
      console.log('Store is ready');
      console.log('Products: ' + JSON.stringify(this.store.products));
      console.log(JSON.stringify(this.store.get(this.productId2)));
      });
    
      if (product) {
      // Check if the product is already owned
      if (product.state === this.store.OWNED) {
        //this.envioRegalo(pub);
        console.log('Product already owned');
        resolve();
        return;
      }
    
      // Check if the product is invalid
      if (product.state === this.store.INVALID) {
        reject(new Error('Product is invalid'));
        return;
      }
    
      // To make a purchase
      this.store.order(this.productId2);
    
      // Proceed with the purchase flow
      this.store.when(this.productId2).approved((product) => {
        product.finish();
    
        console.log('Subscription ordered!');
    
        this.store.when(this.productId2).owned(async (product) => {
          //this.envioRegalo(pub);
        console.log('Product is owned');
        this.sendNotification('Estatus de tu perfil actualizado', '¡Felicitaciones! Ya tienes acceso a la cartera Hubble.');
        
        });
      });
    
      // Handle error events
      this.store.when(this.productId2).error((err) => {
        console.log('Error occurred during purchase:', err);
        reject(err);
      });
    
      // Refresh the product information
      this.store.refresh();
      } else {
      reject(new Error('Failed to fetch product information'));
      }
    
    });
    }
    
    sendNotification(title, body) {
     
    return new Promise((resolve, reject) => {
      const notification = {
      title: title,
      body: body,
      user_id:this.pr.id,
  
      };
    
      this.request.createRequestPost('GreatAtractorNotifications', notification)
      .then((data) => {
       
        resolve(data);
      }).catch((error) => {
        console.error('Failed to send notification:', error);
        reject(error);
      });
    });
    }


    async presentActionSheet(pub){
      Swal.fire({
        title: '',
        text: '',
        icon: 'success',
        timer: 9999, // Show the alert for 45 seconds
        timerProgressBar: true,
        toast: true,
        position: 'top-start',
        showConfirmButton: false
        });
      setTimeout(() => {
        this.ActionSheetController.dismiss();
        }, 9999); 
      const actionSheet = await this.ActionSheetController.create({
        header: 'Acerca de la publicación',
        cssClass: 'title-action',
        buttons: [
        
          {
          text: 'Eliminar publicación',
          icon: 'trash-outline',
          cssClass: 'icon',
          handler: () => {
            if(pub.owner){
              const audioFile = 'assets/sounds/send_sound.wav';
              const sound = new Howl({
                src: [audioFile],
                autoplay: true,
                volume: 1.0, // Initial volume level (0.0 to 1.0)
              });
              sound.play();
              this.deleteAlertConfirm(pub);
            }  else{
              Swal2.fire({
                title: 'Al parecer no es tu publicación',
                text: `Solo puedes eliminar tus publicaciones`,
                icon: 'error',
                confirmButtonText: 'Entendido',
                color: '#ffffff',
                background: '#010f4e',
                }).then(() => {
                console.log("Alert displayed successfully.");
                }).catch(error => {
                console.error("Error displaying alert:", error);
                });
            }
          }
        },
        {
          text: 'Reportar publicación',
          icon: 'flag-sharp',
          cssClass: 'icon',
          handler: () => {
   

            this.presentReportActionSheet(pub);
          }
        },
        {
          text: 'Copiar enlace de publicación',
          icon: 'link-outline',
          cssClass: 'icon',
          handler: () => {
          
this.click_on_share(pub);
this.AugmentSharesCounter(pub);
const audioFile = 'assets/sounds/send_sound.wav';
const sound = new Howl({
  src: [audioFile],
  autoplay: true,
  volume: 1.0, // Initial volume level (0.0 to 1.0)
});
sound.play();

          }
        }, {
          text: 'Compartir con otras aplicaciones',
          icon: 'analytics-outline',
          cssClass: 'icon',
          handler: () => {
           if (Capacitor.getPlatform() !== 'web') {   
            this.Share(pub);
           
            this.AugmentSharesCounter(pub);
            const audioFile = 'assets/sounds/send_sound.wav';
            const sound = new Howl({
              src: [audioFile],
              autoplay: true,
              volume: 1.0, // Initial volume level (0.0 to 1.0)
            });
            sound.play();
           }else{
            Swal2.fire({
              title: 'Funcionalidad no disponible en el navegador',
              text: `Accede a esta funcionalidad en la aplicación de Play Store o App Store`,
              icon: 'info',
              confirmButtonText: 'Entendido',
              color: '#ffffff',
              background: '#010f4e',
              }).then(() => {
              console.log("Alert displayed successfully.");
              }).catch(error => {
              console.error("Error displaying alert:", error);
              });
          }
          }
        }, 
        
        {
          text: 'Proliferar',
          icon: 'git-compare-outline',
          cssClass: 'icon',
          handler: () => {
   this.ProliferarPublicacion(pub);
   console.log(pub.id);
   const audioFile = 'assets/sounds/send_sound.wav';
   const sound = new Howl({
     src: [audioFile],
     autoplay: true,
     volume: 1.0, // Initial volume level (0.0 to 1.0)
   });
   sound.play();
          }
        },
        {
          text: 'Añadir a lista de reproducción.',
          icon: 'library',
          cssClass: 'icon',
          handler: () => {
      
              const audioFile = 'assets/sounds/send_sound.wav';
              const sound = new Howl({
                src: [audioFile],
                autoplay: true,
                volume: 1.0, // Initial volume level (0.0 to 1.0)
              });
              sound.play();
             
           this.open_playlists_popover(pub);
          }
        },
       
      ]
      });
      await actionSheet.present();
  
      const { role } = await actionSheet.onDidDismiss();
      console.log('onDidDismiss resolved with role', role);
    
    }
    async open_playlists_popover(pub){
      const popover = await this.popoverCtrl.create({
        component: PlaylistsComponent, // Replace with your component for observations
        translucent: true,
      //  event: ev,
        componentProps: {
          añadir_a_lista:true,
          pub:pub
  
        },
        backdropDismiss: true, // Allow dismissal by tapping outside the popover
      });
    
      await popover.present();
    }
    ProliferarPublicacion(pub){
      return new Promise((resolve, reject) => {
     
      
        this.request.createRequestPost('ProliferarPublicacion', {id: pub.id})
        .then((data) => {
          Swal2.fire({
            title: 'Publicación actualizada con éxito',
            text: `La publicación ha sido actualizada con éxito, y se han modificado tus publicaciones proliferadas `,
            icon: 'success',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
            }).then(() => {
            console.log("Alert displayed successfully.");
            }).catch(error => {
            console.error("Error displaying alert:", error);
            });
          resolve(data);
        }).catch((error) => {
          Swal2.fire({
            title: 'Hubo un error al proliferar',
            text: `Hubo un error al proliferar la publicación, si el error persiste, favor de counicarse al soporte de Laniakea`,
            icon: 'error',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
            }).then(() => {
            console.log("Alert displayed successfully.");
            }).catch(error => {
            console.error("Error displaying alert:", error);
            });
          console.error('Failed to send notification:', error);
          reject(error);
        });
      });

    }
    async Share(pub){

      const publicationId = pub.id;
      const url ='App web: '+ `https://supercumulo.laniakea.tv/home/go-ecosystem-page?p=${publicationId}`+
      'App ios/android: ' + `laniakea://supercumulo.laniakea.tv/home/go-ecosystem-page?p=${publicationId}`;
      await Share.share({
        url: url,
      });
    }

    AugmentSharesCounter(pub){
      return new Promise((resolve, reject) => {
     
      
        this.request.createRequestPost('AugmentShareCounter', {id: pub.id})
        .then((data) => {
          pub.SharedCounter++;
          resolve(data);
        }).catch((error) => {
          console.error('Failed to send notification:', error);
          reject(error);
        });
      });

    }

    AugmentSaveCounter(pub){
      return new Promise((resolve, reject) => {
     
      
        this.request.createRequestPost('AugmentSaveCounter', {id: pub.id})
        .then((data) => {
          resolve(data);
        }).catch((error) => {
          console.error('Failed to send notification:', error);
          reject(error);
        });
      });

    }
    async fetchProfilePublications(isrefresh: boolean){
      const result = await this.actualizacionservice.getProfilePublications(null, isrefresh, this.profile_id, false, null) ;
      const fetchedPublications = result || [];
  
      
      this.filterPublications(fetchedPublications);
      
    }
    async filterPublications(publications) {
    console.log('filering');
      if (publications.length > 0) {
        this.publications = publications;
      
      }
    
      console.log('siguiendo length of pub', this.publications.length);
    
    
    }
    formatCounter(value: number): string {
      if (value >= 1000000000000) {
        return (value / 1000000000000).toFixed(1) + 'T';
      } else if (value >= 1000000000) {
        return (value / 1000000000).toFixed(1) + 'B';
      } else if (value >= 1000000) {
        return (value / 1000000).toFixed(1) + 'M';
      } else if (value >= 1000) {
        return (value / 1000).toFixed(1) + 'K';
      } else {
        return value.toString();
      }
    }
    async presentReportActionSheet(pub){
      Swal.fire({
        title: '',
        text: '',
        icon: 'success',
        timer: 9999, // Show the alert for 45 seconds
        timerProgressBar: true,
        toast: true,
        position: 'top-start',
        showConfirmButton: false
        });
      setTimeout(() => {
        this.ActionSheetController.dismiss();
        }, 9999); 
      const actionSheet = await this.ActionSheetController.create({
        header: '¿Porqué estás reportando la publicación? Tu reporte es público, solo los usuarios verificados y que han comprado esta publicación, pueden realizar algún reporte.',
        cssClass: 'title-action',
        buttons: [
        
          {
          text: 'Simplemente no me gusta.',
          icon: 'heart-dislike-circle',
          cssClass: 'icon',
          handler: () => {
            const description = `Simplemente no me gusta.

            Esta opción se utiliza cuando simplemente no te gusta y te desagrada la publicación o el contenido, pero no infringe directamente o específicamente ninguna de las políticas de uso de la plataforma. Tu opinión es importante, pero recuerda que el reporte debe ser coherente con las políticas de la comunidad y no se tomarán medidas si no se ha producido una infracción clara de las reglas.
        
            Por favor, utiliza esta opción de manera responsable y reporta solo cuando sea necesario.`;
        
         this.presentReportSelectedActionSheet(pub, description, 'Simplemente no me gusta.');


          }
        },
        {
          text: 'Comportamiento engañoso y spam.',
          icon: 'megaphone',
          cssClass: 'icon',
          handler: () => {
   

            const description= `Comportamiento engañoso y spam.
            
            Queda prohibido lo siguiente:

            A) Interacción falsa.

            1. Ofrecimiento de instrucciones o promoción de métodos o servicios para ayudar a un usuario a aumentar artificialmente la interacción, como la venta de seguidores o me gusta.

            B) Spam.

            1. Cuentas que se operan de forma masiva o mediante automatización no autorizada, como bots, para distribuir altos volúmenes de contenido, entre otras cosas, con fines comerciales.

            2. Redes de cuentas que representan entidades similares o publican contenido similar para llevar a los usuarios a ubicaciones específicas dentro o fuera de la plataforma, como otras cuentas, sitios web y negocios.
            `;
            this.presentReportSelectedActionSheet(pub, description, 'Comportamiento engañoso y spam.');
           
          }
        },
        {
          text: 'Desnudez o actividad sexual.',
          icon: 'close-circle',
          cssClass: 'icon',
          handler: () => {
            const description= `Desnudez o actividad sexual.
            
            Queda prohibido lo siguiente:

            A) Actividad, provocación y explotación sexual de jóvenes.

            1. Demostración o divulgación de materiales de abuso sexual infantil o actividad sexual juvenil.

            2. Demostración o incitación de desnudez juvenil.

            3. Promoción o normalización de la explotación sexual, el abuso sexual y el fetichismo sexual de jóvenes, incluidos el grooming, la sextorsión y la pedofilia.

            4. Demostración o promoción de la provocación sexual, incluida la invitación de menores de 18 años a participar en un acto sexual, o a salir de la plataforma y compartir imágenes sexualmente explícitas, incluso si el inciciador también es menor de 18 años.

            5. Cosificación o sexualización de  menores de 18 años a través de imágenes o funciones de interacción en la aplicación.

            B) Comportamiento sexualmente provocativo por parte de jóvenes.

            1. Exposición excesiva del cuerpo por parte de los jóvenes.

            2. Actuaciones provocativas de menores de 18 años.

            3. Referencias e insinuaciones directas o indirectas al sexo o las actividades sexuales por parte de los jóvenes.

            C) Actividad, servicios o provocación sexuales de personas adultas.

            1. Ofrecimiento o solicitación de parejas sexuales o participación en un acto sexual.

            2. Contenido sexualmente explícito, incluido contenido pornográfico que muestre relaciones sexuales, la masturbación y descripciones vívidas de actos sexuales.

            E) Personas adultas desnudas.

            1. Imágenes de personas adultas desnudas, incluidas las fotografías y las imágenes creadas digitalmente (como manga y anime).

            F) Lenguaje sexualmente explícito.

            1. Relatos sexualmente explícitos, como descripciones vívidas de actos sexuales realizados por adultos o menores de 18 años.
            `;
            this.presentReportSelectedActionSheet(pub, description, 'Desnudez o actividad sexual.');

          }
        }, {
          text: 'Discurso o símbolos de odio.',
          icon: 'thumbs-down',
          cssClass: 'icon',
          handler: () => {
            const description= `Discurso o símbolos de odio.
            
            Queda prohibido lo siguiente:
            
            1. Demostración o promoción de la violencia, la discriminación u otro tipo de daño, incluida la reivindicación de la supremacía sobre la base de características personales, como la raza, la religión, el género o la orientación sexual.
            
            2. Denigración de una persona basándose en estas características personales, incluido el uso de insultos de odio.
            
            3. Negación de eventos históricos bien documentados que causaron daño a grupos protegidos, como el Holocausto.
            
            4. Promoción o apoyo de entidades individuos u organizaciones que fomentan ideologías de odio.`;
            this.presentReportSelectedActionSheet(pub, description, 'Discurso o símbolos de odio.');
          }
        }, 
        
        {
          text: 'Intimidación o acoso.',
          icon: 'sad',
          cssClass: 'thumbs-down',
          handler: () => {
            const description= `Intimidación o acoso.

            Queda prohibido lo siguiente:
            
            1. Demostración o promoción del uso de insultos hacia otras personas o amenazas de insultar a alguien, incluido el uso de palabras malsonantes o lenguaje obsceno para denigrar a la gente.

            2. Demostración, incitación o amenazas de recurrir al acoso o la intimidación, física o de otro tipo, de otras personas, incluido el acoso coordinado.
            
            3. Demostración, promoción o amenazas de comportamiento como doxing, chantaje, revelación de información privada o confidencial o solicitudes de revelarla.`;
            this.presentReportSelectedActionSheet(pub, description, 'Intimidación o acoso.');

          }
        },
        {
          text: 'Información falsa.',
          icon: 'newspaper',
          cssClass: 'icon',
          handler: () => {
            const description= `Información falsa.
            
            Queda prohibido lo siguiente:
            
            A) Información errónea sobre elecciones.
            
            1. Desinformación sobre el procedimiento para votar o la postulación para un cargo.
            
            2. Desinformación sobre los resultados finales de elecciones.
            
            B) Desinformación prejudicial.
            
            1. Desinformación que representa un riesgo para la seguridad pública o que puede causar pánico, como el uso de imágenes de un evento pasado para presentar falsamente un aconcetimiento actual, o la difusión de afirmaaciones inexactas de la falta de disponibilidad de bienes de primera necesidad como alimentos o agua.
            
            2. Información médica falsa que representa un riesgo para la salud pública, como declaraciones engañosas sobre vacunas o consejos médicos incorrectos que desalientan a las personas a obtener la atención médica adecuada.
            
            3. Desinformación sobre el cambio climático que contradice un consenso científico bien establecido, como la negación de la existencia del cmabio climático.
            
            4. Teorías de conspiración peligrosas que promueven la violencia, el odio o atacan a personas, como las que crean prejuicios sobre un grupo específico y causan daño.
            
            C) Deepfakes, imágenes sintéticas o manipuladas.
            
            1. Imágenes sintéticas o manipuladas que muestran escenas realistas y que no se divulgan o etiquetan como tales en el video.
            
            2. Imágenes sintéticas que guardan un parecido (visual o sonoro) con una persona real cuando se usan para patrocinios políticos o comerciales o que infringen las normas de la comunidad.
            
            3. Material que se ha editado se una manera que pueda inducir a error a una persona sobre eventos del mundo real.`;
            this.presentReportSelectedActionSheet(pub, description, 'Información falsa.');

          }
        },
        {
          text: 'Violiencia, abuso o explotación criminal.',
          icon: 'nuclear',
          cssClass: 'icon',
          handler: () => {
            const description = `Violiencia, abuso o explotación criminal.
            
            Queda prohibido lo siguiente:

            A) Explotación y abuso de menores de 18 años.

            1. Demostración o incitación de la explotación sexual de personas menores de 18 años, incluido el uso de material de abuso sexual infantil, grooming, provocación y pedofilia.
            
            2. Demostración o incitación de abuso físico, abandono y maltrato psicológico de personas menores de 18 años.
            
            3. Demostración o promoción de la trata de personas menores de 18 años y el reclutamiento de soldados menores de edad.
            
            4. Promoción o facilitación del matrimonio entre menores.
            
            B) Violencia física y amenazas violentas: 
            
             1. Demostración, promoción o amenazas con violencia física, incluida la tortura en el mundo real, violencia explícita y lucha física extrema.

             2. Promoción y apoyo material a organizaciones violentas o que promueven el odio, incluidos los extremistas violentos y las organizaciones criminales.
             
            C) Explotación y abuso sexual.
            
            1. Demostración o promoción de actos sexuales no consentidos, sean reales o ficticios, incluidos el acoso y la violación.
            
            2. Demostración o promoción de la divulgación no consentida de contenido íntimo o amenazas para compartir dicho contenido.
            
            3. Edicción de contenido con el fin de sexualizar a alguien o crear la apariencia de que una persona está involucrada en actividad sexual.
            
            4.. Declaraciones no deseadas o humillantes, como declaraciones sobre las partes privadas del cuerpo, la actividad sexual o la vida sexual privada de alguien.
            
            D) Maltrato animal.
            
            1. Demostración o incitación de abuso físico, maltrato o descuido de los animales.
            
            2. Demostración o incitación de actividades sexuales entre un animal y un ser humano (zoofilia).
            
            E) Otras actividades delictivas. Demostración, promoción u ofrecimiento de instrucciones sobre como cometer robos o destruir propiedades, u otras actividades delictivas que podrían hacer daño a personas, animales o el medio ambiente.`;
            
            this.presentReportSelectedActionSheet(pub, description, 'Violiencia, abuso o explotación criminal.');

          }
        },
        {
          text: 'Estafa o fraude.',
          icon: 'warning',
          cssClass: 'icon',
          handler: () => {
            const description= `Estafa o fraude.
            
            Queda prohibido lo siguiente:
            
            1. Estafas financieras, de inversión, laborales o phishing, incluido el robo de identidades.

            2. Coordinación o facilitación de estafas, o instrucciones sobre como realizar estafas.
            
            3. Fraude organizacional, como lavado de dinero o movimiento de dinero obtenido de forma ilegal para otra persona ('Money muling').
            
            4. Reclutamiento para marketing multinivel
            
            5. Esquemas piramidales o ponzi.`;
            this.presentReportSelectedActionSheet(pub, description, 'Estafa o fraude.');

          }
        },
        {
          text: 'Falsificaciones y propiedad intelectual.',
          icon: 'document-text',
          cssClass: 'icon',
          handler: () => {
            const description= `Falsificaciones y propiedad intelectual.
            
            Queda prohibido lo siguiente:
            
            A) Productos falsificados.
            
            1. Promoción del comercio de productos falsificados, como artículos de lujo.`;
            this.presentReportSelectedActionSheet(pub, description, 'Falsificaciones y propiedad intelectual.');

          }
        },
        {
          text: 'Suicidio o autolesión.',
          icon: 'skull',
          cssClass: 'icon',
          handler: () => {
            const description= `Suicidio o autolesión.

            Queda prohibido lo siguiente:

            1. Demostración, promoción u ofrecimiento de instrucciones sobre el suicidio, la autolesión y juegos, desafíos, retos, bromas y pactos relacionados.

            2. Divulgación de planes de suicidio o autolesión.`;
            this.presentReportSelectedActionSheet(pub, description, 'Suicidio o autolesión.');

          }
        },
        {
          text: 'Desorden alimenticio e imagen corporal poco saludable.',
          icon: 'beer',
          cssClass: 'icon',
          handler: () => {
            const description= `Desorden alimenticio e imagen corporal poco saludable.
            
            Queda prohibido lo siguiente:
            
            1. Demostración o fomento de trastornos alimentarios, como dietas extremas, ayuno, atracones o vómitos intencionales, así como otros comportamientos peligrosos para perder peso, incluido el ejercicio compulsivo y el uso de medicamentos y suplementos potencialmente dañinos.
            
            2. Demostración o promoción de tendencias inapropiadas relacionadas con la toma de medidas corporales.`;
            this.presentReportSelectedActionSheet(pub, description, 'Desorden alimenticio e imagen corporal poco saludable.');

          }
        },
        {
          text: 'Contenido impactante o explícito.',
          icon: 'videocam-off-outline',
          cssClass: 'icon',
          handler: () => {
            const description= `
            Contenido impactante o explícito.
            
            Queda prohibido lo siguiente:

            1. Muertes y accidentes violentos.

            2. Partes del cuerpo humano o animal desmembradas, mutiladas, carbonizadas, quemadas o gravemente heridas.
            `;
            this.presentReportSelectedActionSheet(pub, description, 'Contenido impactante o explícito.');

          }
        },
        {
          text: 'Actividades y mercancias reguladas.',
          icon: 'ribbon-outline',
          cssClass: 'icon',
          handler: () => {
            const description= `
            Actividades y mercancias reguladas.
            
            Queda prohibido lo siguiente:

            A) Juegos de azar.

            1. Servicios de juegos de azar, como casinos, póquer, juegos de tragamonedas, ruleta, lotería, consejos sobre apuestas y software y aplicaciones relacionadas con juegos de azar.

            B) Alocohol, tabaco y drogas.

            1. Demostración de jóvenes que poseen, consumen o intercambian bebidas alcohólicas, productos de tabaco, drogas u otras sustancias reguladas.

            2. Demostración o incitación al consumo de drogas u otras sustancias reguladas con fines recreativos por parte de personas adultas.

            3. Demostración o promoción del uso indebido de artículos de domésticos comúnes o productos de venta libre para intoxicarse, como antihistamínicos, o la inhalación de pegamento.

            4. Ofrecimiento de isntrucciones sobre cómo fabricar bebidad alcohólicas, drogas u otras sustancias reguladas caseras.

            5. Fomento del comercio o la compra de alcohol, productos de tabaco, drogas u otras sustancias reguladas.

            C) Armas de fuego y otras armas peligrosas.

            1. Demostración o promoción de armas de fuego o explosivos que no se usan en un entorno seguro o apropiado.

            2. Fomento del comercio u ofrecimiento de instrucciones sobre como fabricar armas de fuego o explosivos.

            D) Comercio de otros bienes o servicios regulados.

            1. Comercio de dinero y documentos falsos e información robada.

            2. Comercio de animales salvajes y partes del cuerpo de animales en peligro de extinción, como productos y medicamentos elaborados con marfil.
            `;
            this.presentReportSelectedActionSheet(pub, description, 'Actividades y mercancias reguladas.');

          }
        },
        {
          text: 'Divulgación de datos personales.',
          icon: 'id-card-outline',
          cssClass: 'icon',
          handler: () => {
            const description= `Divulgación de datos personales.
            
            Queda prohibido lo siguiente:
            
            1. Divulgación de números de teléfonos personales y domicilios particulares.
            
            2. Divulgación de información financiera y de pago, como cuentas bancarias y números de tarjetas de débito o crédito.
            
            3. Divulgación de datos de acceso, como nombres de usuario y contraseñas.
            
            4. Divulgación de documentos o números de identidad, como pasaportes y números de seguridad social.`;
            this.presentReportSelectedActionSheet(pub, description, 'Divulgación de datos personales.');

          }
        },
        {
          text: 'Actividades y retos peligrosos.',
          icon: 'alert-circle-outline',
          cssClass: 'icon',
          handler: () => {
            const description= `Actividades y retos peligrosos.
            
            Queda prohibido lo siguiente:

            1. Demostración o promoción de actividades, juegos, desafíos, maniobras o retos peligrosos que causan o podrían causar daños físicos importantes o daños a la propiedad, como el uso inadecuado de herramientas peligrosas, el consumo de sustancias peligrosas o un estilo de conducción peligroso.
            `;
            this.presentReportSelectedActionSheet(pub, description, 'Actividades y retos peligrosos.');

          }
        },
        {
          text: 'Otro.',
          icon: 'arrow-forward-circle-outline',
          cssClass: 'icon',
          handler: () => {
            const description= `Nuestra prioridad es ofrecer un entorno seguro y solidario. También fomentamos interacciones auténticas manteniendo el contenido y las cuentas engañosas al margen de nuestra plataforma. Selecciona esta opcion no se encuntra entre las categorías enumeradas.`;
            this.presentReportSelectedActionSheet(pub, description, 'Otro.');

          }
        },
        

      ]
      });
      await actionSheet.present();
  
      const { role } = await actionSheet.onDidDismiss();
      console.log('onDidDismiss resolved with role', role);
    
    }

    async presentReportSelectedActionSheet(pub, description, title) {
      Swal2.fire({
        title: `Reportar`,
        text: `${description}`,
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        color: '#ffffff',
        background: '#010f4e',
      }).then((result) => {
        if (result.isConfirmed) {
          // Your logic here
          this.postReview(pub, title);
        }
      });
    }
    public postReview(pub, title) {
      title= 'Reporte de: ' +title;
     
      this.newComment = new CommentClass(pub.id);
      this.newComment.setText(title);
      let temporalComment =  this.newComment;
      this.newComment = new CommentClass(temporalComment.publication_id);
      this.utils.loadingPresent().then(() => {
        this.commentService.postReview(temporalComment.getDataToSend(), pub.id).then((data: any) => {
          this.publicationService.DeleteLocalPublicationByID(pub.id);
          if (Capacitor.getPlatform() !== 'web') {
          data = JSON.parse(data);
          }
          Swal2.fire({
            title: 'El reporte fue enviado exitosamente',
            text: 'La denuncia se ha sido publicado exitosamente. ',
            icon: 'success',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
            });
          //console.log(data);
          let commentId = data.comment_id;
          console.log('blobs multimedia', this.commentService.blobsMultimediaComment);

            this.utils.loadingDismiss().finally(() => {
          
                this.commentService.updateReviewList(data.comment, pub);
              
              
              this.commentService.blobsMultimediaComment = [];
              this.newComment.deleteMultimedia(0);
            });
      
          
        }).catch((error) => {
          
          Swal2.fire({
            title: 'El reporte no fue enviado exitosamente',
            text: 'Asegúrate de ser un usario verificado y haber comprado el producto o sevicio de la publicación. Si el error persiste, comunícate con el soporte de Laniakea',
            icon: 'error',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
            });
          console.error(error);
          
          this.utils.loadingDismiss();
          if(error.error){
            error = JSON.parse(error.error);
            if (error.error == 'the publication no longer exist') {
           
              this.publicationService.DeleteLocalPublicationByID(pub.id);
            }
          }
        }).finally(() => {
          //this.newComment = new CommentClass(this.publicationId);
        });
      });
  
    }
}
