import { Component, ElementRef, Inject, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Geolocation } from '@capacitor/geolocation';
import { GooglemapsService } from './../googlemaps/googlemaps.service';
import { DOCUMENT } from '@angular/common';
import { ModalController } from '@ionic/angular';
import { EventEmitter } from 'stream';
import { PublicationClass } from 'src/app/Models/Classes/Publications/publication-class';
import { PublicationService } from 'src/app/services/publications/publication.service';
declare var google;
@Component({
  selector: 'app-googlemapsview-publication',
  templateUrl: './googlemapsview-publication.component.html',
  styleUrls: ['./googlemapsview-publication.component.scss'],
})
export class GooglemapsviewPublicationComponent implements OnInit {
      @Input() publication: PublicationClass;

    
 // coordenadas cuenca
 @Input() position = {  
  lat: 37.421998333333335,
  lng: -122.084
};
label = {
  titulo:'Ubicación',
  subtitulo: 'Mi ubicación de envío'
} 

map: any;
marker: any;
infowindow: any;
positionSet: any

 @ViewChild('map') divMap: ElementRef;



  constructor(private renderer: Renderer2 ,
              @Inject(DOCUMENT) private document,
              private googlemapsService: GooglemapsService,
              public modalController: ModalController,
              private publicationService: PublicationService
                                
          
            
              
              ) { }

  ngOnInit(): void {
        this.init();

        console.log('position ->', this.position)
       
       
  }

  async init() {
 

        this.googlemapsService.init(this.renderer, this.document).then( () => {
              this.initMap();
        }).catch( (err) => {    
              console.log(err);
        });
        
  }




  initMap() {

        const position = this.position;

        let latLng = new google.maps.LatLng(position.lat , position.lng);

        let mapOptions = {
              center: latLng,
              zoom: 15,
              disableDefaultUI: true,
              clickableIcons: true,
        };

        this.map = new google.maps.Map(this.divMap.nativeElement, mapOptions);
        this.marker = new google.maps.Marker({
              map: this.map,
              animation: google.maps.Animation.DROP,
              draggable: false,
        });
        this.clickHandleEvent();
        this.infowindow = new google.maps.InfoWindow();
        this.addMarker(position);
        this.setInfoWindow(this.marker, this.label.titulo, this.label.subtitulo);

  }

  clickHandleEvent() {


        this.map.addListener('touchmuve', (event: any) => {
              const position = {
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng(),
              };
              this.addMarker(position);
        });

  }



  addMarker(position: any): void {

        let latLng = new google.maps.LatLng(position.lat, position.lng);

        this.marker.setPosition(latLng);
        this.map.panTo(position);
        this.positionSet = position;

  }


  setInfoWindow(marker: any, titulo: string, subtitulo: string) {

        const contentString  =  '<div id="contentInsideMap">' +
                                '<div>' +
                                '</div>' +
                                '<p style="font-weight: bold; margin-bottom: 5px;">' + titulo + '</p>' +
                                '<div id="bodyContent">' +
                                '<p class"normal m-0">'
                                + subtitulo + '</p>' +
                                '</div>' +
                                '</div>';
        this.infowindow.setContent(contentString);
        this.infowindow.open(this.map, marker);

  }

  async mylocation() {

  

  Geolocation.getCurrentPosition().then((res) => {

        console.log('mylocation() -> get ', res);

        const position = {
              lat: res.coords.latitude,
              lng: res.coords.longitude,
        }
        this.addMarker(position);

  });

  }

  aceptar() {
     

        this.modalController.dismiss({pos: this.positionSet})
        
        this.googlemapsService.Positionsetlat =this.positionSet.lat
        this.googlemapsService.Positionsetlng =this.positionSet.lng
    
      
  }

}