import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RequestService } from 'src/app/services/request/request.service';
import Swal from 'sweetalert2';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-Confirmacion',
  templateUrl: './Confirmacion.html',
  styleUrls: ['./Confirmacion.scss'],
})
export class Confirmacion implements OnInit {
  @Input() email: any;
  @Input() id: number;
  remainingTime: number;
//Confirmación original
public token: string;
  private countdownInterval: any;
  constructor(private formBuilder: FormBuilder, private request: RequestService, private modalcontroller: ModalController,) { }

  ngOnInit() {
    this.startCountdown();
  
  }
  ngOnDestroy() {
    this.stopCountdown();
  }

  startCountdown() {
    this.remainingTime = 60; //299= 4 minutes and 59 seconds

    this.countdownInterval = setInterval(() => {
      this.remainingTime--;

      if (this.remainingTime <= 0) {
        // Stop the countdown when the remaining time reaches zero or below
        this.stopCountdown();
        // Perform any necessary actions when the countdown completes
        // For example, show a message to the user indicating the timeout
      }
    }, 1000);
  }

  stopCountdown() {
    clearInterval(this.countdownInterval);
  }
  formatTime(time: number): string {
    const minutes = Math.floor(time / 60).toString().padStart(2, '0');
    const seconds = (time % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  }
  updateToken(event: any) {
    this.token = event.target.value;
  }
  confirmEmail() {
    Swal.fire({
      title: 'Procesando...',
      text: 'La confirmación se está procesando.',
      icon: 'success',
      timer: 3600, // Show the alert for 3 seconds
      timerProgressBar: true,
      toast: true,
      position: 'top-end',
      showConfirmButton: false
    });
    console.log('tok', this.token);
    console.log('profile_id', this.id);
    if (this.token) {
      
      this.request.createRequestPost(`verified-confirmation`, {token: this.token, profile_id: this.id})
      .then(response => {
        console.log('response', response);
        const responseData = JSON.parse(response);
        console.log('response code', responseData.code);

        if (responseData.code === 200) {
          console.log('Token is correct, proceeding with wallet init');
            // Token is correct, proceed with fund transfer
            let VerificationData = {
              id: this.id,

              // Prepare your verification data here
            };

            this.request.createRequestPost('UsuarioVerificado', VerificationData)
              .then((response) => {
                const responseData = JSON.parse(response);
                if (responseData.code === 200) {
                  // Verification update was successful
                  Swal.fire({
                    title: '¡Ya eres un usuario verificado!',
                    text: `El correo ${this.email}, ha sido verificado exitosamente, por consiguiente tu perfil en tanto que verificado, no tiene limitantes por 30 días a partir de tu suscripción ¡Regocíjate!`,
                    icon: 'success',
                    confirmButtonText: 'Entendido',
                    color: '#ffffff',
                    background: '#010f4e',
                  }).then(() => {
                    console.log("Alert displayed successfully.");
                  }).catch((error) => {
                    console.error("Error displaying alert:", error);
                  });
                  this.dismiss();
                } else {
                  // Verifaction failed
                  // Handle failure here (e.g., show an error message to the user)
                  Swal.fire({
                    title: 'Lo siento la verificación falló ',
                    text: `El correo ${this.email}, no ha sido verificado exitosamente.`,
                    icon: 'error',
                    confirmButtonText: 'Entendido',
                    color: '#ffffff',
                    background: '#010f4e',
                  }).then(() => {
                    console.log("Alert displayed successfully.");
                  }).catch((error) => {
                    console.error("Error displaying alert:", error);
                  });
                }
              })
              .catch((error) => {
                // Handle network errors or other unexpected errors here
              });
          } else {
            // Token is incorrect
            Swal.fire({
              title: 'El token no es válido',
              icon: 'error',
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'btn btn-danger'
              },
              buttonsStyling: false,
              background: '#010f4e',
              iconColor: '#ffffff',
              text: response.title
            });
          }
        })
        .catch(error => {
          Swal.fire({
            title: 'Email Confirmation Failed',
            icon: 'error',
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'btn btn-danger'
            },
            buttonsStyling: false,
            background: '#010f4e',
            iconColor: '#ffffff',
            text: error.response.title
          });
        });
    }
    
  }
  dismiss(){
    this.modalcontroller.dismiss()
  }
}