export class Payment {
    amount: number //"10.00"
    currency: string//"BTC"
    final_amount: number //"12.20"
    method: string //"COINPAYMENT"
    reference: string //"61b7a25306e13"
    status: string //"En Proceso"
    taxes_amount: number //"2.20"
    type: string //"Retiro"
    updated_at: string //"2021-12-13T19:43:15.000000Z"
    details: Array<any>
    constructor(object?) { // Cambiar el signo por una interfaz
        if (object) {
            this.amount = (object.amount)? object.amount : null;
            this.currency = (object.currency) ? object.currency : null;
            this.final_amount = (object.final_amount) ? object.final_amount : null;
            this.method = (object.method)?object.method: null;
            this.reference = (object.reference)?object.reference: null;
            this.status = (object.status) ?object.status : null;
            this.taxes_amount = (object.taxes_amount)?object.taxes_amount: null;
            this.type = (object.type)?object.type: null;
            this.updated_at = (object.updated_at)?object.updated_at:null;
            if(object.details){ // en algun momento agregare una validacion de instanceof array
                this.details = object.details.map((detail)=>{
                    return detail;
                })
            }else{
                this.details = new Array<any>();
            }
        }
    }

    public getTimeSince() {
        let options: Intl.DateTimeFormatOptions = {
            timeZone: 'America/Mexico_City',
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
        }
        return new Date(this.updated_at).toLocaleTimeString("en-US", options);
    }

    public getDateSince() {
        try {
            let date = new Date(this.updated_at);
            return new Intl.DateTimeFormat(['ban', 'id']).format(date);
        } catch (e) {
            console.error(e);
        }
    }
}
