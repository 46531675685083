import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PublicationClass } from 'src/app/Models/Classes/Publications/publication-class';

@Component({
	selector: 'app-url-viewer',
	templateUrl: './url-viewer.component.html',
	styleUrls: ['./url-viewer.component.scss'],
})
export class UrlViewerComponent implements OnInit {

	@Input() publication: PublicationClass;

	constructor(
		private sanitizador: DomSanitizer
	) { }

	ngOnInit() {
		
	}

	public getUrlFromPublication(publication: PublicationClass) {
		//let src = (<any>window).Ionic.WebView.convertFileSrc(path);
		
		//let path = (publication.UrlContainer && publication.UrlContainer.address) ? publication.UrlContainer.address : null;
		//return (path != null) ? this.sanitizador.bypassSecurityTrustResourceUrl(path) : "";
	}

}
