import { Component, OnInit, Input,Output,EventEmitter } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular'
import { GooglemapsviewPublicationComponent } from 'src/app/components/googlemapsview-publication/googlemapsview-publication.component';
import { MediaItem } from 'src/app/Models/Classes/Media/media-item';
import { PublicationClass } from 'src/app/Models/Classes/Publications/publication-class';
import { CommentsModalComponent } from '../../comments/comments-modal/comments-modal.component';
import { PublicacionesColeccionComponent } from '../../create/publicaciones-coleccion/publicaciones-coleccion/publicaciones-coleccion.component';
import { PublicacionesGuardadasComponent } from '../../create/publicaciones-guardadas/publicaciones-guardadas/publicaciones-guardadas.component';
import { PublicacionesResumenComponent } from '../../create/publicaciones-resumen/publicaciones-resumen/publicaciones-resumen.component';


@Component({
  selector: 'app-detalle',
  templateUrl: './detalle.component.html',
  styleUrls: ['./detalle.component.scss'],
})
export class DetalleComponent implements OnInit {
  @Input() profilePhoto: string;
  @Input() userName: string;
  @Input() createdAt: number;
  @Input() owner: boolean = false;
  @Input() user_id: number;
  @Input() discardInteractions: boolean;
  @Input() corp: boolean = false;
  public timeSincePublish: string = "Justo ahora";

  //footer
  @Input() publication: PublicationClass;

  @Output() savePublication = new EventEmitter();
  @Output() sharePublication = new EventEmitter();

  //multimedia
  @Input() multimedia: Array<MediaItem>;
  @Input() index: number = 0;
  constructor(public modalController:ModalController) { }

  ngOnInit() {}

	hideDireccion:boolean = false;
	ngIfCtrlDireccion(){
  	this.hideDireccion = !this.hideDireccion;
	}

  hideServices:boolean = false;
	ngIfCtrlServices(){
  	this.hideServices = !this.hideServices;
	}

  hideReglas:boolean = false;
  nfIfCtrlReglas(){
    this.hideReglas = !this.hideReglas;
  }

  hideCalendario:boolean = false;
  ngIfCtrlCalendario(){
    this.hideCalendario = !this.hideCalendario;
  }

  hideCatalogo: boolean = false;
  ngIfCtrlCatalogo(){
    this.hideCatalogo = !this.hideCatalogo;
  }
  onClick(publication){
    this.modalController.create({
      component: GooglemapsviewPublicationComponent,
			cssClass: 'detailsInModal',
			swipeToClose: true,
      componentProps: {
        publication
       
      }
    }).then((modal) => {
      modal.present();
    });
  }
  hideUbicacion:boolean = false;
	ngIfCtrlUbicacion(){
  	this.hideUbicacion = !this.hideUbicacion;
    
	}

  hideLecciones:boolean = false;
	ngIfCtrlLecciones(){
  	this.hideLecciones = !this.hideLecciones;
	}

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  click_on_comments() {
    this.modalController.create({
      component: CommentsModalComponent,
      cssClass: 'detailsInModal',
			swipeToClose: true,
      componentProps: {
        publication: this.publication,
        publicationId: this.publication['id']
      }
    }).then((modal) => {
      modal.present();
    });
  }

  guardadas() {
    this.modalController.create({
      component: PublicacionesGuardadasComponent,
      cssClass: 'detailsInModal',
			swipeToClose: true,
    }).then((modal) => modal.present());
  }

  resumen() {
    this.modalController.create({
      component: PublicacionesResumenComponent,
      cssClass: 'detailsInModal',
			swipeToClose: true,
    }).then((modal) => modal.present());
  }

  coleccion() {
    this.modalController.create({
      component: PublicacionesColeccionComponent,
      cssClass: 'detailsInModal',
			swipeToClose: true,
    }).then((modal) => modal.present());
  }
  
}
