import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StartingPagePage } from 'src/app/pages/principal/start/starting-page/starting-page.page';

@Component({
  selector: 'app-white-input',
  templateUrl: './white-input.component.html',
  styleUrls: ['./white-input.component.scss'],
})
export class WhiteInputComponent implements OnInit {

  @Input() label: string;
  @Input() contentInput: string;
  @Output() value = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {}

  returnValue(event){
    this.value.emit(event.detail.value);
  }
}
