import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {IonSlides, ModalController} from '@ionic/angular';

import { registerPlugin } from '@capacitor/core';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { PagoVerificacionComponent } from './../pago-verificacion/pago-verificacion.component';
import {CuentaComponent} from "../general-design/cuenta/cuenta.component";
import { ReconoseridServiceService } from './../../services/reconoserid/reconoserid-service.service';
import {MessagesListComponent} from "../chat/messages-list/messages-list.component";
import {ReconoceridComponent} from "./reconocerid/reconocerid.component";
import Swiper from 'swiper';
import  Swal from 'sweetalert2';

//interface GetIDSDKInput {
  //apiUrl: string;
  //token: string;
  //flowName: string;
//}

//interface GetIDPlugin {
  //startVerificationFlow(options: { input: GetIDSDKInput }): Promise<void>;
//}

//const getID = registerPlugin<GetIDPlugin>('GetID');

@Component({
  selector: 'app-slider-information',
  templateUrl: './slider-information.component.html',
  styleUrls: ['./slider-information.component.scss'],
})
export class SliderInformationComponent implements OnInit {
  //@ViewChild('slides', { static: true }) slides: IonSlides;
  @Input() pr: any;
  mostrarButton = false;
  swiper: Swiper;
  urlSlidersInfo = ['','','','','','','','','','',''];
  constructor(
    public modalcontroller: ModalController,
    private profile:ProfileService,
    private recocerIDservice:ReconoseridServiceService) {
  }

  swipechanged() {
    if (this.swiper) {
      const activeSlide = this.swiper.slides[this.swiper.realIndex];
      const activeSlideSrc = activeSlide && activeSlide.getAttribute('src');
      const isLastSlide = activeSlideSrc === 'assets/Img-info/verify-step11.jpeg';
      this.mostrarButton = isLastSlide;
      console.log('activeSlide:', activeSlide);
      console.log('mostrarButton:', this.mostrarButton);
    }
  }
  
  
  
  
  
  
  
  
  

  ngOnInit() {
    this.initializeSwiper();
  }
  initializeSwiper() {
    this.swiper = new Swiper('.swiper-container', {
      // Swiper options here
      // For example, to enable pagination:
      pagination: {
        el: '.swiper-pagination',
      },
      on: {
        slideChange: () => {
          this.swipechanged();
        },
      },
    });
    this.swipechanged();
  }
  get_slider_name(i) {
    return 'assets/Img-info/verify-step'+(i+1)+'.jpeg';
  }

  dismiss() {
    this.modalcontroller.dismiss({
      dismissed: true,
    });
  }

  open_payment_or_reconoserid(){
    if(!this.pr){
      Swal.fire({
			  title: 'Inicia sesión...',
			  text: 'Intenta de nuevo más tarde, debes iniciar sesión para verificar tu perfil.',
			  icon: 'error',
			  timer: 3600, // Show the alert for 2 seconds
			  timerProgressBar: true,
			  toast: true,
			  position: 'top-end',
			  showConfirmButton: false
			});
    }else{
      this.modalcontroller.create({
        component: PagoVerificacionComponent,
        cssClass: 'detailsInModal',
        swipeToClose: true,
        componentProps: {
          pr: this.pr
        }
      }).then((modal)=>{
        modal.present();
      });
    }
   
  }
}

