import { Component, Input, OnInit } from '@angular/core';
import { MediaItem } from 'src/app/Models/Classes/Media/media-item';

@Component({
	selector: 'app-complete-information',
	templateUrl: './complete-information.component.html',
	styleUrls: ['./complete-information.component.scss'],
})
export class CompleteInformationComponent implements OnInit {

	@Input() title: string;
	@Input() description: string;
	@Input() dateTimeStart: number;
	@Input() dateTimeEnd: number;
	@Input() multimedia: Array<MediaItem>;

	public dateTimeStartShow: Date;
	public dateTimeEndShow: Date;

	constructor() { }

	ngOnInit() {
		this.convertDates();
	}

	convertDates() {
		if (this.dateTimeStart != null) {
			this.dateTimeStartShow = new Date(this.dateTimeStart * 1000);
			if (this.dateTimeEnd != null) {
				this.dateTimeEndShow = new Date(this.dateTimeEnd * 1000);
			}
		}
	}

	
}
