import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ReconoceridComponent} from "../reconocerid.component";
import {FormsModule} from "@angular/forms";



@NgModule({
  declarations: [
    ReconoceridComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ]
})
export class ReconoceridModule { }
