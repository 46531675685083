import { Component, Input, OnInit } from '@angular/core';
import { ModalController} from '@ionic/angular';
import { GooglemapsComponent } from 'src/app/components/googlemaps/googlemaps.component';
import { GooglemapsService } from 'src/app/components/googlemaps/googlemaps.service';
import { PublicationClass } from 'src/app/Models/Classes/Publications/publication-class';
import { FormServiceService } from 'src/app/services/publications/form-service.service';


@Component({
  selector: 'app-landservice7',
  templateUrl: './landservice7.component.html',
  styleUrls: ['./landservice7.component.scss'],
})
export class Landservice7Component implements OnInit {
  
  constructor(
    private modalcontroller: ModalController,
    public GooglemapsService:GooglemapsService,
    private formservice: FormServiceService
     ) { }

  ngOnInit() {
    this.GooglemapsService.Positionsetlat
    
    
   }
  // prueva() {
  //   this.modalcontroller.create({
  //     component: GooglemapsComponent
  //   }).then((modal) => {
  //     modal.present();
  //   })
  // }
  onClick(){
        this.formservice.landpublication.latitud=  this.GooglemapsService.Positionsetlat
        this.formservice.landpublication.longitud=  this.GooglemapsService.Positionsetlng
  
  
  }
}
