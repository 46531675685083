import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-inline-bowsing',
  templateUrl: './inline-bowsing.component.html',
  styleUrls: ['./inline-bowsing.component.scss'],
})
export class InlineBowsingComponent implements OnInit {
  unsanitezed_url;
  @Input() url: any;
  constructor(
    private sanitizer: DomSanitizer,
    private ModalController: ModalController
  ) { }

  ngOnInit() {
    this.unsanitezed_url=this.url;
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }
  dismiss(){
    this.ModalController.dismiss();
  }
  getSubdomain(): string {
    let url= this.unsanitezed_url.toString();
    if (typeof url === 'string') {
      // Remove the protocol (http:// or https://) if present
      let urlWithoutProtocol = url.replace(/^https?:\/\//, '');

      // Split the URL by dots
      let parts = urlWithoutProtocol.split('.');

      // If the URL starts with 'www', return the second part (subdomain)
      if (parts.length > 1 && parts[0] === 'www') {
        return parts[1];  // Returns 'documents'
      }
      
      // If there's no 'www', return the first part before the first dot
      return parts[0];  // Returns 'documents' even if 'www' is not present
    }

    return '';
  }
  navigate(){
    window.open(this.unsanitezed_url, '_blank'); // Open the URL in a new tab or window
  }
}
