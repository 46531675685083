import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController } from '@ionic/angular';

@Component({
	selector: 'app-welcome-message',
	templateUrl: './welcome-message.component.html',
	styleUrls: ['./welcome-message.component.scss'],
})
export class WelcomeMessageComponent implements OnInit {
	public typeTextCalled:boolean=false;
	textAlign;
	@ViewChild('slides', { static: true }) slides: IonSlides;
	@Input() username: string = "usuario_01";
	mostrarButton = false;
	urlSlidersInfo = ['','','','',''];
	nextSlideActivationButton:boolean=false;
	constructor(
		private modalController: ModalController
	) { }

	ngOnInit() { 
		this.textAlign='Bienvenido a la app de supercumulo de Laniakea, que te ayudará a crear y monetizar tus publicaciones de una manera sencilla.';
		this.blockSlideChangeUntilTypingComplete(0);

	}
	slideNext(){
		this.slides.slideNext();
	}
	closeModal(){
    localStorage.setItem("welcome_showed","true");
		this.modalController.dismiss();
	}
	slideChanged(sks) {
	
		this.slides.getActiveIndex().then((index) => {
			this.typeTextCalled=false;
			if (index === 1) {
			  this.textAlign =
				'Nuestro objetivo es brindarte una plataforma intuitiva y eficiente para que puedas compartir tus ideas y alcanzar tus metas. Por eso, queremos invitarte a conocer el método de trabajo y aprovechar al máximo las funcionalidades.';
			} else if (index === 2) {
			  this.textAlign =
				'La APP te permite publicar contenido, categorizándolo, además de integrar tus publicaciones con diferentes canales de monetización para obtener los mejores resultados.';
			} else if (index === 3) {
			  this.textAlign =
				'Esperamos que disfrutes de la aplicación y te resulte útil en tu camino. Si tienes alguna duda o sugerencia no dudes en ponerte en contacto con nosotros a través de nuestro soporte en línea.';
			} else {
			  this.textAlign = 'Gracias por elegir a Laniakea. Comencemos a crear juntos.';
			}
		
			this.blockSlideChangeUntilTypingComplete(index);
		  });
		let me = this;
		me.slides.isEnd().then((istrue) => {
		  if (istrue) {
			this.mostrarButton = true;
		  } else {
		  }
		});
	  }

	get_slider_name(i) {
		let index = i+1;
		

		return 'assets/video/SlideB/'+(i+1)+'.png';
	  }
	  async blockSlideChangeUntilTypingComplete(index: number) {
		// Lock slide swipes to prevent changing while typing
		this.slides.lockSwipes(true);
		this.nextSlideActivationButton=false; //this is the next slide button 
	  
		// Type the text and wait for it to complete
		await this.typeText(this.textAlign, 9);
	  
		// Unlock slide swipes after a delay of 3.6 seconds
		setTimeout(() => {
		  this.slides.lockSwipes(false);
		  if(!this.mostrarButton){//this is the close modal button
			this.nextSlideActivationButton=true; //this is the next slide button 
		  }
	 
		  // Slide to the desired index
		  this.slides.slideNext;
		}, 3600); // 3.6 seconds in milliseconds
	  }
	  
	  async typeText(text: string, delay: number) {
		// Check if typeText has already been called for this currentStateIndex
		if (this.typeTextCalled) {
		  return;
		}
		this.typeTextCalled = true; // Set the flag to indicate
		let index = 0;
		this.textAlign = '';
	  
		return new Promise<void>((resolve) => {
		  const interval = setInterval(() => {
			if (index < text.length) {
			  this.textAlign += text.charAt(index);
			  index++;
			} else {
			  clearInterval(interval);
			  this.typeTextCalled = false; // Reset the flag
			  resolve(); // Resolve the promise when typing is complete
			}
		  }, delay);
		});
	  }

	  
}
