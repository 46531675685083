import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ButtonsModule } from 'src/app/components/common-elements/buttons/buttons-module/buttons.module';
import { ReturnModuleModule } from 'src/app/components/common-elements/return/return-module/return-module.module';

import { UrlPublicationComponent } from '../url-publication/url-publication.component';
import { EventPublicationComponent } from '../event-publication/event-publication.component';
import { NormalPublicationComponent } from '../normal-publication/normal-publication.component';
import { PublicationDescriptionModuleModule } from '../../publication-type/publication-description-module/publication-description-module.module';
import { PublicationElementsModuleModule } from '../../single-elements/publication-elements-module/publication-elements-module.module';

import { DetalleComponent } from '../detalle/detalle.component';


@NgModule({
	declarations: [
    EventPublicationComponent,
    NormalPublicationComponent,
    UrlPublicationComponent,
	DetalleComponent
	],
	exports: [
    EventPublicationComponent,
    NormalPublicationComponent,
    UrlPublicationComponent
	,
	DetalleComponent
	],
	entryComponents: [
    EventPublicationComponent,
    NormalPublicationComponent,
    UrlPublicationComponent,
	DetalleComponent
	],
	imports: [
		CommonModule,
		IonicModule,
		ButtonsModule,
		PublicationDescriptionModuleModule,
		PublicationElementsModuleModule
	]
})
export class ShowPublicationModuleModule { }
