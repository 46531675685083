import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PublicationClass } from 'src/app/Models/Classes/Publications/publication-class';
import { StatePublication } from 'src/app/Models/Classes/Publications/Story/StatePublication/state-publication';

@Component({
	selector: 'app-state-publication',
	templateUrl: './state-publication.component.html',
	styleUrls: ['./state-publication.component.scss'],
})
export class StatePublicationComponent implements OnInit {
	public tituloLabel: string = "Titulo";

	public contentInput: string;
	public contentTextArea: string;

	public fabActivated: boolean = false;

	public monto: number = 0;
	@Input() isEditing: boolean = false;

	@Input() publication: StatePublication;
	@Input() publicationToShare: PublicationClass;

	@Input() pricesList = Array<any>();

	@Output() checkIfCanPost = new EventEmitter<string>();
	@Output() deleteMedia = new EventEmitter<any>();

	constructor() { }

	ngOnInit() {
		
		this.contentInput = this.publication.title;
	}

	returnTitleValue(event) {
		this.publication.title = event.detail.value;
		this.checkIfCanPost.emit();
	}

	deleteMediaEmit() {
		this.deleteMedia.emit();
	}

	returnPriceValue(price){}

}
