import { Component, OnInit } from '@angular/core';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import landJson from 'src/assets/plantillas/data-land.json'
import Swal from 'sweetalert2';
import { RequestService } from 'src/app/services/request/request.service';
import {LoadingController} from "@ionic/angular";
@Component({
  selector: 'app-landservice',
  templateUrl: './landservice.component.html',
  styleUrls: ['./landservice.component.scss'],
})
export class LandserviceComponent implements OnInit {
  public subCategoria: boolean= false;
  public loading: any;
  categoriasHistory: Array<any> = [];
  selectedCategoria: string;
  displayContainer:boolean=true;

  public categories: Array<string> = Object.keys(landJson.landdata)
  public Seleccion:string;
  DataPublications = [
    {
      Name: 'Normal',
      selected: true
    },
    {
      Name: 'Servicios Land',
      Categorias: [

        {
          categoria: 'Alimentos y gastronomía',
          selected: false,
          id: 1,
          creator_id: 0,
        },
        {
          categoria: 'Transporte y movilidad',
          selected: false,
          id: 22,
          creator_id: 0

        },
        {
          categoria: 'Asesoría personal',
          selected: false,
          id: 27,
          creator_id: 0
        },
        {
          categoria: 'Publicidad, diseño o lingüísticos',
          selected: false,
          id: 45,
          creator_id: 0,
        },
        {
          categoria: 'Salud y Sanidad',
          selected: false,
          id: 55,
          creator_id: 0,
        },
        {
          categoria: 'Doméstico, reparaciones y mantenimiento',
          selected: false,
          id: 82,
          creator_id: 0,
        },
        {
          categoria: 'Asesoria empresarial, personal y legal',
          selected: false,
          id: 107,
          creator_id: 0

        },
        {
          categoria: 'Producción audiovisual y entretenimiento',
          selected: false,
          id: 120,
          creator_id: 0
        },
        {
          categoria: 'Hotelería y turismo',
          selected: false,
          id: 128,
          creator_id: 0,
        },
        {
          categoria: 'Telecomunicaciones',
          selected: false,
          id: 133,
          creator_id: 0,
        },
        {
          categoria: 'Ingenierías',
          selected: false,
          id: 136,
          creator_id: 0,
        },
        {
          categoria: 'Bienes y raíces',
          selected: false,
          id: 150,
          creator_id: 0

        },
        {
          categoria: 'Servicios básicos',
          selected: false,
          id: 156,
          creator_id: 0
        },
        {
          categoria: 'Agricultura',
          selected: false,
          id: 161,
          creator_id: 0,
        },
        {
          categoria: 'Construcción',
          selected: false,
          id: 170,
          creator_id: 0,
        },
        {
          categoria: 'Comercio',
          selected: false,
          id: 189,
          creator_id: 0,
        },
        {
          categoria: 'Educación',
          selected: false,
          id: 195,
          creator_id: 0

        },
        {
          categoria: 'Industrias químicas',
          selected: false,
          id: 224,
          creator_id: 0
        },
        {
          categoria: 'Industria financiera',
          selected: false,
          id: 232,
          creator_id: 0,
        },
        {
          categoria: 'Manufactura',
          selected: false,
          id: 242,
          creator_id: 0,
        },
        {
          categoria: 'Medios de comunicación',
          selected: false,
          id: 251,
          creator_id: 0,
        },
        {
          categoria: 'Servicios Convergentes',
          selected: false,
          id: 260,
          creator_id: 0

        },
        {
          categoria: 'Salud Silvicultura: madera, celulosa y papel',
          selected: false,
          id: 271,
          creator_id: 0
        },
        {
          categoria: 'Producción de metales básicos',
          selected: false,
          id: 272,
          creator_id: 0,
        },
        {
          categoria: 'Textiles',
          selected: false,
          id: 273,
          creator_id: 0,
        },
       
      ],
      selected: true
    },
    {
      Name: '---------',
      selected: false
    },
    {
      Name: '---------',
      selected: false
    },
    {
      Name: '---------',
      selected: false
    },
    {
      Name: '---------',
      selected: false
    },
  ];
  selectCheck(check) {
    console.log(check);
  
  
  }
  constructor(public formservice: FormServiceService, private requestService: RequestService,
    private loadingController:LoadingController,) { }

  ngOnInit() {
    // console.log(Object.keys(landJson.landdata))
  }

  selectCategory(event) {
   
    this.formservice.landpublication.category = event.target.value
  }

  selectTypeOfService(event) {
  
    this.formservice.landpublication.type_of_service = event.target.value
    this.Seleccion= this.formservice.landpublication.type_of_service

  
    

  
  }

  addService(event) {
   
    this.formservice.landpublication.service = event.target.value
  }
  updateSelectedCategoria() {
		if(this.subCategoria === false){
			this.subCategoria = true;
		const categorias = this.DataPublications[1].Categorias;
		const selectedCategoria = categorias.find(categoria => categoria.selected);
		this.selectedCategoria = selectedCategoria ? selectedCategoria.categoria : '';
		this.formservice.landpublication.category = this.selectedCategoria;
		}else{
			const categorias = this.DataPublications[1].Categorias;
			const selectedCategoria = categorias.find(categoria => categoria.selected);
			this.selectedCategoria = selectedCategoria ? selectedCategoria.categoria : '';
			this.formservice.landpublication.service = this.selectedCategoria;
			Swal.fire({
				title: 'Categorización exitosa',
		
				icon: 'success',
				confirmButtonText: 'OK',
				color: '#ffffff',
				background: '#010f4e',
			  });
		}
	  }

  async showLoading() {
    this.loading = await this.loadingController.create({
      message: '⚡️⚡️⚡️',
      spinner: 'circles'

    });
    this.loading.present();
  }
  async onClick(checklist) {
      // Update the selected attribute of the clicked checklist
  checklist.selected = !checklist.selected;
  // Log the updated checklist
  console.log(checklist);
    this.updateSelectedCategoria();
    console.log('onClick() function called'); // Debugging message
    // Check if there are no categories selected
    const hasNoSelectedCategories = this.DataPublications.every(publication => {
      if (publication.Categorias) {
        return publication.Categorias.every(category => !category.selected);
      }
      return true;
    });
  
    if (hasNoSelectedCategories) {
      Swal.fire('Error', 'Porfavor selecciona al menos una categoría.', 'error');
      return;
    }
  
    await this.showLoading();
  
    const getCategorias = async () => {
      console.log('getCategorias() function called'); // Debugging message
  
      // Step 1: Get the current state of the 'Categorias' array
      const categorias = this.DataPublications[1].Categorias;
  
      // Step 2: Filter the 'Categorias' array to retrieve only the selected objects
      const selectedCategorias = categorias.filter(categoria => categoria.selected);
  
      console.log('selectedCategorias:', selectedCategorias); // Debugging message
  
      // Step 3: Extract the 'id' values from the filtered objects
      const selectedIds = selectedCategorias.map(cat => cat.id);

      const endpoint = 'categoriesLand';
      const queryParams = { id: selectedIds.join(',') }; // Send the 'id's as a comma-separated string
      try {
        const data = await this.requestService.createRequestGet(endpoint, queryParams);
        console.log('data:', data); // Debugging message
  
        // Add JSON parsing and logging
        console.log("Raw JSON data:", data);
        let parsedData;
        try {
          parsedData = JSON.parse(data);
          console.log("Parsed JSON data:", parsedData);
        } catch (error) {
          console.error("Error parsing JSON data:", error);
        }  
//step 4: map th data
        const newPublication = {
          Categorias: parsedData.map(categoria => ({
            ...categoria,
            selected: false,
          })),
        };
  
        // Step 5: Extract the 'categoria', 'id', and 'creator_id' values from the returned objects
        if (Array.isArray(parsedData) && parsedData.every(item => typeof item === 'object' && 'categoria' in item && 'id' in item && 'creator_id' in item)) {
          const newCategorias = parsedData.map(categoria => ({
            categoria: categoria.categoria,
            id: categoria.id,
            creator_id: categoria.creator_id,
            selected: false,
          }));
        
          console.log('newCategorias:', newCategorias); // Debugging message
   if(newCategorias.length ===0){
     this.displayContainer=false;
   }
        
          // Replace the 'Categorias' array with the new one
          const categoriasCopy = JSON.parse(JSON.stringify(this.DataPublications[1].Categorias));
  categoriasCopy.forEach(categoria => categoria.selected = false);
  this.categoriasHistory.push(categoriasCopy);
          this.DataPublications[1].Categorias = newCategorias;
           // Select or unselect checkboxes based on "categorias" data
         
        } else {
          console.error('Unexpected data format:', data);
        }
  
        // Hide the loading spinner when the operation is complete
        this.loading.dismiss();
      } catch (error) {
        console.error('Error fetching categories:', {
          message: error.message,
          name: error.name,
          stack: error.stack,
          errorObject: error,
        });
      }
    };
  
    await getCategorias(); // Invoke the function
  }
  onClickBack() {
    if (this.categoriasHistory.length > 0) {
      this.DataPublications[1].Categorias = this.categoriasHistory.pop();
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'No hay historial',
        text: 'No se puede retroceder más.',
      });
    }
  }
}
