import { Land } from 'src/app/Models/Classes/Publications/Land/land';
import { Component, Input, OnInit } from '@angular/core';
import {
  AlertController,
  ModalController,
  NavController,
  IonToggle
} from '@ionic/angular';
import { PublicationService } from 'src/app/services/publications/publication.service';
import { CameraServiceService } from 'src/app/services/multimedia/camera-service.service';
import { MediaItem } from 'src/app/Models/Classes/Media/media-item';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import { Utils } from 'src/app/Models/Classes/utils';
import { Capacitor } from '@capacitor/core';
import Swal2 from 'sweetalert2';
import { RequestService } from 'src/app/services/request/request.service';
import { isVideo,isImage } from 'src/app/utils';
import { UploadMultimediaService } from './../../../../services/upload/upload-multimedia.service';
import { element } from 'protractor';
import { PrincipalPublicationComponent } from './../principal-components/principal-publication/principal-publication.component';
import { MyFeedPagePage } from './../../../../pages/my-feed-page/my-feed-page.page';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Browser } from '@capacitor/browser';
import { ViewChild, ElementRef } from '@angular/core';
@Component({
  selector: 'app-descripcion-publication',
  templateUrl: './descripcion-publication.component.html',
  styleUrls: ['./descripcion-publication.component.scss'],
})
export class DescripcionPublicationComponent implements OnInit {
  @ViewChild('youTube_toggleElement') youTube_toggleElement: IonToggle;
  youtube_email;

  @Input() audio_duration;
  @Input() audio_start_value;

  browsersEnabled;
  navigator_language='Español';

  @ViewChild('linkedin_toggleElement') linkedin_toggleElement: IonToggle;
  linkedin_user_name;
  linkedin_email_verification;
  linkedin_profile_photo;
  linkedin_email:boolean=false;


  @ViewChild('twitter_toggleElement') twitter_toggleElement: IonToggle;
  twitter_profile_photo;
  twitter_likes_count;
  twitter_is_verified;
  twitter_profile_deep_link;
  twitter_username;
  twitter_followers;
  twitter_following;
  twitter_content_count;
  twitter_privacy_level_options;
  twitter_profile_bio_description;

  @ViewChild('instagram_toggleElement') instagram_toggleElement: IonToggle;
  instagram_media_count;
  instagramReelsEnabled;
  instagramStoriesEnabled;
  instagram_follows;
  instagram_followers;
  instagram_biography;
  instagram_user_name;
  instagram_profile_photo;

  @ViewChild('TikTok_toggleElement') TikTok_toggleElement: IonToggle;
  disable_duet;
  disable_stitch;
  tiktokVideoEnabled;
  tiktok_followers;
  tiktokPhotoEnabled;
  video_cover_timestamp_ms;
  tiktok_is_verified;
  tiktok_profile_bio_description;
  tiktok_profile_deep_link;
  tiktok_video_count;
  tiktok_likes_count;
  tiktok_following;
  tiktok_Content_Disclosure_Setting:boolean=false;
  tiktok_contenido_de_marca:boolean=false;
  tiktok_auto_add_music:boolean=false;
  tiktok_tu_marca:boolean=false;
  tiktok_privacy;
  tiktokDraftEnabled;
  tiktokDirectPost;
  tiktok_privacy_level_options;
  tiktok_profile_photo;
  tiktok_comment_disabled;
  tiktok_username;
  images_compatible_to_tiktok=[];
  thirdPrtyMedia:any;
  thirdPrtyMediaType;
  publication_id;
  public tiktokEnabled:boolean=false;
public instagramEnabled:boolean=false;
public twitterEnabled:boolean=false;
public linkedinEnabled:boolean=false;
public youTubeEnabled:boolean=false;
  // Modulo Earth Multimedias
  Habitacion = "../../../../../assets/icon/Iconos/cuarto.png"
  Bathroom = "../../../../../assets/icon/Iconos/Bathroom.png"
  KingSize = "../../../../../assets/icon/Iconos/cama-king.png"
  QueenSize = "../../../../../assets/icon/Iconos/bed-queen.png"
  Matrimonial = "../../../../../assets/icon/Iconos/cama-matrimonial.png"
  Cama_agua = "../../../../../assets/icon/Iconos/colchon.png"
  Individual = "../../../../../assets/icon/Iconos/cama-individual.png"
  CamaNin = "../../../../../assets/icon/Iconos/cama-nino.png"
  Cuna = "../../../../../assets/icon/Iconos/cuna.png"
  Litera = "../../../../../assets/icon/Iconos/litera.png"
  Sofa = "../../../../../assets/icon/Iconos/sofa.png"
  Sofa_cama = "../../../../../assets/icon/Iconos/sofa-cama.png"
  Colchon_inflable = "../../../../../assets/icon/Iconos/colchon-de-aire.png"
  Colchon_suelo = "../../../../../assets/icon/Iconos/colchoneta-para-dormir.png"
  Hamaca = "../../../../../assets/icon/Iconos/hamaca.png"

  imagen: string = ""
  multimedias: Array<object> = []
  public multimedia: Array<MediaItem> = new Array<MediaItem>();
  multimedias_to_upload = [];





  @Input() ReservaCalendario: Array<any>;

  constructor(
    private request:RequestService,
    private cameraService: CameraServiceService,
    public publicationService: PublicationService,
    public navCtrl: NavController,
    public alertController: AlertController,
    public formservice: FormServiceService,
    public utils: Utils,
    public modalController: ModalController,
    public uploadmultimedia:UploadMultimediaService,
    public principal:PrincipalPublicationComponent,
    private myfeed:MyFeedPagePage,
  ) { }
  ngOnInit() {
    console.log("tipo de publicacion:",this.formservice.publication_type);
    if (this.formservice.publication_type=="lkfilm") {
      this.formservice.lkpublication.price=this.formservice.price

    }else if(this.formservice.publication_type=="normal"){
      this.formservice.normalpublication.price=this.formservice.price
    }

    console.log(this.formservice.normalpublication.title);
    console.log(this.formservice.publication_type);
    console.log("divisa ",this.formservice.divisa);
    console.log("ubicacion ",this.formservice.ubicacion);

    console.log("formservice media",this.formservice.multimedia);
    this.formservice.multimedia.forEach(element => {
      this.imagen = Capacitor.convertFileSrc(element.mediaPath + element.nameFile);
      console.log("ruta media", this.imagen);
      const imagenes = {
        imagen: this.imagen
      };
      this.multimedias.push(imagenes);

    });

    this.CapturaReserva();
    this.CapturaReservaEarth();
  }
  public isVideo(url:string) {
    Capacitor.convertFileSrc(url);
    return isVideo(url);
  }
  ngAfterViewInit(){
    const imagePreviewElement = document.getElementById("imagePreview") as HTMLImageElement;
    if (this.formservice.Capacitor_convertFileSrc && imagePreviewElement){
    setTimeout(() => {
      if (this.formservice.Capacitor_convertFileSrc){
        imagePreviewElement.src =this.formservice.Capacitor_convertFileSrc;
      }

  }, 999);
}
const videoPreviewElement = document.getElementById("videoSource") as HTMLVideoElement;
if (this.formservice.Capacitor_convertFileSrc_video && videoPreviewElement){
setTimeout(() => {
  if (this.formservice.Capacitor_convertFileSrc_video){
    videoPreviewElement.src =this.formservice.Capacitor_convertFileSrc_video;
  }

}, 999);
}
  }

  public isImage(url:string){
    Capacitor.convertFileSrc(url);
    return isImage(url);
  }

  CapturaReserva() {


    let Reservas = this.formservice.landpublication.ReservaCalendario;

    // console.log('Reserva en descripcion:', Reservas);

    const Agenda: Array<object> = [];

    Agenda.push(Reservas);

    // console.log('Agenda:', Agenda);

    Agenda.forEach((element) => {
      let key = Object.keys(element);
      let valor = Object.values(element);

      valor = valor.map((obj, idx) => ({
        fecha: key[idx],
        cupo: obj.cupo,
        horas: obj.hora,
      })


      );

      this.formservice.landpublication.ReservaCalendario = valor
      // console.log("nuevo valor ReservaC:", this.formservice.landpublication.ReservaCalendario);


      // console.log("Latitud:", this.formservice.landpublication.latitud, "Longitud", this.formservice.landpublication.longitud);


    });


  }
  CapturaReservaEarth() {


    let Reservas = this.formservice.earthpublication.ReservaCalendario;

    // console.log('Reserva en descripcion:', Reservas);

    const Agenda: Array<object> = [];

    Agenda.push(Reservas);

    // console.log('Agenda:', Agenda);

    Agenda.forEach((element) => {
      let key = Object.keys(element);
      let valor = Object.values(element);

      valor = valor.map((obj, idx) => ({
        fecha: key[idx],
        cupo: obj.cupo,
        horas: obj.hora,
      })


      );

      this.formservice.earthpublication.ReservaCalendario = valor
      // console.log("nuevo valor ReservaC:", this.formservice.landpublication.ReservaCalendario);


      // console.log("Latitud:", this.formservice.landpublication.latitud, "Longitud", this.formservice.landpublication.longitud);


    });


  }

  dismissNormal() {
    this.formservice.title = '';
    this.formservice.description = '';
    this.formservice.is_feed = false;
    this.formservice.quienpuedever = 'Para todos';
    this.formservice.price = 0;
    this.modalController.dismiss({
      dismissed: true,
    });
    this.thirdPrtyMedia=null;
  }

  dismissLk() {
    this.formservice.title = '';
    this.formservice.description = '';
    this.formservice.is_feed = false;
    this.formservice.quienpuedever = 'Para todos';
    this.formservice.lkpublication.category = undefined;
    this.formservice.lkpublication.price = 0;
    this.modalController.dismiss({
      dismissed: true,
    });
    this.thirdPrtyMedia=null;
  }

  dismissEpoch() {
    this.formservice.title = '';
    this.formservice.description = '';
    this.formservice.is_feed = false;
    this.formservice.quienpuedever = 'Para todos';
    this.formservice.epochpublication.category = undefined;
    this.formservice.epochpublication.titleLeccion = undefined;
    this.formservice.epochpublication.descriptionLeccion = undefined;
    this.formservice.epochpublication.price = undefined;
    this.modalController.dismiss({
      dismissed: true,
    });
    this.thirdPrtyMedia=null;
  }

  dismissLand() {
    this.formservice.title = '';
    this.formservice.description = '';
    this.formservice.is_feed = false;
    this.formservice.quienpuedever = 'Para todos';
    this.formservice.landpublication.price = 0;
    this.formservice.landpublication.cantidad = undefined;
    this.formservice.landpublication.category = undefined;
    this.formservice.landpublication.service = undefined;
    this.formservice.landpublication.type_of_service = undefined;
    this.formservice.landpublication.agenda = undefined;
    this.formservice.landpublication.ubicacion = undefined;
    this.formservice.landpublication.zonaHoraria = undefined;
    this.formservice.landpublication.capacidad = undefined;
    this.formservice.landpublication.title2 = '';
    this.formservice.landpublication.description2 = '';
    this.formservice.landpublication.price2 = 0;
    this.formservice.landpublication.fecha_inicial = undefined;
    this.formservice.landpublication.fecha_final = undefined;
    this.formservice.landpublication.hora_inicial = undefined;
    this.formservice.landpublication.hora_final = undefined;
    this.modalController.dismiss({
      dismissed: true,
    });
    this.thirdPrtyMedia=null;
  }

  dismissEarth() {
    this.formservice.title = '';
    this.formservice.description = '';
    this.formservice.is_feed = false;
    this.formservice.quienpuedever = 'Para todos';
    this.formservice.earthpublication.price = undefined;
    this.formservice.earthpublication.lugar = undefined;
    this.formservice.earthpublication.tipo = undefined;
    this.formservice.earthpublication.alojamiento = undefined;
    this.formservice.earthpublication.alojamientoExclusivo = undefined;
    this.formservice.earthpublication.capacidad = undefined;
    this.formservice.earthpublication.bedrooms = 0;
    this.formservice.earthpublication.bathroom = 0;
    this.formservice.earthpublication.king_bed = 0;
    this.formservice.earthpublication.queen_bed = 0;
    this.formservice.earthpublication.matrimonial_bed = 0;
    this.formservice.earthpublication.individual_bed = 0;
    this.formservice.earthpublication.sofa_bed = 0;
    this.formservice.earthpublication.sofa = 0;
    this.formservice.earthpublication.inflatable_mattress = 0;
    this.formservice.earthpublication.litera = 0;
    this.formservice.earthpublication.mattress_on_the_floor = 0;
    this.formservice.earthpublication.bed_for_children = 0;
    this.formservice.earthpublication.crib = 0;
    this.formservice.earthpublication.water_bed = 0;
    this.formservice.earthpublication.hamaca = 0;
    this.formservice.earthpublication.direction = undefined;
    this.formservice.earthpublication.interior_number = undefined;
    this.formservice.earthpublication.exterior_number = undefined;
    this.formservice.earthpublication.postal_code = undefined;
    this.formservice.earthpublication.col = undefined;
    this.formservice.earthpublication.city = undefined;
    this.formservice.earthpublication.state = undefined;
    this.formservice.earthpublication.wifi = 'No';
    this.formservice.earthpublication.kitchen_room = 'No';
    this.formservice.earthpublication.free_parking = 'No';
    this.formservice.earthpublication.tv = 'No';
    this.formservice.earthpublication.air_conditioning = 'No';
    this.formservice.earthpublication.washing_machine = 'No';
    this.formservice.earthpublication.drying_machine = 'No';
    this.formservice.earthpublication.iron = 'No';
    this.formservice.earthpublication.swimming_pool = 'No';
    this.formservice.earthpublication.pets = 'No';
    this.formservice.earthpublication.smoking_is_allowed = 'No';
    this.formservice.earthpublication.events_are_allowed = 'No';
    this.formservice.earthpublication.personal_clean = 'No';
    this.formservice.earthpublication.additional_details = undefined;
    this.formservice.earthpublication.fecha_inicial = undefined;
    this.formservice.earthpublication.fecha_final = undefined;
    this.modalController.dismiss({
      dismissed: true,
    });
    this.thirdPrtyMedia=null;
  }

  dismissEventos() {
    this.formservice.title = '';
    this.formservice.description = '';
    this.formservice.is_feed = false;
    this.formservice.quienpuedever = 'Para todos';
    this.formservice.eventospublication.category = undefined;
    this.formservice.eventospublication.link = undefined;
    this.formservice.eventospublication.limited_availability = undefined;
    this.formservice.eventospublication.ubicacion = undefined;
    this.formservice.eventospublication.street = undefined;
    this.formservice.eventospublication.interior_number = undefined;
    this.formservice.eventospublication.exterior_number = undefined;
    this.formservice.eventospublication.postal_code = undefined;
    this.formservice.eventospublication.col = undefined;
    this.formservice.eventospublication.city = undefined;
    this.formservice.eventospublication.state = undefined;
    this.formservice.eventospublication.fecha_inicial = undefined;
    this.formservice.eventospublication.fecha_final = undefined;
    this.formservice.eventospublication.hora_inicial = undefined;
    this.formservice.eventospublication.hora_final = undefined;
    this.modalController.dismiss({
      dismissed: true,
    });
    this.thirdPrtyMedia=null;
  }

  publicUpdatemultimedia( multimedia:Array<MediaItem>, type: string, size: number, publication_ids:Array<number>,id:number) {
    this.cameraService
      .postArrayMultimedia(
        multimedia,
        publication_ids,
        'publications.multimedia/' + id,
        this.formservice.publication_type
      )
      .then((ruta: any) => {
        this.updateStatusPublication(publication_ids)
          .then(() => {
            this.publicationService
              .getPublicationByID(publication_ids[0], true)
              .then((publication) => {
                // De ser una historia la publicacion recien creada se debe agregar o reemplazar la historia actual
                console.log("Publicacion normal", publication);
                if (publication.publication_type == 'story') {
                  this.publicationService.updatePublicationList(
                    'MyStoriesList',
                    publication,
                    true
                  );
                } else {
                  //createpost request
                  let pub_type = '';
                  // console.log(this.multimedias[0].typeData);
                  console.log("multimedias:", this.multimedias);
                    if(type.includes("image")) {
                      pub_type= 'imagen';
                    }else if(type.includes("video")){
                      pub_type= 'video';
                    }
                    const objmedia = {
                      route: ruta[0].route,
                      size: size,
                      type:pub_type,
                      publication_type: publication.publication_type,
                      publication_id:id

                    };
                    console.log('objmedia', objmedia);
                    this.request.createRequestPost('publications.multimedia',objmedia).then((final_data) => {

                      this.utils.loadingDismiss();

                    }).catch((error) => {

                      this.utils.loadingDismiss();
                      Swal2.fire({
                        title: 'Error al subir multimedia!',
                        text: error,
                        icon: 'warning',
                        confirmButtonText: 'Entendido',
                        color: '#ffffff',
                        background: '#010f4e',
                      });
                    });

                }
              })
              .catch((error) => {
                console.error(error);
              });
          })
          .catch((error) => {
            console.error(error);
          });
        //this.publicationService.updatePublicationList('MyFeedList', data, true);
        //this.publicationService.updatePublicationList('MyPublicationsList', data, true);
      });
  }

  back() {
    this.modalController.dismiss({
      dismissed: true
    });
  }
  postToIG(){
    if (this.instagramEnabled){
      this.request.createRequestPost('postToInstagram', {instagramReelsEnabled:this.instagramReelsEnabled,instagramStoriesEnabled:this.instagramStoriesEnabled, mediaUrl:this.thirdPrtyMedia, caption:this.formservice.title, mediaType: this.thirdPrtyMediaType })
		.then((data) => {

      if(this.thirdPrtyMediaType === 'video' && data.mediaId){

     // Define a function to handle the Instagram post request
const handleInstagramPost = () => {
  this.request.createRequestPost('postToInstagram', { mediaType: this.thirdPrtyMediaType, mediaId: data.mediaId })
      .then((data) => {
          if (data.success) {
              // If the post was successful, show success message and stop looping
              Swal2.fire({
                  title: 'Publicación en Instagram',
                  text: 'La publicación se ha enviado exitosamente',
                  icon: 'success',
                  toast: true,
                  confirmButtonText: 'Entendido',
                  color: '#ffffff',
                  background: '#010f4e',
              });
          } else {
              // If the post is still being processed, show processing message and continue looping
              Swal2.fire({
                  title: 'Procesando...',
                  text: 'El video se está procesando.',
                  icon: 'success',
                  timer: 45000, // Show the alert for 45 seconds
                  timerProgressBar: true,
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: false
              });
              // Call the function recursively to check again
              handleInstagramPost();
          }
      });
};

// Start the process by calling the function
handleInstagramPost();


      }

		// Open the login URL in the system browser
	
		console.log(data);
    if(data.success){
      Swal2.fire({
        title: 'Publicación en Instagram',
        text: 'La publicación se ha enviado exitosamente',
        icon: 'success',
        toast: true,
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
    }

	});

    }
  }
  postToX(){
    if (this.twitterEnabled){
      const url ='App web: '+ `https://supercumulo.laniakea.tv/home/go-ecosystem-page?p=${this.publication_id}`;
      this.request.createRequestPost('postMediaToX', {mediaUrl:this.thirdPrtyMedia, description:this.formservice.title +': ' +url, mediaType: this.thirdPrtyMediaType })
		.then((data) => {

		// Open the login URL in the system browser
	
		console.log(data);
    if(data.success){
      Swal2.fire({
        title: 'Publicación en 𝕏',
        position: 'top-end',
        toast: true,
        text: 'La publicación se ha enviado exitosamente',
        icon: 'success',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
    }

	});
    }
  }
  postToLinkedIn(){
    if (this.linkedinEnabled){
      const url ='App web: '+ `https://supercumulo.laniakea.tv/home/go-ecosystem-page?p=${this.publication_id}`;
      this.request.createRequestPost('postMediaToLinkedIn', {mediaUrl:this.thirdPrtyMedia, caption:this.formservice.title +': ' +url, mediaType: this.thirdPrtyMediaType })
		.then((data) => {

		// Open the login URL in the system browser
	
		console.log(data);
    if(data.success){
      Swal2.fire({
        title: 'Publicación en LinkedIn',
        text: 'La publicación se ha enviado exitosamente',
        icon: 'success',
        toast: true,
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
    }

	});
    }
  }
  postToYouTube(){
    if (this.youTubeEnabled){

      this.request.createRequestPost('getAuthYouTubeToken', {mediaUrl:this.thirdPrtyMedia,thirdPrtyMediaType:this.thirdPrtyMediaType,  title:this.formservice.title,description:this.formservice.description,   })
		.then((data) => {


		console.log(data);
    if(data.success){
      Swal2.fire({
        title: 'Publicación en YouTube',
        text: 'La publicación se ha enviado exitosamente',
        icon: 'success',
        toast: true,
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
    }

	});
    }
  }
  postToTikTok(){
    if (this.tiktokEnabled){
      let video_cover_timestamp_ms= this.video_cover_timestamp_ms;
      this.request.createRequestPost('postToTikTok', {mediaUrl:this.thirdPrtyMedia,video_cover_timestamp_ms:video_cover_timestamp_ms,thirdPrtyMediaType:this.thirdPrtyMediaType, tiktokDraftEnabled:this.tiktokDraftEnabled,tiktokDirectPost:this.tiktokDirectPost, imagesArray: this.images_compatible_to_tiktok, title:this.formservice.title,description:this.formservice.description,  tiktok_privacy: this.tiktok_privacy, tiktok_comment_disabled:this.tiktok_comment_disabled, tiktok_auto_add_music:this.tiktok_auto_add_music})
		.then((data) => {

		// Open the login URL in the system browser
      this.formservice.TikTok=1;
		console.log(data);
    if(data.success){
      Swal2.fire({
        title: 'Publicación en TikTok',
        text: 'La publicación se ha enviado exitosamente',
        icon: 'success',
        toast: true,
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
    }

	});
    }
  }
  public post() {
    if(this.instagramEnabled && !this.instagram_profile_photo){
      Swal2.fire({
        title: 'Publicación en Instagram',
        text: 'Debes autenticarte antes de activar publicaciones a Instagram',
        icon: 'error',
        toast: true,
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
      return;
    }
    if(this.tiktokEnabled && !this.tiktokDraftEnabled && !this.tiktokDirectPost){
      Swal2.fire({
        title: 'Publicación en TikTok',
        text: 'Debes elegir si enviar como borrador a tu banddeja o publicacón directa',
        icon: 'error',
        toast: true,
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
      return;
    }
    if(this.tiktokEnabled  && !this.tiktokDraftEnabled && !this.tiktok_privacy ){
      Swal2.fire({
        title: 'Publicación en TikTok',
        text: 'Debes elegir la privacidad de la publicación',
        icon: 'error',
        toast: true,
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
      return;
    }
    if(this.tiktok_Content_Disclosure_Setting && !this.tiktok_contenido_de_marca && !this.tiktok_tu_marca  && !this.tiktokDraftEnabled){
      Swal2.fire({
        title: 'Publicación en TikTok',
        text: 'Debes indicar si tu contenido te promociona a ti mismo, a un tercero o a ambos.',
        icon: 'error',
        toast: true,
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
      return;
    }
    if(this.tiktok_privacy ==='SELF_ONLY' && this.tiktok_Content_Disclosure_Setting  && !this.tiktokDraftEnabled){
      Swal2.fire({
        title: 'Publicación en TikTok',
        text: 'La visibilidad del contenido de marca no puede ser privada.',
        icon: 'error',
        toast: true,
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
      return;
    }

    console.log("Estoy dentro del post");
    console.log('formserivce', this.formservice);
    
    if (this.formservice.publication_type === 'normal') {
      console.log('normal form', this.formservice.normalpublication.getPublicationsDataToSend());
      this.utils.loadingPresent().then(() => {
        this.publicationService
          .postPublication(
            this.formservice.normalpublication.getPublicationsDataToSend()
          )
          .then((data: any) => {
            if (Capacitor.getPlatform() !== 'web') {
            data = JSON.parse(data); // En una Historia el id devuelto debe ser el del estado recientemente agregado
            }else{
              if (typeof data === 'string') {
                data = JSON.parse(data);
                   console.log('json result publication service.ts', data);
                 
                 }else {
                  data = data; 
                 }
            }
            let publication_ids: Array<number> = [];
            publication_ids.push(data.id);
            console.log("publication_id",publication_ids);
            this.utils.loadingDismiss();
            this.formservice.normalpublication.blobsMultimedia.forEach((element, index, array) => {
              console.log("Elemento",element);
              this.uploadmultimedia.post(element.blob, element.filename).then(({ link, mimeType }) => {
                
                const ruta = link;
               // const ruta = r[0];
                //const mimeType = r[1];
                console.log('ruta', ruta);
                let pub_type = '';
                //console.log(this.multimedia[0].typeData);
                if(mimeType.includes("image")) {
                  pub_type= 'imagen';
                }else if(mimeType.includes("video")){
                  pub_type= 'video';
                }
             
                let is_premium_m: boolean;
                console.log('is premium', element.isPremium);
                if(element.isPremium === undefined){
                  console.log('current multimedia status', this.formservice.normalpublication.current_multimedia_status);
                  is_premium_m = this.formservice.normalpublication.current_multimedia_status;
                }else{
                  is_premium_m = element.isPremium;
                }
                const objmedia = {
                  route: ruta,
                  size: 800,
                  type:pub_type,
                  publication_type: this.formservice.publication_type,
                  publication_id: publication_ids[0],
                  is_premium: is_premium_m
                };
        
                console.log('objmedia', objmedia);
                this.request.createRequestPost('publications.multimedia',objmedia).then((final_data) => {
                  this.thirdPartyPost(is_premium_m, pub_type, link, index, array, publication_ids[0]);
                  console.log('request history final data',final_data);
                 
                }).catch((error) => {
                  this.utils.loadingDismiss();
                  Swal2.fire({
                    title: 'Error al subir multimedia!',
                    text: error,
                    icon: 'warning',
                    confirmButtonText: 'Entendido',
                    color: '#ffffff',
                    background: '#010f4e',
                  });

                });

              });
            });
           
            console.log('finalizado subir multimedias');
            this.utils.loadingDismiss();
            this.formservice.normalpublication.blobsMultimedia = []
            this.back();
          })
          .catch((error) => {
            Swal2.fire({
              title: 'Error al subir multimedia!',
              text: 'Ocurrió un error inseperado, si persiste comunícate con el soprte de Laniakea',
              icon: 'warning',
              confirmButtonText: 'Entendido',
              color: '#ffffff',
              background: '#010f4e',
            });
            this.utils.loadingDismiss();
          });
      });
    }
    
    if (this.formservice.publication_type === 'lkfilm') {
      this.utils.loadingPresent().then(() => {
        this.publicationService
          .postPublication(
            this.formservice.lkpublication.getPublicationsDataToSend()
          ).then((data: any) => {
            if (Capacitor.getPlatform() !== 'web') {
          data = JSON.parse(data); // En una Historia el id devuelto debe ser el del estado recientemente agregado
        }else{
          if (typeof data === 'string') {
            data = JSON.parse(data);
               console.log('json result publication service.ts', data);
             
             }else {
              data = data; 
             }
        }
          let publication_ids: Array<number> = [];
          publication_ids.push(data.id);
          if (data.publication_type == 'story' && data.state_id != null) {
            publication_ids.push(data.state_id);
          }
          console.log(data);
          this.utils.loadingDismiss();
         // if (this.formservice.lkpublication.multimedia.length != 0) {
            //En una historia el multimedia container lo deberia tener un estado
            //this.utils.alertPresent('En Preparación', 'Le notificaremos cuando pueda ver su publicación', 'OK');
            this.formservice.normalpublication.blobsMultimedia.forEach((element, index, array) => {
              console.log("Elemento", element);
              this.uploadmultimedia.post(element.blob, element.filename).then(({ link, mimeType }) => {
                const ruta = link;
               // const ruta = r[0];
                //const mimeType = r[1];
                console.log('ruta', ruta);
                let pub_type = '';
                //console.log(this.multimedia[0].typeData);
                if (mimeType.includes("image")) {
                  pub_type = 'imagen';
                } else if (mimeType.includes("video")) {
                  pub_type = 'video';
                }
                let is_premium_m: boolean;
                console.log('is premium', element.isPremium);
                if(element.isPremium === undefined){
                  console.log('current multimedia status', this.formservice.normalpublication.current_multimedia_status);
                  is_premium_m = this.formservice.normalpublication.current_multimedia_status;
                }else{
                  is_premium_m = element.isPremium;
                }
                const objmedia = {
                  route: ruta,
                  size: 800,
                  type: pub_type,
                  publication_type: this.formservice.publication_type,
                  publication_id: publication_ids[0],
                  is_premium: is_premium_m,
                };
               
                console.log('objmedia', objmedia);
                this.request.createRequestPost('publications.multimedia', objmedia).then((final_data) => {
                  this.thirdPartyPost(is_premium_m, pub_type, link, index, array, publication_ids[0]);
                  console.log('request history final data', final_data);

                }).catch((error) => {
                  this.utils.loadingDismiss();
                  Swal2.fire({
                    title: 'Error al subir multimedia!',
                    text: 'Ocurrió un error inseperado, si persiste comunícate con el soprte de Laniakea',
                    icon: 'warning',
                    confirmButtonText: 'Entendido',
                    color: '#ffffff',
                    background: '#010f4e',
                  });

                });

              });
            });
         // }
          console.log('finalizado subir multimedias');
          this.utils.loadingDismiss();
          this.formservice.normalpublication.blobsMultimedia = []
          this.back();
        }).catch((error)=>{
            this.utils.loadingDismiss();
            Swal2.fire({
              title: 'Error al subir publicacion!',
              text: 'Ocurrió un error inseperado, si persiste comunícate con el soprte de Laniakea',
              icon: 'warning',
              confirmButtonText: 'Entendido',
              color: '#ffffff',
              background: '#010f4e',
            });
        });
      });
    }
    if (this.formservice.publication_type === 'epoch') {
      this.utils.loadingPresent().then(() => {
        this.publicationService
          .postPublication(
            this.formservice.epochpublication.getPublicationsDataToSend()
          )
          .then((data: any) => {
          
            if (Capacitor.getPlatform() !== 'web') {
              data = JSON.parse(data); // En una Historia el id devuelto debe ser el del estado recientemente agregado
            }else{
              if (typeof data === 'string') {
                data = JSON.parse(data);
                   console.log('json result publication service.ts', data);
                 
                 }else {
                  data = data; 
                 }
            }
              let publication_ids: Array<number> = [];
              publication_ids.push(data.id);
              if (data.publication_type == 'story' && data.state_id != null) {
                publication_ids.push(data.state_id);
              }
            console.log(data);
            this.utils.loadingDismiss();
       
             // if (this.formservice.landpublication.multimedia.length != 0) {
              this.formservice.normalpublication.blobsMultimedia.forEach((element, index, array) => {
                  console.log("Elemento",element);
                  this.uploadmultimedia.post(element.blob, element.filename).then(({ link, mimeType }) => {
                    const ruta = link;
                   // const ruta = r[0];
                    //const mimeType = r[1];
                    console.log('ruta', ruta);
                    let pub_type = '';
                    //console.log(this.multimedia[0].typeData);
                    if(mimeType.includes("image")) {
                      pub_type= 'imagen';
                    }else if(mimeType.includes("video")){
                      pub_type= 'video';
                    }
                    let is_premium_m: boolean;
                    console.log('is premium', element.isPremium);
                    if(element.isPremium === undefined){
                      console.log('current multimedia status', this.formservice.normalpublication.current_multimedia_status);
                      is_premium_m = this.formservice.normalpublication.current_multimedia_status;
                    }else{
                      is_premium_m = element.isPremium;
                    }
                    const objmedia = {
                      route: ruta,
                      size: 800,
                      type:pub_type,
                      publication_type: this.formservice.publication_type,
                      publication_id: publication_ids[0],
                      is_premium: is_premium_m
                    };
                   
                    console.log('objmedia', objmedia);
                    this.request.createRequestPost('publications.multimedia',objmedia).then((final_data) => {
                      this.thirdPartyPost(is_premium_m, pub_type, link, index, array, publication_ids[0]);
                      console.log('request history final data',final_data);
                      this.dismissEpoch();
                    }).catch((error) => {
                      this.utils.loadingDismiss();
                      Swal2.fire({
                        title: 'Error al subir multimedia!',
                        text: 'Ocurrió un error inseperado, si persiste comunícate con el soprte de Laniakea',
                        icon: 'warning',
                        confirmButtonText: 'Entendido',
                        color: '#ffffff',
                        background: '#010f4e',
                      });
    
                    });
    
                  });
                });
          /*  if (this.formservice.epochpublication.multimedia.length != 0) {
              //En una historia el multimedia container lo deberia tener un estado
              //this.utils.alertPresent('En Preparación', 'Le notificaremos cuando pueda ver su publicación', 'OK');
              this.cameraService
                .postArrayMultimedia(
                  this.formservice.epochpublication.multimedia,
                  publication_ids,
                  'publications',
                  this.formservice.publication_type
                )
                .finally(() => {
                  this.updateStatusPublication(publication_ids)
                    .then(() => {
                      this.publicationService
                        .getPublicationByID(publication_ids[0], true)
                        .then((publication) => {
                          // De ser una historia la publicacion recien creada se debe agregar o reemplazar la historia actual
                          console.log(publication);
                          if (publication.publication_type == 'story') {
                            this.publicationService.updatePublicationList(
                              'MyStoriesList',
                              publication,
                              true
                            );
                          } else {
                            this.publicationService.updatePublicationList(
                              'MyepochList',
                              publication,
                              true
                            );
                            this.publicationService.updatePublicationList(
                              'MyPublicationsList',
                              publication,
                              true
                            );
                            this.dismissEpoch();
                          }
                        })
                        .catch((error) => {
                          console.error(error);
                        });
                    })
                    .catch((error) => {
                      console.error(error);
                    });
                  //this.publicationService.updatePublicationList('MyepochList', data, true);
                  //this.publicationService.updatePublicationList('MyPublicationsList', data, true);
                });
              this.publicationService.updatePublicationList(
                'MyepochList',
                data,
                true
              );
              this.publicationService.updatePublicationList(
                'MyPublicationsList',
                data,
                true
              );
            } else {
              //this.utils.alertPresent('Exito', 'Publicación realizada con exito', 'OK');
              this.publicationService.updatePublicationList(
                'MyepochList',
                data,
                true
              );
              this.publicationService.updatePublicationList(
                'MyPublicationsList',
                data,
                true
              );

              
              this.dismissEpoch();
              // this.cancelButton();
            }
            */
          })
          .catch((error) => {
            console.error(error);
            this.utils.loadingDismiss();
          });

      });
    
    }
    if (this.formservice.publication_type === 'land') {
      this.utils.loadingPresent().then(() => {
        this.publicationService
          .postPublication(
            this.formservice.landpublication.getPublicationsDataToSend()
          )
          .then((data: any) => {
            if (Capacitor.getPlatform() !== 'web') {
            data = JSON.parse(data); // En una Historia el id devuelto debe ser el del estado recientemente agregado
          }else{
            if (typeof data === 'string') {
              data = JSON.parse(data);
                 console.log('json result publication service.ts', data);
               
               }else {
                data = data; 
               }
          }
            let publication_ids: Array<number> = [];
            publication_ids.push(data.id);
            if (data.publication_type == 'story' && data.state_id != null) {
              publication_ids.push(data.state_id);
            }
            console.log("Data information", data);
            this.utils.loadingDismiss();
           // if (this.formservice.landpublication.multimedia.length != 0) {
            this.formservice.normalpublication.blobsMultimedia.forEach((element, index, array) => {
                console.log("Elemento",element);
                this.uploadmultimedia.post(element.blob, element.filename).then(({ link, mimeType }) => {
                  const ruta = link;
                 // const ruta = r[0];
                  //const mimeType = r[1];
                  console.log('ruta', ruta);
                  let pub_type = '';
                  //console.log(this.multimedia[0].typeData);
                  if(mimeType.includes("image")) {
                    pub_type= 'imagen';
                  }else if(mimeType.includes("video")){
                    pub_type= 'video';
                  }
                  let is_premium_m: boolean;
                  console.log('is premium', element.isPremium);
                  if(element.isPremium === undefined){
                    console.log('current multimedia status', this.formservice.normalpublication.current_multimedia_status);
                    is_premium_m = this.formservice.normalpublication.current_multimedia_status;
                  }else{
                    is_premium_m = element.isPremium;
                  }
                  const objmedia = {
                    route: ruta,
                    size: 800,
                    type:pub_type,
                    publication_type: this.formservice.publication_type,
                    publication_id: publication_ids[0],
                    is_premium: is_premium_m
                  };
               
                  console.log('objmedia', objmedia);
                  this.request.createRequestPost('publications.multimedia',objmedia).then((final_data) => {
                    this.thirdPartyPost(is_premium_m, pub_type, link, index, array, publication_ids[0]);
                    console.log('request history final data',final_data);
                  }).catch((error) => {
                    this.utils.loadingDismiss();
                    Swal2.fire({
                      title: 'Error al subir multimedia!',
                      text: 'Ocurrió un error inseperado, si persiste comunícate con el soprte de Laniakea',
                      icon: 'warning',
                      confirmButtonText: 'Entendido',
                      color: '#ffffff',
                      background: '#010f4e',
                    });
  
                  });
  
                });
              });
              //En una historia el multimedia container lo deberia tener un estado
              //this.utils.alertPresent('En Preparación', 'Le notificaremos cuando pueda ver su publicación', 'OK');
              //this.cameraService
               // .postArrayMultimedia(
               //   this.formservice.landpublication.multimedia,
               //   publication_ids,
              //    'publications',
               //   this.formservice.publication_type
               // )
               // .finally(() => {
               //   this.updateStatusPublication(publication_ids)
               //     .then(() => {
               //       this.publicationService
              //          .getPublicationByID(publication_ids[0], true)
              //          .then((publication) => {
                          // De ser una historia la publicacion recien creada se debe agregar o reemplazar la historia actual
                //          console.log(publication);
                          //if (publication.publication_type == 'story') {
                            //this.publicationService.updatePublicationList(
                              //'MyStoriesList',
                             // publication,
                           //   true
                           // );
                          //} else {
                          ///  this.publicationService.updatePublicationList(
                          ///    'MylandList',
                           //   publication,
                          //    true
                          //  );
                         //   this.publicationService.updatePublicationList(
                         //     'MyPublicationsList',
                         //     publication,
                        //      true
                        //    );
                            this.dismissLand();
                         // }
                        //})
                       // .catch((error) => {
                      //    console.error(error);
                      //  });
                    //})
                   // .catch((error) => {
                   //   console.error(error);
                   // });
                  //this.publicationService.updatePublicationList('MylandList', data, true);
                  //this.publicationService.updatePublicationList('MyPublicationsList', data, true);
              //  });
              //this.publicationService.updatePublicationList(
             //   'MylandList',
             //   data,
            //    true
           //   );
             // this.publicationService.updatePublicationList(
           //     'MyPublicationsList',
          //      data,
            //    true
            //  );
           // } else {
              //this.utils.alertPresent('Exito', 'Publicación realizada con exito', 'OK');
             // this.publicationService.updatePublicationList(
            //    'MylandList',
            //    data,
           //     true
          //    );
         //     this.publicationService.updatePublicationList(
        //        'MyPublicationsList',
        //        data,
        //        true
          //    );
              this.dismissLand();
              // this.cancelButton();
           // }
          })
          .catch((error) => {
            console.error(error);
            this.utils.loadingDismiss();
          });
      });
    }
    if (this.formservice.publication_type === 'earth') {
      this.utils.loadingPresent().then(() => {
        this.publicationService
          .postPublication(
            this.formservice.earthpublication.getPublicationsDataToSend()
          )
          .then((data: any) => {
            if (Capacitor.getPlatform() !== 'web') {
              data = JSON.parse(data); // En una Historia el id devuelto debe ser el del estado recientemente agregado
            }else{
              if (typeof data === 'string') {
                data = JSON.parse(data);
                   console.log('json result publication service.ts', data);
                 
                 }else {
                  data = data; 
                 }
            }
              let publication_ids: Array<number> = [];
              publication_ids.push(data.id);
              if (data.publication_type == 'story' && data.state_id != null) {
                publication_ids.push(data.state_id);
              }
              console.log("Data information", data);
              this.utils.loadingDismiss();
           // if (this.formservice.landpublication.multimedia.length != 0) {
            this.formservice.normalpublication.blobsMultimedia.forEach((element, index, array) => {
                console.log("Elemento",element);
                this.uploadmultimedia.post(element.blob, element.filename).then(({ link, mimeType }) => {
                  const ruta = link;
                 // const ruta = r[0];
                  //const mimeType = r[1];
                  console.log('ruta', ruta);
                  let pub_type = '';
                  //console.log(this.multimedia[0].typeData);
                  if(mimeType.includes("image")) {
                    pub_type= 'imagen';
                  }else if(mimeType.includes("video")){
                    pub_type= 'video';
                  }
                  let is_premium_m: boolean;
                  console.log('is premium', element.isPremium);
                  if(element.isPremium === undefined){
                    console.log('current multimedia status', this.formservice.normalpublication.current_multimedia_status);
                    is_premium_m = this.formservice.normalpublication.current_multimedia_status;
                  }else{
                    is_premium_m = element.isPremium;
                  }
                  const objmedia = {
                    route: ruta,
                    size: 800,
                    type:pub_type,
                    publication_type: this.formservice.publication_type,
                    publication_id: publication_ids[0],
                    is_premium: is_premium_m
                  };
                
                  console.log('objmedia', objmedia);
                  this.request.createRequestPost('publications.multimedia',objmedia).then((final_data) => {
                    this.thirdPartyPost(is_premium_m, pub_type, link, index, array, publication_ids[0]);
                    console.log('request history final data',final_data);
                    this.dismissEarth();
                  }).catch((error) => {
                    this.utils.loadingDismiss();
                    Swal2.fire({
                      title: 'Error al subir multimedia!',
                      text: 'Ocurrió un error inseperado, si persiste comunícate con el soprte de Laniakea',
                      icon: 'warning',
                      confirmButtonText: 'Entendido',
                      color: '#ffffff',
                      background: '#010f4e',
                    });
  
                  });
  
                });
              });
            /*
            console.log(data);
            this.utils.loadingDismiss();
            if (this.formservice.earthpublication.multimedia.length != 0) {
              //En una historia el multimedia container lo deberia tener un estado
              //this.utils.alertPresent('En Preparación', 'Le notificaremos cuando pueda ver su publicación', 'OK');
              this.cameraService
                .postArrayMultimedia(
                  this.formservice.earthpublication.multimedia,
                  publication_ids,
                  'publications',
                  this.formservice.publication_type
                )
                .finally(() => {
                  this.updateStatusPublication(publication_ids)
                    .then(() => {
                      this.publicationService
                        .getPublicationByID(publication_ids[0], true)
                        .then((publication) => {
                          // De ser una historia la publicacion recien creada se debe agregar o reemplazar la historia actual
                          console.log(publication);
                          if (publication.publication_type == 'story') {
                            this.publicationService.updatePublicationList(
                              'MyStoriesList',
                              publication,
                              true
                            );
                          } else {
                            this.publicationService.updatePublicationList(
                              'MyearthList',
                              publication,
                              true
                            );
                            this.publicationService.updatePublicationList(
                              'MyPublicationsList',
                              publication,
                              true
                            );
                            this.dismissEarth();
                          }
                        })
                        .catch((error) => {
                          console.error(error);
                        });
                    })
                    .catch((error) => {
                      console.error(error);
                    });
                  //this.publicationService.updatePublicationList('MyearthList', data, true);
                  //this.publicationService.updatePublicationList('MyPublicationsList', data, true);
                });
              this.publicationService.updatePublicationList(
                'MyearthList',
                data,
                true
              );
              this.publicationService.updatePublicationList(
                'MyPublicationsList',
                data,
                true
              );
            } else {
              //this.utils.alertPresent('Exito', 'Publicación realizada con exito', 'OK');
              this.publicationService.updatePublicationList(
                'MyearthList',
                data,
                true
              );
              this.publicationService.updatePublicationList(
                'MyPublicationsList',
                data,
                true
              );
              this.dismissEarth();
              // this.cancelButton();
            }
            */
          })
          .catch((error) => {
            console.error(error);
            this.utils.loadingDismiss();
          });
      });
     
    }
    if (this.formservice.publication_type === 'LK MUSIC') {
      console.log('normal form', this.formservice.normalpublication.getPublicationsDataToSend());
      let data=  this.formservice.normalpublication.get_LK_MUSIC_DataToSend();
      data.data.audio_duration=this.audio_duration;
      data.data.audio_start_value=this.audio_start_value;
      this.utils.loadingPresent().then(() => {
        this.publicationService
          .postPublication(
            data
          )
          .then((data: any) => {
            if (Capacitor.getPlatform() !== 'web') {
            data = JSON.parse(data); // En una Historia el id devuelto debe ser el del estado recientemente agregado
            }else{
              if (typeof data === 'string') {
                data = JSON.parse(data);
                   console.log('json result publication service.ts', data);
                 
                 }else {
                  data = data; 
                 }
            }
            let publication_ids: Array<number> = [];
            publication_ids.push(data.id);
            console.log("publication_id",publication_ids);
            this.utils.loadingDismiss();
            this.formservice.normalpublication.blobsMultimedia.forEach((element, index, array) => {
              console.log("Elemento",element);
              this.uploadmultimedia.post(element.blob, element.filename).then(({ link, mimeType }) => {
                
                const ruta = link;
               // const ruta = r[0];
                //const mimeType = r[1];
                console.log('ruta', ruta);
                let pub_type = '';
                let is_premium_m: boolean;
                //console.log(this.multimedia[0].typeData);
                if(mimeType.includes("image")) {
                  pub_type= 'imagen';
                }else if(mimeType.includes("video")){
                  pub_type= 'video';
               }else {//audio 
                  pub_type= 'audio';

                }
             
           
                console.log('is premium', element.isPremium);
                if(element.isPremium === undefined){
                  console.log('current multimedia status', this.formservice.normalpublication.current_multimedia_status);
                  is_premium_m = this.formservice.normalpublication.current_multimedia_status;
                }else{
                  is_premium_m = element.isPremium;
                }
                if( this.formservice.price !== 0 ){
                  is_premium_m=true;
                }
                const objmedia = {
                  route: ruta,
                  size: 800,
                  type:pub_type,
                  publication_type: this.formservice.publication_type,
                  publication_id: publication_ids[0],
                  is_premium: is_premium_m
                };
        
                console.log('objmedia', objmedia);
                this.request.createRequestPost('publications.multimedia',objmedia).then((final_data) => {
                  this.thirdPartyPost(is_premium_m, pub_type, link, index, array, publication_ids[0]);
                  console.log('request history final data',final_data);
                 
                }).catch((error) => {
                  this.utils.loadingDismiss();
                  Swal2.fire({
                    title: 'Error al subir multimedia!',
                    text: error,
                    icon: 'warning',
                    confirmButtonText: 'Entendido',
                    color: '#ffffff',
                    background: '#010f4e',
                  });

                });

              });
            });
           
            console.log('finalizado subir multimedias');
            this.utils.loadingDismiss();
            this.formservice.normalpublication.blobsMultimedia = []
            this.back();
          })
          .catch((error) => {
            Swal2.fire({
              title: 'Error al subir multimedia!',
              text: 'Ocurrió un error inseperado, si persiste comunícate con el soprte de Laniakea',
              icon: 'warning',
              confirmButtonText: 'Entendido',
              color: '#ffffff',
              background: '#010f4e',
            });
            this.utils.loadingDismiss();
          });
      });
    }
    if (this.formservice.publication_type === 'eventos') {
      this.utils.loadingPresent().then(() => {
        this.publicationService
          .postPublication(
            this.formservice.eventospublication.getPublicationsDataToSend()
          )
          .then((data: any) => {
            if (Capacitor.getPlatform() !== 'web') {
              data = JSON.parse(data); // En una Historia el id devuelto debe ser el del estado recientemente agregado
            }else{
              if (typeof data === 'string') {
                data = JSON.parse(data);
                   console.log('json result publication service.ts', data);
                 
                 }else {
                  data = data; 
                 }
            }
              let publication_ids: Array<number> = [];
              publication_ids.push(data.id);
              if (data.publication_type == 'story' && data.state_id != null) {
                publication_ids.push(data.state_id);
              }
            console.log("Data information", data);
            this.utils.loadingDismiss();
           // if (this.formservice.landpublication.multimedia.length != 0) {
            this.formservice.normalpublication.blobsMultimedia.forEach((element, index, array) => {
                console.log("Elemento",element);
                this.uploadmultimedia.post(element.blob, element.filename).then(({ link, mimeType }) => {
                  const ruta = link;
                 // const ruta = r[0];
                  //const mimeType = r[1];
                  console.log('ruta', ruta);
                  let pub_type = '';
                  //console.log(this.multimedia[0].typeData);
                  if(mimeType.includes("image")) {
                    pub_type= 'imagen';
                  }else if(mimeType.includes("video")){
                    pub_type= 'video';
                  }
                  let is_premium_m: boolean;
                  console.log('is premium', element.isPremium);
                  if(element.isPremium === undefined){
                    console.log('current multimedia status', this.formservice.normalpublication.current_multimedia_status);
                    is_premium_m = this.formservice.normalpublication.current_multimedia_status;
                  }else{
                    is_premium_m = element.isPremium;
                  }
                  const objmedia = {
                    route: ruta,
                    size: 800,
                    type:pub_type,
                    publication_type: this.formservice.publication_type,
                    publication_id: publication_ids[0],
                    is_premium: is_premium_m
                  };

                  console.log('objmedia', objmedia);
                  this.request.createRequestPost('publications.multimedia',objmedia).then((final_data) => {
                    this.thirdPartyPost(is_premium_m, pub_type, link, index, array, publication_ids[0]);
                    this.dismissEventos();
                    console.log('request history final data',final_data);
                  }).catch((error) => {
                    this.utils.loadingDismiss();
                    Swal2.fire({
                      title: 'Error al subir multimedia!',
                      text: 'Ocurrió un error inseperado, si persiste comunícate con el soprte de Laniakea',
                      icon: 'warning',
                      confirmButtonText: 'Entendido',
                      color: '#ffffff',
                      background: '#010f4e',
                    });
  
                  });
  
                });
              });
         /*   console.log(data);
            this.utils.loadingDismiss();
            if (this.formservice.eventospublication.multimedia.length != 0) {
              //En una historia el multimedia container lo deberia tener un estado
              //this.utils.alertPresent('En Preparación', 'Le notificaremos cuando pueda ver su publicación', 'OK');
              this.cameraService
                .postArrayMultimedia(
                  this.formservice.eventospublication.multimedia,
                  publication_ids,
                  'publications',
                  this.formservice.publication_type
                )
                .finally(() => {
                  this.updateStatusPublication(publication_ids)
                    .then(() => {
                      this.publicationService
                        .getPublicationByID(publication_ids[0], true)
                        .then((publication) => {
                          // De ser una historia la publicacion recien creada se debe agregar o reemplazar la historia actual
                          console.log(publication);
                          if (publication.publication_type == 'story') {
                            this.publicationService.updatePublicationList(
                              'MyStoriesList',
                              publication,
                              true
                            );
                          } else {
                            this.publicationService.updatePublicationList(
                              'MyeventosList',
                              publication,
                              true
                            );
                            this.publicationService.updatePublicationList(
                              'MyPublicationsList',
                              publication,
                              true
                            );
                            this.dismissEventos();
                          }
                        })
                        .catch((error) => {
                          console.error(error);
                        });
                    })
                    .catch((error) => {
                      console.error(error);
                    });
                  //this.publicationService.updatePublicationList('MyeventosList', data, true);
                  //this.publicationService.updatePublicationList('MyPublicationsList', data, true);
                });
              this.publicationService.updatePublicationList(
                'MyeventosList',
                data,
                true
              );
              this.publicationService.updatePublicationList(
                'MyPublicationsList',
                data,
                true
              );
            } else {
              //this.utils.alertPresent('Exito', 'Publicación realizada con exito', 'OK');
              this.publicationService.updatePublicationList(
                'MyeventosList',
                data,
                true
              );
              this.publicationService.updatePublicationList(
                'MyPublicationsList',
                data,
                true
              );
              this.dismissEventos();
              // this.cancelButton();
            }
            */
          })
          .catch((error) => {
            console.error(error);
            this.utils.loadingDismiss();
          });
      });
     
    }

  }

  public updateStatusPublication(publication_ids: Array<number>) {
    // Log the publication_ids array
    console.log('publication_ids:', publication_ids);
    return new Promise((resolve, reject) => {
      const status = {
        publication_type: 'publication',
        data: {
          publication_status_id: 1,
        },
      };
      this.publicationService
        .putPublication(status, publication_ids[0])
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  emitTwitterToggleEvent(){
    if (this.twitterEnabled  && !this.twitter_username){
      this.request.createRequestPost('getAuthXProfile', {})
      .then((data) => {
  
      // Open the login URL in the system browser
    
      console.log(data);
     
      if(data.success){
        this.twitter_profile_photo=data.tp_user.profile_image_url;
        this.twitter_username=data.tp_user.username;
          this.twitter_privacy_level_options=data.tp_user.protected;
          this.twitter_followers=data.tp_user.followers_count;
          this.twitter_following=data.tp_user.following_count;
          this.twitter_likes_count=data.tp_user.like_count;
          this.twitter_content_count=data.tp_user.tweet_count;
          this.twitter_is_verified=data.tp_user.verified;
          this.twitter_profile_deep_link=data.tp_user.url;
          this.twitter_profile_bio_description=data.tp_user.description;
        Swal2.fire({
          title: 'Usuario de 𝕏',
          text: 'El usuario ha sido autenticado',
          icon: 'success',
          toast: true,
          confirmButtonText: 'Entendido',
          color: '#ffffff',
          background: '#010f4e',
        });
      }else{
        Swal2.fire({
          title: 'Usuario de 𝕏',
          html: 'El usuario no ha sido autenticado. Busca este <ion-icon name="apps-sharp"></ion-icon> icono en la esquina superior izquierda de supercumulo.laniakea.tv, y autenticate.',
          icon: 'error',
          toast: true,
          confirmButtonText: 'Entendido',
          color: '#ffffff',
          background: '#010f4e',
        });
       // Untoggle the ion-toggle element by setting its checked property to false
       this.twitter_toggleElement.checked = false;
      }
  
    });
    }
  }
  emitLinkedInToggleEvent(){
    if (this.linkedinEnabled && !this.linkedin_user_name){
      this.request.createRequestPost('getAuthLinkedInProfile', {})
      .then((data) => {
  
      // Open the login URL in the system browser
    
      console.log(data);
     
      if(data.success){
        this.linkedin_profile_photo=data.success.picture;
        this.linkedin_user_name= data.success.name;
        this.linkedin_email= data.success.email;
        this.linkedin_email_verification= data.success.email_verified;
       


        Swal2.fire({
          title: 'Usuario de LinkedIn',
          text: 'El usuario ha sido autenticado',
          icon: 'success',
          toast: true,
          confirmButtonText: 'Entendido',
          color: '#ffffff',
          background: '#010f4e',
        });
      }else{
        Swal2.fire({
          title: 'Usuario de LinkedIn',
          html: 'El usuario no ha sido autenticado. Busca este <ion-icon name="apps-sharp"></ion-icon> icono en la esquina superior izquierda de supercumulo.laniakea.tv, y autenticate.',
          icon: 'error',
          toast: true,
          confirmButtonText: 'Entendido',
          color: '#ffffff',
          background: '#010f4e',
        });
       // Untoggle the ion-toggle element by setting its checked property to false
       this.linkedin_toggleElement.checked = false;
      }
  
    });
    }
  }
  emitYouTubeToggleEvent(){
    if (this.youTubeEnabled && !this.linkedin_user_name){
      this.request.createRequestPost('getAuthYouTubeToken', {})
      .then((data) => {
  
      // Open the login URL in the system browser
    
      console.log(data);
     
      if(data.success){
        this.youtube_email= data.tp_username;
        Swal2.fire({
          title: 'Usuario de YouTube',
          text: 'El usuario ha sido autenticado',
          icon: 'success',
          toast: true,
          confirmButtonText: 'Entendido',
          color: '#ffffff',
          background: '#010f4e',
        });
      }else{
        Swal2.fire({
          title: 'Usuario de YouTube',
          html: 'El usuario no ha sido autenticado. Busca este <ion-icon name="apps-sharp"></ion-icon> icono en la esquina superior izquierda de supercumulo.laniakea.tv, y autenticate.',
          icon: 'error',
          toast: true,
          confirmButtonText: 'Entendido',
          color: '#ffffff',
          background: '#010f4e',
        });
        
       // Untoggle the ion-toggle element by setting its checked property to false
       this.youTube_toggleElement.checked = false;
      }
  
    });
    }
  }
  emitTikTokToggleEvent(){
    if (this.tiktokEnabled && !this.tiktok_username){
      this.request.createRequestPost('getAuthTikTokProfile', {})
      .then((data) => {
  
      // Open the login URL in the system browser
    
      console.log(data);
     
      if(data.success){
        this.tiktok_profile_photo=data.success.data.creator_avatar_url;
        this.tiktok_username=data.success.data.creator_username;
        //this.tiktok_comment_disabled=data.success.data.comment_disabled;
          this.tiktok_privacy_level_options=data.success.data.privacy_level_options;
          this.tiktok_followers=data.genuine_data.data.user.follower_count;
          this.tiktok_following=data.genuine_data.data.user.following_count;
          this.tiktok_likes_count=data.genuine_data.data.user.likes_count;
          this.tiktok_video_count=data.genuine_data.data.user.video_count;
          this.tiktok_is_verified=data.genuine_data.data.user.is_verified;
          this.tiktok_profile_deep_link=data.genuine_data.data.user.profile_deep_link;
          this.tiktok_profile_bio_description=data.genuine_data.data.user.bio_description;
        Swal2.fire({
          title: 'Usuario de TikTok',
          text: 'El usuario ha sido autenticado',
          icon: 'success',
          toast: true,
          confirmButtonText: 'Entendido',
          color: '#ffffff',
          background: '#010f4e',
        });
      }else{
        Swal2.fire({
          title: 'Usuario de TikTok',
          html: 'El usuario no ha sido autenticado. Busca este <ion-icon name="apps-sharp"></ion-icon> icono en la esquina superior izquierda de supercumulo.laniakea.tv, y autenticate.',
          icon: 'error',
          toast: true,
          confirmButtonText: 'Entendido',
          color: '#ffffff',
          background: '#010f4e',
        });
       // Untoggle the ion-toggle element by setting its checked property to false
       this.TikTok_toggleElement.checked = false;
      }
  
    });
    }
  }
  emitInstagramToggleEvent(){
    if (this.instagramEnabled && !this.instagram_user_name){
      this.request.createRequestPost('getAuthInstagramProfile', {})
      .then((data) => {
        
      // Open the login URL in the system browser
    
      console.log(data);
     
      if(data.success){
        this.instagram_profile_photo=data.tp_user.profile_picture_url;
        this.instagram_user_name=data.tp_user.username;
        this.instagram_biography=data.tp_user.biography;
        this.instagram_followers=data.tp_user.followers_count;
        this.instagram_follows=data.tp_user.follows_count;
        this.instagram_media_count=data.tp_user.media_count;
        Swal2.fire({
          title: 'Usuario de Instagram',
          text: 'El usuario ha sido autenticado',
          icon: 'success',
          toast: true,
          confirmButtonText: 'Entendido',
          color: '#ffffff',
          background: '#010f4e',
        });
      }else{
       // Untoggle the ion-toggle element by setting its checked property to false
       Swal2.fire({
        title: 'Usuario de Instagram',
        html: 'El usuario no ha sido autenticado. Busca este <ion-icon name="apps-sharp"></ion-icon> icono en la esquina superior izquierda de supercumulo.laniakea.tv, y autenticate.',
        icon: 'error',
        toast: true,
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
      this.instagram_toggleElement.checked = false;
      }
  
    });
    }
  }
  openURL(url){
    Browser.open({url:url});
  }
  emit_tiktok_Content_Disclosure_Setting(){
    if(this.tiktok_privacy === 'SELF_ONLY'){
      Swal2.fire({
        title: 'Publicación en TikTok',
        text: 'La visibilidad del contenido de marca no puede ser privada.',
        icon: 'warning',
        toast: true,
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
     
    }
  }
  thirdPartyPost(is_premium_m, pub_type, link, index, array, publication_ids){
    Swal2.fire({
			title: 'Procesando...',
			text: 'El comando se está procesando.',
			icon: 'success',
			timer: 45000, // Show the alert for 45 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-end',
			showConfirmButton: false
		  });
    if(this.tiktokEnabled && !is_premium_m && this.tiktokPhotoEnabled && pub_type === 'imagen'){
      Swal2.fire({
        title: 'Procesando...',
        text: 'El comando se está procesando. Publicando fotos en TiktoK',
        icon: 'success',
        timer: 45000, // Show the alert for 45 seconds
        timerProgressBar: true,
        toast: true,
        position: 'top-end',
        showConfirmButton: false
        });
const extension = link.split('.').pop()?.toLowerCase();

// Check if the extension matches the image formats you want to identify
if (extension === 'jpeg' || extension === 'jpg') {
// Create a new Image element
const img = new Image();

// Set the src attribute to the image URL
img.src = link;

// When the image is loaded, check its dimensions
img.onload = () => {
const width = img.width; // Image width
const height = img.height; // Image height
const FULL_HD_PIXEL_COUNT = 1920 * 1080;

// Check if the dimensions are less than or equal to 1080p
if ((width * height) <= FULL_HD_PIXEL_COUNT) {
      this.images_compatible_to_tiktok.push(link);
      console.log('index', 'array');
      console.log(index, array);
      const isLastElement = index === array.length - 1;
  
      if (isLastElement) {
          // This is the last element in the array
          // Add your logic for the last element here
          this.postToTikTok();
      }
}else{
Swal2.fire({
title: 'Publicación en Tiktok',
text: 'Las imagenes deben ser 1080p como máximo. Es decir, dimensiones no mayores a  1920 x 1080.',
icon: 'error',
toast: true,
confirmButtonText: 'Entendido',
color: '#ffffff',
background: '#010f4e',
});
console.log('Las imagenes deben ser 1080p como máximo. Es decir, dimensiones no mayores a  1920 x 1080.');
}
};
}else{
Swal2.fire({
title: 'Publicación en Tiktok',
text: 'Las imagenes deben estar en formato jpeg',
icon: 'error',
toast: true,
confirmButtonText: 'Entendido',
color: '#ffffff',
background: '#010f4e',
});
}
      }
      else if(this.tiktokEnabled && !is_premium_m && this.tiktokVideoEnabled && pub_type !== 'imagen'){
          Swal2.fire({
            title: 'Procesando...',
            text: 'El comando se está procesando. Publicando video/s en TiktoK',
            icon: 'success',
            timer: 45000, // Show the alert for 45 seconds
            timerProgressBar: true,
            toast: true,
            position: 'top-end',
            showConfirmButton: false
            });
        this.thirdPrtyMedia=link;
        this.thirdPrtyMediaType=pub_type;
        this.postToTikTok();
      }
    
      if(!this.thirdPrtyMedia && !is_premium_m){
        this.thirdPrtyMedia=link;
        this.thirdPrtyMediaType=pub_type;
        this.publication_id= publication_ids;
        if(this.browsersEnabled){
          Swal2.fire({
            title: 'Procesando...',
            text: 'El comando se está procesando. Publicando en navegadores',
            icon: 'success',
            timer: 45000, // Show the alert for 45 seconds
            timerProgressBar: true,
            toast: true,
            position: 'top-end',
            showConfirmButton: false
            });
            this.PostOnWix();
        }
        if(this.linkedinEnabled){
          Swal2.fire({
            title: 'Procesando...',
            text: 'El comando se está procesando. Publicando en LinkedIn',
            icon: 'success',
            timer: 45000, // Show the alert for 45 seconds
            timerProgressBar: true,
            toast: true,
            position: 'top-end',
            showConfirmButton: false
            });
          this.postToLinkedIn();
        }
        if (this.twitterEnabled){
          Swal2.fire({
            title: 'Procesando...',
            text: 'El comando se está procesando. Publicando en Twitter',
            icon: 'success',
            timer: 45000, // Show the alert for 45 seconds
            timerProgressBar: true,
            toast: true,
            position: 'top-end',
            showConfirmButton: false
            });
          this.postToX();
        }
       
      }
      if (this.youTubeEnabled && !is_premium_m){
        Swal2.fire({
          title: 'Procesando...',
          text: 'El comando se está procesando. Publicando en YouTube',
          icon: 'success',
          timer: 45000, // Show the alert for 45 seconds
          timerProgressBar: true,
          toast: true,
          position: 'top-end',
          showConfirmButton: false
          });
          this.thirdPrtyMedia=link;
          this.thirdPrtyMediaType=pub_type;
          if(pub_type==='video'){
            this.postToYouTube();
          }
       
     
      }
      if(this.instagramEnabled && !is_premium_m && this.instagramStoriesEnabled){
        Swal2.fire({
          title: 'Procesando...',
          text: 'El comando se está procesando. Publicando foto en Instagram',
          icon: 'success',
          timer: 45000, // Show the alert for 45 seconds
          timerProgressBar: true,
          toast: true,
          position: 'top-end',
          showConfirmButton: false
          });
        this.thirdPrtyMedia=link;
        this.thirdPrtyMediaType=pub_type;
        this.postToIG();
      }else if(this.instagramEnabled && !is_premium_m && this.instagramReelsEnabled && pub_type=== 'video'){
        Swal2.fire({
          title: 'Procesando...',
          text: 'El comando se está procesando. Publicando video en Instagram',
          icon: 'success',
          timer: 45000, // Show the alert for 45 seconds
          timerProgressBar: true,
          toast: true,
          position: 'top-end',
          showConfirmButton: false
          });
        this.thirdPrtyMedia=link;
        this.thirdPrtyMediaType=pub_type;
        this.postToIG();
      }
  }
  PostOnWix(){


    this.request.createRequestPost('getAuthWixProfile', {navigator_language:this.navigator_language,title:this.formservice.title, description:this.formservice.description,publication_id: this.publication_id})
    .then((data) => {

    });
          
  
  }

}
