import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopzoomComponent } from '../popzoom/popzoom.component';
import { IonicModule } from '@ionic/angular';
import { GeneralModuleModule } from '../../general-design/general-module/general-module.module';
import { PublicationElementsModuleModule } from '../../publication/single-elements/publication-elements-module/publication-elements-module.module';
import { PublicacionesGuardadasComponent } from '../../publication/create/publicaciones-guardadas/publicaciones-guardadas/publicaciones-guardadas.component';
import { PublicacionesResumenComponent } from '../../publication/create/publicaciones-resumen/publicaciones-resumen/publicaciones-resumen.component';
import { PublicacionesColeccionComponent } from '../../publication/create/publicaciones-coleccion/publicaciones-coleccion/publicaciones-coleccion.component';
import { FormsModule } from '@angular/forms';


@NgModule({
	declarations: [
		PopzoomComponent,
		PublicacionesGuardadasComponent,
		PublicacionesResumenComponent,
		PublicacionesColeccionComponent
	],
	exports: [
		PopzoomComponent,
		PublicacionesGuardadasComponent,
		PublicacionesResumenComponent,
		PublicacionesColeccionComponent
	],
	entryComponents: [
		PopzoomComponent,
		PublicacionesGuardadasComponent,
		PublicacionesResumenComponent,
		PublicacionesColeccionComponent
	],
	imports: [
	CommonModule,
		IonicModule,
		GeneralModuleModule,
		PublicationElementsModuleModule,
    FormsModule
	]
})
export class MenuGaleryModulModule { }
