import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-cumulo-subscription2',
  templateUrl: './cumulo-subscription2.component.html',
  styleUrls: ['./cumulo-subscription2.component.scss']
})
export class CumuloSubscription2Component implements OnInit {
   NombreCumulo='';
   ModeloTarifa;
   TarifaEstandar;

   @Input()ArrayCumulo

  constructor(private Mctrl:ModalController) { }

  ngOnInit() {
    console.log();

  }

  Agregar(){

    let cumulo={
      Nombre:this.NombreCumulo,
      ModeloT:this.ModeloTarifa,
      TarifaE:this.TarifaEstandar
    }
    this.ArrayCumulo.push(cumulo)

    this.NombreCumulo=''
    this.ModeloTarifa=''
    this.TarifaEstandar=''
    this.Mctrl.dismiss()



  }
  retorno(){
    this.Mctrl.dismiss()
  }

}
