import { Component, EventEmitter, Input, OnInit, Output,ElementRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MediaItem } from 'src/app/Models/Classes/Media/media-item';
import { PublicationClass } from 'src/app/Models/Classes/Publications/publication-class';
import { MultimediaViewComponent } from '../multimedia-view/multimedia-view.component';
import { isVideo,isImage } from 'src/app/utils';
import {Capacitor} from "@capacitor/core";
import { LkfilmViewBySlidesComponent } from './../../../lkfilm-view-by-slides/lkfilm-view-by-slides.component';
import { ViewChild } from '@angular/core';
import { ViewChildren, QueryList } from '@angular/core';
import Swiper from 'swiper';
import { IonSlides } from '@ionic/angular';
import  Swal from 'sweetalert2';
import { ActualizacionServiceService } from 'src/app/services/actualizacion/Actualizacion-service.service';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import {PublicationMediaTemplateComponent} from 'src/app/components/publication/publication-media-template/publication-media-template.component';
@Component({
	selector: 'app-content-media',
	templateUrl: './content-media.component.html',
	styleUrls: ['./content-media.component.scss'],
})
export class ContentMediaComponent implements OnInit {
  currentIndex;
  totalSlides;
  SlideInteraction:boolean=true;
  index=0;
  checkIntervalId: any;

  timeoutId;
  currentTime: number = 0;
  duration;
  @ViewChild('videoElement') videoElement: ElementRef;
  slideOpts = {
    direction: 'horizontal',
    initialSlide: 0,
    speed: 400,

  };
  @ViewChildren('videoPlayer') videoPlayers: QueryList<any>; // access all video elements
  @ViewChild('media_slider', { static: false }) slider: IonSlides;
	@Input() multimedia: Array<MediaItem>;
	@Input() publication: PublicationClass;
	@Output() savePublication = new EventEmitter();
	@Output() sharePublication = new EventEmitter();
  @Input() activeSlideIndex: number;
  @Input() currentSlideIndex: number;
  videoorPhotoMedia;
  audio;
	constructor(
		private modalController: ModalController,
    private actualizacionservice:ActualizacionServiceService,
    private formService:FormServiceService,
    private publication_template:PublicationMediaTemplateComponent) { }

	ngOnInit() {

    this.videoorPhotoMedia = this.multimedia.filter(mul => mul.type === 'Video' || mul.type === 'Imagen');




    //console.log('Active slide index:', this.activeSlideIndex);
      //console.log('Current slide index:', this.currentSlideIndex);
  }
  updateAudioDuration(event: Event){
    const audio = event.target as HTMLAudioElement;
    const dictationTimer = setInterval(() => {
 
      

    const publication = this.formService.publications[this.formService.publication_template_index];
    

    if (publication) {
      console.log('Current pub in audio:', publication);
     // console.log('Current pub  audio:', publication.multimediaContainer[this.index].type);
      const publicationUrl = publication.multimediaContainer.find(item => item.type === 'Audio')?.url;
      console.log('audio publicationUrl', publicationUrl);
      if (publicationUrl) {
      
  
            const videoAbsoluteUrl = new URL(audio.currentSrc, window.location.href).href;
            const matchingMediaAbsoluteUrl = new URL(publicationUrl, window.location.href).href;
  
            if (videoAbsoluteUrl === matchingMediaAbsoluteUrl) {
              audio.muted = false;
              audio.play();
              console.log('audio played')
            }else{
              audio.muted = true;
              audio.pause();
            }
          
        }else{
          audio.muted = true;
          audio.pause();
        }
      
    }
  }, 1200); // Update every second
    console.log('audio duration', audio.duration);
  }
  updateAudioCurrentTime(){
   
  }
  ngOnDestroy() {
    clearTimeout(this.timeoutId);
    this.timeoutId = null;
    clearInterval(this.checkIntervalId);
    this.checkIntervalId = null;
  }
  ngAfterViewInit() {
    this.currentIndex =  this.slider.getActiveIndex();
    this.totalSlides =  this.slider.length();
    setTimeout(() => {

    const audioPreviewElement = document.getElementById(`audioSource_${this.formService.publication_template_index}`) as HTMLAudioElement;

     this.audio=this.multimedia.filter(mul => mul.type == 'Audio');
  
     if (this.audio && this.audio?.[0]?.url) {
      console.log('audioPreviewElement', this.audio[0]['url']);
      audioPreviewElement.src = this.audio[0]['url'];
      console.log('audioPreviewElement', audioPreviewElement);
    } else {
      console.log('Audio object or URL is missing', this.audio);
    }
  }, 3000);
      this.checkIntervalId = setInterval(() => {
        if(this.SlideInteraction){
          this.SlideInteraction=false;
          this.timeoutId = setTimeout(() => {
            this.SlideInteraction=true;
          }, 3000);
       // console.log('status,', this.formService.changeMediaPeriodically);
        //console.log('status2,', this.duration);
if(!this.formService.changeMediaPeriodically||this.duration ){
  return;
}else{
          Swal.fire({
            title: 'Siguiente',
            text: ``,
            icon: 'info',
            timer: 6900, // Show the alert for 6.9 seconds
            timerProgressBar: true,
            toast: true,
            position: 'bottom-end',
            showCancelButton: true,
          showConfirmButton:true,
            cancelButtonText: '<ion-icon name="reload"></ion-icon>No',
            confirmButtonText: '<ion-icon name="play-skip-forward-sharp"></ion-icon>Si',
            color: 'black',
            background: 'white',
            }).then((result) => {
              this.formService.changeMediaPeriodically=false;
              clearTimeout(this.timeoutId);
              clearInterval(this.timeoutId);
            if (result.isConfirmed) {
    this.Next();
    this.formService.changeMediaPeriodically=true;
            }else{
             
              clearTimeout(this.timeoutId);
              clearInterval(this.timeoutId);
              this.formService.changeMediaPeriodically=false;
    this.timeoutId=null;
            }
            });
    
            // Set a timeout to call getRandomPosts if there is no interaction from the user
     this.timeoutId = setTimeout(() => {
      if(this.duration){
        return; 
      }
      if(!this.formService.changeMediaPeriodically){
        return;
      }
      this.Next();
    }, 6300);
        
  }
}
      }, 6900);

    
      
   
  }

  updateCurrentTime(event: Event) {//displays the alert to change when video ended
    if(!this.formService.changeMediaPeriodically){
      return;
    }
    const video = event.target as HTMLVideoElement;
    this.currentTime = video.currentTime;
    console.log('duration', this.duration);
    const publication = this.formService.publications[this.formService.publication_template_index];
    if(video.duration==this.duration && this.formService.actual_publication === publication){
      console.log('current same video, next alert can only be dipslayed once for a media')
      return;
    }
    this.duration = video.duration;
    console.log('duration', this.duration);
    console.log('currentTime', this.currentTime);
    if (this.currentTime >= video.duration - 6 && !this.timeoutId) {
      console.log('Video is at the end or within 6 seconds of the end.');
      // You can add additional logic here, e.g., displaying an alert or performing an action

      Swal.fire({
				title: 'Siguiente',
				text: ``,
				icon: 'info',
				timer: 6900, // Show the alert for 6.9 seconds
				timerProgressBar: true,
				toast: true,
				position: 'bottom-end',
				showCancelButton: true,
			showConfirmButton:true,
				cancelButtonText: '<ion-icon name="reload"></ion-icon>No',
        confirmButtonText: '<ion-icon name="play-skip-forward-sharp"></ion-icon>Si',
				color: 'black',
				background: 'white',
			  }).then((result) => {
          this.formService.changeMediaPeriodically=false;
          clearTimeout(this.timeoutId);
          clearInterval(this.timeoutId);
				if (result.isConfirmed) {
          this.formService.changeMediaPeriodically=true;
this.Next();
this.currentTime=null;
				}else{
          this.formService.changeMediaPeriodically=false;
          clearTimeout(this.timeoutId);
          clearInterval(this.timeoutId);
          this.duration=null;

          //play again the video
				}
			  });

        // Set a timeout to call getRandomPosts if there is no interaction from the user
 this.timeoutId = setTimeout(() => {


  this.Next();
  this.currentTime=null;
}, 6300);
    }
  }
async Next(){
  console.log('status final,', this.formService.changeMediaPeriodically);
  if(!this.formService.changeMediaPeriodically){
    return;
  }
  this.currentTime=0;
  clearInterval(this.timeoutId);
  this.duration=null;
  this.timeoutId=null;
console.log('nextpost called');

  this.nextMedia();



}	  openComponentVideo(fetchedPublications){
		
  this.formService.mostrar = false;
 this.formService.publications=fetchedPublications;
  this.formService.valueSelect= 'MENU';
  this.formService.mediaTemplate=true;

  }
  updateDuration(event: Event) {//plays the curremt media
  
    const video = event.target as HTMLVideoElement;
    const dictationTimer = setInterval(() => {
   // Check if the current video is playing
   if (!video.paused && !video.ended) {
    // Get all video elements in the document
    const allVideos = document.querySelectorAll('video');

    allVideos.forEach((alternate_video) => {
      // Mute all videos except the current one
      if (alternate_video !== video) {
        alternate_video.muted = true;
        alternate_video.pause();
      }
    });
    console.log('Current video is playing, others muted.');
  }
      

    const publication = this.formService.publications[this.formService.publication_template_index];
    if(this.formService.actual_publication === publication){ //to avoid looping in playing an already started video
      return;
    }else{
      video.muted = true;
      video.pause();
    }
    if (publication) {
      console.log('Current Publication:', publication);
      console.log('Current Publication:', publication.multimediaContainer[this.index].type);
      if (publication.multimediaContainer[this.index].type === 'Video') {
        const publicationUrl = publication.multimediaContainer[this.index].url;
        const matchingMedia = this.multimedia.find(mediaItem => mediaItem.url === publicationUrl);
  
        if (matchingMedia) {
          console.log('Matching multimedia found:', matchingMedia);
  
         
          if (video) {
            const videoAbsoluteUrl = new URL(video.currentSrc, window.location.href).href;
            const matchingMediaAbsoluteUrl = new URL(matchingMedia.url, window.location.href).href;
  
            if (videoAbsoluteUrl === matchingMediaAbsoluteUrl) {
              const mediaElements = document.querySelectorAll('video');
              mediaElements.forEach((media) => {
                // Typecast Element to HTMLMediaElement, which includes video and audio elements
                const mediaElement = media as HTMLMediaElement;
                // Mute each media element
                mediaElement.muted = true;
                // Pause the media if it's playing
                if (!mediaElement.paused) {
                  mediaElement.pause();
                }
              });
              video.muted = false;
              video.play();
              this.duration =video.duration;
              this.formService.actual_publication=publication;
            }else{
              video.muted = true;
              video.pause();
            }
          }
        }else{
          video.muted = true;
          video.pause();
        }
      }
    }
  }, 1200); // Update every second
    console.log('duration', video.duration);
  }
  
  ReadySetPlayVideo(videoPlayer: HTMLVideoElement) {
    if (videoPlayer) {
      console.log('Playback rate:', videoPlayer.playbackRate);
      console.log('Active slide index:', this.activeSlideIndex);
  
      videoPlayer.playbackRate = this.activeSlideIndex === this.activeSlideIndex ? 1.25 : 1.0;
  
    }
  }
  

    async nextMedia() {
    this.duration=null;
     this.currentIndex =   await this.slider.getActiveIndex();
    this.totalSlides =  await this.slider.length();
    if(this.formService.PermitSlidechange  ){
      this.formService.PermitSlidechange =false;
 setTimeout(() => {
  this.formService.PermitSlidechange =true;

      }, 1590);

      console.log('currentIndex',this.currentIndex);
      console.log('totalSlides',this.totalSlides);
    if (this.currentIndex < this.totalSlides - 1) {
      this.slider.slideNext();
    } else {
      this.publication_template.nextPub();
      if(this.formService.publications.length === 1){
        console.log('there are no more posts');
        try {
          const result = await this.actualizacionservice.getAIPublications(null, false, null, false, null);
          const fetchedPublications = result || [];
          this.openComponentVideo(fetchedPublications);
        } catch (error) {
          console.error(error);
        }
      }else{
        console.log('there are already posts');
      }
      console.log('No more media slides available');
    }
          
  }

  }
  backMedia(){
    this.slider.slidePrev(); // Method to move to the next slide
  }
   async slideChanged() {
    this.formService.actual_publication=null;
    this.index=await this.slider.getActiveIndex();

      const publication = this.formService.publications[this.formService.publication_template_index];
    if (publication) {
      // Now you have the publication, you can work with it
      console.log('Current Publication:', publication);
  
    }
    
    //this.formService.changeMediaPeriodically=true;
  }
  ionSlideWillChange(){
    // Query all video and audio elements
this.formService.muteAllMedia();
  }
  //async slideChanged() {
    //const activeIndex = await this.slider.getActiveIndex();
   // const currentSlide = this.multimedia[activeIndex];
  
    // pause all videos
    //this.videoPlayers.toArray().forEach((player) => player.nativeElement.pause());
  
    // if current slide is a video, play it
    //if (currentSlide.type === 'Video') {
     // this.videoPlayers.toArray()[activeIndex].nativeElement.play();
   // }
  //}
  
  public isVideo(url:string) {
    Capacitor.convertFileSrc(url);
    return isVideo(url);
  }

  public isImage(url:string){
    Capacitor.convertFileSrc(url);
    return isImage(url);
  }

	openView2(index?: number) {
		this.modalController.create({
			component: MultimediaViewComponent,
      cssClass: 'detailsInModal',
			swipeToClose: true,
			componentProps: {
				multimedia: this.multimedia,
				index: index + 1,
				publication: this.publication,
				savePublication: this.savePublication,
				sharePublication: this.sharePublication,
			}
		}).then((modal)=>{
			modal.present();
		});
	}

  open_modal(pub) {
    if (pub.publication_type === 'lkfilm'){
      console.log('ES LKFILM!!', pub);
      let pubforlkfilm = {
        owner: pub.owner,
        container: {
          multimediaContainer: pub.multimediaContainer,
          title: pub.title,
          description: pub.description
        }
      };
      this.modalController
        .create({
          component: LkfilmViewBySlidesComponent,
          cssClass: 'detailsInModal',
          swipeToClose: true,
          componentProps: {
            publication: pubforlkfilm,
          },
        })
        .then((modal) => {
          modal.present();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

}
