import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EarhtComponent } from '../../earth/earht/earht.component';
import { Earht2Component } from '../../earth/earht2/earht2.component';
import { Earht3Component } from '../../earth/earht3/earht3.component';
import { Earht4Component } from '../../earth/earht4/earht4.component';
import { Earht5Component } from '../../earth/earht5/earht5.component';
import { Earht6Component } from '../../earth/earht6/earht6.component';
import { PrincipalEarthComponent } from '../principal-earth/principal-earth.component';
import { IonicModule } from '@ionic/angular';
import { DescripcionPublicationComponent } from '../../descripcion-publication/descripcion-publication.component';
import { DescriptionModuleModule } from '../../description-module/description-module.module';
import { CalendariomodulModule } from 'src/app/components/calendar/calendariomodul/calendariomodul.module';
import { GooglemapsComponent } from 'src/app/components/googlemaps/googlemaps.component';


@NgModule({
  declarations: [
    /* componente principal earth */
    PrincipalEarthComponent,
    /* Sub componente bienes */
    EarhtComponent,
    Earht2Component,
    Earht3Component,
    Earht4Component,
    Earht5Component,
    Earht6Component,
    GooglemapsComponent
  ],
  exports:[
    /* componente principal earth */
    PrincipalEarthComponent,
    /* Sub componente bienes */
    EarhtComponent,
    Earht2Component,
    Earht3Component,
    Earht4Component,
    Earht5Component,
    Earht6Component
    
  ],
  entryComponents:[
    /* componente principal earth */
    PrincipalEarthComponent,
    /* Sub componente bienes */
    EarhtComponent,
    Earht2Component,
    Earht3Component,
    Earht4Component,
    Earht5Component,
    Earht6Component
  ],
  imports: [
    CommonModule,
    IonicModule,
    DescriptionModuleModule,
    CalendariomodulModule
  ]
})
export class EarthModuleModule { }
