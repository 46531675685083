import { Injectable } from '@angular/core';
import { ActionSheetController, AlertController } from '@ionic/angular';
import Swal2 from 'sweetalert2';
@Injectable({
	providedIn: 'root'
})
export class InputAlertControllerService {

	constructor(
		private alertController: AlertController,
		private actionSheetController: ActionSheetController,
	) { }

	InputAlertPrompt(header: string, message:string, inputValue?: any, inputType?: any, okButtonText?: string, maxLenght?: string) {
		return new Promise((resolve, reject) => {
			this.alertController.create({
				cssClass: 'relationConfrmation',
				header: header,
				message: message,
				inputs: [
					{
						name: 'edit',
						type: (inputType)? inputType: 'text',
						id: 'edit',
						value: inputValue,
						max: (maxLenght)? maxLenght: "10"
					},
				],
				buttons: [
					{
						text: 'Cancelar',
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => {
							resolve({action: false, value: null});
						}
					}, {
						text: (okButtonText)? okButtonText: 'Ok',
						handler: (data) => {
							resolve({action: true, value: data.edit});
						}
					}
				]
			}).then((alert)=>{
				alert.present().then(()=>{
					//resolve(alert);
				}).catch((error)=>{
					reject(error);
				});
			}).catch((error)=>{
				reject(error);
			});
		});
	}

	SelectorActionSheet(buttonsArray: Array<any>, header: string ) {
		Swal2.fire({
			title: '',
			text: '',
			icon: 'success',
			timer: 9999, // Show the alert for 45 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-start',
			showConfirmButton: false
		  });
    setTimeout(() => {
      this.actionSheetController.dismiss();
      }, 9999); 
		return new Promise((resolve, reject) => {
			let buttons = new Array<any>();
			buttonsArray.forEach((button, index) => {
				let auxButton = [
					{
						text: button.text,
						icon: button.icon,
						handler: () => {
							resolve({action: true, index: index, button: button});
						}
					}
				];
				buttons = buttons.concat(auxButton);
			});

			let cancelButton = [
				{
					text: "Cancelar",
					icon: "close",
					role: 'cancel',
					handler: () => {
						resolve({action: false, index: null, button: null});
					}
				}
			];
			buttons = buttons.concat(cancelButton);

			this.actionSheetController.create({
				header: header,
				cssClass: 'options-sheet',
				buttons: buttons
			}).then((actionSheet)=>{
				actionSheet.present();
			}).catch((error)=>{
				reject(error);
			});
		});
	}

	OkInfoMessage(header: string, message: string, okButtonText?: string){
		return new Promise((resolve, reject) => {
			this.alertController.create({
				cssClass: 'relationConfrmation',
				header: header,
				message: message,
				buttons: [okButtonText? okButtonText: 'Aceptar']
			}).then((alert)=>{
				alert.present().then(()=>{
					resolve(true);
				}).catch((error)=>{
					reject(error);
				});
			}).catch((error)=>{
				reject(error);
			});
		});
	}

	BinarySelectorAlert(header: string, message: string, okButtonText?: string, cancelButtonText?:string){
		return new Promise((resolve, reject) => {
			this.alertController.create({
				cssClass: 'relationConfrmation',
				header: header,
				message: message,
				buttons: [
					{
						text: (cancelButtonText)? cancelButtonText: 'Cancelar',
						role: 'cancel',
						handler: () => {
							resolve({action: false, value: false});
						}
					}, {
						text: (okButtonText)? okButtonText: 'Confirmar',
						handler: () => {
							resolve({action: true, value: true});
						}
					}
				]
			}).then((alert)=>{
				alert.present();
			}).catch((error)=>{
				reject(error);
			});
		});
	}
}
