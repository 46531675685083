import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EclipseEventosComponent } from '../../eclipse-eventos/eclipse-eventos/eclipse-eventos.component';
import { EclipseEventos2Component } from '../../eclipse-eventos/eclipse-eventos2/eclipse-eventos2.component';
import { EclipseEventos3Component } from '../../eclipse-eventos/eclipse-eventos3/eclipse-eventos3.component';
import { EclipseEventos4Component } from '../../eclipse-eventos/eclipse-eventos4/eclipse-eventos4.component';
import { EclipseEventos5Component } from '../../eclipse-eventos/eclipse-eventos5/eclipse-eventos5.component';
import { IonicModule } from '@ionic/angular';
import { PrincipalEventosComponent } from '../principal-eventos/principal-eventos.component';
import { DescripcionPublicationComponent } from '../../descripcion-publication/descripcion-publication.component';
import { DescriptionModuleModule } from '../../description-module/description-module.module';
import { GooglemapsComponent } from 'src/app/components/googlemaps/googlemaps.component';



@NgModule({
  declarations: [
    /* Sub componente */
    PrincipalEventosComponent,
    EclipseEventosComponent,
    EclipseEventos2Component,
    EclipseEventos3Component,
    EclipseEventos4Component,
    EclipseEventos5Component,
    GooglemapsComponent
  ],
  exports:[
    /* Sub componente */
    PrincipalEventosComponent,
    EclipseEventosComponent,
    EclipseEventos2Component,
    EclipseEventos3Component,
    EclipseEventos4Component,
    EclipseEventos5Component
  ],
  entryComponents:[
    /* Sub componente */
    PrincipalEventosComponent,
    EclipseEventosComponent,
    EclipseEventos2Component,
    EclipseEventos3Component,
    EclipseEventos4Component,
    EclipseEventos5Component
  ],
  imports: [
    CommonModule,
    IonicModule,
    DescriptionModuleModule,
  ]
})
export class EclipseModuleModule { }
