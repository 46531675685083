import { Component, OnInit, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import {ModalController} from '@ionic/angular';
import Swal2 from 'sweetalert2';
import{FingerprintAIO}from "@ionic-native/fingerprint-aio/ngx";
import {ConfirmEmailPage} from 'src/app/components/email-confirm/confirm-email.page';
import { AnyRecord } from 'dns';
import {RequestService} from "src/app/services/request/request.service";
@Component({
  selector: 'app-convenios',
  templateUrl: './convenios.component.html',
  styleUrls: ['./convenios.component.scss'],
})
export class ConveniosComponent implements OnInit {

  public referencia:any;
  public CIE:any;
  public concepto: any;
  public amount: any;
  public cashierStep2:boolean=false;
  public backgroundImage: any;
  timestamp: Date = new Date();
  formattedTimestamp: string; 
  @Input() Cajero: boolean=false;
  @Input() balance:any;
  @Input() profile_id: boolean=false;
  @Input() profileinfo:any;
  caracteres:any;
  filteredConvenios: Array<any>;
  public filteringConvenios: boolean =false;
  public items: Array<{ image: string, label1: string, label2: string, type:string }> = [
    {
      image: 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Convenios/american_express.png',
      label1: 'American Express.',
      label2: '000081306',
      type: '15 caracteres numéricos',
    },
    {
      image: 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Convenios/AT%26T_logo_2016.svg.png',
      label1: 'AT&T.',
      label2: '001436929',
      type: '10 caracteres numéricos'
    },
    {
      image: 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Convenios/BradesCard.png',
      label1: 'BradesCard.',
      label2: '001042378',
      type: '16 caracteres numéricos'
    },
    {
      image: 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Convenios/CAME-Horizontal_(Color).png',
      label1: 'CAME.',
      label2: '000734128',
      type: '1 a 20 caracteres alfanuméricos'
    },
    {
      image: 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Convenios/CFE.png',
      label1: 'CFE.',
      label2: '000578869',
      type: '20 caracteres numéricos'
    },
    {
      image: 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Convenios/el-palacio-de-hierro.png',
      label1: 'El Palacio de Hierro.',
      label2: '000516221',
      type: '16 caracteres numéricos'
    },
    {
      image: 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Convenios/HermanosBatta.png',
      label1: 'Hermanos Batta.',
      label2: '001293451',
      type: '1 a 18 caracteres alfanuméricos'
    },
    {
      image: 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Convenios/infonavit-logo.jpg',
      label1: 'Infonavit.',
      label2: '000828289',
      type: '10 caracteres numéricos'
    },
    {
      image: 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Convenios/Kueski.png',
      label1: 'Kueski.',
      label2: '001356976',
      type: '1 a 17 caracteres alfanuméricos'
    },
    {
      image: 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Convenios/liverpool_logo.png',
      label1: 'Liverpool.',
      label2: '000084946',
      type: '14 a 16 caracteres alfanuméricos'
    },
    {
      image: 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Convenios/Logo_CDMX.png',
      label1: 'CDMX.',
      label2: 'Pago a la tesorería CDMX',
      type: '20 carácteres alfanuméricos'
    },
    {
      image: 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Convenios/Logo_de_CIBanco.svg',
      label1: 'CI Banco.',
      label2: '001247913',
      type: '10 carácteres numéricos'
    },
    {
      image: 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Convenios/Logo_del_SAT.svg.png',
      label1: 'SAT.',
      label2: 'Pago referenciado SAT.',
      type: '20 caracteres alfanuméricos'
    },
    {
      image: 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Convenios/Logo_Movistar.svg.png',
      label1: 'Movistar',
      label2: '000657409',
      type: '18 caracteres numéricos'
    },
    {
      image: 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Convenios/Logotipo_izzi_.png',
      label1: 'Izzi Telecom',
      label2: '001294350',
      type: '10 caracteres numéricos'
    },
    {
      image: 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Convenios/mexigas.jpeg',
      label1: 'Consorcio Mexi-gas',
      label2: '000084704',
      type: '20 caracteres numéricos'
    },
    {
      image: 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Convenios/Sears.png',
      label1: 'Sears',
      label2: '000653837',
      type: '12 a 13 caracteres alfanuméricos'
    },
    {
      image: 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Convenios/Sky_Group_Logo_2020.png',
      label1: 'Sky',
      label2: '000084624',
      type: '12 caracteres numéricos'
    },
    {
      image: 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Convenios/Telcel.png',
      label1: 'Telcel',
      label2: '000182251',
      type: '13 caracteres numéricos'
    },
    {
      image: 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Convenios/Telmex.png',
      label1: 'Telmex',
      label2: '000035241',
      type: '11 caracteres numéricos'
    },
    {
      image: 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Convenios/Totalplay-Logo-Vector.svg-.png',
      label1: 'TotalPlay',
      label2: '001278800',
      type: '10 a 17 caracteres alfanuméricos'
    },

    // Add more items with different images and labels here...
  ];
  constructor(private datePipe: DatePipe,
    public modalController: ModalController,
    private faio:FingerprintAIO,
    public request:RequestService) { }

  ngOnInit() {
    this.getprofile();
    console.log('t', this.timestamp);
    
    this.backgroundImage='https://laniakea-multimedia.s3.amazonaws.com/commercial_cards.png';}
// Handle the search bar input change
onSearchChange(searchValue: string) {
  if (searchValue) {
    this.filteringConvenios = true;

    // Filter the items based on the search input
    this.filteredConvenios = this.items.filter((item) => {
      return (
        item.label1.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.label2.toLowerCase().includes(searchValue.toLowerCase())
      );
    });
  } else {
    this.filteringConvenios = false;
  }
}

selectService(item, type){
  console.log(item);
  console.log(type);
  this.CIE=item;
  this.caracteres=type;
  this.onSearchChange(item);


}
dismiss(){
  this.modalController.dismiss();
}
Pay(){
  if(!this.CIE){
    Swal2.fire({
      title: 'Servicio o impuesto faltante',
      text: 'Porfavor selecciona un servicio o impuesto.',
      icon: 'error',
      confirmButtonText: 'Entendido',
      color: '#ffffff',
      background: '#010f4e',
    });
    return;
  }
  if(!this.CIE){
    Swal2.fire({
      title: 'Servicio o impuesto faltante',
      text: 'Porfavor selecciona un servicio o impuesto.',
      icon: 'error',
      confirmButtonText: 'Entendido',
      color: '#ffffff',
      background: '#010f4e',
    });
    return;
  }
  if(!this.amount){
    Swal2.fire({
      title: 'Monto faltante',
      text: 'Porfavor ingresa un monto.',
      icon: 'error',
      confirmButtonText: 'Entendido',
      color: '#ffffff',
      background: '#010f4e',
    });
    return;
  }
  if(!this.referencia){
    Swal2.fire({
      title: 'Referencia faltante',
      text: 'Porfavor ingresa una referencia.',
      icon: 'error',
      confirmButtonText: 'Entendido',
      color: '#ffffff',
      background: '#010f4e',
    });
    return;
  }
  if(!this.concepto){
    Swal2.fire({
      title: 'Concepto faltante',
      text: 'Porfavor ingresa un concepto.',
      icon: 'error',
      confirmButtonText: 'Entendido',
      color: '#ffffff',
      background: '#010f4e',
    });
    return;
  }
  this.Authenticate('CIE');
}
next(){
  if(this.amount && this.concepto){
    this.Authenticate('cajero');

  }else{
    Swal2.fire({
      title: 'Monto y/o concepto faltante',
      text: 'Porfavor selecciona un monto y/o concepto.',
      icon: 'error',
      confirmButtonText: 'Entendido',
      color: '#ffffff',
      background: '#010f4e',
    });
  }
  
}
selectAmount(amount){


  // Check if the amount is greater than 7500
  if(this.amount > 7500){
    Swal2.fire({
      icon: 'error',
      title: 'Monto muy grande',
      text: 'El monto es muy grande, por el momento no lo soportamos, por favor selecciona un monto menor.',
      confirmButtonText: 'Aceptar',
      color: '#ffffff',
      background: '#010f4e',
    });
  }
  // If you need to do something if the amount is less or equal to 7500, else block goes here.
  else {
    console.log(amount);
    this.amount = amount;
    // Proceed with the rest of your code for amounts <= 7500
  }
}

Authenticate(type){
  this.faio.isAvailable().then(()=>{
    this.faio.show({}).then((val)=>{
      alert(JSON.stringify(val));
      if(type==='cajero'){
        Swal2.fire({
          title: 'Retiro creado',
          text: `El retiro ha sido creado exitosamente. Revisa tu correo electrónico: ${this.profileinfo.user.email}.`,
          icon: 'success',
          confirmButtonText: 'OK',
          color: '#ffffff',
          background: '#010f4e',
        });
        this.cashierStep2=true;
        this.formattedTimestamp= this.datePipe.transform(this.timestamp, 'dd MMM yyyy HH:mm');
        console.log(this.formattedTimestamp);
      }else if(type==='CIE'){
        if (this.balance < this.amount) {
          Swal2.fire({
            title: 'Error',
            text: 'Balance insuficiente.',
            icon: 'error',
            confirmButtonText: 'OK',
            color: '#ffffff',
            background: '#010f4e',
          });
          return;
          }
      
        let data = {
          email: this.profileinfo.user.email,
          monto: this.amount,
          divisa: 'MXN'
        };
        
        // Step 1: Request email confirmation
        this.request.createRequestPost('send-email-retiros', data)
          .then(response => {
          console.log('response', response);
          const responseData = JSON.parse(response);
          console.log('response code', responseData.code);
          if (responseData.code === 200) {
          const modal = this.modalController.create({
            component: ConfirmEmailPage,
            cssClass: 'detailsInModal',
            swipeToClose: true,
            componentProps: {
            amount: this.amount,
            currency: 'MXN',
            destination: this.referencia,
            concepto: this.concepto,
            email: this.profileinfo.user.email,
            profile_id: this.profile_id,
            metodo: 'CIE'
            },
          }).then(modal => {
            modal.onDidDismiss().then(data => {
            // Handle modal dismissal
            });
            modal.present();
          });
        
          // Email confirmation sent successfully
          console.log("Profile info and user exist. Displaying alert...");
        
          Swal2.fire({
            title: 'Confirmacion de Correo Electronico',
            text: `Confirmacion de retiro enviada a ${this.profileinfo.user.email}. 
            Monto a retirar: $${this.amount} MXN`,
            icon: 'success',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
          }).then(() => {
            console.log("Alert displayed successfully.");
          }).catch(error => {
            console.error("Error displaying alert:", error);
          });
        
        }
          })
          .catch(error => {
          console.error("Error:", error);
          // Handle network errors or other unexpected errors here
          });
      }
    
    },(err)=>{
      alert(JSON.stringify(err));
    })
  },(err)=>{
    alert("fingerprint not available")
  })

}
getprofile() {
  this.request.createRequestGet("profile-all", null, { id: this.profile_id })
      .then((data => {
          this.profileinfo = JSON.parse(data);
          console.log("Successfully fetched profile info.");
          console.log('ppr', this.profileinfo);
          console.log("Profile info:", this.profileinfo);
          console.log("Email:", this.profileinfo.user.email);

  
      }))
      .catch((error) => {
          console.error("Error fetching profile info:", error);
      });
}
}
