import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {ModulesLockComponent} from "../../go-ecosystem/modules-lock/modules-lock.component";
import { RequestService } from 'src/app/services/request/request.service';
import Swal2 from 'sweetalert2';
import { Capacitor } from '@capacitor/core';
@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent implements OnInit {
  @Input() email: any;
  @Input() id: number;
  
private password: any;
private password_confirmation:any;
  constructor(public modalController:ModalController, private request: RequestService,) { }

  ngOnInit() {
    console.log('e', this.email);
    console.log('e2', this.id);

  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }
  async onSaveClick() {
    this.request.createRequestPost('reset-password', { id: this.id, email: this.email, password: this.password, password_confirmation: this.password_confirmation })
    .then(response => {
        if (response) {
          if (Capacitor.getPlatform() !== 'web'){
          const responseData = JSON.parse(response);
          response=responseData;
          }
          if (response.code === 200) {
          this.sendNotification('Contraseña actualizada', 'Tu contraseña ha sido actualizada exitosamente');
          this.dismiss();
            console.log('After API call:', response);  // Log the received response
            this.dismiss();
          }else{
            Swal2.fire({
              title: 'ERROR',
              text: 'ERROR AL CAMBIAR CONTRASEÑA',
              icon: 'error',
              confirmButtonText: 'Entendido',
              color: '#ffffff',
              background: '#010f4e',
            });
          }
        } else {
            console.error('No response from API call.');
        }
    })
    .catch(error => {
        console.error('Error from API call:', error);  // Log error from API call
    });
}

sendNotification(title, body) {
   
  return new Promise((resolve, reject) => {
    const notification = {
      title: title,
      body: body,
      user_id:this.id,

    };

    this.request.createRequestPost('GreatAtractorNotifications', notification)
      .then((data) => {
        Swal2.fire({
          title: 'Vínculo generado',
          text: 'Nueva contraseña reiniciada exitosamente',
          icon: 'success',
          confirmButtonText: 'Entendido',
          color: '#ffffff',
          background: '#010f4e',
        });
        resolve(data);
      }).catch((error) => {
        console.error('Failed to send notification:', error);
        reject(error);
      });
  });
}

}
