import { MediaItem } from "../../../Media/media-item";
import { PublicationClass } from "../../publication-class";

export class StatePublication extends PublicationClass {
    //Multimedia
    doesItHaveMultimedia: boolean;
    multimediaCounter: number;
    multimediaContainer: Array<MediaItem>;
    title: string;
    constructor(state?:any, media?: MediaItem){
        super();
        if (state && media) {
            this.title = (state.title)? state.title : "";
        } else {
            //State
            this.title = "";
            //Multimedia
            this.doesItHaveMultimedia = false;
            this.multimediaCounter = 0;
            this.multimediaContainer = new Array<MediaItem>();
        }

    }

    setMultimedia( mediaItem ){
        this.multimediaContainer.push(mediaItem);
        this.doesItHaveMultimedia = true;
    }

    deleteMultimedia(index:number){
        this.multimediaContainer.splice(index,1);
        if(this.multimediaContainer.length == 0){
            this.doesItHaveMultimedia = false;
        }
    }

    getPublicationsDataToSend(){
        let data = {
            doesItHaveMultimedia: this.doesItHaveMultimedia,
            publication_type: this.publication_type,
            data:{
                title: this.title
            }
        }
        return data;
    }
}
