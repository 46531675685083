import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CameraServiceService } from 'src/app/services/multimedia/camera-service.service';
import { MediaItem } from 'src/app/Models/Classes/Media/media-item';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import { PublicationService } from 'src/app/services/publications/publication.service';
import { Utils } from 'src/app/Models/Classes/utils';
import * as uuid from 'uuid';
import {Filesystem, FilesystemDirectory} from '@capacitor/filesystem';
import { Capacitor, Plugins } from '@capacitor/core';
import { isVideo,isImage } from 'src/app/utils';
import Swal2 from 'sweetalert2';
import {RequestService} from '../../services/request/request.service';
import {Router} from "@angular/router";
import {UploadMultimediaService} from "../../services/upload/upload-multimedia.service";
import { CameraResultType, CameraSource, CameraOptions} from '@capacitor/camera';

import { ChangeDetectorRef } from '@angular/core';
const { Camera } = Plugins;
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { v4 as uuidv4 } from 'uuid';
import { File } from '@ionic-native/file/ngx';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';





declare var MediaRecorder;

defineCustomElements(window);
declare var navigator: any;
declare var ImagePicker: any;
@Component({
  selector: 'app-history-view',
  templateUrl: './history-view.component.html',
  styleUrls: ['./history-view.component.scss'],
})



export class HistoryViewComponent implements OnInit, OnDestroy {
  stream;
  isRecording = false;
  safeVideoUrl: SafeResourceUrl;
  public multimedia: Array<MediaItem> = new Array<MediaItem>();
  @Input() publication: any;
// Add mediaType property to the class
mediaType: string;

  image: any;
 // video: any;
  ruta: Array<object>=[];
  historias: Array<object> =[];
  titulo='';
  allowEdit: boolean=false;




  constructor(

    public modalController: ModalController,
    private router: Router,
    private cameraService: CameraServiceService,
    public formservice: FormServiceService,
    private publicationService: PublicationService,
    private request: RequestService,
    private utils: Utils,
    private uploadMultimedia: UploadMultimediaService,
    private ref: ChangeDetectorRef,
    private file: File, 
    private sanitizer: DomSanitizer,
    ) { }
ngOnDestroy() {
  document.body.classList.add('dark');
  this.stopMedia();
}
  ngOnInit() {
    this.formservice.history = [];
    this.image = '';
    this.uploadMultimedia.image = '';
    document.body.classList.remove('dark');
  }
  ngAfterViewInit(){
     setTimeout(() => {
    this.camera();
  }, 999);
  }
  camera(){
    const video = document.getElementById('video') as HTMLVideoElement;
    const canvas = document.getElementById('canvas') as HTMLCanvasElement;
    const recordButton = document.getElementById('record');
    const takePhotoButton = document.getElementById('take-photo');
    const ctx = canvas.getContext('2d');
    
    let mediaRecorder;
    let chunks = [];

    
    // Access the camera
    navigator.mediaDevices.getUserMedia({ video: true, audio: true })
        .then(stream => {
          this.stream = stream;
            video.srcObject = stream;
    
            mediaRecorder = new MediaRecorder(stream);
    
            mediaRecorder.ondataavailable = event => {
                chunks.push(event.data);
            };
    
            mediaRecorder.onstop = () => {
         
                const blob = new Blob(chunks, { 'type': 'video/mp4;' });
                chunks = [];
                const videoURL = URL.createObjectURL(blob);
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

                const a = document.createElement('a');
                a.href = videoURL;
                a.download = 'video.mp4';
                document.body.appendChild(a);
                a.click();
                const currentTime = new Date().toISOString().replace(/:/g, '-').replace(/\./g, '-');
                Swal2.fire({
                  title: '¿Deseas publicar tu media?',
                  html: `<video src="${videoURL}" alt="Media" style="width: 100%; max-width: 300px;"autoplay controls playsinline></video>`,
                  icon: 'success',
                  confirmButtonText: 'Si, publcar <ion-icon name="cloud-upload"></ion-icon>',
                  color: '#ffffff',
                  background: '#010f4e',
                }).then((result) => {
                  if (result.isConfirmed) {
                     this.post(blob, currentTime);
                  }else{
                    this.back();
                  }
                });
                if(this.formservice.normalpublication.blobsMultimedia.length < 1){
                  this.formservice.normalpublication.blobsMultimedia[0]={blob:blob,isPremium:false, filename: currentTime + '.jpeg'};
                }else {
                  this.formservice.normalpublication.blobsMultimedia.push({blob:blob,isPremium:false, filename: currentTime + '.jpeg'});
                } 
                this.formservice.Capacitor_convertFileSrc_video=videoURL;
            };
        })
        .catch(error => {
            console.error('Error accessing the camera', error);
        });
    
    // Take photo
    takePhotoButton.addEventListener('click', () => {

     //   canvas.width = video.videoWidth * 0.10;
     //   canvas.height = video.videoHeight* 0.10;
  //   canvas.width=69;
    // canvas.height=69;
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        const dataUrl = canvas.toDataURL('image/png');
        const a = document.createElement('a');
        a.href = dataUrl;
        a.download = 'photo.png';
        document.body.appendChild(a);
        a.click();
        canvas.toBlob(blob => {
          const currentTime = new Date().toISOString().replace(/:/g, '-').replace(/\./g, '-');
          Swal2.fire({
            title: '¿Deseas publicar tu media?',
            html: `<img src="${blob}" alt="Media" style="width: 100%; max-width: 300px;">`,
            icon: 'success',
            confirmButtonText: 'Si, publcar <ion-icon name="cloud-upload"></ion-icon>',
            color: '#ffffff',
            background: '#010f4e',
          }).then((result) => {
            if (result.isConfirmed) {
               this.post(blob, currentTime);
            }else{
              this.back();
            }
          });
          if(this.formservice.normalpublication.blobsMultimedia.length < 1){
            this.formservice.normalpublication.blobsMultimedia[0]={blob:blob,isPremium:false, filename: currentTime + '.jpeg'};
          }else {
            this.formservice.normalpublication.blobsMultimedia.push({blob:blob,isPremium:false, filename: currentTime + '.jpeg'});
          } 
        }, 'image/png');
        this.formservice.Capacitor_convertFileSrc=dataUrl;
    });
    
    // Record video
    recordButton.addEventListener('click', () => {
        if (this.isRecording) {
          this.formservice.recording_icon='radio-button-on';
            mediaRecorder.stop();
            //recordButton.textContent = 'Record';
        } else {
          this.formservice.recording_icon='square';
            mediaRecorder.start();
            //recordButton.textContent = 'Stop Recording';
        }
        this.isRecording = !this.isRecording;
    });
    

  }
  stopMedia(){
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
      const video = document.getElementById('video') as HTMLVideoElement;
      if(video){
        video.srcObject = null;
      }
    
    }

  }
  public isVideo(url: string) {
    Capacitor.convertFileSrc(url);
    return isVideo(url);
  }

  public isImage(url: string){
    Capacitor.convertFileSrc(url);
    return isImage(url);
  }

  getText(event: { target: { value: string; }; }){
    this.titulo = event.target.value;
  }

  //para archivos desde el carrete
  // Add the 'name' parameter to the function signature
  
  async setMultimediaCarrete(mediaBlob: Blob, name: string): Promise<{ filename: string; blob: Blob }> {
    const myId = uuidv4();
  
    const newPath = this.file.dataDirectory + `image_history_publication_${myId}.${name.substr(name.lastIndexOf('.') + 1)}`;
    console.log('About to call this.file.writeFile() with newPath:', newPath);
    await this.file.writeFile(this.file.dataDirectory, `image_history_publication_${myId}.${name.substr(name.lastIndexOf('.') + 1)}`, mediaBlob, { replace: true });
  
    const mediaItemConverted = new MediaItem({
      type: `image/${name.substr(name.lastIndexOf('.') + 1)}`,
      mediaPath: newPath.substr(0, newPath.lastIndexOf('/') + 1),
      name,
      id: null,
      index: 0,
      route: null,
      status: "Esperando Media",
      size: mediaBlob.size, // Use the size of the mediaBlob
      url: null,
      localUrl: newPath, // Use newPath as localUrl
      is_premium: 0,
    });
  
    console.log('multimediaitemfotoconverted', mediaItemConverted);
    this.multimedia.push(mediaItemConverted);
    console.log('Multimedia array after pushing item:', this.multimedia);
    this.formservice.multimedia = this.multimedia;
    this.image = newPath;
    this.ruta.push(this.image);
    this.formservice.history.push(History);
  
    return {
      filename: name,
      blob: mediaBlob,
    };
  }
  
  
  
  
  
  
  
  //para archivos desde camara
  setMultimediaCamera(mediaItem: MediaItem) {
    this.uploadMultimedia.image = Capacitor.convertFileSrc(mediaItem.mediaPath + mediaItem.nameFile);
    this.uploadMultimedia.readBlobFile(mediaItem.mediaPath + mediaItem.nameFile)
  .then(() => {
    this.image = this.uploadMultimedia.image;
    console.log("finalizado");
    console.log(this.image);
    this.uploadMultimedia.post().then(({ link, mimeType }) => {
      // Do something with the link and type
    }).catch((error) => {
      console.error("Error uploading file:", error);
    });
  })
  .catch((error) => {
    console.error("Error reading blob file:", error);
  });
  }
  
  setMultimediaVideo(mediaItem: MediaItem) {
    this.uploadMultimedia.image = Capacitor.convertFileSrc(mediaItem.mediaPath + mediaItem.nameFile);
    this.uploadMultimedia.readBlobFile(mediaItem.mediaPath + mediaItem.nameFile)
  .then(() => {
    this.image = this.uploadMultimedia.image;
    console.log("finalizado");
    console.log(this.image);
    this.uploadMultimedia.post().then(({ link, mimeType }) => {
      // Do something with the link and type
    }).catch((error) => {
      console.error("Error uploading file:", error);
    });
  })
  .catch((error) => {
    console.error("Error reading blob file:", error);
  });
  }
  
 // async setMultimediaCarretevideo(mediaItem: MediaItem) {
    /*console.log(mediaItem);
    this.multimedia.push(mediaItem);
    this.image= Capacitor.convertFileSrc (mediaItem.mediaPath + mediaItem.nameFile);
    console.log(' this.video',  this.image);
    this.ruta.push(this.image);
    console.log('this.ruta', this.ruta);*/
  //  const myId = uuid.v4();
 //   const savedVideoFile = await Filesystem.writeFile({
 //     path: 'video_history_publication_'+myId+'.'+mediaItem.type.replace('video/',''),
 //     data: mediaItem.mediaPath,
 //     directory: FilesystemDirectory.Data
 //   });
 //   const savedVideo = Capacitor.convertFileSrc(savedVideoFile.uri);
  //  const name = savedVideoFile.uri.substr(savedVideoFile.uri.lastIndexOf('/') + 1);
    //const mediaItemConverted = new MediaItem({
//      type: 'video/'+ name.substr(name.lastIndexOf('.') + 1),
  //    mediaPath: savedVideoFile.uri.substr(0, savedVideoFile.uri.lastIndexOf('/') + 1),
  //    name
    //});
//    console.log('multimediavideoitemconverted',mediaItemConverted);
 //   this.multimedia.push(mediaItemConverted);
   // this.formservice.multimedia = this.multimedia;
 //   this.image = savedVideo;
 //   this.ruta.push(this.image);
 //   this.formservice.history.push(History);
 // }

 // public takePhoto() {
   // this.cameraService
 //   .askForMediCapture()
//    .then((data:[MediaItem,String])=>{
  //    if(data[1].toString() === 'camara'){
    //    this.setMultimediaCamera(data[0]);
  //    }
   //   else if(data[1].toString()==='camvideo'){
    //    console.log("Estoy en video");
   //     this.setMultimediaVideo(data[0]);
     // }

//    }).catch((error)=>{
  //    Swal2.fire({
   //           title: 'Error take photo',
     //         text: error,
     //         icon: 'warning',
   //           confirmButtonText: 'Entendido',
   //           color: '#ffffff',
     //         background: '#010f4e',
   //         });

    //});
 // }

  back() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  public async post(blobfile: Blob, filename: string) {
    // Log the multimedia array before posting
  console.log('multimedia array before posting:', this.multimedia);
    this.publication.multimediaContainer= this.multimedia;
    console.log('posting the next publication', this.publication);
    this.utils.loadingPresent().then(() => {
      this.publicationService
        .postPublication({publication_type:'state',data:{title:this.titulo}})
        .then((data: any) => {
          if (Capacitor.getPlatform() !== 'web') {
            data = JSON.parse(data); // En una Historia el id devuelto debe ser el del estado recientemente agregado
          }else{
            if (typeof data === 'string') {
              data = JSON.parse(data);
                 console.log('json result publication service.ts', data);
               
               }else {
                data = data; 
               }
          }
          const publication_ids: Array<number> = [];
          // console.log('data from post history-view', data);
          publication_ids.push(data.id);
          if (data.publication_type == 'story' && data.state_id != null) {
            publication_ids.push(data.state_id);
          }
          console.log('Entrando a post de multimedia');

        //En una historia el multimedia container lo deberia tener un estado
        //this.utils.alertPresent('En Preparación', 'Le notificaremos cuando pueda ver su publicación', 'OK');
        console.log("posting the next array multimedia", this.publication.multimediaContainer)
        this.uploadMultimedia.post(blobfile, filename).then(({ link, mimeType }) => {
          const ruta = link;
            console.log('ruta', ruta);
            
            if (Capacitor.getPlatform() !== 'web') {
            this.updateStatusPublication(publication_ids).then(() => {
            })
            .catch((error) => {
              Swal2.fire({
                title: 'Error 2 update status publication',
                text: 'Ocurrió un error inseperado, si persiste comunícate con el soprte de Laniakea',
                icon: 'warning',
                confirmButtonText: 'Entendido',
                color: '#ffffff',
                background: '#010f4e',
              });
            });
            }
            
               /*this.publicationService
                  .getPublicationByID(publication_ids[0], true)
                  .then((publication) => {
                   
                    // De ser una historia la publicacion recien creada se debe agregar o reemplazar la historia actual
                    console.log('publicationnnnnnn', publication);
                    if (publication.publication_type === 'story') {
                       */
                      /*this.publicationService.updatePublicationList(
                        "MyStoriesList",
                        publication,
                        true
                      );*/
                      //createpost request
                      let pub_type = '';
                      //console.log(this.multimedia[0].typeData);
                      if(mimeType.includes("image")) {
                        pub_type= 'imagen';
                      }else if(mimeType.includes("video")){
                        pub_type= 'video';
                      }
                      const objmedia = {
                        route: ruta,
                        size: 800,
                        type:pub_type,
                        publication_type: 'story',
                        state_id: publication_ids[1],
                        publication_id: publication_ids[0],
                        is_premium: false
                      };
                      console.log('objmedia', objmedia);
                      this.request.createRequestPost('publications.multimedia',objmedia).then((final_data) => {
                      console.log("request history final data",final_data);
                        this.utils.loadingDismiss();
                        this.back()
                      }).catch((error) => {

                        this.utils.loadingDismiss();
                        Swal2.fire({
                          title: 'Error al subir multimedia!',
                          text: error,
                          icon: 'warning',
                          confirmButtonText: 'Entendido',
                          color: '#ffffff',
                          background: '#010f4e',
                        });

                      });
                    /* } else {
                      this.publicationService.updatePublicationList(
                        'MyFeedList',
                        publication,
                        true
                      );
                      this.publicationService.updatePublicationList(
                        'MyPublicationsList',
                        publication,
                        true
                      );
                    }
                 })
                  .catch((error) => {
                    Swal2.fire({
                      title: 'Error update status publication',
                      text: 'Ocurrió un error inseperado, si persiste comunícate con el soprte de Laniakea',
                      icon: 'warning',
                      confirmButtonText: 'Entendido',
                      color: '#ffffff',
                      background: '#010f4e',
                    });
                  });*/
         
          });
        })
        .catch((error) => {
          Swal2.fire({
            title: 'Error post publication',
            text: 'Ocurrió un error inseperado, si persiste comunícate con el soprte de Laniakea',
            icon: 'warning',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
          });
          this.utils.loadingDismiss();
        });
    });

  }

  public updateStatusPublication(publication_ids: Array<number>) {
      // Log the publication_ids array
      console.log('publication_ids:', publication_ids);
    return new Promise((resolve, reject) => {
      const status = {
        publication_type: 'publication',
        data: {
          publication_status_id: 1,
        },
      };
      this.publicationService
        .putPublication(status, publication_ids[0])
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  async pickMulti(){
    try {
      const options: CameraOptions = {
        quality: 100,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        source: CameraSource.Photos,
        saveToGallery: false,
      };
      console.log('About to call Camera.getPhoto()');
      const media = await Camera.getPhoto(options);
  
      if (media) {

        console.log('Media picked successfully:', media);
        this.uploadMultimedia.image = media.webPath;
        this.mediaType = media.format === 'jpeg' || media.format === 'png' ? 'image' : 'video';
        this.ref.detectChanges();
      
  
        // Read the file as a Blob
        const response = await fetch(media.webPath);
        const blobfile = await response.blob();
  
        // Log the Blob for debugging purposes
        console.log('fileBlob:', blobfile);
        if (Capacitor.getPlatform() !== 'web') {
        const filename = media.path.substring(media.path.lastIndexOf('/') + 1);
        if (this.mediaType === 'image') {
          await this.setMultimediaCarrete(blobfile, filename);
        }else {
          console.error('Error: fileBlob is undefined');
      }
    
      await this.post(blobfile, filename);
    }else{
      const currentTime = new Date().toISOString().replace(/:/g, '-').replace(/\./g, '-');
    const filename = currentTime + '.png';

    
      if (this.mediaType === 'image') {
        await this.setMultimediaCarrete(blobfile, filename);
      }else {
        console.error('Error: fileBlob is undefined');
    }
    Swal2.fire({
      title: '¿Deseas publicar tu media?',
      html: `<img src="${media.webPath}" alt="Media" style="width: 100%; max-width: 300px;">`,
      icon: 'success',
      confirmButtonText: 'Si, publicar <ion-icon name="cloud-upload"></ion-icon>',
      color: '#ffffff',
      background: '#010f4e',
    }).then((result) => {
      if (result.isConfirmed) {
         this.post(blobfile, filename);
      }else{
        this.back();
      }
    });
 

    }
      }
    } catch (error) {
      console.error('Error picking media:', error);
    }
  }  
  
  
    

pickmedia_selection(){
  Swal2.fire({
    title: '¿Qué deseas publicar?',
    html: `Imagen o video`,
    icon: 'success',
    confirmButtonText: 'imagen',
    showCancelButton:true,
    cancelButtonText: 'video',
    color: '#ffffff',
    background: '#010f4e',
  }).then((result) => {
    if (result.isConfirmed) {
     this.pickMulti();
    } else {
 this.pickMedia();
    }
  });
  
}
LaniakeaMediaPicker(){
  Swal2.fire({
    title: 'Selecciona una foto',
    text: `¿Deseas elegir la imagen desde Google Drive o desde tu dispositivo?`,
    icon: 'info',
    //timer: 6900, // Show the alert for 3.9 seconds
    //timerProgressBar: true,
  //	toast: true,
  //	position: 'top-end',
    showCancelButton: true,
    confirmButtonText: 'Google Drive',
    cancelButtonText: 'Este dispositivo',
    color: '#ffffff',
    background: '#010f4e',
    }).then((result) => {
    if (result.isConfirmed) {
     // this.googlePicker();
    }else{
   // this.pickImage();
    }
    });

  }
  
  
  public pickMedia() { 
    if (Capacitor.getPlatform() === 'ios') {
      Swal2.fire({
        title: 'Añade videos en tu dispositivo apple',
        text: 'Para añadir videos optimizados añade el video de tu librería de Fotos a tus Archivos. A continuación la opción optimizada es: Choose File',
        icon: 'info',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
    }
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'video/*';//image/*,
    fileInput.multiple = false;

    fileInput.addEventListener('change', async (event) => {
      const target = event.target as HTMLInputElement;
      const files = target.files;
      if (files && files.length > 0) {
        const media = files[0];
        console.log('Media picked successfully:', media);

        const mimeType = media.type;
        const blobfile = media;
        const filename = media.name;

        // Process the media here
        // ...

        const videoUrl = URL.createObjectURL(blobfile);
        this.safeVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);

        Swal2.fire({
          title: '¿Deseas publicar tu media?',
          html: `<video src="${videoUrl}" alt="Media" style="width: 100%; max-width: 300px;"autoplay controls playsinline></video>`,
          icon: 'success',
          confirmButtonText: 'Si, publcar <ion-icon name="cloud-upload"></ion-icon>',
          color: '#ffffff',
          background: '#010f4e',
        }).then((result) => {
          if (result.isConfirmed) {
             this.post(blobfile, filename);
          }else{
            this.back();
          }
        });
     
      
      } else {
        console.log('Media picking cancelled by the user.');
        // Handle cancellation here (e.g., display a message to the user)
      }
    });

    fileInput.click();
  }

  
 public pickMedia2(): void {
  const options = {
    quality: 80,
    destinationType: navigator.camera.DestinationType.FILE_URI,
    mediaType: navigator.camera.MediaType.ALLMEDIA,
    sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
  };

  navigator.camera.getPicture(
    async (mediaUrl: string) => {
      console.log('Media picked successfully:', mediaUrl);
      this.safeVideoUrl = null; // Reset safeVideoUrl
      this.uploadMultimedia.image = null; // Reset uploadMultimedia.image

      if (this.isImageMediaType(mediaUrl)) {
        // Image media
        this.uploadMultimedia.image = mediaUrl;
      } else if (this.isVideoMediaType(mediaUrl)) {
        // Video media
        this.safeVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(mediaUrl);
      }

      try {
        //const blobfile = await this.convertMediaUriToBlob(mediaUrl);
        const filename = this.getFilenameFromUri(mediaUrl);

        // Process the media here
        // ...

        // Uncomment the line below if you need to upload the media file
        // await this.post(blobfile, filename);
      } catch (error) {
        console.error('Error occurred during media conversion:', error);
      }
    },
    (error: any) => {
      console.log('Error capturing media:', error);
    },
    options
  );
}

private isImageMediaType(mediaUrl: string): boolean {
  // Implement your logic to determine if the media is an image
  // You can use file extensions or other criteria to identify images
  return mediaUrl.endsWith('.jpg') || mediaUrl.endsWith('.jpeg') || mediaUrl.endsWith('.png');
}

private isVideoMediaType(mediaUrl: string): boolean {
  // Implement your logic to determine if the media is a video
  // You can use file extensions or other criteria to identify videos
  return mediaUrl.endsWith('.mp4') || mediaUrl.endsWith('.mov') || mediaUrl.endsWith('.avi');
}



private async convertMediaUriToBlob(mediaUrl: string): Promise<Blob> {
  const response = await fetch(mediaUrl);
  const blob = await response.blob();
  return blob;
}







private getFilenameFromUri(uri: string): string {
  const lastSlashIndex = uri.lastIndexOf('/');
  return uri.substr(lastSlashIndex + 1);
}


  
  
  
  
  
  
}
  
  
  
  

 
  

  // Add the 'name' parameter to the function signature
 
  
    
  

