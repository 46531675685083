import { MediaItem } from "../../Media/media-item";
import { ReactionContainerClass } from "../../Reactions/ReactionContainer/reaction-container-class";
import { SellerProfile } from "../../User/Store/seller-profile";
import { CommentClass } from "../Comments/comment-class";
import { PublicationClass } from "../publication-class";

export class Earth extends PublicationClass {
    //Multimedia
    views: number;
    regalos: number;
    Verified: boolean;
    SaveCounter: any;
    ShareCounter: any;
    IsReservaCalendario: boolean = false;
    zonaHoraria: string;
    latitud:number;
    longitud:number;
    ReservaCalendario:Array<any>=[];
    doesItHaveMultimedia: boolean;
    multimediaCounter: number;
    multimediaContainer: Array<MediaItem>;
    public multimedia: Array<MediaItem> = new Array<MediaItem>()
    //Earth Publication Data
    title: string;
    description: string;
    ubicacion: string;
    quienpuedever:Array<string>=["Para todos"];
    publication_type: string;
    price: number;
    is_feed: boolean;

    //earht1-----------------------------------------------------------
    tipo: string;
    lugar: string;
    //earht2-----------------------------------------------------------
    alojamiento: string;
    alojamientoExclusivo: string;
    capacidad: string;
    //earht3-----------------------------------------------------------
    bedrooms: number=0;
    bathroom: number=0;
    king_bed: number =0;
    queen_bed: number=0;
    matrimonial_bed: number=0;;
    individual_bed: number=0;;
    sofa_bed: number=0;;
    sofa: number=0;;
    inflatable_mattress: number=0;;
    litera: number=0;;
    mattress_on_the_floor: number=0;;
    bed_for_children: number=0;;
    crib: number=0;;
    water_bed: number=0;;
    hamaca: number=0;;
    //earht4-----------------------------------------------------------
    direction: string;
    interior_number: number;
    exterior_number: number;
    postal_code: number;
    col: string;
    city: string;
    state: string;
    //earht5----------------------------------------------------------------
    personal_clean: string;
    wifi: string;
    kitchen_room: string;
    free_parking: string;
    tv: string;
    air_conditioning: string;
    washing_machine: string;
    drying_machine: string;
    iron: string
    swimming_pool: string;
    pets: string;
    smoking_is_allowed: string;
    events_are_allowed: string;
    additional_details: string;
    //earth calendario
    fecha_inicial: string;
    fecha_final: string;
    disponibilidad_dia:Array<any>=[];

    constructor(publication?: any, userProfile?: SellerProfile, reactionContainer?: Array<ReactionContainerClass>, ownReaction?: any) {
        super(publication, userProfile, reactionContainer, ownReaction);
        if (publication && publication.container) {
            //Multimedia
            this.doesItHaveMultimedia = (publication.multimediaCounter == 0) ? false : true;
            this.multimediaCounter = (publication.container.multimediaCounter) ? parseInt(publication.container.multimediaCounter) : 0;
            this.transformMediaToMediaItem(publication.container.multimediaContainer).then((multimedia) => {
                this.multimediaContainer = multimedia;
            });

            //Earth Publication Data
            this.views = (publication.container.views) ? publication.container.views : null;
            this.disponibilidad_dia=(publication.container.disponibilidad_dia) ? publication.container.disponibilidad_dia : null;
            this.regalos = (publication.container.regalos) ? publication.container.regalos : null;
            this.Verified = (publication.container.Verified) ? publication.container.Verified : null;
            this.SaveCounter = (publication.container.SaveCounter) ? publication.container.SaveCounter : null;
            this.ShareCounter = (publication.container.ShareCounter) ? publication.container.ShareCounter : null;
            this.latitud=(publication.container.latitud) ? publication.container.latitud : null;
            this.longitud=(publication.container.longitud) ? publication.container.longitud : null;
            this.title = (publication.container.title) ? publication.container.title : null;
            this.description = (publication.container.description) ? publication.container.description : null;
            this.price = (publication.container.price) ? publication.container.price : null;
            this.quienpuedever = (publication.container.quienpuedever) ? publication.container.quienpuedever : null;
            this.is_feed = (publication.container.is_feed) ? publication.container.is_feed : null;
            //earht1-----------------------------------------------------------
            this.lugar = (publication.container.lugar) ? publication.container.lugar : null;
            this.tipo = (publication.container.tipo) ? publication.container.tipo : null;

            //earht2-----------------------------------------------------------
            this.alojamiento = (publication.container.alojamiento) ? publication.container.alojamiento : null;
            this.alojamientoExclusivo = (publication.container.alojamientoExclusivo) ? publication.container.alojamientoExclusivo : null;
            this.capacidad = (publication.container.capacidad) ? publication.container.capacidad : null;
            this.bedrooms = (publication.container.bedrooms) ? publication.container.bedrooms : null;
            this.bathroom = (publication.container.bathroom) ? publication.container.bathroom : null;

            //earht3-----------------------------------------------------------
            this.king_bed = (publication.container.king_bed) ? publication.container.king_bed : null;
            this.queen_bed = (publication.container.queen_bed) ? publication.container.queen_bed : null;
            this.matrimonial_bed = (publication.container.matrimonial_bed) ? publication.container.matrimonial_bed : null;
            this.individual_bed = (publication.container.individual_bed) ? publication.container.individual_bed : null;
            this.sofa_bed = (publication.container.sofa_bed) ? publication.container.sofa_bed : null;
            this.sofa = (publication.container.sofa) ? publication.container.sofa : null;
            this.inflatable_mattress = (publication.container.inflatable_mattress) ? publication.container.inflatable_mattress : null;
            this.litera = (publication.container.litera) ? publication.container.litera : null;
            this.mattress_on_the_floor = (publication.container.mattress_on_the_floor) ? publication.container.mattress_on_the_floor : null;
            this.bed_for_children = (publication.container.bed_for_children) ? publication.container.bed_for_children : null;
            this.crib = (publication.container.crib) ? publication.container.crib : null;
            this.water_bed = (publication.container.water_bed) ? publication.container.water_bed : null;
            this.hamaca = (publication.container.hamaca) ? publication.container.hamaca : null;

            //earht4-----------------------------------------------------------
            this.direction = (publication.container.direction) ? publication.container.direction : null;
            this.interior_number = (publication.container.interior_number) ? publication.container.interior_number : null;
            this.exterior_number = (publication.container.exterior_number) ? publication.container.exterior_number : null;
            this.postal_code = (publication.container.postal_code) ? publication.container.postal_code : null;
            this.col = (publication.container.col) ? publication.container.col : null;
            this.city = (publication.container.city) ? publication.container.city : null;
            this.state = (publication.container.state) ? publication.container.state : null;

            //earht5-----------------------------------------------------------
            this.personal_clean = (publication.container.personal_clean) ? publication.container.wifi : null;
            this.wifi = (publication.container.wifi) ? publication.container.wifi : null;
            this.kitchen_room = (publication.container.kitchen_room) ? publication.container.kitchen_room : null;
            this.free_parking = (publication.container.free_parking) ? publication.container.free_parking : null;
            this.tv = (publication.container.tv) ? publication.container.tv : null;
            this.air_conditioning = (publication.container.air_conditioning) ? publication.container.air_conditioning : null;
            this.washing_machine = (publication.container.washing_machine) ? publication.container.washing_machine : null;
            this.drying_machine = (publication.container.drying_machine) ? publication.container.drying_machine : null;
            this.iron = (publication.container.iron) ? publication.container.iron : null;
            this.swimming_pool = (publication.container.swimming_pool) ? publication.container.swimming_pool : null;
            this.pets = (publication.container.pets) ? publication.container.pets : null;
            this.smoking_is_allowed = (publication.container.smoking_is_allowed) ? publication.container.smoking_is_allowed : null;
            this.events_are_allowed = (publication.container.events_are_allowed) ? publication.container.events_are_allowed : null;
            this.additional_details = (publication.container.additional_details) ? publication.container.additional_details : null;
            //earth calendario
            this.fecha_inicial = (publication.container.fecha_inicial) ? publication.container.fecha_inicial : null;
            this.fecha_final = (publication.container.fecha_final) ? publication.container.fecha_final : null;

        } else {
            this.longitud=0;
            this.latitud=0;
            //Multimedia
            this.doesItHaveMultimedia = false;
            this.multimediaCounter = 0;
            this.multimediaContainer = new Array<MediaItem>();
            //Earth Publication Data
            this.title = undefined;
            this.description = undefined;
            this.price = undefined;
            this.quienpuedever = undefined;
            this.is_feed = undefined;
            //earht1-----------------------------------------------------------
            this.lugar = undefined;
            this.tipo = undefined;

            //earht2-----------------------------------------------------------
            this.alojamiento = undefined;
            this.alojamientoExclusivo = undefined;
            this.capacidad = undefined;
            //earht3-----------------------------------------------------------
            this.bedrooms = 0;
            this.bathroom = 0;
            this.king_bed = 0;
            this.queen_bed = 0;
            this.matrimonial_bed = 0;
            this.individual_bed = 0;
            this.sofa_bed = 0;
            this.sofa = 0;
            this.inflatable_mattress = 0;
            this.litera = 0;
            this.mattress_on_the_floor = 0;
            this.bed_for_children = 0;
            this.crib = 0;
            this.water_bed = 0;
            this.hamaca = 0;
            //earht4-----------------------------------------------------------
            this.direction = undefined;
            this.interior_number = undefined;
            this.exterior_number = undefined;
            this.postal_code = undefined;
            this.col = undefined;
            this.city = undefined;
            this.state = undefined;
            //earht5-----------------------------------------------------------
            this.wifi = "No";
            this.kitchen_room = "No";
            this.free_parking = "No";
            this.tv = "No";
            this.air_conditioning = "No";
            this.washing_machine = "No";
            this.drying_machine = "No";
            this.iron = "No";
            this.swimming_pool = "No";
            this.pets = "No";
            this.smoking_is_allowed = "No";
            this.events_are_allowed = "No";
            this.personal_clean = "No";
            this.additional_details = undefined;
            //earth calendario
            this.fecha_inicial = undefined;
            this.fecha_final = undefined;
            this.disponibilidad_dia=undefined;
        }
    }

    public updatePublication(publication: any, userProfile: SellerProfile, reactionContainer: Array<ReactionContainerClass>, ownReaction: any) {
        //Multimedia
        this.doesItHaveMultimedia = (publication.multimediaCounter == 0) ? false : true;
        this.multimediaCounter = (publication.multimediaCounter) ? parseInt(publication.multimediaCounter) : this.multimediaCounter;
        this.transformMediaToMediaItem(publication.container.multimediaContainer).then((multimedia) => {
            this.multimediaContainer = multimedia;
        });
        //Publication
        this.id = (publication.id) ? parseInt(publication.id) : this.id;
        this.publication_type = (publication.publication_type_string) ? publication.publication_type_string : this.publication_type;
        this.status_string = (publication.status_string) ? publication.status_string : this.status_string;
        //Interaction
        this.reactionCounter = (publication.reactionCounter) ? parseInt(publication.reactionCounter) : this.reactionCounter;
        this.reactionContainer = reactionContainer;
        this.ownReaction = ownReaction;
        this.commentCounter = (publication.commentCounter) ? parseInt(publication.commentCounter) : this.commentCounter;
        //User
        this.userProfile = userProfile;
        this.corp = (publication.corp) ? publication.corp : this.corp;
        this.is_saved = (publication.is_saved) ? publication.is_saved : this.is_saved;
        this.owner = (publication.owner) ? publication.owner : this.owner;
    }


    setMultimedia(mediaItem) {
        if (this.publication_type == "event") {
            if (this.doesItHaveMultimedia) {
                this.deleteMultimedia(0);
            }
        }
        this.multimediaContainer.push(mediaItem);
        this.doesItHaveMultimedia = true;
    }

    deleteMultimedia(index: number) {
        this.multimediaContainer.splice(index, 1);
        if (this.multimediaContainer.length == 0) {
            this.doesItHaveMultimedia = false;
        }
    }

    getPublicationsDataToSend() {
        let data = {
            doesItHaveMultimedia: this.doesItHaveMultimedia,
            publication_type: this.publication_type,
            data: {
                ubicacion: this.ubicacion,
                longitud:this.longitud.toString(),
                latitud:this.latitud.toString(),
                title: this.title,
                description: this.description,
                quienpuedever:this.quienpuedever.join(","),
                price: this.price,
                is_feed: this.is_feed,
                tipo: this.tipo,
                lugar: this.lugar.toString(),
                alojamiento: this.alojamiento.toString(),
                alojamientoExclusivo: this.alojamientoExclusivo,
                capacidad: this.capacidad,
                bedrooms: this.bedrooms,
                bathroom: this.bathroom,
                king_bed: this.king_bed,
                queen_bed: this.queen_bed,
                matrimonial_bed: this.matrimonial_bed,
                individual_bed: this.individual_bed,
                sofa_bed: this.sofa_bed,
                sofa: this.sofa,
                inflatable_mattress: this.inflatable_mattress,
                litera: this.litera,
                mattress_on_the_floor: this.mattress_on_the_floor,
                bed_for_children: this.bed_for_children,
                crib: this.crib,
                water_bed: this.water_bed,
                hamaca: this.hamaca,
                direction: this.direction,
                interior_number: this.interior_number,
                exterior_number: this.exterior_number,
                postal_code: this.postal_code,
                col: this.col,
                city: this.city,
                state: this.state,
                personal_clean: this.personal_clean,
                wifi: this.wifi,
                kitchen_room: this.kitchen_room,
                free_parking: this.free_parking,
                tv: this.tv,
                air_conditioning: this.air_conditioning,
                washing_machine: this.washing_machine,
                drying_machine: this.drying_machine,
                iron: this.iron,
                swimming_pool: this.swimming_pool,
                pets: this.pets,
                smoking_is_allowed: this.smoking_is_allowed,
                events_are_allowed: this.events_are_allowed,
                additional_details: this.additional_details,
                //fecha_inicial:this.fecha_inicial,
                //fecha_final:this.fecha_final,
                disponibilidad_dia: this.ReservaCalendario,
            }   
        }
        console.log(data);
        return data;
    }

    transformMediaToMediaItem(objectMedia) {
        return new Promise<Array<MediaItem>>((resolve) => {
            let MediaContainer = new Array<MediaItem>();
            if (objectMedia) {
                objectMedia.forEach(media => {
                    MediaContainer.push(new MediaItem(media));
                });
                resolve(MediaContainer);
            }
            else {
                resolve(MediaContainer);
            }

        });
    }
}
