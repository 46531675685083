import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { CompleteInformationComponent } from '../events-publication/complete-information/complete-information.component';
import { InformationComponent } from '../normal-publication/information/information.component';
import { InformationEventComponent } from '../events-publication/information-event/information-event.component';
import { ButtonsModule } from 'src/app/components/common-elements/buttons/buttons-module/buttons.module';
import { ReturnModuleModule } from 'src/app/components/common-elements/return/return-module/return-module.module';


@NgModule({
	declarations: [
		CompleteInformationComponent,
		InformationComponent,
		InformationEventComponent
	],
	exports: [
		CompleteInformationComponent,
		InformationComponent,
		InformationEventComponent
	],
	entryComponents: [
		CompleteInformationComponent,
		InformationComponent,
		InformationEventComponent
	],
	imports: [
		CommonModule,
		IonicModule,
		ButtonsModule,
		ReturnModuleModule
	]
})
export class PublicationDescriptionModuleModule { }
