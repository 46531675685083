import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { IonTextarea } from '@ionic/angular';
import { EMPTY } from 'rxjs';
import { MediaItem } from 'src/app/Models/Classes/Media/media-item';
import { CameraServiceService } from 'src/app/services/multimedia/camera-service.service';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import { UploadMultimediaService } from 'src/app/services/upload/upload-multimedia.service';
import { isImage, isVideo } from 'src/app/utils';
import Swal2 from 'sweetalert2';
import { CommentsService } from 'src/app/services/comments/comments.service';
import { Camera, CameraResultType, CameraSource, Photo, CameraOptions } from '@capacitor/camera';
import { ChangeDetectorRef } from '@angular/core';
@Component({
	selector: 'app-text-input',
	templateUrl: './text-input.component.html',
	styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent implements OnInit {
	mediaType: string;
	@Input() label: string;
	@Input() icon: string;
	@Input() placeholder: string;
	@Input() acceptMedia: boolean = false;
	@Input() type: string = "text";
	@Input() value: number | string;
	@Output() valueChange = new EventEmitter<string>();

	// @Output() takePhoto = new EventEmitter<boolean>();
	// @Output() takeVideo = new EventEmitter<boolean>();
	// @Output() pickMedia = new EventEmitter<boolean>();
	@Output() clickOnSend = new EventEmitter<boolean>();

	rutImage
	public blobsMediaComment = [];

	@ViewChild(IonTextarea)
	set pane(ita: IonTextarea) {
		if(ita){
			ita.getInputElement().then((input) => {
				setTimeout(() => {
					input.parentElement.style.height = '15px'
					input.style.height = '15px'
				}, 200);
			})
		}
	}

	constructor(
		private cameraService: CameraServiceService,
		public uploadMedia: UploadMultimediaService,
		public formservice: FormServiceService,
		public commentservice:CommentsService,
		private ref: ChangeDetectorRef,
	) { }

	ngOnInit() {
		console.log("contenido de media", this.uploadMedia.image);

		this.uploadMedia.image=" "
	}

	changeDetected(event) {
		this.valueChange.emit(event.detail.value.trim());
	}

	send_click() {
    console.log('oprimiste send');
    if (this.rutImage != undefined) {
      this.guardarMedia();
    }
		this.clickOnSend.emit();
	}

	public isVideo(url: string) {
		Capacitor.convertFileSrc(url);
		return isVideo(url);
	  }

	  public isImage(url: string){
		Capacitor.convertFileSrc(url);
		return isImage(url);
	  }


	  public setMultimediaCamera(mediaItem: MediaItem) {
		this.uploadMedia.image = Capacitor.convertFileSrc(mediaItem.mediaPath + mediaItem.nameFile);
		this.uploadMedia.readBlobFile(mediaItem.mediaPath + mediaItem.nameFile).
		then((result)=>{
			this.rutImage={image: this.uploadMedia.image};
		});
	  }

	  setMultimediaVideo(mediaItem: MediaItem) {
		this.uploadMedia.image = Capacitor.convertFileSrc(mediaItem.mediaPath + mediaItem.nameFile);
		this.uploadMedia.readBlobFile(mediaItem.mediaPath + mediaItem.nameFile).
		then((result)=>{
			this.rutImage={image: this.uploadMedia.image};
		});
	  }

	  public takePhoto() {
		this.cameraService
		  .askForMediCapture()
		  .then((data: [MediaItem, String])=>{
			if(data[1].toString() === 'camara'){
			  this.setMultimediaCamera(data[0]);
			}
			else if(data[1].toString()==='camvideo'){
			  this.setMultimediaVideo(data[0]);
			}

		  }).catch((error)=>{
		  Swal2.fire({
			title: 'Error take photo',
			text: error,
			icon: 'warning',
			confirmButtonText: 'Entendido',
			color: '#ffffff',
			background: '#010f4e',
		  });

		});
	  }

  public pickMedia() {
    this.uploadMedia.pickmultimedia().then((result) => {
      this.rutImage = {image: this.uploadMedia.image};

    });
  }

    guardarMedia(){
    console.log('guardando media');
   //   if(this.rutImage.filename !== '') {
        this.commentservice.multimediaComment.push(this.rutImage);
        this.commentservice.blobsMultimediaComment.push({blob:this.uploadMedia.blob, filename:this.uploadMedia.filename});
     // }
    }
	async pickImage(){
		const imagePreviewElement = document.getElementById("imagePreview") as HTMLImageElement;
		try {
		  const options: CameraOptions = {
			quality: 100,
			allowEditing: false,
			resultType: CameraResultType.Uri,
			source: CameraSource.Photos,
			saveToGallery: false,
		  };
		  console.log('About to call Camera.getPhoto()');
		  const media = await Camera.getPhoto(options);
	  
		  if (media) {
			console.log('Media picked successfully:', media);
			const imageUrl = Capacitor.convertFileSrc(media.path);
		  imagePreviewElement.src = imageUrl;
			this.mediaType = media.format === 'jpeg' || media.format === 'png' ? 'image' : 'video';
			this.ref.detectChanges();
			//const mimeType = media.format === 'jpeg' ? 'image/jpeg' : media.format === 'png' ? 'image/png' : 'video/mp4';
	  
			// Read the file as a Blob
			const response = await fetch(media.webPath);
			const blobfile = await response.blob();
	  
			// Log the Blob for debugging purposes
			console.log('fileBlob:', blobfile);
			const filename = media.path.substring(media.path.lastIndexOf('/') + 1);
	  
			//this.uploadMedia.post(blobfile, filename).then(({ link, mimeType }) => {
			//  const ruta = link;
		//	  console.log('ruta', ruta);
		//	  let pub_type = '';
		//	  if(mimeType.includes("image")) {
		//		pub_type= 'imagen';
		//	  }else if(mimeType.includes("video")){
	//			pub_type= 'video';
	//		  }
			
	this.uploadMedia.blob= blobfile;
	this.uploadMedia.filename=filename;
			  this.rutImage = blobfile;
		//	 }).catch((error)=>{
		//	   console.log(error);
//			 });
		   
		  
			
		  }
		} catch (error) {
		  console.error('Error picking media:', error);
		}
	  }  
}
