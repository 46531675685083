import { Component, OnInit} from '@angular/core';
import { FormServiceService } from 'src/app/services/publications/form-service.service';


@Component({
  selector: 'app-earht6',
  templateUrl: './earht6.component.html',
  styleUrls: ['./earht6.component.scss'],
})
export class Earht6Component implements OnInit {

  constructor(public formservice: FormServiceService) { }

  ngOnInit() {
    this.formservice.EarthCalendar = true;
   }

  addPrecio(event) {
    this.formservice.earthpublication.price = event.target.value
  }

  ObtenerDateIni(event) {
   
    this.formservice.earthpublication.fecha_inicial=event.detail.value
  }
  
  ObtenerDateEnd(event) {
    this.formservice.earthpublication.fecha_final=event.detail.value
  }
  zonahoraria(event) {
    this.formservice.landpublication.zonaHoraria=event.detail.value
  }

}
