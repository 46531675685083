import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarritoComponent } from './carrito.component';
import { IonicModule } from '@ionic/angular';



@NgModule({
  declarations: [
    CarritoComponent
  ],
  exports:[
    CarritoComponent
  ],
  entryComponents:[
    CarritoComponent
  ],
  imports: [
    CommonModule,
    IonicModule
  ]
})
export class CarritoModule { }
