import { Component, OnInit } from '@angular/core';
import { FormServiceService } from 'src/app/services/publications/form-service.service';


@Component({
  selector: 'app-landservice5',
  templateUrl: './landservice5.component.html',
  styleUrls: ['./landservice5.component.scss'],
})
export class Landservice5Component implements OnInit {

  constructor(public formservice: FormServiceService) { }

  ngOnInit() { }

  // hour(event) {

  //   this.formservice.landpublication.hour = event.target.value
  // }
}
