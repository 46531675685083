import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagoVerificacionComponent } from '../pago-verificacion.component';
import { IonicModule } from '@ionic/angular';
import { PagoTarjetaComponent } from '../Pago-Tarjeta/Pago-Tarjeta.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,




  ],
  exports:[PagoVerificacionComponent,PagoTarjetaComponent],
  entryComponents:[PagoVerificacionComponent,PagoTarjetaComponent],
  declarations: [PagoVerificacionComponent,PagoTarjetaComponent]
})
export class PagoVerificacionModuleModule { }
