import { Component, Input, OnInit, Output } from '@angular/core';
import { NormalPublication } from 'src/app/Models/Classes/Publications/Normal/normal-publication';
import * as Util from "util";
import {Utils} from "../../../../Models/Classes/utils";
import {LkfilmPublication} from "../../../../Models/Classes/Publications/Lkfilm/lkfilm-publication";
import {WelcomeMessageComponent} from "../../../general-design/welcome-message/welcome-message.component";
import {Lkfilms2Component} from "../../create/lkfilms/lkfilms2/lkfilms2.component";
import {LkfilmViewBySlidesComponent} from "../../../lkfilm-view-by-slides/lkfilm-view-by-slides.component";
import {ModalController} from "@ionic/angular";

@Component({
	selector: 'app-normal-publication',
	templateUrl: './normal-publication.component.html',
	styleUrls: ['./normal-publication.component.scss'],
})
export class NormalPublicationComponent implements OnInit {
	@Input() publication: NormalPublication;
  @Input() publication_lk: LkfilmPublication;
  @Input() activeSlideIndex: number;
  @Input() currentSlideIndex: number;
  public Profile: any;
	@Input() profilePhoto: string;

	constructor(
    private utils: Utils,
    private modalController: ModalController
  ) {
    this.utils.getItem('ProfileData').then((result) =>{
      this.Profile = JSON.parse(result);
    });
  }

	ngOnInit() {
    console.log('publicacion normal publication', this.publication);
    if (this.publication) {
      console.log('Publication title in InformationComponent:', this.publication.title);
    }
    console.log('Normal Publication Slide',this.activeSlideIndex);
    console.log('Normal Publication Slide',this.currentSlideIndex);
  }

	compra_confirmada(){}

	sharePublication(){}

	savePublication(){
    console.log('Oprimiste save publication en normal-publication')
  }

  open_modal() {
    if (this.publication.publication_type === 'lkfilm'){
      console.log('ES LKFILM!!', this.publication);
      let pubforlkfilm = {
        owner: this.publication.owner,
        container: {
          multimediaContainer: this.publication.multimediaContainer,
          title: this.publication.title,
          description: this.publication.description
        }
      };
      this.modalController
        .create({
          component: LkfilmViewBySlidesComponent,
          cssClass: 'detailsInModal',
          swipeToClose: true,
          componentProps: {
            publication: pubforlkfilm,
          },
        })
        .then((modal) => {
          modal.present();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
}
