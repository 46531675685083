import { Component, OnInit } from "@angular/core";
import { AlertController, ModalController, NavController } from "@ionic/angular";
import { FormServiceService } from "src/app/services/publications/form-service.service";

@Component({
  selector: 'app-principal-bienes-feex',
  templateUrl: './principal-bienes-feex.component.html',
  styleUrls: ['./principal-bienes-feex.component.scss'],
})
export class PrincipalBienesFeexComponent implements OnInit {

  public step: number = 1;
  public buttonSee: string = "ver";
  constructor(public formservice: FormServiceService,
    public modalController: ModalController,
    public navCtrl: NavController,
    public alertController: AlertController) {
    this.formservice.feexpublication.title = this.formservice.title
    this.formservice.feexpublication.description = this.formservice.description
    this.formservice.feexpublication.publication_type = this.formservice.publication_type
    this.formservice.feexpublication.quienpuedever = this.formservice.quienpuedever
    this.formservice.feexpublication.is_feed = this.formservice.is_feed
  }

  ngOnInit() { }

  //funcion para el boton siguiente
  next() {
    
    console.log(this.formservice.feexpublication)
    if (this.step < 7) {
      this.step++;
    }
    if (this.step === 7) {
      this.buttonSee = "oculto"
    }
   
  }

  //cerrar el modal
  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  //abrir un mensaje de salir de la publicacion
  async back() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Salir de la publicación',
      message: 'Si desea salir se eliminará todo su avance',
      buttons: [
        {
          text: 'Salir',
          handler: () => {
            this.dismiss();
          }
        },
        {
          text: 'Continuar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }
      ]
    });

    await alert.present();
  }

}
