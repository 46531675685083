import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModulesLockComponent } from '../../go-ecosystem/modules-lock/modules-lock.component';

@Component({
  selector: 'app-privacidad',
  templateUrl: './privacidad.component.html',
  styleUrls: ['./privacidad.component.scss'],
})
export class PrivacidadComponent implements OnInit {
  privateAccount: boolean = false;

  constructor(public modalController:ModalController) { }

  ngOnInit() {}

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  async onPrivateAccountClick() {
    if (this.privateAccount) {
      // Private account is currently ON, turn it OFF
      this.privateAccount = false;
    } else {
      // Private account is currently OFF, turn it ON
      const modal = await this.modalController.create({
        component: ModulesLockComponent,
        cssClass: 'detailsInModal',
        swipeToClose: true,
        componentProps: {}
      });
      return await modal.present();
    }
  }

  async onBlockedAccountsClick() {
    const modal = await this.modalController.create({
      component: ModulesLockComponent,
      cssClass: 'detailsInModal',
      swipeToClose: true,
      componentProps: {}
    });
    return await modal.present();
  }


}
