import { Injectable } from '@angular/core';
import { async } from '@angular/core/testing';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Platform } from '@ionic/angular';

const IMAGE_DIR="stored-images"

interface LocalFile{
  name:string;
  path:string;
  data:string;
}

@Injectable({
  providedIn: 'root'
})
export class CameraService {

constructor(private platform:Platform) { }



async selectImage(){
  const image=await Camera.getPhoto({
    quality:90,
    allowEditing:false,
    resultType:CameraResultType.Uri,
    source:CameraSource.Photos
  });
  console.log(image);

  if(image){
    this.saveImage(image)

  }
}
async saveImage(photo:Photo){
  const base64Data= await this.readAsBase64(photo);
  console.log(base64Data);
  
  const fileName=new Date().getTime()+'.jpeg';
  const savedFile= await Filesystem.writeFile({
    directory: Directory.Data,
    path: `${IMAGE_DIR}/${fileName}`,
    data: base64Data
  });
  console.log('saved',savedFile);
   
}

async readAsBase64(photo:Photo){

  if(this.platform.is('hybrid')){

    const file= await Filesystem.readFile({
      path:photo.path
    });
    return file.data
  }
  else{
    const response=await fetch(photo.webPath);
    const blob= await response.blob();

    return await this.convertBlobToBase64(blob) as string;
  }

}

convertBlobToBase64 = (blob:Blob) => new Promise((resolve, reject)=>{
  const reader = new FileReader;
  reader.onerror=reject;
  reader.onload=()=>{
    resolve(reader.result);

  };
  reader.readAsDataURL(blob);
});



}


