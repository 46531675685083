import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PublicationClass } from 'src/app/Models/Classes/Publications/publication-class';
import { PublicationService } from 'src/app/services/publications/publication.service';
import * as moment from 'moment'
import { CompraFinalizadaComponent } from '../../publication/create/finalizar-compra/compra-finalizada/compra-finalizada.component';
@Component({
  selector: 'app-principal-agenda',
  templateUrl: './principal-agenda.component.html',
  styleUrls: ['./principal-agenda.component.scss'],
})
export class PrincipalAgendaComponent implements OnInit {
@Input() publication:PublicationClass
  week: any = [
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
    "Domingo"
  ];
  AuxWeek: any = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado"
  ];

  dateValue: moment.Moment;;
  monthSelect: any[];
  dateSelect: any;
  startDate: any;
  arrayDays: any = [];
  objectDate: moment.Moment;
  constructor( private modalcontroller: ModalController,
    private publicationService: PublicationService) { }

  ngOnInit() {
    this.getDaysFromDate(11, 2020)
  }
  dismiss() {
    this.modalcontroller.dismiss({
      'dismissed': true
    });
  }
  getDaysFromDate(month, year) {
    this.startDate = moment.utc(`${year}/${month}/01`)

    const endDate = this.startDate.clone().endOf('month')

    this.dateSelect = this.startDate;
    const diffDays = endDate.diff(this.startDate, 'days', true)
    const numberDays = Math.round(diffDays);
    this.arrayDays = Object.keys([...Array(numberDays)]).map((a: any) => {
      a = parseInt(a) + 1;
      const dayObject = moment.utc(`${year}-${month}-${a}`);
      return {
        name: dayObject.format("dddd"),
        value: a,
        indexWeek: dayObject.isoWeekday()
      };
    });
    this.monthSelect = this.arrayDays;
  }
  changeMonth(flag) {
    if (flag < 0) {
      const prevDate = this.dateSelect.clone().subtract(1, "month");
      this.getDaysFromDate(prevDate.format("MM"), prevDate.format("YYYY"));
    } else {
      const nextDate = this.dateSelect.clone().add(1, "month");
      this.getDaysFromDate(nextDate.format("MM"), nextDate.format("YYYY"));
    }
  }
  clickDay(day) {
    const monthYear = this.dateSelect.format('YYYY-MM')
    const parse = `${monthYear}-${day.value}`
    this.objectDate = moment.utc(parse)

  }

  pagar() {

      this.modalcontroller
        .create({
          component: CompraFinalizadaComponent,

          componentProps: {
            publication: this.publication
          },
        })
        .then((modal) => modal.present())
    }


}
