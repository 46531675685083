import { MediaItem } from "../../Media/media-item";
import { ReactionContainerClass } from "../../Reactions/ReactionContainer/reaction-container-class";
import { ReactionClass } from "../../Reactions/SingleReaction/reaction-class";
import { SellerProfile } from "../../User/Store/seller-profile";

export class CommentClass {
    publication_id: number;

    id: number;
    edited: boolean;
    owner: boolean;
    userProfile: SellerProfile;

    text: string;
    timestamp: number;
    status_string: string;

    multimediaContainer: Array<MediaItem>;
    multimediaCounter: number;
    public blobsMultimediacommets=[]

    reactionContainer: Array<ReactionContainerClass>;
    reactionCounter: number;
    ownReaction: {
        reaction_id: number,
        reactionClass: ReactionClass
    };

    constructor(publication_id: number, objectComment?: any, userProfile?: SellerProfile, reactionContainer?: Array<ReactionContainerClass>, ownReaction?: { reaction_id: number, reactionClass: ReactionClass }) {

        if (objectComment != null) {
            this.id = (objectComment.id) ? parseInt(objectComment.id) : undefined;
            this.edited = (objectComment.edited) ? objectComment.edited : false;
            this.owner = (objectComment.owner) ? objectComment.owner: false;

            this.userProfile = (userProfile) ? userProfile : undefined;

            this.text = (objectComment.text) ? objectComment.text : "";
            this.timestamp = (objectComment.timestamp) ? parseInt(objectComment.timestamp) : undefined;
            this.status_string = (objectComment.status_string) ? objectComment.status_string : "";

            this.multimediaCounter = (objectComment.multimediaCounter) ? parseInt(objectComment.multimediaCounter) : 0;
            this.transformMediaToMediaItem(objectComment.multimediaContainer).then((multimedia) => {
                this.multimediaContainer = multimedia;
            });

            this.reactionContainer = (reactionContainer) ? reactionContainer : new Array<ReactionContainerClass>();
            this.reactionCounter = (objectComment.reactionCounter) ? parseInt(objectComment.reactionCounter) : 0;
            this.ownReaction = (ownReaction) ? ownReaction : undefined;
        }
        else {
            this.id = undefined;
            this.edited = false;
            this.owner = true;

            this.userProfile = (userProfile) ? userProfile : undefined;

            this.text = "";
            this.timestamp = undefined;
            this.status_string = "";

            this.multimediaCounter = 0;
            this.multimediaContainer = new Array<MediaItem>();


            this.reactionContainer = (reactionContainer) ? reactionContainer : new Array<ReactionContainerClass>();
            this.reactionCounter = 0;
            this.ownReaction = (ownReaction) ? ownReaction : undefined;
        }
        this.publication_id = publication_id;
    }

    public updateComment(objectComment: any, userProfile: SellerProfile, reactionContainer: Array<ReactionContainerClass>, ownReaction: { reaction_id: number, reactionClass: ReactionClass }) {
        this.id = (objectComment.id) ? parseInt(objectComment.id) : this.id;
        this.edited = (objectComment.edited) ? objectComment.edited : this.edited;
        this.owner = (objectComment.owner) ? objectComment.owner : this.owner;

        this.userProfile = userProfile;

        this.text = (objectComment.text) ? objectComment.text : this.text;
        this.timestamp = (objectComment.timestamp) ? parseInt(objectComment.timestamp) : this.timestamp;
        this.status_string = (objectComment.status_string) ? objectComment.status_string : this.status_string;

        this.multimediaCounter = (objectComment.multimediaCounter) ? parseInt(objectComment.multimediaCounter) : this.multimediaCounter;
        this.transformMediaToMediaItem(objectComment.multimediaContainer).then((multimedia) => {
            this.multimediaContainer = multimedia;
        });

        this.reactionContainer = reactionContainer;
        this.reactionCounter = parseInt(objectComment.reactionCounter);
        this.ownReaction = ownReaction;
    }

    transformMediaToMediaItem(objectMedia) {
        return new Promise<Array<MediaItem>>((resolve) => {
            let MediaContainer = new Array<MediaItem>();
            if (objectMedia) {
                objectMedia.forEach(media => {
                    MediaContainer.push(new MediaItem(media));
                });
                resolve(MediaContainer);
            }
            else {
                resolve(MediaContainer);
            }

        });
    }

    getDataToSend() {
        let data = {
            text: this.text,
            publication_id: this.publication_id,
            comment_id: this.id,
            doesItHaveMultimedia: this.getHaveMultimedia(),
        }
        return data;
    }

    setMultimedia(mediaItem: MediaItem) {
        if (this.getHaveMultimedia()) {
            this.deleteMultimedia(0);
        }
        this.multimediaContainer.push(mediaItem);
    }

    setText(text: string) {
        this.text = text;
    }

    getText() {
        return this.text;
    }

    getHaveMultimedia() {


        return (this.blobsMultimediacommets.length > 0) ? true : false;


    }
    getMultimediaArray() {
        return this.multimediaContainer;
    }

    deleteMultimedia(index: number) {
        this.multimediaContainer.splice(index, 1);
    }



}
