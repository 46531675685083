import { Injectable } from '@angular/core';
import { RequestService } from '../request/request.service';
import { RequestResponse } from '../../Models/Classes/request-response';
import { MediaItem } from 'src/app/Models/Classes/Media/media-item';
//import { Conversation } from 'src/app/Models/Classes/Chat/conversation';

@Injectable({
	providedIn: 'root'
})
export class MultimediaInChatService {
	static instance: MultimediaInChatService;
	constructor(
		private request: RequestService
	) { 
		MultimediaInChatService.instance = this;
	}

	public postChatElement(chatElement: any): Promise<RequestResponse> {
		return new Promise((resolve, reject) => {
			var params = null;
			this.request.createRequestPost('chatElement', chatElement, params).then((data) => {
				resolve(data);
			}).catch((error) => {
				reject(error);
			})
		});
	}

	public getChatElement(chatElement_id?:number, queryParams?: any): Promise<MediaItem> {
		return new Promise((resolve, reject) => {
			var params = (chatElement_id)? {'id': chatElement_id.toString()} : null;
			this.request.createRequestGet('chatElement',queryParams, params).then((data: any) => {
				data = JSON.parse(data);
				let mediaItem = new MediaItem(data.media);
			
				resolve(mediaItem);
			}).catch((error) => {
				reject(error);
			})
		});
	}

	public putChatElement(chatElement: any, id_chatElement:number): Promise<RequestResponse> {	
		return new Promise((resolve, reject) => {
			var params = { 
				"id": id_chatElement
			}	
			this.request.createRequestPut('chatElement', chatElement, params).then((data) => {
				resolve(data);
			}).catch((error) => {
				reject(error);
			});
		});
	}

	public deleteChatElement(id_chatElement:number): Promise<RequestResponse> {	
		return new Promise((resolve, reject) => {
			var params = { 
				"id": id_chatElement
			}	
			this.request.createRequestDelete('chatElement', params).then((data) => {
				resolve(data);
			}).catch((error) => {
				reject(error);
			})
		});
	}

	/*
	public buildChatInfo(conversation?:Conversation, user_id?){
		return new Promise((resolve, reject) => {
			let chat_info = {
				chat_imagen: "",
				chat_name: ""
			};
			if(conversation && conversation.getIsGroup()){
				let params = {
					"graph_ql_id": conversation.getID()
				}
			
				this.getChatElement(undefined, params).then((data)=>{
					chat_info.chat_imagen = data['media']['url'];
					chat_info.chat_name = conversation['name'];
					resolve(chat_info);
				}).catch((error)=>{
					reject(error);
				});
			}
			else{
				let params = {
					"user_id": (conversation)? conversation.getUsers()[0].id : user_id
				}
			
				this.getChatElement(undefined, params).then((data)=>{
					chat_info.chat_imagen = data['profile_photo']['url'];
					
					if(data['name'] != null && data['last_name'] != null){
						chat_info.chat_name = data['name'] + " " + data['last_name'];
					}else {
						chat_info.chat_name = conversation.getName();
					}
					resolve(chat_info);
					
				}).catch((error)=>{
					reject(error);
				});
			}
		});
	}

	public getSystemMessage(messageBody: string){
		return new Promise((resolve, reject) => {
			if(messageBody.length > 8){
				//let segmentSystem = messageBody.slice(0, 8);
				if(messageBody.slice(0, 8) == "<system>"){
	
					let arrStr = messageBody.split('>');
	
					let codeSegment = arrStr[1].split('<')[1];
					let valueSegment = arrStr[2];
					let systemMessage: string = undefined;
	
					if(codeSegment){
						switch(codeSegment){
							case "Update ChatName":{
								systemMessage = " modifico el nombre del chat";
								if(valueSegment){
									systemMessage += " a: "+ valueSegment;
								}
								resolve({
									isSystemMessage: true,
									codeSegment: codeSegment,
									valueSegment: valueSegment,
									message: systemMessage
								});
								break;
							}
							case "Update ChatIcon":{
								systemMessage = " modifico la imagen del grupo";
								if(valueSegment){
									systemMessage += " a: "+ valueSegment;
								}
								resolve({
									isSystemMessage: true,
									codeSegment: codeSegment,
									valueSegment: valueSegment,
									message: systemMessage
								});
								break;
							}
							default:
								console.error(codeSegment);
								break;
						}
						
					}
				}
			}
		});
	}
	*/
}
