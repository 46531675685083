import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LkfilmstudiosComponent } from '../../lkfilms/lkfilmstudios/lkfilmstudios.component';
import { Lkfilms2Component } from '../../lkfilms/lkfilms2/lkfilms2.component';
import { PrincipalLkfilmComponent } from '../principal-lkfilm/principal-lkfilm.component';
import { IonicModule } from '@ionic/angular';
import { DescripcionPublicationComponent } from '../../descripcion-publication/descripcion-publication.component';
import { DescriptionModuleModule } from '../../description-module/description-module.module';
import { FormsModule } from '@angular/forms';
import { PagarCComponent } from 'src/app/components/pagar-c/pagar-c.component';
import { NormalPublicationModule } from '../normal-publication/normal-publication.module';


@NgModule({
  declarations: [

    /* componente principal lkfilm */
    PrincipalLkfilmComponent,
    /*Sub Component lkfiml */
    LkfilmstudiosComponent,
    Lkfilms2Component,

  ],
  exports:[
    // PagarCComponent,
    /* componente principal lkfilm */
    PrincipalLkfilmComponent,
    /*Sub Component lkfiml */
    LkfilmstudiosComponent,
    Lkfilms2Component,
  ],
  entryComponents:[
    PagarCComponent,

    /* componente principal lkfilm */
    PrincipalLkfilmComponent,
    /*Sub Component lkfiml */
    LkfilmstudiosComponent,
    Lkfilms2Component,
  ],
  imports: [
  NormalPublicationModule,

  CommonModule,
    IonicModule,
    DescriptionModuleModule,
    FormsModule
  ]
})
export class LkfilmModuleModule { }
