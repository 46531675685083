import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MediaItem } from 'src/app/Models/Classes/Media/media-item';
import { PublicationClass } from 'src/app/Models/Classes/Publications/publication-class';
import { IMultimediable } from 'src/app/Models/Interfaces/imultimediable';

@Component({
	selector: 'app-preview',
	templateUrl: './preview.component.html',
	styleUrls: ['./preview.component.scss'],
})
export class PreviewComponent implements OnInit {

	@Input() media: MediaItem;
	@Input() index: number;
	@Input() IMultimediable: IMultimediable;
	@Input() canBeDeleted: boolean = false;

	@Output() deleteMedia = new EventEmitter<any>();

	constructor(
		private modalController: ModalController,
	) { }

	ngOnInit() {
		console.log(this.media, this.IMultimediable, this.index);
	}

	closePreview() {
		this.modalController.dismiss();
	}

	deleteItem() {
		//this.publication.deleteMultimedia(this.index);
		
		this.IMultimediable.deleteMultimedia(this.media.index)
		this.deleteMedia.emit();

		this.modalController.dismiss(true);
	}

	public getSrcFromUri(path: string) {
		let src = (<any>window).Ionic.WebView.convertFileSrc(path);
		return src;
	}
	toggleVideoPlayback(videoPlayer: HTMLVideoElement) {
		if (videoPlayer.paused) {
			videoPlayer.play();
		} else {
			videoPlayer.pause();
		}
	}
	
	getVideoProgress(videoPlayer: HTMLVideoElement) {
		if (videoPlayer.duration > 0) {
			return videoPlayer.currentTime / videoPlayer.duration * 100;
		}
		return 0;
	}
	


}
