import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IonTextarea } from '@ionic/angular';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
})
export class TextAreaComponent implements OnInit {

  @Input() label: string;
  @Input() contentTextArea: string;
  @Output() value = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {}

  returnValue(event){
    this.value.emit(event.detail.value.trim());
  }
}
