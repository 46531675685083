import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { StoriesComponent } from '../stories/stories.component';
import { StoryViewerComponent } from '../story-viewer/story-viewer.component';

@NgModule({
	declarations: [
		StoriesComponent,
		StoryViewerComponent
	],
	exports: [
		StoriesComponent,
		StoryViewerComponent
	],
	entryComponents: [
		StoriesComponent,
		StoryViewerComponent
	],
	imports: [
		CommonModule,
		IonicModule
	]
})
export class StoriesModuleModule { }
