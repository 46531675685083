import { Injectable } from "@angular/core";
import { graphqlOperation } from "@aws-amplify/api-graphql";
import  API  from "@aws-amplify/api-graphql";

import { Observable } from "zen-observable-ts";
import * as models from "src/app/Models/Classes/graphql/models/models-graphql";
import * as mutations from "src/app/Models/Classes/graphql/queries/mutations";
import * as queries from "src/app/Models/Classes/graphql/queries/queries";
import * as subscriptions from "src/app/Models/Classes/graphql/queries/subscriptions";

@Injectable({
	providedIn: "root"
})


export class GraphQLAPIService {
	async CreateSeenByMessages(
		input: models.CreateUserMessageByMessagesList,
		condition?: models.ModelUserMessageConditionInput
	): Promise<models.CreateSeenByMessagesMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(
			graphqlOperation(mutations.createSeenByMessages, gqlAPIServiceArguments)
		)) as any;
		return <models.CreateSeenByMessagesMutation>response.data.createSeenByMessages;
	}

	async CreateConversationByUsersList(
		input: models.CreateConversationByUsersList,
		userID: string,
		condition?: models.ModelConversationConditionInput
	): Promise<models.CreateConversationByUsersListMutation> {
		const gqlAPIServiceArguments: any = {
			input,
			userID
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(
			graphqlOperation(mutations.createConversationByUsersList, gqlAPIServiceArguments)
		)) as any;
		return <models.CreateConversationByUsersListMutation>(
			response.data.createConversationByUsersList
		);
	}

	async CreateUser(
		input: models.CreateUserInput,
		condition?: models.ModelUserConditionInput
	): Promise<models.CreateUserMutation> {

		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(
			graphqlOperation(mutations.createUser, gqlAPIServiceArguments)
		)) as any;
		return <models.CreateUserMutation>response.data.createUser;
	}

	async UpdateUser(
		input: models.UpdateUserInput,
		condition?: models.ModelUserConditionInput
	): Promise<models.UpdateUserMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(
			graphqlOperation(mutations.updateUser, gqlAPIServiceArguments)
		)) as any;
		return <models.UpdateUserMutation>response.data.updateUser;
	}

	async DeleteUser(
		input: models.DeleteUserInput,
		condition?: models.ModelUserConditionInput
	): Promise<models.DeleteUserMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(
			graphqlOperation(mutations.deleteUser, gqlAPIServiceArguments)
		)) as any;
		return <models.DeleteUserMutation>response.data.deleteUser;
	}

	async CreateConversation(
		input: models.CreateConversationInput,
		condition?: models.ModelConversationConditionInput
	): Promise<models.CreateConversationMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(
			graphqlOperation(mutations.createConversation, gqlAPIServiceArguments)
		)) as any;
		return <models.CreateConversationMutation>response.data.createConversation;
	}

	async UpdateConversation(
		input: models.UpdateConversationInput,
		condition?: models.ModelConversationConditionInput
	): Promise<models.UpdateConversationMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(
			graphqlOperation(mutations.updateConversation, gqlAPIServiceArguments)
		)) as any;
		return <models.UpdateConversationMutation>response.data.updateConversation;
	}

	async DeleteConversation(
		input: models.DeleteConversationInput,
		condition?: models.ModelConversationConditionInput
	): Promise<models.DeleteConversationMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(
			graphqlOperation(mutations.deleteConversation, gqlAPIServiceArguments)
		)) as any;
		return <models.DeleteConversationMutation>response.data.deleteConversation;
	}

	async CreateMessage(
		input: models.CreateMessageInput,
		condition?: models.ModelMessageConditionInput
	): Promise<models.CreateMessageMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(
			graphqlOperation(mutations.createMessage, gqlAPIServiceArguments)
		)) as any;
		return <models.CreateMessageMutation>response.data.createMessage;
	}

	async UpdateMessage(
		input: models.UpdateMessageInput,
		condition?: models.ModelMessageConditionInput
	): Promise<models.UpdateMessageMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(
			graphqlOperation(mutations.updateMessage, gqlAPIServiceArguments)
		)) as any;
		return <models.UpdateMessageMutation>response.data.updateMessage;
	}

	async DeleteMessage(
		input: models.DeleteMessageInput,
		condition?: models.ModelMessageConditionInput
	): Promise<models.DeleteMessageMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(
			graphqlOperation(mutations.deleteMessage, gqlAPIServiceArguments)
		)) as any;
		return <models.DeleteMessageMutation>response.data.deleteMessage;
	}

	async CreateConnection(
		input: models.CreateConnectionInput,
		condition?: models.ModelConnectionConditionInput
	): Promise<models.CreateConnectionMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(
			graphqlOperation(mutations.createConnection, gqlAPIServiceArguments)
		)) as any;
		return <models.CreateConnectionMutation>response.data.createConnection;
	}

	async UpdateConnection(
		input: models.UpdateConnectionInput,
		condition?: models.ModelConnectionConditionInput
	): Promise<models.UpdateConnectionMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(
			graphqlOperation(mutations.updateConnection, gqlAPIServiceArguments)
		)) as any;
		return <models.UpdateConnectionMutation>response.data.updateConnection;
	}

	async DeleteConnection(
		input: models.DeleteConnectionInput,
		condition?: models.ModelConnectionConditionInput
	): Promise<models.DeleteConnectionMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(
			graphqlOperation(mutations.deleteConnection, gqlAPIServiceArguments)
		)) as any;
		return <models.DeleteConnectionMutation>response.data.deleteConnection;
	}

	async CreateSeen(
		input: models.CreateSeenInput,
		condition?: models.ModelSeenConditionInput
	): Promise<models.CreateSeenMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(
			graphqlOperation(mutations.createSeen, gqlAPIServiceArguments)
		)) as any;
		return <models.CreateSeenMutation>response.data.createSeen;
	}

	async UpdateSeen(
		input: models.UpdateSeenInput,
		condition?: models.ModelSeenConditionInput
	): Promise<models.UpdateSeenMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(
			graphqlOperation(mutations.updateSeen, gqlAPIServiceArguments)
		)) as any;
		return <models.UpdateSeenMutation>response.data.updateSeen;
	}

	async DeleteSeen(
		input: models.DeleteSeenInput,
		condition?: models.ModelSeenConditionInput
	): Promise<models.DeleteSeenMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(
			graphqlOperation(mutations.deleteSeen, gqlAPIServiceArguments)
		)) as any;
		return <models.DeleteSeenMutation>response.data.deleteSeen;
	}

	async CreateWrite(
		input: models.CreateWriteInput,
		condition?: models.ModelWriteConditionInput
	): Promise<models.CreateWriteMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(
			graphqlOperation(mutations.createWrite, gqlAPIServiceArguments)
		)) as any;
		return <models.CreateWriteMutation>response.data.createWrite;
	}

	async UpdateWrite(
		input: models.UpdateWriteInput,
		condition?: models.ModelWriteConditionInput
	): Promise<models.UpdateWriteMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(
			graphqlOperation(mutations.updateWrite, gqlAPIServiceArguments)
		)) as any;
		return <models.UpdateWriteMutation>response.data.updateWrite;
	}

	async DeleteWrite(
		input: models.DeleteWriteInput,
		condition?: models.ModelWriteConditionInput
	): Promise<models.DeleteWriteMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(
			graphqlOperation(mutations.deleteWrite, gqlAPIServiceArguments)
		)) as any;
		return <models.DeleteWriteMutation>response.data.deleteWrite;
	}

	async CreateUserConversation(
		input: models.CreateUserConversationInput,
		condition?: models.ModelUserConversationConditionInput
	): Promise<models.CreateUserConversationMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(
			graphqlOperation(mutations.createUserConversation, gqlAPIServiceArguments)
		)) as any;
		return <models.CreateUserConversationMutation>response.data.createUserConversation;
	}

	async UpdateUserConversation(
		input: models.UpdateUserConversationInput,
		condition?: models.ModelUserConversationConditionInput
	): Promise<models.UpdateUserConversationMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(
			graphqlOperation(mutations.updateUserConversation, gqlAPIServiceArguments)
		)) as any;
		return <models.UpdateUserConversationMutation>response.data.updateUserConversation;
	}

	async DeleteUserConversation(
		input: models.DeleteUserConversationInput,
		condition?: models.ModelUserConversationConditionInput
	): Promise<models.DeleteUserConversationMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(
			graphqlOperation(mutations.deleteUserConversation, gqlAPIServiceArguments)
		)) as any;
		return <models.DeleteUserConversationMutation>response.data.deleteUserConversation;
	}

	async CreateUserMessage(
		input: models.CreateUserMessageInput,
		condition?: models.ModelUserMessageConditionInput
	): Promise<models.CreateUserMessageMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(
			graphqlOperation(mutations.createUserMessage, gqlAPIServiceArguments)
		)) as any;
		return <models.CreateUserMessageMutation>response.data.createUserMessage;
	}

	async UpdateUserMessage(
		input: models.UpdateUserMessageInput,
		condition?: models.ModelUserMessageConditionInput
	): Promise<models.UpdateUserMessageMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(
			graphqlOperation(mutations.updateUserMessage, gqlAPIServiceArguments)
		)) as any;
		return <models.UpdateUserMessageMutation>response.data.updateUserMessage;
	}

	async DeleteUserMessage(
		input: models.DeleteUserMessageInput,
		condition?: models.ModelUserMessageConditionInput
	): Promise<models.DeleteUserMessageMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(
			graphqlOperation(mutations.deleteUserMessage, gqlAPIServiceArguments)
		)) as any;
		return <models.DeleteUserMessageMutation>response.data.deleteUserMessage;
	}

	async GetUserConversationsByUsers(
		id: string,
		relationUserID: string
	): Promise<models.GetUserConversationsByUsersQuery> {

		const gqlAPIServiceArguments: any = {
			id,
			relationUserID
		};
		const response = (await API.graphql(
			graphqlOperation(queries.getUserConversationsByUsers, gqlAPIServiceArguments)
		)) as any;
		return <models.GetUserConversationsByUsersQuery>(
			response.data.getUserConversationsByUsers
		);
	}

	async GetConversationByUser(
		id: string,
		userID: string
	): Promise<models.GetConversationByUserQuery> {
		const gqlAPIServiceArguments: any = {
			id,
			userID
		};
		const response = (await API.graphql(
			graphqlOperation(queries.getConversationByUser, gqlAPIServiceArguments)
		)) as any;
		return <models.GetConversationByUserQuery>response.data.getConversationByUser;
	}

	async GetConversationMessages(
		id: string,
		limit?: number,
		nextToken?: string
	): Promise<models.GetConversationMessagesQuery> {
		const gqlAPIServiceArguments: any = {
			id
		};
		if (limit) {
			gqlAPIServiceArguments.limit = limit;
		}
		if (nextToken) {
			gqlAPIServiceArguments.nextToken = nextToken;
		}
		const response = (await API.graphql(
			graphqlOperation(queries.getConversationMessages, gqlAPIServiceArguments)
		)) as any;
		return <models.GetConversationMessagesQuery>response.data.getConversationMessages;
	}

	async GetUser(id: string): Promise<models.GetUserQuery> {
		const gqlAPIServiceArguments: any = {
			id
		};
		const response = (await API.graphql(
			graphqlOperation(queries.getUser, gqlAPIServiceArguments)
		)) as any;
		return <models.GetUserQuery>response.data.getUser;
	}

	async GetConversationsByUser(id: string): Promise<models.GetUserQuery> {
		const gqlAPIServiceArguments: any = {
			id
		};
		const response = (await API.graphql(graphqlOperation(queries.getConversationsByUser, gqlAPIServiceArguments))) as any;
		return <models.GetUserQuery>(response.data.getUser);
	}

	async ListUsers(
		filter?: models.ModelUserFilterInput,
		limit?: number,
		nextToken?: string
	): Promise<models.ListUsersQuery> {
		const gqlAPIServiceArguments: any = {};
		if (filter) {
			gqlAPIServiceArguments.filter = filter;
		}
		if (limit) {
			gqlAPIServiceArguments.limit = limit;
		}
		if (nextToken) {
			gqlAPIServiceArguments.nextToken = nextToken;
		}
		const response = (await API.graphql(
			graphqlOperation(queries.listUsers, gqlAPIServiceArguments)
		)) as any;
		return <models.ListUsersQuery>response.data.listUsers;
	}

	async GetConversation(id: string): Promise<models.GetConversationQuery> {
		const gqlAPIServiceArguments: any = {
			id
		};
		const response = (await API.graphql(
			graphqlOperation(queries.getConversation, gqlAPIServiceArguments)
		)) as any;
		return <models.GetConversationQuery>response.data.getConversation;
	}

	async ListConversations(
		filter?: models.ModelConversationFilterInput,
		limit?: number,
		nextToken?: string
	): Promise<models.ListConversationsQuery> {
		const gqlAPIServiceArguments: any = {};
		if (filter) {
			gqlAPIServiceArguments.filter = filter;
		}
		if (limit) {
			gqlAPIServiceArguments.limit = limit;
		}
		if (nextToken) {
			gqlAPIServiceArguments.nextToken = nextToken;
		}
		const response = (await API.graphql(
			graphqlOperation(queries.listConversations, gqlAPIServiceArguments)
		)) as any;
		return <models.ListConversationsQuery>response.data.listConversations;
	}

	async GetMessage(id: string): Promise<models.GetMessageQuery> {
		const gqlAPIServiceArguments: any = {
			id
		};
		const response = (await API.graphql(
			graphqlOperation(queries.getMessage, gqlAPIServiceArguments)
		)) as any;
		return <models.GetMessageQuery>response.data.getMessage;
	}

	async ListMessages(
		filter?: models.ModelMessageFilterInput,
		limit?: number,
		nextToken?: string
	): Promise<models.ListMessagesQuery> {
		const gqlAPIServiceArguments: any = {};
		if (filter) {
			gqlAPIServiceArguments.filter = filter;
		}
		if (limit) {
			gqlAPIServiceArguments.limit = limit;
		}
		if (nextToken) {
			gqlAPIServiceArguments.nextToken = nextToken;
		}
		const response = (await API.graphql(
			graphqlOperation(queries.listMessages, gqlAPIServiceArguments)
		)) as any;
		return <models.ListMessagesQuery>response.data.listMessages;
	}

	async GetConnection(id: string): Promise<models.GetConnectionQuery> {
		const gqlAPIServiceArguments: any = {
			id
		};
		const response = (await API.graphql(
			graphqlOperation(queries.getConnection, gqlAPIServiceArguments)
		)) as any;
		return <models.GetConnectionQuery>response.data.getConnection;
	}

	async ListConnections(
		filter?: models.ModelConnectionFilterInput,
		limit?: number,
		nextToken?: string
	): Promise<models.ListConnectionsQuery> {
		const gqlAPIServiceArguments: any = {};
		if (filter) {
			gqlAPIServiceArguments.filter = filter;
		}
		if (limit) {
			gqlAPIServiceArguments.limit = limit;
		}
		if (nextToken) {
			gqlAPIServiceArguments.nextToken = nextToken;
		}
		const response = (await API.graphql(
			graphqlOperation(queries.listConnections, gqlAPIServiceArguments)
		)) as any;
		return <models.ListConnectionsQuery>response.data.listConnections;
	}

	async GetSeen(id: string): Promise<models.GetSeenQuery> {
		const gqlAPIServiceArguments: any = {
			id
		};
		const response = (await API.graphql(
			graphqlOperation(queries.getSeen, gqlAPIServiceArguments)
		)) as any;
		return <models.GetSeenQuery>response.data.getSeen;
	}

	async ListSeens(
		filter?: models.ModelSeenFilterInput,
		limit?: number,
		nextToken?: string
	): Promise<models.ListSeensQuery> {
		const gqlAPIServiceArguments: any = {};
		if (filter) {
			gqlAPIServiceArguments.filter = filter;
		}
		if (limit) {
			gqlAPIServiceArguments.limit = limit;
		}
		if (nextToken) {
			gqlAPIServiceArguments.nextToken = nextToken;
		}
		const response = (await API.graphql(
			graphqlOperation(queries.listSeens, gqlAPIServiceArguments)
		)) as any;
		return <models.ListSeensQuery>response.data.listSeens;
	}

	async GetWrite(id: string): Promise<models.GetWriteQuery> {
		const gqlAPIServiceArguments: any = {
			id
		};
		const response = (await API.graphql(
			graphqlOperation(queries.getWrite, gqlAPIServiceArguments)
		)) as any;
		return <models.GetWriteQuery>response.data.getWrite;
	}

	async ListWrites(
		filter?: models.ModelWriteFilterInput,
		limit?: number,
		nextToken?: string
	): Promise<models.ListWritesQuery> {
		const gqlAPIServiceArguments: any = {};
		if (filter) {
			gqlAPIServiceArguments.filter = filter;
		}
		if (limit) {
			gqlAPIServiceArguments.limit = limit;
		}
		if (nextToken) {
			gqlAPIServiceArguments.nextToken = nextToken;
		}
		const response = (await API.graphql(
			graphqlOperation(queries.listWrites, gqlAPIServiceArguments)
		)) as any;
		return <models.ListWritesQuery>response.data.listWrites;
	}

	OnCreateMessageByConversationListener(conversationID: string): Observable<models.SubscriptionResponse<models.OnCreateMessageByConversationSubscription>> {
		let variables = {
			conversationID: conversationID
		}
		let response = API.graphql(graphqlOperation(subscriptions.onCreateMessageByConversation, variables)) as Observable<models.SubscriptionResponse<models.OnCreateMessageByConversationSubscription>>
		return response;
	}

	OnCreateUserConversationByUserListener(userID: string): Observable<models.SubscriptionResponse<models.OnCreateUserConversationByUserSubscription>> {
		let variables = {
			userID: userID
		};
		let response = API.graphql(graphqlOperation(subscriptions.onCreateUserConversationByUser, variables)) as Observable<models.SubscriptionResponse<models.OnCreateUserConversationByUserSubscription>>;
		return response;
	}

	OnUpdateConversationByConversationListener(conversationID: string): Observable<models.SubscriptionResponse<models.OnUpdateConversationByConversationSubscription>> {
		let variables = {
			conversationID: conversationID
		}
		let response = API.graphql(graphqlOperation(subscriptions.onUpdateConversationByConversation, variables)) as Observable<models.SubscriptionResponse<models.OnUpdateConversationByConversationSubscription>>;
		return response;
	}

	OnUpdateUserConversationByConversationListener(conversationID: string): Observable<models.SubscriptionResponse<models.OnUpdateUserConversationByConversationSubscription>> {
		let variables = {
			conversationID: conversationID
		}
		let response = API.graphql(graphqlOperation(subscriptions.onUpdateUserConversationByConversation, variables)) as Observable<models.SubscriptionResponse<models.OnUpdateUserConversationByConversationSubscription>>;
		return response;
	}

	OnUpdateMessageByConversationListener(conversationID: string): Observable<models.SubscriptionResponse<models.OnUpdateMessageByConversationSubscription>> {
		let variables = {
			conversationID: conversationID
		}
		let response = API.graphql(graphqlOperation(subscriptions.onUpdateMessageByConversation, variables)) as Observable<models.SubscriptionResponse<models.OnUpdateMessageByConversationSubscription>>;
		return response;
	}

	OnCreateConversationByUsersListListener: Observable<models.SubscriptionResponse<models.OnCreateConversationByUsersListSubscription>> = API.graphql(
		graphqlOperation(subscriptions.onCreateConversationByUsersList)
	) as Observable<models.SubscriptionResponse<models.OnCreateConversationByUsersListSubscription>>;

	OnCreateWriteByConversationListener: Observable<models.SubscriptionResponse<models.OnCreateWriteByConversationSubscription>> = API.graphql(
		graphqlOperation(subscriptions.onCreateWriteByConversation)
	) as Observable<models.SubscriptionResponse<models.OnCreateWriteByConversationSubscription>>;

	OnCreateUserMessageByConversationListener: Observable<models.SubscriptionResponse<models.OnCreateUserMessageByConversationSubscription>> = API.graphql(
		graphqlOperation(subscriptions.onCreateUserMessageByConversation)
	) as Observable<models.SubscriptionResponse<models.OnCreateUserMessageByConversationSubscription>>;

	/*
	OnCreateConnectionByUserListener: Observable<models.SubscriptionResponse<models.OnCreateConnectionByUserSubscription>> = API.graphql(
		graphqlOperation(subscriptions.onCreateConnectionByUser)
	) as Observable<models.SubscriptionResponse<models.OnCreateConnectionByUserSubscription>>;
	*/

	OnCreateConnectionByUserListener(userID: string): Observable<models.SubscriptionResponse<models.OnCreateConnectionByUserSubscription>> {
		let variables = {
			userID: userID
		}
		let response = API.graphql(graphqlOperation(subscriptions.onCreateConnectionByUser, variables)) as Observable<models.SubscriptionResponse<models.OnCreateConnectionByUserSubscription>>;
		return response;
	}

	OnCreateSeenByConversationListener(conversationID: string): Observable<models.SubscriptionResponse<models.OnCreateSeenByConversationSubscription>> {
		let variables = {
			conversationID: conversationID
		}
		let response = API.graphql(graphqlOperation(subscriptions.onCreateSeenByConversation, variables)) as Observable<models.SubscriptionResponse<models.OnCreateSeenByConversationSubscription>>;
		return response;
	}
	OnCreateUserListener: Observable<models.SubscriptionResponse<models.OnCreateUserSubscription>> = API.graphql(
		graphqlOperation(subscriptions.onCreateUser)
	) as Observable<models.SubscriptionResponse<models.OnCreateUserSubscription>>;

	OnUpdateUserListener: Observable<models.SubscriptionResponse<models.OnUpdateUserSubscription>> = API.graphql(
		graphqlOperation(subscriptions.onUpdateUser)
	) as Observable<models.SubscriptionResponse<models.OnUpdateUserSubscription>>;

	OnDeleteUserListener: Observable<models.SubscriptionResponse<models.OnDeleteUserSubscription>> = API.graphql(
		graphqlOperation(subscriptions.onDeleteUser)
	) as Observable<models.SubscriptionResponse<models.OnDeleteUserSubscription>>;

	OnCreateConversationListener: Observable<models.SubscriptionResponse<models.OnCreateConversationSubscription>> = API.graphql(
		graphqlOperation(subscriptions.onCreateConversation)
	) as Observable<models.SubscriptionResponse<models.OnCreateConversationSubscription>>;

	OnUpdateConversationListener: Observable<models.SubscriptionResponse<models.OnUpdateConversationSubscription>> = API.graphql(
		graphqlOperation(subscriptions.onUpdateConversation)
	) as Observable<models.SubscriptionResponse<models.OnUpdateConversationSubscription>>;

	OnDeleteConversationListener: Observable<models.SubscriptionResponse<models.OnDeleteConversationSubscription>> = API.graphql(
		graphqlOperation(subscriptions.onDeleteConversation)
	) as Observable<models.SubscriptionResponse<models.OnDeleteConversationSubscription>>;

	OnCreateMessageListener: Observable<models.SubscriptionResponse<models.OnCreateMessageSubscription>> = API.graphql(
		graphqlOperation(subscriptions.onCreateMessage)
	) as Observable<models.SubscriptionResponse<models.OnCreateMessageSubscription>>;

	OnUpdateMessageListener: Observable<models.SubscriptionResponse<models.OnUpdateMessageSubscription>> = API.graphql(
		graphqlOperation(subscriptions.onUpdateMessage)
	) as Observable<models.SubscriptionResponse<models.OnUpdateMessageSubscription>>;

	OnDeleteMessageListener: Observable<models.SubscriptionResponse<models.OnDeleteMessageSubscription>> = API.graphql(
		graphqlOperation(subscriptions.onDeleteMessage)
	) as Observable<models.SubscriptionResponse<models.OnDeleteMessageSubscription>>;

	OnCreateConnectionListener: Observable<models.SubscriptionResponse<models.OnCreateConnectionSubscription>> = API.graphql(
		graphqlOperation(subscriptions.onCreateConversation)
	) as Observable<models.SubscriptionResponse<models.OnCreateConnectionSubscription>>;

	OnUpdateConnectionListener: Observable<models.SubscriptionResponse<models.OnUpdateConnectionSubscription>> = API.graphql(
		graphqlOperation(subscriptions.onUpdateConnection)
	) as Observable<models.SubscriptionResponse<models.OnUpdateConnectionSubscription>>;

	OnDeleteConnectionListener: Observable<models.SubscriptionResponse<models.OnDeleteConnectionSubscription>> = API.graphql(
		graphqlOperation(subscriptions.onDeleteConnection)
	) as Observable<models.SubscriptionResponse<models.OnDeleteConnectionSubscription>>;

	OnCreateSeenListener: Observable<models.SubscriptionResponse<models.OnCreateSeenSubscription>> = API.graphql(
		graphqlOperation(subscriptions.onCreateSeen)
	) as Observable<models.SubscriptionResponse<models.OnCreateSeenSubscription>>;

	OnUpdateSeenListener: Observable<models.SubscriptionResponse<models.OnUpdateSeenSubscription>> = API.graphql(
		graphqlOperation(subscriptions.onUpdateSeen)
	) as Observable<models.SubscriptionResponse<models.OnUpdateSeenSubscription>>;

	OnDeleteSeenListener: Observable<models.SubscriptionResponse<models.OnDeleteSeenSubscription>> = API.graphql(
		graphqlOperation(subscriptions.onDeleteSeen)
	) as Observable<models.SubscriptionResponse<models.OnDeleteSeenSubscription>>;

	OnCreateWriteListener: Observable<models.SubscriptionResponse<models.OnCreateWriteSubscription>> = API.graphql(
		graphqlOperation(subscriptions.onCreateWrite)
	) as Observable<models.SubscriptionResponse<models.OnCreateWriteSubscription>>;

	OnUpdateWriteListener: Observable<models.SubscriptionResponse<models.OnUpdateWriteSubscription>> = API.graphql(
		graphqlOperation(subscriptions.onUpdateWrite)
	) as Observable<models.SubscriptionResponse<models.OnUpdateWriteSubscription>>;

	OnDeleteWriteListener: Observable<models.SubscriptionResponse<models.OnDeleteWriteSubscription>> = API.graphql(
		graphqlOperation(subscriptions.onDeleteWrite)
	) as Observable<models.SubscriptionResponse<models.OnDeleteWriteSubscription>>;

	OnCreateUserConversationListener: Observable<models.SubscriptionResponse<models.OnCreateUserConversationSubscription>> = API.graphql(
		graphqlOperation(subscriptions.onCreateUserConversation)
	) as Observable<models.SubscriptionResponse<models.OnCreateUserConversationSubscription>>;

	OnUpdateUserConversationListener: Observable<models.SubscriptionResponse<models.OnUpdateUserConversationSubscription>> = API.graphql(
		graphqlOperation(subscriptions.onUpdateUserConversation)
	) as Observable<models.SubscriptionResponse<models.OnUpdateUserConversationSubscription>>;

	OnDeleteUserConversationListener: Observable<models.SubscriptionResponse<models.OnDeleteUserConversationSubscription>> = API.graphql(
		graphqlOperation(subscriptions.onDeleteUserConversation)
	) as Observable<models.SubscriptionResponse<models.OnDeleteUserConversationSubscription>>;

	OnCreateUserMessageListener: Observable<models.SubscriptionResponse<models.OnCreateUserMessageSubscription>> = API.graphql(
		graphqlOperation(subscriptions.onCreateUserMessage)
	) as Observable<models.SubscriptionResponse<models.OnCreateUserMessageSubscription>>;

	OnUpdateUserMessageListener: Observable<models.SubscriptionResponse<models.OnUpdateUserMessageSubscription>> = API.graphql(
		graphqlOperation(subscriptions.onUpdateUserMessage)
	) as Observable<models.SubscriptionResponse<models.OnUpdateUserMessageSubscription>>;

	OnDeleteUserMessageListener: Observable<models.SubscriptionResponse<models.OnDeleteUserMessageSubscription>> = API.graphql(
		graphqlOperation(subscriptions.onDeleteUserMessage)
	) as Observable<models.SubscriptionResponse<models.OnDeleteUserMessageSubscription>>;
}

/*

export class GraphQLAPIService {
	async CreateConversationByUsersList(input: models.CreateConversationByUsersList, userID: string, condition?: models.ModelConversationConditionInput): Promise<models.CreateConversationByUsersListMutation> {
		const gqlAPIServiceArguments: any = {
			input,
			userID
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(graphqlOperation(mutations.createConversationByUsersList, gqlAPIServiceArguments))) as any;
		return <models.CreateConversationByUsersListMutation>(response.data.createConversationByUsersList);
	}

	async CreateUser(input: models.CreateUserInput, condition?: models.ModelUserConditionInput): Promise<models.CreateUserMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(graphqlOperation(mutations.createUser, gqlAPIServiceArguments))) as any;
		return <models.CreateUserMutation>(response.data.createUser);
	}

	async UpdateUser(input: models.UpdateUserInput, condition?: models.ModelUserConditionInput): Promise<models.UpdateUserMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(graphqlOperation(mutations.updateUser, gqlAPIServiceArguments))) as any;
		return <models.UpdateUserMutation>(response.data);
	}

	async DeleteUser(input: models.DeleteUserInput, condition?: models.ModelUserConditionInput): Promise<models.DeleteUserMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(graphqlOperation(mutations.deleteUser, gqlAPIServiceArguments))) as any;
		return <models.DeleteUserMutation>(response.data);
	}

	async CreateUserConversation(input: models.CreateUserConversationInput, condition?: models.ModelUserConversationConditionInput): Promise<models.CreateUserConversationMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(graphqlOperation(mutations.createUserConversation, gqlAPIServiceArguments))) as any;
		return <models.CreateUserConversationMutation>(response.data);
	}

	async UpdateUserConversation(input: models.UpdateUserConversationInput, condition?: models.ModelUserConversationConditionInput): Promise<models.UpdateUserConversationMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(
			graphqlOperation(mutations.updateUserConversation, gqlAPIServiceArguments)
		)) as any;
		return <models.UpdateUserConversationMutation>(response.data);
	}

	async DeleteUserConversation(input: models.DeleteUserConversationInput, condition?: models.ModelUserConversationConditionInput): Promise<models.DeleteUserConversationMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(graphqlOperation(mutations.deleteUserConversation, gqlAPIServiceArguments))) as any;
		return <models.DeleteUserConversationMutation>(response.data);
	}

	async CreateConversation(input: models.CreateConversationInput, condition?: models.ModelConversationConditionInput): Promise<models.CreateConversationMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(graphqlOperation(mutations.createConversation, gqlAPIServiceArguments))) as any;
		return <models.CreateConversationMutation>(response.data);
	}

	async UpdateConversation(input: models.UpdateConversationInput, condition?: models.ModelConversationConditionInput): Promise<models.UpdateConversationMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(graphqlOperation(mutations.updateConversation, gqlAPIServiceArguments))) as any;
		return <models.UpdateConversationMutation>(response.data);
	}

	async DeleteConversation(input: models.DeleteConversationInput, condition?: models.ModelConversationConditionInput): Promise<models.DeleteConversationMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(graphqlOperation(mutations.deleteConversation, gqlAPIServiceArguments))) as any;
		return <models.DeleteConversationMutation>(response.data);
	}

	async CreateMessage(input: models.CreateMessageInput, condition?: models.ModelMessageConditionInput): Promise<models.CreateMessageMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(graphqlOperation(mutations.createMessage, gqlAPIServiceArguments))) as any;
		return <models.CreateMessageMutation>(response.data);
	}

	async UpdateMessage(input: models.UpdateMessageInput, condition?: models.ModelMessageConditionInput): Promise<models.UpdateMessageMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(graphqlOperation(mutations.updateMessage, gqlAPIServiceArguments))) as any;
		return <models.UpdateMessageMutation>(response.data);
	}

	async DeleteMessage(input: models.DeleteMessageInput, condition?: models.ModelMessageConditionInput): Promise<models.DeleteMessageMutation> {
		const gqlAPIServiceArguments: any = {
			input
		};
		if (condition) {
			gqlAPIServiceArguments.condition = condition;
		}
		const response = (await API.graphql(graphqlOperation(mutations.deleteMessage, gqlAPIServiceArguments))) as any;
		return <models.DeleteMessageMutation>(response.data);
	}

	async GetUserConversationsByUsers(id: string, relationUserID: string): Promise<models.GetUserConversationsByUsersQuery> {
		const gqlAPIServiceArguments: any = {
			id,
			relationUserID
		};
		const response = (await API.graphql(graphqlOperation(queries.getUserConversationsByUsers, gqlAPIServiceArguments))) as any;
		return <models.GetUserConversationsByUsersQuery>(response.data.getUserConversationsByUsers);
	}

	async GetConversationByUser(id: string, userID: string): Promise<models.GetConversationByUserQuery> {
		const gqlAPIServiceArguments: any = {
			id,
			userID
		};
		const response = (await API.graphql(graphqlOperation(queries.getConversationByUser, gqlAPIServiceArguments))) as any;
		return <models.GetConversationByUserQuery>(response.data);
	}

	async GetConversationMessages(id: string, limit?: number, nextToken?: string): Promise<models.GetConversationMessagesQuery> {
		const gqlAPIServiceArguments: any = {
			id
		};
		if (limit) {
			gqlAPIServiceArguments.limit = limit;
		}
		if (nextToken) {
			gqlAPIServiceArguments.nextToken = nextToken;
		}
		const response = (await API.graphql(
			graphqlOperation(queries.getConversationMessages, gqlAPIServiceArguments)
		)) as any;
		return <models.GetConversationMessagesQuery>(response.data.getConversationMessages);
	}

	async GetUser(id: string): Promise<models.GetUserQuery> {
		const gqlAPIServiceArguments: any = {
			id
		};
		const response = (await API.graphql(graphqlOperation(queries.getUser, gqlAPIServiceArguments))) as any;
		return <models.GetUserQuery>(response.data.getUser);
	}

	async GetConversationsByUser(id: string): Promise<models.GetUserQuery> {
		const gqlAPIServiceArguments: any = {
			id
		};
		const response = (await API.graphql(graphqlOperation(queries.getConversationsByUser, gqlAPIServiceArguments))) as any;
		return <models.GetUserQuery>(response.data.getUser);
	}

	async ListUsers(filter?: models..ModelUserFilterInput, limit?: number, nextToken?: string): Promise<models.ListUsersQuery> {
		const gqlAPIServiceArguments: any = {};
		if (filter) {
			gqlAPIServiceArguments.filter = filter;
		}
		if (limit) {
			gqlAPIServiceArguments.limit = limit;
		}
		if (nextToken) {
			gqlAPIServiceArguments.nextToken = nextToken;
		}
		const response = (await API.graphql(graphqlOperation(queries.listUsers, gqlAPIServiceArguments))) as any;
		return <models.ListUsersQuery>(response.data);
	}

	async GetConversation(id: string): Promise<models.GetConversationQuery> {
		const gqlAPIServiceArguments: any = {
			id
		};
		const response = (await API.graphql(graphqlOperation(queries.getConversation, gqlAPIServiceArguments))) as any;
		return <models.GetConversationQuery>(response.data);
	}

	async ListConversations(filter?: models..ModelConversationFilterInput, limit?: number, nextToken?: string): Promise<models.ListConversationsQuery> {
		const gqlAPIServiceArguments: any = {};
		if (filter) {
			gqlAPIServiceArguments.filter = filter;
		}
		if (limit) {
			gqlAPIServiceArguments.limit = limit;
		}
		if (nextToken) {
			gqlAPIServiceArguments.nextToken = nextToken;
		}
		const response = (await API.graphql(graphqlOperation(queries.listConversations, gqlAPIServiceArguments))) as any;
		return <models.ListConversationsQuery>(response.data);
	}

	async GetMessage(id: string): Promise<models.GetMessageQuery> {
		const gqlAPIServiceArguments: any = {
			id
		};
		const response = (await API.graphql(graphqlOperation(queries.getMessage, gqlAPIServiceArguments))) as any;
		return <models.GetMessageQuery>(response.data);
	}

	async ListMessages(filter?: models..ModelMessageFilterInput, limit?: number, nextToken?: string): Promise<models.ListMessagesQuery> {
		const gqlAPIServiceArguments: any = {};
		if (filter) {
			gqlAPIServiceArguments.filter = filter;
		}
		if (limit) {
			gqlAPIServiceArguments.limit = limit;
		}
		if (nextToken) {
			gqlAPIServiceArguments.nextToken = nextToken;
		}
		const response = (await API.graphql(graphqlOperation(queries.listMessages, gqlAPIServiceArguments))) as any;
		return <models.ListMessagesQuery>(response.data);
	}

	OnCreateMessageByConversationListener(conversationID: any): Observable<models.SubscriptionResponse<models.OnCreateMessageByConversationSubscription>> {
		let variables = {
			conversationID: conversationID
		}
		let response = API.graphql(graphqlOperation(subscriptions.onCreateMessageByConversation, variables)) as Observable<models.SubscriptionResponse<models.OnCreateMessageByConversationSubscription>>
		return response;
	}

	OnCreateUserConversationByUserListener(userID: any): Observable<models.SubscriptionResponse<models.OnCreateUserConversationByUserSubscription>> {
		let variables = {
			userID: userID
		};
		let response = API.graphql(graphqlOperation(subscriptions.onCreateUserConversationByUser, variables)) as Observable<models.SubscriptionResponse<models.OnCreateUserConversationByUserSubscription>>;
		return response;
	}

	OnUpdateConversationByConversationListener(conversationID: any): Observable<models.SubscriptionResponse<models.OnUpdateConversationByConversationSubscription>> {
		let variables = {
			conversationID: conversationID
		}
		let response = API.graphql(graphqlOperation(subscriptions.onUpdateConversationByConversation, variables)) as Observable<models.SubscriptionResponse<models.OnUpdateConversationByConversationSubscription>>;
		return response;
	}

	OnUpdateUserConversationByConversationListener(conversationID: any): Observable<models.SubscriptionResponse<models.OnUpdateUserConversationByConversationSubscription>> {
		let variables = {
			conversationID: conversationID
		}
		let response = API.graphql(graphqlOperation(subscriptions.onUpdateUserConversationByConversation, variables)) as Observable<models.SubscriptionResponse<models.OnUpdateUserConversationByConversationSubscription>>;
		return response;
	}

	OnUpdateMessageByConversationListener(conversationID: any): Observable<models.SubscriptionResponse<models.OnUpdateMessageByConversationSubscription>> {
		let variables = {
			conversationID: conversationID
		}
		let response = API.graphql(graphqlOperation(subscriptions.onUpdateMessageByConversation, variables)) as Observable<models.SubscriptionResponse<models.OnUpdateMessageByConversationSubscription>>;
		return response;
	}

	OnCreateConversationByUsersListListener: Observable<models.SubscriptionResponse<models.OnCreateConversationByUsersListSubscription>> = API.graphql(graphqlOperation(subscriptions.onCreateConversationByUsersList)) as Observable<models.SubscriptionResponse<models.OnCreateConversationByUsersListSubscription>>;

	OnCreateUserListener: Observable<models.SubscriptionResponse<models.OnCreateUserSubscription>> = API.graphql(graphqlOperation(subscriptions.onCreateUser)) as Observable<models.SubscriptionResponse<models.OnCreateUserSubscription>>;

	OnUpdateUserListener: Observable<models.SubscriptionResponse<models.OnUpdateUserSubscription>> = API.graphql(graphqlOperation(subscriptions.onUpdateUser)) as Observable<models.SubscriptionResponse<models.OnUpdateUserSubscription>>;

	OnDeleteUserListener: Observable<models.SubscriptionResponse<models.OnDeleteUserSubscription>> = API.graphql(graphqlOperation(subscriptions.onDeleteUser)) as Observable<models.SubscriptionResponse<models.OnDeleteUserSubscription>>;

	OnCreateUserConversationListener: Observable<models.SubscriptionResponse<models.OnCreateUserConversationSubscription>> = API.graphql(graphqlOperation(subscriptions.onCreateUserConversation)) as Observable<models.SubscriptionResponse<models.OnCreateUserConversationSubscription>>;

	OnUpdateUserConversationListener: Observable<models.SubscriptionResponse<models.OnUpdateUserConversationSubscription>> = API.graphql(graphqlOperation(subscriptions.onUpdateUserConversation)) as Observable<models.SubscriptionResponse<models.OnUpdateUserConversationSubscription>>;

	OnDeleteUserConversationListener: Observable<models.SubscriptionResponse<models.OnDeleteUserConversationSubscription>> = API.graphql(graphqlOperation(subscriptions.onDeleteUserConversation)) as Observable<models.SubscriptionResponse<models.OnDeleteUserConversationSubscription>>;

	OnCreateConversationListener: Observable<models.SubscriptionResponse<models.OnCreateConversationSubscription>> = API.graphql(graphqlOperation(subscriptions.onCreateConversation)) as Observable<models.SubscriptionResponse<models.OnCreateConversationSubscription>>;

	OnUpdateConversationListener: Observable<models.SubscriptionResponse<models.OnUpdateConversationSubscription>> = API.graphql(graphqlOperation(subscriptions.onUpdateConversation)) as Observable<models.SubscriptionResponse<models.OnUpdateConversationSubscription>>;

	OnDeleteConversationListener: Observable<models.SubscriptionResponse<models.OnDeleteConversationSubscription>> = API.graphql(graphqlOperation(subscriptions.onDeleteConversation)) as Observable<models.SubscriptionResponse<models.OnDeleteConversationSubscription>>;

	OnCreateMessageListener: Observable<models.SubscriptionResponse<models.OnCreateMessageSubscription>> = API.graphql(graphqlOperation(subscriptions.onCreateMessage)) as Observable<models.SubscriptionResponse<models.OnCreateMessageSubscription>>;

	OnUpdateMessageListener: Observable<models.SubscriptionResponse<models.OnUpdateMessageSubscription>> = API.graphql(graphqlOperation(subscriptions.onUpdateMessage)) as Observable<models.SubscriptionResponse<models.OnUpdateMessageSubscription>>;

	OnDeleteMessageListener: Observable<models.SubscriptionResponse<models.OnDeleteMessageSubscription>> = API.graphql(graphqlOperation(subscriptions.onDeleteMessage)) as Observable<models.SubscriptionResponse<models.OnDeleteMessageSubscription>>;
}
*/