import { Component, OnInit } from '@angular/core';
import {   ModalController } from '@ionic/angular';
import { RequestService } from 'src/app/services/request/request.service';
import  Swal from 'sweetalert2';
@Component({
  selector: 'app-content-preferences',
  templateUrl: './content-preferences.component.html',
  styleUrls: ['./content-preferences.component.scss'],
})
export class ContentPreferencesComponent implements OnInit {
public other;
selectedCategories: string[] = [];
categories = [
  { label: 'Videojuegos', icon: 'game-controller-outline' },
  { label: 'Comedia', icon: 'happy-outline' },
  { label: 'Cultura de entretenimiento', icon: 'ticket-outline' },
  { label: 'Casa y Jardín', icon: 'home-outline' },
  { label: 'Vida Diaria', icon: 'heart-circle-outline' },
  { label: 'Ciencia y educación', icon: 'library-outline' },
  { label: 'Comida y bebida', icon: 'fast-food-outline' },
  { label: 'vehículos y transporte', icon: 'car-sport-outline' },
  { label: 'Anime y comics', icon: 'book-outline' },
  { label: 'Música', icon: 'musical-notes-outline' },
  { label: 'Animales', icon: 'paw-outline' },
  { label: 'Salud y fitness', icon: 'fitness-outline' },
  { label: 'Deportes', icon: 'basketball-outline' },
  { label: 'Estilo y belleza', icon: 'color-palette-outline' },
  { label: 'Familia', icon: 'people-outline' },
  { label: 'Motivación y consejos', icon: 'bulb-outline' },
  { label: 'Glitches de vida', icon: 'construct-outline' },
  { label: 'Arte', icon: 'brush-outline' },
  { label: 'ASMR', icon: 'musical-notes-outline' },
  { label: 'Viajes', icon: 'airplane-outline' },
  { label: 'HUM', icon: 'color-wand-outline' },
];
  constructor(
    private modalctrl:ModalController,
    private request: RequestService,
  ) { }
  dismiss(){
    this.modalctrl.dismiss();
  }
  ngOnInit() {}
  push_other_input(){
    this.categories.push({ label: this.other, icon: 'color-wand-outline' });
    this.other='';
  }
 otherChange($event){
   this.other=$event.value;
 }
 onCheckboxChange(event: any, category: string) {
  if (event.detail.checked) {
    this.selectedCategories.push(category);
  } else {
    const index = this.selectedCategories.indexOf(category);
    if (index > -1) {
      this.selectedCategories.splice(index, 1);
    }
  }
  console.log(this.selectedCategories);
}
send_data(){
  if(this.other){
    Swal.fire({
      title: 'No haz agregado tu otra categoría.',
      text: `Por favor agrega: ${this.other} antes de enviar tus preferncias. `,
      icon: 'error',
      confirmButtonText: 'Aceptar',
      background: '#010f4e',
      });
      return;
  }
  this.request.createRequestPost('save_content_preferences_component', {  selectedCategories:this.selectedCategories})
  .then((final_data) => {
   this.dismiss();
    Swal.fire({
    title: 'enviado exitosamente.',
    text: `Se han guardado tus preferencias: . `,
    icon: 'success',
    confirmButtonText: 'Aceptar',
    background: '#010f4e',
    });
  
  })
}
}
