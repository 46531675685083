import { Injectable } from '@angular/core';
import { RequestService } from '../request/request.service';
import { HttpClient } from '@angular/common/http';
import { Capacitor } from '@capacitor/core';
@Injectable({
	providedIn: 'root'
})
export class ProfileService {
	static instance: ProfileService
	public profile: any;
	public profileinfo:any;
	profileid:number;
	constructor(
		private request: RequestService,
		private http: HttpClient
	) {
		ProfileService.instance = this;
		this.getProfileInfo().then((profileData)=>{
			this.profile = profileData;
		});

		

		
		
	}

	public getProfileInfo(profile_id?: number): Promise<any> {
		if (Capacitor.getPlatform() !== 'web') {
		return new Promise((resolve, reject) => {
			var params;
			profile_id ? params = { "id": profile_id } : params = null;

			this.profileid=params
			
			this.request.createRequestGet('profile', null, this.profileid).then((data) => {
				resolve(data);
			}).catch((error) => {
				console.log(error);
				reject(error);
			});
		});
	}else{
		return this.request.createWebRequestGet('profile', null, profile_id ? { "id": profile_id } : null);
	}
	}
	/*
	public getALtProfileInfo(profile_id?: number): Promise<any> {
		if (Capacitor.getPlatform() !== 'web') {
		return new Promise((resolve, reject) => {
			var params;
			profile_id ? params = { "id": profile_id } : params = null;

			this.profileid=params
			
			this.request.createRequestGet('alternative_profile', null, this.profileid).then((data) => {
				resolve(data);
			}).catch((error) => {
				console.log(error);
				reject(error);
			});
		});
	}else{
		return this.request.createWebRequestGet('alternative_profile', null, profile_id ? { "id": profile_id } : null);
	}
	}
*/

	// public getProfileInfoall(profile_id: number=this.userid): Promise<any> {
	// 	return new Promise((resolve, reject) => {
	// 		var params;
	// 		profile_id ? params = { "id": profile_id } : params = null;
	// 		if (params){
	// 			console.log("id params",params);
				
	// 			this.request.createRequestGet('profile-all', null, {id:params}).then((data) => {

	// 				resolve(data);
	// 			}).catch((error) => {
	// 				console.log(error);
	// 				reject(error);
	// 			});
				
	// 		} else{
	// 			console.log("No llego el params");	
	// 		}
			
	// 	});
	// }

	public postProfile(attribute: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this.request.createRequestPost('profile', attribute).then((data) => {
				resolve(data);
			}).catch((error) => {
				reject(error);
			})
		});
	}



	/*public putProfile(attribute: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this.request.createRequestPut('profile', attribute).then((data) => {
				resolve(data);
			}).catch((error) => {
				reject(error);
			});
		});
	}

	public deleteProfile(id_profile: number): Promise<any> {
		return new Promise((resolve, reject) => {
			var params = { "id": id_profile }
			this.request.createRequestDelete('profile', params).then((client) => {
				resolve(client);
			}).catch((error) => {
				reject(error);
			})
		});
	}*/
}
