import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unvaliable-publication',
  templateUrl: './unvaliable-publication.component.html',
  styleUrls: ['./unvaliable-publication.component.scss'],
})
export class UnvaliablePublicationComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
