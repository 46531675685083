import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { Utils } from '../../../../Models/Classes/utils';
import { CommentsService } from '../../../../services/comments/comments.service';
import { ReactionssService } from 'src/app/services/reactions/reactions.service';
import { MultimediaService } from 'src/app/services/multimedia/multimedia.service';
import { InputAlertControllerService } from 'src/app/services/utils/input-alert-controller.service';
import { MultimediaViewComponent } from '../../single-elements/multimedia-view/multimedia-view.component';
import { CommentClass } from 'src/app/Models/Classes/Publications/Comments/comment-class';
import { PublicationService } from 'src/app/services/publications/publication.service';
import { PublicationClass } from 'src/app/Models/Classes/Publications/publication-class';
import { Capacitor } from '@capacitor/core';
import { isImage, isVideo } from 'src/app/utils';
import {element} from "protractor";

@Component({
	selector: 'app-comment',
	templateUrl: './comment.component.html',
	styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit {
	@Input() noLikes: boolean;
	@Input() comment: CommentClass;
	@Input() publication: PublicationClass;

	@Output() resfreshComments = new EventEmitter<any>();

	public timeSinceComment: string = "Justo ahora";
	completePictureUrl: string; // new property to hold the complete URL
	constructor(
		private utils: Utils,
		private commentService: CommentsService,
		private reactionService: ReactionssService,
		private multimediaService: MultimediaService,
		private navCtrl: NavController,
		private modalController: ModalController,
		private inputAlertController: InputAlertControllerService,
		private publicationService: PublicationService,
	) { }


	ngOnInit() {
		this.calcularTiempo();
		this.completePictureUrl = 'https://laniakea-multimedia.s3.amazonaws.com/' + this.comment.userProfile.profile_photo;
	}

	public isVideo(url: string) {
    const video_extensions = [
      "mp4",
      "avi",
      "mkv",
      "mov",
      "flv",
      "wmv",
      "mpg",
    ];
    let result = false;
    video_extensions.forEach((extension) => {
      if (url.includes('.' + extension)) {
        result = true;
      }
    });
    return result;
  }

	  public isImage(url: string){
      const image_extensions = [
        "jpg",
        "jpeg",
        "png",
        "gif",
        "bmp",
      ];
      let result  = false;
      image_extensions.forEach((extension) =>{
        if(url.includes('.'+extension)){
          result = true;
        }
      });
      return result;
	  }


	click_on_like() {
		let reaction = { type: "Me gusta" };

		if (this.comment.ownReaction) {
			//Delete
			this.reactionService.deleteReaction(this.comment.id, 'comments').then((data: any) => {

				this.comment.ownReaction = undefined;
				this.comment.reactionCounter--;

			}).catch((error) => {
				console.error(error);
				if(error.error){
					error = JSON.parse(error.error);
					if(error.error == 'the comment no longer exist'){
						this.commentService.detachCommentFromPublicationByID(this.comment.id, this.publication);
					}
					if(error.error == "You have no reaction in this comment"){
						this.commentService.getCommentFromServer(this.publication.id, this.comment.id).then((commentObject)=>{
							this.commentService.updateCommentList(commentObject, this.publication);
						}).catch((error)=>{
							console.error(error);
						});
					}
				}

			});
		}
		else {
			this.reactionService.postReaction(reaction, this.comment.id, 'comments').then((data: any) => {

				data = JSON.parse(data);

				let objectReaction = {
					ownReaction: {
						reaction_group_id: data.reaction_group_id,
						reaction_icon: null,
						reaction_id: data.reaction_id,
						reaction_name: data.reaction_apllied
					}
				};

				this.publicationService.getOwnReaction_Publications(objectReaction).then((ownReaction) => {
					this.comment.ownReaction = ownReaction;
					this.comment.reactionCounter++;

				});

			}).catch((error) => {
				console.error(error);
				if(error.error){
					error = JSON.parse(error.error);
					if(error.error == 'the comment no longer exist'){
						this.commentService.detachCommentFromPublicationByID(this.comment.id, this.publication);
					}
					if(error.error == "you can only reaction once per comment"){
						this.commentService.getCommentFromServer(this.publication.id, this.comment.id).then((commentObject)=>{
							this.commentService.updateCommentList(commentObject, this.publication);
						}).catch((error)=>{
							console.error(error);
						});
					}
				}
			});
		}

	}

	calcularTiempo() {
		if (this.comment.timestamp != undefined) {
			let now = new Date((new Date(Date.now() - (new Date()).getTimezoneOffset())));
			let timestamp_publication = new Date(this.comment.timestamp);

			//Segundos
			let difference = ((now.getTime() / 1000) - timestamp_publication.getTime());
			(difference < 0) ? difference = difference * -1 : difference = difference;
			if (difference < 60) {
				this.timeSinceComment = difference.toFixed(0) + " seg";
			}
			else {

				//Minutos
				difference = difference / 60;
				if (difference < 60) {
					this.timeSinceComment = difference.toFixed(0) + " min";
				}
				else {

					//Horas
					difference = difference / 60;
					if (difference < 24) {
						this.timeSinceComment = difference.toFixed(0) + "  hrs";
					}
					else {

						//Dias
						difference = difference / 24;
						this.timeSinceComment = difference.toFixed(0) + "  dia";
						if (Number(difference.toFixed(0)) > 1) {
							this.timeSinceComment += "s";
						}
					}
				}
			}
		}

	}

	isOwner() {
    console.log('commnet',this.comment);
		if (this.comment.owner) {
			this.presentActionSheet();
		}
	}

	presentActionSheet() {
		let buttons = new Array<any>();
		buttons.push({ text: 'Editar', icon: 'create' });
		buttons.push({ text: 'Eliminar', icon: 'trash' });

		this.inputAlertController.SelectorActionSheet(buttons, 'Opciones sobre tu comentario').then((result) => {
			if (result['action']) {
				switch (result['button']['text']) {
					case 'Editar': {
						this.editAlertPrompt();
						break;
					}
					case 'Eliminar': {
						this.deleteComment();
						break;
					}
					default: break;

				}
			}
		});
	}

	public deleteComment() {

		this.utils.loadingPresent().then(() => {
			this.commentService.deleteComment(this.comment.publication_id, this.comment.id).then((data) => {
				this.commentService.detachCommentFromPublicationByID(this.comment.id, this.publication);
				this.utils.loadingDismiss();

				/*if (this.comment.multimediaContainer.length > 0) {
					this.deleteMultimedia().catch((err) => {
						console.error(err);
					}).finally(() => {
						this.commentService.detachCommentFromPublicationByID(this.comment.id, this.publication);
						this.utils.loadingDismiss();
					});
				}
				else {
					this.commentService.detachCommentFromPublicationByID(this.comment.id, this.publication);
					this.utils.loadingDismiss();
					//this.utils.alertPresent('Exito', 'Publicación eliminada', 'Se ha eliminado el comentario');
				}*/
			}).catch(error => {
				console.error(error);
				this.utils.loadingDismiss();
				if(error.error){
					error = JSON.parse(error.error);
					if(error.error == 'the comment no longer exist'){
						this.commentService.detachCommentFromPublicationByID(this.comment.id, this.publication);
					}
				}
				//this.utils.alertPresent('Error', 'Comentario', 'No se ha eliminado el comentario');
			});
		});


	}

	public deleteMultimedia() {
		return new Promise((resolve, reject) => {
			this.multimediaService.deleteMultimedia(this.comment.id, this.comment.multimediaContainer[0].id, 'comments').then((data) => {
				resolve(data);
			}).catch(error => {
				console.error(error);
				reject(error);
			});
		});

	}

	editAlertPrompt() {

		this.inputAlertController.InputAlertPrompt(
			"Editar Comentario", 										//Header
			"",															//Message
			this.comment.text, 													//Input Value
			"text", 													//Input Type
			"Editar"													//Ok Button Text
		).then((result) => {
			if (result['action']) {
				if (!result['value'].match(/^ *$/)) {
					let updateComment = {
						text: result['value'].trim(),
						status: "Activa"
					}
					this.updateComment(updateComment);
				} else {
					this.errorAlert();
				}
			}
		});
	}

	errorAlert() {
		this.inputAlertController.OkInfoMessage('Error', 'El comentario no puede ir vacío');
	}

	public updateComment(updateComment: any) {

		this.utils.loadingPresent().then(() => {
			this.commentService.putComment(updateComment, this.comment.publication_id, this.comment.id).then((data: any) => {
				data = JSON.parse(data);
				this.commentService.updateCommentList(data.comment, this.publication);

				this.utils.loadingDismiss();
			}).catch((error) => {
				console.error(error);
				this.utils.loadingDismiss();
				if(error.error){
					error = JSON.parse(error.error);
					if (error.error == 'the publication no longer exist') {
						this.modalController.dismiss();
						this.publicationService.DeleteLocalPublicationByID(this.publication.id);
					}
					if(error.error == 'the comment no longer exist'){
						this.commentService.detachCommentFromPublicationByID(this.comment.id, this.publication);
					}
				}
			});
		});

	}


	Click_On_User() {
		this.modalController.dismiss().finally(() => {
			this.navCtrl.navigateForward('home/profile/' + this.comment.userProfile.user_id);
		});

	}

	openMediaInModal() {
		this.modalController.create({
			component: MultimediaViewComponent,
			cssClass: 'detailsInModal',
			swipeToClose: true,
			componentProps: {
				multimedia: this.comment.multimediaContainer
			}
		}).then((modal) => {
			modal.present();
		});

	}

}
