import { Component, OnInit } from '@angular/core';
import { FormServiceService } from 'src/app/services/publications/form-service.service';


@Component({
  selector: 'app-eclipse-eventos3',
  templateUrl: './eclipse-eventos3.component.html',
  styleUrls: ['./eclipse-eventos3.component.scss'],
})
export class EclipseEventos3Component implements OnInit {

  constructor(public formservice:FormServiceService) { }

  ngOnInit() {}
  prueba:boolean=false;
  
  otro($event){
    this.prueba=!this.prueba
   
  }

  validarstreaming:boolean=false;
  streaming($event){
    this.validarstreaming=!this.validarstreaming
    
  }
  
  addPrice(event) {
    this.formservice.eventospublication.price=event.target.value
  }

  ubicacion(event) {
  
    this.formservice.eventospublication.ubicacion=event.target.value
  }

  addLink(event) {
    this.formservice.eventospublication.link=event.target.value
  }

  addLimited_availability(event) {

    this.formservice.eventospublication.limited_availability=event.target.value
  }

}
