import { isNull } from '@angular/compiler/src/output/output_ast';
import { EventEmitter, Injectable } from '@angular/core';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { Notification } from 'src/app/Models/Classes/Notifications/notification';
import { RequestService } from '../request/request.service';

@Injectable({
	providedIn: 'root'
})
export class NotificationService {
	protected intervalHandle: any = null;


	public notificationsList: Array<Notification>;
	public notificationCounter: number;
	public unreadNotificationCounter: number;
	public eventNotification = new EventEmitter<number>();

	constructor(
		private request: RequestService,
		private nativeAudio: NativeAudio,
	) {

	}

	public FetchNotificationGeneralCounter() {
		return new Promise((resolve, reject) => {
		  this.request.createRequestGet('GenaralNotificationsCounter', {})
			.then((data) => {
			  console.log('NotificationsCounter', data);
			  resolve(data); // Resolve the promise with the data
			})
			.catch((error) => {
			  console.error('Failed to send request:', error);
			  reject(error); // Reject the promise with an error
			});
		});
	  }
	  
	public ReadingNotifications(){
		this.request.createRequestPost('UpdateNotificationRecord', {profile:null}) .then((data) => {
			
console.log(data);
	  }).catch((error) => {
		  console.error('Failed to send request:', error);
	  });
	}
	public LaniakeaConversation(){
		this.request.createRequestPost('LaniakeaConversation',{}) .then((data) => {
			

	  }).catch((error) => {
		  console.error('Failed to send request:', error);
	  });
	}

	/*

	initialize() {
		return new Promise((resolve, reject) => {

			this.notificationsList = new Array<Notification>();
			this.notificationCounter = 0;
			this.unreadNotificationCounter = 0;


			this.updateNotificationList().then((data) => {
				
				//this.ToggleServiceNotifications();
				resolve(true);
			}).catch((error) => {
				reject(error);
			});
		});
	}

	private updateNotificationList() {
		return new Promise((resolve, reject) => {


			this.notificationCounter = 0;
			this.unreadNotificationCounter = 0;

			this.fetchNotifications().then((dataList: any) => {

				dataList.forEach(notification => {
					let newNoti = new Notification(notification);

					let found = this.notificationsList.find(element => element.id == newNoti.id);
					if (found != null) {
						if (found.notification_status != newNoti.notification_status) {
							let index = this.notificationsList.indexOf(found);
							if (index !== -1) {
								this.notificationsList[index] = newNoti;
							}
						}
					}
					else {
						//this.notificationsList.push(newNoti);
						this.notificationsList.splice(0,0, newNoti);
						if (newNoti.notification_status == "Sin Leer") {
							this.unreadNotificationCounter++;
							this.eventNotification.emit(this.unreadNotificationCounter);
							this.nativeAudio.play('new_notification').catch(()=>{});
						}
					}

				});

				//Proceso de Eliminar Notificaciones Descartadas
				this.notificationsList.forEach((element, index) => {
					let found = dataList.find(actualElement => actualElement.id == element.id);
					if (found == null) {
						this.notificationsList.splice(index, 1);
					}
				});

				
				this.notificationCounter = this.notificationsList.length;
				resolve(this.notificationsList);
			}).catch((error) => {
				reject(error);
			});
		});
	}

	public ToggleServiceNotifications() {
		if (this.intervalHandle === null) {
			this.intervalHandle = setInterval(() => {
				this.updateNotificationList().catch((error) => {
					console.error(error);
					this.ToggleServiceNotifications();
				});
			}, 5000);
		}
		else {
			clearInterval(this.intervalHandle);
			this.intervalHandle = null;
		}
	}

	private fetchNotifications() {
		return new Promise((resolve, reject) => {
			this.request.createRequestGet('notifications').then((data) => {
				let dataList = JSON.parse(data);
				resolve(dataList);
			}).catch((error) => {
				reject(error);
			});
		});
	}

	public putNotification(id_notification: number, status: any){
		return new Promise((resolve, reject) => {
			var params = { 
				"id": id_notification
			}
			this.request.createRequestPut('notifications', status, params).then((data) => {
				resolve(JSON.parse(data));
			}).catch((error) => {
				reject(error);
			});
		});
	}

	public deleteNotification(id_notification:number){	
		return new Promise((resolve, reject) => {
			var params = { 
				"id": id_notification
			}	
			this.request.createRequestDelete('notifications', null, params).then((data) => {
				resolve(JSON.parse(data));
			}).catch((error) => {
				reject(error);
			});
		});
	}
	*/
}
