export class SellerProfile {
    user_id: number;
    username: string;
    profile_photo: string;
    

    constructor(object?: {user_id: number, username: string, profile_photo: {url: string},} ) {
        if (object != null) {
            this.user_id = (object.user_id != null) ? object.user_id : undefined;
            this.username = (object.username != null) ? object.username : undefined;
            this.profile_photo = (object.profile_photo != null) ? object.profile_photo.url : undefined;
        }

    }
}
