import {Component, OnInit, Input, AfterViewInit, OnChanges, SimpleChanges} from '@angular/core';
import { NgModule } from '@angular/core';
import {ModalController} from "@ionic/angular";
import { PublicacionesColeccionComponent } from '../publication/create/publicaciones-coleccion/publicaciones-coleccion/publicaciones-coleccion.component';
import {FormServiceService} from "../../services/publications/form-service.service";
// Import Output and EventEmitter to emit events from this component
import { ActualizacionServiceService } from 'src/app/services/actualizacion/Actualizacion-service.service';

@Component({
  selector: 'app-paid-view-by-slides',
  templateUrl: './paid-view-by-slides.component.html',
  styleUrls: ['./paid-view-by-slides.component.scss'],
})
export class PaidViewBySlidesComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() publication;
  @Input() publications: any[] = [];
  @Input() index;
  xDown = null;
  yDown = null;
  publicaciones: any;
  @Input() valueSelect: string;

  constructor(
    private modalController: ModalController,
    public formService:FormServiceService,
    private actualizacionservice:ActualizacionServiceService,
  ) { }

  ngOnInit() {
    console.log('videos', this.publication);
    console.log('LK FILME', this.publications);

    this.addEventButton();
    //this.fetchPublications2();
  }

  ngAfterViewInit() {
    this.addEventButton();
  }
  async fetchPublications2(){
    const result = await this.actualizacionservice.getPublications(null, false, null, null, null);
    console.log("result en publication media template",result);
    this.publications = result;
    return result;
  }

  getTouches(evt) {
    return evt.touches ||
      evt.originalEvent.touches;
  }

  handleTouchStart(evt) {
    const firstTouch = this.getTouches(evt)[0];
    this.xDown = firstTouch.clientX;
    this.yDown = firstTouch.clientY;
  };

  handleTouchMove(evt) {
    if ( ! this.xDown || ! this.yDown ) {
      return;
    }

    const xUp = evt.touches[0].clientX;
    const yUp = evt.touches[0].clientY;

    let xDiff = this.xDown - xUp;
    let yDiff = this.yDown - yUp;

    if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
      if ( xDiff > 0 ) {
        /* right swipe */
      } else {
        /* left swipe */
      }
    } else {
      if ( yDiff < 0 ) {
        console.log("Swipe ejecutado para abajo");
        this.modalController.dismiss();
      } else {
        console.log("Swipe ejecutado para arriba");
        this.modalController.dismiss();
      }
    }
    /* reset values */
    this.xDown = null;
    this.yDown = null;
  };

  addEventButton() {
    console.log('adding event listener');
    document.getElementById('closeButton').addEventListener('touchstart', e=>{
      this.handleTouchStart(e);
    });
    document.getElementById('closeButton').addEventListener('touchmove', e => {
      this.handleTouchMove(e);
    });
  }
  showClic(){
    this.modalController.dismiss();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('lkfilm view by slides Changes detected at ', changes);
  }

  home(){
    this.closeAllModals();
  }
  async closeAllModals() {
    await this.modalController.dismiss();
  }

  coleccion(index) {
    this.formService.sectionVer="lkfilme"
    console.log("section select",this.formService.sectionVer);

    this.modalController
      .create({

        component: PublicacionesColeccionComponent,
        cssClass: 'detailsInModal',
        swipeToClose: true,
        componentProps: {
          publications:this.publicaciones,
          index: index
        }
      },

      )
      .then((modal) => modal.present())
  }
  

}
