import { CalendaryComponent } from '../calendary/calendar.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarioComponent } from '../calendario/calendario.component';
import { FormsModule } from '@angular/forms';
//import {PagosPorPublicacionComponent} from 'src/app/components/publication/create/Pagos/Pagos-por-publicacion/Pagos-por-publicacion.component';





@NgModule({
  declarations: [ CalendaryComponent,

 ],
  entryComponents:[
 ],
  exports: [CalendaryComponent,
    
   ],
  imports: [
    CommonModule,
    FormsModule,
  
  ]
})
export class CalendariomodulModule {}
