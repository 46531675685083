import { MediaItem } from "../../../Media/media-item";

export class State{
    title: string;
    media: MediaItem;
    constructor(state?:any, media?: MediaItem){
        if (state && media) {
            this.title = (state.title)? state.title : "";
            this.media = (media)?media:null;
        } else {
            //State
            this.title = "";
            this.media = null;
        }

    }
}
