import { Component, OnInit } from '@angular/core';
import { NavController, PopoverController, Platform } from '@ionic/angular';
import {  ActivatedRoute } from '@angular/router';
import { Notification } from 'src/app/Models/Classes/Notifications/notification';
import { Utils } from 'src/app/Models/Classes/utils';
import { NotificationService } from 'src/app/services/notifications/notification.service';
import { InputAlertControllerService } from 'src/app/services/utils/input-alert-controller.service';
import { Router } from '@angular/router';
import { ModulesLockComponent } from 'src/app/components/go-ecosystem/modules-lock/modules-lock.component';
import { ModalController } from '@ionic/angular';
import { FriendshipService } from 'src/app/services/relations/relations.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { RequestService } from 'src/app/services/request/request.service';
import { Capacitor } from '@capacitor/core';
import { MessagesListComponent } from 'src/app/components/chat/messages-list/messages-list.component';
import { Conversation } from 'src/app/Models/Classes/Chat/conversation';
import { ChatService } from 'src/app/services/chat/chat.service';
import { RelationsListComponent } from 'src/app/components/chat/relations-list/relations-list.component';
import { UserContact } from 'src/app/Models/Classes/User/user-contact';
@Component({
	selector: 'app-notification-list',
	templateUrl: './notification-list.component.html',
	styleUrls: ['./notification-list.component.scss'],
})
export class NotificationListComponent implements OnInit {
	public contactList : Array<UserContact> = new Array<UserContact>();
	public notificationList: Array<Notification>; //Le llega desde donde de invoca, Header-Bar-Component
	public relations = new Array<any>();
	actualSection: string = 'PANEL';
	public friendships_filter: string = "Amigos";
	public RegalosyComprasFilter: string = "Regalos";
	public filtro_actual: string = "Cuentas";
	public refrescar: number = 0;
	public available_sections: Array<string> = [ "Amigos", "Siguiendo", "Seguidores", "Súper A."];
	public RegalosyComprasAvailable_sections: Array<string> = [ "Regalos", "Ventas"];
	public filtros_disponibles = new Array<string>();
	public profile: any = null;
	public profile_id: number;
	private friendsArrayHeader = Array<string>();
	private friendsArrayMessage = Array<string>();
	private superArrayHeader = Array<string>();
	private superArrayMessage = Array<string>();

	constructor(
		public notificationsService: NotificationService,
		private utils: Utils,
		private popOverController: PopoverController,
		private navController: NavController,
		private inputAlertController: InputAlertControllerService,
		private router: Router,
		private modalController: ModalController,
		public friendshipService: FriendshipService,
		private route: ActivatedRoute,
		private plaftorm: Platform,
		private profile_service: ProfileService,
		private request: RequestService,
		private chatService: ChatService,
	) { 
		this.profile_id = this.route.snapshot.params.profile_id;
		this.plaftorm.ready().then(()=>{
			this.route.queryParams.subscribe(params => {

				if (params['filter']) {
					this.filtro_actual = params['filter'];
					this.refrescar += 1;
					
				}
	
			});
		});
	}

	ngOnInit() {
		this.notificationsService.ReadingNotifications();

		console.log('profile in sections profile', this.profile);
		if (this.profile && this.profile.username) {
    this.friendsArrayHeader = [
      "Enviar solicitud de amistad",
      "Cancelar solicitud de amistad",
      "Re-enviar solicitud de amistad",
      "Aceptar solicitud de amistad",
      "Eliminar relación de amistad"];
    this.friendsArrayMessage = [
      "¿Deseas enviar una solicitud de amistad a @" + this.profile.username + "?",
      "¿Deseas cancelar tu solicitud de amistad enviada a @" + this.profile.username + "?",
      "¿Deseas enviar nuevamente una solicitud de amistad a @" + this.profile.username + "?",
      "¿Deseas aceptar la solicitud de amistad de @" + this.profile.username + "?",
      "¿Deseas eliminar tu relación de amistad con @" + this.profile.username + "?"];

    this.superArrayHeader = [
      "Enviar solicitud de súper amigos",
      "Cancelar solicitud de súper amigos",
      "Re-enviar solicitud de súper amigos",
      "Aceptar solicitud de súper amigos",
      "Eliminar relación de súper amigos"];
    this.superArrayMessage = [
      "¿Deseas enviar una solicitud de súper amigos a @" + this.profile.username + "?",
      "¿Deseas cancelar tu solicitud de súper amigos enviada a @" + this.profile.username + "?",
      "¿Deseas enviar nuevamente una solicitud de súper amigos a @" + this.profile.username + "?",
      "¿Deseas aceptar la solicitud de súper amigos de @" + this.profile.username + "?",
      "¿Deseas eliminar tu relación de súper amigos con @" + this.profile.username + "?"];

	}
      this.filtros_disponibles.push("Cuentas");
	  

	}
	ionViewWillEnter() {

		this.profile_service.getProfileInfo(this.profile_id).then((profileInfo)=>{
			profileInfo = JSON.parse(profileInfo);
			this.profile =profileInfo;
			this.profile_id = profileInfo.id;
		}).catch((error)=>{
			console.error(error);
		}).finally(()=>{

		});

	}
	reloadProfile(){
		this.profile_service.getProfileInfo(this.profile_id).then((data)=>{
			data = JSON.parse(data);

			this.profile = data;
			this.profile_id = data.id;
		}).catch((error)=>{
			console.error(error);
		}).finally(()=>{

		});
	}

/*
	notificationActionButtons(notification: Notification, buttonAction: string){
		this.utils.loadingPresent().then(()=>{
			if(buttonAction == "Archivar"){
				this.notificationsService.putNotification(notification.id, {status: "archivada"}).then((result)=>{
					this.hideNotification(notification);

				}).catch((error)=>{
					console.error(error);
				}).finally(()=>{
					this.utils.loadingDismiss();
				});
			}
			else if(buttonAction == "Eliminar"){
				this.notificationsService.deleteNotification(notification.id).then((result)=>{
					this.hideNotification(notification);

				}).catch((error)=>{
					console.error(error);
				}).finally(()=>{
					this.utils.loadingDismiss();
				});
			}
			else{
				this.utils.loadingDismiss();
			}
		})

	}
*/
	hideNotification(notification: Notification){
		//Proceso de Eliminar Notificaciones Descartadas

		let index = this.notificationsService.notificationsList.indexOf(notification, 0);
		if (index > -1) {
			this.notificationsService.notificationsList.splice(index, 1);
		}
		this.notificationsService.notificationCounter = this.notificationsService.notificationsList.length;
	}


	/*
	openNotification(notification: Notification){

		if(notification.notification_status == "Sin Leer"){
			this.notificationsService.putNotification(notification.id, {status: "Leida"});
			notification.notification_status = "Leida";
		}

		this.popOverController.dismiss().finally(()=>{
			switch (notification.notifiable) {
				case "relation":
					this.navController.navigateForward('home/profile/'+notification.relation.user_info.profile_id);
					break;

				default:
					this.inputAlertController.OkInfoMessage(
						"Función en desarrollo", 												//Header
						"Estamos trabajando para que puedas usar esta función lo antes posible",//Message
						"Esperar una versión futura"											//Ok Button Text
					);
					break;
			}

		});

	}
	*/
	openGiftsPurchasesAndSubscriptions() {

		this.actualSection = 'RegalosyVentas';
		this.getRegalosorVentas();
		
		//this.modalController.create({
			// component: HospedajeEarthViewPage
  //component: ModulesLockComponent
	//	}).then((modal)=>{
	//		modal.present();
	//	});
	}
	openCommentsLikesAndSaves() {
		this.modalController.create({
			// component: HospedajeEarthViewPage
  component: ModulesLockComponent
		}).then((modal)=>{
			modal.present();
		});
	}
	openNewFollowers() {
		this.actualSection = 'Relaciones';
		this.getFriends();
		this.friendships_filter = "Seguidores";
		//this.modalController.create({
			// component: HospedajeEarthViewPage
  //component: ModulesLockComponent
	//	}).then((modal)=>{
	//		modal.present();
	//	});
	}
	openFriendRequests() {
		this.actualSection = 'Relaciones';
		this.getFriends();
	
		this.friendships_filter = "Amigos";
		//this.modalController.create({
			// component: HospedajeEarthViewPage
  //component: ModulesLockComponent
	//	}).then((modal)=>{
			//modal.present();
	//	});
	}

	openNewDMs() {
		this.router.navigate(['/messages-page']);
	  }
	  openLaniakeaChat(){
		
		this.router.navigate(['/home/messages-page']);  
		
		this.notificationsService.LaniakeaConversation();
		this.modalController.create({
			component: RelationsListComponent,
			swipeToClose: true,
			componentProps: {
				contactList: this.contactList,
				Laniakea_chat:true
			}
		}).then((modal)=>{
			modal.present();
		}).catch((error)=>{
			console.error(error);
		});
		
	  }
	  friends_filter_change(filtro){
		this.friendships_filter = filtro;
		this.getFriends();
	}
	RegalosyCompras_filter_change(filtro){
		this.RegalosyComprasFilter = filtro;
		this.getRegalosorVentas();
	}
	  
	  public getFriends() {
		this.utils.loadingPresent().then(()=>{
			let relationType = [2];
			let relationDirection =  2;
			let relationStatus = 1;
			switch (this.friendships_filter) {
				case "Amigos":
					relationDirection =  2;
					relationType  = [1];
					relationStatus = 1;
					break;
				case "Súper A.":
					relationDirection =  2;
					relationType  = [2];
					relationStatus = 1;
					break;
				case "Siguiendo":
					relationDirection = 1;
					relationType  = [3];
					relationStatus = 2;
					break;
				case "Seguidores":
					relationDirection =  2;
					relationType  = [3];
					relationStatus = 2;
					break;

				default:
					break;
			}
			if (Capacitor.getPlatform() !== 'web') {
			let request = {
				queryParams: {
					'relationDirection': relationDirection.toString(),  // 1 Envio, 2 Recibido, 3 Todos
					'relationType': relationType,   // 1 Amigo, 2 S Amigos, 3 Seguidores
					'relationStatus': relationStatus.toString(),  // 1 Pendiente, 2 Confirmado
					//'user_id': this.profile_id,
				}
			};
			this.friendshipService.getAllRelations(request.queryParams).then((data) => {
			


				

					this.relations = JSON.parse(data);
				  
				
        console.log('relations', this.relations);
			}).catch((error) => {
			}).finally(()=>{
				this.utils.loadingDismiss();
			});

			
		}else{

			let request = {
				queryParams: {
					'relationDirection': relationDirection.toString(),  // 1 Envio, 2 Recibido, 3 Todos
					'relationType': relationType,   // 1 Amigo, 2 S Amigos, 3 Seguidores
					'relationStatus': relationStatus.toString(),  // 1 Pendiente, 2 Confirmado
					//'user_id': this.profile_id,
				}
			};
			this.friendshipService.getAllRelations(request.queryParams).then((data) => {

			if (typeof data === 'string') {
				data = JSON.parse(data);
						   console.log('json result publication service.ts', data);
				 this.relations =  data;
						 }else {
				this.relations = data; 
			   }
			   console.log('relations', this.relations);
			}).catch((error) => {
			}).finally(()=>{
				this.utils.loadingDismiss();
			});

			
		}

			
		});

	}
	public getAllRelations(relation: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this.request.createRequestGet('relations', relation)
				.then((data) => {
					resolve(data);
				}).catch((error) => {
					console.error('Failed to get all relations:', error);
					reject(error);
				});
		});
	}

	getRegalosorVentas() {
		this.utils.loadingPresent().then(() => {
		  switch (this.RegalosyComprasFilter) {
			case "Regalos":
			  this.getRegalos();
			  break;
			case "Ventas":
			  this.getVentas();
			  break;
			default:
			  break;
		  }
		}).finally(() => {
			this.utils.loadingDismiss();
		  });
	  }
	  
	  public getRegalos(): Promise<any> {
		return new Promise((resolve, reject) => {
		  this.request.createRequestGet('RegalosNotifications', null, { id: this.profile_id })
			.then((result) => {
			  if (result ) { // Check if the 'data' property exists in the result
				console.log("Successfully fetched Regalos info.");
				console.log("Regalos info:", result);
			
			
			
				console.log('relations', this.relations);
				// Process the fetched data as needed
				if (Capacitor.getPlatform() === 'web') {
					if (typeof result === 'string') {
						result = JSON.parse(result);
								   console.log('json result publication service.ts', result);
						 this.relations = result; 
								 }else {
						this.relations = result; 
					   }
				  }else{
					this.relations = JSON.parse(result);// Assign the 'data' property to the 'relations' variable
				  }
	  
				resolve(result); // Resolve the promise with the result
			  } else {
				console.log("No results found.");
				resolve(null); // Resolve the promise with null if no results found
			  }
			})
			.catch((error) => {
			  console.error("Error fetching Regalos info:", error);
			  reject(error); // Reject the promise with the error
			})
		
		});
	  }
	  
	  public getVentas(): Promise<any> {
		return new Promise((resolve, reject) => {
		  this.request.createRequestGet('VentasNotifications', null, { id: this.profile_id })
			.then((result) => {
			  if (result) { // Check if the 'data' property exists in the result
				console.log("Successfully fetched Ventas info.");
				console.log("Ventas info:", result);
			
			
				
				console.log('relations', this.relations);
				// Process the fetched data as needed
				if (Capacitor.getPlatform() === 'web') {
					if (typeof result === 'string') {
						result = JSON.parse(result);
								   console.log('json result publication service.ts', result);
						 this.relations = result; 
								 }else {
						this.relations = result; 
					   }
				  }else{
					this.relations = JSON.parse(result); // Assign the 'data' property to the 'relations' variable
				  }
	  
				resolve(result); // Resolve the promise with the result
			  } else {
				console.log("No results found.");
				resolve(null); // Resolve the promise with null if no results found
			  }
			})
			.catch((error) => {
			  console.error("Error fetching Ventas info:", error);
			  reject(error); // Reject the promise with the error
			})
		
		});
	  }
	  

	
	 
	  

}

