import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terminos-condiciones-view',
  templateUrl: './terminos-condiciones-view.page.html',
  styleUrls: ['./terminos-condiciones-view.page.scss'],
})
export class TerminosCondicionesViewPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
