import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { IonicModule } from '@ionic/angular'
import { CreatePublicationPage } from './create-publication.page'
import { CreatePublicationPageRoutingModule } from './create-publication-routing.module'
import { PublicationCreateModuleModule } from 'src/app/components/publication/create/publication-create-module/publication-create-module.module'
import { BienesModuleModule } from 'src/app/components/publication/create/principal-components/bienes-module/bienes-module.module'
import { EarthModuleModule } from 'src/app/components/publication/create/principal-components/earth-module/earth-module.module'
import { EclipseModuleModule } from 'src/app/components/publication/create/principal-components/eclipse-module/eclipse-module.module'
import { EpochModuleModule } from 'src/app/components/publication/create/principal-components/epoch-module/epoch-module.module'
import { LandModuleModule } from 'src/app/components/publication/create/principal-components/land-module/land-module.module'
import { LkfilmModuleModule } from 'src/app/components/publication/create/principal-components/lkfilm-module/lkfilm-module.module'
import { DescriptionModuleModule } from 'src/app/components/publication/create/description-module/description-module.module'
import { NormalPublicationModule } from 'src/app/components/publication/create/principal-components/normal-publication/normal-publication.module'
import { CarritoModule } from 'src/app/components/publication/create/carrito-de-compras/carrito/carrito.module'
import { CompraFinalizadaModule } from 'src/app/components/publication/create/finalizar-compra/compra-finalizada/compra-finalizada.module'
import { PagoCompletadoModule } from 'src/app/components/publication/create/pago-completo/pago-completado/pago-completado.module'
import { HistoryViewModule } from 'src/app/components/history-view/history-view.module'

@NgModule({
  imports: [
    CommonModule, 
    FormsModule, 
    ReactiveFormsModule,
    IonicModule,
    PublicationCreateModuleModule,
    BienesModuleModule,
		EarthModuleModule,
		EclipseModuleModule,
		EpochModuleModule,
		EclipseModuleModule,
		LandModuleModule,
		LkfilmModuleModule,
    CarritoModule,
    CompraFinalizadaModule,
    PagoCompletadoModule,
    NormalPublicationModule,
    DescriptionModuleModule,
    CreatePublicationPageRoutingModule,
    HistoryViewModule
  ],
  declarations: [
    CreatePublicationPage
  ]
})
export class CreatePublicationPageModule {



  
}
