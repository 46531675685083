import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalDateTimeComponent } from 'src/app/components/common-elements/inputs/modal-date-time/modal-date-time.component';
import { MediaItem } from 'src/app/Models/Classes/Media/media-item';
import { EventPublication } from 'src/app/Models/Classes/Publications/Event/event-publication';
import { PublicationClass } from 'src/app/Models/Classes/Publications/publication-class';

@Component({
	selector: 'app-event-publication',
	templateUrl: './event-publication.component.html',
	styleUrls: ['./event-publication.component.scss'],
})
export class EventPublicationComponent implements OnInit {
	public tituloLabel: string = "Nombre del evento";
	public descripcionLabel: string = "Descripción";


	public dateTimeStart: string = "Fecha y Hora De Inicio";
	public dateTimeEnd: string = "Fecha y Hora De Fin";

	public type: number;
	public fabActivated: boolean = false;

	@Input() eventImage: MediaItem;
	@Input() publication: EventPublication;

	@Input() isEditing: boolean = false;
	

	@Output() checkIfCanPost = new EventEmitter<string>();
	/*
	@Output() inputValue = new EventEmitter<string>();
	@Output() textValue = new EventEmitter<string>();
	@Output() dateStartValue = new EventEmitter<string>();
	@Output() dateEndValue = new EventEmitter<string>();
	*/

	@Output() deleteMedia = new EventEmitter<any>();

	constructor(
		public modalController: ModalController
	) { }

	ngOnInit() {
		if (this.publication.timestamp_start) {
			let start = this.publication.timestamp_start;
			this.dateTimeStart = new Date(start * 1000).toLocaleDateString() + ' ' + new Date(start * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
			if (this.publication.timestamp_finish) {
				let finish = this.publication.timestamp_finish;
				this.dateTimeEnd = new Date(finish * 1000).toLocaleDateString() + ' ' + new Date(finish * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
			} else {
				this.dateTimeEnd = "Fecha y Hora De Fin";
			}

		} else {
			this.dateTimeStart = "Fecha y Hora De Inicio";
			this.dateTimeEnd = "Fecha y Hora De Fin";

		}
	}


	presentModal(type: string) {
		this.modalController.create({

			component: ModalDateTimeComponent,
			cssClass: 'my-custom-modal-css detailsInModal',
			swipeToClose: true,
			
			componentProps: {
				'title': type,
				'dateStart': this.publication.timestamp_start,
				'dateEnd': this.publication.timestamp_finish
			}
		}).then((modal)=>{
			modal.onDidDismiss().then((dataReturned) => {
				if (dataReturned.data != null) {
					const { inicio, anterior } = dataReturned.data;
	
					if (type == 'Inicio') {
						this.dateTimeStart = new Date(inicio * 1000).toLocaleDateString() + ' ' + new Date(inicio * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
						this.returnDateStart(inicio);
	
						if (anterior == null) {
							this.dateTimeEnd = "Fecha y Hora De Fin";
						} else {
							this.returnDateEnd(anterior);
						}
	
					}
					else {
						if (anterior == null) {
							this.dateTimeEnd = "Fecha y Hora De Fin";
						} else {
							this.dateTimeEnd = new Date(anterior * 1000).toLocaleDateString() + ' ' + new Date(anterior * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
							this.returnDateEnd(anterior);
						}
	
					}
				}
			});
			modal.present();
		});

	}

	/*
	returnInputValue(event) {
		
		
		//this.inputValue.emit(event.detail.value);
	}

	returnTextValue(event) {
		this.textValue.emit(event.detail.value);
	}

	

	*/

	returnTitleValue(event) {
		this.publication.title = event.detail.value;
		this.checkIfCanPost.emit();
	}

	returnDescriptionValue(event) {
		this.publication.description = event.detail.value;
		this.checkIfCanPost.emit();
	}

	returnDateStart(value) {
	
		this.publication.timestamp_start = value;
		this.checkIfCanPost.emit();
		//this.dateStartValue.emit(value);
	}

	returnDateEnd(value) {
		
		this.publication.timestamp_finish = value;
		this.checkIfCanPost.emit();
		//this.dateEndValue.emit(value);
	}

	deleteImage() {
		this.publication.deleteMultimedia(0);
		this.deleteMedia.emit();
	}

	public getSrcFromUri(path:string){
		let src = (<any>window).Ionic.WebView.convertFileSrc(path);
		return src;
	}
}
