import { Component, Input, OnInit, } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Utils } from 'src/app/Models/Classes/utils';
import { InputAlertControllerService } from 'src/app/services/utils/input-alert-controller.service';

@Component({
	selector: 'app-modal-date-time',
	templateUrl: './modal-date-time.component.html',
	styleUrls: ['./modal-date-time.component.scss'],
})
export class ModalDateTimeComponent implements OnInit {

	public dateTime: Date = new Date();
	public string_dateTime: string = undefined;
	public minYear: number;
	public maxYear: number;
	PickerOptionsForHour: any;
	PickerOptionsForDate: any;

	@Input() title: string;
	@Input() dateStart: number;
	@Input() dateEnd: number;

	constructor(
		private modalController: ModalController,
		private utils: Utils,
		private inputAlertControlleR: InputAlertControllerService,
	) {
		this.string_dateTime = this.dateTime.toISOString();
		this.PickerOptionsForDate = {
			buttons: [
				{
					text: 'Cancelar',
				}, {
					text: 'Guardar',
					handler: (fullDate) => {
						this.dateTime.setFullYear(fullDate['year']['value'], fullDate['month']['value']-1, fullDate['day']['value']);
						this.string_dateTime = this.dateTime.toISOString();
					}
				}
			],
			cssClass: "loading-controller-class"
		};

		this.PickerOptionsForHour = {
			buttons: [
				{
					text: 'Cancelar',
				}, {
					text: 'Guardar',
					handler: (fullTime) => {
						this.dateTime.setHours( (fullTime['ampm']['value'] == "am")? fullTime['hour']['value']: fullTime['hour']['value']+12, fullTime['minute']['value'], 0, 0);
						this.string_dateTime = this.dateTime.toISOString();
					}
				}
			],
			cssClass: "loading-controller-class"
		};
	}

	ngOnInit() {
		this.minYear = new Date().getFullYear();
		this.maxYear = this.minYear + 5;
	}

	async closeModal() {
		var date = Math.floor(new Date(this.dateTime).getTime() / 1000);
		var currentDate = Math.floor((new Date().getTime() - 600000) / 1000);
		var inicio;
		var anterior;

		let dateTime = {
			inicio,
			anterior
		}

		if (date != null && date > currentDate) {

			if (this.title == "Inicio") {
				dateTime.anterior = null;
				dateTime.inicio = date;
				await this.modalController.dismiss(dateTime);
			}
			else if (this.title == "Fin" && this.dateStart == null) {
				this.inputAlertControlleR.OkInfoMessage(
					'Fecha erronea', 
					'Para asignar este campo primero rellena la fecha de inicio', 
					'Asignar fecha de inicio');
				await this.modalController.dismiss();
			}
			else if (this.title == "Fin" && date > this.dateStart) {
				dateTime.inicio = this.dateStart;
				dateTime.anterior = date;
				await this.modalController.dismiss(dateTime);
			}
			else {
				this.inputAlertControlleR.OkInfoMessage(
					'Configuración de fecha invalida', 
					'La fecha de fin debe ser mayor a la fecha de incio', 
					);
				await this.modalController.dismiss();
			}
		}
		else {
			this.inputAlertControlleR.OkInfoMessage(
				'La fecha ingresada no es asignable', 
				'Es necesario configurar una fecha actual, o futura', 
				'Ingrese una fecha correcta');
			await this.modalController.dismiss();
		}
	}
}
