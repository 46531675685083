import { Component, Input, OnInit } from '@angular/core';
import { EventPublication } from 'src/app/Models/Classes/Publications/Event/event-publication';

@Component({
	selector: 'app-event-publication',
	templateUrl: './event-publication.component.html',
	styleUrls: ['./event-publication.component.scss'],
})
export class EventPublicationComponent implements OnInit {
	@Input() publication: EventPublication;
	constructor() {
		
	
	 }

	ngOnInit() {
	
	 }

	sharePublication(){}

	savePublication(){}



}
