import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { modalController } from '@ionic/core';

@Component({
  selector: 'app-Regalo-Modal',
  templateUrl: './Regalo-Modal.component.html',
  styleUrls: ['./Regalo-Modal.component.scss']
})
export class RegaloModalComponent implements OnInit {
  lanzamiento
  numero=10

  constructor( private modalctrl: ModalController) { }

  ngOnInit() {

     this.lanzamiento = setInterval(() => {
      this.conteo();
    }, 1200);

    console.log(`time ${this.numero}`);

  }

  conteo(){

    console.log(`lanzamiento en ${this.numero}`);
    this.numero--
    if (this.numero == 0) {
      clearInterval(this.lanzamiento)
      this.modalctrl.dismiss()
      console.log("finalizo el conteo y se cerro el modal");


    }


  }



}
