import { Component, OnInit } from '@angular/core';
import { FormServiceService } from 'src/app/services/publications/form-service.service';

@Component({
  selector: 'app-eclipse-eventos4',
  templateUrl: './eclipse-eventos4.component.html',
  styleUrls: ['./eclipse-eventos4.component.scss'],
})
export class EclipseEventos4Component implements OnInit {

  constructor(public formservice:FormServiceService) { }


  ngOnInit() { }

  addStreet(event) {
    this.formservice.eventospublication.street=event.target.value
  }
  addNumberInterior(event) {
    this.formservice.eventospublication.interior_number=event.target.value
  }
  addNumberExterior(event) {
    this.formservice.eventospublication.exterior_number=event.target.value
  }
  addPostalCode(event) {
    this.formservice.eventospublication.postal_code=event.target.value
  }
  addCol(event) {
    this.formservice.eventospublication.col=event.target.value
  }
  addCity(event) {
    this.formservice.eventospublication.city=event.target.value
  }
  addState(event) {
    this.formservice.eventospublication.state=event.target.value
  }
}
