export class User {
    username: string;
    email: string;
    password: string;
    password_confirmation: string;
    name:string;
    last_name:string;
    birth:string;
    celular:any;
    constructor() {
        this.username = "";
        this.email = "";
        this.password = "";
        this.password_confirmation = "";
        this.name="";
        this.last_name="";
        this.birth="";
        this.celular="";
    }
}
