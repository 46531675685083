import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Utils } from 'src/app/Models/Classes/utils';
import { InputAlertControllerService } from 'src/app/services/utils/input-alert-controller.service';
import { WalletServiceService } from 'src/app/services/wallet/wallet-service.service';
import {RequestService} from "src/app/services/request/request.service";
import Swal2 from "sweetalert2";
import { FriendshipService } from 'src/app/services/relations/relations.service';
@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	@Input() followButton: any;
  @Input() fromwhere:string ;
	@Input() profilePhoto: string;
	@Input() userName: string;
	@Input() createdAt: number;
	@Input() owner: boolean = false;
	@Input() user_id: number;
	@Input() discardInteractions: boolean;
	@Input() corp: boolean = false;
	public timeSincePublish: string = "Justo ahora";
	completePictureUrl: string;
	isKYCValid: boolean = false;
	@Output() sharePublication = new EventEmitter();
	@Output() editPublication = new EventEmitter();
	@Output() deletePublication = new EventEmitter();

	constructor(
		private navCtrl: NavController,
		private utils: Utils,
		private inputAlertController: InputAlertControllerService,
		public walletService: WalletServiceService,
		private request: RequestService,
		private friendshipService: FriendshipService,
	) { }

	ngOnInit() {
		//console.log('followButton', this.followButton);
		//console.log('owner', this.owner);
		console.log('followButton2', this.followButton.followButton.error);
		this.utils.calcularTiempo(this.createdAt).then(time => {
			this.timeSincePublish = time;
			
		});
		this.completePictureUrl = 'https://laniakea-multimedia.s3.amazonaws.com/' + this.profilePhoto;
		console.log('Profile Photo URL:', this.profilePhoto);
		//this.walletService.KYC_validation3(this.user_id).then((result_kyc) => {
		//	console.log('KYC_validation result:', result_kyc);
	//	  if (result_kyc['permit']) {
	//		this.isKYCValid = true;
	//	  }
	//	});



	}

  returnclass(){
    if((this.fromwhere === "lkfilm") || (this.fromwhere==="webview")){
      return "transparent-95"
    }else{
      return "transparent"
    }

  }

	moreActionSheet() {
		let buttons = new Array<any>();
		buttons.push({text: 'Compartir', icon: 'share'});

		if (this.owner) {
			buttons.push({text: 'Editar', icon: 'create'});
			buttons.push({text: 'Eliminar', icon: 'trash'});
		}
		this.inputAlertController.SelectorActionSheet(buttons, 'Opciones de publicación').then((result)=>{
			if(result['action']){
				switch(result['button']['text']){
					case 'Compartir': {
						this.sharePublication.emit();
						break;
					}
					case 'Editar': {
						this.editPublication.emit();
						break;
					}
					case 'Eliminar': {
						this.deletePublication.emit();
						break;
					}
					default: break;
				}
			}
		}).catch((error)=>{
			console.error(error);
		});
	}

	Click_On_User() {
    console.log('click header to send to user profile page');
		//if(!this.discardInteractions){
			this.navCtrl.navigateForward('home/profile/' + this.user_id);
		//}
	}
	click_FollowButton(button_info) {

		//switch (this.followButton['text']) {
		//	case "Seguir":
				this.buttonsActions(button_info);
		//		break;
		//	case "Siguiendo":
				//this.buttonsActions(button_info);
		//		break;
		//}
	}
	buttonsActions(button_info){
		console.log('click header follow');
console.log(button_info);
		//if(button_info['type'] == 3){
			//switch (button_info.text) {
				//case "Seguir":
					this.createRelation(3).then((data)=>{
						this.utils.presentToast("Ahora sigues al usuario");
						this.followButton['text']="Siguiendo";
				//	});
				//	break;
				//case "Siguiendo":
				//	this.deleteRelation(button_info['relation_id']).then((data)=> {
				//		this.utils.presentToast("Dejaste de seguir al usuario");
				//		this.followButton['text']="Seguir";
				//	});
				//	break;
			})
		//}
	}
	createRelation(relation_type): Promise<any>{
		return new Promise((resolve, reject) => {
			let newRelation = {
				'relationUserId': this.user_id.toString(),
				'relationType': '3'
			};
			this.sendNotification( );
			this.friendshipService.createRelation(newRelation).then((result)=>{
				result = JSON.parse(result);
				switch (relation_type) {
					case 3:
						this.followButton.relation_id = result.relation_id;
						break;

					default:
						break;
				}
				resolve(result);
			}).catch((error) =>{
				reject(error);
			});
		});

	}
	sendNotification() {
   
		return new Promise((resolve, reject) => {
		  const notification = {
			title: 'Nuevo seguidor asequido',
			body: `¡Has recibido un nuevo seguidor en tu perfil!`,
			user_id:this.user_id,
	
		  };
	  
		  this.request.createRequestPost('GreatAtractorNotifications', notification)
			.then((data) => {
				
			  resolve(data);
			}).catch((error) => {
			  console.error('Failed to send notification:', error);
			  reject(error);
			});
		});
	  }
	  deleteRelation(relation_id):Promise<any>{
		return new Promise((resolve, reject) => {
			let params = {
				'id': relation_id.toString(),
			};
			this.friendshipService.deleteRelation(params).then((result) => {
				resolve(result);
			}).catch((error) =>{
				reject(error);
			});
		});

	}

}
