import { MediaItem } from "../../Media/media-item";
import { ReactionContainerClass } from "../../Reactions/ReactionContainer/reaction-container-class";
import { SellerProfile } from "../../User/Store/seller-profile";
import { CommentClass } from "../Comments/comment-class";
import { PublicationClass } from "../publication-class";

export class Eventos extends PublicationClass {
    //Multimedia
    views: number;
    regalos: number;
    Verified: boolean;
    SaveCounter: any;
    ShareCounter: any;
    latitud:number;
    longitud:number;
    doesItHaveMultimedia: boolean;
    multimediaCounter: number;
    multimediaContainer: Array<MediaItem>;
    public multimedia: Array<MediaItem> = new Array<MediaItem>()
    //Eventos Publication Data 
    title: string;
    description: string;
    quienpuedever:Array<string>=["Para todos"];
    publication_type: string;
    price: number;
    is_feed: boolean;
    //Eventos1---------------------------------------
    category: string;
    //eventos3---------------------------------------
    link: string;
    limited_availability: number;
    ubicacion: string;
    //eventos4----------------------------------------
    street: string;
    interior_number: number;
    exterior_number: number;
    postal_code: number;
    col: string;
    city: string;
    state: string;
    //eventos calendario
    fecha_inicial: string;
    fecha_final: string;
    hora_inicial: string;
    hora_final: string;

    constructor(publication?: any, userProfile?: SellerProfile, reactionContainer?: Array<ReactionContainerClass>, ownReaction?: any) {
        super(publication, userProfile, reactionContainer, ownReaction);
        if (publication && publication.container) {
            //Multimedia
            this.doesItHaveMultimedia = (publication.multimediaCounter == 0) ? false : true;
            this.multimediaCounter = (publication.container.multimediaCounter) ? parseInt(publication.container.multimediaCounter) : 0;
            this.transformMediaToMediaItem(publication.container.multimediaContainer).then((multimedia) => {
                this.multimediaContainer = multimedia;
            });
            //Eventos Publication Data
            this.views = (publication.container.views) ? publication.container.views : null;
            this.regalos = (publication.container.regalos) ? publication.container.regalos : null;
            this.Verified = (publication.container.Verified) ? publication.container.Verified : null;
            this.SaveCounter = (publication.container.SaveCounter) ? publication.container.SaveCounter : null;
            this.ShareCounter = (publication.container.ShareCounter) ? publication.container.ShareCounter : null;
            this.latitud=(publication.container.latitud) ? publication.container.latitud : null;
            this.longitud=(publication.container.longitud) ? publication.container.longitud : null;
            this.title = (publication.container.title) ? publication.container.title : null;
            this.description = (publication.container.description) ? publication.container.description : null;
            this.price = (publication.container.price) ? publication.container.price : null;
            this.quienpuedever = (publication.container.quienpuedever) ? publication.container.quienpuedever : null;
            this.is_feed = (publication.container.is_feed) ? publication.container.is_feed : null;
            //eventos1------------------------------------
            this.category = (publication.container.category) ? publication.container.category : null;
            //eventos3------------------------------------
            this.link = (publication.container.link) ? publication.container.link : null;
            this.limited_availability = (publication.container.limited_availability) ? publication.container.limited_availability : null;
            this.ubicacion = (publication.container.ubicacion) ? publication.container.ubicacion : null;
            //eventos4
            this.street = (publication.container.street) ? publication.container.street : null;
            this.interior_number = (publication.container.interior_number) ? publication.container.interior_number : null;
            this.exterior_number = (publication.container.exterior_number) ? publication.container.exterior_number : null;
            this.postal_code = (publication.container.postal_code) ? publication.container.postal_code : null;
            this.col = (publication.container.col) ? publication.container.col : null;
            this.city = (publication.container.city) ? publication.container.city : null;
            this.state = (publication.container.state) ? publication.container.state : null;
            //eventos calendario
            this.fecha_inicial = (publication.container.fecha_inicial) ? publication.container.fecha_inicial : null;
            this.fecha_final = (publication.container.fecha_final) ? publication.container.fecha_final : null;
            this.hora_inicial = (publication.container.hora_inicial) ? publication.container.hora_inicial : null;
            this.hora_final = (publication.container.hora_final) ? publication.container.hora_final : null;

        } else {
            this.longitud=0;
            this.latitud=0;
            //Multimedia
            this.doesItHaveMultimedia = false;
            this.multimediaCounter = 0;
            this.multimediaContainer = new Array<MediaItem>();
            //Eventos Publication Data
            this.title = undefined;
            this.description = undefined;
            this.price = undefined;
            this.quienpuedever = undefined;
            this.is_feed = undefined;
            //eventos1----------------
            this.category = undefined;
            //eventos3----------------
            this.link = undefined;
            this.limited_availability = undefined;
            this.ubicacion = undefined;
            //eventos4-----------------------------------------------------------
            this.street = undefined;
            this.interior_number = undefined;
            this.exterior_number = undefined;
            this.postal_code = undefined;
            this.col = undefined;
            this.city = undefined;
            this.state = undefined;
            //eventos calendario
            this.fecha_inicial = undefined;
            this.fecha_final = undefined;
            this.hora_inicial = undefined;
            this.hora_final = undefined;
        }
    }

    public updatePublication(publication: any, userProfile: SellerProfile, reactionContainer: Array<ReactionContainerClass>, ownReaction: any) {
        //Multimedia
        this.doesItHaveMultimedia = (publication.multimediaCounter == 0) ? false : true;
        this.multimediaCounter = (publication.multimediaCounter) ? parseInt(publication.multimediaCounter) : this.multimediaCounter;
        this.transformMediaToMediaItem(publication.container.multimediaContainer).then((multimedia) => {
            this.multimediaContainer = multimedia;
        });
        //Publication
        this.id = (publication.id) ? parseInt(publication.id) : this.id;
        this.publication_type = (publication.publication_type_string) ? publication.publication_type_string : this.publication_type;
        this.status_string = (publication.status_string) ? publication.status_string : this.status_string;
        //Interaction
        this.reactionCounter = (publication.reactionCounter) ? parseInt(publication.reactionCounter) : this.reactionCounter;
        this.reactionContainer = reactionContainer;
        this.ownReaction = ownReaction;
        this.commentCounter = (publication.commentCounter) ? parseInt(publication.commentCounter) : this.commentCounter;
        //User
        this.userProfile = userProfile;
        this.corp = (publication.corp) ? publication.corp : this.corp;
        this.is_saved = (publication.is_saved) ? publication.is_saved : this.is_saved;
        this.owner = (publication.owner) ? publication.owner : this.owner;
    }


    setMultimedia(mediaItem) {
        if (this.publication_type == "event") {
            if (this.doesItHaveMultimedia) {
                this.deleteMultimedia(0);
            }
        }
        this.multimediaContainer.push(mediaItem);
        this.doesItHaveMultimedia = true;
    }

    deleteMultimedia(index: number) {
        this.multimediaContainer.splice(index, 1);
        if (this.multimediaContainer.length == 0) {
            this.doesItHaveMultimedia = false;
        }
    }

    getPublicationsDataToSend() {
        let data = {
            doesItHaveMultimedia: this.doesItHaveMultimedia,
            publication_type: this.publication_type,
            data: {
                longitud:this.longitud.toString(),
                latitud:this.latitud.toString(),
                title: this.title,
                description: this.description,
                quienpuedever:this.quienpuedever.join(","),
                price: this.price,
                is_feed: this.is_feed,
                category: this.category,
                link: this.link,
                limited_availability: this.limited_availability,
                ubicacion: this.ubicacion,
                street: this.street,
                interior_number: this.interior_number,
                exterior_number: this.exterior_number,
                postal_code: this.postal_code,
                col: this.col,
                city: this.city,
                state: this.state,
                fecha_inicial:this.fecha_inicial,
                fecha_final:this.fecha_final,
                hora_inicial:this.hora_inicial,
                hora_final:this.hora_final,
            }
        }
        console.log(data);
        return data;
    }

    transformMediaToMediaItem(objectMedia) {
        return new Promise<Array<MediaItem>>((resolve) => {
            let MediaContainer = new Array<MediaItem>();
            if (objectMedia) {
                objectMedia.forEach(media => {
                    MediaContainer.push(new MediaItem(media));
                });
                resolve(MediaContainer);
            }
            else {
                resolve(MediaContainer);
            }

        });
    }
}
