import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MediaItem } from 'src/app/Models/Classes/Media/media-item';
import { PublicationClass } from 'src/app/Models/Classes/Publications/publication-class';
import { PublicationService } from 'src/app/services/publications/publication.service';
import { ReactionssService } from 'src/app/services/reactions/reactions.service';
import { CommentsModalComponent } from '../../../comments/comments-modal/comments-modal.component';
import { CompleteInformationComponent } from '../complete-information/complete-information.component';

@Component({
	selector: 'app-information-event',
	templateUrl: './information-event.component.html',
	styleUrls: ['./information-event.component.scss'],
})

export class InformationEventComponent implements OnInit {
	@Input() publication: PublicationClass;
	@Input() corp: boolean = false;

	@Output() savePublication = new EventEmitter();
	@Output() sharePublication = new EventEmitter();

	@Input() title: string;
	@Input() description: string;
	@Input() dateTimeStart: number;
	@Input() dateTimeEnd: number;
	@Input() multimedia: Array<MediaItem>;

	public dateTimeStartShow: Date;
	public dateTimeEndShow: Date;

	constructor(private modalController: ModalController,
		private reactionService: ReactionssService,
		private publicationService: PublicationService
		) { }

	ngOnInit() {
		this.convertDates();

	}


	convertDates() {
		if (this.dateTimeStart != null) {
			this.dateTimeStartShow = new Date(this.dateTimeStart * 1000);
			if (this.dateTimeEnd != null) {
				this.dateTimeEndShow = new Date(this.dateTimeEnd * 1000);
			}
		}
	}

	openCompleteInformation() {
		this.modalController.create({
			component: CompleteInformationComponent,
			cssClass: 'detailsInModal',
			swipeToClose: true,
			componentProps: {
				title: this.title,
				description: this.description,
				dateTimeStart: this.dateTimeStart,
				dateTimeEnd: this.dateTimeEnd,
				multimedia: this.multimedia
			}
		}).then((modal => {
			modal.present();
		}));
	}


	click_on_comments() {

		this.modalController.create({
			component: CommentsModalComponent,
			cssClass: 'detailsInModal',
			swipeToClose: true,
			componentProps: {
				publication: this.publication,
				publicationId: this.publication['id']
			}
		}).then((modal)=>{
			modal.present();
		});
	}


	click_on_like() {
		let reaction = { type: "Me gusta" };

		if (( this.publication.ownReaction)? true: false) {
			//Delete
			this.reactionService.deleteReaction(this.publication['id'], "publications").then((data: any) => {

				this.publication['ownReaction'] = undefined;
				this.publication['reactionCounter']--;

			}).catch((error) => {
				console.error(error);
				error = JSON.parse(error.error);
				if(error.error == "You have no reaction in this publication"){
					this.publication['ownReaction'] = undefined;
					this.publication['reactionCounter'] --;
				}
				if(error.error == 'the publication no longer exist'){
					this.publicationService.DeleteLocalPublicationByID(this.publication.id);
				}
			});
		}
		else {
			this.reactionService.postReaction(reaction, this.publication['id'], "publications").then((data: any) => {
				data = JSON.parse(data);


				let objectReaction = {
					ownReaction: {
						reaction_group_id: data.reaction_group_id,
						reaction_icon: null,
						reaction_id: data.reaction_id,
						reaction_name: data.reaction_apllied
					}
				};

				this.publicationService.getOwnReaction_Publications(objectReaction).then((ownReaction)=>{
					this.publication.ownReaction = ownReaction;
					this.publication.reactionCounter++;

				});

			}).catch((error) => {
				console.log(error);
				error = JSON.parse(error.error);
				console.log(error);
				if(error.error == "you can only reaction once per publication"){
					this.publicationService.getPublicationByID(this.publication.id, true).then((publication)=>{
						this.publication = publication;
					}).catch((error)=>{
						console.error(error);
					});
				}
				if(error.error == 'the publication no longer exist'){
					this.publicationService.DeleteLocalPublicationByID(this.publication.id);
				}
			});
		}
	}
	click_on_save() {
		console.log('oprimiste save publication');
	}

	click_on_share() {
		this.sharePublication.emit();
	}
}

