import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MediaItem } from 'src/app/Models/Classes/Media/media-item';
import { PublicationClass } from 'src/app/Models/Classes/Publications/publication-class';
import { MultimediaViewComponent } from '../multimedia-view/multimedia-view.component';
import { PopzoomComponent } from 'src/app/components/galery/popzoom/popzoom.component';

@Component({
  selector: 'app-content-media-ecosystem',
  templateUrl: './content-media-ecosystem.component.html',
  styleUrls: ['./content-media-ecosystem.component.scss'],
})
export class ContentMediaEcosystemComponent implements OnInit {

  @Input() multimedia: Array<MediaItem>;
	@Input() publication: PublicationClass;
	@Output() savePublication = new EventEmitter();
	@Output() sharePublication = new EventEmitter();

	constructor(
		private modalController: ModalController) { }

	ngOnInit() { }

	openView(index?: number) {
		this.modalController.create({
			
			component: PopzoomComponent,
			cssClass: 'detailsInModal',
			swipeToClose: true,
			componentProps: {
				multimedia: this.multimedia,
				index: index + 1,
				publication: this.publication,
				savePublication: this.savePublication,
				sharePublication: this.sharePublication	
				
			}
			
			
		}).then((modal)=>{
			modal.present();
		});
	}
	


}
