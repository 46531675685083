import { Component, OnInit, Input } from '@angular/core';
import { ModalController, NavController, NavParams } from '@ionic/angular';
import { PublicacionesColeccionComponent } from '../../publicaciones-coleccion/publicaciones-coleccion/publicaciones-coleccion.component';
import { PublicacionesResumenComponent } from '../../publicaciones-resumen/publicaciones-resumen/publicaciones-resumen.component';
import { MediaItem } from 'src/app/Models/Classes/Media/media-item';
import { PublicationClass } from 'src/app/Models/Classes/Publications/publication-class';
import { ReactionssService } from 'src/app/services/reactions/reactions.service';
import { PublicationService } from 'src/app/services/publications/publication.service';
import { Utils } from 'src/app/Models/Classes/utils';

@Component({
  selector: 'app-publicaciones-guardadas',
  templateUrl: './publicaciones-guardadas.component.html',
  styleUrls: ['./publicaciones-guardadas.component.scss'],
})
export class PublicacionesGuardadasComponent implements OnInit {

  @Input() profilePhoto: string;
  @Input() userName: string;
  @Input() createdAt: number;
  @Input() owner: boolean = false;
  @Input() user_id: number;
  @Input() discardInteractions: boolean;
  @Input() corp: boolean = false;
  public timeSincePublish: string = "Justo ahora";

  //footer
  @Input() publication: PublicationClass;


  constructor(public modalController:ModalController,
    private reactionService: ReactionssService,
    private publicationService: PublicationService,
    private navCtrl: NavController,
    private utils: Utils,
    private navctrl: NavParams,
    private modalcontroller: ModalController) { }

  ngOnInit() {
    this.utils.calcularTiempo(this.createdAt).then(time => {
      this.timeSincePublish = time;
    });
  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  resumen() {
    this.modalController
      .create({
        component: PublicacionesResumenComponent,
        cssClass: 'detailsInModal',
        swipeToClose: true,
      })
      .then((modal) => modal.present())
  }

  coleccion() {
    this.modalController
      .create({
        component: PublicacionesColeccionComponent,
        cssClass: 'detailsInModal',
        swipeToClose: true,
      })
      .then((modal) => modal.present())
  }

}
