import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RequestService } from 'src/app/services/request/request.service';
import Swal from 'sweetalert2';
import { ModalController } from '@ionic/angular';
import { Plugins } from '@capacitor/core';
const { LocalNotifications } = Plugins;
import {PagoVerificacionComponent} from 'src/app/components/pago-verificacion/pago-verificacion.component';
import {PasswordComponent} from 'src/app/components/general-design/password/password.component';
import { Capacitor } from '@capacitor/core';
@Component({
  selector: 'app-ForgotPassword',
  templateUrl: './ForgotPassword.html',
  styleUrls: ['./ForgotPassword.scss'],
})
export class ForgotPassword implements OnInit {
  @Input() email: any;
  @Input() id: number;
  remainingTime: number;
//Confirmación original
public token: string;
  private countdownInterval: any;
  constructor(private formBuilder: FormBuilder, private request: RequestService, private modalcontroller: ModalController, private PagoVerificacionComponent: PagoVerificacionComponent,) { }

  ngOnInit() {
 
    this.startCountdown();
  
  }
  ngOnDestroy() {
    this.stopCountdown();
  }

  startCountdown() {
    this.remainingTime = 60; // 299 = 4 minutes and 59 seconds

    this.countdownInterval = setInterval(() => {
      this.remainingTime--;

      if (this.remainingTime <= 0) {
        // Stop the countdown when the remaining time reaches zero or below
        this.stopCountdown();
        // Perform any necessary actions when the countdown completes
        // For example, show a message to the user indicating the timeout
      }
    }, 1000);
  }

  stopCountdown() {
    clearInterval(this.countdownInterval);
  }
  formatTime(time: number): string {
    const minutes = Math.floor(time / 60).toString().padStart(2, '0');
    const seconds = (time % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  }
  updateToken(event: any) {
    this.token = event.target.value;
  }
  confirmEmail() {
    console.log('tok', this.token);
    console.log('profile_id', this.id);
    if (this.token) {
      
      this.request.createRequestPost(`verified-confirmation-password`, {token: this.token,})// profile_id: this.id
      .then(response => {
        if (Capacitor.getPlatform() !== 'web'){
        console.log('response', response);
        const responseData = JSON.parse(response);
        console.log('response code', responseData.code);
        response=responseData;
      }

        if (response.code === 200) {
       

          Swal.fire({
            title: 'El token es valido',
            text: 'Token validado exitosamente',
            icon: 'success',
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'btn btn-danger'
            },
            buttonsStyling: false,
            background: '#010f4e',
            iconColor: '#ffffff',
          });

          this.modalcontroller.create({
            component: PasswordComponent,
            cssClass: 'detailsInModal',
            swipeToClose: true,
          componentProps: {
            email: this.email,
            id: this.id
          }
          }).then((modal) => {
            modal.present();
          })

          } else {
            // Token is incorrect
            Swal.fire({
              title: 'El token no es válido',
              icon: 'error',
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'btn btn-danger'
              },
              buttonsStyling: false,
              background: '#010f4e',
              iconColor: '#ffffff',
              text: response.title
            });
          }
        })
        .catch(error => {
          Swal.fire({
            title: 'Email Confirmation Failed',
            icon: 'error',
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'btn btn-danger'
            },
            buttonsStyling: false,
            background: '#010f4e',
            iconColor: '#ffffff',
            text: error.response.title
          });
        });
    }
  }
  
		
    dismiss(){
      this.modalcontroller.dismiss()
    }

  
}