import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { FormServiceService } from 'src/app/services/publications/form-service.service';
import horaC from 'src/assets/plantillas/hora-comienzo-land.json'
import horaT from 'src/assets/plantillas/hora-termino-land.json'
import zonaHoraria from 'src/assets/plantillas/zona-horaria.json'
import { Landservice4Component } from '../landservice4/landservice4.component';


@Component({
  selector: 'app-landservice3',
  templateUrl: './landservice3.component.html',
  styleUrls: ['./landservice3.component.scss'],
})
export class Landservice3Component implements OnInit {
  public horaC: Array<string> = Object.keys(horaC.Hora)
  public horaT: Array<string> = Object.keys(horaT.Hora)
  public zonaHoraria: Array<string> = Object.keys(zonaHoraria.Zona)
  
  Fecha:string="fecha de inicio"
 @Input() date;


  constructor(public formservice: FormServiceService, public modalController: ModalController) { }

  ngOnInit() {
   
    
   }

  
  
  ObtenerDateIni(event) {
    
    this.formservice.landpublication.fecha_inicial=event.detail.value
  }
  ObtenerDateEnd(event) {
    this.formservice.landpublication.fecha_final=event.detail.value
  }

  ObtenerHrIni(event) {
    this.formservice.landpublication.hora_inicial=event.detail.value
  }

  ObtenerHrEnd(event) {
    this.formservice.landpublication.hora_final=event.detail.value
  }

  zonahoraria(event) {
    this.formservice.landpublication.zonaHoraria=event.detail.value
  }




}
