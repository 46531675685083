import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Utils } from 'src/app/Models/Classes/utils';
import { PublicationService } from 'src/app/services/publications/publication.service';

@Component({
	selector: 'app-modal-transaction',
	templateUrl: './modal-transaction.component.html',
	styleUrls: ['./modal-transaction.component.scss'],
})
export class ModalTransactionComponent implements OnInit {

	@Input() price: number;
	@Input() publication: any;
	@Output() pay = new EventEmitter();

	public actual_step: number = 1;
	public payment_wallet = new Array<any>();
	public payment_method_selector: number = -1;
	public paymentResult: string = "Sin pagar";

	constructor(
		private publicationService: PublicationService,
		private utils: Utils,
		public popOverController: PopoverController,
	) { } 

	ngOnInit() { 
		this.payment_wallet.push({
			name: "Wallet GOI",
			api_key: "apiKey_jujuju",
			wallet_id: "1234-5678-9101112-13141516-17181920"
		});
	}

	

	realizar_transaccion() {
		
		this.actual_step = 3;
		setTimeout(() => {
        }, 3000);

		this.publicationService.postBuyPublication(this.publication.id).then((data)=>{
			this.pay.emit();
			this.paymentResult = "success";

		}).catch((error)=>{
			console.error(error);
			this.paymentResult = "error";
			this.utils.alertPresent('Transacción Incorrecta', error.error, null);
			
		});
		
	}

	select_payment_method(index: number, event?){
		if(event){
			if(!event.detail.checked && this.payment_method_selector == index){
				this.payment_method_selector = -1;
			}
		}
		else{
			this.payment_method_selector = index;
		}
	}

}
