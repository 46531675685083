import { Component, Input, OnInit } from '@angular/core';
import { UrlPublication } from 'src/app/Models/Classes/Publications/Url/url-publication';

@Component({
  selector: 'app-url-publication',
  templateUrl: './url-publication.component.html',
  styleUrls: ['./url-publication.component.scss'],
})
export class UrlPublicationComponent implements OnInit {
  @Input() publication: UrlPublication;

  constructor() { }

  ngOnInit() {}

  savePublication(){}

  sharePublication(){}
  

}
