import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { log } from 'console';
import { modalController } from '@ionic/core';
import { ModalController } from '@ionic/angular';
import { CumuloSubscription2Component } from './../cumulo-subscription2/cumulo-subscription2.component';

@Component({
  selector: 'app-cumulo-subscription',
  templateUrl: './cumulo-subscription.component.html',
  styleUrls: ['./cumulo-subscription.component.scss']
})
export class CumuloSubscriptionComponent implements OnInit,OnChanges {

   Cumulos:Array<Object>=[]
   NombreCumulo='';
   ModeloTarifa;
   TarifaEstandar;


   interruptor:boolean=false

  constructor( private Mctrl:ModalController) { }

  ngOnInit() {



  }
  ngOnChanges(){
    this.getCumulos()
  }
  mostrar(){
    console.log("Nombre cumulo",this.NombreCumulo);
    console.log("Modelo",this.ModeloTarifa);
    console.log("Tarifa Estandar",this.TarifaEstandar);


  }

  ActivarModulo(){
    this.mostrar()

    if(this.interruptor == false){
      this.interruptor=true
    }else{
      this.interruptor=false
    }


  }

  Agregar(){

    let cumulo={
      Nombre:this.NombreCumulo,
      ModeloT:this.ModeloTarifa,
      TarifaE:this.TarifaEstandar
    }
    this.Cumulos.push(cumulo)

    this.NombreCumulo=''
    this.ModeloTarifa=''
    this.TarifaEstandar=''

    this.interruptor=false

  }

  getCumulos(){
    this.Cumulos.forEach(element => {
      console.log("Elelementos",element);


    });
  }

  abrirCumulo(){
    this.Mctrl.create({
      component:CumuloSubscription2Component,
      cssClass: 'detailsInModal',
      swipeToClose: true,
      componentProps:{

        ArrayCumulo:this.Cumulos

      }


    }).then((modal)=>{

      modal.present()
    })

  }
  retorno(){
    this.Mctrl.dismiss()
  }
}
