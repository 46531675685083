import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ReturnBarComponent } from '../return-bar/return-bar.component';
import { SliderComponent } from 'src/app/components/publication/single-elements/slider/slider.component';



@NgModule({
	declarations: [
		ReturnBarComponent,
		SliderComponent
	],
	exports: [
		ReturnBarComponent,
		SliderComponent
	],
	entryComponents: [
		ReturnBarComponent,
		SliderComponent
	],
	imports: [
		CommonModule,
		IonicModule
	]
})
export class ReturnModuleModule { }
