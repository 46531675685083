import { Component, OnInit } from '@angular/core';
import { PopoverController,ActionSheetController } from '@ionic/angular';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import Swal2 from 'sweetalert2';
import { UploadMultimediaService } from './../services/upload/upload-multimedia.service';
import { Capacitor, Plugins } from '@capacitor/core';
import { Camera, CameraResultType, CameraSource, Photo, CameraOptions } from '@capacitor/camera';
import { MediaItem } from 'src/app/Models/Classes/Media/media-item';
import { ChangeDetectorRef } from '@angular/core';
import { RequestService } from 'src/app/services/request/request.service';
@Component({
  selector: 'app-my-observations',
  templateUrl: './my-observations.component.html',
  styleUrls: ['./my-observations.component.scss'],
})
export class MyObservationsComponent implements OnInit {
  media_urls;
  mediaType: string;
  selectedOption ;
  constructor(
    private popoverCtrl: PopoverController,
    private request:RequestService,
    public formservice: FormServiceService,
    public uploadMedia: UploadMultimediaService,
    private actionSheetController: ActionSheetController,
    private ref: ChangeDetectorRef,
  ) { }

  ngOnInit() {

  }
  async closePopover() {
    await this.popoverCtrl.dismiss();
  }
  satisfactionSelection(value){
    console.log(value);
    this.selectedOption=value;


  
  }
  askForLibraryCapture(take_photo: boolean = true, pick_AI: boolean = true, pick_image: boolean = true,pick_video: boolean = true) {
    Swal2.fire({
			title: '',
			text: '',
			icon: 'success',
			timer: 9999, // Show the alert for 45 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-start',
			showConfirmButton: false
		  });
    setTimeout(() => {
      this.actionSheetController.dismiss();
      }, 9999); 
		return new Promise((resolve, reject) => {

			let botonesDisponibles = [
				(pick_image) ?
					{
						text: 'Seleccionar una foto',
						icon: 'camera',
						handler: () => {
							console.log("oprimiste camara");

							this
                             .pickImage()
                             .then((mediaItem) => {
                               resolve([mediaItem,'camara']);
                             })
                             .catch((error) => {
                               Swal2.fire({
                                 title: 'Error take photo',
                                 text: error,
                                 icon: 'warning',
                                 confirmButtonText: 'Entendido',
                                 color: '#ffffff',
                                 background: '#010f4e',
                               });
                             });
						}
					} : null,
				(pick_video) ?
					{
						text: 'Seleccionar un vídeo',
						icon: 'videocam',
						handler: () => {
							console.log("oprimiste video");
							this
                            .pickVideo()
                            .then((mediaItem) => {
                              resolve([mediaItem,'camvideo']);
                            })
                            .catch((error) => {
                              Swal2.fire({
                                title: 'Error al capturar video',
                                text: error,
                                icon: 'warning',
                                confirmButtonText: 'Entendido',
                                color: '#ffffff',
                                background: '#010f4e',
                              });
                            });
						}
					} : null,
     
				{
					text: 'Cancelar',
					icon: 'close',
					role: 'cancel',
					handler: () => {
					}
				}
			];

			botonesDisponibles = botonesDisponibles.filter((boton) => {
				if (boton != null) {
					return true;
				}
				return false;
			});


			this.actionSheetController.create({
				header: '¿Desde donde deseas añadir multimedia?',
				cssClass: 'options-sheet',
				buttons: botonesDisponibles
			}).then((actionSheet) => {
				actionSheet.present();
			});
		});
	}
  async pickImage(){

    const imagePreviewElement = document.getElementById("imagePreview") as HTMLImageElement;
    try {
      const options: CameraOptions = {
        quality: 100,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        source: CameraSource.Photos,
        saveToGallery: false,
      };
      console.log('About to call Camera.getPhoto()');
      const media = await Camera.getPhoto(options);
  
      if (media) {

        console.log('Media picked successfully:', media);

      if (Capacitor.getPlatform() === 'web') {
        // For web, create a Blob from the media data and convert it to a data URL
        const response = await fetch(media.webPath);
        const blobfile = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => {
          const imageUrl = reader.result as string;
          imagePreviewElement.src = imageUrl;
        };
        reader.readAsDataURL(blobfile);
      } else {
        // For iOS and Android, use Capacitor.convertFileSrc to get the public URL
        const imageUrl = Capacitor.convertFileSrc(media.path);
        imagePreviewElement.src = imageUrl;
      }
        this.mediaType = media.format === 'jpeg' || media.format === 'png' ? 'image' : 'video';
        this.ref.detectChanges();
        const mimeType = media.format === 'jpeg' ? 'image/jpeg' : media.format === 'png' ? 'image/png' : 'video/mp4';
  
        // Read the file as a Blob
        const response = await fetch(media.webPath);
        const blobfile = await response.blob();
        
  
        // Log the Blob for debugging purposes
        console.log('fileBlob:', blobfile);
        //const filename = media.path.substring(media.path.lastIndexOf('/') + 1);

  this.uploadMedia.blob = blobfile;
  if (Capacitor.getPlatform() !== 'web') {
        this.uploadMedia.filename = media.path.substring(media.path.lastIndexOf('/') + 1);
  }else{
    const currentTime = new Date().toISOString().replace(/:/g, '-').replace(/\./g, '-');
    this.uploadMedia.filename = currentTime + '.png';
  }
        if(this.formservice.normalpublication.blobsMultimedia.length < 1){
          this.formservice.normalpublication.blobsMultimedia[0]={blob:this.uploadMedia.blob,isPremium:false, filename: this.uploadMedia.filename};
        }else {
          this.formservice.normalpublication.blobsMultimedia.push({blob:this.uploadMedia.blob,isPremium:false, filename: this.uploadMedia.filename});
        } 
        
      }
    } catch (error) {
      console.error('Error picking media:', error);
    }
  }  
  async pickVideo() {
    if (Capacitor.getPlatform() === 'ios') {
      Swal2.fire({
        title: 'Añade videos en tu dispositivo apple',
        text: 'Para añadir videos optimizados añade el video de tu librería de Fotos a tus Archivos. A continuación la opción optimizada es: Choose File',
        icon: 'info',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
    }
    
    const videoPreviewElement = document.getElementById("videoSource") as HTMLVideoElement;
  
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "video/*";
    fileInput.multiple = false;
  
    fileInput.addEventListener("change", () => {
      const files = fileInput.files;
  
      if (files && files.length) {
        const file = files[0];

        const mimeType = file.type;


        const blobfile = file;
        const filename = file.name;
       // Create a MediaItem object from the File
      const mediaItem: MediaItem = {
        mediaPath: file.name,
        typeData: file.type,
        nameFile: file.name,
        id: 1, // Assign a unique ID here if needed
        size: file.size,
        index: 0, // Set the desired index value
        route: "", // Set the route value if needed
        status: "", // Set the initial status
        url: "", // Set the URL property
        localUrl: "", // Set the local URL property
        type: "", // Set the type property
        is_premium: 0, // Set the is_premium property
        // Add any other required properties
      };

      this.uploadMedia.blob = blobfile;
      this.uploadMedia.filename = filename;
        if (this.formservice.multimedia.length > 0) {
          this.formservice.multimedia[0] =  mediaItem;
        } else {
          this.formservice.multimedia.push(mediaItem);
        }
        if(this.formservice.normalpublication.blobsMultimedia.length < 1){
          this.formservice.normalpublication.blobsMultimedia[0]={blob:this.uploadMedia.blob,isPremium:false, filename: this.uploadMedia.filename};
        }else {
          this.formservice.normalpublication.blobsMultimedia.push({blob:this.uploadMedia.blob,isPremium:false, filename: this.uploadMedia.filename});
        }
  
        const videoURL = URL.createObjectURL(file);
  
        // Update the video source and display it
        videoPreviewElement.src = videoURL;
      }
    });
  
    // Trigger the file input dialog
    fileInput.click();
  }
  postObservations(){
if(!this.formservice.AI_message){
  Swal2.fire({
    title: 'Error',
    text: 'porfavor escribe una observación.',
    icon: 'warning',
    confirmButtonText: 'Entendido',
    color: '#ffffff',
    background: '#010f4e',
  });
  return;
}
if(!this.selectedOption){
  Swal2.fire({
    title: 'Error',
    text: 'porfavor selecciona tu nivel de satisfacción.',
    icon: 'warning',
    confirmButtonText: 'Entendido',
    color: '#ffffff',
    background: '#010f4e',
  });
  return;
}
if(!this.formservice.normalpublication.blobsMultimedia){
  Swal2.fire({
    title: 'Error',
    text: 'porfavor sube multimedia que ilustre tu observación.',
    icon: 'warning',
    confirmButtonText: 'Entendido',
    color: '#ffffff',
    background: '#010f4e',
  });
  return;
}
    this.formservice.normalpublication.blobsMultimedia.forEach((element, index, array) => {
      console.log("Elemento",element);
      this.uploadMedia.post(element.blob, element.filename).then(({ link, mimeType }) => {
        
        const ruta = link;
       // const ruta = r[0];
        //const mimeType = r[1];
        console.log('ruta', ruta);
        let pub_type = '';
        //console.log(this.multimedia[0].typeData);
        if(mimeType.includes("image")) {
          pub_type= 'imagen';
        }else if(mimeType.includes("video")){
          pub_type= 'video';
        }
     
       
        const objmedia = {
          route: ruta,
          size: 800,
          type:pub_type,

        };
        this.media_urls.push(link);
        const isLastElement = index === array.length - 1;
        console.log('objmedia', objmedia);
        if (isLastElement) {
          this.request.createRequestPost('postUserObservations', {observation:this.formservice.AI_message,image_urls:this.media_urls,satisfaction:this.selectedOption})
          .then((data) => {
      
          // Open the login URL in the system browser
        
          console.log(data);
          if(data.success){

this.sendEmail();
            this.popoverCtrl.dismiss();
            Swal2.fire({
              title: 'Publicación de observaciones',
              position: 'top-end',
              toast: true,
              text: 'La publicación se ha enviado exitosamente',
              icon: 'success',
              confirmButtonText: 'Entendido',
              color: '#ffffff',
              background: '#010f4e',
            });
          }
      
        }).catch((error) => {


          Swal2.fire({
            title: 'Error al subir observaciones!',
            text: 'Hubo un error inesperado, inténtalo de nuevo más tarde',
            icon: 'warning',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
          });
        });

        }

      });
    });
   
  }
  sendEmail() {
   
    return new Promise((resolve, reject) => {
      const notification = {
        subject: 'observaciones',
        Cuerpo: 'Nivel de satisfacción: '+ this.selectedOption+'. observación: '+this.formservice.AI_message,
        name: 'Erick',
        recipient_email: 'erick.rosado@infintummail.com',

      };
  
      this.request.createRequestPost('sendPragmaticCorporateMail', notification)
        .then((data) => {
          Swal2.fire({
            title: 'Correo enviado',
            text: 'El correo ha sido enviado exitosamente',
            icon: 'success',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
          });
          resolve(data);
        }).catch((error) => {
          console.error('Failed to send notification:', error);
          reject(error);
        });
    });
  }
}
