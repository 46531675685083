import { GooglemapsComponent } from 'src/app/components/googlemaps/googlemaps.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandserviceComponent } from '../../landservice/landservice/landservice.component';
import { Landservice2Component } from '../../landservice/landservice2/landservice2.component';
import { Landservice3Component } from '../../landservice/landservice3/landservice3.component';
import { Landservice4Component } from '../../landservice/landservice4/landservice4.component';
import { Landservice5Component } from '../../landservice/landservice5/landservice5.component';
import { Landservice6Component } from '../../landservice/landservice6/landservice6.component';
import { Landservice7Component } from '../../landservice/landservice7/landservice7.component';
import { PrincipalLandComponent } from '../principal-land/principal-land.component';
import { IonicModule } from '@ionic/angular';

import { DescripcionPublicationComponent } from '../../descripcion-publication/descripcion-publication.component';
import { DescriptionModuleModule } from '../../description-module/description-module.module';
import { LandservicePagoComponent } from '../../landservice/landservice-pago/landservice-pago.component';
import { CalendariomodulModule } from 'src/app/components/calendar/calendariomodul/calendariomodul.module';
//import { PagosPorPublicacionComponent } from 'src/app/components/publication/create/Pagos/Pagos-por-publicacion/Pagos-por-publicacion.component';

@NgModule({
  declarations: [
    /* componente principal Land */
    PrincipalLandComponent,
  
    /* Sub componente  land */
    LandserviceComponent,
    Landservice2Component,
    Landservice3Component,
    Landservice4Component,
    Landservice5Component,
    Landservice6Component,
    Landservice7Component,
    LandservicePagoComponent,
    GooglemapsComponent
    
  ],
  exports:[
    /* componente principal Land */
    PrincipalLandComponent,
    /* Sub componente  land */
    LandserviceComponent,
    Landservice2Component,
    Landservice3Component,
    Landservice4Component,
    Landservice5Component,
    Landservice6Component,
    Landservice7Component,
    LandservicePagoComponent
  ],
  entryComponents:[
    /* componente principal Land */
    PrincipalLandComponent,
    /* Sub componente  land */
    LandserviceComponent,
    Landservice2Component,
    Landservice3Component,
    Landservice4Component,
    Landservice5Component,
    Landservice6Component,
    Landservice7Component,
    LandservicePagoComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    DescriptionModuleModule,
    CalendariomodulModule,
    
  ]
})
export class LandModuleModule { }
