import { Component, OnInit} from '@angular/core';
import { ModalController} from '@ionic/angular';
import { GooglemapsComponent } from 'src/app/components/googlemaps/googlemaps.component';
import { FormServiceService } from 'src/app/services/publications/form-service.service';

@Component({
  selector: 'app-earht4',
  templateUrl: './earht4.component.html',
  styleUrls: ['./earht4.component.scss'],
})
export class Earht4Component implements OnInit {

  constructor(public formservice: FormServiceService,
    private modalcontroller: ModalController) { }

  ngOnInit() { }

  addDirection(event) {
    this.formservice.earthpublication.direction = event.target.value
  }
  addNumberInterior(event) {
    this.formservice.earthpublication.interior_number = event.target.value
  }
  addNumberExterior(event) {
    this.formservice.earthpublication.exterior_number = event.target.value
  }
  addPostalCode(event) {
    this.formservice.earthpublication.postal_code = event.target.value
  }
  addCol(event) {
    this.formservice.earthpublication.col = event.target.value
  }
  addCity(event) {
    this.formservice.earthpublication.city = event.target.value
  }
  addState(event) {
    this.formservice.earthpublication.state = event.target.value
  }

  prueva() {
    this.modalcontroller.create({
      component: GooglemapsComponent,
      cssClass: 'detailsInModal',
      swipeToClose: true,
    }).then((modal) => {
      modal.present();
    })
  }
}
