import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Clipboard } from "@ionic-native/clipboard/ngx";
import { Platform } from "@ionic/angular";
import { PublicationClass } from "src/app/Models/Classes/Publications/publication-class";
import { CameraServiceService } from "src/app/services/multimedia/camera-service.service";

@Component({
	selector: "app-youtube-publication",
	templateUrl: "./youtube-publication.component.html",
	styleUrls: ["./youtube-publication.component.scss"],
})
export class YoutubePublicationComponent implements OnInit {
	@Output() YotubeURL = new EventEmitter<string>();
	@Input() publication: PublicationClass;
	@Input() isEditing: boolean;

	public contentInput: string = "";
	private YouTubeCode = "";

	constructor(
		private portapaleles: Clipboard,
		private platform: Platform,
		private cameraService: CameraServiceService
	) { }

	ngOnInit() {
		/*
		if (this.publication.UrlContainer) {
			this.contentInput = this.publication.UrlContainer.address;
		}
		*/
	}

	returnInputValue(event) {
	
		this.contentInput = event.detail.value;
		this.evaluateUrl();
	}

	pasteButton() {
		if (this.platform.is("android") || this.platform.is("ios")) {
			this.portapaleles
				.paste()
				.then((data) => {
					this.contentInput += data;
					this.evaluateUrl();
				})
				.catch((error) => {
					console.error(error);
				});
		} else {
			navigator.clipboard
				.readText()
				.then((data) => {
					this.contentInput += data;
					this.evaluateUrl();
				})
				.catch((error) => {
					console.error(error);
				});
		}
	}

	clearButton() {
		this.contentInput = "";
		//this.publication.UrlContainer = null;
		this.YotubeURL.emit(this.YouTubeCode);
	}

	evaluateUrl() {
		if (this.contentInput.length > 0) {
			let Rule = new RegExp(
				/(?:[?&]v=|\/embed\/|\/1\/|\/v\/|https:\/\/(?:www\.)?youtu\.be\/)([^&\n?#]+)/
			);
			let YouTubeCode = Rule.exec(this.contentInput);
			if (YouTubeCode != null && YouTubeCode[1]) {
				this.YouTubeCode = YouTubeCode[1];
				/*
				this.publication.UrlContainer = {
					address: "https://www.youtube.com/embed/" + this.YouTubeCode
				};
				*/
				this.YotubeURL.emit( 'https://www.youtube.com/embed/' + this.YouTubeCode );
			} else {
				this.YouTubeCode = "";
				
				//this.publication.UrlContainer = null;
				this.YotubeURL.emit(this.YouTubeCode);
				this.cameraService.presentToast(
					"La liga no parece ser valida, corroborala para continuar"
				);
			}
		}
	}
}
