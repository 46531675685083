import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { InformacionDelUsuarioComponent } from '../informacion-del-usuario/informacion-del-usuario.component';
import { VerificacionDeCuentaComponent } from '../verificacion-de-cuenta/verificacion-de-cuenta.component';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { SliderInformationComponent } from './../../slider-information/slider-information.component';
import { PagoVerificacionComponent } from './../../pago-verificacion/pago-verificacion.component';
import { Key } from 'protractor';
import Swal2 from 'sweetalert2';
import { RequestService } from 'src/app/services/request/request.service';
@Component({
  selector: 'app-cuenta',
  templateUrl: './cuenta.component.html',
  styleUrls: ['./cuenta.component.scss'],
})
export class CuentaComponent implements OnInit {
  imageUrl: string = null;
  pr:any
  constructor(public modalController:ModalController,private profile:ProfileService,  private request: RequestService,) { }

  ngOnInit() {
    this.profile.getProfileInfo().then((result)=>{

      this.pr=JSON.parse(result)
      console.log("profile",this.pr);
    })
  }

DirectivaUser(App:String){
    switch(App ){
      case "Informacion":
      this.modalController.create({
        component: InformacionDelUsuarioComponent,
        cssClass: 'detailsInModal',
        swipeToClose: true,
      }).then((modal) => {
        modal.present();
      }).catch((error) => {
        console.error(error);
      });
      break;
      case "Limites":
        this.imageUrl = "https://laniakea-multimedia.s3.amazonaws.com/TL1-1.png";
  break;



      case "Verificacion":
        if(!this.pr){
          Swal2.fire({
            title: 'No autenticado.',
            text: `Debes autenticarte para poder iniciar el proceso de verificación.`,
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: 'ok',
            cancelButtonText: 'Ni modo',
            color: '#ffffff',
            background: '#010f4e',
            }).then((result) => {
            if (result.isConfirmed) {
            }
          });
        }
       
        //actualizar perfil
        if(this.pr.kyc === "Sin Validar"){
          this.modalController.create({
            component: SliderInformationComponent,
            cssClass: 'detailsInModal',
            swipeToClose: true,
            componentProps: {
              pr: this.pr,
            },
          }).then((modal) => {
            modal.present();
          }).catch((error) => {
            console.error(error);
          });
        }else if((this.pr.kyc === 'Sin Validar') || (this.pr.kyc==='Pagado')) {
          console.log("entro al else if ");
          
          this.modalController.create({
            component: PagoVerificacionComponent,
            cssClass: 'detailsInModal',
            swipeToClose: true,
            componentProps: {
              pr: this.pr
            }
          }).then((modal)=>{
            modal.present();
          });
          //alert swal2
        }else if(this.pr.kyc === 'Validado'){
          Swal2.fire({
            title: 'Perfil verificado.',
            text: `¡El perfil ya ha sido validado exitosamente!`,
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'ok',
            cancelButtonText: 'Ni modo',
            color: '#ffffff',
            background: '#010f4e',
            }).then((result) => {
            if (result.isConfirmed) {
            }
          });
        }else{
          console.log("no entro a ninguna de las opciones", this.pr.kyc);
          
        }
    }
  }
  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }
  changeImage() {
    this.imageUrl = "https://laniakea-multimedia.s3.amazonaws.com/TL1.png";
  }
  EliminarCuenta(){
    Swal2.fire({
      title: '¿Estás seguro?',
      text: `Estás seguro que deseas eliminar la cuenta ${this.pr.username}. Esta acción es inmutable.`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Si, eliminar.',
      cancelButtonText: 'No, cancelar.',
      color: '#ffffff',
      background: '#010f4e',
      }).then((result) => {
      if (result.isConfirmed) {
        this.request.createRequestPost('EliminarCuenta',{ id:this.pr.id}) .then((data) => {
          console.log(data);
         
          Swal2.fire({
            title: 'Correo de confirmación.',
            text: `Se ha enviado el correo de confirmación para eliminar tu cuenta exitosamente.`,
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'ok',
            cancelButtonText: 'Ni modo',
            color: '#ffffff',
            background: '#010f4e',
            }).then((result) => {
            if (result.isConfirmed) {
            }
          });
      
      }).catch((error) => {
        Swal2.fire({
          title: 'Correo de confirmación.',
          text: `No se ha enviado el correo de confirmación para eliminar tu cuenta exitosamente.`,
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'ok',
          cancelButtonText: 'Ni modo',
          color: '#ffffff',
          background: '#010f4e',
          }).then((result) => {
          if (result.isConfirmed) {
          }
        });
        console.error('Failed to send request:', error);
      });
      }
    });
}
}
