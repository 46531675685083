import { UserContact } from "../User/user-contact";
import { Message } from "./message";
import * as models from 'src/app/Models/Classes/graphql/models/models-graphql';
import { MediaItem } from "../Media/media-item";
import { MultimediaInChatService } from "src/app/services/chat/multimedia-in-chat.service";

export class Conversation {
    private id: string;
    public name: string;
    private notificationId: number;
    private isGroup: boolean;
    private owner: UserContact;
    private lastMessage: Message;
    private users: Array<UserContact> = Array<UserContact>();
    public messagesList: Array<Message> = Array<Message>();
    private unreadMessagesList: Array<Message> = Array<Message>();
    private multimedia: Array<MediaItem>;
    private multimediaID: string;
    private groupImage: string;
    private nextToken: string;
    private createdAt: string;
    constructor(conversationResponse: models.GetConversationQuery, owner: UserContact, contactList: Array<UserContact>) { // ConversationResponse = conversation, lastMessage, owner
        if (conversationResponse && owner) {
            this.id = (conversationResponse.id) ? conversationResponse.id : null;
            this.name = (conversationResponse.name) ? conversationResponse.name : null;
            this.isGroup = (conversationResponse.isGroup) ? conversationResponse.isGroup : false;
            this.owner = (owner) ? owner : null;
            this.multimediaID = (conversationResponse.multimediaID)? conversationResponse.multimediaID : null;
            this.createdAt = (conversationResponse.createdAt)? conversationResponse.createdAt: new Date().toLocaleString();
            if(this.isGroup && this.multimediaID){
                this.buildGroupImage(this.multimediaID);
            }else {
                this.groupImage = "https://res.cloudinary.com/dtbudl0yx/image/fetch/w_2000,f_auto,q_auto,c_fit/https://adamtheautomator.com/wp-content/uploads/2019/12/group-1824145_1280-768x768.png"
            }
            this.lastMessage = (conversationResponse.messages && conversationResponse.messages.items && conversationResponse.messages.items.length > 0) ? new Message(conversationResponse.messages.items[0]) : null;
            
            if (conversationResponse.users && conversationResponse.users.items.length > 0) {
                this.calculateMembers(conversationResponse.users.items);
                //let itemsFiltered;
                if(conversationResponse.users.items.find(userItem => (userItem.user.id == owner.id))){
                    this.users.push(new UserContact({ user: conversationResponse.user }))
                    conversationResponse.users.items = conversationResponse.users.items.filter(function (value) {
                        if (!(owner.id == value.user.id)) {
                            return value;
                        }
                    });
                }
                conversationResponse.users.items.forEach(userResponse => {
                    let userContact = contactList.find(userContact => (userContact.id == userResponse.user.id))
                    if(userContact){
                        this.users.push(userContact);
                    }else{
                        this.users.push(new UserContact(userResponse));
                    }
                });
                if(this.lastMessage && this.users){
                    this.lastMessage.setSeen(this.users);
                }

            }
        }
    }

    public getDate(): string {
        return this.createdAt;
    }
    
    private buildGroupImage(multimediaID:string){
        MultimediaInChatService.instance.getChatElement(parseInt(multimediaID)).then((chatElementResponse:any)=>{
            this.setGroupImage(chatElementResponse.media.url);
        }).catch((error)=>{
            console.error(error);
        });
    }

    public getUnreadMessages(): Array<Message>{
        return this.unreadMessagesList;
    }

    public clearUnreadMessages(){
        this.unreadMessagesList = new Array<Message>();
    }
    public setGroupImage(groupImage:string){
        this.groupImage = groupImage;
    }
    private calculateMembers(users: any) {
        return users.length + 1;
    }

    public setMultimediaID(multimediaID:string){
        this.multimediaID = multimediaID;
    }
    public setNewLastMessage(lastMessage: Message) {
        this.lastMessage = lastMessage;
    }

    public getMultimediaArray(){
        return this.multimedia;
    }

    public getGroupImage(){
        return this.groupImage;
    }
    public getLastMessage(): Message {
        return this.lastMessage;
    }

    public getOwner(): UserContact {
        return this.owner;
    }

    public getName(): string {
        return this.name;
    }

    public getIsGroup(): boolean {
        return this.isGroup;
    }

    public getUsers(): Array<UserContact> {
        return this.users;
    }

    public getID(): string {
        return this.id;
    }

    public getNotificationID(): number {
        return this.notificationId;
    }
    public getNextToken(): string {
        return this.nextToken;
    }

    public setNextToken(nextToken: string) {
        this.nextToken = nextToken;
    }

    public setNotificationID(notificationID: number) {
        this.notificationId = notificationID;
    }

    public addMessage(newMessage: Message) {
        this.messagesList.push(newMessage);
    }

    public addMessageToUnread(newMessage: Message){
        this.unreadMessagesList.push(newMessage);
    }

    public addMessageTop(newMessage: Message) {
        this.messagesList.splice(0, 0, newMessage);
        //this.messagesList.push(newMessage);
    }

    public getMessagesList(){
        return this.messagesList;
    }
    public setName(newName:string){
        this.name = newName;
    }
}