import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {HTTP} from "@ionic-native/http/ngx";
import {RequestService} from "../request/request.service";

@Injectable({
  providedIn: 'root'
})
export class ReconoseridServiceService {

  url = 'https://demorcs.olimpiait.com:6314/';
  guid_convenio = 'f19f5ffd-ca0d-4e98-a26d-6ceb4109763b';

  constructor(private http: HTTP,
              private request: RequestService,) {

  }
  private create_headers(access_token? : string) {
    let headers = { 'Content-Type': 'application/json', 'Authorization': '', 'Access-Control-Allow-Origin': '*' };
    if (access_token !== undefined) {
      headers.Authorization = 'Bearer ' + access_token;
    }
    return headers;
  }

  public solicitaValidacion(access_token: string, curp, email, nombre, celular){
    return new Promise((resolve, reject) => {
      const headers = this.create_headers(access_token);
      //faltan mas campos (nombre, apellido, curp, etc)
      const object = {
        "guidConv":this.guid_convenio,
        "tipoValidacion":4,
        "asesor":"LaniakeaM_2022",
        "sede":686863,
        "tipoDoc":"CURP",
        "numDoc": curp,
        "email": email,
        "nombreCompleto": nombre,
        "celular": celular,
        "usuario":'Laniakea_2022',
        "clave":'Laniakea.2022*',
        "validarProcesoActivo":true,
        "prefCelular":"52",
        "procesoWhatsapp":null
      };
      this.http.setDataSerializer('json');
      console.log("body", object);
      console.log(headers);
      this.http.post(this.url+'Validacion/SolicitudValidacion',object, headers).then((data) => {
        console.log(data);
        const result = JSON.parse(data.data);
        //guardar en lad BD
        const reconoser_object = {
          'reconoser_guid': result['data']['procesoConvenioGuid'],
          'reconoser_url': result['data']['url']
        };
        this.request.createRequestPost('profile/update_reconoserid',reconoser_object).then(() =>{
          resolve(result);
        }).catch((error)=>{
          console.log(error);
        });
      }).catch((error) => {
        console.error(error);
      });
    });
  }

  public getToken() {
    return new Promise((resolve, reject) => {
      const headers = this.create_headers();
      const object = {
        'clientId': 'Laniakea',
        'clientSecret': 'P@$$w0rd'
      };
      this.http.setDataSerializer('json');
      console.log("body", object);
      this.http.post(this.url+'TraerToken',object, headers).then((data) => {
        const result = JSON.parse(data.data);
          resolve(result['accessToken']);
      }).catch((error) => {
        console.error(error);
      });
    });
  }

  public solicitaStatus(access_token, guid) {
    return new Promise((resolve, reject) => {
      const headers = this.create_headers(access_token);
      //faltan mas campos (nombre, apellido, curp, etc)
      const object = {
        "guidConv":this.guid_convenio,
        "procesoConvenioGuid":guid,
        "usuario":'Laniakea_2022',
        "clave":'Laniakea.2022*'
      };
      this.http.setDataSerializer('json');
      console.log("body solicitastatus", object);
      console.log(headers);
      this.http.post(this.url+'Validacion/ConsultarValidacion',object, headers).then((data) => {
        console.log(data);
        const result = JSON.parse(data.data);
        resolve(result);
      }).catch((error) => {
        console.error(error);
      });
    });
  }


}
