/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://egye64jm6jet3frwo26sqyvtim.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cognito_identity_pool_id": "us-west-2:da91c930-dc52-4309-af15-3a6d8012d3f1",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_btxugBMLQ",
    "aws_user_pools_web_client_id": "36klbi90poccou25o4e7lt1l5o",
    "oauth": {},
    "aws_user_files_s3_bucket": "laniakea-multimedia",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
