export class ReactionClass {

    reaction_group_id: number;
    reaction_icon: string;
    reaction_name: string;

    constructor(singleReaction?: any) {
        if (singleReaction != null) {

            this.reaction_group_id = (singleReaction.reaction_group_id) ? parseInt(singleReaction.reaction_group_id) : undefined;
            this.reaction_icon = (singleReaction.reaction_icon) ? singleReaction.reaction_icon : undefined;
            this.reaction_name = (singleReaction.reaction_name) ? singleReaction.reaction_name : undefined;

        } else {
            this.reaction_group_id = undefined;
            this.reaction_icon = undefined;
            this.reaction_name = undefined;
        }

    }
}
