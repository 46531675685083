import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PublicationFeedPageRoutingModule } from './publication-feed-routing.module';

import { PublicationFeedPage } from './publication-feed.page';
import { PublicationModuleModule } from 'src/app/components/publication/publication-module/publication-module.module';
import { PublicationElementsModuleModule } from 'src/app/components/publication/single-elements/publication-elements-module/publication-elements-module.module';
import { StoriesModuleModule } from 'src/app/components/stories-bar/stories-module/stories-module.module';
import { MenuGaleryModulModule } from 'src/app/components/galery/menu-galery-modul/menu-galery-modul.module';
import {LkfilmViewBySlidesComponent} from "../../components/lkfilm-view-by-slides/lkfilm-view-by-slides.component";
import { HeaderBarComponent } from './../../components/general-design/header-bar/header-bar.component';
import { GeneralModuleModule } from './../../components/general-design/general-module/general-module.module';
import { MainPublicationsSwipecontainerComponent } from './main-publications-swipecontainer/main-publications-swipecontainer.component';

@NgModule({
  imports: [
CommonModule,
    FormsModule,
    IonicModule,
    PublicationFeedPageRoutingModule,
    PublicationModuleModule,
    PublicationElementsModuleModule,
    StoriesModuleModule,
    MenuGaleryModulModule,
    GeneralModuleModule

  ],
  exports:[MainPublicationsSwipecontainerComponent],
  entryComponents: [],
  declarations: [PublicationFeedPage,MainPublicationsSwipecontainerComponent]
})
export class PublicationFeedPageModule {}
