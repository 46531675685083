export class Wallet {
    id: number;
    walletID: string;
    email_generico: string;
    user_generico: string;
    pass_generico: string;
    name: string;
    balance: number;
    currency: string;

    constructor(object?: any) {
        if (object != null) {
            this.id = (object.id != null) ? object.id : null;
            this.walletID = (object.walletID != null) ? object.walletID : "";
            this.email_generico = (object.email_generico != null) ? object.email_generico : "";
            this.user_generico = (object.user_generico != null) ? object.user_generico : null;
            this.pass_generico = (object.pass_generico != null) ? object.pass_generico : null;
            this.name = (object.name != null) ? object.name : "";
            this.balance = (object.balance != null) ? parseFloat(object.balance) : 0;
            this.currency  = (object.currency != null) ? object.currency : "MXN";
        } 
        else{
            this.id =null;
            this.walletID =  "";
            this.email_generico = "";
            this.user_generico = null;
            this.pass_generico =  null;
            this.name = "";
            this.balance = 0;
            this.currency = "MXN";
        }
    }

}
