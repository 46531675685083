import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LkfilmViewBySlidesComponent} from "../lkfilm-view-by-slides.component";
import {IonicModule} from "@ionic/angular";
import { PublicationMediaTemplateComponent } from './../../publication/publication-media-template/publication-media-template.component';
import { GoecosystemModuleModule } from './../../go-ecosystem/goecosystem-module/goecosystem-module.module';
import { PublicationModuleModule } from '../../publication/publication-module/publication-module.module';


@NgModule({
declarations: [
    LkfilmViewBySlidesComponent,




  ],
  entryComponents:[],
  imports: [
CommonModule,
    IonicModule,
    PublicationModuleModule




  ],

})
export class LkfilmViewBySlidesModule { }
