import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { CommentComponent } from '../comment/comment.component';
import { CommentsModalComponent } from '../comments-modal/comments-modal.component';
import { ButtonsModule } from 'src/app/components/common-elements/buttons/buttons-module/buttons.module';
import { InputModuleModule } from 'src/app/components/common-elements/inputs/input-module/input-module.module';
import { ReturnModuleModule } from 'src/app/components/common-elements/return/return-module/return-module.module';
import { SliderComponent } from '../../single-elements/slider/slider.component';



@NgModule({
	declarations: [
		CommentComponent,
		CommentsModalComponent,
		//ReturnBarComponent,
		//SliderComponent
	],
	exports: [
		CommentComponent,
		CommentsModalComponent,
		//ReturnBarComponent,
		//SliderComponent
	],
	entryComponents: [
		CommentComponent,
		CommentsModalComponent,
		//ReturnBarComponent,
		//SliderComponent
	],
	imports: [
		CommonModule,
		IonicModule,
		ButtonsModule,
		InputModuleModule,
		ReturnModuleModule
	]
})
export class CommentsModuleModule { }
