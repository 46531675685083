import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Conversation } from 'src/app/Models/Classes/Chat/conversation';
import { UserContact } from 'src/app/Models/Classes/User/user-contact';
import { ChatService } from 'src/app/services/chat/chat.service';
import { MessagesListComponent } from '../messages-list/messages-list.component';

@Component({
	selector: 'app-relations-list',
	templateUrl: './relations-list.component.html',
	styleUrls: ['./relations-list.component.scss'],
})
export class RelationsListComponent implements OnInit {
	@Input() Laniakea_chat: boolean=false;
	constructor(
		public chatService: ChatService,
		private modalController: ModalController
	) { }

	ngOnInit() {
		if (this.Laniakea_chat) {
		  const targetUsername = 'Laniakea_supercumulo'; // Replace with the target username
	  
		  const targetContact = this.chatService.contactList.find(contact => contact.username === targetUsername);
	  
		  if (targetContact) {
			console.log('Found target contact:', targetContact.username);
			this.newConversation(targetContact);
		  }
		}
	  }
	  

	public newConversation(contact: UserContact) {
		this.chatService.newConversation(contact).then((conversation:Conversation)=>{
			this.openConversation(conversation)
		}).catch((error)=>{
			console.error(error);
		});
	}

	private openConversation(conversation: Conversation) {
		this.modalController.dismiss().then(() => {
			this.modalController.create({
				component: MessagesListComponent,
				swipeToClose: true,
				componentProps: {
					conversation: conversation
				}
			}).then((modal)=>{
				modal.present();
			});
		}).catch(error => {
			console.error(error);
		});
	}
}
