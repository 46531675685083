import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-amigoslist',
	templateUrl: './amigoslist.component.html',
	styleUrls: ['./amigoslist.component.scss'],
})
export class AmigoslistComponent implements OnInit {

	constructor() { }

	ngOnInit() {
		
	}
}
