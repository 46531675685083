import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ContentMediaComponent } from '../content-media/content-media.component';
import { FooterComponent } from '../footer/footer.component';
import { HeaderComponent } from '../header/header.component';
import { MultimediaViewComponent } from '../multimedia-view/multimedia-view.component';
import { PayForPublicationComponent } from '../pay-for-publication/pay-for-publication.component';
import { ModalTransactionComponent } from '../modal-transaction/modal-transaction.component';
import { UnvaliablePublicationComponent } from '../unvaliable-publication/unvaliable-publication.component';
import { UrlViewerComponent } from '../url-viewer/url-viewer.component';
import { ButtonsModule } from 'src/app/components/common-elements/buttons/buttons-module/buttons.module';
import { ReturnModuleModule } from 'src/app/components/common-elements/return/return-module/return-module.module';
import { HeaderInformationEventComponent } from '../header-information-event/header-information-event.component';
import { PreviewComponent } from '../preview/preview.component';
import { ContentMediaEcosystemComponent } from '../content-media-ecosystem/content-media-ecosystem.component';

@NgModule({
	declarations: [
		ContentMediaComponent,
		FooterComponent,
		HeaderComponent,
		MultimediaViewComponent,
		PayForPublicationComponent,
		ModalTransactionComponent,
		UnvaliablePublicationComponent,
		UrlViewerComponent,
		HeaderInformationEventComponent,
		PreviewComponent,
		ContentMediaEcosystemComponent
	],
	exports: [
		ContentMediaComponent,
		FooterComponent,
		HeaderComponent,
		MultimediaViewComponent,
		PayForPublicationComponent,
		ModalTransactionComponent,
		UnvaliablePublicationComponent,
		UrlViewerComponent,
		HeaderInformationEventComponent,
		PreviewComponent,
		ContentMediaEcosystemComponent
	],
	entryComponents: [
		ContentMediaComponent,
		FooterComponent,
		HeaderComponent,
		MultimediaViewComponent,
		PayForPublicationComponent,
		ModalTransactionComponent,
		UnvaliablePublicationComponent,
		UrlViewerComponent,
		HeaderInformationEventComponent,
		PreviewComponent,
		ContentMediaEcosystemComponent
	],
	imports: [
		CommonModule,
		IonicModule,
		ButtonsModule,
		ReturnModuleModule
	]
})
export class PublicationElementsModuleModule { }
