import { Injectable } from '@angular/core';
import { RequestResponse } from '../../Models/Classes/request-response';
import { RequestService } from '../request/request.service';

@Injectable({
  providedIn: 'root'
})
export class MultimediaService {

  constructor(private request: RequestService) { }

  public postMultimedia(dataToSend: any, publication_ids: Array<number>, endPoint: string): Promise<RequestResponse> {

		return new Promise((resolve, reject) => {
			let params;
			publication_ids ? params = { id:publication_ids[0] } : params = null;
			console.log('data to send', dataToSend);
			console.log('publication_ids', publication_ids, params);
			console.log('endPoint', endPoint);
			this.request.createRequestPost(endPoint, dataToSend, params, 'multimedia').then((data) => {
				resolve(data);
			}).catch((error) => {
				reject(error);
			});
		});
  }

  public putMultimedia(status: any, publication_ids: Array<number>, endPoint: string): Promise<RequestResponse> {
    return new Promise((resolve, reject) => {
      let params;
      publication_ids ? params = { id:publication_ids } : params = null;
      this.request.createRequestPut(endPoint, status, params, 'multimedia').then((data) => {
        console.log('data from putmultimedia after upload to s3', data);
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  }

	public deleteMultimedia(id: number, id_multimedia,  endPoint: string): Promise<RequestResponse> {

		return new Promise((resolve, reject) => {
			let params;
    		id ? params = { id, id2: id_multimedia } : params = null;

			this.request.createRequestDelete(endPoint, params, 'multimedia').then((client) => {
				resolve(client);
			}).catch((error) => {
				reject(error);
			});
		});
	}
}
