import { Component, Input, OnInit, Type } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { PublicationClass } from "src/app/Models/Classes/Publications/publication-class";
import { Utils } from "src/app/Models/Classes/utils";
import { CameraServiceService } from "src/app/services/multimedia/camera-service.service";
import { MultimediaService } from "src/app/services/multimedia/multimedia.service";
import { PublicationService } from "src/app/services/publications/publication.service";

@Component({
	selector: "app-create-publication",
	templateUrl: "./create-publication.component.html",
	styleUrls: ["./create-publication.component.scss"],
})
export class CreatePublicationComponent implements OnInit {
	@Input() title: string; //Nombre a mostrar para crear publuicación tipo {{title}}  || example: "Nuevo Evento"
	@Input() publication: any; //Instancia de la publicación
	@Input() publicationToShare: PublicationClass;
	@Input() disablePost: boolean; //Decide si hacer que el boton de publicar este disponible o no
	@Input() isPost: boolean = true; //If True -> Nueva Publicación, if False => Editar Publicación
	@Input() idPublicationToEdit: number;

	//private respaldodePublicacion: PublicationClass;

	constructor(
		private cameraService: CameraServiceService,
		private publicationService: PublicationService,
		private multimediaService: MultimediaService,
		private utils: Utils,
		private modalController: ModalController
	) { }

	ngOnInit() {
		this.disablePost = true;
		if (this.publicationToShare) {
			this.publication.SharedContainer = {
				publication_id: this.publicationToShare.id,
				publication: this.publicationToShare
			};

		}
		if (!this.isPost) {
			//this.respaldodePublicacion = this.publication;
		}
	}

	public takePhoto() {
		this.cameraService
			.takePhoto()
			.then((mediaItem) => {

				this.publication.setMultimedia(mediaItem);
				this.publication.doesItHaveMultimedia = true;
				//this.publication.setHaveMultimedia(true);
				this.check_if_can_post();
			})
			.catch((error) => {
				console.error(error)
			});
	}

	public pickImages() {
    this.cameraService
      .pickMedia(this.publication.getType() == "event" ? "Imagen" : null)
      .then((mediaItem: any) => {
        this.publication.setMultimedia(mediaItem);
        //this.publication.setHaveMultimedia(true);
        this.publication.doesItHaveMultimedia = true;
        this.check_if_can_post();
      })
      .catch((error) => {
        console.error(error);
      });
	}

	public takeVideo() {
		this.cameraService
			.takeVideo()
			.then((mediaItem) => {
				this.publication.setMultimedia(mediaItem);
				//this.publication.setHaveMultimedia(true);
				this.publication.doesItHaveMultimedia = true;
				this.check_if_can_post();
			})
			.catch((error) => {
				console.error(error);
			});
	}

	public check_if_can_post() {
		this.disablePost = true;
		if (this.publication.getType() == "event") {
			if (
				this.publication.timestamp_start != null &&
				this.publication.doesItHaveMultimedia &&
				!this.publication.title.match(/^ *$/)
			) {
				this.disablePost = false;
			}
		} else if (this.publication.getType() == "state") {
			if (this.publication.doesItHaveMultimedia) {
				this.disablePost = false;
			}
		} else if (this.publication.getType() == "normal") {
			this.disablePost = false;
		} else if (this.publication.getType() == "url") {
			if (this.publication && this.publication.address.length > 1) {
				this.disablePost = false;
			}
		} else {
			if (
				this.publication.doesItHaveMultimedia ||
				!this.publication.title.match(/^ *$/)
			) {
				this.disablePost = false;
			}
		}
	}

	public post() {
		this.utils.loadingPresent().then(() => {

			//console.error(this.publication.getPublicationsDataToSend());

			this.publicationService
				.postPublication(this.publication.getPublicationsDataToSend())
				.then((data: any) => {
					data = JSON.parse(data); // En una Historia el id devuelto debe ser el del estado recientemente agregado
					let publication_ids: Array<number> = [];
					publication_ids.push(data.id);
					if (data.publication_type == "story" && data.state_id != null) {
						publication_ids.push(data.state_id);
					}

					this.utils.loadingDismiss();
					if (this.publication.multimediaContainer.length != 0) {
						//En una historia el multimedia container lo deberia tener un estado
						//this.utils.alertPresent('En Preparación', 'Le notificaremos cuando pueda ver su publicación', 'OK');
						this.cameraService
							.postArrayMultimedia(
								this.publication.multimediaContainer,
								publication_ids,
								"publications",
								this.publication.publication_type
							)
							.finally(() => {
								this.updateStatusPublication(publication_ids)
									.then(() => {
										this.publicationService
											.getPublicationByID(publication_ids[0], true)
											.then((publication) => {
												// De ser una historia la publicacion recien creada se debe agregar o reemplazar la historia actual

												if (publication.publication_type == "story") {
													this.publicationService.updatePublicationList(
														"MyStoriesList",
														publication,
														true
													);
												} else {
													this.publicationService.updatePublicationList(
														"MyFeedList",
														publication,
														true
													);
													this.publicationService.updatePublicationList(
														"MyPublicationsList",
														publication,
														true
													);
												}
											})
											.catch((error) => {
												console.error(error);
											});
									})
									.catch((error) => {
										console.error(error);
									});
								//this.publicationService.updatePublicationList('MyFeedList', data, true);
								//this.publicationService.updatePublicationList('MyPublicationsList', data, true);
							});
						this.cancelButton();
					} else {
						//this.utils.alertPresent('Exito', 'Publicación realizada con exito', 'OK');
						this.publicationService.updatePublicationList(
							"MyFeedList",
							data,
							true
						);
						this.publicationService.updatePublicationList(
							"MyPublicationsList",
							data,
							true
						);
						this.cancelButton();
					}
				})
				.catch((error) => {
					console.error(error);
					this.utils.loadingDismiss();
				});
		});
	}

	public updateStatusPublication(publication_ids: Array<number>) {
		return new Promise((resolve, reject) => {
			let status = {
				publication_type: "publication",
				data: {
					publication_status_id: 1,
				},
			};
			this.publicationService
				.putPublication(status, publication_ids[0])
				.then((data) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	public updatePublication() {
		this.utils.loadingPresent().then(() => {

			this.publicationService
				.putPublication(
					this.publication.getPublicationsDataToSend(),
					this.idPublicationToEdit
				)
				.then((data: any) => {
					data = JSON.parse(data);

					//this.publicationService.getPublications(this.publication.id).then((data: any)=>{
					//	this.publication = data;
					//}).catch((error)=>{
					//	console.error(error);
					//});

					//this.utils.alertPresent('Exito', 'La publicación se actualizo correctamente', 'OK');
					this.cancelButton();
				})
				.catch((error) => {
					console.error(error);
					if (error.error) {
						error = JSON.parse(error.error);
						if (error.error == "the publication no longer exist") {
							this.publicationService.DeleteLocalPublicationByID(
								this.publication.id
							);
							this.cancelButton();
						}
					}
				})
				.finally(() => {
					this.utils.loadingDismiss();
				});
		});
	}

	public deleteImages() {
		if (this.idPublicationToEdit) {
			this.multimediaService
				.deleteMultimedia(this.idPublicationToEdit, 0, "publications")
				.then((data) => {
					this.check_if_can_post();
				})
				.catch((error) => {
					console.error(error);
					this.utils.loadingDismiss();
				});
		} else {
			this.check_if_can_post();
		}
	}

	public getSrcFromUri(path: string) {
		let src = (<any>window).Ionic.WebView.convertFileSrc(path);

		return src;
	}

	public cancelButton() {
		//if(!this.isPost){
		//	this.publication = this.respaldodePublicacion;

		//}
		this.modalController.dismiss();
	}
}
