import { GooglemapsModule } from './components/googlemaps/googlemaps.module';

import {  Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { AlertController, IonicModule, IonicRouteStrategy, LoadingController } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { Utils } from './Models/Classes/utils';
import { User } from './Models/Classes/User/user';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgModule, NgZone} from '@angular/core';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { MediaCapture } from '@ionic-native/media-capture/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Vibration } from '@ionic-native/vibration/ngx';
import { ForegroundService } from '@ionic-native/foreground-service/ngx';
import { PublicationFeedPageModule } from './pages/publication-feed/publication-feed.module';
import { BarcodeScanner } from "@ionic-native/barcode-scanner/ngx";
import { CreatePublicationPageModule } from './pages/create-publication/create-publication.module';
import { Calendar } from '@ionic-native/calendar/ngx';
import { EventosEclipseViewPageModule } from './pages/ecosistemas/Eventos-Eclipse/eventos-eclipse-view/eventos-eclipse-view.module';
import { LandServiciosViewPageModule } from './pages/ecosistemas/land-servicios/land-servicios-view/land-servicios-view.module';
import { EducacionEpochViewPageModule } from './pages/ecosistemas/Educacion-Epoch/educacion-epoch-view/educacion-epoch-view.module';
import { HospedajeEarthViewPageModule } from './pages/ecosistemas/Hospedaje-Earth/hospedaje-earth-view/hospedaje-earth-view.module';
import { CalendariomodulModule } from './components/calendar/calendariomodul/calendariomodul.module';
import { TerminosCondicionesViewPageModule } from './pages/Terminos-Condiciones/terminos-condiciones-view/terminos-condiciones-view.module';
import { ChatPagePageRoutingModule } from './pages/chat/chat-page/chat-page-routing.module';
import {FileTransfer} from "@ionic-native/file-transfer/ngx";
import { File } from '@ionic-native/file/ngx';
import {AndroidPermissions} from "@ionic-native/android-permissions/ngx";
import { FileOpener } from "@awesome-cordova-plugins/file-opener/ngx";
import { request } from 'http';
import { FilePath } from '@ionic-native/file-path/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import {Base64} from "@ionic-native/base64/ngx";
import { PagoVerificacionModuleModule } from './components/pago-verificacion/pago-verificacion-module/pago-verificacion-module.module';
import { PagoVerificacionComponent } from './components/pago-verificacion/pago-verificacion.component';

import {SliderInformationComponent} from "./components/slider-information/slider-information.component";
import {
  NewPublicationButtonComponent
} from "./components/common-elements/buttons/new-publication-button/new-publication-button.component";
import { PagarCComponent } from 'src/app/components/pagar-c/pagar-c.component';
import {
  PrincipalPublicationComponent
} from "./components/publication/create/principal-components/principal-publication/principal-publication.component";
import { ContentMediaComponent } from './components/publication/single-elements/content-media/content-media.component';
import { MyFeedPagePage } from './pages/my-feed-page/my-feed-page.page';
import {
  PublicationMediaTemplateComponent
} from "./components/publication/publication-media-template/publication-media-template.component";
import {SectionsProfileComponent} from "./components/profile/sections-profile/sections-profile.component";
import { LkfilmViewBySlidesComponent } from './components/lkfilm-view-by-slides/lkfilm-view-by-slides.component';
import {
  LkfilmViewBySlidesModule
} from "./components/lkfilm-view-by-slides/lkfilm-view-by-slides/lkfilm-view-by-slides.module";
import {SliderInformationModule} from "./components/slider-information/slider-information.module";
import {ReconoceridModule} from "./components/slider-information/reconocerid/reconocerid/reconocerid.module";
import {ReconoceridComponent} from "./components/slider-information/reconocerid/reconocerid.component";
// import {VideoProcessingService} from "./services/videoprocessingservice/video-processing-service.service";
import { PublicationModuleModule } from './components/publication/publication-module/publication-module.module';
import {MainMenuComponent} from "./components/general-design/main-menu/main-menu.component";
import {HeaderBarComponent} from "./components/general-design/header-bar/header-bar.component";
import {ProfilePage} from "./pages/profile/profile.page";
import {PublicationFeedPage} from "./pages/publication-feed/publication-feed.page";
import { PublicationSavePageModule } from './pages/save-publication/publication-save/publication-save.module';
import{FingerprintAIO}from "@ionic-native/fingerprint-aio/ngx"
import { Stripe } from '@awesome-cordova-plugins/stripe/ngx';
//import { S3Client } from '@aws-sdk/client-s3';
//import { InAppPurchase2 } from '@ionic-native/in-app-purchase-2/ngx';
import {Confirmacion} from 'src/app/components/email-confirm/ConfirmaciondeCorreo1/Confirmacion';
import {RecargaStripe} from 'src/app/components/go-ecosystem/wallet-app/sections/recharge-wallet/RecargaStripe/Recarga-stripe';
//import {RecargasStripeModuleModule} from 'src/app/components/go-ecosystem/wallet-app/sections/recharge-wallet/RecargaStripe/recargas-stripe-module.module';
//import { MyWalletComponent } from 'src/app/components/go-ecosystem/wallet-app/sections/my-wallet/my-wallet.component';
//import { WalletAppComponent } from 'src/app/components/go-ecosystem/wallet-app/wallet-app.component';
//import {WebviewComponent} from 'src/app/components/go-ecosystem/webview/webview.component';
import { ApplePay } from '@ionic-native/apple-pay/ngx';
import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
//import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { PagosPorPublicacionComponent } from 'src/app/components/publication/create/Pagos/Pagos-por-publicacion/Pagos-por-publicacion.component';
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { DatePipe } from '@angular/common';
import { SpeechRecognition } from '@ionic-native/speech-recognition/ngx';
import { WalletAppComponent } from 'src/app/components/go-ecosystem/wallet-app/wallet-app.component';
import {WebviewComponent} from 'src/app/components/go-ecosystem/webview/webview.component';


@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,


  ],
  entryComponents: [],
  imports: [

    BrowserModule,
    NgxQRCodeModule,
    //RecargasStripeModuleModule,
    ReconoceridModule,
  LkfilmViewBySlidesModule,
    PagoVerificacionModuleModule,
    CommonModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientJsonpModule,
    CalendariomodulModule,
    PublicationFeedPageModule,
    CreatePublicationPageModule,
    //LandServiciosViewPageModule,
    //EventosEclipseViewPageModule,
    //EducacionEpochViewPageModule,
    //HospedajeEarthViewPageModule,
    TerminosCondicionesViewPageModule,
    ChatPagePageRoutingModule,
    PublicationSavePageModule,
    PublicationModuleModule,
  /*  ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),*/
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule





  ],
  providers: [

    Title, Meta,
   // WalletAppComponent,
    SpeechRecognition,
    DatePipe,
    PagosPorPublicacionComponent,
    Deeplinks,

    //MyWalletComponent,
    RecargaStripe,
    Confirmacion,
    InAppPurchase2,
    Stripe,
    //VideoProcessingService,
    PublicationFeedPage,
    LkfilmViewBySlidesComponent,
    SectionsProfileComponent,
    PublicationMediaTemplateComponent,
    PrincipalPublicationComponent,
    PagarCComponent,
    NewPublicationButtonComponent,
    Base64,
    SliderInformationComponent,
    FileChooser,
    FilePath,
    FileOpener,
    AndroidPermissions,
    File,
    FileTransfer,
    Utils,
    User,
    LoadingController,
    AlertController,
    StatusBar,
    Camera,
    NativeStorage,
    MediaCapture,
    HTTP,
    LocalNotifications,
    Clipboard,
    AppVersion,
    BackgroundMode,
    NativeAudio,
    ForegroundService,
    InAppBrowser,
    Vibration,
    BarcodeScanner,
    Calendar,
    PagoVerificacionComponent,
    ContentMediaComponent,
    MyFeedPagePage,
    ReconoceridComponent,
    MainMenuComponent,
    HeaderBarComponent,
    FingerprintAIO,
    


    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },

  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
