import { MediaCapture } from '@ionic-native/media-capture/ngx';
import { CameraServiceService } from './../../../../../services/multimedia/camera-service.service';
import { Component, OnInit } from '@angular/core';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import { MediaItem } from 'src/app/Models/Classes/Media/media-item';

@Component({
  selector: 'app-landservice-pago',
  templateUrl: './landservice-pago.component.html',
  styleUrls: ['./landservice-pago.component.scss'],
})
export class LandservicePagoComponent implements OnInit {
  listaDivisas=[
    {
      id:104,
      name:"MXN",
      valor: 1000,
      conversion:100
    },
    ];
  AdicionalServices:Array<object>=[]
  public multimedia2: Array<MediaItem> = new Array<MediaItem>()

  ruta:any
  namefile:any


  constructor(public formservice:FormServiceService , private cameraService: CameraServiceService) { }

  ngOnInit() {}
  
  hide:boolean = false;
	ngIfCtrl(){
  	this.hide = !this.hide;
	}
  GuardarServicios(){



   const Servicios ={
     titulo: this.formservice.landpublication.title2,
     descripcion: this.formservice.landpublication.description2,
     precio: this.formservice.landpublication.price,
     imagen: this.ruta + this.namefile

   }

   this.AdicionalServices.push(Servicios)
   this.formservice.landpublication.ServiciosAdicionales= this.AdicionalServices

   console.log("Servicios Guardados: ", this.formservice.landpublication.ServiciosAdicionales);
   
  }

  setMultimedia(mediaItem: MediaItem) {
    this.multimedia2.push(mediaItem)
    this.formservice.multimedia2 = this.multimedia2

   

    this.formservice.multimedia2.forEach(element => {

      this.ruta=element.mediaPath
      this.namefile= element.nameFile

     
      

      
      
    });
    

    
  }

  public pickImages() {
    this.cameraService
      .CargarMedia()
      .then((mediaItem) => {
        this.setMultimedia(mediaItem);
      })
      .catch((error) => {
        console.error(error);
      });
  }






  cantidad(event) {
   
    this.formservice.landpublication.cantidad=event.target.value
  }

  price(event) {
   
    this.formservice.landpublication.price=event.target.value
  }

  title(event) {
   
    this.formservice.landpublication.title2=event.target.value
  }

 

  description(event) {
    
    this.formservice.landpublication.description2=event.target.value
  }

  

}


