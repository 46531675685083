import { Component, OnInit } from '@angular/core';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import capacidadJson from 'src/assets/plantillas/capacidad-hospedaje.json'

@Component({
  selector: 'app-earht2',
  templateUrl: './earht2.component.html',
  styleUrls: ['./earht2.component.scss'],
})
export class Earht2Component implements OnInit {
  public capacidadEarth:Array<string>=Object.keys(capacidadJson.Capacidad)

  constructor(public formservice: FormServiceService) { }

  ngOnInit() {
    console.log(Object.keys(capacidadJson.Capacidad))
  }

  selectCapacidad(event) {
    
    this.formservice.earthpublication.capacidad=event.target.value
  }

  selectAlojamiento(event) {
   
    this.formservice.earthpublication.alojamiento=event.target.value
  }
  
  selectAlojamientoExclusivo(event) {
    
    this.formservice.earthpublication.alojamientoExclusivo=event.target.value
  }

}
