import { Component, Input, OnInit, SimpleChange } from '@angular/core';

@Component({
	selector: 'app-saldo-goi',
	templateUrl: './saldo-goi.component.html',
	styleUrls: ['./saldo-goi.component.scss'],
})
export class SaldoGoiComponent implements OnInit {

	@Input() current_amount: number = 0.00;
	public actual_balance: string = "0.00";
	constructor() {	}

	ngOnInit() { 
		this.actual_balance = this.formaNumber();
	}

	formaNumber(){
		if(this.current_amount > 999999){
			return "+999,999.00";
		}
		else{
			var parts:Array<string> = this.current_amount.toString().split(".");
			parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			if(parts.length==1){
				parts.push("00")
			}
			return parts.join(".");
		}
		
	}

	ngOnChanges(changes: SimpleChange) {
		if (changes['current_amount'] != undefined) {
			this.formaNumber();
		}
	}

}
