import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MediaItem } from 'src/app/Models/Classes/Media/media-item';
import { ProfileService } from 'src/app/services/profile/profile.service';
import {FormServiceService} from "../../services/publications/form-service.service";
import Swal2 from "sweetalert2";
import { RequestService } from 'src/app/services/request/request.service';

@Component({
	selector: 'app-profile',
	templateUrl: './profile.page.html',
	styleUrls: ['./profile.page.scss'],
})
export class ProfilePage implements OnInit {
	stories=[];
	public available_sections: Array<string> = ["Fachada", "Publicaciones", "Relaciones", "Historias"];
	public icons_for_sections: Array<string> = ["person-outline", "documents-outline", "people-outline", "hourglass-outline"];
	public actual_section:string = "Publicaciones";

	public profile: any = null;
	public profile_id: number;
  public mostrar = true;

	constructor(
		public changeDetector: ChangeDetectorRef,
		private profile_service: ProfileService,
		public route: ActivatedRoute,
		private formService: FormServiceService,
		private request: RequestService,


	) {
		this.profile_id = this.route.snapshot.params.profile_id;
	}

	ngOnInit(){

		console.log("hola estas en el ngoninit")

	}

	ionViewWillEnter() {
		
			this.profile_service.getProfileInfo(this.profile_id).then((profileInfo)=>{
				profileInfo = JSON.parse(profileInfo);
				this.profile =profileInfo;
				this.profile_id = profileInfo.id;
			}).catch((error)=>{
				console.error(error);
			}).finally(()=>{

				
			});
		/*
			setTimeout(() => {
		 if(this.formService.titleHeader==='🔑PERFIL'){
			Swal2.fire({
			  title: '🔑',
			  text: 'Debes iniciar sesión. Da click en "🔑PERFIL", para ver tu perfil.',
			  icon: 'info',
			  confirmButtonText: 'Entendido',
			  background: '#010f4e',
			  });
		  }
		}, 999);*/
	}

	section_change(filtro){
		this.actual_section = filtro;
		if(this.actual_section == 'Historias'){
			this.getStories();
			this.mostrar=false;
		}
	}

	reloadProfile(){
		this.profile_service.getProfileInfo(this.profile_id).then((data)=>{
			data = JSON.parse(data);

			this.profile = data;
			this.profile_id = data.id;
		}).catch((error)=>{
			console.error(error);
		}).finally(()=>{

		});
	}
	public getStories() {
		this.request.createRequestPost('get_stories_by_user', { profile_id: this.profile_id })
			.then((final_data) => {
				console.log('stories1', final_data);
	
				final_data.forEach((publication, index) => {
					console.log(`Processing publication ${index + 1} with id ${publication.id}`);
					if (publication.container && Array.isArray(publication.container.states)) {
						publication.container.states.forEach((state, stateIndex) => {
							console.log(`Pushing state ${stateIndex + 1} of publication ${publication.id}`);
							console.log(state);
							if(state.multimediaCounter !== 0){
								this.stories.push(state);
							}
						
						});
					}
					console.log('Current length of stories:', this.stories.length);
				});
	
				console.log('Final stories array:', this.stories);

			})
			.catch((error) => {
				console.error('Error fetching stories:', error);
			});
	}
	
}
