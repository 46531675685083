import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { MediaItem } from 'src/app/Models/Classes/Media/media-item';
import { PublicationClass } from 'src/app/Models/Classes/Publications/publication-class';

@Component({
	selector: 'app-multimedia-view',
	templateUrl: './multimedia-view.component.html',
	styleUrls: ['./multimedia-view.component.scss'],
})
export class MultimediaViewComponent implements OnInit {

	public showSlides: boolean;
	public slideOpts = {};

	@Input() multimedia: Array<MediaItem>;
	@Input() index: number = 0;
	@Input() publication: PublicationClass;
	@Output() savePublication = new EventEmitter();
	@Output() sharePublication = new EventEmitter();

	@ViewChild("slider") slider: any;
	constructor() { }

	ngOnInit() {
		this.slideOpts = {
			initialSlide: this.index,
			speed: 400,
			zoom: {
				maxRatio: 10,
			},
		};

	}

	public Replace(url:string){
		return url.split('?')[0]
	  }

	ionViewDidEnter() {
		this.showSlides = true;
		this.slider.update();
	}

	isVideo(url: string) {
		let split = url.split('.');
		for (let index = 0; index < split.length; index++) {
			if (index == split.length - 1) {
				if (split[index] == 'mp4') {
					return true;
				}
			}
		}
		return false;
	}

	savePublication_click(){
		this.savePublication.emit();
	}

	sharePublication_click(){
		this.sharePublication.emit();
	}

}
