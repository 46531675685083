import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-pago-completado',
  templateUrl: './pago-completado.component.html',
  styleUrls: ['./pago-completado.component.scss'],
})
export class PagoCompletadoComponent implements OnInit {

  constructor(public modalController:ModalController) { }

  ngOnInit() {}
  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

}
