import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-component-button-switch',
  templateUrl: './component-button-switch.component.html',
  styleUrls: ['./component-button-switch.component.scss'],
})
export class ComponentButtonSwitchComponent implements OnInit {

  @Input() optionSelected: string;
  @Input() optionA: string;
  @Input() optionB: string;
  @Input() optionC: string;
  @Output() changeRolApp = new EventEmitter<string>();

  emitChangesOnRolApp(event){
    this.changeRolApp.emit(event.detail.value);
  }

  constructor() { }

  ngOnInit() {}

}
