import { Component, OnInit } from '@angular/core';
import { ActionSheetController, ModalController, NavController } from '@ionic/angular';
import { HistoryViewComponent } from 'src/app/components/history-view/history-view.component';
import { CreatePublicationPage } from 'src/app/pages/create-publication/create-publication.page';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import { StatePublication } from 'src/app/Models/Classes/Publications/Story/StatePublication/state-publication';
import {ModulesLockComponent} from "../../../go-ecosystem/modules-lock/modules-lock.component";
import { InputAlertControllerService } from 'src/app/services/utils/input-alert-controller.service';
import { SliderInformationComponent } from 'src/app/components/slider-information/slider-information.component';
import { PagoVerificacionComponent } from 'src/app/components/pago-verificacion/pago-verificacion.component';
import { WalletServiceService } from 'src/app/services/wallet/wallet-service.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import {Capacitor} from "@capacitor/core";
import {ChimeContactComponent} from "src/app/chime-contact/meeting/chime-contact.component";
import { ShootingStar6Component} from 'src/app/components/publication/create/shooting-star/shooting-star6/shooting-star6.component';
import  Swal from 'sweetalert2';
import {InlineBowsingComponent} from 'src/app/inline-bowsing/inline-bowsing.component';
import { CreateProductComponent } from 'src/app/components/go-ecosystem/store/create-product/create-product.component';
@Component({
  selector: 'app-new-publication-button',
  templateUrl: './new-publication-button.component.html',
  styleUrls: ['./new-publication-button.component.scss'],
})
export class NewPublicationButtonComponent implements OnInit {
  pr:any
  isActionSheetPresented:boolean=false;
  constructor(private formservice: FormServiceService,
    private modalController: ModalController,
    private ActionSheetController: ActionSheetController,
    public navCtrl: NavController,
    private inputAlertController: InputAlertControllerService,
    private walletService: WalletServiceService,
   
    private profile:ProfileService
  ) { }
  //Se llena el array
  ngOnInit() {
    if (this.formservice.profile){
      this.pr=this.formservice.profile;
      this.pr.username=this.formservice.profile.username;
      this.pr.profile_photo=this.formservice.profile.profile_photo;
    }else{
      this.profile.getProfileInfo().then((result)=>{
        this.pr = JSON.parse(result);
        console.log("profile", this.pr);
      });
  
    }
   
    
  }
ngAfterViewInit(){

}
  // redirectCreatePublication() {
  // 	//redirect to create publication
  // 	this.navCtrl.navigateForward('create-publication');
  // }
  async presentActionSheet() {
    if(!this.pr){
      this.pr=this.formservice.profile;
      this.pr.username=this.formservice.profile.username;
      this.pr.profile_photo=this.formservice.profile.profile_photo;
    }
    if (this.isActionSheetPresented) {
      return; // Prevent multiple invocations
    }

    this.isActionSheetPresented = true;
    Swal.fire({
			title: '',
			text: '',
			icon: 'success',
			timer: 9999, // Show the alert for 45 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-start',
			showConfirmButton: false
		  });
    setTimeout(() => {
      this.isActionSheetPresented = false; // Reset flag after dismissal
      this.ActionSheetController.dismiss();
      if(!this.pr){
        Swal.fire({
          title: '🔑',
          text: 'Debes iniciar sesión. Da click en "🔑PERFIL", para ver tu perfil.',
          icon: 'info',
          confirmButtonText: 'Entendido',
          background: '#010f4e',
          });
      }
      }, 9999); 
    const actionSheet = await this.ActionSheetController.create({
      header: '¿Qué vas a publicar?',
      cssClass: 'title-action custom-action-sheet',
      buttons: [{
        text: 'Historia',
        icon: 'hourglass-outline',
        cssClass: 'icon custom-action-sheet',
        handler: () => {
          let ecosystem = "story";
          this.presentModalHistory();
        }
      }, {
        text: 'Normal',
        icon: 'person-outline',
        cssClass: 'icon custom-action-sheet',
        handler: () => {
          let ecosystem = "normal";
          this.formservice.publication_type = ecosystem;
          this.presentModal();
        }
      }, {
        text: ' LK Filme Estudio',
        icon: 'film-outline',
        cssClass: 'icon custom-action-sheet',
        handler: () => {
          this.openKYCApplicationModal('lkfilm');

        }
      }, 
      {
        text: 'Bienes FEEX',
        icon: 'cart-sharp',
        cssClass: 'icon custom-action-sheet',
        handler: () => {
          this.openKYCApplicationModal('feex');

        }
      }, 
      {
          text: 'Servicios Land',
          icon: 'body-outline',
          cssClass: 'icon custom-action-sheet',
          handler: () => {
            //let ecosystem = "land";
            //this.formservice.publication_type = ecosystem
            //this.presentModal();
            this.openKYCApplicationModal('land');
          }
        }, {
          text: 'Eventos Eclipse',
          icon: 'ellipse-outline',
          cssClass: 'icon custom-action-sheet',
          handler: () => {
            //let ecosystem = "eventos";
           // this.formservice.publication_type = ecosystem
           // this.presentModal();
           this.openKYCApplicationModal('eventos');
  
          }
        }, {
          text: 'Educación Epoch',
          icon: 'easel',
          cssClass: 'icon custom-action-sheet',
          handler: () => {
           // let ecosystem = "epoch";
           // this.formservice.publication_type = ecosystem
           // this.presentModal();
           this.openKYCApplicationModal('epoch');
          }
        }, {
          text: 'Hospedaje Earth',
          icon: 'home',
          cssClass: 'icon custom-action-sheet',
          handler: () => {
           // let ecosystem = "earth";
           // this.formservice.publication_type = ecosystem
           // this.presentModal();
           this.openKYCApplicationModal('earth');
          }
        },
        {
          text: 'Conductor de Estrellas Fugaces ',
          icon: 'star-sharp',
          cssClass: 'icon custom-action-sheet',
          handler: () => {
           this.openKYCApplicationModal('ShootingStars');
          }
        },
        {
          text: 'Transmisión en vivo',
          icon: 'radio-sharp',
          cssClass: 'icon custom-action-sheet',
          handler: () => {
           // let ecosystem = "earth";
           // this.formservice.publication_type = ecosystem
           // this.presentModal();
           this.openKYCApplicationModal('live');
          }
        },
        {
          text: 'Entrelazamiento',
          icon: 'git-merge-sharp',
          cssClass: 'icon custom-action-sheet',
          handler: () => {
           let ecosystem = "earth";
           this.formservice.publication_type = 'Entrelazamiento';
           // this.presentModal();
           this.openEntrelazamiento();
          }
        },
        {
          text: 'LK MÚSICA',
          icon: 'musical-notes-sharp',
          cssClass: 'icon custom-action-sheet',
          handler: () => {
           let ecosystem = "LK MUSIC";
           this.formservice.publication_type = ecosystem;
           this.presentModal();
          }
        },

      // {
      //   text: 'Servicios. Land',
      //   icon: 'cog',
      //   cssClass: 'icon',
      //   handler: () => {
      //     let ecosystem = "land";
      //     this.formservice.publication_type = ecosystem
      //     this.presentModal();

      //   }
      // }, {
      //   text: 'Eventos. Eclipse',
      //   icon: 'calendar-number-outline',
      //   cssClass: 'icon',
      //   handler: () => {
      //     let ecosystem = "eventos";
      //     this.formservice.publication_type = ecosystem
      //     this.presentModal();

      //   }
      // }, {
      //   text: 'Educación. Epoch',
      //   icon: 'book-outline',
      //   cssClass: 'icon',
      //   handler: () => {
      //     let ecosystem = "epoch";
      //     this.formservice.publication_type = ecosystem
      //     this.presentModal();

      //   }
      // }, {
      //   text: 'Hospedaje. Earth',
      //   icon: 'bed',
      //   cssClass: 'icon',
      //   handler: () => {
      //     let ecosystem = "earth";
      //     this.formservice.publication_type = ecosystem
      //     this.presentModal();

      //   }
      // }
    ]
    });
    await actionSheet.present();

    const { role } = await actionSheet.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
    this.isActionSheetPresented = false; // Reset flag after dismissal
  }

  async presentModal() {
    //let not_allowed = ['land', 'eventos', 'epoch',  'earth'];
     let not_allowed = [''];
    let modal = null;
    if(not_allowed.includes(this.formservice.publication_type)) {
      // abrir proximamente
      modal = await this.modalController.create({
        component: ModulesLockComponent,
        cssClass: 'my-custom-class detailsInModal',
        componentProps: {

        }
      });
    }else if(this.formservice.publication_type==='live'){
      if (Capacitor.getPlatform() === 'web') {
        this.formservice.webViewLive=false;
        modal = await this.modalController.create({
          //component: ModulesLockComponent,
          component: ChimeContactComponent,
          cssClass: 'detailsInModal',
          swipeToClose: true,
          componentProps: {
          username:this.pr.username,
          profile_photo: this.pr.profile_photo.url,
          isPublication:true,

            },
        })
      }else{
        let isPublication=true;
        let url= `https://supercumulo.laniakea.tv/loader?meeting=0&username=${this.pr.username}&isPublication=${isPublication}&profile_photo=${this.pr.profile_photo.url}`;
        console.log(url);
        this.openBrowser(url);
      }

    } else if(this.formservice.publication_type==='ShootingStars'){
      modal = await this.modalController.create({
        component: ShootingStar6Component,
        cssClass: 'my-custom-class detailsInModal',
        componentProps: {
          ShootingStars: true,
        }
      });
    }else{
      modal = await this.modalController.create({
        swipeToClose: true,
        component: CreatePublicationPage,
        cssClass: 'my-custom-class detailsInModal',
        componentProps: {

        }
      });
    }
    return await modal.present();
  }
  async openBrowser(url: string) {
		//await Browser.open({ url });
		const modal = await this.modalController.create({
			cssClass: 'detailsInModal',
			swipeToClose: true,
			component: InlineBowsingComponent,
			componentProps: {
				url: url,
			
			},
		  });
		  modal.onDidDismiss().then((data)=>{
			
		  });
		  return await modal.present();
		window.open(url, '_blank'); // Open the URL in a new tab or window
	  }
  async presentModalHistory() {
    let publication = new StatePublication();
    publication.publication_type = "state";
    const modal = await this.modalController.create({
      component: HistoryViewComponent,
      cssClass: 'my-custom-class detailsInModal',
      componentProps: {
        title: "Nueva Historia",
				publication: publication
      }

    });
    return await modal.present();
  }

  private openKYCApplicationModal(app:any) {
		this.walletService.KYC_validation().then((result_kyc) => {
		  if (result_kyc['permit']) {
			this.showAppModal(app);
		  } else if (this.pr.kyc === "Sin Validar") {
			this.showSliderInformationModal();
		  } else  {
			this.showPagoVerificacionModal();
		  }
		}).catch((error) => {
		  console.error(error);
		});
	  }
	  
	  private showAppModal(app) {
      let ecosystem = app;
      this.formservice.publication_type = ecosystem
      this.presentModal();
	  }
	  
	  private showSliderInformationModal() {
		this.inputAlertController.OkInfoMessage(
		  'Usuario no verificado',
		  'Esta característica solo está disponible para usuarios verificados, sigue los pasos en la sección de configuración de la cuenta y completa la verificación de la cuenta para acceder a esta funcionalidad',
		  'Ir a completar mi perfil'
		);
		this.modalController.create({
		  component: SliderInformationComponent,
		  componentProps: {
			pr: this.pr,
		  },
		}).then((modal) => {
		  modal.present();
		}).catch((error) => {
		  console.error(error);
		});
	  }
	  
	  private showPagoVerificacionModal() {
		this.inputAlertController.OkInfoMessage(
		  'Usuario no verificado',
		  'Esta característica solo está disponible para usuarios verificados, sigue los pasos en la sección de configuración de la cuenta y completa la verificación de la cuenta para acceder a esta funcionalidad',
		  'Ir a completar mi perfil'
		);
		console.log("entro al else if ");
	  
		this.modalController.create({
		  component: PagoVerificacionComponent,
		  swipeToClose: true,
		  componentProps: {
			pr: this.pr
		  }
		}).then((modal) => {
		  modal.present();
		});
	  }	  
    async openEntrelazamiento(){
      let modal = null;
       modal = await this.modalController.create({
        component: CreatePublicationPage,
        cssClass: 'my-custom-class detailsInModal',
        componentProps: {
          entrelazamiento:true,
        }
      });
      return await modal.present();
    }
}
