import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DescripcionPublicationComponent } from '../descripcion-publication/descripcion-publication.component';
import { IonicModule } from '@ionic/angular';

import { FormsModule } from '@angular/forms'; // Import FormsModule

@NgModule({
	declarations: [
		DescripcionPublicationComponent
	],
	exports: [
		DescripcionPublicationComponent
	],
	entryComponents: [
		DescripcionPublicationComponent
	],
	imports: [
		CommonModule,
		IonicModule,
		FormsModule // Add FormsModule here

	]
})
export class DescriptionModuleModule { }
