import { Component, OnInit,Input } from '@angular/core';
import Swal2 from 'sweetalert2';
import {RequestService} from "../services/request/request.service";
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-bbva-account',
  templateUrl: './bbva-account.component.html',
  styleUrls: ['./bbva-account.component.scss'],
})
export class BBVAAccountComponent implements OnInit {
  //start automobiles
public AutomobileLoanForm:boolean=false; 
vehicleYear;
vehicleAmount;
downPaymentAmount;
installmentPlanTermsNumber;
installmentPlanTermsFrequency;
casualtyInsurance;
//finish automobiles
  ///START MORTGAGES
  public certificadoHipotecario:any;
  financingProgramOptions = [
    { label: 'INFONAVIT en cofinanciamiento con BBVA y aprovechando el ahorro en el saldo de la subcuenta de vivienda.', checked: false, value: 'COFINAVIT' },
    { label: 'INFONAVIT en cofinanciamiento con BBVA.', checked: false, value: 'APOYO_INFONAVIT' },
    { label: 'FOVISSSTE en cofinanciamiento con BBVA y aprovechando el ahorro en el saldo de la subcuenta de vivienda.', checked: false, value: 'ALIA2' },
    { label: 'FOVISSSTE otorga al trabajador únicamente el ahorro del saldo de la subcuenta de vivienda en cofinanciamiento con BBVA.', checked: false, value: 'RESPALDA2' },
  ];
  consideredAmounts_TypeOptions = [
    { label: 'Valor de la propiedad.', checked: false, value: 'PROPERTY_VALUE' },
    { label: 'Monto Real solicitado.', checked: false, value: 'REQUESTED_AMOUNT' },
    { label: 'Pago de cada plazo definido, hasta cumplir con el monto del préstamo.', checked: false, value: 'MONTHLY_INSTALLMENT' },
    { label: 'Pagos que no está registrado en el buró de crédito.', checked: false, value: 'NON_REGISTERED_BUREAU_PAYMENT' },
    { label: 'Pago adicional al inicio de la compra de un bien caro.', checked: false, value: 'ADDITIONAL_DOWN_PAYMENT' },
    { label: 'Suma de todos los ingresos mensuales, tanto fijos como variables.', checked: false, value: 'MONTHLY_INCOMES' },
  ];
  hasCreditProgram:boolean=false;
  financingProgramTypeLabel;
  @Input() mortgages: boolean=false;
  public purposeId: any;
  public promoterName: any;
  public hasPaycheck: any;
  public salaryAmount: number=0;
  public OccupationSector: any;
  public occupation_SENIORITY: any;
  public customer_property_status: any;
  public consideredAmount: any;
  public consideredAmounts_TypeID: any;
  public loanTermsYears: any;
  public financingProgramType: any;
  public financingProgramGrantedAmount: any;
  public financingProgramContributionsBalance: any;
  public financingProgramExpenses: any;
  public notaryFeesPercentage: any;
  public GastosDeInvestigacion: any;
  public CustomergenderId: any;
  public CustomerMaritalStatusId: any;
  public addressStateId: any;
  public incomes:any;
  public isEmployed:any;

  //END MORTGAGES
  
  //start create account
  public genderId: any;
  public birthCountryId: any;
  public birthStateId: any;
  public addressName: any;
  public addressCountryId: any;
  public addressZipCode: any;
  public INTERIOR_NUMBER: any;
  public EXTERIOR_NUMBER: any;
  public MUNICIPALITY: any;
  public addressCOLONY: any;
  public acceptTerms: any;
  public acceptRemarketing: any;
  public acceptCheckCreditScore: any;
  public acceptSharingData: any;
  public hasCreditCard: any;
  public creditCard_lastFourDigits: any;
  public hasAutoLoan: any;
  public hasMortgageLoan: any;
  //end create account

  // START BBVA ACCOUNT
  backgroundImage= 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Convenios/BBVA_.png';
  public image_brand = 'assets/icon/pagos/visa.png';
	remainingTime: number;
	public token: string;
  private countdownInterval: any;
  BBVA_ACCOUNT: any=[];
  create_account:boolean=false;
  bindCardToAccount:boolean=false;
  sentCode:boolean=false;
  celular:boolean=false;
  // END BBVA ACCOUNT
  constructor(
    private request:RequestService,
    public modalCTRL:ModalController
    ) { 
    
  }

  ngOnInit() {
    if(!this.mortgages){
      this.fetchBBVA_ACCOUNT();
    }
  }
  updateFinancingProgramTypeLabel() {
    const selectedOption = this.financingProgramOptions.find(option => option.value === this.financingProgramType);
    this.financingProgramTypeLabel = selectedOption ? selectedOption.label : '';
  }
  
  ConfirmRequestMortgage(){
    if(this.BBVA_ACCOUNT.CLABE){
    Swal2.fire({
      title: 'Procesando...',
      text: 'Laniakea está procesando.',
      icon: 'success',
      timer: 3900, // Show the alert for 3.9 seconds
      timerProgressBar: true,
      toast: true,
      position: 'top-end',
      showConfirmButton: false
      });
      if(this.hasCreditProgram){
        if(!this.financingProgramType || !this.financingProgramGrantedAmount || !this.financingProgramContributionsBalance 
          || !this.financingProgramExpenses ||
    this.financingProgramType === null ||
    this.financingProgramType === undefined ||
    this.financingProgramGrantedAmount === null ||
    this.financingProgramGrantedAmount === undefined ||
    this.financingProgramContributionsBalance === null ||
    this.financingProgramContributionsBalance === undefined ||
    this.financingProgramExpenses ===null ||
    this.financingProgramExpenses ===undefined){
          Swal2.fire({
            title: 'Error',
            text: 'Llena toda la información requerida del Programa de Financiamiento',
            icon: 'error',
            confirmButtonText: 'Entendido',
            background: '#010f4e',
            });
          return;
        }
      }
      if (!(this.loanTermsYears >= 5 && this.loanTermsYears <= 20)) {
        // Return or perform actions if the condition is not met
        Swal2.fire({
          title: 'Error',
          text: 'El plazo del prestamo es de mínimo 5 años y máximo 20 años.',
          icon: 'error',
          confirmButtonText: 'Entendido',
          background: '#010f4e',
          });
        return;
      }
      if (!(this.notaryFeesPercentage >= 3 && this.notaryFeesPercentage <= 10)) {
        // Return or perform actions if the condition is not met
        Swal2.fire({
          title: 'Error',
          text: 'El porcentaje de honorarios notariales debe ser entre 3 y 5 porciento.',
          icon: 'error',
          confirmButtonText: 'Entendido',
          background: '#010f4e',
          });
        return;
      }
      
      if (this.hasCreditProgram && !(this.financingProgramExpenses >= 3 && this.financingProgramExpenses <= 10)) {
        // Return or perform actions if the condition is not met
        Swal2.fire({
          title: 'Error',
          text: `El porcentaje de gastos adicionales en ${this.financingProgramTypeLabel}, debe ser entre 3 y 5 porciento.`,
          icon: 'error',
          confirmButtonText: 'Entendido',
          background: '#010f4e',
          });
        return;
      }
      if( this.purposeId === 'HOUSE' || this.purposeId === 'LAND' ){
        if (!this.addressStateId){
          Swal2.fire({
            title: 'Error',
            text: `Si el propósito del  crédito hipotecario es adquirir una casa o terreno, debes selccionar el estado donde planeas adquirir la propiedad.`,
            icon: 'error',
            confirmButtonText: 'Entendido',
            background: '#010f4e',
            });
            return;
        }
      }
      
      if (this.isEmployed && this.incomes && this.CustomerMaritalStatusId && this.CustomergenderId && this.GastosDeInvestigacion && this.notaryFeesPercentage && this.salaryAmount && this.consideredAmounts_TypeID && this.loanTermsYears && this.purposeId && this.OccupationSector && this.occupation_SENIORITY && this.customer_property_status){
    this.request.createRequestPost('requestMortgage', {addressStateId: this.addressStateId, isEmployed:this.isEmployed, incomes:this.incomes, CustomerMaritalStatusId:this.CustomerMaritalStatusId, CustomergenderId:this.CustomergenderId, GastosDeInvestigacion: this.GastosDeInvestigacion, notaryFeesPercentage:this.notaryFeesPercentage, hasCreditProgram:this.hasCreditProgram, financingProgramExpenses:this.financingProgramExpenses, financingProgramContributionsBalance:this.financingProgramContributionsBalance, financingProgramGrantedAmount:this.financingProgramGrantedAmount, financingProgramType: this.financingProgramType, consideredAmounts_TypeID: this.consideredAmounts_TypeID, loanTermsYears:this.loanTermsYears, hasPaycheck: this.hasPaycheck, purposeId:this.purposeId, salaryAmount:this.salaryAmount, OccupationSector:this.OccupationSector, occupation_SENIORITY: this.occupation_SENIORITY, customer_property_status:this.customer_property_status})
    .then((final_data) => {
      console.log(final_data);
      if(final_data.success){
        //this.dismiss();
        this.certificadoHipotecario=final_data.success.certificate;
      Swal2.fire({
      title: 'Solicitud enviada exitosamente.',
      text: `La solicitud ha sido enviado exitosamente, se te notificará a tu correo electrónico: ${final_data.success.email}. Puedes ir a tu sucursal de BBVA con el siguiente certificado para finalizar los trámites del crédito hipotecario. `,
      icon: 'success',
      confirmButtonText: 'Aceptar',
      background: '#010f4e',
      });
      
    }else{
      Swal2.fire({
        title: 'Error',
        text: 'Ocurrió un error inesperado. Si el problema persiste, comunícate con el soporte de Laniakea. ',
        icon: 'error',
        confirmButtonText: 'Entendido',
        background: '#010f4e',
        });
     }

    })
    .catch((error) => {
      console.error('An error occurred:', error);
      Swal2.fire({
      title: 'Error',
      text: 'Ocurrió un error inesperado. Si el problema persiste, comunícate con el soporte de Laniakea. ',
      icon: 'error',
      confirmButtonText: 'Entendido',
      background: '#010f4e',
      });
    });
  }else{
    Swal2.fire({
      title: 'Error',
      text: 'Porfavor llena todos los campos, ya que son esenciales.',
      icon: 'error',
      confirmButtonText: 'Entendido',
      background: '#010f4e',
      });
  }
}else{
  Swal2.fire({
    title: 'Error',
    text: 'Primero tienes que crear una cuenta BBVA, luego asociar la tarjeta a la cuenta BBVA.',
    icon: 'error',
    confirmButtonText: 'Entendido',
    background: '#010f4e',
    });
 }
  }

  MortgageForm(){
    this.mortgages=true;
  }
  dismiss(){
    this.stopCountdown();
    this.modalCTRL.dismiss();
    this.mortgages=false;
    this.bindCardToAccount=false;
    this.create_account=false;
  }

  createBBVA_ACCOUNT(){
this.create_account=true;
this.mortgages=false;
this.bindCardToAccount=false;
  }
  RenderbindCardToAccountForm(){
    this.bindCardToAccount=true;
    this.create_account=false;
    this.mortgages=false;
  }
  registrarme_con_bbva(){
    if (this.addressZipCode.length != 5 ){
      Swal2.fire({
        title: 'Error',
        text: 'El código postal debe ser de 5 dígitos. ',
        icon: 'error',
        confirmButtonText: 'Aceptar',
        background: '#010f4e',
        });
        return;
    }
    if (!this.INTERIOR_NUMBER && !this.EXTERIOR_NUMBER && !this.MUNICIPALITY && !this.addressCOLONY){
      Swal2.fire({
        title: 'Error',
        text: 'La dirección geográfica debe estar completa. ',
        icon: 'error',
        confirmButtonText: 'Aceptar',
        background: '#010f4e',
        });
        return;
    }
    if(!this.BBVA_ACCOUNT.CLABE){
      Swal2.fire({
        title: 'Procesando...',
        text: 'Laniakea está procesando.',
        icon: 'success',
        timer: 3900, // Show the alert for 3.9 seconds
        timerProgressBar: true,
        toast: true,
        position: 'top-end',
        showConfirmButton: false
        });
        if (this.genderId && this.birthCountryId && this.birthStateId && this.addressName && this.addressCountryId && this.addressStateId && this.addressZipCode && this.INTERIOR_NUMBER && this.EXTERIOR_NUMBER && this.MUNICIPALITY && this.addressCOLONY && this.acceptTerms && this.acceptRemarketing && this.acceptCheckCreditScore && this.acceptSharingData && this.hasCreditCard && this.creditCard_lastFourDigits && this.hasAutoLoan && this.hasMortgageLoan){
      this.request.createRequestPost('create_BBVA_ACCOUNT', {genderId:this.genderId, birthCountryId:this.birthCountryId, birthStateId:this.birthStateId, addressName: this.addressName, addressCountryId:this.addressCountryId, addressStateId: this.addressStateId, addressZipCode:this.addressZipCode, INTERIOR_NUMBER:this.INTERIOR_NUMBER, EXTERIOR_NUMBER:this.EXTERIOR_NUMBER, MUNICIPALITY:this.MUNICIPALITY, COLONY:this.addressCOLONY, acceptTermsAndConditions:this.acceptTerms, acceptRemarketing:this.acceptRemarketing, acceptCheckCreditScore:this.acceptCheckCreditScore, hasCreditCard:this.hasCreditCard, creditCard_lastFourDigits:this.creditCard_lastFourDigits, hasAutoLoan:this.hasAutoLoan, hasMortgageLoan:this.hasMortgageLoan  })
      .then((final_data) => {
       console.log(final_data);
       if(final_data.success){
        this.dismiss();
        Swal2.fire({
          title: 'Cuenta creada exitosamente.',
          text: `La cuenta ha sido creada exitosamente `,
          icon: 'success',
          confirmButtonText: 'Aceptar',
          background: '#010f4e',
          });
       }else{
        Swal2.fire({
          title: 'Error',
          text: 'Ocurrió un error inesperado. Si el problema persiste, comunícate con el soporte de Laniakea. ',
          icon: 'error',
          confirmButtonText: 'Entendido',
          background: '#010f4e',
          });
       }
      
      })
      .catch((error) => {
        console.error('An error occurred:', error);
        Swal2.fire({
        title: 'Error',
        text: 'Ocurrió un error inesperado. Si el problema persiste, comunícate con el soporte de Laniakea. ',
        icon: 'error',
        confirmButtonText: 'Entendido',
        background: '#010f4e',
        });
      });

    }else{
      Swal2.fire({
        title: 'Error',
        text: 'Porfavor llena todos los campos, ya que son esenciales.',
        icon: 'error',
        confirmButtonText: 'Entendido',
        background: '#010f4e',
        });
    }
    }else{
      Swal2.fire({
        title: 'Error',
        text: 'Ya tienes una cuenta. No puedes crear varias cuentas.',
        icon: 'error',
        confirmButtonText: 'Entendido',
        background: '#010f4e',
        });
    }
  }
  fetchBBVA_ACCOUNT(){
    this.request.createRequestPost('fetchBBVA_account_data', {})
    .then((final_data) => {
     console.log(final_data);
     if(final_data.success){
      this.BBVA_ACCOUNT.CLABE=final_data.success[0].clabes;
      if(final_data.success[0].balance){
        this.BBVA_ACCOUNT.CLABE=final_data.success[0].balance;
      }
      this.BBVA_ACCOUNT.titular=final_data.success.complete_name;
      if(final_data.success[0].numero_de_tarjeta){
        this.BBVA_ACCOUNT.cardNum=final_data.success[0].numero_de_tarjeta;
      }
     

     }
    })
    .catch((error) => {
      console.error('An error occurred:', error);
      Swal2.fire({
      title: 'Error',
      text: 'Ocurrió un error inesperado al cargar tu cuenta. Si el problema persiste, comunícate con el soporte de Laniakea. ',
      icon: 'error',
      confirmButtonText: 'Entendido',
      background: '#010f4e',
      });
    });
  }
  send_bbva_otp(){
    if(this.BBVA_ACCOUNT.cardNum.length !== 16){
      Swal2.fire({
        title: 'Error',
        text: 'El número de tarjeta debe ser de 16 posiciones. ',
        icon: 'error',
        confirmButtonText: 'Aceptar',
        background: '#010f4e',
        });
        return;
    }
    if(this.BBVA_ACCOUNT.CLABE){
    Swal2.fire({
      title: 'Procesando...',
      text: 'Laniakea está procesando.',
      icon: 'success',
      timer: 3900, // Show the alert for 3.9 seconds
      timerProgressBar: true,
      toast: true,
      position: 'top-end',
      showConfirmButton: false
      });
      this.stopCountdown();
      this.startCountdown();
    this.request.createRequestPost('AuthSMSCodeVerification', { SendSMS:true })
    .then((final_data) => {
      let phone;
      if (final_data.phone){
        phone=final_data.phone
        this.celular=phone;
     
     
      Swal2.fire({
      title: 'OTP enviado exitosamente',
      text: `OTP ha sido enviado exitosamente a: ${phone}. `,
      icon: 'success',
      confirmButtonText: 'Aceptar',
      background: '#010f4e',
      });
      
      
     this.sentCode=true;
    }else{
      Swal2.fire({
        title: 'Error',
        text: 'Ocurrió un error inesperado. Si el problema persiste, comunícate con el soporte de Laniakea. Verifica que la extension del país sea correcta.',
        icon: 'error',
        confirmButtonText: 'Entendido',
        background: '#010f4e',
        });
    }
    })
    .catch((error) => {
      console.error('An error occurred:', error);
      Swal2.fire({
      title: 'Error',
      text: 'Ocurrió un error inesperado. Si el problema persiste, comunícate con el soporte de Laniakea. Verifica que la extension del país sea correcta.',
      icon: 'error',
      confirmButtonText: 'Entendido',
      background: '#010f4e',
      });
    });
         
   }else{
    Swal2.fire({
      title: 'Error',
      text: 'Primero tienes que crear una cuenta BBVA, luego asociar la tarjeta a la cuenta BBVA.',
      icon: 'error',
      confirmButtonText: 'Entendido',
      background: '#010f4e',
      });
   }
  }
    startCountdown() {
      this.remainingTime = 300; // 299 = 4 minutes and 59 seconds
    
      this.countdownInterval = setInterval(() => {
        this.remainingTime--;
    
        if (this.remainingTime <= 0) {
        // Stop the countdown when the remaining time reaches zero or below
        this.stopCountdown();
        // Perform any necessary actions when the countdown completes
        // For example, show a message to the user indicating the timeout
        }
      }, 1000);
      }
    
      stopCountdown() {
      clearInterval(this.countdownInterval);
      }
      formatTime(time: number): string {
      const minutes = Math.floor(time / 60).toString().padStart(2, '0');
      const seconds = (time % 60).toString().padStart(2, '0');
      return `${minutes}:${seconds}`;
      }
      VerificarOTP( ){
				Swal2.fire({
					title: 'Procesando...',
					text: 'Laniakea está procesando.',
					icon: 'success',
					timer: 3900, // Show the alert for 3.9 seconds
					timerProgressBar: true,
					toast: true,
					position: 'top-end',
					showConfirmButton: false
				  });
          if(this.BBVA_ACCOUNT.cardNum.length !== 16){
            Swal2.fire({
              title: 'Error',
              text: 'El número de tarjeta debe ser de 16 posiciones. ',
              icon: 'error',
              confirmButtonText: 'Aceptar',
              background: '#010f4e',
              });
              return;
          }
          if(this.BBVA_ACCOUNT.cardNum && this.celular && this.token){
				this.request.createRequestPost('bindPhysicalCardToBBVA_account', {  tarjeta:this.BBVA_ACCOUNT.cardNum, phone_number: this.celular, token: this.token })
				.then((final_data) => {
          console.log(final_data);
          if (final_data.success){
            this.dismiss();
            Swal2.fire({
            title: 'Token verificado exitosamente.',
            text: `El token ha sido validado exitosamente: ${this.celular}. `,
            icon: 'success',
            confirmButtonText: 'Aceptar',
            background: '#010f4e',
            });
          }
				
          
		
				})
				.catch((error) => {
          this.dismiss();
				  console.error('An error occurred:', error);
				  Swal2.fire({
					title: 'Error',
					text: 'Ocurrió un error inesperado. Si el problema persiste, comunícate con el soporte de Laniakea. ',
					icon: 'error',
					confirmButtonText: 'Entendido',
					background: '#010f4e',
				  });
				});
                    
      }else{
        this.dismiss();
        Swal2.fire({
					title: 'Error. Campos faltantes',
					text: 'Porfavor asegúrate de llenar todos los campos',
					icon: 'error',
					confirmButtonText: 'Entendido',
					background: '#010f4e',
				  });
      }
		 }
     ConfirmRequestAutomobileLoan(){
       if(this.vehicleYear && this.vehicleAmount && this.downPaymentAmount && this.installmentPlanTermsNumber && this.installmentPlanTermsFrequency){
      this.request.createRequestPost('requestAutomobileLoan', {casualtyInsurance:this.casualtyInsurance, installmentPlanTermsFrequency:this.installmentPlanTermsFrequency, installmentPlanTermsNumber:this.installmentPlanTermsNumber, downPaymentAmount:this.downPaymentAmount, vehicleAmount:this.vehicleAmount, vehicleYear:this.vehicleYear})
      .then((final_data) => {
        console.log(final_data);
        if(final_data.success){
          //this.dismiss();
          this.certificadoHipotecario=final_data.success.certificate;
        Swal2.fire({
        title: 'Solicitud enviada exitosamente.',
        text: `La solicitud ha sido enviada exitosamente, se te notificará a tu correo electrónico: ${final_data.success.email}.  `,
        icon: 'success',
        confirmButtonText: 'Aceptar',
        background: '#010f4e',
        });
        
      }else{
        Swal2.fire({
          title: 'Error',
          text: 'Ocurrió un error inesperado. Si el problema persiste, comunícate con el soporte de Laniakea. ',
          icon: 'error',
          confirmButtonText: 'Entendido',
          background: '#010f4e',
          });
       }
  
      })
      .catch((error) => {
        console.error('An error occurred:', error);
        Swal2.fire({
        title: 'Error',
        text: 'Ocurrió un error inesperado. Si el problema persiste, comunícate con el soporte de Laniakea. ',
        icon: 'error',
        confirmButtonText: 'Entendido',
        background: '#010f4e',
        });
      });
    }
     }
     DisplayAutomobileLoan(){
      this.AutomobileLoanForm=true;
      this.mortgages=false;
      this.bindCardToAccount=false;
      this.create_account=false;
    }
 
}
