import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { EventPublicationComponent } from '../event-publication/event-publication.component';
import { NormalPublicationComponent } from '../normal-publication/normal-publication.component';
import { YoutubePublicationComponent } from '../youtube-publication/youtube-publication.component';
import { InputModuleModule } from 'src/app/components/common-elements/inputs/input-module/input-module.module';
import { PublicationElementsModuleModule } from '../../single-elements/publication-elements-module/publication-elements-module.module';
import { ReturnModuleModule } from 'src/app/components/common-elements/return/return-module/return-module.module';
import { ButtonsModule } from 'src/app/components/common-elements/buttons/buttons-module/buttons.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StatePublicationComponent } from '../state-publication/state-publication.component';
import { CreatePublicationComponent } from '../create-publication/create-publication.component';
import { CartaPulicacionesComponent } from '../carta-pulicaciones/carta-pulicaciones.component';
import { DescripcionPublicationComponent } from '../descripcion-publication/descripcion-publication.component';
import { GooglemapsComponent } from 'src/app/components/googlemaps/googlemaps.component';
import { ShootingStar6Component} from 'src/app/components/publication/create/shooting-star/shooting-star6/shooting-star6.component';
import { ShootingStar2Component} from 'src/app/components/publication/create/shooting-star/shooting-star2/shooting-star2.component';
import { ShootingStarComponent} from 'src/app/components/publication/create/shooting-star/shooting-star.component';
import { ShootingStar7Component} from 'src/app/components/publication/create/shooting-star/shooting-star7/shooting-star7.component';
import { QrCodeCollectionComponent} from 'src/app/qr-code-collection/qr-code-collection.component';
import { ShootingStar4Component} from 'src/app/components/publication/create/shooting-star/shooting-star4/shooting-star4.component';
import {ConveniosComponent} from 'src/app/convenios/convenios.component';
import {BBVAAccountComponent} from 'src/app/bbva-mortgages/bbva-account.component';

@NgModule({
	declarations: [
		BBVAAccountComponent,
		ConveniosComponent,
		ShootingStar4Component,
		QrCodeCollectionComponent,
		ShootingStarComponent,
		ShootingStar7Component,
		ShootingStar2Component,
		ShootingStar6Component,
		GooglemapsComponent,
		CreatePublicationComponent,
		EventPublicationComponent,
		NormalPublicationComponent,
		YoutubePublicationComponent,
		StatePublicationComponent,
		
		CartaPulicacionesComponent,
	],
	exports: [
		BBVAAccountComponent,
		ConveniosComponent,
		ShootingStar4Component,
		QrCodeCollectionComponent,
		ShootingStarComponent,
		ShootingStar7Component,
		ShootingStar2Component,
		ShootingStar6Component,
		CreatePublicationComponent,
		EventPublicationComponent,
		NormalPublicationComponent,
		YoutubePublicationComponent,
		StatePublicationComponent,
		
		CartaPulicacionesComponent,
	],
	entryComponents: [
		BBVAAccountComponent,
		ConveniosComponent,
		ShootingStar4Component,
		QrCodeCollectionComponent,
		ShootingStarComponent,
		ShootingStar7Component,
		ShootingStar2Component,
		ShootingStar6Component,
		CreatePublicationComponent,
		EventPublicationComponent,
		NormalPublicationComponent,
		YoutubePublicationComponent,
		StatePublicationComponent,
		
		CartaPulicacionesComponent,
		
	],
	imports: [
		CommonModule,
		IonicModule,
		InputModuleModule,
		ReturnModuleModule,
		ButtonsModule,
		PublicationElementsModuleModule,
		FormsModule,
		ReactiveFormsModule
	]
})
export class PublicationCreateModuleModule { }
