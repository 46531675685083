import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RequestService } from '../request/request.service';
import {Capacitor} from "@capacitor/core";

@Injectable({
	providedIn: 'root'
})
export class FriendshipService {

	constructor(
		public httpClient: HttpClient,
		private request: RequestService
	) { }

	public getAllRelations(relation: any): Promise<any> {
		return new Promise((resolve, reject) => {
			if (Capacitor.getPlatform() === 'web'){
			relation.webPlatform = true;
			}
			this.request.createRequestGet('relations', relation)
				.then((data) => {
					resolve(data);
				}).catch((error) => {
					console.error('Failed to get all relations:', error);
					reject(error);
				});
		});
	}

	public createRelation(newRelation: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this.request.createRequestPost('relations', newRelation)
				.then((data) => {
					resolve(data);
				}).catch((error) => {
					reject(error);
				});
		});
	}

	public updateRelation(relation: any, params: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this.request.createRequestPut('relations', relation, params)
				.then((data) => {
					resolve(data);
				}).catch((error) => {
					reject(error);
				});
		});
	}

	public deleteRelation(params): Promise<any> {
		return new Promise((resolve, reject) => {
			this.request.createRequestDelete('relations', null, params)
				.then((data) => {
					resolve(data);
				}).catch((error) => {
					reject(error);
				});
		});
	}
}
