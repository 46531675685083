import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-sin-resultados',
	templateUrl: './sin-resultados.component.html',
	styleUrls: ['./sin-resultados.component.scss'],
})
export class SinResultadosComponent implements OnInit {

	constructor() { }

	ngOnInit() {

	 }

}
