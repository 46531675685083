export const onCreateMessageByConversation = /* GraphQL */ `
	subscription OnCreateMessageByConversation($conversationID: ID!) {
		onCreateMessageByConversation(conversationID: $conversationID) {
			id
			userID
			messageID
			conversationID
			multimediaID
			body
			isDelete
			isSeen
			isSystemNotification
			createdAt
			user {
				id
				username
				createdAt
				updatedAt
			}
			message {
				id
				userID
				messageID
				conversationID
				multimediaID
				body
				isDelete
				isSeen
				isSystemNotification
				createdAt
				updatedAt
				user {
					createdAt
					id
					updatedAt
					username
				}
			}
			conversation {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
				users {
					items {
						user {
							id
							username
						}
					}
				}
			}
			users {
				nextToken
			}
			updatedAt
		}
	}
`;

export const onCreateUserConversationByUser = /* GraphQL */ `
	subscription OnCreateUserConversationByUser($userID: ID!) {
		onCreateUserConversationByUser(userID: $userID) {
			id
			userID
			conversationID
			createdAt
			isDelete
			isAdmin
			user {
				id
				username
				createdAt
				updatedAt
			}
			conversation {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
				users {
					items {
						user {
							id
							username
						}
					}
				}
			}
			updatedAt
		}
	}
`;

export const onUpdateConversationByConversation = /* GraphQL */ `
	subscription OnUpdateConversationByConversation($id: ID!) {
		onUpdateConversationByConversation(id: $id) {
			id
			name
			isGroup
			isDelete
			multimediaID
			userID
			createdAt
			seens {
				nextToken
			}
			user {
				id
				username
				createdAt
				updatedAt
			}
			writes {
				nextToken
			}
			users {
				nextToken
			}
			messages {
				nextToken
			}
			updatedAt
		}
	}
`;

export const onUpdateUserConversationByConversation = /* GraphQL */ `
	subscription OnUpdateUserConversationByConversation($conversationID: ID!) {
		onUpdateUserConversationByConversation(conversationID: $conversationID) {
			id
			userID
			conversationID
			createdAt
			isDelete
			isAdmin
			user {
				id
				username
				createdAt
				updatedAt
			}
			conversation {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
			}
			updatedAt
		}
	}
`;

export const onUpdateMessageByConversation = /* GraphQL */ `
	subscription OnUpdateMessageByConversation($conversationID: ID!) {
		onUpdateMessageByConversation(conversationID: $conversationID) {
			id
			userID
			messageID
			conversationID
			multimediaID
			body
			isDelete
			isSeen
			isSystemNotification
			createdAt
			user {
				id
				username
				createdAt
				updatedAt
			}
			message {
				id
				userID
				messageID
				conversationID
				multimediaID
				body
				isDelete
				isSeen
				isSystemNotification
				createdAt
				updatedAt
				user {
					createdAt
					id
					updatedAt
					username
				}
			}
			conversation {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
			}
			users {
				nextToken
			}
			updatedAt
		}
	}
`;

export const onCreateConversationByUsersList = /* GraphQL */ `
	subscription OnCreateConversationByUsersList {
		onCreateConversationByUsersList {
			id
			name
			isGroup
			isDelete
			multimediaID
			userID
			users {
				items{
					user {
						id
						username
						createdAt
						updatedAt
					}
				}
				nextToken
			}
			messages {
				items {
					id
					multimediaID
					body
					createdAt
					user {
						id
						username
						createdAt
						updatedAt
					}
				}
			}
			user {
				id
				username
				createdAt
				updatedAt
			}
			seens {
				nextToken
			}
			writes {
				nextToken
			}
			createdAt
			updatedAt
		}
	}
`;

export const onCreateWriteByConversation = /* GraphQL */ `
	subscription OnCreateWriteByConversation($conversationID: ID!) {
		onCreateWriteByConversation(conversationID: $conversationID) {
			id
			userID
			conversationID
			status
			createdAt
			conversation {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
			}
			user {
				id
				username
				createdAt
				updatedAt
			}
			updatedAt
		}
	}
`;

export const onCreateUserMessageByConversation = /* GraphQL */ `
	subscription OnCreateUserMessageByConversation($conversationID: ID!) {
		onCreateUserMessageByConversation(conversationID: $conversationID) {
			id
			userID
			messageID
			seenID
			createdAt
			isDelete
			seen {
				id
				conversationID
				createdAt
				updatedAt
			}
			user {
				id
				username
				createdAt
				updatedAt
			}
			message {
				id
				userID
				messageID
				conversationID
				multimediaID
				body
				isDelete
				isSeen
				isSystemNotification
				createdAt
				updatedAt
			}
			updatedAt
		}
	}
`;

export const onCreateConnectionByUser = /* GraphQL */ `
	subscription OnCreateConnectionByUser($userID: ID!) {
		onCreateConnectionByUser(userID: $userID) {
			id
			userID
			status
			createdAt
			user {
				id
				username
				createdAt
				updatedAt
			}
			updatedAt
		}
	}
`;

export const onCreateSeenByConversation = /* GraphQL */ `
	subscription OnCreateSeenByConversation($conversationID: ID!) {
		onCreateSeenByConversation(conversationID: $conversationID) {
			id
			conversationID
			createdAt
			conversation {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
			}
			messages {
				items {
					isDelete
					message {
					body
					conversationID
					createdAt
					id
					isDelete
					isSeen
					isSystemNotification
					messageID
					multimediaID
					updatedAt
					userID
					users {
						items {
							user {
								createdAt
								id
								updatedAt
								username
							}
						}
					}
					}
				}
			}
			updatedAt
		}
	}
`;

export const onCreateUser = /* GraphQL */ `
	subscription OnCreateUser {
		onCreateUser {
			id
			username
			conversations {
				nextToken
			}
			messages {
				nextToken
			}
			connections {
				nextToken
			}
			createdAt
			updatedAt
		}
	}
`;

export const onUpdateUser = /* GraphQL */ `
	subscription OnUpdateUser {
		onUpdateUser {
			id
			username
			conversations {
				nextToken
			}
			messages {
				nextToken
			}
			connections {
				nextToken
			}
			createdAt
			updatedAt
		}
	}
`;

export const onDeleteUser = /* GraphQL */ `
	subscription OnDeleteUser {
		onDeleteUser {
			id
			username
			conversations {
				nextToken
			}
			messages {
				nextToken
			}
			connections {
				nextToken
			}
			createdAt
			updatedAt
		}
	}
`;

export const onCreateConversation = /* GraphQL */ `
	subscription OnCreateConversation {
		onCreateConversation {
			id
			name
			isGroup
			isDelete
			multimediaID
			userID
			createdAt
			seens {
				nextToken
			}
			user {
				id
				username
				createdAt
				updatedAt
			}
			writes {
				nextToken
			}
			users {
				nextToken
			}
			messages {
				nextToken
			}
			updatedAt
		}
	}
`;

export const onUpdateConversation = /* GraphQL */ `
	subscription OnUpdateConversation {
		onUpdateConversation {
			id
			name
			isGroup
			isDelete
			multimediaID
			userID
			createdAt
			seens {
				nextToken
			}
			user {
				id
				username
				createdAt
				updatedAt
			}
			writes {
				nextToken
			}
			users {
				nextToken
			}
			messages {
				nextToken
			}
			updatedAt
		}
	}
`;

export const onDeleteConversation = /* GraphQL */ `
	subscription OnDeleteConversation {
		onDeleteConversation {
			id
			name
			isGroup
			isDelete
			multimediaID
			userID
			createdAt
			seens {
				nextToken
			}
			user {
				id
				username
				createdAt
				updatedAt
			}
			writes {
				nextToken
			}
			users {
				nextToken
			}
			messages {
				nextToken
			}
			updatedAt
		}
	}
`;

export const onCreateMessage = /* GraphQL */ `
	subscription OnCreateMessage {
		onCreateMessage {
			id
			userID
			messageID
			conversationID
			multimediaID
			body
			isDelete
			isSeen
			isSystemNotification
			createdAt
			user {
				id
				username
				createdAt
				updatedAt
			}
			message {
				id
				userID
				messageID
				conversationID
				multimediaID
				body
				isDelete
				isSeen
				isSystemNotification
				createdAt
				updatedAt
				user {
					createdAt
					id
					updatedAt
					username
				}
			}
			conversation {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
			}
			users {
				nextToken
			}
			updatedAt
		}
	}
`;

export const onUpdateMessage = /* GraphQL */ `
	subscription OnUpdateMessage {
		onUpdateMessage {
			id
			userID
			messageID
			conversationID
			multimediaID
			body
			isDelete
			isSeen
			isSystemNotification
			createdAt
			user {
				id
				username
				createdAt
				updatedAt
			}
			message {
				id
				userID
				messageID
				conversationID
				multimediaID
				body
				isDelete
				isSeen
				isSystemNotification
				createdAt
				updatedAt
			}
			conversation {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
			}
			users {
				nextToken
			}
			updatedAt
		}
	}
`;

export const onDeleteMessage = /* GraphQL */ `
	subscription OnDeleteMessage {
		onDeleteMessage {
			id
			userID
			messageID
			conversationID
			multimediaID
			body
			isDelete
			isSeen
			isSystemNotification
			createdAt
			user {
				id
				username
				createdAt
				updatedAt
			}
			message {
				id
				userID
				messageID
				conversationID
				multimediaID
				body
				isDelete
				isSeen
				isSystemNotification
				createdAt
				updatedAt
			}
			conversation {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
			}
			users {
				nextToken
			}
			updatedAt
		}
	}
`;

export const onCreateConnection = /* GraphQL */ `
	subscription OnCreateConnection {
		onCreateConnection {
			id
			userID
			status
			createdAt
			user {
				id
				username
				createdAt
				updatedAt
			}
			updatedAt
		}
	}
`;

export const onUpdateConnection = /* GraphQL */ `
	subscription OnUpdateConnection {
		onUpdateConnection {
			id
			userID
			status
			createdAt
			user {
				id
				username
				createdAt
				updatedAt
			}
			updatedAt
		}
	}
`;

export const onDeleteConnection = /* GraphQL */ `
	subscription OnDeleteConnection {
		onDeleteConnection {
			id
			userID
			status
			createdAt
			user {
				id
				username
				createdAt
				updatedAt
			}
			updatedAt
		}
	}
`;

export const onCreateSeen = /* GraphQL */ `
	subscription OnCreateSeen {
		onCreateSeen {
			id
			conversationID
			createdAt
			conversation {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
			}
			messages {
				nextToken
			}
			updatedAt
		}
	}
`;

export const onUpdateSeen = /* GraphQL */ `
	subscription OnUpdateSeen {
		onUpdateSeen {
			id
			conversationID
			createdAt
			conversation {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
			}
			messages {
				nextToken
			}
			updatedAt
		}
	}
`;

export const onDeleteSeen = /* GraphQL */ `
	subscription OnDeleteSeen {
		onDeleteSeen {
			id
			conversationID
			createdAt
			conversation {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
			}
			messages {
				nextToken
			}
			updatedAt
		}
	}
`;

export const onCreateWrite = /* GraphQL */ `
	subscription OnCreateWrite {
		onCreateWrite {
			id
			userID
			conversationID
			status
			createdAt
			conversation {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
			}
			user {
				id
				username
				createdAt
				updatedAt
			}
			updatedAt
		}
	}
`;

export const onUpdateWrite = /* GraphQL */ `
	subscription OnUpdateWrite {
		onUpdateWrite {
			id
			userID
			conversationID
			status
			createdAt
			conversation {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
			}
			user {
				id
				username
				createdAt
				updatedAt
			}
			updatedAt
		}
	}
`;

export const onDeleteWrite = /* GraphQL */ `
	subscription OnDeleteWrite {
		onDeleteWrite {
			id
			userID
			conversationID
			status
			createdAt
			conversation {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
			}
			user {
				id
				username
				createdAt
				updatedAt
			}
			updatedAt
		}
	}
`;

export const onCreateUserConversation = /* GraphQL */ `
	subscription OnCreateUserConversation {
		onCreateUserConversation {
			id
			userID
			conversationID
			createdAt
			isDelete
			isAdmin
			user {
				id
				username
				createdAt
				updatedAt
			}
			conversation {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
			}
			updatedAt
		}
	}
`;

export const onUpdateUserConversation = /* GraphQL */ `
	subscription OnUpdateUserConversation {
		onUpdateUserConversation {
			id
			userID
			conversationID
			createdAt
			isDelete
			isAdmin
			user {
				id
				username
				createdAt
				updatedAt
			}
			conversation {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
			}
			updatedAt
		}
	}
`;

export const onDeleteUserConversation = /* GraphQL */ `
	subscription OnDeleteUserConversation {
		onDeleteUserConversation {
			id
			userID
			conversationID
			createdAt
			isDelete
			isAdmin
			user {
				id
				username
				createdAt
				updatedAt
			}
			conversation {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
			}
			updatedAt
		}
	}
`;

export const onCreateUserMessage = /* GraphQL */ `
	subscription OnCreateUserMessage {
		onCreateUserMessage {
			id
			userID
			messageID
			seenID
			createdAt
			isDelete
			seen {
				id
				conversationID
				createdAt
				updatedAt
			}
			user {
				id
				username
				createdAt
				updatedAt
			}
			message {
				id
				userID
				messageID
				conversationID
				multimediaID
				body
				isDelete
				isSeen
				isSystemNotification
				createdAt
				updatedAt
			}
			updatedAt
		}
	}
`;

export const onUpdateUserMessage = /* GraphQL */ `
	subscription OnUpdateUserMessage {
		onUpdateUserMessage {
			id
			userID
			messageID
			seenID
			createdAt
			isDelete
			seen {
				id
				conversationID
				createdAt
				updatedAt
			}
			user {
				id
				username
				createdAt
				updatedAt
			}
			message {
				id
				userID
				messageID
				conversationID
				multimediaID
				body
				isDelete
				isSeen
				isSystemNotification
				createdAt
				updatedAt
			}
			updatedAt
		}
	}
`;

export const onDeleteUserMessage = /* GraphQL */ `
	subscription OnDeleteUserMessage {
		onDeleteUserMessage {
			id
			userID
			messageID
			seenID
			createdAt
			isDelete
			seen {
				id
				conversationID
				createdAt
				updatedAt
			}
			user {
				id
				username
				createdAt
				updatedAt
			}
			message {
				id
				userID
				messageID
				conversationID
				multimediaID
				body
				isDelete
				isSeen
				isSystemNotification
				createdAt
				updatedAt
			}
			updatedAt
		}
	}
`;
