import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { StoryViewerComponent } from '../story-viewer/story-viewer.component';
import { PublicationClass } from 'src/app/Models/Classes/Publications/publication-class';
import { StatePublication } from 'src/app/Models/Classes/Publications/Story/StatePublication/state-publication';
import {HistoryViewComponent} from "../../history-view/history-view.component";
import {
  NewPublicationButtonComponent
} from "../../common-elements/buttons/new-publication-button/new-publication-button.component";
import {PublicationService} from "../../../services/publications/publication.service";
import {StoryPublication} from "../../../Models/Classes/Publications/Story/story-publication";

@Component({
	selector: 'app-stories',
	templateUrl: './stories.component.html',
	styleUrls: ['./stories.component.scss'],
})
export class StoriesComponent implements OnInit, OnChanges {
	@Input() stories: Array<PublicationClass> = [];
	@Input() profile: any;
  result = [];

	constructor(
		private modalCtrl: ModalController,
    private newpub: NewPublicationButtonComponent,
    private publicationService: PublicationService
	) {}

	ngOnInit() {
	}

  public msToDatetime(ms){
    ms = ms*1000;
    const d = new Date(ms);
    const ds = d.toLocaleString();
    console.log('datetime',ds);
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('My stories', this.stories);
  }

	openStoryViewer(index: number) {
		this.modalCtrl.create({
			component: StoryViewerComponent,
      cssClass: 'detailsInModal',
			swipeToClose: true,
			componentProps: {
				stories: this.stories,
				tapped: index
			}
		}).then((modal)=>{
			modal.present();
		}).catch((error)=>{
			console.error(error);
		});
	}

  searchMyStorie(stories){
    let index = null;
    stories.forEach((element, i) => {
      console.log('story', element);
      console.log('profile', this.profile);
      if (element.userProfile.user_id === this.profile.id) {
        console.log('match!!');
        index = i;
      }
    });
    if (index !== null){
      this.openStoryViewer(index);
    }else {
      this.newStory();
    }
  }

  public newStory() {
    console.log('new history', this.stories);
      this.newpub.presentModalHistory();
  }
}
