import { MediaItem } from "../../Media/media-item";
import { ReactionContainerClass } from "../../Reactions/ReactionContainer/reaction-container-class";
import { SellerProfile } from "../../User/Store/seller-profile";
import { PublicationClass } from "../publication-class";
import { State } from "./State/state";

export class StoryPublication extends PublicationClass {
    states: Array<State> = new Array<State>();
    constructor(publication?: any, userProfile?: SellerProfile, reactionContainer?: Array<ReactionContainerClass>, ownReaction?: any){
        super(publication, userProfile, reactionContainer, ownReaction);
        if(publication && publication.container.states){
            publication.container.states.forEach(state => {
                this.transformMediaToMediaItem(state.multimediaContainer).then((multimedia) => {
                    multimedia.forEach(media => {
                        this.states.push(new State(state, media));
                    });
                });

            });
        }else{
            this.states = new Array<State>();
        }
    }

    public updatePublication(publication: any, userProfile: SellerProfile, reactionContainer: Array<ReactionContainerClass>, ownReaction: any) {
        //Publication
        this.id = (publication.id) ? parseInt(publication.id) : this.id;
        this.publication_type = (publication.publication_type_string) ? publication.publication_type_string : this.publication_type;
        this.status_string = (publication.status_string) ? publication.status_string : this.status_string;
        //Interaction
        this.reactionCounter = (publication.reactionCounter) ? parseInt(publication.reactionCounter) : this.reactionCounter;
        this.reactionContainer = reactionContainer;
        this.ownReaction = ownReaction;
        this.commentCounter = (publication.commentCounter) ? parseInt(publication.commentCounter) : this.commentCounter;
        //User
        this.userProfile = userProfile;
        this.corp = (publication.corp) ? publication.corp : this.corp;
        this.is_saved = (publication.is_saved) ? publication.is_saved : this.is_saved;
        this.owner = (publication.owner) ? publication.owner : this.owner;
    }


    /*
    updatestate(state: State){
        //Multimedia
        this.doesItHaveMultimedia = (state.multimediaCounter == 0) ? false : true;
        this.multimediaCounter = (state.multimediaCounter) ? state.multimediaCounter : this.multimediaCounter;
        this.transformMediaToMediaItem(state.multimediaContainer).then((multimedia)=>{
            this.multimediaContainer = multimedia;
        });
    }
    */



    transformMediaToMediaItem(objectMedia){
        return new Promise<Array<MediaItem>>((resolve) => {
            let MediaContainer = new Array<MediaItem>();
            if(objectMedia){
                objectMedia.forEach(media => {
                    MediaContainer.push( new MediaItem(media) );
                });
                resolve(MediaContainer);
            }
            else{
                resolve(MediaContainer);
            }

        });
    }

}
