import { Component, OnInit } from '@angular/core';
import { ActionSheetController, AlertController, ModalController } from '@ionic/angular';
import { ModalDateTimeComponent } from '../modal-date-time/modal-date-time.component';

@Component({
	selector: 'app-date-time',
	templateUrl: './date-time.component.html',
	styleUrls: ['./date-time.component.scss'],
})
export class DateTimeComponent implements OnInit {

	public startDate: Date;

	constructor(
		public actionSheetController: ActionSheetController,
		public alertController: AlertController,
		public modalController: ModalController
	) {

	}

	ngOnInit() { }

	presentModal() {
		this.modalController.create({
			component: ModalDateTimeComponent,
			cssClass: 'my-custom-modal-css'
		}).then((modal) => {
			modal.present();
		});
	}
}
