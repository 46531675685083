import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController } from '@ionic/angular';
import { ActualizacionServiceService } from 'src/app/services/actualizacion/Actualizacion-service.service';

@Component({
  selector: 'app-publication-save-template',
  templateUrl: './publication-save-template.component.html',
  styleUrls: ['./publication-save-template.component.scss']
})
export class PublicationSaveTemplateComponent implements OnInit {
  @ViewChild('slides') slides: IonSlides;
  publication=[]
  @Input() publications
  @Input() index
  slideOpts = {
    direction: 'vertical',
    initialSlide: 0,
    speed: 400,
  };

  constructor(private modalctrl:ModalController,private actualService:ActualizacionServiceService) { }

  ngOnInit() {
    // this.getpublicationsave()
    console.log("publicaciones provenientes de page",this.publications);

  }
  ngAfterViewInit() {
    let me = this;
    me.slides.slideTo(this.index);
  }

  retorno(){
    this.modalctrl.dismiss()
  }

  getpublicationsave(){
    this.actualService.getPublicationsSave(null, false, null, false, null).then((result:[])=>{



      result['data'].forEach(element => {


         console.log("elementos",element);
         this.publications.push(element)


       });
       console.log("publicaciones Guardadas complete",this.publication);

     })

  }

}
