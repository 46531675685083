import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ModalTransactionComponent } from '../modal-transaction/modal-transaction.component';

@Component({
	selector: 'app-pay-for-publication',
	templateUrl: './pay-for-publication.component.html',
	styleUrls: ['./pay-for-publication.component.scss'],
})
export class PayForPublicationComponent implements OnInit {

	@Input() publication: any;
	@Output() success = new EventEmitter();

	//private pay = new EventEmitter();
	private modal: any;

	constructor(
		private popOvercontroller: PopoverController,
	) { }

	ngOnInit() { }

	comprar_publicacion(ev?) {
		this.popOvercontroller.create({
			component: ModalTransactionComponent,
			cssClass: 'centerPopOver',
			event: ev,
			translucent: true,
			componentProps: {
				price: this.publication.price_cost,
				publication: this.publication,
				pay: this.success
			}
		}).then((modal)=>{
			modal.present();
		});
	}

}
