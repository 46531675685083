import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DescripcionPublicationComponent } from '../publication/create/descripcion-publication/descripcion-publication.component';
import { FormServiceService } from "src/app/services/publications/form-service.service";
import { Clipboard } from '@capacitor/clipboard';
import { WalletServiceService } from 'src/app/services/wallet/wallet-service.service';
import { SliderInformationComponent } from 'src/app/components/slider-information/slider-information.component';
import { PagoVerificacionComponent } from 'src/app/components/pago-verificacion/pago-verificacion.component';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import Swal from 'sweetalert2';
import { InAppPurchase2,IAPProduct } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { RequestService } from 'src/app/services/request/request.service';
import { Capacitor } from '@capacitor/core';
//import { AppComponent } from 'src/app/app.component';
@Component({
  selector: 'app-pagar-c',
  templateUrl: './pagar-c.component.html',
  styleUrls: ['./pagar-c.component.scss'],
})
export class PagarCComponent implements OnInit{
  public msg:string="Leyendo.."
  productId2: string = '1618';
  premium = false;
  selectVal:number=104
  divisaSelect:string=""
	pr: any = '';
  public isToggleChecked: boolean = false;
  public HubbleExpired: boolean = false;

  /*
  USD
AED
AFN*
ALL
AMD
  */
  listaDivisas=[

    // {
    //   id:101,
    //   name:"Btc",
    //   valor: 0.003,
    //   conversion: 2400.45
    // },
    // {
    //   id:102,
    //   name:"USDT",
    //   valor: 220,
    //   conversion:4700.45
    // },
    // {
    //   id:103,
    //   name:"ETH",
    //   valor: 6011.19,
    //   conversion:2400.45
    // },
    {
      id:104,
      name:"MXN",
      valor: 1000,
      conversion:100
    },
    //{
      //id:105,
      //name:"USD",
      //valor: 10,
      //conversion:210.45
    //},



    ];

  constructor(
    public formservice:FormServiceService,
    private modalcontroller: ModalController,
    private walletService: WalletServiceService,
    private profile:ProfileService,
    private faio:FingerprintAIO,
    private store: InAppPurchase2,
    private request: RequestService,
   // public app :  AppComponent,
  ) { }


  ngOnInit() {
    console.log(this.formservice.normalpublication);
    this.profile.getProfileInfo().then((result)=>{
      this.pr = JSON.parse(result);
      console.log("profile", this.pr);
      });
  }

  OnChange(event) {
    console.log(`Divisa id Select ${this.selectVal}`);

    this.listaDivisas.forEach(element => {
      if(this.selectVal === element.id){
        this.divisaSelect=element.name

      }



    });

    console.log(`Divisa id = ${this.selectVal} con Divisa ${this.divisaSelect}`);
    this.formservice.divisa=this.divisaSelect
    console.log("divisa formservice",this.formservice.divisa);




  }

  addPrice2(event) {
    this.formservice.price=event.target.value;

  }
  
  addPrice(event) {
    const inputPrice = parseInt(event.target.value);
    const limitedPrice = Math.min(inputPrice, 999);
    this.formservice.price = limitedPrice > 999 ? 999 : limitedPrice;
  }
  
  

  validar:boolean=false;

  cambio($event){
//this.openKYCApplicationModal();
this.onClick();
  }
  private openKYCApplicationModal() {
		console.log('openKYCApplicationModal called');
		this.walletService.KYC_validation().then((result_kyc) => {
			console.log('KYC_validation result:', result_kyc);
		  if (result_kyc['permit']) {
			this.showWalletAppModal();
		  } else if (this.pr.kyc === "Sin Validar") {
        this.isToggleChecked = false;
			this.showSliderInformationModal();
		  } else {
        this.isToggleChecked = false;
			this.showPagoVerificacionModal();

		  }
		}).catch((error) => {
			console.error('KYC_validation error:', error);
		  console.error(error);
		});
	  }
	  
	  private showWalletAppModal() {
      if (this.walletService.SubscriptionExpired !== true){
     // if (!this.HubbleExpired){
       // this.HubbleExpired = true;
	// Set the authorization response status
  // Set the authorization response status
  this.validar=!this.validar;
  this.formservice.normalpublication.current_multimedia_status = this.validar;
  console.log(this.validar);
		console.log('showWalletAppModal called');

	// Set HubbleExpired to false after 6 seconds
  //setTimeout(() => {
    //this.HubbleExpired = false;
    //console.log('HubbleExpired set to false');
  //}, 6000);

     // }
    } else if (this.walletService.SubscriptionExpired === true){
      this.isToggleChecked = false;
      this.RenewSuscription();
    }
	
  
	  }
	  
	  private showSliderInformationModal() {
		console.log('showSliderInformationModal called');
		this.modalcontroller.create({
		  component: SliderInformationComponent,
      cssClass: 'detailsInModal',
      swipeToClose: true,
		  componentProps: {
			pr: this.pr,
		  },
		}).then((modal) => {
		  modal.present();
		}).catch((error) => {
		  console.error(error);
		});
	  }
	  
	  private showPagoVerificacionModal() {
		console.log('showPagoVerificacionModal called');
		console.log("entro al else if ");
	  
		this.modalcontroller.create({
		  component: PagoVerificacionComponent,
      cssClass: 'detailsInModal',
      swipeToClose: true,
		  componentProps: {
			pr: this.pr
		  }
		}).then((modal) => {
		  modal.present();
		});
	  }
    async RenewSuscription(): Promise<void> {
      Swal.fire({
        title: 'Sucripción de usuario...',
        text: 'Tu suscripción de usuario verificado ha expirado. Espera...',
        icon: 'success',
        timer: 4500, // Show the alert for 4.5 seconds
        timerProgressBar: true,
        toast: true,
        position: 'top-end',
        showConfirmButton: false
        });
      return new Promise<void>(async (resolve, reject) => {
        this.store.verbosity = this.store.DEBUG;
      
        // Register the product with the given ID
        this.store.register({
        id: this.productId2,
        alias: this.productId2,
        type: this.store.PAID_SUBSCRIPTION, // Change the type to PAID_SUBSCRIPTION
        });
      
        // Fetch the product information
        const product = await this.store.get(this.productId2);
      
        // Updated
        this.store.when(this.productId2).updated((product: IAPProduct) => {
          console.log('Updated' + JSON.stringify(product));
          });
      
        // User closed the native purchase dialog
        this.store.when(this.productId2).cancelled((product) => {
        console.error('Purchase was Cancelled');
        });
      
        // Run some code only when the store is ready to be used
        this.store.ready(() => {
        console.log('Store is ready');
        console.log('Products: ' + JSON.stringify(this.store.products));
        console.log(JSON.stringify(this.store.get(this.productId2)));
        });
      
        if (product) {
        // Check if the product is already owned
        if (product.state === this.store.OWNED) {
          console.log('Product already owned');
          resolve();
          return;
        }
      
        // Check if the product is invalid
        if (product.state === this.store.INVALID) {
          reject(new Error('Product is invalid'));
          return;
        }

        Swal.fire({
          title: 'Sucripción de usuario...',
          text: 'Tu suscripción de usuario verificado ha expirado. Espera...',
          icon: 'success',
          timer: 4500, // Show the alert for 4.5 seconds
          timerProgressBar: true,
          toast: true,
          position: 'top-end',
          showConfirmButton: false
          });
      
        // To make a purchase
        this.store.order(this.productId2);
      
        // Proceed with the purchase flow
        this.store.when(this.productId2).approved((product) => {
          product.finish();
      
          console.log('Subscription ordered!');
      
          this.store.when(this.productId2).owned(async (product) => {
            this.showWalletAppModal();
          console.log('Product is owned');
          this.sendNotification('Estatus de tu perfil actualizado', '¡Felicitaciones! Ya tienes acceso a la cartera Hubble.');
          
          });
        });
      
        // Handle error events
        this.store.when(this.productId2).error((err) => {
          console.log('Error occurred during purchase:', err);
          reject(err);
        });
      
        // Refresh the product information
        this.store.refresh();
        } else {
        reject(new Error('Failed to fetch product information'));
        }
      
      });
      }
      
      sendNotification(title, body) {
       
      return new Promise((resolve, reject) => {
        const notification = {
        title: title,
        body: body,
        user_id:this.pr.id,
    
        };
      
        this.request.createRequestPost('GreatAtractorNotifications', notification)
        .then((data) => {
         
          resolve(data);
        }).catch((error) => {
          console.error('Failed to send notification:', error);
          reject(error);
        });
      });
      }
      onClick() {
        if (Capacitor.getPlatform() !== 'web') {
        this.faio.show({
          title: "Leyendo huella Digital",
          subtitle: "Use su huella para permitir el acceso a retiros",
        })
          .then((res) => {

                
                this.openKYCApplicationModal();
       
            console.log(res);
            this.msg = 'Se valido el acceso con tu huella';
        
          })
          .catch((err) => {
            console.log(err);
            this.msg = 'Acceso incorrecto';
            alert(err);
          });
        }else{
          this.openKYCApplicationModal();
        }
      }
        
}
