import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CompraFinalizadaComponent } from '../../finalizar-compra/compra-finalizada/compra-finalizada.component';

@Component({
  selector: 'app-carrito',
  templateUrl: './carrito.component.html',
  styleUrls: ['./carrito.component.scss'],
})
export class CarritoComponent implements OnInit {

  constructor(public modalcontroller: ModalController) { }

  ngOnInit() {}

  finalizar() {
    this.modalcontroller
      .create({
        component: CompraFinalizadaComponent
      })
      .then((modal) => modal.present()) 
  }
  dismiss() {
    this.modalcontroller.dismiss({
      'dismissed': true
    });
  }
}
