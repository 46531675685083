import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-component-button-color-select',
  templateUrl: './component-button-color-select.component.html',
  styleUrls: ['./component-button-color-select.component.scss'],
})

export class ComponentButtonColorSelectComponent implements OnInit {

  @Input() text: string;
  constructor() { }

  ngOnInit() {}

}
