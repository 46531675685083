import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { MediaItem } from 'src/app/Models/Classes/Media/media-item';
import { PublicationClass } from 'src/app/Models/Classes/Publications/publication-class';
import { Payment } from 'src/app/Models/Classes/Wallet/payment';
import { PublicationService } from 'src/app/services/publications/publication.service';
import { WalletServiceService } from 'src/app/services/wallet/wallet-service.service';
import { PagoCompletadoComponent } from '../../pago-completo/pago-completado/pago-completado.component';
import { MetodoDePagoComponent } from '../metodo-de-pago/metodo-de-pago.component';

@Component({
  selector: 'app-compra-finalizada',
  templateUrl: './compra-finalizada.component.html',
  styleUrls: ['./compra-finalizada.component.scss'],
})
export class CompraFinalizadaComponent implements OnInit {
@Input() publication;
@Input() multimedia: Array<MediaItem>;
@Input() profilePhoto: string;
@Input() userName: string;
@Input() createdAt: number;
@Input() owner: boolean = false;
@Input() user_id: number;
@Input() discardInteractions: boolean;
@Input() corp: boolean = false;
@Input() index: number = 0;
isSending: boolean = false;
step: number = 0;
hideBalance: boolean = true;
selectedWallet = null;
paymentResponse = null;
PorcentajedelServicio:number ;
tax:number
taxes:number
transactionToAdd
tran
public transactions: Array<Payment > = new Array<Payment>(); // Unir estas transacciones a una wallet en especifico 
public transaction_detailed: Payment;
public transactions_mode: string = 'transactions';
  constructor( public modalcontroller:ModalController,
    private publicationService: PublicationService ,
    public WalletService:WalletServiceService,
    public alertController: AlertController
   ) { }
   
  ngOnInit() {
    this.PorcentajedelServicio=this.publication.price * 0.10;
    var limitdecimal = this.PorcentajedelServicio.toFixed(2);
    this.tax = parseFloat(limitdecimal)
    this.taxes = this.publication.price + this.tax
    
    this.WalletService.getTransactions().then((transactions_server: any)=> {
			transactions_server.forEach(transaction => {
			this.transactionToAdd = new Payment(transaction);
        this.transactions.push(this.transactionToAdd);
        this.transaction_detailed = transaction;
        
			});
		}).catch((error)=> {
			console.error(error);
		});
console.log(this.transaction_detailed.status)
  
  }

	openDetailsTransaction(transaction: Payment){
		
	}

  dismiss() {
    this.modalcontroller.dismiss({
      'dismissed': true
    });
  }
  MetodoDepago(){
    this.modalcontroller
    .create({
      component: MetodoDePagoComponent
    })
    .then((modal) => modal.present()) 
  }
  newTransfer(): Promise<any> {
		return new Promise((resolve, reject) => {
				let deposit = {
				wallet: this.publication.walletID,
				qty: this.publication.price,
				type: 'PAYMENT',
				method: 'TRANSFER',
				currency: 'MXN'
			}
			this.WalletService.newPayment(deposit).then((data: any) => {
				this.paymentResponse = JSON.parse(data);
				this.WalletService.MyWalletsList.find((wallet) => (wallet.walletID == this.paymentResponse.wallet.walletID)).balance = this.paymentResponse.wallet.balance
				resolve(true);
			}).catch((error) => {
				reject(error);
			});
		});
	}
  async finalizarCompra(transaction: Payment) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Comprando',
      message: 'Si desea Reteactarse selecione que no en el mensaje',
      buttons: [
        {
          text: 'No,me retracto',
          handler: () => {

          }
        },
        {
          text: 'Continuar, Si deseo adquirir este servicio',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log(transaction);
		this.transaction_detailed = transaction;
		this.transactions_mode = 'details';
          if( this.newTransfer() ){
            this.modalcontroller
            
            .create({
              component: PagoCompletadoComponent,
              componentProps:{
                status: this.transaction_detailed.status
              }
              
            })
            .then((modal) => modal.present()) 
          } 
            console.log('Confirm Cancel: blah');
          }
        }
      ]
    });

    await alert.present();
  }
}
