import { Component, OnInit, ViewChild, QueryList, ViewChildren, ChangeDetectorRef, ElementRef } from '@angular/core';
import { NavParams, IonSlides, ModalController, Platform, IonProgressBar } from '@ionic/angular';
import { State } from 'src/app/Models/Classes/Publications/Story/State/state';
import { StoryPublication } from 'src/app/Models/Classes/Publications/Story/story-publication';
import { Utils } from 'src/app/Models/Classes/utils';
import { Profile } from './../../../Models/Classes/User/profile';
import { Capacitor } from '@capacitor/core';


@Component({
	selector: 'app-story-viewer',
	templateUrl: './story-viewer.component.html',
	styleUrls: ['./story-viewer.component.scss'],
})
export class StoryViewerComponent implements OnInit {
	interval;
	public typeTextCalled:boolean=false;
	public titleTyping:any;
	@ViewChild('slides', { static: true }) slides: IonSlides;
	slideOpts: any = {
		grabCursor: true,
		cubeEffect: {
			shadow: true,
			slideShadows: true,
			shadowOffset: 20,
			shadowScale: 0.94,
		}
	};
	@ViewChildren('progress') progress: QueryList<ElementRef>;
	private clickHandler: any;
	private timeoutHandler: any;
	private timeClick: number = 0;
	private videoCurrentTime: number = 0;

	private video: HTMLMediaElement;


	public isShowing: boolean = false;
	private isPaused: boolean;

	public stories: Array<StoryPublication>;
	public timeSincePublish: string = "Justo ahora";

	public currentStoryIndex: number = 0;
	public currentStateIndex: number = 0;
	authorList$: any;

	constructor(
		public navParams: NavParams,
		private modalCtrl: ModalController,
		public platform: Platform,
		private cdRef: ChangeDetectorRef,
		private utils: Utils
	) {
		this.isPaused = true; // Empieza con el progressbar en pausa
		this.stories = this.navParams.get('stories'); // obtiene las historias
		console.log("states,Received Stories: ", this.stories);
		this.slideOpts.initialSlide = this.navParams.get('tapped'); //obtiene el indice de historia abierta
		this.currentStoryIndex = this.slideOpts.initialSlide; // inicializa la historia actual
	}

	ngOnInit() {
		this.slides.update().then(() => {
			this.stateFinish().then(() => {
				this.isShowing = true;
				this.stateStart().then((data) => {
				}).catch((error) => {
					console.error(error);
				});
			}).catch((error) => {
				console.error(error);
			});
		}).catch((error) => {
			console.error(error);
		});
	}
  public Replace(url: string) {
    return url.split('?')[0];
  }
  typeText(text: string, delay: number, currentStateIndex) {
	 // Check if typeText has already been called for this currentStateIndex
	 if (this.typeTextCalled) {
		return;
	  }
	
	  this.typeTextCalled = true; // Set the flag to indicate 
	let index = 0;
	clearInterval(this.interval);
	 this.interval = setInterval(() => {
	  if (index < text.length) {
		this.titleTyping = text.slice(0, index + 1);
		index++;
	  } else {
		clearInterval(this.interval);
	  }
	}, delay);
  }
  
  
  
	public startInterval(event: any) { //Inicia el contador de boton presionado
		this.clickHandler = setInterval(() => {
			this.timeClick++;
			if (this.timeClick > 30) {
				//pausa el estado
				this.statePause();
			}
		}, 10);
	}

	public stopInterval(event: any) { // finaliza el contador del boton presionado
		clearInterval(this.clickHandler);
		if (this.timeClick < 30) { // si el boton duro menos de 30 milisegundos presionado cambia de estado
			this.timeClick = 0;
			this.selectChangeItem(event);
		} else { // si duró mas simplemente reinicia el boton y reanuda el progress bar
			this.timeClick = 0;
			this.stateReanude();
			// reanuda el progress bar
		}
	}

	public selectChangeItem(event: any) { // Obtiene una posicion en el cursor y selecciona a cual item cambiarse

		if (event.offsetX < event.path[0].clientWidth / 2) {
			this.changeItem(-1);
		} else {
			this.changeItem(+1);

		}
	}
	

	public changeItem(direction: number) { // Cambia de estado
		this.isShowing = false;
		let tempStateIndex = this.currentStateIndex + direction;
		if (tempStateIndex >= 0 && tempStateIndex <= this.getActualStory().states.length - 1) { // Verifica que puedas cambiar de estado
			this.updateItem(direction).then(() => {
				this.stateStart().then(() => {

				}).catch((error) => {
					console.error(error);
				});
			}).catch((error) => {
				console.error(error);
			});
		} else { // Hay que realizar un cambio de historia
			let tempStoryIndex = this.currentStoryIndex + direction;
			if (tempStoryIndex < 0 || tempStoryIndex > this.stories.length - 1) {
				this.closeModal();
			} else {
				this.updateSlide(direction).then(() => {
					this.stateStart().then(() => {
					}).catch((error) => {
						console.error(error);
					});
				}).catch((error) => {
					console.error(error);
				});
			}
		}
	}

	public updateItem(direction: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			this.stateFinish().then(() => {
				this.currentStateIndex += direction;
				resolve(true);
			}).catch((error) => {
				reject(error);
			});
		});
	}

	public updateSlide(direction: number): Promise<any> {
		return new Promise((resolve, reject) => {
			this.stateFinish().then(() => {
				this.currentStoryIndex += direction;
				this.currentStateIndex = 0;
				if (direction > 0) {
					this.slides.slideNext().then((slide) => { //Cambia de historia
						resolve(slide);
					}).catch((error) => {
						reject(error);
					});
				} else {
					this.currentStateIndex = 0;
					this.slides.slidePrev().then((slide) => { // Cambia de historia
						resolve(slide);
					}).catch((error) => {
						reject(error);
					});
				}
			}).catch((error) => {
				reject(error);
			});
		});
	}

	private stateStart(): Promise<any> {
		return new Promise((resolve, reject) => {
			this.utils.calcularTiempo(this.getActualStory().createdAt).then(time => {
				this.timeSincePublish = time;
				this.isShowing = true;
				if (this.getActualState().media.typeData == 'Video') {
					this.findVideo(this.getActualState().media.id.toString()).then((video) => {
						this.playVideo().then(() => {
							this.stateInit(video.duration, 'video');
							resolve(true);
						}).catch((error) => {
							reject(error);
						});
					}).catch((error) => {
						reject(error);
					});
				} else {
					this.stateInit(0.001, 'imagen');
				}
			}).catch((error) => {
				reject(error);
			});
		});
	}

	public stateInit(duration: number, type: string) {
		this.isPaused = false;
		this.timeoutHandler = setInterval(() => {
			if (this.isPaused != true) {
				if (type == "video") {
					if (this.video.ended) {
						this.changeItem(1);
					} else {
						this.getActualProgress().value = (this.video.currentTime/duration);
					}
				} else {
					if (this.getActualProgress().value >= 1) {
						this.changeItem(1);
					}
					this.getActualProgress().value += duration;
				}
			}
		}, 10);
	}

	private statePause() {
		if (this.getActualState().media.typeData == 'Video') {
			if (this.video != undefined) {
				this.pauseVideo().then(() => {
					this.isPaused = true;
				});
			}
		} else {
			this.isPaused = true;
		}
	}

	private stateReanude() {
		if (this.getActualState().media.typeData == 'Video') {
			if (this.video != undefined) {
				this.playVideo().then(() => {
					this.isPaused = false;
				});
			}
		} else {
			this.isPaused = false;
		}
	}

	public stateFinish(): Promise<any> { // Detiene  la barra de progreso
		return new Promise((resolve, reject) => {
			clearInterval(this.timeoutHandler);
			this.getActualProgress().value = 0;
			this.typeTextCalled=false;

			if (this.getActualState().media.typeData == 'Video') {

				if (this.video != undefined) {
					this.pauseVideo().then(() => {

						this.isPaused = true;
						resolve(true);
					}).catch((error) => {
						reject(error);
					});
				} else {
					resolve(true);
				}
			} else {

				this.isPaused = true;
				resolve(true);
			}
		});
	}

	private findVideo(id: string): Promise<any> {
		return new Promise((resolve, reject) => {
			this.cdRef.detectChanges();
			this.video = <HTMLMediaElement>document.getElementById(id);
			if (this.video != undefined) {
				resolve(this.video);
			} else {
				reject(undefined);
			}
		});
	}

	public playVideo(): Promise<any> {
		return new Promise((resolve, reject) => {
			this.video.play().then((video) => {
				resolve(video);
			}).catch((error) => {
				reject(error);
			});
		});
	}

	public pauseVideo(): Promise<any> {
		return new Promise((resolve, reject) => {
			try {

				this.video.pause();

				resolve(true);
			} catch (e) {
				reject(e);
			}
		});
	}

	public closeModal() {
		clearInterval(this.clickHandler);
		clearInterval(this.timeoutHandler);
		this.modalCtrl.dismiss();
	}

	private getActualState(): State {
		return this.stories[this.currentStoryIndex].states[this.currentStateIndex];
	}

	private getActualStory(): StoryPublication {
		return this.stories[this.currentStoryIndex];
	}

	private getActualProgress(): IonProgressBar {
		
		return this.progress.toArray()[this.currentStoryIndex].nativeElement.children[this.currentStateIndex].children[0];
		
	}

	public slideChanged() {
		this.slides.getActiveIndex().then((index) => {
			this.stateFinish().then(() => {
				this.currentStoryIndex = index;
				this.currentStateIndex = 0;
				this.stateStart().then(() => {
				}).catch((error) => {
					console.error(error);
				});
			});
		}).catch((error) => {
			console.error(error);
		});
	}
	getAbsoluteImageUrl(relativeUrl: string): string {
		const baseUrl = 'https://laniakea-multimedia.s3.amazonaws.com/';
		return baseUrl + relativeUrl;
	  }	 
}
