import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';

import { registerPlugin } from '@capacitor/core';
import { SliderComponent } from '../../publication/single-elements/slider/slider.component';
import { SliderInformationComponent } from '../../slider-information/slider-information.component';

//interface GetIDSDKInput {
  //apiUrl: string;
  //token: string;
  //flowName: string;
//}

//interface GetIDPlugin {
  //startVerificationFlow(options: { input: GetIDSDKInput }): Promise<void>;
//}

//const getID = registerPlugin<GetIDPlugin>('GetID');

@Component({
  selector: 'app-verificacion-de-cuenta',
  templateUrl: './verificacion-de-cuenta.component.html',
  styleUrls: ['./verificacion-de-cuenta.component.scss'],
  providers: [AuthService],
})
export class VerificacionDeCuentaComponent implements OnInit {
  verificacionGetid: string =
    'https://laniakea.sb.getid.dev/vp/getid-doc-only?token=eyJhbGciOiJSU0EtT0FFUC0yNTYiLCJlbmMiOiJBMjU2Q0JDLUhTNTEyIn0.nEmY8BOTi3a__mrXOoAzdLswjz9fq-nbP7xT-rlgFalJOSAdsAYrouIDul6MRs4kKwbmay4VdNTJbt6dO_lMpRtjOb_MY4WdjroWo6HNGjYjYXBncrWHnW_0TZJbpFnz_PASEUUxhUcSTQJ_3C5yG2cUldfTqMVaG_6DpUiwdaEFtYeF5uivcWnKMdnrWwvf9NpEvFmmEd6myfCOB2SuEZnN-5I7ebGtS94wxc0fM0LG1KrxP8nZBRA2cpkPdtTzPa804Mws7GldAIDyKisyHRz2E1l_0EDL6wQGn9hQ-Y76vLDnLhVJHZZ4mVDj0J5b9MZl2LNeytRkpLWMuUGjWg.9OP-yR9xy-PHlHiGas2yDQ.S7DtaNXdIQu8r-2vXIsRurfVQ9X56Ma-yKlnz8xwDoWxBuGZp6LdXt4C8U8L77JYxbX-Kd240x28VX3PoT9abgf4CTi8gQn8_a_5Mz5oVA4.nPcxUK0U8L57fQ25e077NKg-V-4bM6WvxCm96xZ2uXw';
  public step: number = 1;
  verbuton: string = 'si';
  valuestado: number;
  public user$: Observable<any>;

  /**
   * Note: Don't use your SDK key in the client-side code in the production environment.
   */
  apiUrl = 'https://laniakea.sb.getid.dev';
  sdkKey = 'e379a4031a6b72f54299d65669850ae221ec4f1fe861ebd199fd07b26d4253ba';
  flowName = 'testjp';

  constructor(
    private modalcontroller: ModalController,
    private AuthService: AuthService
  ) {}

  ngOnInit() {}
  dismiss() {
    this.modalcontroller.dismiss({
      dismissed: true,
    });
  }
  next() {
    if (this.step < 6) {
      this.step++;
    }
    if (this.step === 2) {
      this.verbuton = 'no';
    }
    if (this.step === 3) {
      this.verbuton = 'si';
    }
    console.log(this.step);
    console.log(this.verbuton);
  }
  estado(event) {
    this.valuestado = event.detail.value;
  }

  VerifyGetId() {
    const getToken = () =>
      fetch(this.apiUrl + '/sdk/v2/token', {
        method: 'POST',
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-sdk-key': this.sdkKey,
        },
      });

    getToken()
      .then((response) => response.json())
      .then((json) => {
        const input = {
          apiUrl: this.apiUrl,
          token: json.token,
          flowName: this.flowName,
        };
        //const promise = getID.startVerificationFlow({ input });
       // promise.then((result) => console.log(result));
      });
  }

  Nslider(){

    this.modalcontroller.create({

      component:SliderInformationComponent,
      componentProps:{linkgetid:this.verificacionGetid}
      
    }).then((modal) => {
			modal.present();
    })
  }

 

}
