import { Injectable } from '@angular/core';
import { User } from 'src/app/Models/Classes/User/user';
import { RequestService } from '../request/request.service';
import Swal2 from 'sweetalert2';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	constructor(
		private request: RequestService,
		
	) { }

	public register(user: User): Promise<any> {
		return new Promise((resolve, reject) => {
			this.request.createRequestPost('register', user).then((data) => {
				resolve(data);
			}).catch((error) => {
				Swal2.fire({
					title: 'Error de registro',
					text: 'Ocurrió un error inesperado. Si el problema persiste, comunícate con el soporte de Laniakea. ',
					icon: 'error',
					confirmButtonText: 'Entendido',
					color: '#ffffff',
					background: '#010f4e',
				  }).then(() => {
					console.log("Alert displayed successfully.");
				  }).catch((error) => {
					console.error("Error displaying alert:", error);
				  });
				reject(error)
			})
		});
	}


	public login(user: User): Promise<any> {
		return new Promise((resolve, reject) => {
			this.request.createRequestPost('login', user).then((data) => {
			
				resolve(data);
			}).catch((error) => {
			
					Swal2.fire({
						title: 'Error de inicio de sesión',
						text: 'El correo y/o la contraseña no coinciden',
						icon: 'error',
						confirmButtonText: 'Entendido',
						color: '#ffffff',
						background: '#010f4e',
					  }).then(() => {
						console.log("Alert displayed successfully.");
					  }).catch((error) => {
						console.error("Error displaying alert:", error);
					  });
					console.error(error);
				
				reject(error);
			});
		});
	}

	public logout(): Promise<any> {
		return new Promise((resolve, reject) => {
			this.request.createRequestPost('logout', { 'key': 'value' }).then((data) => {
				resolve(data);
			}).catch((error) => {
				reject(error);
			});
		});
	}

	public getVersion() {
		return new Promise((resolve, reject) => {
			this.request.createRequestGet('getVersion').then((data) => {
				resolve(data);
			}).catch((error) => {
				reject(error);
			});
		});
	}
	public ForgotPassword(email: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this.request.createRequestPost('send-email', email).then((data) => {
			
				resolve(data);
			}).catch((error) => {
				reject(error);
			});
		});
	}
}
