import { Component, OnInit, Input,ViewEncapsulation,  OnChanges, SimpleChanges } from '@angular/core';
import { MenuController, NavController, PopoverController, ActionSheetController } from '@ionic/angular';
import { NotificationService } from 'src/app/services/notifications/notification.service';
import { NotificationListComponent } from '../../common-elements/notifications/notification-list/notification-list.component';
import { input } from 'aws-amplify';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MyFeedPagePage } from 'src/app/pages/my-feed-page/my-feed-page.page';
import { Device, BatteryInfo } from '@capacitor/device';
import { Platform, ModalController } from '@ionic/angular';
import Swal2 from 'sweetalert2';
import {Capacitor} from "@capacitor/core";
import { SpeechRecognition } from '@ionic-native/speech-recognition/ngx';
import {UploadMultimediaService} from "src/app/services/upload/upload-multimedia.service";
import { RequestService } from 'src/app/services/request/request.service';
import { Browser } from '@capacitor/browser';
import { PopOptionsListComponent } from '../../general-design/pop-options-list/pop-options-list.component';
import { Howl } from 'howler';
import {TpPostComponent} from 'src/app/tp-post/tp-post.component';
import {AIPopupComponentComponent} from 'src/app/ai-popup-component/ai-popup-component.component';
//import { MessagesPagePage } from 'src/app/pages/messages-page/messages-page.page';
declare var MediaRecorder: any; // Declare MediaRecorder globally
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
	selector: 'app-header-bar',
	templateUrl: './header-bar.component.html',
	styleUrls: ['./header-bar.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class HeaderBarComponent implements OnInit {
	dynamicSrc;
	public web:boolean=true;
	time;
	private noDuplicateAIPopOver: HTMLIonPopoverElement = undefined;
	cancelRekognition;
	public progress: number = 0;
	protected interval: any;
	protected eventPosition: any;
	amountOfPostsRequested;
	private noDuplicatePopOver: HTMLIonPopoverElement = undefined;
//Voic command start
transcriptionJobName;
stream:any;
mediaRecorder:any;

dictationTime;
dictationActive:boolean=false;
dictationTimer;
//Voice command end

	public IsCharging:boolean=false;
	public typeTextCalled:boolean=false;
	currentUTCTime: string;
	@Input() NotificationsCounter: any = '⚡️';
	batteryIndicatorWidth: number = 0; // Initialize with 0
	isCharging: boolean = false;
  @Input() FromModul: string;
  title: string='Laniakea';
  notificationStyle = 'iconUnSelect';
  sendMessageTimeout;
  public mostrar=true;

	constructor(
	private MyFeedPagePage: MyFeedPagePage,
		private menu: MenuController,
		private navCtrl: NavController,
		private popOverController: PopoverController,
		private notificationService: NotificationService,
    private formService:FormServiceService,
	private router:Router,
	private location: Location,
	private platform: Platform,
	private actionSheetController: ActionSheetController,
	private speechRecognition: SpeechRecognition,
	private uploadMultimedia: UploadMultimediaService,
	private request: RequestService,
	private modalController: ModalController,
	private sanitizer: DomSanitizer,
	//private messages : MessagesPagePage

	) {

	 }


		 
		// ngAfterViewInit(){
		//	setTimeout(() => {
				// Reload the current page to reinitialize the app
				//this.formService.onInit();
			//	},3000);
			
		// }
ai_function_calling(data, type){
	if(type=='mensaje'){
	// List of redundant words related to "sending" or "writing" a message
const redundantWords = [
	'enviar', 
	'envía', 
	'mandar', 
	'manda', 
	'escrib', 
	'escribe', 
	'mensaje', 
	'mensajea', 
	'text', 
	'manda un mensaje', 
	'mándale un mensaje', 
	'envíale un mensaje', 
	'enviale un texto', 
	'escríbele'
  ];

// Preprocess the data to remove redundant words
let processedData = data.toLowerCase();
redundantWords.forEach((word) => {
  const regex = new RegExp(`\\b${word}\\b`, 'gi'); // Create a regex to match the whole word
  processedData = processedData.replace(regex, '').replace(/\s+/g, ' '); // Remove redundant words and normalize multiple spaces
});
// Remove 1 or 2 letter words from processedData
processedData = processedData.replace(/\b\w{1,2}\b/g, '').replace(/\s+/g, ' ').trim();
console.log('Processed Data after removing redundant words:', processedData);
				console.log('send dm triggered');
				// Código para enviar el mensaje
				this.formService.Contacting_with_ai(processedData, 'mensaje', data);
}else if(type=='llamada'){
	console.log('call triggered');
	// List of redundant words related to "call"
	const redundantWords = ['llamar', 'llama', 'comunica', 'marca', 'contacta', 'telefonea', 'habla', 'hablale', 'llamada'];
	
	// Preprocess the data to remove redundant words
	let processedData = data.toLowerCase();
	redundantWords.forEach((word) => {
	  const regex = new RegExp(`\\b${word}\\b`, 'gi'); // Create a regex to match the whole word
	  processedData = processedData.replace(regex, '').replace(/\s+/g, ' '); // Remove redundant words and normalize multiple spaces
	});
	// Remove 1 or 2 letter words from processedData
	processedData = processedData.replace(/\b\w{1,2}\b/g, '').replace(/\s+/g, ' ').trim();
	console.log('Processed Data after removing redundant words:', processedData);
	
					this.formService.Contacting_with_ai(processedData, 'llamada', data);
					return;
}
}
		 handleCommandsForFunctionCalling(data){
			 if(this.formService.selectedLiaLanguage!=='es-US'){
				Swal2.fire({
					title: 'Procesando...',
					text: 'El comando de voz no está en español',
					icon: 'warning',
					timer: 45000, // Show the alert for 45 seconds
					timerProgressBar: true,
					toast: true,
					position: 'top-end',
					showConfirmButton: false
				  });
				  return;
			 }
			 if (
				data.includes('llamar') ||
				data.includes('llama') ||
				data.includes('comunica') ||
				data.includes('marca') ||
				data.includes('contacta') ||
				data.includes('telefonea') ||
				data.includes('habla') ||
				data.includes('hablale') ||
				data.includes('llamada')
			) {
				return this.ai_function_calling(data, 'llamada');
				// Código para ejecutar la llamada
			}
			if (
				data.includes('enviar') ||
				data.includes('envía') ||
				data.includes('mandar') ||
				data.includes('manda') ||
				data.includes('escrib') ||
				data.includes('escribe') ||
				data.includes('mensaje') ||
				data.includes('mensajea') ||
				data.includes('text') ||
				data.includes('manda un mensaje') ||
				data.includes('mándale un mensaje') ||
				data.includes('envíale un mensaje') ||
				data.includes('enviale un texto') ||
				data.includes('escríbele')
			) {

				return this.ai_function_calling(data, 'mensaje');
			}
			if (
				data.includes('publica') ||
				data.includes('publicación') ||
				data.includes('postear') ||
				data.includes('poste') ||
				data.includes('subir') ||
				data.includes('sube') ||
				data.includes('compart') ||
				data.includes('comparte') ||
				data.includes('hacer un post') ||
				data.includes('subir un post') ||
				data.includes('escribir un post') ||
				data.includes('hacer una publicación') ||
				data.includes('compartir una publicación') ||
				data.includes('post') ||
				data.includes('entrada')
			) {
				console.log('create a  post triggered');
				// Código para ejecutar la publicación
			}
			
			
	
	
		 }

		 handleMessage(event: MessageEvent) {
			// Validate the origin of the message
			if (event.origin !== 'https://heylia.web.app') {
			  console.warn('Invalid origin:', event.origin);
			  return;
			}
					// Process the data received from the iframe
		
				
					const data = event.data.toLowerCase(); // Convert data to lowercase for case-insensitive matching
	const pattern = /lia|lea/; // Regular expression pattern to match "lia" or "lea"
	let lia=false;
	
	this.handleCommandsForFunctionCalling(data);

					if (data.includes('lia') || data.includes('lea')) {
						console.log('The data includes "Lia".');
						lia=true;
	
						// Additional logic if "Lia" is found in the data
					  } else if (pattern.test(data)) {
						lia=true;
						console.log('The data includes "Lia" or a similar variation.');
						// Additional logic if a variation of "Lia" is found in the data
					  }else if(this.formService.videcall_with_lia){
						lia=true;
					  }
					  if(lia){
						this.formService.voiceCommandListernActive=true;
		if(this.formService.AI_message){
			this.formService.AI_message=this.formService.AI_message+' '+data;
		}else{
			this.formService.AI_message=data;
		}
					
		 // Set the time variable to 6 seconds
		 this.time = 2700;
	
		 // Call sendmessage() after 6 seconds
		this.sendMessageTimeout= setTimeout(() => {
			this.formService.voiceCommandListernActive=false;
		   this.formService.sendMessage_to_ai();
		 }, this.time);
					  }
				
	
		}
		 voiceCommand() {
			 this.formService.voiceCommandListernActive=true;
			this.startDictation();
			this.router.navigate(['/home/messages-page']);
			this.formService.AI=true;
		  }
		  cancelSpeeachRecognition(){
			  this.cancelRekognition=true;
			  this.formService.voiceCommandListernActive=false;
			  setTimeout(() => {
				this.formService.voiceCommandListernActive=false;
				this.cancelRekognition=false;
			  }, 3333);
		  }
		  startDictation() {
			if (Capacitor.getPlatform() !== 'web') {
			this.speechRecognition.hasPermission()
			  .then((hasPermission: boolean) => {
				if (!hasPermission) {
				  this.speechRecognition.requestPermission();
				} else {
				  this.initiateDictation(); // Start dictation process
				}
			  });
			}else{
				this.callAwsSpeechRecognition();
			}
		  }
		  async callAwsSpeechRecognition() {
			if(!this.dictationActive){
			this.dictationTime = 0;
			this.dictationActive = true;
			clearInterval(this.dictationTimer);
		  
			// Start the chronometer
			this.dictationTimer = setInterval(() => {
			  this.dictationTime++;
			}, 1000); // Update every second
			try {
			
		  
			 // Request access to the microphone
			  this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
	
			 // Set up MediaRecorder to capture audio data
			 const mediaRecorder = new MediaRecorder(this.stream);
			 let recordedChunks = [];
	   
			 // Define event handler for when data is available
			 mediaRecorder.ondataavailable = (event) => {
			   recordedChunks.push(event.data);
			 };
	   
			 // Define event handler for when recording is stopped
			 mediaRecorder.onstop = async () => {
				 if(this.cancelRekognition){
					 return;
				 }
			   console.log('Recording stopped. Sending request to AWS Transcribe...');
			   const audioBlob = new Blob(recordedChunks, { type: 'audio/wav' });
	   
			   try {
				const currentTime = new Date().toISOString().replace(/:/g, '-').replace(/\./g, '-');
				const filename = currentTime + '.mp3';
				this.uploadMultimedia.post(audioBlob, filename).then(({ link, mimeType }) => {
	
	
					   const ruta = link;
						 console.log('ruta', ruta);
					
	// Function to handle the response from AWS Transcribe
	const handleResponse = (response) => {
		console.log('Received response from AWS Transcribe:', response);
	
		// Access relevant data from the response
		 //this.transcriptionJobName = response.result.transcriptionJobName;
		const transcriptionText = response.result.transcriptionText;
		const jobStatus = response.result.jobStatus;
	
		// Log transcription result
		console.log('Transcription Job Name:', this.transcriptionJobName);
		console.log('Transcription Text:', transcriptionText);
		console.log('Job Status:', jobStatus);
	
		if (transcriptionText || jobStatus === 'COMPLETED') {
			this.formService.AI_message=transcriptionText;
			this.transcriptionJobName='';
			// If job is completed, end the process
			console.log('Transcription job completed.');
			clearInterval(interval); // Stop setInterval
			// Further processing of transcriptionText, or whatever action needed
		} 
	};
	
	if(!this.transcriptionJobName){
	// Initial call to create transcription job
	this.request.createRequestPost('createAWSTranscribe', { audioBlob: ruta }).then((response) => {
		this.transcriptionJobName = response.result.transcriptionJobName;
		handleResponse(response); // Handle the response
	}).catch((error) => {
		console.error('Failed to send request:', error);
	});
	}
	
	// Set interval to check job status every 5 seconds
	const interval = setInterval(() => {
		this.request.createRequestPost('createAWSTranscribe', { transcriptionJobName: this.transcriptionJobName }).then((response) => {
			Swal2.fire({
				title: 'Procesando...',
				text: 'El comando de voz se está procesando.',
				icon: 'success',
				timer: 45000, // Show the alert for 45 seconds
				timerProgressBar: true,
				toast: true,
				position: 'top-end',
				showConfirmButton: false
			  });
			handleResponse(response); // Handle the response
		}).catch((error) => {
			console.error('Failed to send request:', error);
		});
	
	}, 5000);
	
			}).catch((error) => {
				console.error('Failed to send request:', error);
			});
			   } catch (error) {
				 console.error('Error receiving response from AWS Transcribe:', error);
			   }
			 };
	   
			 console.log('Starting media recorder...');
			 // Start recording audio
			 mediaRecorder.start();
			  // Store the MediaRecorder instance for later use
			  this.mediaRecorder = mediaRecorder;
		   } catch (error) {
			 console.error('Error calling AWS Transcribe service:', error);
		   }
		 } else {
			if (this.stream) {
				this.stream.getTracks().forEach(track => track.stop());
			  }
			this.mediaRecorder.stop();
		   this.stopDictation();
	
		 }
	   }
	  async AI_MESSAGE($event){
		  console.log($event);
		clearInterval(this.interval);
		this.eventPosition = $event;
		const self = this;
		this.progress = 0;
		this.interval = setInterval(function () {
			self.progress = self.progress + 1;
	
	
			if(self.progress >= 15){
				self.display_ai_popup($event);
		
			}
	
		}, 50);
	
	   }
	   onTouchEnd_AI_button($event) {
		console.log($event);
		if(this.noDuplicatePopOver == undefined){
			if (this.platform.is('android') || this.platform.is('ios')) {
				clearInterval(this.interval);
			
			} else {
	
				clearInterval(this.interval);
			
			}
		}
	
	
	}
	   async display_ai_popup($event){
		if(this.noDuplicateAIPopOver == undefined){
			this.formService.AI=true;
		const popover = await this.popOverController.create({
			component: AIPopupComponentComponent, // Replace with your component for observations
			translucent: true,
			event: $event,
			backdropDismiss: true, // Allow dismissal by tapping outside the popover
		  }).then((popover: HTMLIonPopoverElement) => {
			clearInterval(this.interval);
			this.progress = 0;
			if(this.noDuplicateAIPopOver != undefined){
				this.noDuplicateAIPopOver.dismiss();
				this.noDuplicateAIPopOver = undefined;
			}
			this.noDuplicateAIPopOver = popover;
			this.noDuplicateAIPopOver.present();
			this.noDuplicateAIPopOver.onDidDismiss().then((outputData) => {
				this.noDuplicateAIPopOver = undefined;
			})
			});
		
	
		}
	   }
		  
		  initiateDictation() {
			const audioUrl = 'https://laniakea-multimedia.s3.amazonaws.com/public/bongo.mp3';
			console.log('audioUrl', audioUrl);
	const sound = new Howl({
	html5: true,
			  src: [audioUrl],
			  format: ['mp3'], // Specify the audio format as MP3
			  autoplay: true,
			  volume: 1.0,
			});
								sound.play();
	
			// Reset the chronometer
			if(!this.dictationActive){
			this.dictationTime = 0;
			this.dictationActive = true;
			clearInterval(this.dictationTimer);
		  
			// Start the chronometer
			this.dictationTimer = setInterval(() => {
			  this.dictationTime++;
			}, 1000); // Update every second
		  
	
			  // Ensures stopListening has fully executed before starting a new session
			  this.speechRecognition.startListening()
				.subscribe(
				  (matches: Array<string>) => {
					this.formService.AI_message = matches[0];
				  },
				  (onerror) => {
					console.log('error:', onerror);
					this.stopDictation(); // Stop dictation on error
				  }
				);
	
		}else{
			this.stopDictation(); 
		}
		  }
		  
		  stopDictation() {
			// Stop the speech recognition service
			this.speechRecognition.stopListening();
			// Stop the chronometer
			this.dictationActive = false;
			clearInterval(this.dictationTimer);
			this.dictationTime = 0; // Reset the time
	
		  }
		  
	  
		 getNetworkIcon(): string {
			
			switch (this.formService.network.connectionType) {
			  case 'wifi':
				return 'wifi-sharp';
			  case 'cellular':
				return 'cellular-sharp';
			  case 'unknown':
				return 'cloud-done';
			  default:
				return 'cellular-outline';
			}
		  }
		  getBatteryColor(): string {
			
			switch (this.IsCharging) {
			  case true:
				return 'solid yellow';
			  case false:
				return 'solid white';
			
			  default:
				return 'solid white';
			}
		  }
		 askForAuthentifactionCapture(take_photo: boolean = true, take_video: boolean = true, pick_media: boolean = true,pick_video: boolean = true) {
			Swal2.fire({
				title: '',
				text: '',
				icon: 'success',
				timer: 9999, // Show the alert for 45 seconds
				timerProgressBar: true,
				toast: true,
				position: 'top-start',
				showConfirmButton: false
			  });
		setTimeout(() => {
		  this.actionSheetController.dismiss();
		  }, 9999); 
			return new Promise((resolve, reject) => {
	
				let botonesDisponibles = [
					(take_photo) ?
						{
							text: 'Iniciar sesión',
							icon: 'log-in',
							handler: () => {
						this.login();
							}
						} : null,
					(take_video) ?
						{
							text: 'Crear nueva cuenta.',
							icon: 'person-add',
							handler: () => {
								this.router.navigate(['/register']);
							}
						} : null,
					{
						text: 'Cancelar',
						icon: 'close',
						role: 'cancel',
						handler: () => {
							this.actionSheetController.dismiss();
						}
					}
				];
	
				botonesDisponibles = botonesDisponibles.filter((boton) => {
					if (boton != null) {
						return true;
					}
					return false;
				});
	
	
				this.actionSheetController.create({
					header: 'Elige una opción:',
					cssClass: 'options-sheet',
					buttons: botonesDisponibles
				}).then((actionSheet) => {
					actionSheet.present();
				});
			});
		}
	
	  login(){
	   
		 this.router.navigate(['/login']);
		
	  }
	fetchCounter(){
		this.notificationService.FetchNotificationGeneralCounter()
		.then((data) => {
		  this.NotificationsCounter = data;
		  console.log('header:', this.NotificationsCounter);
		})
		.catch((error) => {
		  // Handle the error if the request fails
		});
	}
		 async ngOnInit() {
			this.request.createRequestPost('insert_face_identifaction',{get: true, }) .then((data) => {
				console.log(data);
	
			if(data.success=='active'){
				this.formService.face_id_active=true;
			}else{
				this.formService.face_id_active=false;
			}
	
			}).catch((error) => {
				console.error('Failed to send request:', error);
			});
		
			if(Capacitor.getPlatform() === 'web'){
				this.web=true;
			}else{
				this.web=false;
				this.initiateDictation();
			 // Set the time variable to 6 seconds
			 this.dictationTimer = setInterval(() => {
				const data = this.formService.AI_message.toLowerCase(); // Convert data to lowercase for case-insensitive matching
				const pattern = /lia|lea/; // Regular expression pattern to match "lia" or "lea"
				let lia=false;
				
				
								if (data.includes('Lia') || data.includes('Lea')) {
									console.log('The data includes "Lia".');
									lia=true;
				
									// Additional logic if "Lia" is found in the data
								  } else if (pattern.test(data)) {
									lia=true;
									console.log('The data includes "Lia" or a similar variation.');
									// Additional logic if a variation of "Lia" is found in the data
								  }
				 if(lia){
					this.formService.voiceCommandListernActive=true;
	
		
				 }else{
					return;
				 }
	
		
			 this.time = 1500;
	
			 // Call sendmessage() after 6 seconds
			this.sendMessageTimeout= setTimeout(() => {
				this.formService.voiceCommandListernActive=false;
			   this.formService.sendMessage_to_ai();
			 }, this.time);
			}, 1000); // Update every second
			}
		
			this.request.createRequestPost('handle_hey_Lia', {get:true})
			.then((data) => {
			console.log(data);
			
			if(data.success){
				this.formService.selectedLiaLanguage=data.success.Lia_language;
				console.log('lia_language',this.formService.selectedLiaLanguage)
				const baseUrl = "https://heylia.web.app/?username=null&language=";
				this.dynamicSrc =  this.sanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}${this.formService.selectedLiaLanguage}`);
				console.log('heyLia',data.success);
				if(data.success.hey_lia === '1'){
					this.formService.heyLia=true;
				}else{
					this.formService.heyLia=false;
				}
				
			}
			})
			.catch((error) => {
			
			  console.error('An error occurred:', error);
			  // Handle the error here, such as displaying a message to the user or logging it for further investigation.
			});
			window.addEventListener('message', this.handleMessage.bind(this));
		this.fetchCounter();
		  
		this.formService.titleHeader=this.title;
		this.typeText( this.formService.titleHeader,99)
		this.formService.mostrar=this.mostrar;
		this.updateCurrentUTCTime(); // Initialize the time immediately
		 setInterval(() => {
		   this.updateCurrentUTCTime(); // Update the time every second
		 }, 1000);
		
		 setInterval(() => {
	this.getBatteryData();
		}, 9999);
	   }
	   async getBatteryData(){
		const info = await Device.getBatteryInfo();
		this.batteryIndicatorWidth = info.batteryLevel * 100;
		this.batteryIndicatorWidth= Math.round(this.batteryIndicatorWidth);
		this.IsCharging=info.isCharging;
	   }
	   updateCurrentUTCTime() {
		const now = new Date();
		const currentTime = now.toISOString().split('T')[1].substring(0, 8);
		this.currentUTCTime = currentTime; // Get the current UTC time (HH:mm:ss)
	  }
	  handleTouchStart($event){
		const touchX = $event.touches[0].clientX;
		const touchY = $event.touches[0].clientY;
		this.handleApps($event);
	  }
	  onTouchEnd($event) {
		
	
	
					this.handleApps($event);
		
	
	}
	  async handleApps(ev:any){
	
		Swal2.fire({
			title: 'Procesando...',
			text: 'Se está procesando la autentificación.',
			icon: 'success',
			timer: 4500, // Show the alert for 45 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-end',
			showConfirmButton: false
		  });
		const audioUrl = 'https://laniakea-multimedia.s3.amazonaws.com/public/bongo.mp3';
			console.log('audioUrl', audioUrl);
	const sound = new Howl({
	html5: true,
			  src: [audioUrl],
			  format: ['mp3'], // Specify the audio format as MP3
			  autoplay: true,
			  volume: 1.0,
			});
								sound.play();
	  let optionList = new Array<any>();
	
	  if(this.formService.profile){
	  this.request.createRequestPost('getAuthTikTokProfile', {})
	  .then((data) => {
		let username;
		  if(data.success){
			 username='@'+data.success.data.creator_username;
		  }
		 
	  console.log(data);
	  if(this.formService.TikTok){
		this.request.createRequestPost('checkTikTokPostStatus', {})
			.then((data) => {
	
			// Open the login URL in the system browser
		
			console.log(data);
	let status =data.success.data.status;
	if (data.success.data.fail_reason){
		status=status +': '+data.success.data.fail_reason;
	}
		
			if(username){
				optionList.push({ text: username, icon: "logo-tiktok" });
			}else{
				optionList.push({ text: status, icon: "logo-tiktok" });
			}
		});
		
	
		this.formService.TikTok=null;
	}else{
	
		if(username){
			optionList.push({ text: username, icon: "logo-tiktok" });
		}else{
			optionList.push({ text: "Tik Tok", icon: "logo-tiktok" });
		}
		
	}
	})
	.catch((error) => {
		optionList.push({ text: "Tik Tok", icon: "logo-tiktok" });
	  console.error('An error occurred:', error);
	  // Handle the error here, such as displaying a message to the user or logging it for further investigation.
	});
	this.request.createRequestPost('getAuthInstagramProfile', {})
	.then((data) => {
	console.log(data);
	
	if(data.success){
		optionList.push({ text: data.tp_user.username, icon: "logo-instagram" });
	}else{
		optionList.push({ text: "Instagram", icon: "logo-instagram" });
	}
	})
	.catch((error) => {
		optionList.push({ text: "Instagram", icon: "logo-instagram" });
	  console.error('An error occurred:', error);
	  // Handle the error here, such as displaying a message to the user or logging it for further investigation.
	});
	this.request.createRequestPost('getAuthLinkedInProfile', {})
	.then((data) => {
	console.log(data);
	
	if(data.success){
		optionList.push({ text: data.success.name, icon: "logo-linkedin" });
	}else{
		optionList.push({ text: "LinkedIn", icon: "logo-linkedin" });
	}
	})
	.catch((error) => {
		optionList.push({ text: "LinkedIn", icon: "logo-linkedin" });
	  console.error('An error occurred:', error);
	  // Handle the error here, such as displaying a message to the user or logging it for further investigation.
	});
	this.request.createRequestPost('getAuthYouTubeToken', {})
	.then((data) => {
	console.log(data);
	
	if(data.success){
		optionList.push({ text: data.tp_username, icon: "logo-youtube" });
		optionList.push({ text: data.tp_username, icon: "logo-google" });
	}else{
		optionList.push({ text: "YouTube", icon: "logo-youtube" });
	optionList.push({ text: 'Drive', icon: "logo-google" });
	}
	})
	.catch((error) => {
		optionList.push({ text: "YouTube", icon: "logo-youtube" });
	optionList.push({ text: 'Drive', icon: "logo-google" });
	  console.error('An error occurred:', error);
	  // Handle the error here, such as displaying a message to the user or logging it for further investigation.
	});
	this.request.createRequestPost('getAuthXProfile', {})
	.then((data) => {
	console.log(data);
	
	if(data.success){
		optionList.push({ text: '@'+data.success.tp_username, icon: "logo-twitter" });
	}else{
		optionList.push({ text: "𝕏", icon: "logo-twitter" });
	}
	})
	.catch((error) => {
		optionList.push({ text: "𝕏", icon: "logo-twitter" });
	  console.error('An error occurred:', error);
	  // Handle the error here, such as displaying a message to the user or logging it for further investigation.
	});
		  
	}else{
		Swal2.fire({
			title: 'No disponible',
			text: 'Inicia sesión para acceder a esta funcionalidad.',
			icon: 'warning',
			//showCancelButton: true,
			//cancelButtonText:'Cancelar',
			confirmButtonColor: 'green',
			color: '#ffffff',
			background: '#010f4e',
			confirmButtonText: 'OK'
		}).then((result) => {
			if (result.isConfirmed) {
				// Handle logout process here
				//this.logout_button();
			}
		});
		optionList.push({ text: "𝕏", icon: "logo-twitter" });
		optionList.push({ text: "LinkedIn", icon: "logo-linkedin" });
		optionList.push({ text: "Instagram", icon: "logo-instagram" });
		optionList.push({ text: "Tik Tok", icon: "logo-tiktok" });
		optionList.push({ text: 'YouTube', icon: "logo-youtube" });
		optionList.push({ text: 'Drive', icon: "logo-google" });
	
	}
	
	
	//optionList.push({ text: "Drive", icon: "logo-facebook" });
	  //optionList.push({ text: "Facebook", icon: "logo-facebook" });
	
	
	  
	  //optionList.push({ text: "Responder Mensaje", icon: "arrow-undo" });
	
	  this.popOverController.create({
		  component: PopOptionsListComponent,
		  cssClass: 'relationConfrmation',
		//showBackdrop:false,
		event: ev,
		  translucent: true,
		  componentProps: {
			  optionList: optionList,
			  title: '1 EN LA APP: publica a otras apps desde una sola app. 2-Terceros: enlaza tu contenido de otras apps a Laniakea.'
		  },
	
	  }).then((popover: HTMLIonPopoverElement) => {
		  if(this.noDuplicatePopOver != undefined){
			  this.noDuplicatePopOver.dismiss();
			  this.noDuplicatePopOver = undefined;
		  }
		  this.noDuplicatePopOver = popover;
		  this.noDuplicatePopOver.present();
		  this.noDuplicatePopOver.onDidDismiss().then((outputData) => {
			  this.noDuplicatePopOver = undefined;
			  if (outputData['data']) {
			console.log(	outputData.data.icon);
				  this.messageOptions(outputData.data.icon);
				
			  }
		  })
	  });
	}
	
	  
	
	async messageOptions(selectedOption?: string) {
		Swal2.fire({
			title: 'Procesando...',
			text: 'Se está procesando la solicitud.',
			icon: 'success',
			timer: 4500, // Show the alert for 45 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-end',
			showConfirmButton: false
		  });
		let url;
	  switch (selectedOption) {
		case "logo-youtube":
			if(this.formService.popup_section=== 'TERCEROS'){
				Swal2.fire({
					title: 'No disponible',
					text: 'Por ahora esta app no está disponible',
					icon: 'warning',
					//showCancelButton: true,
					//cancelButtonText:'Cancelar',
					confirmButtonColor: 'green',
					color: '#ffffff',
					background: '#010f4e',
					confirmButtonText: 'OK'
				}).then((result) => {
					if (result.isConfirmed) {
						// Handle logout process here
						//this.logout_button();
					}
				});
				return;
			}
			this.request.createRequestPost('redirectToYoutubeEntanglement', {})
			.then((data) => {
	
			// Open the login URL in the system browser
			url=data;
			console.log(url);
			Browser.open({ url: url });
		});
		
	
			  break;
		  
		  case "logo-instagram":
			let uploadMedia=false;
			if (this.formService.popup_section=== 'TERCEROS'){
				 uploadMedia=true;
			}
			/*else{
				Swal2.fire({
					title: 'No disponible',
					text: 'Por ahora esta app no está disponible',
					icon: 'warning',
					//showCancelButton: true,
					//cancelButtonText:'Cancelar',
					confirmButtonColor: 'green',
					color: '#ffffff',
					background: '#010f4e',
					confirmButtonText: 'OK'
				}).then((result) => {
					if (result.isConfirmed) {
						// Handle logout process here
						//this.logout_button();
					}
				});
				return;
			}*/
			this.request.createRequestPost('redirectToInstagramForThirdPartyEntanglement', {popup_section:this.formService.popup_section})
			.then((data) => {
	
			// Open the login URL in the system browser
			url=data;
			console.log(url);
			Browser.open({ url: url });
			if(url && uploadMedia){
				this.modalController.create({
					component: TpPostComponent,
			  cssClass: 'detailsInModal',
					swipeToClose: true,
					componentProps: {
					
					}
				}).then((modal) => {
					modal.present();
				}).catch((error) => {
					console.error(error);
				});
			}
		});
		
		
		
		 
			  break;
		  case "logo-twitter":
			if(this.formService.popup_section=== 'TERCEROS'){
				Swal2.fire({
					title: 'No disponible',
					text: 'Por ahora esta app no está disponible',
					icon: 'warning',
					//showCancelButton: true,
					//cancelButtonText:'Cancelar',
					confirmButtonColor: 'green',
					color: '#ffffff',
					background: '#010f4e',
					confirmButtonText: 'OK'
				}).then((result) => {
					if (result.isConfirmed) {
						// Handle logout process here
						//this.logout_button();
					}
				});
				return;
			}
			this.request.createRequestPost('redirectToXForPostsEntanglement', {})
			.then((data) => {
	
			// Open the login URL in the system browser
			url=data;
			console.log(url);
			Browser.open({ url: url });
		});
		
	
			  break;
		  case "logo-linkedin":
			if(this.formService.popup_section=== 'TERCEROS'){
				Swal2.fire({
					title: 'Enlazar publicaciones de tu organiación',
					text: 'Por el momento, por políticas de LinkedIn solo se pueden enlazar las publicaciones de la organización en la que seas administrador.',
					icon: 'warning',
					//showCancelButton: true,
					//cancelButtonText:'Cancelar',
					confirmButtonColor: 'green',
					color: '#ffffff',
					background: '#010f4e',
					confirmButtonText: 'OK'
				}).then((result) => {
					if (result.isConfirmed) {
						// Handle logout process here
						//this.logout_button();
					}
				});
			
			}
			this.request.createRequestPost('redirectToLinkedInForEntanglement', {popup_section:this.formService.popup_section})
			.then((data) => {
	
			// Open the login URL in the system browser
			url=data;
			console.log(url);
			Browser.open({ url: url });
			if(url && this.formService.popup_section=== 'TERCEROS'){
				this.modalController.create({
					component: TpPostComponent,
			  cssClass: 'detailsInModal',
					swipeToClose: true,
					componentProps: {
					
					}
				}).then((modal) => {
					modal.present();
				}).catch((error) => {
					console.error(error);
				});
			}
		});
	
			  break;
	
			  case "logo-tiktok":
				  let makeRequest=false;
				if(this.formService.popup_section=== 'TERCEROS'){
	
	
				const { value: newAmount, isConfirmed } = await Swal2.fire({
					title: 'Selecciona cantidad de publicaciones',
					html: `
					  <ion-item >
						<ion-label>Cantidad: </ion-label>
						<ion-range #rangeInput [pin]="true" min="1" max="9999" step="1">
						  <ion-label slot="start">1</ion-label>
						  <ion-label slot="end">9999</ion-label>
						</ion-range>
					  </ion-item>
	
					`,
					focusConfirm: false,
					showCancelButton: true,
					confirmButtonText: 'Confirmar',
					cancelButtonText: 'Cancelar',
					preConfirm: () => {
						const rangeInput = document.querySelector('ion-range') as HTMLIonRangeElement;
						return rangeInput ? rangeInput.value : null;
					}
				  });
			  
				  if (isConfirmed && newAmount) {
					// New amount selected and confirmed
					console.log('Selected amount of posts:', newAmount);
					this.amountOfPostsRequested=newAmount;
				
	
					Swal2.fire({
						title: 'Recordatorio, de publicaciones inmediatas',
						text: `Se publicarán en LANIAKEA inmediatamente tus publicaciones de TikTok. De acuerdo a tu selección de ${this.amountOfPostsRequested} publicaciones.`,
						icon: 'info',
						showCancelButton: true,
						confirmButtonText: 'Enterado',
						cancelButtonText: 'Cancelar',
						color: '#ffffff',
						background: '#010f4e',
					  }).then((result) => {
						if (result.isConfirmed) {
							makeRequest=true;
						}
					  });
				
				  } else {
					// Dialog canceled
					console.log('Dialog canceled');
				  }
				}else{
					makeRequest=true;
				
				}
				if(makeRequest){
				this.request.createRequestPost('redirectToTikTokForEntanglement', {popup_section:this.formService.popup_section, amountOfPostsRequested:this.amountOfPostsRequested})
				.then((data) => {
		
				// Open the login URL in the system browser
				url=data;
				console.log(url);
				Browser.open({ url: url });
				if(url && this.formService.popup_section=== 'TERCEROS'){
					this.modalController.create({
						component: TpPostComponent,
				  cssClass: 'detailsInModal',
						swipeToClose: true,
						componentProps: {
						
						}
					}).then((modal) => {
						modal.present();
					}).catch((error) => {
						console.error(error);
					});
				}
			});
		}
				  break;
				  case 'logo-google':
					if(this.formService.popup_section!== 'TERCEROS'){
						Swal2.fire({
							title: 'No disponible',
							text: 'Por ahora esta app no está disponible',
							icon: 'warning',
							//showCancelButton: true,
							//cancelButtonText:'Cancelar',
							confirmButtonColor: 'green',
							color: '#ffffff',
							background: '#010f4e',
							confirmButtonText: 'OK'
						}).then((result) => {
							if (result.isConfirmed) {
								// Handle logout process here
								//this.logout_button();
							}
						});
						return;
					}
					this.request.createRequestPost('redirectToYoutubeEntanglement', {drive:true,})
					.then((data) => {
			
					// Open the login URL in the system browser
					url=data;
					console.log(url);
					Browser.open({ url: url });
				});
				
			
					break;  
		  case "Responder Mensaje":
			
		  default:
			Swal2.fire({
				title: 'No disponible',
				text: 'Por ahora esta app no está disponible',
				icon: 'warning',
				//showCancelButton: true,
				//cancelButtonText:'Cancelar',
				confirmButtonColor: 'green',
				color: '#ffffff',
				background: '#010f4e',
				confirmButtonText: 'OK'
			}).then((result) => {
				if (result.isConfirmed) {
					// Handle logout process here
					//this.logout_button();
				}
			});
			  break;
	  }
	
	}

	ColorScheme(){
		let color;
		if(window.matchMedia('(prefers-color-scheme: dark)')){
			color =  'white';
		}else{
			color = 'black'
		}
		return color
	}

	NotificationsButton(ev: any) {

    console.log("title en header bar",this.title);

		//this.notificationService.unreadNotificationCounter = 0;
		//this.notificationService.eventNotification.emit(0);
    this.notificationStyle = 'iconSelect';
		this.popOverController.create({
			component: NotificationListComponent,
			cssClass: 'notificationsPopOver',
		//	event: ev,
			translucent: true,
			componentProps:{
			//	notificationList: this.notificationService.notificationsList
			},

		}).then((popover)=>{
			popover.present();
			popover.onDidDismiss().then((outputData)=>{
        this.notificationStyle = 'iconUnSelect';
				if(outputData['data']){
					//this.messageOptions(outputData.data.text);
				}
			})
		});
	}

	MenuButton() {
		this.menu.toggle("main-menu");
	} 

	route() {

		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
			// Navigate back to the current URL
			this.router.navigate(['/loader']);
		  });
	  setTimeout(() => {
	  // Reload the current page to reinitialize the app
	  window.location.reload();
	  },300);
		/*
		// Store current URL
		let currentUrl = this.router.url;
	  
		// Navigate to a temporary route
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
		  // Navigate back to the current URL
		  this.router.navigate([currentUrl]);
		});*/
	  }
	  
	  typeText(text: string, delay: number) {
		      	 // Check if typeText has already been called for this currentStateIndex
	 if (this.typeTextCalled) {
		return;
	  }
    this.typeTextCalled = true; // Set the flag to indicate 
		let index = 0;
		this.formService.titleHeader='';
		const interval = setInterval(() => {
		  if (index < text.length) {
			this.formService.titleHeader += text.charAt(index);
			index++;
		  } else {
			clearInterval(interval);
		  }
		}, delay);
	  }

	 
	  
}
