import { Component, OnInit } from '@angular/core';
import { FormServiceService } from 'src/app/services/publications/form-service.service';

@Component({
  selector: 'app-earht5',
  templateUrl: './earht5.component.html',
  styleUrls: ['./earht5.component.scss'],
})
export class Earht5Component implements OnInit {

  select: boolean = false;
  select1: boolean = false;
  select2: boolean = false;
  select3: boolean = false;
  select4: boolean = false;
  select5: boolean = false;
  select6: boolean = false;
  select7: boolean = false;
  select8: boolean = false;
  select9: boolean = false;
  select10: boolean = false;
  select11: boolean = false;
  select12: boolean = false;
  select13: boolean = false;

  decision: string = "";
  decision1: string = "";
  decision2: string = "";
  decision3: string = "";
  decision4: string = "";
  decision5: string = "";
  decision6: string = "";
  decision7: string = "";
  decision8: string = "";
  decision9: string = "";
  decision10: string = "";
  decision11: string = "";
  decision12: string = "";
  decision13: string = "";

  constructor(public formservice: FormServiceService) { }

  ngOnInit() { }

  selectdecicion(event) {
    this.formservice.earth5=true;
    var checked = event.detail.checked
    if (checked === true) {
      this.decision = "Si"
      this.formservice.earthpublication.personal_clean = this.decision
    }
    if (checked === false) {
      this.decision = "No"
      this.formservice.earthpublication.personal_clean = this.decision
    }
  }

  selectdecicion1(event) {
    this.formservice.earth5=true;
    var checked = event.detail.checked
    if (checked === true) {
      this.decision1 = "Si"
      this.formservice.earthpublication.wifi = this.decision1

    }
    if (checked === false) {
      this.decision1 = "No"
      this.formservice.earthpublication.wifi = this.decision1
    }
  }

  selectdecicion2(event) {
    this.formservice.earth5=true;
    var checked = event.detail.checked
    if (checked === true) {
      this.decision2 = "Si"
      this.formservice.earthpublication.kitchen_room = this.decision2
    }
    if (checked === false) {
      this.decision2 = "No"
      this.formservice.earthpublication.kitchen_room = this.decision2
    }
  }

  selectdecicion3(event) {
    this.formservice.earth5=true;
    var checked = event.detail.checked
    if (checked === true) {
      this.decision3 = "Si"
      this.formservice.earthpublication.free_parking = this.decision3
    }
    if (checked === false) {
      this.decision3 = "No"
      this.formservice.earthpublication.free_parking = this.decision3
    }
  }

  selectdecicion4(event) {
    this.formservice.earth5=true;
    var checked = event.detail.checked
    if (checked === true) {
      this.decision4 = "Si"
      this.formservice.earthpublication.tv = this.decision4
    }
    if (checked === false) {
      this.decision4 = "No"
      this.formservice.earthpublication.tv = this.decision4
    }
  }

  selectdecicion5(event) {
    this.formservice.earth5=true;
    var checked = event.detail.checked
    if (checked === true) {
      this.decision5 = "Si"
      this.formservice.earthpublication.air_conditioning = this.decision5
    }
    if (checked === false) {
      this.decision5 = "No"
      this.formservice.earthpublication.air_conditioning = this.decision5
    }
  }

  selectdecicion6(event) {
    this.formservice.earth5=true;
    var checked = event.detail.checked
    if (checked === true) {
      this.decision6 = "Si"

      this.formservice.earthpublication.washing_machine = this.decision6

    }
    if (checked === false) {
      this.decision6 = "No"
      this.formservice.earthpublication.washing_machine = this.decision6
    }
  }

  selectdecicion7(event) {
    this.formservice.earth5=true;
    var checked = event.detail.checked
    if (checked === true) {
      this.decision7 = "Si"
      this.formservice.earthpublication.drying_machine = this.decision7
    }
    if (checked === false) {
      this.decision7 = "No"
      this.formservice.earthpublication.drying_machine = this.decision7
    }
  }

  selectdecicion8(event) {
    this.formservice.earth5=true;
    var checked = event.detail.checked
    if (checked === true) {
      this.decision8 = "Si"
      this.formservice.earthpublication.iron = this.decision8
    }
    if (checked === false) {
      this.decision8 = "No"
      this.formservice.earthpublication.iron = this.decision8
    }
  }

  selectdecicion9(event) {
    this.formservice.earth5=true;
    var checked = event.detail.checked
    if (checked === true) {
      this.decision9 = "Si"
      this.formservice.earthpublication.swimming_pool = this.decision9
    }
    if (checked === false) {
      this.decision9 = "No"
      this.formservice.earthpublication.swimming_pool = this.decision9
    }
  }

  selectdecicion10(event) {
    this.formservice.earth5=true;
    var checked = event.detail.checked
    if (checked === true) {
      this.decision10 = "Si"
      this.formservice.earthpublication.pets = this.decision10
    }
    if (checked === false) {
      this.decision10 = "No"
      this.formservice.earthpublication.pets = this.decision10
    }
  }

  selectdecicion11(event) {
    this.formservice.earth5=true;
    var checked = event.detail.checked
    if (checked === true) {
      this.decision11 = "Si"
      this.formservice.earthpublication.smoking_is_allowed = this.decision11
    }
    if (checked === false) {
      this.decision11 = "No"
      this.formservice.earthpublication.smoking_is_allowed = this.decision11
    }
  }

  selectdecicion12(event) {
    this.formservice.earth5=true;
    var checked = event.detail.checked
    if (checked === true) {
      this.decision12 = "Si"
      this.formservice.earthpublication.events_are_allowed = this.decision12
    }
    if (checked === false) {
      this.decision12 = "No"
      this.formservice.earthpublication.events_are_allowed = this.decision12
    }
  }

  additional(event) {
    this.formservice.earth51 =true;
    this.formservice.earthpublication.additional_details = event.target.value
  }
}
