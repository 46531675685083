
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IonContent, IonList, IonTextarea, ModalController, NavController } from '@ionic/angular';
import { Conversation } from 'src/app/Models/Classes/Chat/conversation';
import { Message } from 'src/app/Models/Classes/Chat/message';
import { MediaItem } from 'src/app/Models/Classes/Media/media-item';
import { Utils } from 'src/app/Models/Classes/utils';
import { ChatService } from 'src/app/services/chat/chat.service';
import { CameraServiceService } from 'src/app/services/multimedia/camera-service.service';
import { InputAlertControllerService } from 'src/app/services/utils/input-alert-controller.service';
import { CheckUsersListComponent } from '../check-users-list/check-users-list.component';
import Swal2 from "sweetalert2";
import * as models from "../../../Models/Classes/graphql/models/models-graphql";
import {CommentsService} from "../../../services/comments/comments.service";
import {UploadMultimediaService} from "../../../services/upload/upload-multimedia.service";
import {MultimediaInChatService} from "../../../services/chat/multimedia-in-chat.service";
import {ChimeContactComponent} from "src/app/chime-contact/meeting/chime-contact.component";
import { RequestService } from 'src/app/services/request/request.service';
import { Capacitor } from '@capacitor/core';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import {InlineBowsingComponent} from 'src/app/inline-bowsing/inline-bowsing.component';
@Component({
	selector: 'app-messages-list',
	templateUrl: './messages-list.component.html',
	styleUrls: ['./messages-list.component.scss'],
})
export class MessagesListComponent implements OnInit {

	@Input() username: any;
	@ViewChild(IonContent) contentArea: IonContent;
	@ViewChild(IonList, { read: ElementRef }) set chatList(el: ElementRef) {
		this.mutationObserver.observe(el.nativeElement, {
			childList: true
		});
	};
	@Input() conversation: Conversation;
	@Output() domChange = new EventEmitter<any>();
public objmedia: any;
	public newMessage: Message;
	public messageResponse: Message;
  public answering: boolean = false;
	public showScrollButton: boolean = false;
	public chat_imagen: string;
	public offsetTop: number = 0;
	public timing: number = 0;
	private mutationObserver: MutationObserver;
	constructor(
		private chatService: ChatService,
		private cameraService: CameraServiceService,
		private inputAlertController: InputAlertControllerService,
		private utils: Utils,
		private modalController: ModalController,
		private navCtrl: NavController,
    private commentService: CommentsService,
    private uploadMultimediaService: UploadMultimediaService,
    private chatElementService: MultimediaInChatService,
	private request: RequestService,
	public formservice: FormServiceService,
	) {
		this.newMessage = new Message();

		this.mutationObserver = new MutationObserver((mutations: MutationRecord[]) => {
			mutations.forEach((mutation: any) => {
				if (this.offsetTop < mutation.target.offsetHeight) {
					this.offsetTop = mutation.target.offsetHeight;
				}
				if (this.offsetTop == mutation.addedNodes[0].offsetTop + 18) {
					if (this.conversation.getLastMessage().getMultimediaID() != null || (this.conversation.getLastMessage().getMessageResponse() && this.conversation.getLastMessage().getMessageResponse().getMultimediaID() != null)) {

						setTimeout(() => {
							this.contentArea.scrollToBottom();
						}, 400);
					} else {

						setTimeout(() => {
							this.contentArea.scrollToBottom();
						}, 50);
					}
				}
			});
		});
	}
	startVideoCall(){
		if (Capacitor.getPlatform() === 'web') {
		this.modalController.create({
			//component: ModulesLockComponent,
			component: ChimeContactComponent,
			cssClass: 'detailsInModal',
			swipeToClose: true,
			componentProps: {
			videoCall: true,
			username: this.username,
			receipient_user_id:this.conversation.getUsers()[0].id,
			conversationID:this.conversation.getID(),
				},
		}).then((modal)=>{
			modal.present();
		});
	}else{
		let url= `https://supercumulo.laniakea.tv/loader?meeting=0&username=${this.username}&receipient_user_id=${this.conversation.getUsers()[0].id}&conversationID=${this.conversation.getID()}`;
	this.openBrowser(url);
	}
	}
	async openBrowser(url: string) {
		//await Browser.open({ url });
		const modal = await this.modalController.create({
			cssClass: 'detailsInModal',
			swipeToClose: true,
			component: InlineBowsingComponent,
			componentProps: {
				url: url,
			
			},
		  });
		  modal.onDidDismiss().then((data)=>{
			
		  });
		  return await modal.present();
		window.open(url, '_blank'); // Open the URL in a new tab or window
	  }
	startCall(){
		if (Capacitor.getPlatform() === 'web') {
			this.formservice.webViewLive=false;
		this.modalController.create({
			//component: ModulesLockComponent,
			component: ChimeContactComponent,
			cssClass: 'detailsInModal',
			swipeToClose: true,
			componentProps: {
		call:true,
		username: this.username,
		receipient_user_id:this.conversation.getUsers()[0].id,
		conversationID:this.conversation.getID(),
				},
		}).then((modal)=>{
			modal.present();
		});
	}else{
		let url= `https://supercumulo.laniakea.tv/loader?meeting=0&username=${this.username}&receipient_user_id=${this.conversation.getUsers()[0].id}&conversationID=${this.conversation.getID()}`;
	this.openBrowser(url);
	}
	}
	
	ngOnInit() {
		this.conversation.getMessagesList().forEach((message) => {
			if (message.getMultimediaID() != null) {
				this.timing = 500;
			}
		});
	}

	onDomChange(event) {
		console.log(event);
	}

	ngAfterViewInit() {
		if (this.timing != 0) {
			setTimeout(() => {
				this.contentArea.scrollToBottom();
			}, this.timing);
		} else {
			setTimeout(() => {
				this.contentArea.scrollToBottom();
			}, this.conversation.getMessagesList().length * 3);
		}
		this.chatService.updateSeenMessages(this.conversation);
		this.chatService.conversationOpened = this.conversation.getID();
	}

	public setMessage(message: string) {
		this.newMessage.setText(message);
	}

	public clickOnSend() {
    if (this.commentService.blobsMultimediaComment.length > 0 || !this.IsEmptyText(this.newMessage.getText())) {
		Swal2.fire({
			title: 'Procesando...',
			text: 'El mensaje se está procesando.',
			icon: 'success',
			timer: 4000, // Show the alert for 4 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-end',
			showConfirmButton: false
		  });
		this.post();
    }
	}

  private IsEmptyText(textInput: string) {
    textInput = textInput.replace(/[\r\n\s]+/gm, "");
    if (textInput.length > 0) {
      return false;
    }
    else {
      return true;
    }
  }

  takePhoto() {
    this.cameraService.takePhoto().then((mediaItem) => {
      this.newMessage.setMultimedia(mediaItem);
    }).catch((error) => {
      console.error(error);
    });
  }

  pickMedia() {
    this.cameraService.pickMedia().then((mediaItem) => {
      this.newMessage.setMultimedia(mediaItem);
    }).catch((error) => {
      console.error(error);
    });
  }

  takeVideo() {
    this.cameraService.takeVideo().then((mediaItem) => {
      this.newMessage.setMultimedia(mediaItem);
    }).catch((error) => {
      console.error(error);
    });
  }

	public moreData(event?) {
		if (!this.conversation.getNextToken()) {
			event.target.complete();
			event.target.disabled = true;
		} else {

			this.chatService.getMessagesByConversation(this.conversation, 10, this.conversation.getNextToken()).then(() => {
				event.target.complete();
			}).catch((error) => {
				event.target.complete();
				console.error(error);
			});
		}
	}

  public post() {

	
		const notification = {
			user_id: this.formservice.profile.id,
		
		};
	
		this.request.createRequestPost('validateThatTheUserIsUnblockedInMessage', notification)
		  .then((data) => {

		
	  console.log('posting the message');
this.sendNotification('Nuevo mensaje directo',this.newMessage.getText(), this.conversation.getUsers()[0].id);
    let tempMessage = this.newMessage;
    let tempMessageResponse = this.messageResponse;
    this.newMessage = new Message();
    this.utils.loadingPresent().then(() => {
      let newMessageReq: models.CreateMessageInput = {
        userID: this.chatService.owner.id,
        conversationID: this.conversation.getID(),
        isDelete: false,
        isSeen: false,
        isSystemNotification: false,
        body: tempMessage.getText()
      };
      if (tempMessageResponse) {
        newMessageReq.messageID = tempMessageResponse.getId();
      }
      if (this.commentService.blobsMultimediaComment.length>0) {
        this.commentService.blobsMultimediaComment.forEach(element => {
          console.log('media item in chat service', element);
          this.uploadMultimediaService.post(element.blob, element.filename).then(({ link, mimeType }) => {
			const ruta = link;
		   // const ruta = r[0];
			//const mimeType = r[1];
			console.log('ruta', ruta);
            let pub_type = '';
           // if (mimeType.includes("image")) {
              pub_type = 'imagen';
           // } else if (mimeType.includes("video")) {
             // pub_type = 'video';
            //}
            //let objmedia;
            const element_type = 'mensaje';
            this.objmedia = {
              route: ruta,
              size: 800,
              type: pub_type,
              element_type
            };
            this.chatElementService.postChatElement(this.objmedia).then((data: any) => {
              const chatElement_id = JSON.parse(data).chat_element_id;
              const status = {status: 'activa'};
              this.chatElementService.putChatElement(status, chatElement_id).then((result: any) => {
                //this.presentToastWithOptions();
                const d = JSON.parse(result);
                newMessageReq.multimediaID = d.chatElement.id;
                this.chatService.sendMessageOnGraphQl(newMessageReq).then((messageResponse) => {
                  this.utils.loadingDismiss();
                  this.commentService.blobsMultimediaComment = [];
                  this.newMessage.deleteMultimedia(0);
                  this.answering = false;
                  this.messageResponse = undefined;
                }).catch((error) => {
                  console.error(error);
                  this.utils.loadingDismiss();
                });
              }).catch((error) => {
                console.error(error);
                this.utils.loadingDismiss();
              });
            }).catch((error) => {
              console.error(error);
              this.utils.loadingDismiss();
            });
          });
        });
      }else {
        this.chatService.sendMessageOnGraphQl(newMessageReq).then((messageResponse) => {
          this.answering = false;
          this.messageResponse = undefined;
          this.utils.loadingDismiss();
        }).catch((error) => {
          console.error(error);
          this.utils.loadingDismiss();
        });
      }


    });
}).catch((error) => {
	console.error('The user might be indeed blocked:', error);
	Swal2.fire({
		title: 'Mensaje bloqueado',
		text: 'Es probable que tú o el usuario se hayan bloqueado',
		icon: 'error',
		confirmButtonText: 'Entendido',
		color: '#ffffff',
		background: '#010f4e',
	  });
  });
  }

	public checkScroller(event?) {
		this.contentArea.getScrollElement().then((data) => {
			let maxScroll = data['scrollHeight'];
			let difference = data['scrollTop'] + data['clientHeight'];

			if ((maxScroll - difference) < 5) {
				this.showScrollButton = false;
			}
			else {
				this.showScrollButton = true;
			}
		}).catch((error) => {
			console.error(error);
		});
	}

	public scrollToBottom() {
		this.contentArea.scrollToBottom();
	}

	public presentActionSheet() {
		let buttons = new Array<any>();

		if (this.conversation.getIsGroup()) {
			//buttons.push({ text: 'Salir del grupo', icon: 'log-out' });
			buttons.push({ text: 'Cambiar imagen de la conversación', icon: 'images' });
			buttons.push({ text: 'Cambiar nombre de la conversación', icon: 'text' });
		}
		else {
			//buttons.push({ text: 'Eliminar todos los mensajes', icon: 'trash' });
			buttons.push({ text: 'Ver perfil del usuario', icon: 'person-circle' });
		}
		this.inputAlertController.SelectorActionSheet(buttons, "Opciones de conversacion").then((result) => {

			if (result['action']) {
				switch (result['button']['text']) {
					case 'Salir del grupo': {
						this.inputAlertController.OkInfoMessage(
							"Función en desarrollo", 												//Header
							"Estamos trabajando para que puedas usar esta función lo antes posible",//Message
							"Esperar una versión futura"											//Ok Button Text
						);
						break;
					}
					case 'Cambiar imagen de la conversación': {
						this.changeChatImage();
						break;
					}
					case 'Cambiar nombre de la conversación': {
						this.updateConversationName();
						break;
					}
					case 'Eliminar todos los mensajes': {
						this.inputAlertController.OkInfoMessage(
							"Función en desarrollo", 												//Header
							"Estamos trabajando para que puedas usar esta función lo antes posible",//Message
							"Esperar una versión futura"											//Ok Button Text
						);
						break;
					}
					case 'Ver perfil del usuario': {
						this.utils.loadingPresent().then(() => {
							this.modalController.dismiss().then(() => {
								this.utils.loadingDismiss().then(() => {
									this.navCtrl.navigateForward('home/profile/' + this.conversation.getUsers()[0].id);
								});
							}).catch(() => {
								this.utils.loadingDismiss();
							});
						});


						break;
					}
					default: break;
				}
			}
		}).catch((error) => {
			console.error(error);
		});
	}

	public changeChatImage() {
		this.cameraService.askForMediCapture(true, false, true).then((value) => {
			switch (value) {
				case "Imagen":
					this.cameraService.takePhoto().then((mediaItem: MediaItem) => {
						this.chatService.uploadChatImagen(mediaItem, this.conversation).then((chatElementResponse) => {
							this.conversation.setGroupImage(chatElementResponse.chatElement.media.url);
							this.conversation.setMultimediaID(chatElementResponse.chatElement.id);
						}).catch((error) => {
							console.error(error);
						});
					}).catch((error) => {
						console.error(error);
					});
					break;
				case "Pick":
					this.cameraService.pickMedia("Imagen").then((mediaItem) => {
						this.chatService.uploadChatImagen(mediaItem, this.conversation).then((chatElementResponse) => {
							this.conversation.setGroupImage(chatElementResponse.chatElement.media.url);
							this.conversation.setMultimediaID(chatElementResponse.chatElement.id);
						}).catch((error) => {
							console.error(error);
						});
					}).catch((error) => {
						console.error(error);
					});
					break;
				default:
					break;
			}
		}).catch((error) => {
			console.error(error);
		});
	}

	public updateConversationName() {
		this.inputAlertController.InputAlertPrompt(
			"Modificar nombre del grupo", 								//Header
			"Ingresa el nuevo nombre",									//Message
			this.conversation.getName(), 								//Input Value
			"text", 													//Input Type
			"Cambiar Nombre",											//Ok Button Text
			"20"														//Max Length
		).then((result) => {
			if (result['action']) {
				this.conversation.setName(result['value']);
				this.chatService.updateConversationName(this.conversation).then((promisesArray) => {
					console.log(promisesArray);
				}).catch((error) => {
					console.error(error);
				});
			}

		}).catch((error) => {
			console.error(error);
		});
	}

	public setMessageResponse(event?: any) {
		this.messageResponse = event.message;
    this.answering = true;
	}

	public openResendModal(event?: any) {
		this.modalController.create({
			component: CheckUsersListComponent,
			cssClass: 'detailsInModal',
			swipeToClose: true,
			componentProps: {
				resendMesage: event.message
			}
		}).then((modal) => {
			modal.present();
		});
	}

	public deleteMessage(event?: any) {
		this.chatService.updateMessage(event.message).then((messageResponse) => {
			console.log(messageResponse)
		}).catch((error) => {
			console.error(error);
		});
	}

	public deleteMedia(event) { }
	sendNotification(title, body, user_id) {
   
		return new Promise((resolve, reject) => {
		  const notification = {
			title: title,
			body: body,
			user_id:user_id,
	
		  };
	  
		  this.request.createRequestPost('GreatAtractorNotifications', notification)
			.then((data) => {
			  /*Swal2.fire({
				title: 'Vínculo generado',
				text: 'Nuevo status asequida exitosamente',
				icon: 'success',
				confirmButtonText: 'Entendido',
				color: '#ffffff',
				background: '#010f4e',
			  });
			  */
			  resolve(data);
			}).catch((error) => {
			  console.error('Failed to send notification:', error);
			  reject(error);
			});
		});
	  }
}
