import { Component, OnInit } from '@angular/core';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import bedroomsJson from 'src/assets/plantillas/earth-recamaras.json';
import bathroomJson from 'src/assets/plantillas/earth-baños.json';

@Component({
  selector: 'app-earht3',
  templateUrl: './earht3.component.html',
  styleUrls: ['./earht3.component.scss'],
})
export class Earht3Component implements OnInit {
  public bedroomsEarth: Array<string> = Object.keys(bedroomsJson.Recamaras);
  public bathroomEarth: Array<string> = Object.keys(bathroomJson.Baños);

  //??????????????????????????????????????????????????????????????????????
  numeros: number = 0;
  numeros1: number = 0;
  numeros2: number = 0;
  numeros3: number = 0;
  numeros4: number = 0;
  numeros5: number = 0;
  numeros6: number = 0;
  numeros7: number = 0;
  numeros8: number = 0;
  numeros9: number = 0;
  numeros10: number = 0;
  numeros11: number = 0;
  numeros12: number = 0;

  constructor(public formservice: FormServiceService) {}

  ngOnInit() {}
  selectBedrooms(event) {
    this.formservice.earthpublication.bedrooms = event.target.value;
  }
  selectBathrooms(event) {
    this.formservice.earthpublication.bathroom = event.target.value;
  }

  // funciones anteriores
  decremento() {
    this.numeros--;
  }
  decremento1() {
    this.numeros1--;
    if ((this.numeros1 = 0)) {
    }
  }
  decremento2() {
    this.numeros2--;
    if ((this.numeros2 = 0)) {
    }
  }
  decremento3() {
    this.numeros3--;
    if ((this.numeros3 = 0)) {
    }
  }
  decremento4() {
    this.numeros4--;
    if ((this.numeros4 = 0)) {
    }
  }
  decremento5() {
    this.numeros5--;
    if ((this.numeros5 = 0)) {
    }
  }
  decremento6() {
    this.numeros6--;
    if ((this.numeros6 = 0)) {
    }
  }
  decremento7() {
    this.numeros7--;
    if ((this.numeros7 = 0)) {
    }
  }
  decremento8() {
    this.numeros8--;
    if ((this.numeros8 = 0)) {
    }
  }
  decremento9() {
    this.numeros9--;
    if ((this.numeros9 = 0)) {
    }
  }
  decremento10() {
    this.numeros10--;
    if ((this.numeros10 = 0)) {
    }
  }
  decremento11() {
    this.numeros11--;
    if ((this.numeros11 = 0)) {
    }
  }
  decremento12() {
    this.numeros12--;
    if ((this.numeros12 = 0)) {
    }
  }

  contador() {
    this.formservice.earth3 =true;
    this.numeros++;

    this.formservice.earthpublication.king_bed = this.numeros;
  }
  contador1() {
    this.formservice.earth3 =true;
    this.numeros1++;

    this.formservice.earthpublication.queen_bed = this.numeros1;
  }
  contador2() {
    this.formservice.earth3 =true;
    this.numeros2++;

    this.formservice.earthpublication.matrimonial_bed = this.numeros2;
  }
  contador3() {
    this.formservice.earth3 =true;
    this.numeros3++;

    this.formservice.earthpublication.individual_bed = this.numeros3;
  }
  contador4() {
    this.formservice.earth3 =true;
    this.numeros4++;

    this.formservice.earthpublication.sofa_bed = this.numeros4;
  }
  contador5() {
    this.formservice.earth3 =true;
    this.numeros5++;

    this.formservice.earthpublication.sofa = this.numeros5;
  }
  contador6() {
    this.formservice.earth3 =true;
    this.numeros6++;

    this.formservice.earthpublication.inflatable_mattress = this.numeros6;
  }
  contador7() {
    this.formservice.earth3 =true;
    this.numeros7++;

    this.formservice.earthpublication.litera = this.numeros7;
  }
  contador8() {
    this.formservice.earth3 =true;
    this.numeros8++;

    this.formservice.earthpublication.mattress_on_the_floor = this.numeros8;
  }
  contador9() {
    this.formservice.earth3 =true;
    this.numeros9++;

    this.formservice.earthpublication.bed_for_children = this.numeros9;
  }
  contador10() {
    this.formservice.earth3 =true;
    this.numeros10++;

    this.formservice.earthpublication.crib = this.numeros10;
  }
  contador11() {
    this.formservice.earth3 =true;
    this.numeros11++;

    this.formservice.earthpublication.water_bed = this.numeros11;
  }
  contador12() {
    this.formservice.earth3 =true;
    this.numeros12++;

    this.formservice.earthpublication.hamaca = this.numeros12;
  }
}
