import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import { LkfilmPublication } from 'src/app/Models/Classes/Publications/Lkfilm/lkfilm-publication';
import {
  AlertController,
  ModalController,
  NavController,
} from '@ionic/angular';
import Swal2 from 'sweetalert2';
import {CreatePublicationPage} from "../../../../../pages/create-publication/create-publication.page";

@Component({
  selector: 'app-principal-lkfilm',
  templateUrl: './principal-lkfilm.component.html',
  styleUrls: ['./principal-lkfilm.component.scss'],
})
export class PrincipalLkfilmComponent implements OnInit {
  public step: number = 1;
  public buttonSee: string = 'ver';
  constructor(
    public formservice: FormServiceService,
    public modalController: ModalController,
    public navCtrl: NavController,
    public alertController: AlertController,
    public createPubPage: CreatePublicationPage
  ) {
    this.formservice.lkpublication.title = this.formservice.title;
    this.formservice.lkpublication.description = this.formservice.description;
    this.formservice.lkpublication.publication_type =
      this.formservice.publication_type;
    this.formservice.lkpublication.quienpuedever =
      this.formservice.quienpuedever;
      this.formservice.lkpublication.ubicacion = this.formservice.ubicacion;
    this.formservice.lkpublication.is_feed = this.formservice.is_feed;
    this.formservice.lkpublication.multimedia = this.formservice.multimedia;
  }

  ngOnInit() {}

  //Funcion para el boton de siguiente
  next() {
    switch (this.step < 3) {
      case this.step === 1:
        if (this.formservice.lkpublication.category) {
          this.step++;

        } else {
          Swal2.fire({
            title: 'Advertencia!',
            text: 'Asegurate de elegir una categoria',
            icon: 'warning',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
          });
        }
        break;

      case this.step === 2:
        if (this.formservice.premiumMedia ===true) {
          this.step++;

        } else {
          Swal2.fire({
            title: 'Advertencia!',
            text: 'Ingresa un precio',
            icon: 'warning',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
          });
        }

        break;

      default:
        break;
    }


    // if (this.step < 3) {
    //   this.step++;
    // }
    // if(this.step===3){
    //   this.buttonSee="oculto"
    // }

  }

  //cerrar el modal
  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  //abrir un mensaje de salir de la publicacion
  retorno(){
    if (this.createPubPage.siguiente == true && this.step > 1) {
      this.step--;
    }else {
      this.createPubPage.siguiente = false;
    }
    if (this.step != 3){
      this.buttonSee = 'ver';
    }
  }
}
