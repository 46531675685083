import { Component, OnInit, Input } from '@angular/core';
import { RequestService } from 'src/app/services/request/request.service';
import Swal from 'sweetalert2';
import Swal2 from 'sweetalert2';
import { PopoverController } from '@ionic/angular';
import {FormServiceService} from ".././services/publications/form-service.service";
import { Router } from '@angular/router';
import { ActualizacionServiceService } from 'src/app/services/actualizacion/Actualizacion-service.service';
@Component({
  selector: 'app-playlists',
  templateUrl: './playlists.component.html',
  styleUrls: ['./playlists.component.scss'],
})
export class PlaylistsComponent implements OnInit {
  @Input() añadir_a_lista:boolean=false;
  @Input() pub;
  playlists;
  constructor(	private request: RequestService,
    private popoverController: PopoverController,
    private formService: FormServiceService,
    private router: Router,
    private actualizacionservice:ActualizacionServiceService,
    ) { }

  ngOnInit() {
    this.request.createRequestPost('playlist_controller',{get: true}) .then((data) => {
      console.log(data.success);
      this.playlists=data.success;
    }).catch((error) => {
      console.error('Failed to send request:', error);
    });
  }
  create_playlist(){
    Swal.fire({
      title: 'Create New Playlist',
      input: 'text',
      inputLabel: 'Playlist Name',
      inputPlaceholder: 'Enter the name of the playlist',
      confirmButtonText: 'Create',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      background: '#010f4e', // Customize the background color if needed
      inputValidator: (value) => {
        if (!value) {
          return 'You need to write something!';
        }
        return null;
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const playlistName = result.value;
        console.log('Playlist name:', playlistName);
        // You can now use the playlistName to create a new playlist
        this.request.createRequestPost('playlist_controller',{name: playlistName, create:true}) .then((data) => {
          console.log(data);
          if(data.data){
            Swal2.fire({
              title: 'Playlist creada exitosamente',
              text: 'La playlist se ha creado exitosamente.',
              icon: 'success',
              confirmButtonText: 'Entendido',
              color: '#ffffff',
              background: '#010f4e',
            });
            this.request.createRequestPost('playlist_controller',{get: true}) .then((data) => {
              console.log(data);
              this.playlists=data.success;
           
            }).catch((error) => {
              console.error('Failed to send request:', error);
              this.popoverController.dismiss();
            });
          }
    
        }).catch((error) => {
          console.error('Failed to send request:', error);
        });
      }
    });

   
  }
  async AbrirPlaylist(playlist){
    
    if(this.añadir_a_lista && this.pub && playlist){
      console.log('pub__', this.pub);
      this.request.createRequestPost('playlist_controller',{name:playlist.name, publication_id:this.pub.id}) .then((data) => {
        console.log(data);
        if(data.data){
          Swal2.fire({
            title: 'Añadida exitosamente',
            text: 'La publicación se ha añadida exitosamente a la lista de reproducción.',
            icon: 'success',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
          });
          this.popoverController.dismiss();
        }
      }).catch((error) => {
        console.error('Failed to send request:', error);
        this.popoverController.dismiss();
      });
    }else{
     
      this.formService.actual_playlist=playlist;
      this.router.navigate(['/home/ecosystem-page']);
      const publications_from_server = await this.actualizacionservice.getPlaylistPublications(null, false, null, false, this.formService.actual_playlist);
      const fetchedPublications = publications_from_server || [];
		 
		  this.openComponentVideo(fetchedPublications);
    }
	
  }
  openComponentVideo(fetchedPublications){
		
    this.formService.mostrar = false;
   this.formService.publications=fetchedPublications;
   this.formService.valueSelect='playlist';
    this.formService.mediaTemplate=true;
  
    }
}
