
import { modalController } from '@ionic/core';
import { dismiss } from '@ionic/core/dist/types/utils/overlays';
const video_extensions = [
    "mp4",
    "avi",
    "mkv",
    "mov",
    "flv",
    "wmv",
    "mpg",
];

 const image_extensions = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
];


function isVideo(url: string): boolean {
  let result = false;
  if (url != undefined) {
    video_extensions.forEach((element)=>{
      if (url.indexOf(element) !== -1){
        result = true;
      }
    });
  }
  return result;
}

function isImage(url: string){
  let result = false;
  if (url != undefined) {
    image_extensions.forEach((element)=>{
      if (url.indexOf(element) !== -1){
        result = true;
      }
    });
  }
  return result;
}






export {isVideo,isImage,video_extensions,image_extensions}
