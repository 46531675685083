import { Injectable, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ModalController, ActionSheetController, NavController } from '@ionic/angular';
import { RequestService } from 'src/app/services/request/request.service';
import { ModulesLockComponent } from 'src/app/components/go-ecosystem/modules-lock/modules-lock.component';
import Swal from 'sweetalert2';
import {LoadingController} from "@ionic/angular";
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { GoogleMap, Marker, MapType } from '@capacitor/google-maps';
import { Plugins } from '@capacitor/core';
const { CapacitorGoogleMaps } = Plugins;
import { ActualizacionServiceService } from 'src/app/services/actualizacion/Actualizacion-service.service';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import { Share } from '@capacitor/share';
import { Clipboard } from '@ionic-native/clipboard/ngx';
@Component({
  selector: 'app-Filter-Bar',
  templateUrl: './Filter-Bar.component.html',
  styleUrls: ['./Filter-Bar.component.scss']
})
export class FilterBarComponent implements OnInit, OnDestroy {
  displayContainer:boolean=true;
  private markerObjects: any[] = [];
  public publications: any[] = [];
  @ViewChild('map', { static: false }) mapRef: ElementRef<HTMLElement>;
  newMap: GoogleMap;
  private apiKey: string = 'AIzaSyDotFU2YUDcy3nIlgiv-sEqFSpCFzRZQ-A';
  public available_sections: Array<string> = ["Alfanumerico", "Geografico"];
	public icons_for_sections: Array<string> = [ "language-outline", "earth-outline"];
	public actual_section: string = "Alfanumerico";
  categoriasHistory: Array<any> = [];
  public itemsToShow = 9;
  public loading: any;
  parsedResponse:any;
  @Input() profile_id: number;
  data: any = {};
  DataPublications = [
    {
      Name: 'Normal',
      selected: true
    },
    {
      Name: 'LK Filme Estudio',
      Categorias: [

        {
          categoria: 'Series',
          selected: true,
          id: 165,
          creator_id: 0,
        },
        {
          categoria: 'Películas',
          selected: true,
          id: 1,
          creator_id: 0

        },
        {
          categoria: 'Tutoriales',
          selected: true,
          id: 625,
          creator_id: 0
        },
        {
          categoria: 'Videoblogs',
          selected: true,
          id: 3125,
          creator_id: 0,
        },
        {
          categoria: 'Documentales',
          selected: true,
          id: 2448,
          creator_id: 0,
        },
      ],
      selected: true
    },
    {
      Name: 'Servicios Land',
      Categorias: [

        {
          categoria: 'Alimentos y gastronomía',
          selected: false,
          id: 1,
          creator_id: 0,
        },
        {
          categoria: 'Transporte y movilidad',
          selected: false,
          id: 22,
          creator_id: 0

        },
        {
          categoria: 'Asesoría personal',
          selected: false,
          id: 27,
          creator_id: 0
        },
        {
          categoria: 'Publicidad, diseño o lingüísticos',
          selected: false,
          id: 45,
          creator_id: 0,
        },
        {
          categoria: 'Salud y Sanidad',
          selected: false,
          id: 55,
          creator_id: 0,
        },
        {
          categoria: 'Doméstico, reparaciones y mantenimiento',
          selected: false,
          id: 82,
          creator_id: 0,
        },
        {
          categoria: 'Asesoria empresarial, personal y legal',
          selected: false,
          id: 107,
          creator_id: 0

        },
        {
          categoria: 'Producción audiovisual y entretenimiento',
          selected: false,
          id: 120,
          creator_id: 0
        },
        {
          categoria: 'Hotelería y turismo',
          selected: false,
          id: 128,
          creator_id: 0,
        },
        {
          categoria: 'Telecomunicaciones',
          selected: false,
          id: 133,
          creator_id: 0,
        },
        {
          categoria: 'Ingenierías',
          selected: false,
          id: 136,
          creator_id: 0,
        },
        {
          categoria: 'Bienes y raíces',
          selected: false,
          id: 150,
          creator_id: 0

        },
        {
          categoria: 'Servicios básicos',
          selected: false,
          id: 156,
          creator_id: 0
        },
        {
          categoria: 'Agricultura',
          selected: false,
          id: 161,
          creator_id: 0,
        },
        {
          categoria: 'Construcción',
          selected: false,
          id: 170,
          creator_id: 0,
        },
        {
          categoria: 'Comercio',
          selected: false,
          id: 189,
          creator_id: 0,
        },
        {
          categoria: 'Educación',
          selected: false,
          id: 195,
          creator_id: 0

        },
        {
          categoria: 'Industrias químicas',
          selected: false,
          id: 224,
          creator_id: 0
        },
        {
          categoria: 'Industria financiera',
          selected: false,
          id: 232,
          creator_id: 0,
        },
        {
          categoria: 'Manufactura',
          selected: false,
          id: 242,
          creator_id: 0,
        },
        {
          categoria: 'Medios de comunicación',
          selected: false,
          id: 251,
          creator_id: 0,
        },
        {
          categoria: 'Servicios Convergentes',
          selected: false,
          id: 260,
          creator_id: 0

        },
        {
          categoria: 'Salud Silvicultura: madera, celulosa y papel',
          selected: false,
          id: 271,
          creator_id: 0
        },
        {
          categoria: 'Producción de metales básicos',
          selected: false,
          id: 272,
          creator_id: 0,
        },
        {
          categoria: 'Textiles',
          selected: false,
          id: 273,
          creator_id: 0,
        },
       
      ],
      selected: true,
    },
    {
      Name: 'Eventos Eclipse',
      Categorias: [

        {
          categoria: 'Eventos sociales',
          selected: false,
          id: 1,
          creator_id: 0,
        },
        {
          categoria: 'Eventos de empresa y eventos corporativos',
          selected: false,
          id: 12,
          creator_id: 0

        },
        {
          categoria: 'Eventos con causa y de recaudación de fondos',
          selected: false,
          id: 36,
          creator_id: 0
        },
        {
          categoria: 'Espectáculos y eventos de ocio',
          selected: false,
          id: 44,
          creator_id: 0,
        },
        {
          categoria: 'Eventos deportivos',
          selected: false,
          id: 49,
          creator_id: 0,
        },
        {
          categoria: 'Reuniones o convenciones',
          selected: false,
          id: 53,
          creator_id: 0,
        },
      ],
      selected: false
    },
    {
      Name: 'Educación Epoch',
      Categorias: [

        {
          categoria: 'Educación reglada',
          selected: false,
          id: 1,
          creator_id: 0,
        },
        {
          categoria: 'Educación práctica',
          selected: false,
          id: 14553,
          creator_id: 0

        },
      ],
      selected: false
    },
    {
      Name: 'Hospedaje Earth',
      Categorias: [
	
        {
          categoria: 'Departamento',
          selected: false,
          id: 1,
          creator_id: 0,
        },
        {
          categoria: 'Casa',
          selected: false,
          id: 6,
          creator_id: 0
    
        },
        {
          categoria: 'Hotel',
          selected: false,
          id: 26,
          creator_id: 0
        },
        ],
      selected: false
    },
  ];
  FilterUsers = [
    {
      Name: 'Selenitas.',
      selected: false
    },
    {
      Name: 'Indomables.',
      selected: false
    },
    {
      Name: 'Tus admiradores.',
      selected: false
    },
  ];
 
  selectCheck(check) {
    console.log(check);
  
   
  }
  
  selectAllPublications() {
    const areAllPublicationsSelected = this.DataPublications.every(publication => publication.selected);
  
    this.DataPublications.forEach(publication => {
      publication.selected = !areAllPublicationsSelected;
      if (publication.Categorias) {
        publication.Categorias.forEach(category => {
          category.selected = !areAllPublicationsSelected;
        });
      }
    });
  }

  selectAllCategories() {
    console.log('selectAllCategories() function called'); // Debugging message
  
    // Determine if at least one category is unselected
    const hasUnselectedCategory = this.DataPublications.some(publication =>
      publication.selected && publication.Categorias && publication.Categorias.some(category => !category.selected)
    );
  
    console.log('hasUnselectedCategory:', hasUnselectedCategory); // Debugging message
  
    // Set the 'selected' attribute of all categories based on the presence of unselected categories
    this.DataPublications.forEach(publication => {
      if (publication.selected && publication.Categorias) {
        publication.Categorias.forEach(category => category.selected = hasUnselectedCategory);
      }
    });
  }
  

  constructor(private modalController: ModalController,
    private requestService: RequestService,
    private loadingController:LoadingController,
    private router:Router,
    private actualizacionservice:ActualizacionServiceService,
    private formService:FormServiceService,
    public actionSheetController: ActionSheetController,
    private navCtrl: NavController,
    private clipboard: Clipboard,
    ) { }

  ngOnInit() { console.log('filter', this.profile_id);this.getDataFromBackend();  

}



  async emitChange(event){
    //this.optionChange.emit(event.detail.value);
    this.actual_section = event.detail.value;
    setTimeout(() => {
      this.createMap();
    }, 100); 
    if (this.actual_section === 'Alfanumerico'){
     this.DestroyMap();
    }
   
  
  }
  async DestroyMap(){

    const result = await CapacitorGoogleMaps.hide();
    // Set the map reference to null
    this.newMap = undefined;

      //const mapElement = document.getElementById('map');
      //if (mapElement && mapElement.parentNode) {
    //    mapElement.parentNode.removeChild(mapElement);
     // }
    
  }
  ngOnDestroy(): void {
this.DestroyMap();
  }
  async onClose() {
   this.DestroyMap();
    await this.modalController.dismiss();

  }

  async showLoading() {
    this.loading = await this.loadingController.create({
      message: '⚡️⚡️⚡️',
      spinner: 'circles'

    });
    this.loading.present();
  }
  async loadMoreData(event: any) {
    setTimeout(() => {
      this.itemsToShow += 9;
      event.target.complete();
    }, 500);
  }  
  async onClick() {
    console.log('onClick() function called'); // Debugging message
  
    // Check if there are no categories selected
    const hasNoSelectedCategories = this.DataPublications.every(publication => {
      if (publication.Categorias) {
        return publication.Categorias.every(category => !category.selected);
      }
      return true;
    });
  
    if (hasNoSelectedCategories) {
      Swal.fire('Error', 'Porfavor selecciona al menos una categoría.', 'error');
      return;
    }
  
    await this.showLoading();
  
    if (this.DataPublications[1].selected ===true){
    const getCategorias = async () => {
      console.log('getCategorias() function called'); // Debugging message
  
      // Step 1: Get the current state of the 'Categorias' array
      const categorias = this.DataPublications[1].Categorias;
      
  
      // Step 2: Filter the 'Categorias' array to retrieve only the selected objects
      const selectedCategorias = categorias.filter(categoria => categoria.selected);
  
      console.log('selectedCategorias:', selectedCategorias); // Debugging message
  
      // Step 3: Extract the 'id' values from the filtered objects
      const selectedIds = selectedCategorias.map(cat => cat.id);

      const endpoint = 'categories';
      const queryParams = { id: selectedIds.join(',') }; // Send the 'id's as a comma-separated string
      try {
        const data = await this.requestService.createRequestGet(endpoint, queryParams);
        console.log('data:', data); // Debugging message
  
        // Add JSON parsing and logging
        console.log("Raw JSON data:", data);
        let parsedData;
     
        try {
          parsedData = JSON.parse(data);
          console.log("Parsed JSON data:", parsedData);
        } catch (error) {
          console.error("Error parsing JSON data:", error);
        }  
 
//step 4: map th data
        const newPublication = {
          Categorias: parsedData.map(categoria => ({
            ...categoria,
            selected: true,
          })),
        };
  
        // Step 5: Extract the 'categoria', 'id', and 'creator_id' values from the returned objects
        if (Array.isArray(parsedData) && parsedData.every(item => typeof item === 'object' && 'categoria' in item && 'id' in item && 'creator_id' in item)) {
          const newCategorias = parsedData.map(categoria => ({
            categoria: categoria.categoria,
            id: categoria.id,
            creator_id: categoria.creator_id,
            selected: true,
          }));
        
          console.log('newCategorias:', newCategorias); // Debugging message
          if(newCategorias.length ===0){
            this.displayContainer=false;
            }
          // Replace the 'Categorias' array with the new one
          this.categoriasHistory.push(this.DataPublications[1].Categorias);
          this.DataPublications[1].Categorias = newCategorias;
           // Select or unselect checkboxes based on "categorias" data
          const categoriasItems = this.data.categorias;
          if (categoriasItems){
          this.DataPublications[1].Categorias.forEach((checklist) => {
            checklist.selected = categoriasItems.includes(checklist.categoria);
          });
        }
        } else {
          console.error('Unexpected data format:', data);
        }

      
           // Hide the loading spinner when the operation is complete
         
     
      } catch (error) {
        console.error('Error fetching categories:', {
          message: error.message,
          name: error.name,
          stack: error.stack,
          errorObject: error,
        });
      }
    };
  
    await getCategorias(); // Invoke the function
    }
    if (this.DataPublications[2].selected ===true){
    this.getLandCategories();
    }
    if (this.DataPublications[3].selected ===true){
    this.getEclipseCategories();
    }
    if (this.DataPublications[4].selected ===true){
    this.getEpochCategories();
    }
    if (this.DataPublications[5].selected ===true){
    this.getEarthCategories();
    }
    this.loading.dismiss();
  }
  
  async getLandCategories(){
    const getCategorias = async () => {
      console.log('getCategorias() function called'); // Debugging message
  
      // Step 1: Get the current state of the 'Categorias' array
      const categorias = this.DataPublications[2].Categorias;
      
  
      // Step 2: Filter the 'Categorias' array to retrieve only the selected objects
      const selectedCategorias = categorias.filter(categoria => categoria.selected);
  
      console.log('selectedCategorias:', selectedCategorias); // Debugging message
  
      // Step 3: Extract the 'id' values from the filtered objects
      const selectedIds = selectedCategorias.map(cat => cat.id);

      const endpoint = 'categoriesLand';
      const queryParams = { id: selectedIds.join(',') }; // Send the 'id's as a comma-separated string
      try {
        const data = await this.requestService.createRequestGet(endpoint, queryParams);
        console.log('data:', data); // Debugging message
  
        // Add JSON parsing and logging
        console.log("Raw JSON data:", data);
        let parsedData;
     
        try {
          parsedData = JSON.parse(data);
          console.log("Parsed JSON data:", parsedData);
        } catch (error) {
          console.error("Error parsing JSON data:", error);
        }  
 
//step 4: map th data
        const newPublication = {
          Categorias: parsedData.map(categoria => ({
            ...categoria,
            selected: true,
          })),
        };
  
        // Step 5: Extract the 'categoria', 'id', and 'creator_id' values from the returned objects
        if (Array.isArray(parsedData) && parsedData.every(item => typeof item === 'object' && 'categoria' in item && 'id' in item && 'creator_id' in item)) {
          const newCategorias = parsedData.map(categoria => ({
            categoria: categoria.categoria,
            id: categoria.id,
            creator_id: categoria.creator_id,
            selected: true,
          }));
        
          console.log('newCategorias:', newCategorias); // Debugging message
        
          // Replace the 'Categorias' array with the new one
          this.categoriasHistory.push(this.DataPublications[2].Categorias);
          this.DataPublications[2].Categorias = newCategorias;
           // Select or unselect checkboxes based on "categorias" data
          const categoriasItems = this.data.categorias;
          if (categoriasItems){
          this.DataPublications[2].Categorias.forEach((checklist) => {
            checklist.selected = categoriasItems.includes(checklist.categoria);
          });
        }
        } else {
          console.error('Unexpected data format:', data);
        }
  
      
      } catch (error) {
        console.error('Error fetching categories:', {
          message: error.message,
          name: error.name,
          stack: error.stack,
          errorObject: error,
        });
      }
    };
  
    await getCategorias(); // Invoke the function
  }
  
  async getEclipseCategories(){
    const getCategorias = async () => {
      console.log('getCategorias() function called'); // Debugging message
  
      // Step 1: Get the current state of the 'Categorias' array
      const categorias = this.DataPublications[3].Categorias;
      
  
      // Step 2: Filter the 'Categorias' array to retrieve only the selected objects
      const selectedCategorias = categorias.filter(categoria => categoria.selected);
  
      console.log('selectedCategorias:', selectedCategorias); // Debugging message
  
      // Step 3: Extract the 'id' values from the filtered objects
      const selectedIds = selectedCategorias.map(cat => cat.id);

      const endpoint = 'categoriesEclipse';
      const queryParams = { id: selectedIds.join(',') }; // Send the 'id's as a comma-separated string
      try {
        const data = await this.requestService.createRequestGet(endpoint, queryParams);
        console.log('data:', data); // Debugging message
  
        // Add JSON parsing and logging
        console.log("Raw JSON data:", data);
        let parsedData;
     
        try {
          parsedData = JSON.parse(data);
          console.log("Parsed JSON data:", parsedData);
        } catch (error) {
          console.error("Error parsing JSON data:", error);
        }  
 
//step 4: map th data
        const newPublication = {
          Categorias: parsedData.map(categoria => ({
            ...categoria,
            selected: true,
          })),
        };
  
        // Step 5: Extract the 'categoria', 'id', and 'creator_id' values from the returned objects
        if (Array.isArray(parsedData) && parsedData.every(item => typeof item === 'object' && 'categoria' in item && 'id' in item && 'creator_id' in item)) {
          const newCategorias = parsedData.map(categoria => ({
            categoria: categoria.categoria,
            id: categoria.id,
            creator_id: categoria.creator_id,
            selected: true,
          }));
        
          console.log('newCategorias:', newCategorias); // Debugging message
        
          // Replace the 'Categorias' array with the new one
          this.categoriasHistory.push(this.DataPublications[3].Categorias);
          this.DataPublications[3].Categorias = newCategorias;
           // Select or unselect checkboxes based on "categorias" data
           
          const categoriasItems = this.data.categorias;
          if (categoriasItems){
          this.DataPublications[3].Categorias.forEach((checklist) => {
            checklist.selected = categoriasItems.includes(checklist.categoria);
          });
        }
        } else {
          console.error('Unexpected data format:', data);
        }
  
      
      } catch (error) {
        console.error('Error fetching categories:', {
          message: error.message,
          name: error.name,
          stack: error.stack,
          errorObject: error,
        });
      }
    };
  
    await getCategorias(); // Invoke the function
  }
  async getEpochCategories(){
    const getCategorias = async () => {
      console.log('getCategorias() function called'); // Debugging message
  
      // Step 1: Get the current state of the 'Categorias' array
      const categorias = this.DataPublications[4].Categorias;
      
  
      // Step 2: Filter the 'Categorias' array to retrieve only the selected objects
      const selectedCategorias = categorias.filter(categoria => categoria.selected);
  
      console.log('selectedCategorias:', selectedCategorias); // Debugging message
  
      // Step 3: Extract the 'id' values from the filtered objects
      const selectedIds = selectedCategorias.map(cat => cat.id);

      const endpoint = 'categoriesEpoch';
      const queryParams = { id: selectedIds.join(',') }; // Send the 'id's as a comma-separated string
      try {
        const data = await this.requestService.createRequestGet(endpoint, queryParams);
        console.log('data:', data); // Debugging message
  
        // Add JSON parsing and logging
        console.log("Raw JSON data:", data);
        let parsedData;
     
        try {
          parsedData = JSON.parse(data);
          console.log("Parsed JSON data:", parsedData);
        } catch (error) {
          console.error("Error parsing JSON data:", error);
        }  
 
//step 4: map th data
        const newPublication = {
          Categorias: parsedData.map(categoria => ({
            ...categoria,
            selected: true,
          })),
        };
  
        // Step 5: Extract the 'categoria', 'id', and 'creator_id' values from the returned objects
        if (Array.isArray(parsedData) && parsedData.every(item => typeof item === 'object' && 'categoria' in item && 'id' in item && 'creator_id' in item)) {
          const newCategorias = parsedData.map(categoria => ({
            categoria: categoria.categoria,
            id: categoria.id,
            creator_id: categoria.creator_id,
            selected: true,
          }));
        
          console.log('newCategorias:', newCategorias); // Debugging message
        
          // Replace the 'Categorias' array with the new one
          this.categoriasHistory.push(this.DataPublications[4].Categorias);
          this.DataPublications[4].Categorias = newCategorias;
           // Select or unselect checkboxes based on "categorias" data
          const categoriasItems = this.data.categorias;
          if (categoriasItems){
          this.DataPublications[4].Categorias.forEach((checklist) => {
            checklist.selected = categoriasItems.includes(checklist.categoria);
          });
        }
        } else {
          console.error('Unexpected data format:', data);
        }
  
      
      } catch (error) {
        console.error('Error fetching categories:', {
          message: error.message,
          name: error.name,
          stack: error.stack,
          errorObject: error,
        });
      }
    };
  
    await getCategorias(); // Invoke the function
  }
  async getEarthCategories(){
    const getCategorias = async () => {
      console.log('getCategorias() function called'); // Debugging message
  
      // Step 1: Get the current state of the 'Categorias' array
      const categorias = this.DataPublications[5].Categorias;
      
  
      // Step 2: Filter the 'Categorias' array to retrieve only the selected objects
      const selectedCategorias = categorias.filter(categoria => categoria.selected);
  
      console.log('selectedCategorias:', selectedCategorias); // Debugging message
  
      // Step 3: Extract the 'id' values from the filtered objects
      const selectedIds = selectedCategorias.map(cat => cat.id);

      const endpoint = 'categoriesEarth';
      const queryParams = { id: selectedIds.join(',') }; // Send the 'id's as a comma-separated string
      try {
        const data = await this.requestService.createRequestGet(endpoint, queryParams);
        console.log('data:', data); // Debugging message
  
        // Add JSON parsing and logging
        console.log("Raw JSON data:", data);
        let parsedData;
     
        try {
          parsedData = JSON.parse(data);
          console.log("Parsed JSON data:", parsedData);
        } catch (error) {
          console.error("Error parsing JSON data:", error);
        }  
 
//step 4: map th data
        const newPublication = {
          Categorias: parsedData.map(categoria => ({
            ...categoria,
            selected: true,
          })),
        };
  
        // Step 5: Extract the 'categoria', 'id', and 'creator_id' values from the returned objects
        if (Array.isArray(parsedData) && parsedData.every(item => typeof item === 'object' && 'categoria' in item && 'id' in item && 'creator_id' in item)) {
          const newCategorias = parsedData.map(categoria => ({
            categoria: categoria.categoria,
            id: categoria.id,
            creator_id: categoria.creator_id,
            selected: true,
          }));
        
          console.log('newCategorias:', newCategorias); // Debugging message
        
          // Replace the 'Categorias' array with the new one
          this.categoriasHistory.push(this.DataPublications[5].Categorias);
          this.DataPublications[5].Categorias = newCategorias;
           // Select or unselect checkboxes based on "categorias" data
         
          const categoriasItems = this.data.categorias;
          if (categoriasItems){
          this.DataPublications[5].Categorias.forEach((checklist) => {
            checklist.selected = categoriasItems.includes(checklist.categoria);
          });
        }
        } else {
          console.error('Unexpected data format:', data);
        }
  
      
      } catch (error) {
        console.error('Error fetching categories:', {
          message: error.message,
          name: error.name,
          stack: error.stack,
          errorObject: error,
        });
      }
    };
  
    await getCategorias(); // Invoke the function
  }
  onClickBack() {
    if (this.categoriasHistory.length > 0) {
      this.DataPublications[1].Categorias = this.categoriasHistory.pop();
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'No hay historial',
        text: 'No se puede retroceder más.',
      });
    }
  }
  
   
  async onDepurar() {
    const selectedPublications = this.DataPublications.filter(publication => publication.selected);
  
    // Check if no publication is selected
    if (selectedPublications.length === 0) {
      Swal.fire('Error', 'Por favor selecciona alguna publicación.', 'error');
      return;
    }
  
    const selectedCategories = this.DataPublications[1].Categorias
      .filter(category => category.selected)
      .map(category => category.categoria);
  
    const precioDesde = parseFloat(this.data.precioDesde) || 0;
    const precioHasta = parseFloat(this.data.precioHasta) || 0;
  
    // Check if precio_desde is greater than precio_hasta
    if (precioDesde > precioHasta) {
      Swal.fire('Error', 'Por favor selecciona precios razonables.', 'error');
      return;
    }
    const tiempoDesde = parseFloat(this.data.fechaInicio + ' ' + this.data.horaInicio) || 0;
    const tiempoHasta = parseFloat(this.data.fechaFin + ' ' + this.data.horaFin) || 0;
    if (tiempoDesde > tiempoHasta) {
      Swal.fire('Error', 'Por favor selecciona fechas razonables.', 'error');
      return;
    }
    const flattenedCategories = this.categoriasHistory.reduce((acc, categorias) => {
      const selectedCategories = categorias.filter(categoria => categoria.selected);
      const categoryNames = selectedCategories.map(categoria => categoria.categoria);
      return [...acc, ...categoryNames];
    }, []);
  
    const requestData = {
      'Titulo/Description': this.data.buscar || '',
      'publicacion': selectedPublications.map(publication => publication.Name),
      'categorias':[...selectedCategories, ...flattenedCategories],
      'fecha_hora_publicacion_inicio': this.data.fechaInicio + ' ' + this.data.horaInicio,
      'fecha_hora_publicacion_fin': this.data.fechaFin + ' ' + this.data.horaFin,
      'precio_desde': precioDesde,
      'precio_hasta': precioHasta,
      'ubicacion': this.data.paises || '',
      'profile_id': this.profile_id,
    };
  
    try {
      await this.requestService.createRequestPost('saveAIData', requestData);
      await this.modalController.dismiss();
      Swal.fire('Success', 'Nuevos datos de algoritmo artificial inteligente.', 'success');
  
      this.route(); // Reload the page after dismissing the modal
    } catch (error) {
      console.error(error);
      Swal.fire('Error', 'Un error ocurrió al guardar los datos', 'error');
    }
  }
  
  route() {
		// Store current URL
		let currentUrl = this.router.url;
	  
		// Navigate to a temporary route
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
		  // Navigate back to the current URL
		  this.router.navigate([currentUrl]);
		});
	  }
	  
  async getDataFromBackend() {
    if (!this.formService.profile){
      
      Swal.fire({
        title: 'Debes iniciar sesión',
        text: 'Porfavor inicia sesión para comenzar a entrelazar con algún usuario.',
        icon: 'error',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      }).then(() => {
        return;
        console.log("Alert displayed successfully.");
      }).catch((error) => {
        console.error("Error displaying alert:", error);
      });
      return;
    }
    if (Capacitor.getPlatform() !== 'web'){
    try {
      const response = await this.requestService.createRequestGet("getAIData", null, { id: this.profile_id });
      console.log('Received response from backend:', response);
  
      if (response.length === 0) {
        console.log('Response is empty.');
        Swal.fire('Info', 'No tienes un algoritmo artificial inteligente todavía', 'info');
        return;
      }
  
      const parsedResponse = JSON.parse(response);
      console.log('Parsed response:', parsedResponse);
  
      const firstItem = parsedResponse[0];
      const fechaInicioParts = firstItem['fecha_hora_publicacion_inicio'].split(' ');
      const fechaFinParts = firstItem['fecha_hora_publicacion_fin'].split(' ');
  
      this.data = {
        ...firstItem,
        precioDesde: firstItem['precio_desde'],
        precioHasta: firstItem['precio_hasta'],
        paises: firstItem['ubicacion'].split(', '),
        fechaInicio: new Date(fechaInicioParts[0]).toISOString(),
        fechaFin: new Date(fechaFinParts[0]).toISOString(),
        horaInicio: new Date('1970-01-01T' + fechaInicioParts[1]).toISOString(),
        horaFin: new Date('1970-01-01T' + fechaFinParts[1]).toISOString(),
      };
  
      this.data.buscar = firstItem['Titulo/Description'];
  
      console.log('Data assigned:', this.data);
  
      // Select or unselect checkboxes based on "publicacion" data
      const publicacionItems = this.data.publicacion.split(', ');
      this.DataPublications.forEach((check) => {
        check.selected = publicacionItems.includes(check.Name);
      });
      // Select or unselect checkboxes based on "categorias" data
const categoriasItems = this.data.categorias;
this.DataPublications[1].Categorias.forEach((checklist) => {
  checklist.selected = categoriasItems.includes(checklist.categoria);
});

  
      Swal.fire('Success', 'Algoritmo inteligente artificial recuperado', 'success');
    } catch (error) {
      console.log('Error retrieving data from the backend:', error);
      // Swal.fire('Error', 'Error al solicitar tu algoritmo inteligente artificial', 'error');
    }
  }
  else{
    try {
      const response = await this.requestService.createWebRequestGet("getAIData", null, { id: this.profile_id });
      console.log('Received response from backend:', response);
  
      if (response.length === 0) {
        console.log('Response is empty.');
        Swal.fire('Info', 'No tienes un algoritmo artificial inteligente todavía', 'info');
        return;
      }

      if (typeof response === 'string') {
        this.parsedResponse = JSON.parse(response);
           console.log('json result publication service.ts', response);
         
         }else{
          this.parsedResponse = response;
         }
  
      const parsedResponse = this.parsedResponse;
      console.log('Parsed response:', parsedResponse);
  
      const firstItem = this.parsedResponse?.[0];
      const fechaInicioParts = firstItem['fecha_hora_publicacion_inicio']?.split(' ');
      const fechaFinParts = firstItem['fecha_hora_publicacion_fin']?.split(' ');
  
      this.data = {
        ...firstItem,
        precioDesde: firstItem['precio_desde'],
        precioHasta: firstItem['precio_hasta'],
        paises: firstItem['ubicacion']?.split(', '),
      };
    
      if (fechaInicioParts && fechaInicioParts.length > 0) {
        this.data.fechaInicio = new Date(fechaInicioParts[0]).toISOString();
        this.data.horaInicio = new Date('1970-01-01T' + fechaInicioParts[1]).toISOString();
      } else {
        // Set default values for fechaInicio and horaInicio if fechaInicioParts is undefined or empty
        this.data.fechaInicio = ''; // Set a default value for fechaInicio
        this.data.horaInicio = ''; // Set a default value for horaInicio
        // OR show an error message to the user
        // Swal.fire('Error', 'Fecha de inicio inválida', 'error');
      }
    
      if (fechaFinParts && fechaFinParts.length > 0) {
        this.data.fechaFin = new Date(fechaFinParts[0]).toISOString();
        this.data.horaFin = new Date('1970-01-01T' + fechaFinParts[1]).toISOString();
      } else {
        // Set default values for fechaFin and horaFin if fechaFinParts is undefined or empty
        this.data.fechaFin = ''; // Set a default value for fechaFin
        this.data.horaFin = ''; // Set a default value for horaFin
        // OR show an error message to the user
        // Swal.fire('Error', 'Fecha de fin inválida', 'error');
      }
    
  
      this.data.buscar = firstItem['Titulo/Description'];
  
      console.log('Data assigned:', this.data);
  
      // Select or unselect checkboxes based on "publicacion" data
      const publicacionItems = this.data.publicacion?.split(', ');
      this.DataPublications.forEach((check) => {
        check.selected = publicacionItems?.includes(check.Name);
      });
      // Select or unselect checkboxes based on "categorias" data
const categoriasItems = this.data.categorias;
this.DataPublications[1].Categorias.forEach((checklist) => {
  checklist.selected = categoriasItems?.includes(checklist.categoria);
});

  
      Swal.fire('Success', 'Algoritmo inteligente artificial recuperado', 'success');
    } catch (error) {
      console.log('Error retrieving data from the backend:', error);
      // Swal.fire('Error', 'Error al solicitar tu algoritmo inteligente artificial', 'error');
    }
  }

  }

  async getUniversalLinkPublications(publicationId: number) {
    console.log('pub_id',publicationId);
  try {
    const result = await this.actualizacionservice.getUniversalLinkPublications(
    null,
    false,
    publicationId,
    null,
    null
    );
    const fetchedPublications = result || [];
    this.publications = fetchedPublications;
    this.openComponentVideo(fetchedPublications);
  } catch (error) {
    console.error(error);
  }
  
  }
  openComponentVideo(fetchedPublications){
		
		this.formService.mostrar = false;
	 this.formService.publications=fetchedPublications;
	  this.formService.valueSelect= 'MENU';
	  this.formService.mediaTemplate=true;
	
	  }

  async createMap() {

    const apiKey = this.apiKey;

    //const mapRef = document.getElementById('map');
    const mapRefElement = this.mapRef.nativeElement;
     this.newMap = await GoogleMap.create({
      id: 'magnanimous-map', // Unique identifier for this map instance
      element: mapRefElement, // reference to the capacitor-google-map element
      apiKey: apiKey, // Your Google Maps API Key
      config: {
        center: {
          // The initial position to be rendered by the map
          lat:  23.634501,
          lng: -102.552784
        },
        zoom: 3, // The initial zoom level to be rendered by the map
      },
    });


      // Move the map programmatically
  await this.newMap.setCamera({
    coordinate: {
      lat: 23.634501, // You can set the map center here if needed
      lng: -102.552784
    }
  });
await this.getMapData();



const mapType = 'hybrid'; // Specify the map type as 'hybrid'
  const result =  await CapacitorGoogleMaps.setMapType({ type: mapType });
    
      //this.getMapData();

      CapacitorGoogleMaps.addListener('didTapInfoWindowOf', (data: any) => {
        // Data contains information about the clicked marker
        const markerData = data.result;
        console.log('Marker clicked:', markerData);
      
        // Call your custom method to handle the marker click
        this.actual_section = 'Alfanumerico';
        this.onMarkerClick(markerData);
        
        this.DestroyMap();
      });
      CapacitorGoogleMaps.addListener('didLongPressInfoWindowOf', (data: any) => {
        // Data contains information about the clicked marker
        const markerData = data.result;
        console.log('Marker clicked:', markerData);
      
        // Call your custom method to handle the marker click
        this.actual_section = 'Alfanumerico';
        this.onMarkerClick(markerData);
      
        this.DestroyMap();
      });

CapacitorGoogleMaps.setTrafficEnabled();

   
  }

  
  

onMarkerClick(markerData: any) {
  // Extract the information you need from the markerData object
  const markerId = markerData.id;
  const coordinates = markerData.coordinates;
  const title = markerData.title;
  const snippet = markerData.snippet;
  const metadata = markerData.metadata;
  console.log('markerId', markerId);
  console.log('coordinates', coordinates);
  console.log('title', title);
  console.log('snippet', snippet);
  console.log('metadata', metadata);

this.presentActionSheet(markerData);
  // Do whatever you want to do when a marker is clicked
  // For example:
  
}

  
  async getMapData() {
    const responseJson = await this.requestService.createRequestGet("GetSuperclusterMap", null, { id: this.profile_id });
    const response = JSON.parse(responseJson);
  
    console.log('Received response from backend:', response);
  
    if (!Array.isArray(response) || response.length === 0) {
      console.log('Map Response is empty.');
      return;
    }
  
    // Mapping of country names to their coordinates
    const countryCoordinates = {
      "México": { lat: 23.634501, lng: -102.552784 },
      "Argentina": { lat: -38.416097, lng: -63.61667199999999 },
      "Brasil": { lat: -14.235004, lng: -51.92528 },
      "Chile": { lat: -35.675147, lng: -71.542969 },
      "Colombia": { lat: 4.570868, lng: -74.297333 },
      "Costa Rica": { lat: 9.748916999999999, lng: -83.753428 },
      "Ecuador": { lat: -1.831239, lng: -78.18340599999999 },
      "El Salvador": { lat: 1.0468089, lng: -81.3914138 },
      "Guatemala": { lat: 15.783471, lng: -90.23075899999999 },
      "Panamá": { lat: 8.537981, lng: -80.782127 },
      "Perú": { lat: -9.189967, lng: -75.015152 },
      "Venezuela": { lat: 6.42375, lng: -66.58973 },
      // Add more countries and their coordinates here
    };
  
    // Extract 'ubicacion' values from the response
    const ubicaciones = response.map(item => decodeURIComponent(item.container.ubicacion)).filter(Boolean);
    console.log('ubicaciones', ubicaciones);
  
  // Convert 'ubicacion' values into coordinates array for Google Maps
  const coordinatesArray = [];

  
    for (const ubicacion of ubicaciones) {
      try {
        const coordinates = countryCoordinates[ubicacion];
        if (coordinates) {
          coordinatesArray.push(coordinates);
        //  console.log('array', coordinatesArray);
        //  console.log(`⚡️  [log] - Found coordinates for ${ubicacion}: ${coordinates.lat}, ${coordinates.lng}`);
        } else {
          console.log(`⚡️  [log] - Coordinates not available for ${ubicacion}`);
        }
      } catch (error) {
        console.error('Error while geocoding:', error);
      }
    }
  
    console.log('Coordinates Array:', coordinatesArray);
  
  // Create and add markers to the map
  const markers: any[] = coordinatesArray.map((coordinates, index) => {
    const item = response[index];
    //const title = item.container.title + (item.container.Verified ? ' - Verified' : '');
    const title = item.container.title ;
    const snippet =  item.container.description;
    const media = item.container.multimediaContainer.url;
    const ID = item.container.id;
//console.log('i', ID);
    
   // const snippet = `Description: ${item.container.description} | Multimedia Count: ${item.container.multimediaCounter}`;
    return {
      position: coordinates,
      title: title,
      snippet: snippet,
      media: media,
      ID : ID
    };
  });
  
 // Loop through the markers array and add markers one by one
 for (const marker of markers) {
  const addedMarker = await CapacitorGoogleMaps.addMarker({
    latitude: marker.position.lat,
    longitude: marker.position.lng,
    title: marker.title,
    snippet: marker.snippet,
    media: marker.media,
    Publication_id: marker.ID
  });

  this.markerObjects.push(addedMarker);
}

   
    
  }
  
  async presentActionSheet(pub){
    Swal.fire({
			title: '',
			text: '',
			icon: 'success',
			timer: 9999, // Show the alert for 45 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-start',
			showConfirmButton: false
		  });
    setTimeout(() => {
      this.actionSheetController.dismiss();
      }, 9999); 
  const actionSheet = await this.actionSheetController.create({
    header:  pub.title,
    cssClass: 'title-action',
    buttons: [{
      text: 'Ir a la publicación',
      icon: 'telescope-outline',
      cssClass: 'icon',
      handler: () => {
   
this.onClose();
  this.getUniversalLinkPublications(pub.metadata);

      }
    } , {
      text: 'Copiar enlace de publicación',
      icon: 'link-outline',
      cssClass: 'icon',
      handler: () => {
       
this.click_on_share(pub);
this.AugmentSharesCounter(pub);

      }
    }, {
      text: 'Compartir con otras aplicaciones',
      icon: 'analytics-outline',
      cssClass: 'icon',
      handler: () => {
        this.Share(pub);
        this.AugmentSharesCounter(pub);
      }
    }, 

   
  ]
  });
  await actionSheet.present();

  const { role } = await actionSheet.onDidDismiss();
  console.log('onDidDismiss resolved with role', role);

}
Click_On_User(user_id){
  this.navCtrl.navigateForward('home/profile/'+user_id);
}
async Share(pub){
  const publicationId = pub.metadata;
  const url =  `laniakea://supercumulo.laniakea.tv/home/go-ecosystem-page?p=${publicationId}`;
  await Share.share({
    url: url,
  });
}
click_on_share(publication: any) {
  const publicationId = publication.metadata;
  const url =  `laniakea://supercumulo.laniakea.tv/home/go-ecosystem-page?p=${publicationId}`;
  // Copy the URL to clipboard
  this.clipboard.copy(url)
    .then(() => {
      console.log('URL copied to clipboard:', url);
      Swal.fire({
        title: '¡URL copiado!',
        text: 'El URL ha sido copiado en el portapapeles.',
        icon: 'success',
        timer: 2000, // Show the alert for 2 seconds
        timerProgressBar: true,
        toast: true,
        position: 'top-end',
        showConfirmButton: false
      });
    })
    .catch((error) => {
      console.error('Error copiando el URL en el portapapeles:', error);
      Swal.fire({
        title: 'Error',
        text: 'Error copiando el URL en el portapapeles.',
        icon: 'error',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000 // Show the alert for 2 seconds
      });
    });
}
AugmentSharesCounter(pub){
  return new Promise((resolve, reject) => {
 
  
    this.requestService.createRequestPost('AugmentShareCounter', {id: pub.metadata})
    .then((data) => {
      resolve(data);
    }).catch((error) => {
      console.error('Failed to send notification:', error);
      reject(error);
    });
  });

}
SearchUsers(check){
  if (!this.formService.profile){
      
    Swal.fire({
      title: 'Debes iniciar sesión',
      text: 'Porfavor inicia sesión para comenzar a entrelazar con algún usuario.',
      icon: 'error',
      confirmButtonText: 'Entendido',
      color: '#ffffff',
      background: '#010f4e',
    }).then(() => {
      return;
      console.log("Alert displayed successfully.");
    }).catch((error) => {
      console.error("Error displaying alert:", error);
    });
    return;
  }
  console.log(check);

  this.navCtrl.navigateForward('search');
  this.onClose();
}
}





  //{
    //Name:'Land Servicios',
    //selected:false
  //},
  //{
    //Name:'Eventos Eclipse',
    //selected:false
  //},
  //{
    //Name:'Epoch',
    //selected:false
  //},
  //{
    //Name:'Hospedaje',
    //Categorias:[
     // {
       // name:'Todas',
        //select:true
      //},

      //{
        //name:'Hoteles',
        //select:true
      //},
      //{
        //name:'Casas de alojamiento',
        //select:true
      //},
      //{
        //name:'Lugares para acampar',
        //select:true
      //},

  //],
    //selected:false
  //},

//]


  
