import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-inline-bowsing',
  templateUrl: './inline-bowsing.component.html',
  styleUrls: ['./inline-bowsing.component.scss'],
})
export class InlineBowsingComponent implements OnInit {

  @Input() url: any;
  constructor(
    private sanitizer: DomSanitizer,
    private ModalController: ModalController
  ) { }

  ngOnInit() {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }
  dismiss(){
    this.ModalController.dismiss();
  }

}
