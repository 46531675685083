import {Component, Input, OnInit} from '@angular/core';
import {ReconoseridServiceService} from "../../../services/reconoserid/reconoserid-service.service";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import Swal2 from 'sweetalert2';
import {ModalController} from "@ionic/angular";
import {Utils} from "../../../Models/Classes/utils";
import { RequestService } from 'src/app/services/request/request.service';


@Component({
  selector: 'app-reconocerid',
  templateUrl: './reconocerid.component.html',
  styleUrls: ['./reconocerid.component.scss'],
})
export class ReconoceridComponent implements OnInit {
  @Input() webPage:any;
  nombre:string = '';
  curp:string = '';
  rfc:string= '';
  @Input() correo: string = '';
  telefono:string = '';
  iframe:boolean = false;
  urlSafe;
  muestraformulario = false;
  @Input() profile: any;

    constructor(
      private reconoceridservice: ReconoseridServiceService,
      public sanitizer: DomSanitizer,
      private iab: InAppBrowser,
      private modalController: ModalController,
      private utils: Utils,
      private request: RequestService,
  ) { }

  async ngOnInit() {
    if(this.webPage){
let url= await this.open_iframe_validation(this.webPage);
    }else{
    console.log('email reconoser', this.correo);
    console.log("profile object",this.profile);
    
    console.log("estoy en reconocerid ngoninit",this.profile['reconoser_url']);
    
    if (this.profile['reconoser_url'] !== null) {
      this.reconoceridservice.getToken().then((token)=>{
        this.reconoceridservice.solicitaStatus(token,this.profile['reconoser_guid']).then((result)=>{
          // la variable es finalizado. si es false hay que mandar la url para validar con iframe
          console.log(result);
          const aprobado = (result as any)?.data?.aprobado;

          if (aprobado == true) {
            // Display success swalalert
            Swal2.fire({
              title: 'Validación de identidad exitosa, :hourglass_flowing_sand: actualizando tu perfil',
      
              icon: 'success',
              confirmButtonText: 'OK',
              color: '#ffffff',
              background: '#010f4e',
            });
            
            // Make a request to modify the status
            const VerificationData = {
              id: this.profile.id
              // Include the necessary data for modifying the status
            };
  
            this.request.createRequestPost('StripeIdentified', VerificationData)
              .then((response) => {
                
                const ResponseData = JSON.parse(response);
                if (ResponseData.code === 200) {
                  this.modalController.dismiss();
                  // Handle the success response if needed
                } else {
                  Swal2.fire({
                    title: 'Hubó un error al actualizar tu validación de identidad',
            
                    icon: 'error',
                    confirmButtonText: 'OK',
                    color: '#ffffff',
                    background: '#010f4e',
                  });
                  // Handle other response codes if needed
                }
              })
              .catch((error) => {
                // Handle the error if the request fails
                console.log(error);
              });
          }
        }).catch((error) => {
          console.log(error);
        });
      }).catch((error) => {
        console.log(error);
      });
    } else {
      this.muestraformulario = true;
    }
  }
  }

  open_iframe_validation(url){
    this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  dismiss() {
    this.modalController.dismiss(this.profile);
  }

  change_status() {
      if(this.curp.length != 18) {
        this.showError('CURP no válida');
      }else if(this.nombre == '') {
        this.showError('Nombre erroneo');
      }else if(this.rfc.length >= 12 ) {
        this.showError('RFC erroneo');
      }else if(this.telefono == '' || this.telefono.length != 10) {
        this.showError('Telefono erroneo');
      }else if(!(this.validateEmail(this.correo))) {
        this.showError('Correo no valido');
      }else {
        const reconoser_object = {
          'curp': this.curp,
          'rfc': this.rfc
        };
        this.request.createRequestPost('profile/update_reconoserid',reconoser_object).then((data) =>{
          console.log(data);
          if (data.profile) {

        this.utils.loadingPresent().then(() => {
          this.reconoceridservice.getToken().then((token: string) => {
            this.reconoceridservice.solicitaValidacion(token, this.curp, this.correo, this.nombre, this.telefono).then((result)=>{
              console.log(result);
              this.iframe= true;
              this.profile['reconoser_url'] = result['data']['url'];
              this.profile['reconoser_guid'] = result['data']['procesoConvenioGuid'];
              this.open_iframe_validation(result['data']['url']);
              this.utils.loadingDismiss();
            });
          });
        }).catch((error) => {
          console.log(error);
          this.utils.loadingDismiss();
        });
                  
      }
    }).catch((error)=>{
      console.log(error);
    });
      }
  }

  showError(message) {
    Swal2.fire({
      title: 'Error',
      text: message,
      icon: 'warning',
      confirmButtonText: 'Entendido',
      color: '#ffffff',
      background: '#010f4e',
    });
  }

}
