import { Component, OnInit } from '@angular/core';
import { modalController } from '@ionic/core';

@Component({
  selector: 'app-modules-lock',
  templateUrl: './modules-lock.component.html',
  styleUrls: ['./modules-lock.component.scss']
})
export class ModulesLockComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  cerrar(){
    modalController.dismiss()
    console.log("se cerro componente modules-lock");

  }

}
