import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DateTimeComponent } from '../date-time/date-time.component';
import { ModalDateTimeComponent } from '../modal-date-time/modal-date-time.component';
import { TextAreaComponent } from '../text-area/text-area.component';
import { TextInputComponent } from '../text-input/text-input.component';
import { WhiteInputComponent } from '../white-input/white-input.component';
import { IconTextComponent } from '../icon-text/icon-text.component';
import { ButtonsModule } from '../../buttons/buttons-module/buttons.module';
import { SelectionInputComponent } from '../selection-input/selection-input.component';



@NgModule({
	declarations: [
		DateTimeComponent,
		ModalDateTimeComponent,
		TextAreaComponent,
		TextInputComponent,
		WhiteInputComponent,
		IconTextComponent,
		SelectionInputComponent,
	],
	exports: [
		DateTimeComponent,
		ModalDateTimeComponent,
		TextAreaComponent,
		TextInputComponent,
		WhiteInputComponent,
		IconTextComponent,
		SelectionInputComponent,
	],
	entryComponents: [
		DateTimeComponent,
		ModalDateTimeComponent,
		TextAreaComponent,
		TextInputComponent,
		WhiteInputComponent,
		IconTextComponent,
		SelectionInputComponent,
	],
	imports: [
		CommonModule,
		IonicModule,
		ButtonsModule
	],
	schemas: []
})
export class InputModuleModule { }
