import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PoliticaDePrivacidadComponent } from '../politica-de-privacidad/politica-de-privacidad.component';
import { TerminosYCondicionesComponent } from '../terminos-y-condiciones/terminos-y-condiciones.component';



@NgModule({
  declarations: [
    PoliticaDePrivacidadComponent,
    TerminosYCondicionesComponent
  ],
  exports:[
    PoliticaDePrivacidadComponent,
    TerminosYCondicionesComponent
  ],
  entryComponents:[
    PoliticaDePrivacidadComponent,
    TerminosYCondicionesComponent
  ],
  imports: [
    CommonModule
  ]
})
export class TerminosCondicionesModuleModule { }
