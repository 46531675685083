import { Component, OnInit } from '@angular/core';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import garantiaJson from 'src/assets/plantillas/garantia-feex.json'

@Component({
  selector: 'app-bienes5',
  templateUrl: './bienes5.component.html',
  styleUrls: ['./bienes5.component.scss'],
})
export class Bienes5Component implements OnInit {
  public Garantia: Array<string> = Object.keys(garantiaJson.garantia)

  constructor(private formservice : FormServiceService) { }

  ngOnInit() {
  }

  addTiempo(event) {
    this.formservice.feexpublication.tiempo_garantia=event.target.value
  }

  selectDia(event) {
    this.formservice.feexpublication.dia_mes=event.target.value
  }
}
