import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-component-round-button',
  templateUrl: './component-round-button.component.html',
  styleUrls: ['./component-round-button.component.scss'],
})
export class ComponentRoundButtonComponent implements OnInit {

  @Input() name: string;
  constructor() { }

  ngOnInit() {}

}
