import { Component, OnInit } from '@angular/core';
import { ModalController} from '@ionic/angular';
import { GooglemapsComponent } from 'src/app/components/googlemaps/googlemaps.component';
import { FormServiceService } from 'src/app/services/publications/form-service.service';

@Component({
  selector: 'app-eclipse-eventos5',
  templateUrl: './eclipse-eventos5.component.html',
  styleUrls: ['./eclipse-eventos5.component.scss'],
})
export class EclipseEventos5Component implements OnInit {

  constructor(public formservice: FormServiceService,
    private modalcontroller: ModalController) { }

  ngOnInit() { }

  openMap() {
    this.modalcontroller.create({
      component: GooglemapsComponent,
      cssClass: 'detailsInModal',
      swipeToClose: true,
    }).then((modal) => {
      modal.present();
    })
  }
}
