import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NormalPublication } from 'src/app/Models/Classes/Publications/Normal/normal-publication';
import { PublicationClass } from 'src/app/Models/Classes/Publications/publication-class';
import { ActionSheetController, ModalController, NavController, NavParams } from '@ionic/angular';
import { EclipseEventosComponent } from '../eclipse-eventos/eclipse-eventos/eclipse-eventos.component';
import { EpochComponent } from '../epoch/epoch/epoch.component';
import { LkfilmstudiosComponent } from '../lkfilms/lkfilmstudios/lkfilmstudios.component';
import Swal2 from 'sweetalert2';
@Component({
	selector: 'app-normal-publication',
	templateUrl: './normal-publication.component.html',
	styleUrls: ['./normal-publication.component.scss'],
})
export class NormalPublicationComponent implements OnInit {
	public tituloLabel: string = "Titulo";
	public descripcionLabel: string = "Descripción";
	public contentInput: string;
	public contentTextArea: string;
	public fabActivated: boolean = false;
	public verpara; //?
	public validar; //?
	public validareco; //?
	public a; //?

	@Input() isEditing: boolean = false;
	@Input() publication: NormalPublication;
	@Input() publicationToShare: PublicationClass;
	@Input() pricesList = Array<any>();
	@Output() checkIfCanPost = new EventEmitter<string>();
	@Output() deleteMedia = new EventEmitter<any>();


	constructor(public actionSheetController: ActionSheetController,
		private modalcontroller: ModalController,
		public navcontroller: NavController
	) { }


	ngOnInit() {
		
		this.contentInput = this.publication.title;
		this.contentTextArea = this.publication.description;
		//this.returnPriceValue(this.publication.price_id);

	}

	cambio() {
		this.validar = !this.validar
		
	}


	cambioeco() {
		this.validareco = !this.validareco
		
		if (this.validareco === true) {
			this.a = "feed";
			
		}
	}

	returnTitleValue(event) {
		this.publication.title = event.detail.value;
		this.checkIfCanPost.emit();
	}

	returnDescriptionValue(event) {
		this.publication.description = event.detail.value;
		this.checkIfCanPost.emit();
	}

	deleteMediaEmit() {
		this.deleteMedia.emit();
	}

	async presentActionSheet() {
		Swal2.fire({
			title: '',
			text: '',
			icon: 'success',
			timer: 9999, // Show the alert for 45 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-start',
			showConfirmButton: false
		  });
    setTimeout(() => {
      this.actionSheetController.dismiss();
      }, 9999); 
		const actionSheet = await this.actionSheetController.create({
			header: 'Adjuntar archivo',
			cssClass: 'my-custom-class',
			buttons: [{
				text: 'Archivo',
				icon: 'add',
				handler: () => {
				}
			}, {
				text: 'Foto',
				icon: 'heart',
				handler: () => {
					console.log('Favorite clicked');
				}
			}, {
				text: 'Video',
				icon: 'heart',
				handler: () => {
					console.log('Favorite clicked');
				}
			}
			]
		});
		await actionSheet.present();

		const { role } = await actionSheet.onDidDismiss();
		
	}

	openApp(app: string) {
		console.log(app)
		switch (app) {
			case 'ECLIPSE EVENTOS':
				this.modalcontroller.create({
					component: EclipseEventosComponent
				}).then((modal) => {
					modal.present();
				})

				break;
			case 'EARTH HOSPEDAJE':
				/*	this.modalcontroller.create({
						component: EarhtComponent
					}).then((modal) => {
						modal.present();
					})
					*/
				break;
			case 'EPONCH EDUCATIVO':
				this.modalcontroller.create({
					component: EpochComponent
				}).then((modal) => {
					modal.present();
				})
				break;
			case 'LAND SERVICIOS':
				/*	this.modalcontroller.create({
						component: LandserviceComponent
					}).then((modal) => {
						modal.present();
					})
					*/
				break;
			case 'ROCKET SUBASTAS':
				/*	this.modalcontroller.create({
						component: RocketComponent
					}).then((modal) => {
						modal.present();
					})
					*/
				break;
			case 'LK FILM STUDIOS':
				this.modalcontroller.create({
					component: LkfilmstudiosComponent
				}).then((modal) => {
					modal.present();
				})
				break;
			case 'FEEX BIENES':
				/*	this.modalcontroller.create({
						component: BienesComponent
					}).then((modal) => {
						modal.present();
					})
					*/
				break;

			case 'FEED':
				// this.navcontroller.push(FeedComponent)
				/*this.modalcontroller.create({
					component: FeedComponent,
					swipeToClose: true,
					componentProps: {
						quienpuedever: this.verpara

					}
				}).then((modal) => {
					modal.present();
				})
				*/
				break;
		}
	}

	pasardatos(event) {
		
		this.verpara = event.target.value
	}

	returnPriceValue(price) { }
}
