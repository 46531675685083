import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
	selector: 'app-selection-input',
	templateUrl: './selection-input.component.html',
	styleUrls: ['./selection-input.component.scss'],
})
export class SelectionInputComponent implements OnInit {

	@Input() optionList: Array<any>;
	@Input() hasSubCategories: boolean = false;
	@Input() actualSelection: string = undefined;

	constructor(
		private popOverController: PopoverController,
	) { }

	ngOnInit() { }

	returnSelected(categorie: string, subcategorie?: string){
		if(subcategorie){
			let response = {
				categorie: categorie,
				subcategorie: subcategorie
			};
			this.popOverController.dismiss(response);
		}
		else{
			this.popOverController.dismiss(categorie);
		}
		
	}

}
