import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FormServiceService } from 'src/app/services/publications/form-service.service';


@Component({
  selector: 'app-bienes3',
  templateUrl: './bienes3.component.html',
  styleUrls: ['./bienes3.component.scss'],
})
export class Bienes3Component implements OnInit {

  constructor(private formservice: FormServiceService) { }

  ngOnInit() { }

  selectEnvio(event) {
    
    this.formservice.feexpublication.envio = event.target.value
  }

  selectEnvioG(event) {
   
    this.formservice.feexpublication.envio_gratis = event.target.value
  }
}
