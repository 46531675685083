export const getUserConversationsByUsers = /* GraphQL */ `
	query GetUserConversationsByUsers($id: ID!, $relationUserID: ID!) {
		getUserConversationsByUsers(id: $id, relationUserID: $relationUserID) {
			id
     		username
			conversations {
				items {
					conversation {
						id
						name
						isGroup
						isDelete
						users(filter: {userID: {eq: $relationUserID}}) {
							items {
								user {
									username
								}
							}
						}
					}
				}
			}
			messages {
				nextToken
			}
			connections {
				nextToken
			}
      		createdAt
      		updatedAt
		}
	}
`;

export const getConversationByUser = /* GraphQL */ `
	query GetConversationByUser($id: ID!, $userID: ID!) {
		getConversationByUser(id: $id, userID: $userID) {
			id
			name
			isGroup
			isDelete
			multimediaID
			userID
			createdAt
			seens {
				nextToken
			}
			user {
				id
				username
				createdAt
				updatedAt
			}
			writes {
				nextToken
			}
			users(filter: {not: {userID: {eq: $userID}}}) {
				items {
					user{
						id
						username
					}
				}
			}
			messages {
				items {
					nextToken
				}
				nextToken
      		}
			updatedAt
		}
	}
`;

export const getConversationMessages = /* GraphQL */ `
	query GetConversationMessages($id: ID!, $limit: Int, $nextToken: String) {
		getConversationMessages(id: $id, limit: $limit, nextToken: $nextToken) {
			id
			name
			isGroup
			isDelete
			multimediaID
			userID
			createdAt
			seens {
				nextToken
			}
			user {
				id
				username
				createdAt
				updatedAt
			}
			writes {
				nextToken
			}
			users {
				items {
					user {
						createdAt
						id
						updatedAt
						username
					}
				}
			}
			messages(limit: $limit, nextToken: $nextToken, sortDirection: DESC) {
				items {
					body
					conversationID
					createdAt
					id
					isDelete
					isSeen
					isSystemNotification
					multimediaID
					updatedAt
					userID
					messageID
					message {
						body
						conversationID
						createdAt
						id
						isDelete
						isSystemNotification
						multimediaID
						updatedAt
						userID
						messageID
						user {
							createdAt
							id
							updatedAt
							username
						}
					}
					user {
						createdAt
						id
						updatedAt
						username
					}
					users {
						items {
							user {
								createdAt
								id
								updatedAt
								username
							}
						}
					}
				}
				nextToken
			}
			updatedAt
		}
	}
`;

export const getUser = /* GraphQL */ `
	query GetUser($id: ID!) {
		getUser(id: $id) {
			id
			username
			conversations {
				nextToken
			}
			messages {
				nextToken
			}
			connections(sortDirection: DESC, limit: 1) {
				items {
					createdAt
				}
			}
			createdAt
			updatedAt
		}
	}
`;

export const getConversationsByUser = /* GraphQL */ `
	query GetUser($id: ID!) {
    	getUser(id: $id) {
			id
			username
			createdAt
			updatedAt
			conversations(sortDirection: DESC) {
				items {
					conversation {
						id
						name
						isGroup
						isDelete
						multimediaID
						userID
						createdAt
						seens {
							nextToken
						}
						user {
							id
							username
							createdAt
							updatedAt
						}
						writes {
							nextToken
						}
						updatedAt
						users(filter: {not: {userID: {eq: $id}}}) {
							items {
								id
								user {
									username
									id
								}
							}
						}
						messages(sortDirection: DESC, limit: 1) {
							items {
								body
								conversationID
								createdAt
								id
								isDelete
								isSeen
								isSystemNotification
								multimediaID
								updatedAt
								userID
								messageID
								message {
									body
									conversationID
									createdAt
									id
									isDelete
									isSystemNotification
									multimediaID
									updatedAt
									userID
									messageID
									user {
										createdAt
										id
										updatedAt
										username
									}
								}
								user {
									createdAt
									id
									updatedAt
									username
								}
								users {
									items {
										user {
											createdAt
											id
											updatedAt
											username
										}
									}
								}
							}
							nextToken
						}
					}
				}
			}
    	}
  	}
`;

export const listUsers = /* GraphQL */ `
	query ListUsers(
		$filter: ModelUserFilterInput
		$limit: Int
		$nextToken: String
	) {
		listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				username
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;

export const getConversation = /* GraphQL */ `
	query GetConversation($id: ID!) {
		getConversation(id: $id) {
			id
			name
			isGroup
			isDelete
			multimediaID
			userID
			createdAt
			seens {
				nextToken
			}
			user {
				id
				username
				createdAt
				updatedAt
			}
			writes {
				nextToken
			}
			users {
				nextToken
			}
			messages {
				nextToken
			}
			updatedAt
		}
	}
`;

export const listConversations = /* GraphQL */ `
	query ListConversations(
		$filter: ModelConversationFilterInput
		$limit: Int
		$nextToken: String
	) {
		listConversations(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;

export const getMessage = /* GraphQL */ `
	query GetMessage($id: ID!) {
		getMessage(id: $id) {
			id
			userID
			messageID
			conversationID
			multimediaID
			body
			isDelete
			isSeen
			isSystemNotification
			createdAt
			user {
				id
				username
				createdAt
				updatedAt
			}
			message {
				id
				userID
				messageID
				conversationID
				multimediaID
				body
				isDelete
				isSeen
				isSystemNotification
				createdAt
				updatedAt
			}
			conversation {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
			}
			users {
				nextToken
			}
			updatedAt
		}
	}
`;

export const listMessages = /* GraphQL */ `
	query ListMessages(
		$filter: ModelMessageFilterInput
		$limit: Int
		$nextToken: String
	) {
		listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				userID
				messageID
				conversationID
				multimediaID
				body
				isDelete
				isSeen
				isSystemNotification
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;

export const getConnection = /* GraphQL */ `
  query GetConnection($id: ID!) {
    getConnection(id: $id) {
      id
      userID
      status
      createdAt
      user {
        id
        username
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;

export const listConnections = /* GraphQL */ `
	query ListConnections(
		$filter: ModelConnectionFilterInput
		$limit: Int
		$nextToken: String
	) {
		listConnections(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				userID
				status
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;

export const getSeen = /* GraphQL */ `
	query GetSeen($id: ID!) {
		getSeen(id: $id) {
			id
			conversationID
			createdAt
			conversation {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
			}
			messages {
				nextToken
			}
			updatedAt
		}
	}
`;

export const listSeens = /* GraphQL */ `
	query ListSeens(
		$filter: ModelSeenFilterInput
		$limit: Int
		$nextToken: String
	) {
		listSeens(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				conversationID
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;

export const getWrite = /* GraphQL */ `
	query GetWrite($id: ID!) {
		getWrite(id: $id) {
			id
			userID
			conversationID
			status
			createdAt
			conversation {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
			}
			user {
				id
				username
				createdAt
				updatedAt
			}
			updatedAt
		}
	}
`;

export const listWrites = /* GraphQL */ `
	query ListWrites(
		$filter: ModelWriteFilterInput
		$limit: Int
		$nextToken: String
	) {
		listWrites(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				userID
				conversationID
				status
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;
