import {Component, OnInit, Input, AfterViewInit} from '@angular/core';
import { PublicationClass } from 'src/app/Models/Classes/Publications/publication-class';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { PublicationService } from 'src/app/services/publications/publication.service';
import {ActualizacionServiceService} from "../../services/actualizacion/Actualizacion-service.service";
import {
  PublicationMediaTemplateComponent
} from "../../components/publication/publication-media-template/publication-media-template.component";
import {HeaderBarComponent} from 'src/app/components/general-design/header-bar/header-bar.component';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
@Component({
    selector: 'app-my-feed-page',
    templateUrl: './my-feed-page.page.html',
    styleUrls: ['./my-feed-page.page.scss'],
})
export class MyFeedPagePage implements AfterViewInit {
    public Profile: any;
    private paginatorIndex: number = 1;
    @Input() profile_id: number;
    @Input() publicationWeb:any;
    valueSelect: string = 'Para todos';
    public publications: any[] = [];
    public paraTodosPublications: any[] = [];
    public siguiendoPublications: any[] = [];
    public Oldpublications: any[] = [];
    evento;
    mostrar_enlace='Mostrar';
	  mostrar=true;

    constructor(
     // public HeaderBarComponent: HeaderBarComponent,
        public publicationService: PublicationService,
        private profileService: ProfileService,
        private actualizacionService: ActualizacionServiceService,
        private formService:FormServiceService,

    ) { }

    ngOnInit() {
      this.actualizacionService.current_page=0;
      this.getStories();
      this.getProfile();
      this.getPublications(null, true);
    }
 
    ngAfterViewInit() {
    }

    swipe(){
console.log('swiped');
        if(this.mostrar){
          this.mostrar=false;
          this.mostrar_enlace='Mostrar';
          this.formService.mostrar = false;
          console.log('swiped',this.formService.mostrar );
        }else{
          this.mostrar=true;
          this.mostrar_enlace='Oculto';
          this.formService.mostrar = true;
          console.log('swiped',this.formService.mostrar );
        }
      
    }

    ionViewDidEnter() {

    }

    segmentChanged2(event) {
      this.getPublications(null, null);
      this.valueSelect = event;
    }
    segmentChanged(event) {
      this.valueSelect = event;
      if (event === 'Seguidores') {
        if (this.siguiendoPublications.length === 0) {
          this.getPublications(null, null);
          this.publications = this.siguiendoPublications;
        } else {
          this.publications = this.siguiendoPublications;
        }
      } else if (event === 'Para todos') {
        if (this.paraTodosPublications.length === 0) {
          this.getPublications(null, null);
          this.publications = this.paraTodosPublications;
        } else {
          this.publications = this.paraTodosPublications;
        }
      }
    }
    
    

    siguiendo(){
      let old_publications = this.Oldpublications;
      this.siguiendoPublications = [];
      old_publications.forEach((publication)=>{
        if('quienpuedever' in publication) {
          //if (publication['quienpuedever'].includes('Seguidores')) {
            this.siguiendoPublications.push(publication);
         // }
        }
      });
      console.log('siguiendo length of pub', this.siguiendoPublications.length)
      if(this.siguiendoPublications.length == 0 && this.actualizacionService.current_page <3) {
        this.actualizacionService.current_page++;
        this.getPublications(null, false);
      }else{
        this.publications = this.siguiendoPublications;
      }
   }

   paratodos() {
      let old_publications = this.Oldpublications;
      this.paraTodosPublications = [];
      //let i = 0;
      old_publications.forEach((publication)=>{
        if('quienpuedever' in publication) {
          //if (publication['quienpuedever'].includes('Para todos')) {
           // if (i<=3) {
              this.paraTodosPublications.push(publication);
             // i++;
           // }
          //}
        }
      });
      console.log('siguiendo length of pub', this.publications.length)
      if(this.publications.length == 0 && this.actualizacionService.current_page <3) {
        this.actualizacionService.current_page++;
        this.getPublications(null, false,);
      }else {
        this.publications = this.paraTodosPublications;
      }
      
      console.log('Parati length of pub', this.publications.length); // Add this line
      console.log('Parati publications', this.publications);
    }

    

    public doRefresh(event) {
      this.evento=event;
      this.getStories();
      this.getPublications(event, true);
    }

    public getPublications(event: any, firstLoading) {
      let section;
      if (this.valueSelect === 'Seguidores'){
section='Parati';
console.log('valueSelect',section);
      }else if (this.valueSelect === 'Para todos'){
        section='paraTodos';
        console.log('valueSelect', section);
      }
      this.actualizacionService.getAIPublications(event, firstLoading, null, null, section).then((result: [])=>{
        this.Oldpublications = result;
        if(firstLoading){
          let parati = document.getElementById('buttonparati');
          parati.click();
        }else{
          console.log(result)
          console.log('entraste get publications else');
          if (this.valueSelect === 'Seguidores'){
           this.siguiendo();
          }else if (this.valueSelect === 'Para todos') {
            this.paratodos();
          }
        }
      });
    }

    public getProfile() {
        this.profileService.getProfileInfo().then((data) => {
            this.Profile = JSON.parse(data);
        });
    }

    public moreData(event?) {
        this.publicationService.getAllPublications(this.paginatorIndex,null, null).then((data: any) => {
            Object.keys(data.data).forEach(key => {
                let value = data.data[key];
                this.publicationService.updatePublicationList('MyFeedList', value, false);
            });
            this.paginatorIndex++;
            event.target.complete();
            if (data.last_page < this.paginatorIndex) {
                event.target.disabled = true;
            }
        }).catch(error => {
            console.error("Error Get Publications", error);
        });
    }

    public getStories() {
      this.publicationService.getStories().then((data: any) => {
        data.forEach(element => {
          this.publicationService.updatePublicationList('MyStoriesList', element, true);
        });
      }).catch(error => {
        console.error("Error Get Publications", error);
      });
    }

    deletePublication(publication: PublicationClass) { }

}

