export const createSeenByMessages = /* GraphQL */ `
  mutation CreateSeenByMessages(
    $input: CreateUserMessageByMessagesList!
    $condition: ModelUserMessageConditionInput
  ) {
    createSeenByMessages(input: $input, condition: $condition) {
		id
		conversationID
		createdAt
		conversation {
			id
			name
			isGroup
			isDelete
			multimediaID
			userID
			createdAt
			updatedAt
		}
		messages {
			items {
				isDelete
				message {
					body
					conversationID
					createdAt
					id
					isDelete
					isSeen
					isSystemNotification
					messageID
					multimediaID
					updatedAt
					userID
					users {
						items {
							user {
								createdAt
								id
								updatedAt
								username
							}
						}
					}
				}
			}
		}
		updatedAt
		}
	}
`;
export const createConversationByUsersList = /* GraphQL */ `
	mutation CreateConversationByUsersList(
    	$input: CreateConversationByUsersList!
    	$userID: ID!
    	$condition: ModelConversationConditionInput
  	) {
		createConversationByUsersList(input: $input, userID:$userID, condition: $condition) {
			id
			name
			isGroup
			isDelete
			multimediaID
			userID
			users(filter: {not: {userID: {eq: $userID}}}) {
				items{
					user {
						id
						username
						createdAt
						updatedAt
					}
				}
				nextToken
			}
			messages(sortDirection: DESC, limit: 1) {
				items {
					id
					multimediaID
					body
					createdAt
					user {
						id
						username
						createdAt
						updatedAt
					}
				}
			}
			user {
				id
				username
				createdAt
				updatedAt
			}
			seens {
				nextToken
			}
			writes {
				nextToken
			}
			createdAt
			updatedAt
		}
  	}
`;
export const createUser = /* GraphQL */ `
  	mutation CreateUser(
    	$input: CreateUserInput!
    	$condition: ModelUserConditionInput
  	) {
		createUser(input: $input, condition: $condition) {
			id
			username
			conversations {
				nextToken
			}
			messages {
				nextToken
			}
			connections {
				nextToken
			}
			createdAt
			updatedAt
		}
  	}
`;
export const updateUser = /* GraphQL */ `
	mutation UpdateUser(
		$input: UpdateUserInput!
		$condition: ModelUserConditionInput
	) {
		updateUser(input: $input, condition: $condition) {
			id
			username
			conversations {
				nextToken
			}
			messages {
				nextToken
			}
			connections {
				nextToken
			}
			createdAt
			updatedAt
		}
	}
`;
export const deleteUser = /* GraphQL */ `
	mutation DeleteUser(
		$input: DeleteUserInput!
		$condition: ModelUserConditionInput
	) {
		deleteUser(input: $input, condition: $condition) {
			id
			username
			conversations {
				nextToken
			}
			messages {
				nextToken
			}
			connections {
				nextToken
			}
			createdAt
			updatedAt
		}
	}
`;
export const createConversation = /* GraphQL */ `
	mutation CreateConversation(
		$input: CreateConversationInput!
		$condition: ModelConversationConditionInput
	) {
		createConversation(input: $input, condition: $condition) {
			id
			name
			isGroup
			isDelete
			multimediaID
			userID
			createdAt
			seens {
				nextToken
			}
			user {
				id
				username
				createdAt
				updatedAt
			}
			writes {
				nextToken
			}
			users {
				nextToken
			}
			messages {
				nextToken
			}
			updatedAt
		}
	}
`;
export const updateConversation = /* GraphQL */ `
	mutation UpdateConversation(
		$input: UpdateConversationInput!
		$condition: ModelConversationConditionInput
	) {
		updateConversation(input: $input, condition: $condition) {
			id
			name
			isGroup
			isDelete
			multimediaID
			userID
			createdAt
			seens {
				nextToken
			}
			user {
				id
				username
				createdAt
				updatedAt
			}
			writes {
				nextToken
			}
			users {
				nextToken
			}
			messages {
				nextToken
			}
			updatedAt
		}
	}
`;
export const deleteConversation = /* GraphQL */ `
	mutation DeleteConversation(
		$input: DeleteConversationInput!
		$condition: ModelConversationConditionInput
	) {
		deleteConversation(input: $input, condition: $condition) {
			id
			name
			isGroup
			isDelete
			multimediaID
			userID
			createdAt
			seens {
				nextToken
			}
			user {
				id
				username
				createdAt
				updatedAt
			}
			writes {
				nextToken
			}
			users {
				nextToken
			}
			messages {
				nextToken
			}
			updatedAt
		}
	}
`;
export const createMessage = /* GraphQL */ `
	mutation CreateMessage(
		$input: CreateMessageInput!
		$condition: ModelMessageConditionInput
	) {
		createMessage(input: $input, condition: $condition) {
			id
			userID
			messageID
			conversationID
			multimediaID
			body
			isDelete
			isSeen
			isSystemNotification
			createdAt
			user {
				id
				username
				createdAt
				updatedAt
			}
			message {
				id
				userID
				messageID
				conversationID
				multimediaID
				body
				isDelete
				isSeen
				isSystemNotification
				createdAt
				updatedAt
				user {
					id
					username
					createdAt
					updatedAt
				}
			}
			conversation {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
				users {
					items {
						user {
							username
							id
						}
					}
				}
			}
			users {
				nextToken
			}
			updatedAt
		}
	}
`;
export const updateMessage = /* GraphQL */ `
	mutation UpdateMessage(
		$input: UpdateMessageInput!
		$condition: ModelMessageConditionInput
	) {
		updateMessage(input: $input, condition: $condition) {
			id
			userID
			messageID
			conversationID
			multimediaID
			body
			isDelete
			isSeen
			isSystemNotification
			createdAt
			user {
				id
				username
				createdAt
				updatedAt
			}
			message {
				id
				userID
				messageID
				conversationID
				multimediaID
				body
				isDelete
				isSeen
				isSystemNotification
				createdAt
				updatedAt
			}
			conversation {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
			}
			users {
				nextToken
			}
			updatedAt
		}
	}
`;
export const deleteMessage = /* GraphQL */ `
	mutation DeleteMessage(
		$input: DeleteMessageInput!
		$condition: ModelMessageConditionInput
	) {
		deleteMessage(input: $input, condition: $condition) {
			id
			userID
			messageID
			conversationID
			multimediaID
			body
			isDelete
			isSeen
			isSystemNotification
			createdAt
			user {
				id
				username
				createdAt
				updatedAt
			}
			message {
				id
				userID
				messageID
				conversationID
				multimediaID
				body
				isDelete
				isSeen
				isSystemNotification
				createdAt
				updatedAt
			}
			conversation {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
			}
			users {
				nextToken
			}
			updatedAt
		}
	}
`;
export const createConnection = /* GraphQL */ `
	mutation CreateConnection(
		$input: CreateConnectionInput!
		$condition: ModelConnectionConditionInput
	) {
		createConnection(input: $input, condition: $condition) {
			id
			userID
			status
			createdAt
			user {
				id
				username
				createdAt
				updatedAt
			}
			updatedAt
		}
	}
`;
export const updateConnection = /* GraphQL */ `
	mutation UpdateConnection(
		$input: UpdateConnectionInput!
		$condition: ModelConnectionConditionInput
	) {
		updateConnection(input: $input, condition: $condition) {
			id
			userID
			status
			createdAt
			user {
				id
				username
				createdAt
				updatedAt
			}
			updatedAt
		}
	}
`;
export const deleteConnection = /* GraphQL */ `
	mutation DeleteConnection(
		$input: DeleteConnectionInput!
		$condition: ModelConnectionConditionInput
	) {
		deleteConnection(input: $input, condition: $condition) {
			id
			userID
			status
			createdAt
			user {
				id
				username
				createdAt
				updatedAt
			}
			updatedAt
		}
	}
`;
export const createSeen = /* GraphQL */ `
	mutation CreateSeen(
		$input: CreateSeenInput!
		$condition: ModelSeenConditionInput
	) {
		createSeen(input: $input, condition: $condition) {
			id
			conversationID
			createdAt
			conversation {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
			}
			messages {
				nextToken
			}
			updatedAt
		}
	}
`;
export const updateSeen = /* GraphQL */ `
	mutation UpdateSeen(
		$input: UpdateSeenInput!
		$condition: ModelSeenConditionInput
	) {
		updateSeen(input: $input, condition: $condition) {
			id
			conversationID
			createdAt
			conversation {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
			}
			messages {
				nextToken
			}
			updatedAt
		}
	}
`;
export const deleteSeen = /* GraphQL */ `
	mutation DeleteSeen(
		$input: DeleteSeenInput!
		$condition: ModelSeenConditionInput
	) {
		deleteSeen(input: $input, condition: $condition) {
			id
			conversationID
			createdAt
			conversation {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
			}
			messages {
				nextToken
			}
			updatedAt
		}
	}
`;
export const createWrite = /* GraphQL */ `
	mutation CreateWrite(
		$input: CreateWriteInput!
		$condition: ModelWriteConditionInput
	) {
		createWrite(input: $input, condition: $condition) {
			id
			userID
			conversationID
			status
			createdAt
			conversation {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
			}
			user {
				id
				username
				createdAt
				updatedAt
			}
			updatedAt
		}
	}
`;
export const updateWrite = /* GraphQL */ `
	mutation UpdateWrite(
		$input: UpdateWriteInput!
		$condition: ModelWriteConditionInput
	) {
		updateWrite(input: $input, condition: $condition) {
			id
			userID
			conversationID
			status
			createdAt
			conversation {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
			}
			user {
				id
				username
				createdAt
				updatedAt
			}
			updatedAt
		}
	}
`;
export const deleteWrite = /* GraphQL */ `
	mutation DeleteWrite(
		$input: DeleteWriteInput!
		$condition: ModelWriteConditionInput
	) {
		deleteWrite(input: $input, condition: $condition) {
			id
			userID
			conversationID
			status
			createdAt
			conversation {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
			}
			user {
				id
				username
				createdAt
				updatedAt
			}
			updatedAt
		}
	}
`;
export const createUserConversation = /* GraphQL */ `
	mutation CreateUserConversation(
		$input: CreateUserConversationInput!
		$condition: ModelUserConversationConditionInput
	) {
		createUserConversation(input: $input, condition: $condition) {
			id
			userID
			conversationID
			createdAt
			isDelete
			isAdmin
			user {
				id
				username
				createdAt
				updatedAt
			}
			conversation {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
			}
			updatedAt
		}
	}
`;
export const updateUserConversation = /* GraphQL */ `
	mutation UpdateUserConversation(
		$input: UpdateUserConversationInput!
		$condition: ModelUserConversationConditionInput
	) {
		updateUserConversation(input: $input, condition: $condition) {
			id
			userID
			conversationID
			createdAt
			isDelete
			isAdmin
			user {
				id
				username
				createdAt
				updatedAt
			}
			conversation {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
			}
			updatedAt
		}
	}
`;
export const deleteUserConversation = /* GraphQL */ `
	mutation DeleteUserConversation(
		$input: DeleteUserConversationInput!
		$condition: ModelUserConversationConditionInput
	) {
		deleteUserConversation(input: $input, condition: $condition) {
			id
			userID
			conversationID
			createdAt
			isDelete
			isAdmin
			user {
				id
				username
				createdAt
				updatedAt
			}
			conversation {
				id
				name
				isGroup
				isDelete
				multimediaID
				userID
				createdAt
				updatedAt
			}
			updatedAt
		}
	}
`;
export const createUserMessage = /* GraphQL */ `
	mutation CreateUserMessage(
		$input: CreateUserMessageInput!
		$condition: ModelUserMessageConditionInput
	) {
		createUserMessage(input: $input, condition: $condition) {
			id
			userID
			messageID
			seenID
			createdAt
			isDelete
			seen {
				id
				conversationID
				createdAt
				updatedAt
			}
			user {
				id
				username
				createdAt
				updatedAt
			}
			message {
				id
				userID
				messageID
				conversationID
				multimediaID
				body
				isDelete
				isSeen
				isSystemNotification
				createdAt
				updatedAt
			}
			updatedAt
		}
	}
`;
export const updateUserMessage = /* GraphQL */ `
	mutation UpdateUserMessage(
		$input: UpdateUserMessageInput!
		$condition: ModelUserMessageConditionInput
	) {
		updateUserMessage(input: $input, condition: $condition) {
			id
			userID
			messageID
			seenID
			createdAt
			isDelete
			seen {
				id
				conversationID
				createdAt
				updatedAt
			}
			user {
				id
				username
				createdAt
				updatedAt
			}
			message {
				id
				userID
				messageID
				conversationID
				multimediaID
				body
				isDelete
				isSeen
				isSystemNotification
				createdAt
				updatedAt
			}
			updatedAt
		}
	}
`;
export const deleteUserMessage = /* GraphQL */ `
	mutation DeleteUserMessage(
		$input: DeleteUserMessageInput!
		$condition: ModelUserMessageConditionInput
	) {
		deleteUserMessage(input: $input, condition: $condition) {
			id
			userID
			messageID
			seenID
			createdAt
			isDelete
			seen {
				id
				conversationID
				createdAt
				updatedAt
			}
			user {
				id
				username
				createdAt
				updatedAt
			}
			message {
				id
				userID
				messageID
				conversationID
				multimediaID
				body
				isDelete
				isSeen
				isSystemNotification
				createdAt
				updatedAt
			}
			updatedAt
		}
	}
`;
