import { Component, OnInit, OnChanges, SimpleChanges, Input  } from '@angular/core';
import { WalletServiceService } from 'src/app/services/wallet/wallet-service.service';
import { Utils } from 'src/app/Models/Classes/utils';
import { Wallet } from 'src/app/Models/Classes/Wallet/wallet';
import { ModalController } from '@ionic/angular';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { ActivatedRoute } from '@angular/router';
import { RequestService } from 'src/app/services/request/request.service';


@Component({
	selector: 'app-wallet-app',
	templateUrl: './wallet-app.component.html',
	styleUrls: ['./wallet-app.component.scss'],
})
export class WalletAppComponent implements OnInit, OnChanges {
	public profile: any = null;
	//public profile_id: number;
	@Input() shortcut: any;
	@Input() objmedia: any;
	@Input() publication_id: any;
	@Input() TotalPayment: any;
	@Input() balances: any[];
	 @Input() transactions: any[];
	 @Input() profile_id: number;
	 public LaniakeaBalance: number;

	public available_sections: Array<string> = ["Saldo", "Transacciones", "Retirar", "Depositar"];
	public icons_for_sections: Array<string> = ["wallet", "swap-horizontal", "Download-sharp", "cash-sharp"];
	public actual_section: string = "Saldo";
	constructor(
		public modalcontroller: ModalController,
		private walletService: WalletServiceService,
		private utils: Utils,
		private profile_service: ProfileService,
		public route: ActivatedRoute,
		private request: RequestService,
	) {
		this.profile_id = this.route.snapshot.params.profile_id;
	 }

	ngOnInit() {
		if (this.shortcut==='pagoDePublicacion'){
			this.available_sections=["Depositar"];
			this.actual_section='Depositar';
		}
		/*this.utils.loadingPresent().then(() => {
		  this.walletService.refreshWallets().finally(() => {
			this.utils.loadingDismiss().catch((error) => {
			  console.error(error);
			});
		  });
		});
		*/
		//console.log('wallet app', this.profile.id);
		console.log('wallet app', this.profile_id);
		console.log('wallet app balances', this.balances);
		console.log('wallet app transactions', this.transactions);
		if(this.shortcut === 'Hipoteca' || this.shortcut === 'AutomobileLoan'){
			this.actual_section='Retirar';
		}
	  }


	section_change(filtro) {
		this.actual_section = filtro;
	}

	back() {
		this.modalcontroller.dismiss({
			'dismissed': true
		});
	}
	ngOnChanges(changes: SimpleChanges) {
		
	  }

	  public onBalanceUniversalChange(balanceUniversal: number): void {
		// Access the BalanceUniversal value emitted from the child component
		console.log('BalanceUniversal value:', balanceUniversal);
		this.LaniakeaBalance = balanceUniversal;
		// Do whatever you need to do with the value
	  }
	

	  
	  
	
	

	ionViewWillEnter() {

		this.profile_service.getProfileInfo(this.profile_id).then((profileInfo)=>{
			profileInfo = JSON.parse(profileInfo);
			this.profile =profileInfo;
			this.profile_id = profileInfo.id;
		}).catch((error)=>{
			console.error(error);
		}).finally(()=>{

		});

	}



	reloadProfile(){
		this.profile_service.getProfileInfo(this.profile_id).then((data)=>{
			data = JSON.parse(data);

			this.profile = data;
			this.profile_id = data.id;
		}).catch((error)=>{
			console.error(error);
		}).finally(()=>{

		});
	}
}
