import { ProfileService } from "src/app/services/profile/profile.service";

export class UserContact {
    id: string;
    name: string;
    username: string;
    photo_url: string;
    status: boolean = false;
    lastStatus: string;
    wallets: Array<any>;
    constructor(userResponse?: any) {
        if (userResponse) {
            if (userResponse.user) {
                this.getUserInfo(userResponse).then((newUserInfo) => {
                    this.setByResponse(newUserInfo);
                }).catch((error) => {
                    console.error(error);
                });
            } else {
                if (userResponse.owner) {
                    this.id = (userResponse.id) ? userResponse.id : null;
                    this.name = (userResponse.name && userResponse.last_name) ? userResponse.name + userResponse.last_name : userResponse.username;
                    this.username = (userResponse.username) ? userResponse.username : null;
                    this.photo_url = (userResponse.profile_photo) ? userResponse.profile_photo.route : "https://www.tuexperto.com/wp-content/uploads/2015/07/perfil_01.jpg";
                    this.lastStatus = (userResponse.lastConnection)? userResponse.lastConnection : null;
                    this.wallets = (userResponse.wallets)? userResponse.wallets : new Array<any>();

                } else {
                    this.id = (userResponse.profile_id) ? userResponse.profile_id : null;
                    this.name = (userResponse.full_name && !(userResponse.full_name === " ")) ? userResponse.full_name : userResponse.username;
                    this.username = (userResponse.username) ? userResponse.username : null;
                    this.photo_url = (userResponse.photo) ? userResponse.photo : "https://www.tuexperto.com/wp-content/uploads/2015/07/perfil_01.jpg";
                    this.lastStatus = (userResponse.lastConnection)? userResponse.lastConnection : null;
                    this.wallets = (userResponse.wallets)? userResponse.wallets : new Array<any>();
                }
            }
        }
    }

    public setByResponse(newUserInfo) {

        this.id = (newUserInfo.id) ? newUserInfo.id : null;
        this.name = (newUserInfo.name && newUserInfo.last_name) ? newUserInfo.name + " " + newUserInfo.last_name : newUserInfo.username;
        this.username = (newUserInfo.username) ? newUserInfo.username : null;
        this.photo_url = (newUserInfo.profile_photo.url) ? newUserInfo.profile_photo.url : "https://www.tuexperto.com/wp-content/uploads/2015/07/perfil_01.jpg";
    }

    public getUserInfo(userResponse): Promise<any> {
        return new Promise((resolve, reject) => {
            ProfileService.instance.getProfileInfo(userResponse.user.id).then((userInfo)=>{
                try{
                    userInfo = JSON.parse(userInfo);
                    resolve(userInfo)
                }catch(e){
                    reject(e)
                }
            }).catch((error)=>{
                reject(error);
            });
        });
    }

    public getUsername() {
        return '@' + this.username;
    }

    public getDateSince() : Date {
        try {
            let date = new Date(this.lastStatus);
            return date;
            //return new Intl.DateTimeFormat(['ban', 'id']).format(date);
        }catch(e){
            console.error(e);
        }
    }
}
