import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-ai-popup-component',
  templateUrl: './ai-popup-component.component.html',
  styleUrls: ['./ai-popup-component.component.scss'],
})
export class AIPopupComponentComponent implements OnInit {
  @ViewChild('myTextarea', { static: false }) myTextarea: ElementRef;
  constructor(
    private formService:FormServiceService,
    private router:Router,
  ) { }

  ngOnInit() { if (this.myTextarea) {
    this.myTextarea.nativeElement.click();
  }}
  sendMessageToAI(){
this.formService.sendMessage_to_ai();
  }

}
