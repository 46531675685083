import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagoCompletadoComponent } from './pago-completado.component';

@NgModule({
  declarations: [
    PagoCompletadoComponent
  ],
  exports:[
    PagoCompletadoComponent
  ],
  entryComponents:[
    PagoCompletadoComponent
  ],
  imports: [
    CommonModule
  ]
})
export class PagoCompletadoModule { }
