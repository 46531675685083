import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EpochComponent } from '../../epoch/epoch/epoch.component';
import { Epoch2Component } from '../../epoch/epoch2/epoch2.component';
import { PrincipalEpochComponent } from '../principal-epoch/principal-epoch.component';
import { IonicModule } from '@ionic/angular';
import { DescripcionPublicationComponent } from '../../descripcion-publication/descripcion-publication.component';
import { DescriptionModuleModule } from '../../description-module/description-module.module';
import { Epoch3Component } from '../../epoch/epoch3/epoch3.component';



@NgModule({
  declarations: [
    /* componente principal epoch */
    PrincipalEpochComponent,
    /* Sub Componente epoch */
    EpochComponent,
    Epoch2Component,
    Epoch3Component
  ],
  exports:[
    /* componente principal epoch */
    PrincipalEpochComponent,
    /* Sub Componente epoch */
    EpochComponent,
    Epoch2Component,
    Epoch3Component
  ],
  entryComponents:[
    /* componente principal epoch */
    PrincipalEpochComponent,
    /* Sub Componente epoch */
    EpochComponent,
    Epoch2Component,
    Epoch3Component
  ],
  imports: [
    CommonModule,
    IonicModule,
    DescriptionModuleModule
  ]
})
export class EpochModuleModule { }
