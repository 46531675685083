import { Component, OnInit } from '@angular/core';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
 import FeexJson from 'src/assets/plantillas/data-feex.json'

@Component({
  selector: 'app-bienes',
  templateUrl: './bienes.component.html',
  styleUrls: ['./bienes.component.scss'],
})
export class BienesComponent implements OnInit {
   public Feexdata: Array<string> = Object.keys(FeexJson.dataFeex)

  constructor(private formservice: FormServiceService) { }
  ngOnInit() {
  }

  selectCategory(event) {
   
    this.formservice.feexpublication.category=event.target.value
  }

  addMarca(event) {
    this.formservice.feexpublication.marca=event.target.value
  }

  ubicacion(event) {
   
    this.formservice.feexpublication.ubicacion=event.target.value
  }

  addStock(event) {
    this.formservice.feexpublication.stock=event.target.value
  }
  
}
