import { MediaItem } from "../Media/media-item";
import { ReactionContainerClass } from "../Reactions/ReactionContainer/reaction-container-class";
import { ReactionClass } from "../Reactions/SingleReaction/reaction-class";
import { SellerProfile } from "../User/Store/seller-profile";
import { CommentClass } from "./Comments/comment-class";

export class PublicationClass {
    //Publication data
    Relative: any;
    Valoraciones: any;
    id: number;
    publication_type: string;
    publication_type_id: number;
    status_string: string;
    createdAt: number;
    access_level: string;
    //Interaction
    reactionContainer: Array<ReactionContainerClass>;
    reactionCounter: number;
    ownReaction: {
        reaction_id: number;
        reactionClass: ReactionClass
    };
    commentCounter: number;
    reviewCounter: number;
    commentContainer: Array<CommentClass>;
    ReviewContainer: Array<CommentClass>;
    //User
    userProfile: SellerProfile;
    corp: boolean;
    is_saved: boolean;
    owner: boolean;

    constructor(publication?: any, userProfile?: SellerProfile, reactionContainer?: Array<ReactionContainerClass>, ownReaction?: any) {
        if (publication != null) {
            //Publication
            this.id = (publication.id) ? parseInt(publication.id) : undefined;
            this.publication_type = (publication.publication_type) ? publication.publication_type : undefined;
            this.publication_type_id = (publication.publication_type_id) ? parseInt(publication.publication_type_id) : undefined;
            this.status_string = (publication.status_string) ? publication.status_string : "Borrador";
            this.createdAt = (publication.created_at)? publication.created_at : null;
            //Interaction
            this.reactionCounter = (publication.reactionCounter) ? parseInt(publication.reactionCounter) : 0;
            this.reactionContainer = (reactionContainer) ? reactionContainer : new Array<ReactionContainerClass>();
            this.ownReaction = (ownReaction) ? ownReaction : undefined;
            this.commentCounter = (publication.commentCounter) ? parseInt(publication.commentCounter) : 0;
            this.reviewCounter = (publication.reviewCounter) ? parseInt(publication.reviewCounter) : 0;
            this.Relative = (publication.Relative) ? publication.Relative: undefined;
            this.Valoraciones = (publication.Valoraciones) ? publication.Valoraciones: undefined;
            this.ReviewContainer = new Array<CommentClass>();
            this.commentContainer = new Array<CommentClass>();
            //User
            this.userProfile = (userProfile) ? userProfile : undefined;
            this.corp = (publication.corp) ? publication.corp : false;
            this.is_saved = (publication.is_saved) ? publication.is_saved : false;
            this.owner = (publication.owner) ? publication.owner : false;


        } else {
            //Publication

            this.id = undefined;
            this.publication_type = undefined;
            this.publication_type_id = undefined;
            this.status_string = "Borrador";
            //Interaction
            this.reactionCounter = 0;
            this.reactionContainer = (reactionContainer) ? reactionContainer : new Array<ReactionContainerClass>();
            this.ownReaction = (ownReaction) ? ownReaction : undefined;
            this.commentCounter = 0;
            this.reviewCounter = 0;
            this.Relative = undefined;
            this.Valoraciones = undefined;
            this.commentContainer = new Array<CommentClass>();
            this.ReviewContainer = new Array<CommentClass>();
            //User
            this.userProfile = (userProfile) ? userProfile : undefined;
            this.corp = false;
            this.is_saved = false;
            this.owner = true;
        }

    }

    getType(){
        return this.publication_type;
    }
}
