import { Component, OnInit } from '@angular/core';
import { FormServiceService } from 'src/app/services/publications/form-service.service';

@Component({
  selector: 'app-bienes4',
  templateUrl: './bienes4.component.html',
  styleUrls: ['./bienes4.component.scss'],
})
export class Bienes4Component implements OnInit {

  constructor(private formservice: FormServiceService) { }

  ngOnInit() { }
  selectProducto(event) {
  
    this.formservice.feexpublication.producto = event.target.value
  }

  selectGarantia(event) {
  
    this.formservice.feexpublication.garantia = event.target.value
  }
}
