import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Conversation } from 'src/app/Models/Classes/Chat/conversation';
import { Message } from 'src/app/Models/Classes/Chat/message';
import { UserContact } from 'src/app/Models/Classes/User/user-contact';
import { ChatService } from 'src/app/services/chat/chat.service';

@Component({
	selector: 'app-check-users-list',
	templateUrl: './check-users-list.component.html',
	styleUrls: ['./check-users-list.component.scss'],
})
export class CheckUsersListComponent implements OnInit {
	public selectedContacts: Array<UserContact | Conversation> = new Array<UserContact | Conversation>();
	@Input() resendMesage: Message;
	constructor(
		public chatService: ChatService,
		private modalController: ModalController
	) { }

	ngOnInit() { 
		console.log(this.resendMesage);
	}

	public attach_detach(user: UserContact | Conversation) {
		if (this.isSelected(user)) {
			const index = this.selectedContacts.indexOf(user, 0);
			if (index > -1) {
				this.selectedContacts.splice(index, 1);
			}
		}
		else {
			this.selectedContacts.push(user);
		}

	}

	public attach_detach_checkbox(event, contact: UserContact | Conversation) {
		if (event.detail.checked) {
			if (!this.isSelected(contact)) {
				this.selectedContacts.push(contact);
			}
		}
		else {
			if (this.isSelected(contact)) {
				const index = this.selectedContacts.indexOf(contact, 0);
				if (index > -1) {
					this.selectedContacts.splice(index, 1);
				}
			}
		}
	}

	public isSelected(user: UserContact | Conversation) {
		const index = this.selectedContacts.indexOf(user, 0);
		if (index > -1) {
			return true;
		}
		return false;
	}

	public resendMessage(){
		this.chatService.resendMessageByConversation(this.selectedContacts, this.resendMesage).then((messagesList)=>{
			this.modalController.dismiss();
		}).catch((error)=>{
			console.error(error);
		});
	}

}
