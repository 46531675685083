import { Component, OnInit } from '@angular/core';
import { FormServiceService } from 'src/app/services/publications/form-service.service';

@Component({
  selector: 'app-landservice2',
  templateUrl: './landservice2.component.html',
  styleUrls: ['./landservice2.component.scss'],
})
export class Landservice2Component implements OnInit {

  constructor(public formservice:FormServiceService) { }

  ngOnInit() { }

  Agenda(event) {
    
    this.formservice.landpublication.agenda=event.target.value
  }

  Ubicacion(event) {
  
    this.formservice.landpublication.ubicacion=event.target.value
  }

}
