import { Injectable } from '@angular/core';
import { RequestResponse } from 'src/app/Models/Classes/request-response';
import { RequestService } from '../request/request.service';

@Injectable({
  providedIn: 'root'
})
export class ReactionssService {

  constructor(private request: RequestService) { }

  public async postReaction(reaction: any, id_publication, reactionDestination: string): Promise<RequestResponse> {
    var params;
    id_publication ? params = { "id":id_publication } : params = null;

		return new Promise((resolve, reject) => {
			this.request.createRequestPost(reactionDestination, reaction, params, 'reactions').then((data) => {
				resolve(data);
			}).catch((error) => {
				reject(error);
			})
		});
  }

  public async deleteReaction(id_publication:number, reactionDestination: string): Promise<RequestResponse> {
		var params = {
			"id": id_publication
		};
		return new Promise((resolve, reject) => {
			this.request.createRequestDelete(reactionDestination, null, params, 'reactions').then((client) => {
				resolve(client);
			}).catch((error) => {
				reject(error);
			})
		});
	}
}
