import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-carta-pulicaciones',
	templateUrl: './carta-pulicaciones.component.html',
	styleUrls: ['./carta-pulicaciones.component.scss'],
})
export class CartaPulicacionesComponent implements OnInit {
	arrayver: any
	arraylugars: any
	arraytipos: any
	arrayvers:any
	validareco:any
	validar:any
	constructor(){}

	ngOnInit() {
		
	}

	dismiss(){}
	
	cambioeco(){}
	
	pasardatos(event){}

	openApp(tipo){}

	selectipo(event){}

	cambio(){}
}
