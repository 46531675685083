import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PublicacionesColeccionComponent } from '../../publicaciones-coleccion/publicaciones-coleccion/publicaciones-coleccion.component';
import { PublicacionesGuardadasComponent } from '../../publicaciones-guardadas/publicaciones-guardadas/publicaciones-guardadas.component';

@Component({
  selector: 'app-publicaciones-resumen',
  templateUrl: './publicaciones-resumen.component.html',
  styleUrls: ['./publicaciones-resumen.component.scss'],
})
export class PublicacionesResumenComponent implements OnInit {

  constructor(public modalController:ModalController) { }

  ngOnInit() {}

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  guardadas() {
    this.modalController
      .create({
        component: PublicacionesGuardadasComponent,
        cssClass: 'detailsInModal',
        swipeToClose: true,
      })
      .then((modal) => modal.present())
  }

  coleccion() {
    this.modalController
      .create({
        component: PublicacionesColeccionComponent,
        cssClass: 'detailsInModal',
        swipeToClose: true,
      })
      .then((modal) => modal.present())
  }

}
