import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AlertController, ModalController, NavController, NavParams } from '@ionic/angular';
import { MediaItem } from 'src/app/Models/Classes/Media/media-item';
import { PublicationClass } from 'src/app/Models/Classes/Publications/publication-class';
import { Utils } from 'src/app/Models/Classes/utils';
import { PublicationService } from 'src/app/services/publications/publication.service';
import { ReactionssService } from 'src/app/services/reactions/reactions.service';
import { WalletServiceService } from 'src/app/services/wallet/wallet-service.service';
import { PrincipalAgendaComponent } from '../../Agenda/principal-agenda/principal-agenda.component';
import { CommentsModalComponent } from '../../publication/comments/comments-modal/comments-modal.component';
import { CarritoComponent } from '../../publication/create/carrito-de-compras/carrito/carrito.component';
import { CompraFinalizadaComponent } from '../../publication/create/finalizar-compra/compra-finalizada/compra-finalizada.component';
import { PublicacionesColeccionComponent } from '../../publication/create/publicaciones-coleccion/publicaciones-coleccion/publicaciones-coleccion.component';
import { PublicacionesGuardadasComponent } from '../../publication/create/publicaciones-guardadas/publicaciones-guardadas/publicaciones-guardadas.component';
import { PublicacionesResumenComponent } from '../../publication/create/publicaciones-resumen/publicaciones-resumen/publicaciones-resumen.component';
import { CompleteInformationComponent } from '../../publication/publication-type/events-publication/complete-information/complete-information.component';
import { DetalleComponent } from '../../publication/show/detalle/detalle.component';

@Component({
  selector: 'app-popzoom',
  templateUrl: './popzoom.component.html',
  styleUrls: ['./popzoom.component.scss'],
})
export class PopzoomComponent implements OnInit {
  public url: string = "assets/galeriaimag/";
  public urll: string = "assets/iconoslk/";

  @Input() profilePhoto: string;
  @Input() userName: string;
  @Input() createdAt: number;
  @Input() owner: boolean = false;
  @Input() user_id: number;
  @Input() discardInteractions: boolean;
  @Input() corp: boolean = false;

  public timeSincePublish: string = "Justo ahora";
  public Difuminado:boolean = true;

  //footer
  @Input() publication

  @Output() savePublication = new EventEmitter();
  @Output() sharePublication = new EventEmitter();

  //multimedia
  @Input() multimedia: Array<MediaItem>;
  @Input() index: number = 0;
  qty: number = 0;
	encodedData: any;



	isSending: boolean = false;
	step: number = 0;
	hideBalance: boolean = true;
	selectedWallet = null;
	paymentResponse = null;

  constructor(
    private reactionService: ReactionssService,
    private publicationService: PublicationService,
    private navCtrl: NavController,
    private utils: Utils,
    private navctrl: NavParams,
    private modalcontroller: ModalController,
    public WalletService:WalletServiceService,
    public alertController: AlertController
  ) {
    this.navctrl.get('publicacion');
  }
  ngOnInit() {
     this.utils.calcularTiempo(this.createdAt).then(time => {
      this.timeSincePublish = time;

       if( this.publication.price >= 1){
            this.Difuminado=false
       }
    });
  }
  // openCompleteInformation() {
  //   this.modalcontroller.create({
  //     component: CompleteInformationComponent,
  //     swipeToClose: true,
  //     componentProps: {

  //     }
  //   }).then((modal => {
  //     modal.present();
  //   }));
  // }
  guardadas() {
    this.modalcontroller.create({
      component: PublicacionesGuardadasComponent
    })
    .then((modal) => modal.present());
  }
  resumen() {
    this.modalcontroller.create({
      component: PublicacionesResumenComponent
    })
    .then((modal) => modal.present());
  }
  coleccion() {
    this.modalcontroller.create({
      component: PublicacionesColeccionComponent
    })
    .then((modal) => modal.present());
  }
  Click_On_User() {
    //if(!this.discardInteractions){
    this.navCtrl.navigateForward('home/profile/' + this.user_id);
    //}
  }
  click_on_like() {
    let reaction = { type: "Me gusta" };

    if ((this.publication.ownReaction) ? true : false) {
      //Delete
      this.reactionService.deleteReaction(this.publication['id'], "publications").then((data: any) => {

        this.publication['ownReaction'] = undefined;
        this.publication['reactionCounter']--;

      }).catch((error) => {
        console.error(error);
        error = JSON.parse(error.error);
        if (error.error == "You have no reaction in this publication") {
          this.publication['ownReaction'] = undefined;
          this.publication['reactionCounter']--;
        }
        if (error.error == 'the publication no longer exist') {
          this.publicationService.DeleteLocalPublicationByID(this.publication.id);
        }
      });
    }
    else {
      this.reactionService.postReaction(reaction, this.publication['id'], "publications").then((data: any) => {
        data = JSON.parse(data);

        let objectReaction = {
          ownReaction: {
            reaction_group_id: data.reaction_group_id,
            reaction_icon: null,
            reaction_id: data.reaction_id,
            reaction_name: data.reaction_apllied
          }
        };

        this.publicationService.getOwnReaction_Publications(objectReaction).then((ownReaction) => {
          this.publication.ownReaction = ownReaction;
          this.publication.reactionCounter++;
        });

      }).catch((error) => {
        error = JSON.parse(error.error);
        console.log(error);
        if (error.error == "you can only reaction once per publication") {
          this.publicationService.getPublicationByID(this.publication.id, true).then((publication) => {
            this.publication = publication;
          }).catch((error) => {
            console.error(error);
          });
        }
        if (error.error == 'the publication no longer exist') {
          this.publicationService.DeleteLocalPublicationByID(this.publication.id);
        }
      });
    }
  }
  click_on_comments() {
    this.modalcontroller.create({
      component: CommentsModalComponent,
      swipeToClose: true,
      componentProps: {
        publication: this.publication,
        publicationId: this.publication['id']
      }
    }).then((modal) => {
      modal.present();
    });
  }
  Detalle( publication) {
    this.modalcontroller.create({
      component: DetalleComponent,
      swipeToClose: true,
      componentProps: {
        publication: this.publication,
      }
    }).then((modal) => {
      modal.present();
    });
  }
  click_on_save() {
    this.savePublication.emit();
  }
  click_on_share() {
    this.sharePublication.emit();
  }
  carrito() {
    this.modalcontroller
      .create({
        component: CarritoComponent
      })
      .then((modal) => modal.present())
  }
  pagar() {
    this.publication.publication_type
    if (this.publication.publication_type === "land") {
      this.modalcontroller
        .create({
          component: PrincipalAgendaComponent,

          componentProps: {
            publication: this.publication
          },
        })
        .then((modal) => modal.present())
    } else if (this.publication.publication_type === "earth") {
      this.modalcontroller
        .create({
          component: PrincipalAgendaComponent,

          componentProps: {
            publication: this.publication
          },
        })
        .then((modal) => modal.present())
    } else {
      this.modalcontroller
        .create({
          component: CompraFinalizadaComponent,

          componentProps: {
            publication: this.publication
          },
        })
        .then((modal) => modal.present())
    }

  }
  dismiss() {
    this.modalcontroller.dismiss({
      'dismissed': true
    });
  }
  


}
