import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, IonSlides } from '@ionic/angular';
import { ShootingStar7Component} from 'src/app/components/publication/create/shooting-star/shooting-star7/shooting-star7.component';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import { RequestService } from 'src/app/services/request/request.service';
import  Swal2 from 'sweetalert2';
//import * as QRCode from 'qrcode'; // Import the qrcode library
@Component({
  selector: 'app-shooting-star6',
  templateUrl: './shooting-star6.component.html',
  styleUrls: ['./shooting-star6.component.scss'],
})
export class ShootingStar6Component implements OnInit, AfterViewInit {
  start;
  destination;
  private currentIndex: number = 0; // Keep track of the current slide index
  @ViewChild('slides', { static: true }) slides: IonSlides; // ViewChild to access IonSlides
  public reservations:Array<any>=[];
  public showQrCode:boolean =false;
  public buttonText:any= 'Aceptar viaje.';
  public showMore:boolean=false;
  
slideOpts = {
  direction: 'horizontal',
  initialSlide: 0, // Set the initial slide
  speed: 400, // Slide transition speed
  //slidesPerView: 1, // Number of slides visible at once
  spaceBetween: 10, // Space between slides
  // Add other options as needed
};

  URLimagen: string="../../../../../../assets/galeriaimag/Activar.png"
public trips:any;
  constructor(private modalctrl:ModalController,
    private formservice: FormServiceService,
    private request: RequestService) { }
    ngAfterViewInit() {
      setTimeout(() => {
        this.updateStartAndDestination();
        }, 3600);
      this.updateStartAndDestination();
  }
  
  
  ngOnInit() {
    if(this.formservice.selectedVehicle){
      console.log('vehicle_id', this.formservice.selectedVehicle.id);
      this.request.createRequestPost('arrendadorBuscaViajes',{ Servicio: this.formservice.selectedVehicle.vehiculo, Address:this.formservice.address }) .then((data) => {
        console.log('vehicles_: ', data);
        console.log(data.vehicles);
        this.trips=data.vehicles;
      	if (typeof data === 'string') {
          let response = JSON.parse(data);
          console.log('json result publication service.ts', response);
          this.trips=response.vehicles;
          } else {
            this.trips=data.vehicles;
          }
                // Subscribe to slide changes after view is fully initialized
                this.slides.ionSlideDidChange.subscribe(() => {
                  this.updateStartAndDestination();
                });
              
      }).catch((error) => {
        console.error('Failed to send request:', error);
      });
    }
  }


  sendNotification(title, body, user_id) {
    return new Promise((resolve, reject) => {
       const notification = {
          title: title, body: body, user_id: user_id // Añadiste esta línea. 
         }; this.request.createRequestPost('GreatAtractorNotifications', notification) .then((data) => {
            resolve(data); 
           }).catch((error) => { 
             console.error('Failed to send notification:', error);
              reject(error); }); }); } 
  ProseguirViaje(id, user_id, vehicle, partida, destino){
    console.log(id);
    if(this.buttonText=== 'Aceptar viaje.'){
        let alertTitle;

      if (vehicle === 'Camión remolque' || vehicle === 'Avión privado' ||  vehicle === 'Helicóptero' || vehicle === 'Lancha' || vehicle === 'Yate'){
        alertTitle='Toca el mapa, asegúrate la exactitud del maracdor del puerto de partida. Tocando el mapa, especifíca el puerto de salida en el mapa, se enviará al usuario inmediatamente.';
      } if (vehicle !== 'Camión remolque' || vehicle !== 'Avión privado' ||  vehicle !== 'Helicóptero' || vehicle !== 'Lancha' || vehicle !== 'Yate'){
        alertTitle='Al aceptar, debes fugazmente dirigirte al punto de partida.';
      } 
      Swal2.fire({
        title: 'Confirmación de aceptación de viaje.',
        text: `Viaje fugaz con vehículo solicitado a la brevedad: ${vehicle}. 
        Punto de partida: ${partida}. Punto de destino ${destino}. ${alertTitle}`,
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        color: '#ffffff',
        background: '#010f4e',
      }).then((result) => {
        if (result.isConfirmed) {
          this.request.createRequestPost('arrendadorBuscaViajes',{ Request_id: id, Vehicle_id: this.formservice.selectedVehicle.id, puerto:this.formservice.address }) .then((data) => {
            //console.log(data.trip);
            //this.trips=data.trip;
            this.buttonText= 'Comenzar Viaje.';
            Swal2.fire({
              title: 'El viaje fue aceptado exitosmente.',
              text: 'El viaje ha sido añadido a tu lista de "Casa/ Mis boletos/ Estrellas Fugaces". El cliente debe scanear el viaje como indicador de que ha comenzado el viaje.',
              icon: 'success',
              confirmButtonText: 'Entendido',
              color: '#ffffff',
              background: '#010f4e',
            });
            console.log(user_id);
            if (vehicle !== 'Camión remolque' || vehicle !== 'Avión privado' ||  vehicle !== 'Helicóptero' || vehicle !== 'Lancha' || vehicle !== 'Yate'){
              this.sendNotification('Han aceptado tu viaje',`@${this.formservice.profile.username} va en camino al punto de partida`, user_id, )
            } else if (vehicle === 'Camión remolque' || vehicle === 'Avión privado' ||  vehicle === 'Helicóptero' || vehicle === 'Lancha' || vehicle === 'Yate'){
              this.sendNotification('Han aceptado tu viaje',`@${this.formservice.profile.username} actualizó el status, indicando el puerto.`, user_id, )
            }
            
          
          }).catch((error) => {
            Swal2.fire({
              title: 'Error al aceptar el viaje.',
              text: 'El status del viaje es probable que haya sido modificado. Si el error persiste comunícate con el soporte de Laniakea.',
              icon: 'error',
              confirmButtonText: 'Entendido',
              color: '#ffffff',
              background: '#010f4e',
            });
            console.error('Failed to send request:', error);
          });
        }
      });

   
  }
  }
 
  retorno(){
    this.modalctrl.dismiss()

  }
  showMoreData(){
    this.showMore = !this.showMore;
  }
  onClick(){
    this.modalctrl.create({
      component:ShootingStar7Component,
      cssClass: 'detailsInModal',
			swipeToClose: true,
    })
    .then((modal => {modal.present();} ))
  }
  private updateStartAndDestination() {
    this.slides.getActiveIndex().then((index) => {
      this.currentIndex = index;
      const currentTrip = this.trips[this.currentIndex];
      console.log(currentTrip);
      if (currentTrip) {
        this.start = currentTrip.startPoint;
        this.destination = currentTrip.destination;
      }
    });
  }
  obtenerInput(event)
	{

let text=event.target.value;
   

this.request.createRequestPost('arrendadorBuscaViajes',{ Request_id: text}) .then((data) => {
  console.log('vehicles_: ', data);
  console.log(data.vehicles);
  this.trips=data.vehicles;
  if (typeof data === 'string') {
    let response = JSON.parse(data);
    console.log('json result publication service.ts', response);
    this.trips=response.vehicles;
    } else {
      this.trips=data.vehicles;
    }
          // Subscribe to slide changes after view is fully initialized
          this.slides.ionSlideDidChange.subscribe(() => {
            this.updateStartAndDestination();
          });
        
}).catch((error) => {
  console.error('Failed to send request:', error);
});
  }
}
