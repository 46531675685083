import { Injectable } from '@angular/core';
import { MediaItem } from 'src/app/Models/Classes/Media/media-item';
import { CommentClass } from 'src/app/Models/Classes/Publications/Comments/comment-class';
import { PublicationClass } from 'src/app/Models/Classes/Publications/publication-class';
import { ReactionContainerClass } from 'src/app/Models/Classes/Reactions/ReactionContainer/reaction-container-class';
import { SellerProfile } from 'src/app/Models/Classes/User/Store/seller-profile';
import { RequestResponse } from '../../Models/Classes/request-response';
import { PublicationService } from '../publications/publication.service';
import { RequestService } from '../request/request.service';

@Injectable({
	providedIn: 'root'
})
export class CommentsService {

	 //comments
	 public multimediaComment: Array<MediaItem> = new Array<MediaItem>()
	 public blobsMultimediaComment=[];

	constructor(
		private request: RequestService,
		private publicationService: PublicationService,
	) { }

	public postComment(comment: any, id_publication: number): Promise<RequestResponse> {
		var params;
		id_publication ? params = { "id": id_publication } : params = null;

		return new Promise((resolve, reject) => {
			this.request.createRequestPost('publications', comment, params, 'comments').then((data) => {
				resolve(data);
			}).catch((error) => {
				reject(error);
			})
		});
	}

	public getComments(publication_id?: number, page?: number): Promise<RequestResponse> {

		let page_str;
		page ? page_str = page.toString() : page_str = "1";
		publication_id ? publication_id = publication_id : publication_id = 0;

		var params = {
			'id': publication_id.toString(),
			'queryParams': {
				"page": page_str
			}
		};

		return new Promise((resolve, reject) => {
			this.request.createRequestGet('publications', params.queryParams, params, 'comments').then((data) => {
				resolve(JSON.parse(data));
			}).catch((error) => {
				reject(error);
			})
		});
	}

	public getCommentFromServer(publication_id: number, comment_id: number): Promise<RequestResponse> {
		return new Promise((resolve, reject) => {
			var params = { "id": publication_id, "id2": comment_id } ;
			this.request.createRequestGet('publications', null, params, 'comments').then((data) => {
				resolve(JSON.parse(data));
			}).catch((error) => {
				reject(error);
			});
		});
	}

	public putComment(comment: any, id_publication: number, id_comment: number): Promise<RequestResponse> {
		var params = {
			"id": id_publication,
			"id2": id_comment
		}

		return new Promise((resolve, reject) => {
			this.request.createRequestPut('publications', comment, params, 'comments').then((data) => {
				resolve(data);
			}).catch((error) => {
				reject(error);
			});
		});
	}

	public deleteComment(id_publication: number, id_comment: number): Promise<RequestResponse> {
		var params = {
			"id": id_publication,
			"id2": id_comment
		}

		return new Promise((resolve, reject) => {
			this.request.createRequestDelete('publications', null, params, 'comments').then((client) => {
				resolve(client);
			}).catch((error) => {
				reject(error);
			})
		});
	}

	public updateCommentList(objectComment: any, publication: PublicationClass) {
		return new Promise<CommentClass>((resolve, reject) => {
			this.publicationService.getUserProfile_Publications(objectComment).then((userProfile: SellerProfile) => {
				this.publicationService.getReactionContainer_Publications(objectComment).then((reactionContainer: Array<ReactionContainerClass>) => {
					this.publicationService.getOwnReaction_Publications(objectComment).then((ownReaction) => {

						let comment: CommentClass = publication.commentContainer.find(element => element.id == objectComment.id);
						if (comment != undefined) {
							comment.updateComment(objectComment, userProfile, reactionContainer, ownReaction);

							this.orderCommentList(publication.commentContainer);
							resolve(comment);
						}
						else {
							comment = new CommentClass(publication.id, objectComment, userProfile, reactionContainer, ownReaction);
							publication.commentContainer.push(comment);

							this.orderCommentList(publication.commentContainer);
							resolve(comment);
						}
						publication.commentCounter = publication.commentContainer.length;

					});
				});
			}).catch((error) => {
				reject({ 'error': 'la publicación no existe' });
			});

		});
	}

	public orderCommentList(commentsList: Array<CommentClass>) {
		commentsList = commentsList.sort((p1, p2) => {
			return (p1.timestamp > p2.timestamp) ? -1 : 1;
		});
	}

	public detachCommentFromPublicationByID(comment_id: number, publication: PublicationClass) {
		return new Promise<string>((resolve, reject) => {
			let comment = publication.commentContainer.find(element => element.id == comment_id);

			if (comment != null) {
				let index = publication.commentContainer.indexOf(comment, 0);
				if (index > -1) {
					publication.commentContainer.splice(index, 1);
				}

				comment = undefined;
				publication.commentCounter = publication.commentContainer.length;
				resolve("Publicación eliminada de memoria");

			}
			else {
				reject("La publicación no esta en memoria");
			}

		});
	}


	public postReview(comment: any, id_publication: number): Promise<RequestResponse> {
		var params;
		id_publication ? params = { "id": id_publication } : params = null;

		return new Promise((resolve, reject) => {
			this.request.createRequestPost('publications', comment, params, 'reviews').then((data) => {
				resolve(data);
			}).catch((error) => {
				reject(error);
			})
		});
	}

	public getReviews(publication_id?: number, page?: number): Promise<RequestResponse> {

		let page_str;
		page ? page_str = page.toString() : page_str = "1";
		publication_id ? publication_id = publication_id : publication_id = 0;

		var params = {
			'id': publication_id.toString(),
			'queryParams': {
				"page": page_str
			}
		};

		return new Promise((resolve, reject) => {
			this.request.createRequestGet('publications', params.queryParams, params, 'reviews').then((data) => {
				resolve(JSON.parse(data));
			}).catch((error) => {
				reject(error);
			})
		});
	}
	public putReview(comment: any, id_publication: number, id_comment: number): Promise<RequestResponse> {
		var params = {
			"id": id_publication,
			"id2": id_comment
		}

		return new Promise((resolve, reject) => {
			this.request.createRequestPut('publications', comment, params, 'reviews').then((data) => {
				resolve(data);
			}).catch((error) => {
				reject(error);
			});
		});
	}
	public updateReviewList(objectComment: any, publication: PublicationClass) {
		return new Promise<CommentClass>((resolve, reject) => {
			this.publicationService.getUserProfile_Publications(objectComment).then((userProfile: SellerProfile) => {
				this.publicationService.getReactionContainer_Publications(objectComment).then((reactionContainer: Array<ReactionContainerClass>) => {
					this.publicationService.getOwnReaction_Publications(objectComment).then((ownReaction) => {

						let comment: CommentClass = publication.ReviewContainer.find(element => element.id == objectComment.id);
						if (comment != undefined) {
							comment.updateComment(objectComment, userProfile, reactionContainer, ownReaction);

							this.orderCommentList(publication.ReviewContainer);
							resolve(comment);
						}
						else {
							comment = new CommentClass(publication.id, objectComment, userProfile, reactionContainer, ownReaction);
							publication.ReviewContainer.push(comment);

							this.orderCommentList(publication.ReviewContainer);
							resolve(comment);
						}
						publication.reviewCounter = publication.ReviewContainer.length;

					});
				});
			}).catch((error) => {
				reject({ 'error': 'la publicación no existe' });
			});

		});
	}

}
