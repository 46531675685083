import { Component, OnInit } from '@angular/core';
import { FormServiceService } from 'src/app/services/publications/form-service.service';

@Component({
  selector: 'app-epoch3',
  templateUrl: './epoch3.component.html',
  styleUrls: ['./epoch3.component.scss'],
})
export class Epoch3Component implements OnInit {

  constructor(public formservice:FormServiceService) { }

  ngOnInit() {}

  addPrice(event) {
  
    this.formservice.epochpublication.price=event.target.value
  }

}
