import { ReactionClass } from "../SingleReaction/reaction-class";

export class ReactionContainerClass {

    reaction_quantity: number;
    reaction: ReactionClass;

    constructor(singleReaction: ReactionClass, reaction_quantity?: number) {
        this.reaction = singleReaction;

        this.reaction_quantity = (reaction_quantity) ? reaction_quantity : 0;

    }

}
