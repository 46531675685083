import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompraFinalizadaComponent } from './compra-finalizada.component';



@NgModule({
  declarations: [
    CompraFinalizadaComponent
  ],
  exports:[
    CompraFinalizadaComponent
  ],
  entryComponents:[
    CompraFinalizadaComponent
  ],
  imports: [
    CommonModule
  ]
})
export class CompraFinalizadaModule { }
