import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Conversation } from 'src/app/Models/Classes/Chat/conversation';
import { UserContact } from 'src/app/Models/Classes/User/user-contact';
import { ChatService } from 'src/app/services/chat/chat.service';
import { InputAlertControllerService } from 'src/app/services/utils/input-alert-controller.service';
import { MessagesListComponent } from '../messages-list/messages-list.component';

@Component({
	selector: 'app-create-group',
	templateUrl: './create-group.component.html',
	styleUrls: ['./create-group.component.scss'],
})
export class CreateGroupComponent implements OnInit {
	public selectedContacts: Array<UserContact> = new Array<UserContact>();
	public chatName: string = "Nuevo Grupo";
	constructor(
		public chatService:ChatService,
		private modalController: ModalController,
		private inputAlertController: InputAlertControllerService
	) { }

	ngOnInit() {
	
	}


	public attach_detach(user: UserContact) {
		if (this.isSelected(user)) {
			const index = this.selectedContacts.indexOf(user, 0);
			if (index > -1) {
				this.selectedContacts.splice(index, 1);
			}
		}
		else {
			this.selectedContacts.push(user);
		}

	}

	public attach_detach_checkbox(event, contact: UserContact) {
		if (event.detail.checked) {
			if (!this.isSelected(contact)) {
				this.selectedContacts.push(contact);
			}
		}
		else {
			if (this.isSelected(contact)) {
				const index = this.selectedContacts.indexOf(contact, 0);
				if (index > -1) {
					this.selectedContacts.splice(index, 1);
				}
			}
		}
	}

	public isSelected(user: UserContact) {
		const index = this.selectedContacts.indexOf(user, 0);
		if (index > -1) {
			return true;
		}
		return false;
	}

	public newGroupConversation() {
		this.chatService.newGroupConversation(this.selectedContacts, this.chatName).then((conversation:Conversation)=>{
			this.openConversation(conversation)
		}).catch((error)=>{
			console.error(error);
		});
	}

	public rename_chat(){
		this.inputAlertController.InputAlertPrompt(
			"Cambiar Nombre del Grupo",                                         //Header
			"",                                                            //Message
			this.chatName,
			"text",                                                     //Input Type
			"Editar"                                                    //Ok Button Text
		).then((result) => {
			if (result['action']) {
				if (!result['value'].match(/^ *$/)) {
					this.chatName = result['value'].trim();
				}
			}
		});
	}

	private openConversation(conversation: Conversation) {
		this.modalController.dismiss().then(() => {
			this.modalController.create({
				component: MessagesListComponent,
				swipeToClose: true,
				componentProps: {
					conversation: conversation
				}
			}).then((modal)=>{
				modal.present();
			});
		}).catch(error => {
			console.error(error);
		});
	}
}
