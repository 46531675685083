import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Utils } from '../Models/Classes/utils';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	constructor(
		private utils: Utils,
		private router: Router
	) { }

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
		return new Promise((resolve) => {
			this.utils.getAccessData().then(() => {
				resolve(this.router.parseUrl("/home"));
			}).catch(() => {
				resolve(true);
			});
		});
	}
}
