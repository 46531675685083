import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { CameraServiceService } from 'src/app/services/multimedia/camera-service.service';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import { MediaItem } from 'src/app/Models/Classes/Media/media-item';
import { element } from 'protractor';
import { Capacitor } from '@capacitor/core';
import { isVideo,isImage } from 'src/app/utils';
import { ModalController, ActionSheetController } from '@ionic/angular';
import Swal2 from 'sweetalert2';
import { UploadMultimediaService } from 'src/app/services/upload/upload-multimedia.service';
import { Camera, CameraResultType, CameraSource, CameraOptions } from '@capacitor/camera';
import { ChangeDetectorRef } from '@angular/core';
@Component({
  selector: 'app-epoch2',
  templateUrl: './epoch2.component.html',
  styleUrls: ['./epoch2.component.scss'],
})
export class Epoch2Component implements OnInit {

  @ViewChild("Limp") limpiar: ElementRef;
  mediaType: string;
  Lecciones:Array<object> =[]
  
  rutaPortada : any;

  public multimedia2: Array<MediaItem> = new Array<MediaItem>()
  ruta:any
  namefile:any

  constructor(private cameraService: CameraServiceService,
    public formservice:FormServiceService,private render2:Renderer2,
    private actionSheetController: ActionSheetController,
    public uploadMedia: UploadMultimediaService,
    private ref: ChangeDetectorRef,) { }

  ngOnInit() {
    this.formservice.multimedia2=[]
   }

  public isVideo(url:string) {
    Capacitor.convertFileSrc(url)
    return isVideo(url)
  }

public isImage(url:string){
  Capacitor.convertFileSrc(url)

  return isImage(url)
}

  GuardarServicios(){

  


    const LeccionAdicional ={
      titulo: this.formservice.epochpublication.titleLeccion,
      descripcion: this.formservice.epochpublication.descriptionLeccion,
      ruta:  Capacitor.convertFileSrc(this.ruta + this.namefile),
      
    }
 
    

    this.Lecciones.push(LeccionAdicional)
    this.formservice.epochpublication.Lecciones= this.Lecciones
 
    console.log("Lecciones Guardadas: ", this.formservice.epochpublication.Lecciones);

    
    
    
   }
  addTitleLeccion(event) {
    this.formservice.epochpublication.titleLeccion=event.target.value
  }
  
  addDescriptionLeccion(event) {
    this.formservice.epochpublication.descriptionLeccion=event.target.value
  }

  hide:boolean = false;
	ngIfCtrl(){
  	this.hide = !this.hide;
	}

  setMultimedia(mediaItem: MediaItem) {
		this.multimedia2.push(mediaItem)

    this.formservice.multimedia2=this.multimedia2
    

    this.formservice.multimedia2.forEach(element=>{

      this.ruta=element.mediaPath
      this.namefile=element.nameFile

      
      
    })
    
	}

  public pickImages() {
		this.cameraService
			.pickMedia()
			.then((mediaItem) => {
				this.setMultimedia(mediaItem);
			})
			.catch((error) => {
				console.error(error);
			});
	}
  askForLibraryCapture(take_photo: boolean = true, take_video: boolean = true, pick_image: boolean = true,pick_video: boolean = true) {
    Swal2.fire({
			title: '',
			text: '',
			icon: 'success',
			timer: 9999, // Show the alert for 45 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-start',
			showConfirmButton: false
		  });
    setTimeout(() => {
      this.actionSheetController.dismiss();
      }, 9999); 
		return new Promise((resolve, reject) => {

			let botonesDisponibles = [
				(pick_image) ?
					{
						text: 'Seleccionar una foto',
						icon: 'camera',
						handler: () => {
							console.log("oprimiste camara");

							this
                             .pickImage()
                             .then((mediaItem) => {
                               resolve([mediaItem,'camara']);
                             })
                             .catch((error) => {
                               Swal2.fire({
                                 title: 'Error take photo',
                                 text: error,
                                 icon: 'warning',
                                 confirmButtonText: 'Entendido',
                                 color: '#ffffff',
                                 background: '#010f4e',
                               });
                             });
						}
					} : null,
				(pick_video) ?
					{
						text: 'Seleccionar un vídeo',
						icon: 'videocam',
						handler: () => {
							console.log("oprimiste video");
							this
                            .pickVideo()
                            .then((mediaItem) => {
                              resolve([mediaItem,'camvideo']);
                            })
                            .catch((error) => {
                              Swal2.fire({
                                title: 'Error al capturar video',
                                text: error,
                                icon: 'warning',
                                confirmButtonText: 'Entendido',
                                color: '#ffffff',
                                background: '#010f4e',
                              });
                            });
						}
					} : null,
				{
					text: 'Cancelar',
					icon: 'close',
					role: 'cancel',
					handler: () => {
					}
				}
			];

			botonesDisponibles = botonesDisponibles.filter((boton) => {
				if (boton != null) {
					return true;
				}
				return false;
			});


			this.actionSheetController.create({
				header: '¿Desde donde deseas añadir multimedia?',
				cssClass: 'options-sheet',
				buttons: botonesDisponibles
			}).then((actionSheet) => {
				actionSheet.present();
			});
		});
	}
  async pickImage(){
    const imagePreviewElement = document.getElementById("imagePreview") as HTMLImageElement;
    try {
      const options: CameraOptions = {
        quality: 100,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        source: CameraSource.Photos,
        saveToGallery: false,
      };
      console.log('About to call Camera.getPhoto()');
      const media = await Camera.getPhoto(options);
  
      if (media) {
        console.log('Media picked successfully:', media);
        if (Capacitor.getPlatform() === 'web') {
          // For web, create a Blob from the media data and convert it to a data URL
          const response = await fetch(media.webPath);
          const blobfile = await response.blob();
          const reader = new FileReader();
          reader.onloadend = () => {
            const imageUrl = reader.result as string;
            imagePreviewElement.src = imageUrl;
          };
          reader.readAsDataURL(blobfile);
        } else {
          // For iOS and Android, use Capacitor.convertFileSrc to get the public URL
          const imageUrl = Capacitor.convertFileSrc(media.path);
          imagePreviewElement.src = imageUrl;
        }
        this.mediaType = media.format === 'jpeg' || media.format === 'png' ? 'image' : 'video';
        this.ref.detectChanges();
        const mimeType = media.format === 'jpeg' ? 'image/jpeg' : media.format === 'png' ? 'image/png' : 'video/mp4';
  
        // Read the file as a Blob
        const response = await fetch(media.webPath);
        const blobfile = await response.blob();
        this.uploadMedia.blob = blobfile;
        // Log the Blob for debugging purposes
        console.log('fileBlob:', blobfile);
        if (Capacitor.getPlatform() !== 'web') {
          this.uploadMedia.filename = media.path.substring(media.path.lastIndexOf('/') + 1);
    }else{
      const currentTime = new Date().toISOString().replace(/:/g, '-').replace(/\./g, '-');
      this.uploadMedia.filename = currentTime + '.png';
    }
this.formservice.premiumMedia = true;
  this.uploadMedia.blob = blobfile;
        //this.uploadMedia.filename = filename;
        if(this.formservice.normalpublication.blobsMultimedia.length < 1){
          this.formservice.normalpublication.blobsMultimedia[0]={blob:this.uploadMedia.blob,isPremium:true, filename: this.uploadMedia.filename};
        }else {
          this.formservice.normalpublication.blobsMultimedia.push({blob:this.uploadMedia.blob,isPremium:true, filename: this.uploadMedia.filename});
        } 
        
      }
    } catch (error) {
      console.error('Error picking media:', error);
    }
  }  
  async pickVideo() {
    if (Capacitor.getPlatform() === 'ios') {
      Swal2.fire({
        title: 'Añade videos en tu dispositivo apple',
        text: 'Para añadir videos optimizados añade el video de tu librería de Fotos a tus Archivos. A continuación la opción optimizada es Choose File',
        icon: 'info',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
    }

    const videoPreviewElement = document.getElementById("videoSource") as HTMLVideoElement;
  
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "video/*";
    fileInput.multiple = false;
  
    fileInput.addEventListener("change", () => {
      const files = fileInput.files;
  
      if (files && files.length) {
        const file = files[0];

        const mimeType = file.type;


        const blobfile = file;
        const filename = file.name;
       // Create a MediaItem object from the File
      const mediaItem: MediaItem = {
        mediaPath: file.name,
        typeData: file.type,
        nameFile: file.name,
        id: 1, // Assign a unique ID here if needed
        size: file.size,
        index: 0, // Set the desired index value
        route: "", // Set the route value if needed
        status: "", // Set the initial status
        url: "", // Set the URL property
        localUrl: "", // Set the local URL property
        type: "", // Set the type property
        is_premium: 1, // Set the is_premium property
        // Add any other required properties
      };
      this.formservice.premiumMedia = true;
      this.uploadMedia.blob = blobfile;
      this.uploadMedia.filename = filename;
        if (this.formservice.multimedia.length > 0) {
          this.formservice.multimedia[0] =  mediaItem;
        } else {
          this.formservice.multimedia.push(mediaItem);
        }
        if(this.formservice.normalpublication.blobsMultimedia.length < 1){
          this.formservice.normalpublication.blobsMultimedia[0]={blob:this.uploadMedia.blob,isPremium:true, filename: this.uploadMedia.filename};
        }else {
          this.formservice.normalpublication.blobsMultimedia.push({blob:this.uploadMedia.blob,isPremium:true, filename: this.uploadMedia.filename});
        }
  
        const videoURL = URL.createObjectURL(file);
  
        // Update the video source and display it
        videoPreviewElement.src = videoURL;
      }
    });
  
    // Trigger the file input dialog
    fileInput.click();
  }
  public setMultimediaCamera(mediaItem: MediaItem) {
    
    console.log("setMultimediaCamera function called");
    const imagePath = mediaItem.mediaPath + mediaItem.nameFile;
    console.log("Captured image path:", imagePath);
    this.uploadMedia.image = Capacitor.convertFileSrc(imagePath);
    this.rutaPortada = { image: this.uploadMedia.image };
    this.uploadMedia.blob = mediaItem.mediaPath;
    this.uploadMedia.filename =  mediaItem.nameFile;
    if (this.formservice.multimedia.length > 0) {
      console.log("Inside  ecosystem condition");
      this.formservice.multimedia[0] = this.rutaPortada;
    } else {
      this.formservice.multimedia.push(this.rutaPortada);
    }
    if(this.formservice.normalpublication.blobsMultimedia.length < 1){
      this.formservice.normalpublication.blobsMultimedia[0]={blob:this.uploadMedia.blob,isPremium:true, filename: this.uploadMedia.filename};
    }else {
      this.formservice.normalpublication.blobsMultimedia.push({blob:this.uploadMedia.blob,isPremium:true, filename: this.uploadMedia.filename});
    }
  
    console.log("Updated rutaPortada:", this.rutaPortada);
  
    // Set the image source in the HTML
    const imagePreviewElement = document.getElementById("imagePreview") as HTMLImageElement;
    imagePreviewElement.src = this.uploadMedia.image;
  }
  
}
